import { Room } from 'models/Room';
import { RoomsCardSort } from 'typings/enums/RoomsCardSort';

const compareFn = (propA: string | boolean, propB: string | boolean, asc = true) => {
  if (propA > propB) {
    return asc ? 1 : -1;
  }
  if (propA < propB) {
    return asc ? -1 : 1;
  }
  return 0;
};

export const getRoomsSortFn = (cardsSortMode?: RoomsCardSort) => {
  return (a: Room, b: Room) => {
    switch (cardsSortMode) {
      case RoomsCardSort.AlphaAsc:
        return compareFn(a.name.toLowerCase(), b.name.toLowerCase());
      case RoomsCardSort.AlphaDesc:
        return compareFn(a.name.toLowerCase(), b.name.toLowerCase(), false);
      case RoomsCardSort.MostRecent:
        return compareFn(a.createdUtc, b.createdUtc, false);
      case RoomsCardSort.LastEdited:
        return compareFn(a.lastEditedUtc, b.lastEditedUtc, false);
      case RoomsCardSort.Favorite:
      default:
        return compareFn(a.isFavorite, b.isFavorite, false);
    }
  };
};
