import { FormControl, FormControlProps, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { Switch } from 'appkit-react';

import { useController, UseControllerProps } from 'react-hook-form';

interface ISwitchFieldProps
  extends UseControllerProps,
    Omit<FormControlProps, 'onChange' | 'defaultValue'> {
  onText?: string;
  offText?: string;
  disabled?: boolean;
  readonly?: boolean;
  label?: string;
}

export const SwitchField: FC<ISwitchFieldProps> = ({ control, name, rules, sx, ...rest }) => {
  const {
    field: { value, ...restField },
    fieldState: { error },
  } = useController({ control, name, rules });

  return (
    <FormControl sx={sx}>
      <Switch size="small" defaultChecked={value} {...restField} {...rest} />
      {error && <Text className="a-form-error">{error.message}</Text>}
    </FormControl>
  );
};
