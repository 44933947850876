import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';

import { Form } from 'models/Form';
import { useFormMutate } from '../../RoomsItemsList.hooks';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { PopAlertFail } from 'services/PopAlertFail';
import { useCurrentUserEmail } from 'hooks/useCurrentUserEmail';

interface IRoomCellTypeFormProps {
  form: Form;
}

export const RoomCellTypeForm: FC<IRoomCellTypeFormProps> = ({ form }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useFormMutate(form, HttpMethod.Post, `/${form.id}/setFavorite`);
  const userEmail = useCurrentUserEmail();

  const handleFavorite = async () => {
    setIsLoading(true);
    try {
      await mutate({
        isFavorite: !form.isFavorited(userEmail),
      });
    } catch (response: any) { // eslint-disable-line
      PopAlertFail(t('forms.favorite.error.title'), response.message);
    } finally {
      setIsLoading(false);
    }
  };

  const icon = form.isFavorited(userEmail) ? 'icon-rating-fill' : 'icon-rating-outline';

  return isLoading ? (
    <Text as="span" className="a-loading a-default" width="16px" height="16px" />
  ) : (
    <Box as="span" cursor="pointer" className={`appkiticon ${icon}`} onClick={handleFavorite} />
  );
};
