import { useDatx } from 'hooks/useDatx';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

import { useCurrentRoomFolders } from 'hooks/useCurrentRoomFolders';
import { RoomFolder } from 'models/RoomFolder';
import { useRoomFolderMatch } from 'hooks/useRoomFolderMatch';

export const useFolderBreadcrumbs = () => {
  useCurrentRoomFolders(); // force render when room-folders are loaded to store
  const location = useLocation();
  const datx = useDatx();

  const pathRegex = /rooms\/([\w+, -]+)(?<folders>.+)+/gi;
  const matches = pathRegex.exec(location.pathname);

  const folders = matches?.groups?.folders
    .split('/')
    .map((id) => datx.findOne(RoomFolder, id))
    .filter(Boolean);

  return folders;
};

export const useRoomFolderBreadcrumbs = () => {
  useCurrentRoomFolders(); // force re-render when room-folders are loaded to store
  const datx = useDatx();
  const match = useRoomFolderMatch();

  const folderBreadcrumbItems = useMemo(() => {
    if (!match) {
      return [];
    }

    const { roomId, folderId } = match.params;
    if (!folderId) {
      return [];
    }
    const currentFolder = datx.findOne(RoomFolder, folderId);
    if (!currentFolder) {
      return [];
    }
    return currentFolder.ancestorNamesAndIds.map((folder) => ({
      id: folder.folderId,
      link: `/rooms/${roomId}/${folder.folderPath.join('/')}`,
      value: folder.folderName,
    }));
  }, [match, datx]);

  return folderBreadcrumbItems;
};
