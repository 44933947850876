import { FC } from 'types';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Form } from 'models/Form';

interface IFormsTableCellStatusProps {
  form: Form;
}

export const FormsTableCellStatus: FC<IFormsTableCellStatusProps> = ({ form }) => {
  const { t } = useTranslation();

  const getStatus = () => {
    if (form.isDeleted) {
      return t(`form.status.Deleted`);
    }

    if (form.isDeactivated) {
      return t(`form.status.Deactivated`);
    }

    return t(`form.status.${form.formStatus}`);
  };

  return <Box>{getStatus()}</Box>;
};
