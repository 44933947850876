import { useMemo, useState } from 'react';

import { FormComponent } from 'models/FormComponent';
import { FormComponentType } from 'typings/enums/FormComponentType';
import {
  IntervalNumberValue,
  IntervalTextValue,
  IntervalValuesFormField,
  ScaleIntervalInputType,
  ScaleSettingsFormFields,
  ScaleType,
} from 'typings/types/FormComponentSettings';

import {
  parseFormFieldValues,
  parseScaleNumberQuestionValues,
  parseScaleTextQuestionValues,
} from '../ScaleSettingsEditorModal/ScaleSettingsEditor.utils';

export const useScaleComponentState = (question: FormComponent) => {
  const [state, setState] = useState<ScaleSettingsFormFields>(() => parseFormFieldValues(question));

  const [intervalNumberMarks, setIntervalNumberMarks] = useState<Array<IntervalNumberValue>>(() =>
    state.inputType === ScaleIntervalInputType.Number
      ? parseScaleNumberQuestionValues(question.valueJson)
      : [],
  );
  const [intervalTextMarks, setIntervalTextMarks] = useState<Array<IntervalTextValue>>(() =>
    state.inputType === ScaleIntervalInputType.Text
      ? parseScaleTextQuestionValues(question.valueJson)
      : [],
  );
  const intervalMarks = useMemo<IntervalValuesFormField>(
    () => ({ marksNumber: intervalNumberMarks, marksText: intervalTextMarks }),
    [intervalNumberMarks, intervalTextMarks],
  );

  const setType = (type: ScaleType) => {
    setState((previous) => {
      return { ...previous, scaleType: type };
    });
  };
  const setSettings = (formValues: ScaleSettingsFormFields) => {
    setState((previous) => {
      return { ...formValues, scaleType: previous.scaleType };
    });
  };

  const getComponentType = (): FormComponentType =>
    state.inputType === ScaleIntervalInputType.Text
      ? FormComponentType.ScaleText
      : FormComponentType.ScaleNumber;

  return {
    type: state.scaleType,
    setType,
    settings: state,
    setSettings,
    intervalMarks,
    intervalNumberMarks,
    setIntervalNumberMarks,
    intervalTextMarks,
    setIntervalTextMarks,
    getComponentType,
  };
};
