import React from 'react';
import { FC } from 'types'
import { Heading } from '@chakra-ui/react';

export const RoomCardTitle: FC = ({ children }) => {
  return (
    <Heading
      as="h3"
      noOfLines={2}
      sx={{
        height: '72px',
        maxWidth: '290px',
        color: 'white',
        margin: 'auto',
        fontSize: '22',
        fontWeight: 'medium', // this looks smaller on the prototype, check again
        lineHeight: '36',
        letterSpacing: '0',
        textAlign: 'center',
      }}
    >
      {children}
    </Heading>
  );
};
