import { FC } from 'types';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { Radio } from 'appkit-react';

interface ICreateRoomFormTypeProps extends Pick<BoxProps, 'onClick'> {
  isActive: boolean;
}

export const CreateRoomFormType: FC<ICreateRoomFormTypeProps> = ({
  isActive,
  onClick,
  children,
}) => {
  return (
    <Flex direction="column" cursor="pointer" onClick={onClick}>
      <Box width="170px" boxShadow="mainWhiteSmall" flex="1">
        {children}
      </Box>
      <Flex justify="center" mt="32px" align="flex-end">
        <Radio checked={isActive} />
      </Flex>
    </Flex>
  );
};
