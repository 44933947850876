import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useBoolean } from '@chakra-ui/react';
import { mutate } from 'swr';

import { CampaignResponse } from 'models/CampaignResponse';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { useCampaignCommonActions } from 'hooks/useInitiateCampaign';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { ActionsMenuItem } from 'components/core/ActionsMenu/ActionsMenuItem/ActionsMenuItem';
import { ActionsMenu } from 'components/core/ActionsMenu/ActionsMenu.elements';
import { CampaignResponseActivationStatus } from 'typings/enums/CampaignResponseActivationStatus';
import { CampaignUserRole } from 'typings/enums/CampaignUserRole';

interface IFormCampaignsTableViewMenuProps {
  campaignViewItem: CampaignResponse;
  top?: number;
  left?: number;
  roomId: string;
  formId: string;
  onClose(): void;
  onManageModalOpen(): void;
  onExportModalOpen(): void;
}

export const FormCampaignsTableViewMenu: FC<IFormCampaignsTableViewMenuProps> = ({
  campaignViewItem,
  roomId,
  formId,
  onManageModalOpen,
  onExportModalOpen,
  onClose,
  ...props
}) => {
  const [isDeleting, setIsDeleting] = useBoolean();
  const [isActivating, setIsActivating] = useBoolean();
  const dependencyHeaders = useDependencyHeaders();

  const { t } = useTranslation();
  const history = useHistory();

  const { deleteCampaign, activateCampaign } = useCampaignCommonActions(roomId, formId);

  const handleOpen = () => {
    history.push(`/rooms/${roomId}/forms/${formId}/campaign/${campaignViewItem.id}`);
    onClose();
  };

  const handleManage = () => {
    onManageModalOpen();
    onClose();
  };

  const handleDelete = async () => {
    setIsDeleting.on();
    try {
      await deleteCampaign(campaignViewItem.id);
      PopAlertSuccess(t('campaigns.tableView.successMessages.delete'));
      await mutate(`rooms/${roomId}/forms/${formId}/campaign@${dependencyHeaders['x-site-id']}`);
      onClose();
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 403) {
        PopAlertFail(t('campaigns.tableView.errors.delete.main'));
      } else {
        PopAlertFail(t('campaigns.tableView.errors.delete.unexpected'));
      }
    } finally {
      setIsDeleting.off();
    }
  };

  const handleActivation = async () => {
    setIsActivating.on();
    try {
      await activateCampaign(campaignViewItem.id);
      PopAlertSuccess(t('campaigns.tableView.successMessages.activationStatus'));
      await mutate(`rooms/${roomId}/forms/${formId}/campaign@${dependencyHeaders['x-site-id']}`);
      onClose();
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 403) {
        PopAlertFail(t('campaigns.tableView.errors.activationStatus.main'));
      } else {
        PopAlertFail(t('campaigns.tableView.errors.activationStatus.unexpected'));
      }
    } finally {
      setIsActivating.off();
    }
  };

  return (
    <ActionsMenu {...props}>
      <ActionsMenuItem
        label={t('campaigns.tableView.menu.items.open')}
        iconName="icon-purpose-outline"
        onClick={handleOpen}
      />
      {campaignViewItem.currentUserRole !== CampaignUserRole.Viewer && (
        <ActionsMenuItem
          label={t('campaigns.tableView.menu.items.manageCampaign')}
          iconName="icon-digital-transformation-outline"
          onClick={handleManage}
        />
      )}
      <ActionsMenuItem
        label={t('campaigns.tableView.menu.items.export')}
        iconName="icon-download-outline"
        onClick={() => {
          onExportModalOpen();
          onClose();
        }}
      />
      <ActionsMenuItem
        label={
          campaignViewItem.activationStatus === CampaignResponseActivationStatus.Active
            ? t('campaigns.tableView.menu.items.deactivate')
            : t('campaigns.tableView.menu.items.activate')
        }
        iconName={
          campaignViewItem.activationStatus === CampaignResponseActivationStatus.Active
            ? 'icon-end-process-outline'
            : 'icon-check-mark-outline'
        }
        onClick={handleActivation}
        isLoading={isActivating}
      />
      <ActionsMenuItem
        label={t('campaigns.tableView.menu.items.delete')}
        iconName="icon-delete-outline"
        onClick={handleDelete}
        isLoading={isDeleting}
      />
    </ActionsMenu>
  );
};
