import { Assessment } from 'models/Assessment';
import { User } from 'models/User';
import { PermissionGroupUserField } from 'typings/types/dto/AssessmentDTO';
import { useDatx } from 'hooks/useDatx';

export const useCollaboratorsFormChoices = (
  assessment: Assessment,
): Array<Partial<PermissionGroupUserField>> => {
  const datx = useDatx();

  const result = assessment.parsedCollaborators.map((pc) => {
    const user = datx.findOne<User>(User, pc.userId);

    return {
      ...pc,
      email: user?.email,
      username: user?.username,
    };
  });
  return result;
};
