import { Box, HStack } from '@chakra-ui/react';
import { useState } from 'react';
import { Table } from 'appkit-react';
import { useTranslation, TFunction } from 'react-i18next';

import { SiteManagementSiteCreatedMessageModal } from 'components/application-admin/site-management/SiteManagementSiteCreatedMessageModal';
import { SiteManagementModal } from 'components/application-admin/site-management/SiteManagementModal';
import { TableSearch } from 'components/table/TableSearch/TableSearch';
import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { useFilteredSites } from 'hooks/useFilteredSites';
import { Site } from 'models/Site';
import { CellText } from 'components/core/CellText/CellText';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { useModal } from 'hooks/useModal';
import { ExportAppData } from 'components/export-data/ExportAppData/ExportAppData';

const columns = (onSelect: (siteId: string) => void, t: TFunction) => [
  {
    Header: t('applicationAdmin.site.tableColumns.name'),
    accessor: 'siteName',
    Cell: (row: Record<string, unknown>) => (
      <CellText onClick={() => onSelect((row.original as Site).id)} cursor="pointer">
        {row.value as string}
      </CellText>
    ),
  },
  {
    Header: t('applicationAdmin.site.tableColumns.contact'),
    accessor: 'pwcContact',
  },
  {
    Header: t('applicationAdmin.site.tableColumns.status'),
    accessor: 'siteStatus',
  },
  {
    Header: t('applicationAdmin.site.tableColumns.libraryAccess'),
    accessor: 'allowLibraryAccess',
    Cell: (row: Record<string, unknown>) => (
      <CellText width="100%">
        {(row.value as string)
          ? t('applicationAdmin.domain.libraryAccess.enabled')
          : t('applicationAdmin.domain.libraryAccess.disabled')}
      </CellText>
    ),
  },
  {
    Header: t('applicationAdmin.site.tableColumns.maxUsers'),
    accessor: 'maximumNumberOfUsers',
  },
];

export const SiteManagementTable = () => {
  const managementModal = useModal();
  const successModal = useModal();

  const [createdSiteName, setCreatedSiteName] = useState<string | null>('');
  const [selectedSite, setSelectedSite] = useState<string | null>(null);

  const { t } = useTranslation();

  const { handleSearchInputChange, data, isValidating } = useFilteredSites('siteName');

  const onSiteSelect = (siteId: string) => {
    setSelectedSite(siteId);
    managementModal.onOpen();
  };

  const onCancel = () => {
    setSelectedSite(null);
    managementModal.onClose();
  };

  const onSiteCreated = (siteName: string) => {
    setCreatedSiteName(siteName);
    successModal.onOpen();
  };

  const onCreationSiteMessageModalAction = () => {
    successModal.onClose();
    setCreatedSiteName(null);
  };

  return (
    <Box mt={20}>
      <TableWrapper isLoading={isValidating}>
        <TableSearch onChange={handleSearchInputChange} />
        <Table
          data={data}
          columns={columns(onSiteSelect, t)}
          showPageSizeOptions={false}
          defaultPageSize={10}
          defaultSorted={[{ id: 'siteName', asc: true }]}
        />
        {managementModal.isOpen && (
          <SiteManagementModal
            siteId={selectedSite}
            onCancel={onCancel}
            onSiteCreated={onSiteCreated}
          />
        )}
        {successModal.isOpen && (
          <SiteManagementSiteCreatedMessageModal
            siteName={createdSiteName}
            onCancel={onCreationSiteMessageModalAction}
          />
        )}

        <HStack spacing={20} position="absolute" right="0" bottom="0">
          <ExportAppData />
          <StyledButton size="md" onClick={managementModal.onOpen}>
            {t('applicationAdmin.site.action.create')}
          </StyledButton>
        </HStack>
      </TableWrapper>
    </Box>
  );
};
