import { chakra } from '@chakra-ui/react';

export const StyledGroup = chakra('div', {
  baseStyle: {
    px: '25px',
    py: '10px',
    my: '10px',
    position: 'relative',
  },
});

export const StyledGroupWrapperLeft = chakra('div', {
  baseStyle: {
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    bg: 'brand.orange',
    width: '1px',
    ':before': {
      content: '""',
      position: 'absolute',
      left: '0',
      width: '15px',
      height: '1px',
      top: '0',
      bg: 'brand.orange',
    },
    ':after': {
      content: '""',
      position: 'absolute',
      left: '0',
      width: '15px',
      bottom: '0',
      height: '1px',
      bg: 'brand.orange',
    },
  },
});

export const StyledGroupWrapperRight = chakra('div', {
  baseStyle: {
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    bg: 'brand.orange',
    width: '1px',
    ':before': {
      content: '""',
      position: 'absolute',
      right: '0',
      width: '15px',
      height: '1px',
      top: '0',
      bg: 'brand.orange',
    },
    ':after': {
      content: '""',
      position: 'absolute',
      right: '0',
      width: '15px',
      bottom: '0',
      height: '1px',
      bg: 'brand.orange',
    },
  },
});
