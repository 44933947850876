import { FC } from 'types';
import { ButtonGroup } from 'appkit-react';
import { Control, FieldValues, useController } from 'react-hook-form';

import { IButtonGroupProps } from 'typings/appkit-react/IButtonGroupProps';

interface IButtonGroupFieldProps extends Omit<IButtonGroupProps, 'value' | 'defaultValue'> {
  name: string;
  control: Control<FieldValues>;
}
export const ButtonGroupField: FC<IButtonGroupFieldProps> = ({ name, control, children }) => {
  const {
    field: { onChange, ...restField },
  } = useController({ control, name });

  return (
    <ButtonGroup {...restField} onChange={(_e: Event, value: string) => onChange(value)}>
      {children}
    </ButtonGroup>
  );
};
