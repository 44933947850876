import { Square, SquareProps, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { Tooltip } from 'appkit-react';

export const FormFieldHelp: FC<SquareProps> = ({ children, ...props }) => {
  return (
    <Square size="34px" {...props}>
      <Tooltip content={children} placement="top">
        <Text
          size="3xl"
          as="span"
          className="appkiticon icon-help-question-fill"
          color="brand.orange"
        />
      </Tooltip>
    </Square>
  );
};
