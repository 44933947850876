import { FC } from 'types';
import { Box, Flex, useBoolean } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup } from 'appkit-react';
import { observer } from 'mobx-react';
import { updateModel } from '@datx/core';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { ModalFooter } from 'components/modal/Modal/components/ModalFooter';
import { Campaign } from 'models/Campaign';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { InitiateCampaignTabsValueType } from 'components/campaign/CampaignModalTabs/CampaignModalTabs';
import { AssessmentType } from 'typings/enums/AssessmentType';
import { IAssessmentSetupFormValues } from 'typings/interfaces/formValues/IAssessmentSetupFormValues';
import { useDatx } from 'hooks/useDatx';
import { ParseAndPopAlertFail } from 'services/ParseAndPopAlertFail';
import { CampaignStatus } from 'typings/enums/CampaignStatus';
import { isApiClientError } from 'utils/isApiClientError';
import { isApiFormError } from 'utils/isApiFormError';
import { parseApiFormError } from 'utils/parseApiFormError';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

import { EachRecipientForm } from './components/EachRecipientForm/EachRecipientForm';
import { OneRecipientGroupForm } from './components/OneRecipientGroupForm/OneRecipientGroupForm';
import {
  updateAssessmentPermissionGroups,
  updateCampaignPermissionGroups,
} from './AssessmentSetupForm.utils';

export interface IAssessmentSetupFormProps {
  campaign: Campaign;
  onDelete(): Promise<void>;
  onSave(): Promise<void>;
  setSelectedTab(tab: InitiateCampaignTabsValueType): void;
}

export const AssessmentSetup: FC<IAssessmentSetupFormProps> = observer(
  ({ campaign, onSave, onDelete, setSelectedTab }) => {
    const { t } = useTranslation();
    const datx = useDatx();
    const [isDeleting, setIsDeleting] = useBoolean();

    const {
      control,
      handleSubmit,
      setError,
      clearErrors,
      formState: { isSubmitting },
    } = useForm<IAssessmentSetupFormValues>({
      defaultValues: {
        assessmentType: campaign.assessmentType,
        numberOfAssessments: campaign.maxAssessmentNumber ?? 5,
        assessmentPermissionGroups: campaign.assessmentsPermissionGroupFormValues,
        permissionGroups: campaign.filteredPermissionsGroups,
      },
    });

    const watchAssessmentType = useWatch({ control, name: 'assessmentType' });

    const handleSave = async (values: IAssessmentSetupFormValues) => {
      try {
        if (values.assessmentType === AssessmentType.EachRecipient) {
          updateCampaignPermissionGroups(campaign, values);
        } else if (values.assessmentType === AssessmentType.OneRecipientGroup) {
          updateAssessmentPermissionGroups(campaign, datx, values);
          updateModel(campaign, {
            isOneAssessmentPerUser: false,
          });
        }

        await onSave();

        PopAlertSuccess(t('campaign.save.success'));
      } catch (error: any) {
        // eslint-disable-line
        if (isApiFormError(error)) {
          const fieldErrors = parseApiFormError<IAssessmentSetupFormValues>(error);
          fieldErrors.forEach((fieldError) =>
            setError(fieldError.topLevelFieldName, { message: fieldError.message }),
          );
          PopAlertFail(
            t('campaign.save.error'),
            fieldErrors.map((e) => e.message),
          );
        } else if (isApiClientError(error)) {
          ParseAndPopAlertFail(error, t);
        } else {
          PopAlertFail(t('campaign.save.error'));
        }
        throw error;
      }
    };

    const handleDelete = async () => {
      try {
        setIsDeleting.on();
        await onDelete();
        PopAlertSuccess(t('campaign.delete.success'));
      } catch (error: any) {
        // eslint-disable-line
        PopAlertFail(t('campaign.delete.error'));
      } finally {
        setIsDeleting.off();
      }
    };

    const handleNext = async () => {
      try {
        clearErrors();
        await handleSubmit(handleSave)();
        setSelectedTab(InitiateCampaignTabsValueType.ValidationAndMessage);
        PopAlertSuccess(t('campaign.save.success'));
      } catch {
        return;
      }
    };

    return (
      <Box minH="600px" as="form" onSubmit={handleSubmit(handleSave)}>
        <Box width="100%" minH="inherit">
          <Controller
            control={control}
            name="assessmentType"
            render={({ field }) => (
              <RadioGroup {...field}>
                <Radio
                  value={AssessmentType.EachRecipient}
                  disabled={
                    !campaign.isOneAssessmentPerUser &&
                    campaign.campaignStatus === CampaignStatus.Initiated
                  }
                >
                  {t(`campaign.assessmentType.${AssessmentType.EachRecipient}`)}
                </Radio>
                <Radio
                  value={AssessmentType.OneRecipientGroup}
                  disabled={
                    campaign.isOneAssessmentPerUser &&
                    campaign.campaignStatus === CampaignStatus.Initiated
                  }
                >
                  {t(`campaign.assessmentType.${AssessmentType.OneRecipientGroup}`)}
                </Radio>
              </RadioGroup>
            )}
          />

          <Box mt={10}>
            {watchAssessmentType === AssessmentType.EachRecipient ? (
              <EachRecipientForm campaign={campaign} control={control} />
            ) : (
              <OneRecipientGroupForm campaign={campaign} control={control} />
            )}
          </Box>
        </Box>

        <ModalFooter w="100%" padding="0px" mt={50}>
          <StyledButton
            size="lg"
            negative
            className="a-mr-20"
            disabled={isDeleting || isSubmitting}
            isLoading={isDeleting}
            onClick={handleDelete}
          >
            {t('action.delete.label')}
          </StyledButton>
          <Flex ml="auto" alignItems="center">
            <StyledButton
              size="lg"
              kind="secondary"
              type="button"
              className="a-mr-20"
              disabled={isSubmitting || isDeleting}
              onClick={() => setSelectedTab(InitiateCampaignTabsValueType.PermissionGroups)}
            >
              {t('action.back.label')}
            </StyledButton>
            <StyledButton
              size="lg"
              type="submit"
              className="a-mr-20"
              disabled={isSubmitting || isDeleting}
              isLoading={isSubmitting}
            >
              {t('action.save.label')}
            </StyledButton>
            <StyledButton
              size="lg"
              type="button"
              disabled={isSubmitting || isDeleting}
              onClick={handleNext}
            >
              {t('action.next.label')}
            </StyledButton>
          </Flex>
        </ModalFooter>
      </Box>
    );
  },
);
