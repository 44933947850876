import { Attribute } from '@datx/core';

import { Resource } from './Resource';

export class Country extends Resource {
  public static type = 'keydata/countries';

  @Attribute({ isIdentifier: true })
  public readonly alpha2Code!: string;
  @Attribute()
  public readonly alpha3Code!: string;
  @Attribute()
  public readonly name!: string;
}
