import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { ConditionOperator } from 'typings/enums/ConditionOperator';
import { ConditionRuleOperator } from 'typings/enums/ConditionRuleOperator';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { ConditionRule, ConditionRuleGroup } from 'typings/types/FormComponentSettings';

const resolveOr = (values: Array<boolean>) => values.some((value) => value);
const resolveAnd = (values: Array<boolean>) => !values.some((value) => !value);

const resolveIs = (stringValue: string, questionValue?: string | Array<string>) => {
  if (Array.isArray(questionValue)) {
    return questionValue.includes(stringValue);
  }
  return stringValue === questionValue;
};
const resolveIsNot = (stringValue: string, questionValue?: string | Array<string>) => {
  if (Array.isArray(questionValue)) {
    return !questionValue.includes(stringValue);
  }
  return stringValue !== questionValue;
};

const resolveGreaterThan = (stringValue: string, questionValue?: string | Array<string>) => {
  if (Array.isArray(questionValue)) {
    return false;
  }

  if (!questionValue) {
    return true;
  }

  if (isNaN(parseFloat(stringValue))) {
    return stringValue.localeCompare(questionValue) === -1;
  }

  const floatValue = parseFloat(stringValue);
  const floatQuestionValue = parseFloat(questionValue);

  return floatQuestionValue > floatValue;
};
const resolveLesserThan = (stringValue: string, questionValue?: string | Array<string>) => {
  if (Array.isArray(questionValue)) {
    return false;
  }

  if (!questionValue) {
    return true;
  }

  if (isNaN(parseFloat(stringValue))) {
    return stringValue.localeCompare(questionValue) === 1;
  }

  const floatValue = parseFloat(stringValue);
  const floatQuestionValue = parseFloat(questionValue);

  return floatQuestionValue < floatValue;
};
const resolveGreaterOrEqual = (stringValue: string, questionValue?: string | Array<string>) => {
  if (Array.isArray(questionValue)) {
    return false;
  }

  if (!questionValue) {
    return true;
  }

  if (isNaN(parseFloat(stringValue))) {
    return stringValue.localeCompare(questionValue) <= 0;
  }

  const floatValue = parseFloat(stringValue);
  const floatQuestionValue = parseFloat(questionValue);

  return floatQuestionValue >= floatValue;
};
const resolveLesserOrEqual = (stringValue: string, questionValue?: string | Array<string>) => {
  if (Array.isArray(questionValue)) {
    return false;
  }
  if (!questionValue) {
    return true;
  }

  if (isNaN(parseFloat(stringValue))) {
    return stringValue.localeCompare(questionValue) >= 0;
  }

  const floatValue = parseFloat(stringValue);
  const floatQuestionValue = parseFloat(questionValue);

  return floatQuestionValue <= floatValue;
};

const ruleOperatorMap = {
  [ConditionOperator.Is]: resolveIs,
  [ConditionOperator.IsNot]: resolveIsNot,
  [ConditionOperator.GreaterThan]: resolveGreaterThan,
  [ConditionOperator.GreaterThanOrEquals]: resolveGreaterOrEqual,
  [ConditionOperator.LessThan]: resolveLesserThan,
  [ConditionOperator.LessThanOrEquals]: resolveLesserOrEqual,
};

const groupMap = {
  [ConditionRuleOperator.And]: resolveAnd,
  [ConditionRuleOperator.Or]: resolveOr,
};

export const resolveCondition = (
  component: AssessmentFormComponent,
  components: Array<AssessmentFormComponent>,
  skippedQuestionIds: Array<string>,
) => {
  if (!component.conditionGroup) {
    return true;
  }

  const getQuestionValue = (formComponentId: string) => {
    const assessmentQuestionId = `${formComponentId}@${component.assessmentId}`;

    if (skippedQuestionIds.includes(assessmentQuestionId)) {
      return undefined;
    }

    const question = components.find((component) => component.formComponentId === formComponentId);
    if (!question) {
      return undefined;
    }

    if (question.componentType === FormComponentType.Name && question.assessmentComponent.result) {
      // TODO: do we use middle name here?
      // case sensitive?
      // trim outer white-spaces?
      return `${question.assessmentComponent.result?.[0] ?? ''} ${
        question.assessmentComponent.result?.[1] ?? ''
      }${question.assessmentComponent.result?.[2] ?? ''}`;
    }

    if (question.componentType === FormComponentType.Number) {
      // since Appkit InputNumber can't show "no value" and defaults to 0,
      // we need to calculate conditions with that value, otherwise it confuses the users
      return question.assessmentComponent.result?.[0] ?? '0';
    }

    if (question.componentType === FormComponentType.MultipleChoice) {
      return question.assessmentComponent.result;
    }

    return question.assessmentComponent.result?.[0] ?? undefined;
  };

  const resolveRule = (rule: ConditionRule) => {
    const operator = ruleOperatorMap[rule.operator];
    const questionValue = getQuestionValue(rule.componentId);

    return operator(rule.stringValue, questionValue);
  };

  const resolveGroup = (group: ConditionRuleGroup): boolean => {
    const groupOperator = getGroupOperator(group.operator);

    let rulesResult = groupOperator === ConditionRuleOperator.And;
    if (group.conditionRules?.length) {
      rulesResult = groupMap[groupOperator](group.conditionRules.map(resolveRule));
    }

    if (
      (groupOperator === ConditionRuleOperator.And && !rulesResult) ||
      (groupOperator === ConditionRuleOperator.Or && rulesResult)
    ) {
      // we can skip resolving subgroups, since the result is always false/true (for and/or operator) from now
      return rulesResult;
    }

    let groupsResult = groupOperator === ConditionRuleOperator.And;
    if (group.conditionRuleGroups?.length) {
      groupsResult = groupMap[groupOperator](group.conditionRuleGroups.map(resolveGroup));
    }

    return groupsResult;
  };

  return resolveGroup(component.conditionGroup);
};

// AQB sometimes uses 'AND' and sometimes 'And'
const getGroupOperator = (groupOperator: string) => {
  if (groupOperator === 'AND') {
    return ConditionRuleOperator.And;
  }

  if (groupOperator === 'OR') {
    return ConditionRuleOperator.Or;
  }

  return groupOperator as ConditionRuleOperator;
};
