import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Text } from '@chakra-ui/react';
import { Tooltip, Button } from 'appkit-react';
import { JsonGroup } from 'react-awesome-query-builder';

import { FormComponent } from 'models/FormComponent';
import { FormModal } from 'components/modal/FormModal/FormModal';
import { LogicBuilder } from 'components/LogicBuilder/LogicBuilder';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { ILogicBuilderState } from 'typings/interfaces/ILogicBuilderState';
import { LogicPreview } from 'components/LogicPreview/Preview';
import { getInitialState, stateToDto } from 'components/LogicBuilder/LogicBuilder.utils';
import { FormAddonType } from 'typings/enums/FormAddonType';
import { useDatx } from 'hooks/useDatx';
import { compareNumberByNumberArrayFn } from 'utils/compareNumberByNumberArrayFn';
import { compareFormComponentOrder } from 'services/formComponents/compareFormComponentOrder';
import { IFormComponentAddonDto } from 'typings/interfaces/dto/IFormComponentAddonDto';

import { ConditionAddonSettingsValidation } from './ConditionAddonSettingsValidation/ConditionAddonSettingsValidation';

interface IConditionAddonSettingsEditorModalProps {
  addon: IFormComponentAddonDto;
  question: FormComponent;
  onClose(): void;
}
export const ConditionAddonSettingsEditorModal: FC<IConditionAddonSettingsEditorModalProps> = ({
  question,
  onClose,
}) => {
  const datx = useDatx();
  const { t } = useTranslation();
  const { components, disableEdit, updateComponent } = useFormEditor();
  const [logicState, setLogicState] = useState<ILogicBuilderState | undefined>();
  const [validationError, setValidationError] = useState('');

  const [previousQuestions] = useState(() => {
    return components
      .filter(
        (component) =>
          !component.isSection &&
          !component.isQuestionSet &&
          !component.isRange &&
          compareNumberByNumberArrayFn(question.order, component.order) > 0,
      )
      .sort(compareFormComponentOrder);
  });

  const [defaultState] = useState(() => {
    const builderState = JSON.parse(
      question.addons?.find((addon) => addon.addonType === FormAddonType.Condition)?.settingsJson ??
        '{}',
    );

    return {
      builderState,
      previewTree: getInitialState(datx, builderState?.conditionRuleGroup),
    };
  });

  const handleChange = (state: ILogicBuilderState) => {
    setLogicState(state);
  };

  const onSave = async () => {
    if (disableEdit) {
      return;
    }

    if (logicState?.previewTree) {
      const addons =
        question.addons?.filter((addon) => addon.addonType !== FormAddonType.Condition) ?? [];
      addons.push({
        addonType: FormAddonType.Condition,
        settingsJson: JSON.stringify({ conditionRuleGroup: stateToDto(logicState) }),
      });
      question.addons = addons;

      try {
        await updateComponent(question, { displayErrorNotification: false });
        onClose();
      } catch (response: any) { // eslint-disable-line
        setValidationError(response.message);
      }
    }
  };

  return (
    <FormModal
      isOpen
      title={t('form.settings.modal.title', {
        questionNo: question.orderTag,
        questionType: t('form.addon.condition.name'),
      })}
      maxWidth="1200px"
      onClose={onClose}
    >
      <HStack spacing={5} alignItems="center" mb={20}>
        <Text fontSize={20}>{t('form.settings.condition.conditionEditArea')}</Text>
        <Tooltip content={t('form.settings.condition.conditionEditAreaHelp')} placement="top">
          <Text
            as="span"
            className="appkiticon icon-help-question-fill"
            color="brand.orange"
            fontSize="md"
          />
        </Tooltip>
      </HStack>
      <LogicBuilder
        disabled={disableEdit}
        defaultState={defaultState?.builderState.conditionRuleGroup}
        components={previousQuestions}
        onChange={handleChange}
      />
      <LogicPreview
        mt={40}
        tree={logicState?.previewTree || (defaultState.previewTree as JsonGroup)}
      />
      {validationError && <ConditionAddonSettingsValidation mt={40} error={validationError} />}
      <HStack spacing={12} justifyContent="flex-end" mt={40}>
        <Button size="lg" kind="secondary" onClick={onClose}>
          {t('action.cancel.label')}
        </Button>
        <Button size="lg" onClick={onSave}>
          {t('action.save.label')}
        </Button>
      </HStack>
    </FormModal>
  );
};
