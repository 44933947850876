import { FC } from 'types';
import { useState } from 'react';
import { Tabs } from 'appkit-react';
import { useTranslation } from 'react-i18next';

import { StyledTab } from 'components/core/StyledAppKit/StyledAppKit';
import { CampaignSettingsForm } from 'forms/InitiateCampaign/CampaignSettingsForm/CampaignSettingsForm';
import { ValidationAndMessageForm } from 'forms/InitiateCampaign/ValidationAndMessageForm/ValidationAndMessageForm';
import { PermissionGroupsForm } from 'forms/InitiateCampaign/PermissionGroupsForm/PermissionGroupsForm';
import { AssessmentSetup } from 'forms/InitiateCampaign/AssessmentSetupForm/AssessmentSetupForm';
import { Campaign } from 'models/Campaign';

interface ICampaignModalTabsProps {
  campaign: Campaign;
  onSave(): Promise<void>;
  onDelete(): Promise<void>;
  onCancel(): void;
}

export enum InitiateCampaignTabsValueType {
  CampaignSettings = 'CampaignSettings',
  PermissionGroups = 'PermissionGroups',
  AssessmentSetup = 'AssessmentSetup',
  ValidationAndMessage = 'ValidationMessage',
}

export const CampaignModalTabs: FC<ICampaignModalTabsProps> = ({
  campaign,
  onSave,
  onDelete,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<InitiateCampaignTabsValueType>(
    InitiateCampaignTabsValueType.CampaignSettings,
  );

  const handleTabChange = (_: Event, value: InitiateCampaignTabsValueType) => {
    setSelectedTab(value);
  };

  return (
    <Tabs value={selectedTab} onChange={handleTabChange} size="md">
      <StyledTab
        label={t(`campaign.tabs.${InitiateCampaignTabsValueType.CampaignSettings}`).toUpperCase()}
        value={InitiateCampaignTabsValueType.CampaignSettings}
        pt={20}
      >
        <CampaignSettingsForm
          campaign={campaign}
          onSave={onSave}
          onDelete={onDelete}
          setSelectedTab={setSelectedTab}
        />
      </StyledTab>
      <StyledTab
        label={t(`campaign.tabs.${InitiateCampaignTabsValueType.PermissionGroups}`).toUpperCase()}
        value={InitiateCampaignTabsValueType.PermissionGroups}
        pt={20}
      >
        <PermissionGroupsForm
          campaign={campaign}
          onSave={onSave}
          onDelete={onDelete}
          setSelectedTab={setSelectedTab}
        />
      </StyledTab>
      {!campaign.isDraft && (
        <StyledTab
          label={t(`campaign.tabs.${InitiateCampaignTabsValueType.AssessmentSetup}`).toUpperCase()}
          value={InitiateCampaignTabsValueType.AssessmentSetup}
          pt={20}
        >
          {selectedTab === InitiateCampaignTabsValueType.AssessmentSetup && (
            // need to rerender form default values since they are calculated from campaign model
            <AssessmentSetup
              campaign={campaign}
              onSave={onSave}
              onDelete={onDelete}
              setSelectedTab={setSelectedTab}
            />
          )}
        </StyledTab>
      )}
      {!campaign.isDraft && (
        <StyledTab
          label={t(
            `campaign.tabs.${InitiateCampaignTabsValueType.ValidationAndMessage}`,
          ).toUpperCase()}
          value={InitiateCampaignTabsValueType.ValidationAndMessage}
          pt={20}
        >
          <ValidationAndMessageForm
            campaign={campaign}
            onSave={onSave}
            onCancel={onCancel}
            onDelete={onDelete}
            setSelectedTab={setSelectedTab}
          />
        </StyledTab>
      )}
    </Tabs>
  );
};
