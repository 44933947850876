import { ToolbarIconButton } from 'components/core/ToolbarIconButton/ToolbarIconButton';
import { useAssessmentForm } from 'context/AssessmentContext/AssessmentContextProvider';
import { toggleBookmarked } from 'context/AssessmentContext/AssessmentContext.utils';
import { ToolbarActionIcon } from 'typings/enums/ToolbarActionIcon';

export const ToggleBookmarkedIconButton = () => {
  const { isBookmarkFilterOn, dispatch } = useAssessmentForm();

  const icon = isBookmarkFilterOn ? ToolbarActionIcon.BookmarkActive : ToolbarActionIcon.Bookmark;

  return <ToolbarIconButton action={icon} onClick={() => toggleBookmarked(dispatch)} />;
};
