import { useLocation, useRouteMatch } from 'react-router-dom';
import { useBoolean } from '@chakra-ui/react';
import { useSWRConfig } from 'swr';
import { useTranslation } from 'react-i18next';

import { Room } from 'models/Room';
import { RoomFolder } from 'models/RoomFolder';
import { useDatx } from 'hooks/useDatx';
import { useMutate } from 'hooks/useMutate';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { Form } from 'models/Form';
import { UpsertRoomDTO } from 'typings/types/dto/RoomDTO';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';

export const useCurrentFolder = () => {
  const datx = useDatx();
  const location = useLocation();

  const currentFolderId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const currentFolder = datx.findOne(RoomFolder, currentFolderId);

  return currentFolder;
};

export const useNestedFolders = (rootFolders: Array<RoomFolder>, searchQuery = '') => {
  const currentFolder = useCurrentFolder();

  const filterFolders = (folders: Array<RoomFolder>) => {
    return folders.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
  };

  if (currentFolder) {
    return filterFolders(currentFolder?.childRoomFolders);
  }

  return filterFolders(rootFolders);
};

export const useNestedForms = (forms?: Array<Form>, searchQuery = '') => {
  const currentFolder = useCurrentFolder();

  if (!forms) {
    return [];
  }

  if (currentFolder) {
    return forms
      .filter((form) => form.parentFolderId === currentFolder.id)
      .filter((form) => form.name.toLowerCase().includes(searchQuery.toLocaleLowerCase()));
  }

  return forms
    .filter((form) => form.parentFolderId === null)
    .filter((form) => form.name.toLowerCase().includes(searchQuery.toLocaleLowerCase()));
};

export const useRoomId = () => {
  const { url } = useRouteMatch();
  const pathRegex = /(rooms)\/([\w+, -]+)/gi;
  const [, , roomId] = pathRegex.exec(url) as RegExpExecArray;

  return roomId;
};

export const useUpdateRoom = (roomId: string) => {
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useBoolean(false);
  const saveMutation = useMutate(Room.update(roomId), Room.type);

  const save = async (roomJSON: UpsertRoomDTO) => {
    setIsSaving.on();
    try {
      await saveMutation.mutate(roomJSON);
      PopAlertSuccess(t('room.successMessages.updateRoom'));
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('room.errors.updatingRoom.main'), response.errors?.name);
      } else {
        PopAlertFail(t('room.errors.updatingRoom.unexpected'), response.message);
      }
    } finally {
      setIsSaving.off();
    }
  };
  return { save, isSaving };
};

export const useUpdateRoomUsers = (roomId: string) => {
  const { mutate } = useSWRConfig();
  const dependencyHeaders = useDependencyHeaders();
  const [isSaving, setIsSaving] = useBoolean(false);
  const { t } = useTranslation();

  const method = HttpMethod.Put;
  const url = `rooms/${roomId}/user`;

  const save = async (roomJSON: UpsertRoomDTO) => {
    try {
      setIsSaving.on();
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify(roomJSON),
      });
      PopAlertSuccess(t('room.successMessages.updateRoom'));
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('room.errors.updatingRoom.main'), response.errors?.name);
      } else {
        PopAlertFail(t('room.errors.updatingRoom.unexpected'), response.message);
      }
    } finally {
      mutate(`rooms@${dependencyHeaders['x-site-id']}`);
      setIsSaving.off();
    }
  };

  return { save, isSaving };
};
