import { useEffect, useState } from 'react';
import { Checkbox, Tabs } from 'appkit-react';
import { Box, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FC } from 'types';

import { FormEditorSidebarItemType } from 'typings/enums/FormEditorSidebarItemType';
import { StyledTab } from 'components/core/StyledAppKit/StyledAppKit';
import { Form } from 'models/Form';
import { useCurrentUserEmail } from 'hooks/useCurrentUserEmail';
import { useUpdateRoomForm } from 'hooks/useRoomForms';
import { useConclusionWithMutations } from 'hooks/useConclusionWithMutations';

import { FormEditorSidebarMenu } from './components/FormEditorSidebarMenu/FormEditorSidebarMenu';

enum SidebarCheckboxValueType {
  Approver = 'approver',
  Conclusion = 'conclusion',
  Rating = 'rating',
}

enum SidebarTabsValueType {
  Components = 'components',
  Addons = 'addons',
}

interface IFormEditorSidebarProps {
  form: Form | undefined;
  formId: string;
  roomId: string;
  isEditing: boolean;
}
export const FormEditorSidebar: FC<IFormEditorSidebarProps> = ({
  form,
  formId,
  roomId,
  isEditing,
}) => {
  const { t } = useTranslation();

  const [isRemovingAll, setIsRemovingAll] = useState(false);
  const currentUserEmail = useCurrentUserEmail();
  const { save } = useUpdateRoomForm(formId, roomId);
  const { removeAll, data, isInitialLoad } = useConclusionWithMutations(
    roomId,
    formId,
    setIsRemovingAll,
  );
  const [isConclusionEnabled, setIsConclusionEnabled] = useState(false);
  const { hasApproverFlag = false, isEditable = false } = form ?? {};
  const disabledEditing = isEditing || !isEditable || !form?.hasEditor(currentUserEmail);

  const handleCheckboxChange = async (
    event: { target: { checked: boolean } },
    value: SidebarCheckboxValueType,
  ) => {
    if (form && value === SidebarCheckboxValueType.Approver) {
      await save({ ...form?.updateDTO, hasApprover: event.target.checked });
    }

    if (value === SidebarCheckboxValueType.Conclusion) {
      if (!event.target.checked && Boolean(data?.length)) {
        await removeAll();
        setIsConclusionEnabled(false);

        return;
      }

      setIsConclusionEnabled(event.target.checked);
    }
  };

  useEffect(() => {
    if (data?.length) {
      setIsConclusionEnabled(true);
    }
  }, [data]);

  return (
    <Box>
      <HStack justify="space-between" mb={20}>
        <Checkbox
          value={SidebarCheckboxValueType.Approver}
          checked={hasApproverFlag}
          onChange={handleCheckboxChange}
          disabled={disabledEditing}
        >
          {t('form.options.approver')}
        </Checkbox>
        <Checkbox
          value={SidebarCheckboxValueType.Conclusion}
          checked={isConclusionEnabled}
          disabled={disabledEditing || isRemovingAll}
          uncheckedIcon={
            isRemovingAll || isInitialLoad ? (
              <Box w="16px" h="16px" className="a-primary a-loading" />
            ) : undefined
          }
          checkedIcon={
            isRemovingAll || isInitialLoad ? (
              <Box w="16px" h="16px" className="a-primary a-loading" />
            ) : undefined
          }
          onChange={handleCheckboxChange}
        >
          {t('form.options.conclusion')}
        </Checkbox>
        <Checkbox value={SidebarCheckboxValueType.Rating} disabled>
          {t('form.options.rating')}
        </Checkbox>
      </HStack>
      <Tabs defaultValue={SidebarTabsValueType.Components} size="lg">
        <StyledTab
          label={t('form.options.components').toUpperCase()}
          value={SidebarTabsValueType.Components}
          pt={24}
        >
          <FormEditorSidebarMenu
            type={FormEditorSidebarItemType.Components}
            disabled={disabledEditing}
          />
        </StyledTab>
        <StyledTab
          label={t('form.options.addons').toUpperCase()}
          value={SidebarTabsValueType.Addons}
          pt={24}
        >
          <FormEditorSidebarMenu
            type={FormEditorSidebarItemType.Addons}
            disabled={disabledEditing}
          />
        </StyledTab>
      </Tabs>

      {isConclusionEnabled && (
        <Tabs defaultValue="conclusion" size="lg" className="a-mt-20">
          <StyledTab
            label={t('form.options.conclusion').toUpperCase()}
            value="conclusion"
            sx={{ pt: 24 }}
          >
            <FormEditorSidebarMenu
              type={FormEditorSidebarItemType.Conclusions}
              disabled={disabledEditing}
            />
          </StyledTab>
        </Tabs>
      )}
    </Box>
  );
};
