import { HStack, Spacer } from '@chakra-ui/react';
import { FC } from 'types';

import { FormApproversButton } from 'components/form-editor/FormApproversButton/FormApproversButton';
import { FormUserManagementButton } from 'components/form-editor/FormUserManagementButton/FormUserManagementButton';
import { FormDeleteButton } from 'components/form-editor/FormDeleteButton/FormDeleteButton';
import { Form } from 'models/Form';
import { useCurrentUserEmail } from 'hooks/useCurrentUserEmail';

import { FormHistoryButton } from '../FormHistoryButton/FormHistoryButton';
import { FormEditorViewIconButton } from '../FormEditorViewIconButton/FormEditorViewIconButton';
import { FormEditorExportIconButton } from '../FormEditorExportIconButton/FormEditorExportIconButton';

interface IFormEditorToolbarProps {
  form: Form | undefined;
  roomId: string;
  formId: string;
  isEditing: boolean;
}
export const FormEditorToolbar: FC<IFormEditorToolbarProps> = ({
  form,
  formId,
  isEditing,
  ...rest
}) => {
  const currentUserEmail = useCurrentUserEmail();
  const userIsEditor = Boolean(form?.hasEditor?.(currentUserEmail));
  const { isEditable = false, hasApproverFlag = false, isActive = false } = form ?? {};
  const disabled = isEditing || !isEditable || !userIsEditor;

  return (
    <HStack spacing={12} color="brand.orange">
      <FormEditorViewIconButton />
      <Spacer w="30px" />
      <FormUserManagementButton disabled={!userIsEditor} formId={formId} {...rest} />
      <FormApproversButton disabled={!hasApproverFlag || disabled} formId={formId} {...rest} />
      <FormEditorExportIconButton formId={formId} />
      <FormHistoryButton formId={formId} />
      <FormDeleteButton
        disabled={isEditing || !isActive || !userIsEditor}
        formId={formId}
        {...rest}
      />
    </HStack>
  );
};
