import { Box, Heading, useBoolean } from '@chakra-ui/react';
import { FC } from 'types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextEditor } from 'components/core/TextEditor/TextEditor';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { ReviewComment } from 'typings/types/ReviewComment';
import { IAsyncResult } from 'typings/interfaces/IAsyncResult';

type EntityReviewFormFields = {
  text: string;
};

interface IReviewAddCommentProps {
  onSubmit(reviewComment: ReviewComment): Promise<IAsyncResult>;
}

export const ReviewAddComment: FC<IReviewAddCommentProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useBoolean();
  const { control, handleSubmit, reset } = useForm<EntityReviewFormFields>({
    defaultValues: {
      text: '',
    },
  });

  const handleSave = async (comment: ReviewComment) => {
    setIsSaving.on();

    await onSubmit(comment);

    setIsSaving.off();
    reset();
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)} maxW="100%" w="100%">
      <Heading as="h3" size="lg" mb={20}>
        {t('review.comments.label')}
      </Heading>
      <Controller
        control={control}
        name="text"
        render={({ field: { value, ref, ...restField } }) => {
          return <TextEditor textEditorRef={ref} {...restField} initialHtmlContent={value} />;
        }}
      />
      <FormActions width="100%">
        <FormButtonSubmit isLoading={isSaving}>{t('review.comments.button')}</FormButtonSubmit>
      </FormActions>
    </Box>
  );
};
