import { RegisterOptions, Validate } from 'react-hook-form';
import { TFunction } from 'react-i18next';

import { isEmail } from 'utils/isEmail';

export const userSearchValidation = (
  t: TFunction,
  isUserAvailable: (value: string) => boolean,
  isUserAdded: (value: string) => boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  otherValidationRules?: Record<string, Validate<any>>,
): RegisterOptions => {
  return {
    validate: {
      isEmail: (value: string) =>
        isEmail(value) || (t('userManagementSearch.validation.email') as string),
      isUserAvailable: (value: string) =>
        isUserAvailable(value) || (t('userManagementSearch.validation.notFound') as string),
      isUserAlreadyAdded: (value: string) => {
        return !isUserAdded(value) || (t('userManagementSearch.validation.added') as string);
      },
      ...otherValidationRules,
    },
  };
};
