import { useBoolean } from '@chakra-ui/react';
import { useEffect, useReducer } from 'react';

import { useCurrentUserEmail } from 'hooks/useCurrentUserEmail';
import { IAssessmentBase } from 'typings/interfaces/IAssessmentBase';
import { useRevalidateAssessment } from 'hooks/useAssessment';
import { useDatx } from 'hooks/useDatx';

import {
  AssessmentStateReducerAction,
  getSkippedFormComponents,
  recalculateSkippedComponents,
  stateReducer,
} from './AssessmentContext.utils';

export const useAssessmentState = (
  assessmentId: string | undefined,
  assessment: IAssessmentBase | undefined,
  timestamp: string | undefined,
) => {
  const [
    {
      isInitiator,
      isLoading,
      isBookmarkFilterOn,
      skippedComponents,
      shouldRevalidate,
      pendingUpdatingAnswer,
      updatingComponentIds,
    },
    dispatch,
  ] = useReducer(stateReducer, {
    isLoading: false,
    isInitiator: false,
    isBookmarkFilterOn: false,
    skippedComponents: [],
    shouldRevalidate: false,
    pendingUpdatingAnswer: 0,
    updatingComponentIds: [],
  });

  const [isRevalidating, setIsRevalidating] = useBoolean();
  const currentUserEmail = useCurrentUserEmail();

  const datx = useDatx();
  useEffect(() => {
    if (assessment?.id) {
      dispatch({
        type: AssessmentStateReducerAction.initOnLoad,
        payload: {
          isInitiator: assessment.hasInitiator(currentUserEmail),
          skippedComponents: getSkippedFormComponents(datx, assessment.id),
        },
      });
    }
  }, [datx, assessment, currentUserEmail]);

  const isEditable = Boolean(assessment?.isEditable && assessment?.hasRecipient(currentUserEmail));

  const revalidate = useRevalidateAssessment(assessment, timestamp);
  useEffect(() => {
    if (shouldRevalidate) {
      setIsRevalidating.on();
      dispatch({ type: AssessmentStateReducerAction.resetShouldRevalidate });
      revalidate().finally(() => {
        recalculateSkippedComponents(dispatch, datx, assessmentId);
        setIsRevalidating.off();
      });
    }
  }, [assessmentId, datx, revalidate, setIsRevalidating, shouldRevalidate]);

  const isUpdatingResult = pendingUpdatingAnswer > 0;

  return {
    assessmentId,
    assessment,
    isInitiator,
    isEditable,
    isLoading,
    isBookmarkFilterOn,
    skippedComponents,
    updatingComponentIds,
    isUpdatingResult,
    isRevalidating,
    timestamp,
    dispatch,
  };
};
