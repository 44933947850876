import { Attribute } from '@datx/core';
import { computed } from 'mobx';
import { UpsertRoomUserDTO } from 'typings/types/dto/RoomUserDTO';
import { RoomUserRole } from '../typings/enums/RoomUserRole';
import { Resource } from './Resource';

export class RoomUser extends Resource {
  public static type = 'room_user';

  @Attribute()
  public readonly username!: string;

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public userId!: string;

  @Attribute()
  public email!: string;

  @Attribute({ defaultValue: RoomUserRole.Member })
  public roomUserRole!: RoomUserRole;

  @Attribute()
  public expirationDate!: string | null;

  @computed
  public get displayName() {
    return this.username === this.email ? this.email : `${this.username} (${this.email})`;
  }

  @computed
  public get upsertDTO(): UpsertRoomUserDTO {
    return {
      id: this.userId,
      roomUserRole: this.roomUserRole,
      expirationDate: this.expirationDate,
    };
  }
}
