import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { FormAction } from 'typings/enums/FormAction';
import { InitiateCampaignModal } from 'components/campaign/InitiateCampaignModal/InitiateCampaignModal';
import { useModal } from 'hooks/useModal';

interface IFormEditorInitiateButtonProps {
  roomId: string;
  formId: string;
  isLoading?: boolean;
  disabled?: boolean;
}
export const FormEditorInitiateButton: FC<IFormEditorInitiateButtonProps> = ({
  disabled,
  isLoading,
  ...rest
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useModal();

  return (
    <Fragment>
      <StyledButton
        size="md"
        minWidth="50px"
        disabled={disabled}
        isLoading={isLoading}
        onClick={onOpen}
      >
        {t(`form.action.${FormAction.Initiate}`)}
      </StyledButton>
      {isOpen && <InitiateCampaignModal onCancel={onClose} {...rest} />}
    </Fragment>
  );
};
