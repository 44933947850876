import { BoxProps } from '@chakra-ui/layout';
import ReactTagInput, { ReactTagInputProps } from '@pathofdev/react-tag-input';
import React from 'react';
import { FC } from 'types'

import { StyledReactTagInputWrapper } from './TagInput.elements';

interface ITagInputProps extends ReactTagInputProps, Omit<BoxProps, 'onChange'> {}

export const TagInput: FC<ITagInputProps> = ({ sx, ...props }) => {
  return (
    <StyledReactTagInputWrapper sx={sx}>
      <ReactTagInput {...props} />
    </StyledReactTagInputWrapper>
  );
};
