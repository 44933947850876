import { FC } from 'types';

import { TableCellRow } from 'typings/types/TableCellRow';
import { RoomCellTypeFolder } from '../RoomCellTypeFolder/RoomCellTypeFolder';
import { RoomCellTypeForm } from '../RoomCellTypeForm/RoomCellTypeForm';

export const RoomCellType: FC<TableCellRow> = (props) => {
  return props.original.meta.type === 'forms' ? (
    <RoomCellTypeForm form={props.original} />
  ) : (
    <RoomCellTypeFolder />
  );
};
