import { Fragment, useEffect, useState } from 'react';
import { FC } from 'types'

import { RoomUser } from 'models/RoomUser';
import { Room } from 'models/Room';
import { RoomUsersSearch } from 'components/room-users/RoomUsersSearch/RoomUsersSearch';
import { RoomUsersListView } from 'components/room-users/RoomUsersListView/RoomUsersListView';
import { RoomUserRole } from 'typings/enums/RoomUserRole';
import { UpsertRoomUserDTO } from 'typings/types/dto/RoomUserDTO';

interface IRoomMembersManagementProps {
  room: Room;
  readonly?: boolean;
  onChange(roomUsers: Array<UpsertRoomUserDTO>): void;
}

export const RoomMembersManagement: FC<IRoomMembersManagementProps> = ({
  room,
  readonly,
  onChange,
}) => {
  const [roomUsers, setRoomUsers] = useState<Array<RoomUser>>(() => room.roomUsers);

  useEffect(() => {
    const roomUsersDto: Array<UpsertRoomUserDTO> = roomUsers.map((roomUser) => ({
      id: roomUser.id,
      userId: roomUser.userId,
      roomUserRole: roomUser.roomUserRole || RoomUserRole.Member,
      expirationDate: roomUser.expirationDate || null,
    }));
    onChange(roomUsersDto);
  }, [onChange, roomUsers]);

  const handleAdd = (user: RoomUser) => {
    if (!user) {
      return;
    }
    setRoomUsers((prevState) => [...prevState, user]);
  };

  const handleRemove = (userId: string) => {
    setRoomUsers((prevState) => prevState.filter((user) => user.userId !== userId));
  };

  const getAddedUserById = (userId: string) => {
    let roomUserIndex = -1;
    const roomUser = roomUsers.find((user, index) => {
      if (user.userId === userId) {
        roomUserIndex = index;
        return true;
      }
      return false;
    });

    return { roomUserIndex, roomUser };
  };

  const handleSetUserRole = (userId: string, role: RoomUserRole) => {
    const { roomUserIndex, roomUser } = getAddedUserById(userId);
    if (!roomUser || roomUserIndex === -1) {
      return;
    }
    roomUser.roomUserRole = role;
    setRoomUsers([
      ...roomUsers.slice(0, roomUserIndex),
      roomUser,
      ...roomUsers.slice(roomUserIndex + 1),
    ]);
  };

  const handleSetUserExpiration = (userId: string, date: Date) => {
    const { roomUserIndex, roomUser } = getAddedUserById(userId);
    if (!roomUser || roomUserIndex === -1) {
      return;
    }
    roomUser.expirationDate = date?.toISOString() ?? null;
    setRoomUsers([
      ...roomUsers.slice(0, roomUserIndex),
      roomUser,
      ...roomUsers.slice(roomUserIndex + 1),
    ]);
  };

  return (
    <Fragment>
      <RoomUsersSearch
        roomId={room.id}
        disabled={readonly}
        addedUsers={roomUsers}
        onAdd={handleAdd}
      />
      <RoomUsersListView
        room={room}
        disabled={readonly}
        addedUsers={roomUsers}
        onChangeRole={handleSetUserRole}
        onChangeExpiration={handleSetUserExpiration}
        onRemoveUser={handleRemove}
      />
    </Fragment>
  );
};
