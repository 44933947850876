import { FC } from 'types';
import { Box, VStack, HStack, Flex, useBoolean } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button } from 'appkit-react';
import { updateModel } from '@datx/core';
import { observer } from 'mobx-react';

import { ModalFooter } from 'components/modal/Modal/components/ModalFooter';
import { Campaign } from 'models/Campaign';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { InitiateCampaignTabsValueType } from 'components/campaign/CampaignModalTabs/CampaignModalTabs';
import { ParseAndPopAlertFail } from 'services/ParseAndPopAlertFail';

import { ICampaignSettingsFormProps } from '../CampaignSettingsForm/CampaignSettingsForm';

import { PermissionGroupsFormGroups } from './component/PermissionGroupsFormGroups/PermissionGroupsFormGroups';
import { PermissionGroupsFormTable } from './component/PermissionGroupsFormTable/PermissionGroupsFormTable';
import { isApiClientError } from 'utils/isApiClientError';
import { isApiFormError } from 'utils/isApiFormError';
import { parseApiFormError } from 'utils/parseApiFormError';

interface IPermissionGroupsFormProps {
  campaign: Campaign;
  onDelete(): Promise<void>;
  onSave(): Promise<void>;
  setSelectedTab(tab: InitiateCampaignTabsValueType): void;
}

export const PermissionGroupsForm: FC<IPermissionGroupsFormProps> = observer(
  ({ campaign, onSave, onDelete, setSelectedTab }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useBoolean(false);
    const [isDeleting, setIsDeleting] = useBoolean(false);

    const deletePermissionGroup = (groupId: string) => {
      const permissionGroups = campaign.permissionGroups.filter((group) => group.id !== groupId);

      updateModel(campaign, {
        permissionGroups,
      });
    };

    const addPermissionGroup = (value: string) => {
      updateModel(campaign, {
        permissionGroups: [
          ...campaign.permissionGroups,
          {
            name: value,
            componentIds: campaign.components?.map((component) => component.id),
          },
        ],
      });
    };

    const editPermissionGroup = (groupId: string, value: string) => {
      const permissionGroups = campaign.permissionGroups.map((group) => {
        if (group.id === groupId) {
          return { ...group, name: value };
        } else {
          return group;
        }
      });

      updateModel(campaign, {
        permissionGroups,
      });
    };

    const handleSave = async () => {
      setIsLoading.on();
      try {
        await onSave();
        PopAlertSuccess(t('campaign.save.success'));
      } catch (error: any) { // eslint-disable-line
        if (isApiFormError(error)) {
          const fieldErrors = parseApiFormError<ICampaignSettingsFormProps>(error);
          PopAlertFail(
            t('campaign.save.error'),
            fieldErrors.map((e) => e.message),
          );
        } else if (isApiClientError(error)) {
          ParseAndPopAlertFail(error, t);
        } else {
          PopAlertFail(t('campaign.save.error'));
        }
        throw error;
      } finally {
        setIsLoading.off();
      }
    };

    const handleDelete = async () => {
      setIsDeleting.on();
      await onDelete();
      setIsDeleting.off();
    };

    const handleNext = async () => {
      try {
        await handleSave();
        setSelectedTab(InitiateCampaignTabsValueType.AssessmentSetup);
      } catch {
        return;
      }
    };

    return (
      <Box as="form" minH="600px">
        <Flex width="100%" height="100%">
          <VStack align="flex-start" width="30%" mr="20px">
            <PermissionGroupsFormGroups
              permissionGroups={campaign.permissionGroups}
              onDelete={deletePermissionGroup}
              onAdd={addPermissionGroup}
              onEdit={editPermissionGroup}
            />
          </VStack>

          <HStack align="flex-start" width="70%">
            <PermissionGroupsFormTable campaign={campaign} isLoading={false} />
          </HStack>
        </Flex>

        <ModalFooter w="100%" padding="0px" mt="50px">
          <Button
            size="lg"
            negative
            className="a-mr-20"
            disabled={isDeleting || isLoading}
            isLoading={isDeleting}
            onClick={handleDelete}
          >
            {t('action.delete.label').toUpperCase()}
          </Button>
          <Flex ml="auto" alignItems="center">
            <Button
              size="lg"
              kind="secondary"
              type="button"
              className="a-mr-20"
              disabled={isLoading || isDeleting}
              onClick={() => setSelectedTab(InitiateCampaignTabsValueType.CampaignSettings)}
            >
              {'back'.toUpperCase()}
            </Button>
            <Button
              size="lg"
              type="button"
              className="a-mr-20"
              disabled={isLoading || isDeleting}
              isLoading={isLoading}
              onClick={handleSave}
            >
              {t('action.save.label').toUpperCase()}
            </Button>
            <Button
              size="lg"
              type="button"
              disabled={isLoading || isDeleting || campaign.isDraft}
              onClick={handleNext}
            >
              {t('action.next.label').toUpperCase()}
            </Button>
          </Flex>
        </ModalFooter>
      </Box>
    );
  },
);
