import { HStack, Spacer, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { ToggleExpandButton } from 'components/core/ToggleExpandButton/ToggleExpandButton';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';

import { ConclusionCloseButton } from '../ConclusionCloseButton/ConclusionCloseButton';

interface IFormEditorConclusionGroupHeaderProps {
  isExpanded: boolean;
  onToggleExpand(): void;
  onDelete(): void;
}

export const FormEditorConclusionGroupHeader: FC<IFormEditorConclusionGroupHeaderProps> = ({
  isExpanded,
  onToggleExpand,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { disableEdit } = useFormEditor();

  return (
    <HStack
      spacing="20px"
      justify="space-between"
      sx={{
        height: '42px',
        mb: isExpanded || '18px',
        borderBottom: ({ colors }) => `2px solid ${colors.grey.lvl3}`,
      }}
    >
      <Text size="lg" pl={10} textTransform="uppercase">
        {t('form.conclusions.Conclusion')}
      </Text>
      <Spacer />
      <ToggleExpandButton isExpanded={isExpanded} onClick={onToggleExpand} />
      {!disableEdit && <ConclusionCloseButton onClick={onDelete} />}
    </HStack>
  );
};
