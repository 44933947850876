import { useRouteMatch } from 'react-router-dom';

import { asOneGuid, asZeroOrMoreGuidWithLeadingSlash } from 'utils/regex';

export const useRoomFolderMatch = () => {
  const matchWithFolders = useRouteMatch<{
    folderId: string;
    roomId: string;
    parentFoldersIds: string;
  }>(
    `/rooms/:roomId${asOneGuid}:parentFoldersIds${asZeroOrMoreGuidWithLeadingSlash}/:folderId${asOneGuid}`,
  );
  const matchRoom = useRouteMatch<{ folderId: string; roomId: string; parentFoldersIds: string }>(
    `/rooms/:roomId${asOneGuid}`,
  );

  return matchWithFolders || matchRoom;
};
