import { Attribute, Model } from '@datx/core';
import { computed } from 'mobx';
import { Tenant } from './Tenant';

export class Profile extends Model {
  public static type = 'profile';

  @Attribute({ isIdentifier: true })
  public uid!: string;

  @Attribute()
  public givenName!: string;

  @Attribute()
  public familyName!: string;

  @Attribute()
  public name!: string;

  // eslint-disable-next-line
  @Attribute({ parse: (_, data: Record<string, any>) => data.upn })
  public email!: string;

  @Attribute({ toMany: Tenant, defaultValue: [] })
  public tenants!: Array<Tenant>;

  @Attribute({ toOne: Tenant })
  public activeTenant!: Tenant;

  @computed
  public get hasTenants(): boolean {
    return this.tenants.length > 0;
  }

  @computed
  public get favoriteTenant(): Tenant | undefined {
    return this.tenants.find((tenant) => tenant.isFavorite);
  }
}
