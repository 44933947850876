export const StyledSelect = {
  variants: {
    transparent: {
      '&.a-dropdown > .select-toggle': {
        backgroundColor: 'transparent !important',
        borderColor: 'transparent !important',
      },
    },
  },
};
