import { FC } from 'types';
import { useFormContext } from 'react-hook-form';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { InputNumberMaskedFieldHorizontal } from 'fields/InputNumberField/InputNumberField';

import { getLabel, getValidSettings } from './AssessmentNumberField.utils';

interface IAssessmentNumberFieldProps {
  assessmentQuestion: AssessmentFormComponent;
  disabled?: boolean;
  index?: number;
}

export const AssessmentNumberField: FC<IAssessmentNumberFieldProps> = ({
  assessmentQuestion,
  disabled,
  index = 0,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const settings = getValidSettings(assessmentQuestion.settingsJson);
  if (!settings) {
    throw new Error(
      `Invalid assessment field settings in question "${assessmentQuestion.orderTag}" of type ${assessmentQuestion.componentType} {${assessmentQuestion.id}}`,
    );
  }
  const label = getLabel(settings);
  const step = settings.decimalDigits
    ? `0.${'1'.padStart(settings.decimalDigits, '0')}`
    : undefined;

  return (
    <Flex w="350px" mx="auto" align="center">
      <InputNumberMaskedFieldHorizontal
        control={control}
        name={assessmentQuestion.fieldName(index)}
        integerOnly={settings.decimalDigits === 0}
        numberOfDecimals={settings.decimalDigits}
        min={-Infinity}
        max={Infinity}
        step={step}
        disabled={disabled}
        kind="separate"
        rules={{
          required: assessmentQuestion.isRequired ? t<string>('validation.required') : false,
        }}
      />
      <Text ml={16}>{label}</Text>
    </Flex>
  );
};
