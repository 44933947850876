import { FC } from 'types';
import { observer } from 'mobx-react';
import { Box } from '@chakra-ui/react';

import { AssessmentQuestionSetField } from 'components/assessment-fields/AssessmentQuestionSetField/AssessmentQuestionSetField';
import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { useAssessmentForm } from 'context/AssessmentContext/AssessmentContextProvider';

import { AssessmentQuestionCard } from '../AssessmentQuestionCard/AssessmentQuestionCard';
import { AssessmentField } from '../AssessmentField/AssessmentField';
import { AssessmentFieldArray } from '../AssessmentFieldArray/AssessmentFieldArray';
import { AssessmentFieldFormWrapper } from '../AssessmentFieldFormWrapper/AssessmentFieldFormWrapper';

import { useRenderNumberKey } from './AssessmentQuestion.hooks';

interface IAssessmentQuestionProps {
  question: AssessmentFormComponent;
  disabled?: boolean;
}

export const AssessmentQuestion: FC<IAssessmentQuestionProps> = observer(
  ({ disabled, question }) => {
    const { isQuestionSet, canHaveMoreAnswers } = question;
    const { isRevalidating, updatingComponentIds } = useAssessmentForm();

    const isUpdating = updatingComponentIds.includes(question.id);
    const questionKeyNumber = useRenderNumberKey(question.result, isUpdating, isRevalidating);
    const questionKey = `${question.fieldName()}#${questionKeyNumber}`;

    return (
      <AssessmentQuestionCard assessmentQuestion={question} disabled={disabled}>
        {isQuestionSet && <AssessmentQuestionSetField questionSet={question} disabled={disabled} />}
        {!isQuestionSet && canHaveMoreAnswers && (
          <AssessmentFieldFormWrapper question={question} key={questionKey}>
            <AssessmentFieldArray
              assessmentQuestion={question}
              disabled={disabled}
              render={(assessmentQuestion: AssessmentFormComponent, index: number) => (
                <AssessmentField
                  assessmentQuestion={assessmentQuestion}
                  index={index}
                  disabled={disabled}
                />
              )}
            />
          </AssessmentFieldFormWrapper>
        )}
        {!isQuestionSet && !canHaveMoreAnswers && (
          <Box mt={20} mr={45}>
            <AssessmentFieldFormWrapper question={question} key={questionKey}>
              <AssessmentField assessmentQuestion={question} disabled={disabled} />
            </AssessmentFieldFormWrapper>
          </Box>
        )}
      </AssessmentQuestionCard>
    );
  },
);
