import { Box, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { TextEditor } from 'components/core/TextEditor/TextEditor';

interface IFormEditorConditionGroupTextProps {
  initialHtmlContent: string;
  disabled: boolean;
  onChange(html: string): void;
}

export const FormEditorConditionGroupText: FC<IFormEditorConditionGroupTextProps> = ({
  initialHtmlContent,
  disabled,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <Box w="100%">
      <Text size="lg" mb={20} textTransform="capitalize">
        {t('form.conclusions.ConclusionText')}
      </Text>
      <TextEditor
        editorProps={{ readOnly: disabled }}
        initialHtmlContent={initialHtmlContent}
        onChange={onChange}
      />
    </Box>
  );
};
