import { Box, Flex, Text } from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';
import { FC } from 'types';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useTenants } from 'hooks/useTenants';
import { useLastLoginTrigger } from 'hooks/useLastLoginTrigger';

export const LoginCallbackPage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [shouldFetchTenants, setShouldFetchTenants] = useState(false);
  const { isInitialLoad, error, data } = useTenants(undefined, shouldFetchTenants);
  const triggerLastLogin = useLastLoginTrigger();
  const [redirectPath, setRedirectPath] = useState<string>();

  useEffect(() => {
    if (data && Boolean(data.length)) {
      triggerLastLogin();
    }
  }, [data, triggerLastLogin]);

  useEffect(() => {
    const postLoginRedirect = sessionStorage.getItem('postLoginRedirect');

    if (postLoginRedirect) {
      sessionStorage.removeItem('postLoginRedirect');

      setRedirectPath(postLoginRedirect);
      setShouldFetchTenants(true);
    }
  }, [history, isInitialLoad]);

  return (
    <Flex height="100vh" width="100vw" justify="center" align="center" direction="column">
      {isInitialLoad && !error && (
        <Fragment>
          <Box className="a-loading a-primary" />
          <Text mt="20px" size="xl">
            {t('loading.fetching.sites')}
          </Text>
        </Fragment>
      )}
      {error && (
        <Fragment>
          <Text as="span" className="appkiticon icon-alert-outline a-text-48" />
          <Text mt="20px" size="3xl">
            {error.Message || t('errors.fetchingProfileInfo.unexpected')}
          </Text>
        </Fragment>
      )}
      {!error && !isInitialLoad && redirectPath && <Redirect to={{ pathname: redirectPath }} />}
    </Flex>
  );
};
