import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Box, Grid, Spacer } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { BoxHeader } from 'components/core/BoxHeader/BoxHeader';
import { BackButton } from 'components/core/BoxHeader/components/BackButton';
import { MainLayout } from 'components/layouts/MainLayout/MainLayout';
import { FormEditorToolbar } from 'components/form-editor/FormEditorToolbar/FormEditorToolbar';
import { FormBreadcrumbs } from 'components/form/FormBreadcrumbs/FormBreadcrumbs';
import { FormEditorContainer } from 'components/form-editor/FormEditor/FormEditorContainer';
import { FormEditorActionButton } from 'components/form-editor/FormEditorActionButton/FormEditorActionButton';
import { StatusIndicator } from 'components/core/StatusIndicator/StatusIndicator';
import { useRoomForm } from 'hooks/useRoomForms';

export const FormPage = () => {
  const { t } = useTranslation();
  const {
    params: { roomId, formId },
    path,
  } = useRouteMatch<{ roomId: string; formId: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const { form } = useRoomForm(roomId, formId);

  return (
    <MainLayout>
      <Grid
        gridTemplateAreas="'header side-header' 'main main'"
        gridTemplateRows="100px auto"
        gridTemplateColumns="auto 340px"
        gridColumnGap={40}
        height="100%"
      >
        <BoxHeader gridArea="header" align="center" justify="space-between">
          <BackButton />

          <Box pt={20}>
            <Box width="fit-content">
              <FormBreadcrumbs roomId={roomId} formId={formId} formName={form?.name} />
            </Box>
          </Box>

          <Spacer />

          <FormEditorToolbar form={form} roomId={roomId} formId={formId} isEditing={isEditing} />
        </BoxHeader>

        <BoxHeader gridArea="side-header" align="center" justify="space-between">
          <StatusIndicator>{form && t(`form.status.${form.formStatus}`)}</StatusIndicator>
          <FormEditorActionButton
            form={form}
            roomId={roomId}
            formId={formId}
            isEditing={isEditing}
          />
        </BoxHeader>

        <Box gridArea="main" height="100%">
          <Switch>
            <Route exact path={path}>
              <FormEditorContainer
                roomId={roomId}
                formId={formId}
                form={form}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Route>
            <Route path={`${path}/sortable`}>TODO: sortable view</Route>
            <Route path={`${path}/testing`}>TODO: testing views table</Route>
          </Switch>
        </Box>
      </Grid>
    </MainLayout>
  );
};
