import { BasicConfig, Widgets } from 'react-awesome-query-builder';

import { AQBBoolean } from '../components/Boolean';
import { AQBDate } from '../components/Date';
import { AQBNumber } from '../components/Number';
import { AQBScore } from '../components/Score';
import { AQBSelect } from '../components/Select';
import { AQBSelectCountry } from '../components/SelectCountry';
import { AQBText } from '../components/Text';
import { AQBTime } from '../components/Time';

export const widgets: Widgets = {
  ...BasicConfig.widgets,
  text: {
    ...BasicConfig.widgets.text,
    factory: AQBText,
  },
  select: {
    ...BasicConfig.widgets.select,
    factory: AQBSelect,
  },
  multiselect: {
    ...BasicConfig.widgets.multiselect,
    factory: AQBSelect,
    customProps: {
      multiple: true,
    },
  },
  number: {
    ...BasicConfig.widgets.number,
    factory: AQBNumber,
  },
  boolean: {
    ...BasicConfig.widgets.boolean,
    factory: AQBBoolean,
  },
  date: {
    ...BasicConfig.widgets.date,
    factory: AQBDate,
  },
  time: {
    ...BasicConfig.widgets.time,
    factory: AQBTime,
  },
  datetime: {
    ...BasicConfig.widgets.datetime,
    factory: AQBDate,
  },
  select_country: {
    ...BasicConfig.widgets.select,
    factory: AQBSelectCountry,
  },
  score: {
    jsType: 'object',
    type: 'multiselect',
    valueSrc: 'value',
    valuePlaceholder: 'Placeholder',
    customProps: {
      className: 'score',
    },
    formatValue: (val, _, __, isForDisplay) => {
      return isForDisplay ? val : JSON.stringify(val);
    },
    sqlFormatValue: (val) => {
      return val;
    },
    factory: AQBScore,
  },
};
