import { Box, BoxProps } from '@chakra-ui/react';
import { useDatx } from 'hooks/useDatx';
import { observer } from 'mobx-react';
import { FC } from 'types';
import { useState } from 'react';
import {
  Config,
  ImmutableTree,
  JsonGroup,
  Query,
  Utils as QbUtils,
} from 'react-awesome-query-builder';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import { IFormConditionGroup } from 'typings/interfaces/IFormConditionGroup';
import { FormComponent } from 'models/FormComponent';
import { ILogicBuilderState } from 'typings/interfaces/ILogicBuilderState';

import { BuilderWrapper } from './components/BuilderWrapper';
import { initConfig } from './config';
import { componentsToFields, getInitialState } from './LogicBuilder.utils';

interface ILogicBuilderProps extends Omit<BoxProps, 'onChange'> {
  components: Array<FormComponent>;
  disabled?: boolean;
  defaultState?: IFormConditionGroup;
  onChange(state: ILogicBuilderState): void;
}

export const LogicBuilder: FC<ILogicBuilderProps> = observer(
  ({ defaultState, components, disabled = false, onChange, ...props }) => {
    const { t } = useTranslation();
    const datx = useDatx();

    const fields = componentsToFields(components);
    const config = initConfig(fields, t, { disabled });
    const [initialTree] = useState(() => {
      const startingPoint = getInitialState(datx, defaultState) as JsonGroup;

      return QbUtils.checkTree(QbUtils.loadTree(startingPoint), config);
    });

    const [state, setState] = useState<ILogicBuilderState>(() => ({
      config,
      tree: initialTree,
      previewTree: QbUtils.getTree(initialTree),
    }));

    const handleChange = debounce((tree: ImmutableTree, config: Config) => {
      if (!disabled) {
        const previewTree = QbUtils.getTree(tree);

        setState({ tree, config, previewTree });
        onChange({ tree, config, previewTree });
      }
    }, 300);

    return (
      <Box width="100%" {...props}>
        <Query
          {...config}
          value={state.tree}
          renderBuilder={BuilderWrapper}
          onChange={handleChange}
        />
      </Box>
    );
  },
);
