import useSWR from 'swr';

import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { useDatx } from 'hooks/useDatx';
import { AssessmentView } from 'models/AssessmentView';

export const useAssessments = (searchQuery: string) => {
  const dependencyHeaders = useDependencyHeaders();

  const datx = useDatx();

  const siteId = dependencyHeaders['x-site-id'];

  const key = siteId ? `assessments@${siteId}` : null;

  const swr = useSWR(
    key,
    async () => {
      const response = await apiClient('assessments', HttpMethod.Get, {
        headers: {
          ...dependencyHeaders,
        },
      });

      const assessments: Array<AssessmentView> = datx.add(response, AssessmentView);
      return assessments;
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  const filteredData = () => {
    if (swr.data) {
      return swr.data.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
    } else {
      return [];
    }
  };

  return {
    isInitialLoad: !swr.data && !swr.error,
    filteredData,
    ...swr,
  };
};
