import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Tabs, Tab } from 'appkit-react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BoxHeader } from 'components/core/BoxHeader/BoxHeader';
import { BackButton } from 'components/core/BoxHeader/components/BackButton';
import { BoxTitle } from 'components/core/BoxHeader/components/BoxTitle';
import { MainLayout } from 'components/layouts/MainLayout/MainLayout';
import { PopAlertFail } from 'services/PopAlertFail';
import { useProfile } from 'hooks/useProfile';

import { AppAdminDomainManagementPage } from './domain-management';
import { AppAdminLibraryManagementPage } from './library-management';
import { AppAdminSiteManagementPage } from './site-management';

export const ApplicationAdminPage = () => {
  const { path, url } = useRouteMatch();
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();
  const profile = useProfile();

  const handleTabChange = (_: unknown, path: string) => {
    history.push(path);
  };

  useEffect(() => {
    if (!profile.isAppAdmin) {
      history.push('/rooms');
      PopAlertFail(
        t('alerts.unauthorizedApplicationAccess.title'),
        t('alerts.unauthorizedApplicationAccess.description'),
        false,
        8000,
      );
    }
  }, [profile, history, t]);

  return (
    <MainLayout>
      <BoxHeader align="center" justify="space-between">
        <BackButton />
        <BoxTitle>{t('applicationAdmin.title')}</BoxTitle>
        <Box />
      </BoxHeader>
      <Box width="195px">
        <Tabs onChange={handleTabChange} defaultValue={location.pathname}>
          <Tab label={t('applicationAdmin.tabs.sites').toUpperCase()} value={url} />
          <Tab
            label={t('applicationAdmin.tabs.library').toUpperCase()}
            value={`${url}/library-management`}
          />
          <Tab label={t('applicationAdmin.tabs.domain').toUpperCase()} value={`${url}/domains`} />
        </Tabs>
      </Box>
      <Switch>
        <Route exact path={`${path}/(site-management)?`} component={AppAdminSiteManagementPage} />
        <Route path={`${path}/library-management`} component={AppAdminLibraryManagementPage} />
        <Route path={`${path}/domains`} component={AppAdminDomainManagementPage} />
      </Switch>
    </MainLayout>
  );
};
