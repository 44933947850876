import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { useDatx } from 'hooks/useDatx';
import { useResources } from 'hooks/useResources';
import { User } from 'models/User';
import { RoomUser } from 'models/RoomUser';
import { Room } from 'models/Room';
import { RoomType } from 'typings/enums/RoomType';
import { UserManagementSearch } from 'components/user-management/UserManagementSearch/UserManagementSearch';
import { userSearchValidation } from 'services/validation/userSearch';

interface IRoomUsersSearchProps {
  roomId: string;
  addedUsers: Array<RoomUser>;
  disabled?: boolean;
  onAdd(user: RoomUser): void;
}

export const RoomUsersSearch: FC<IRoomUsersSearchProps> = ({
  roomId,
  addedUsers,
  disabled = false,
  onAdd,
}) => {
  const { data, isInitialLoad } = useResources(User);
  const datx = useDatx();
  const room = datx.findOne(Room, roomId);
  const { t } = useTranslation();

  const filteredUsers = (data as Array<User>).filter((user) => {
    if (room?.roomType === RoomType.Internal) {
      return user.isDominantUser;
    }

    return true;
  });

  const handleAddUser = (email: string) => {
    const user = (data as Array<User>).find((item) => email === item.email);
    const roomUser = datx.add(
      {
        id: `${user?.id}@${roomId}`,
        userId: user?.id,
        username: user?.username,
        expirationDate: null,
        email: user?.email,
      },
      RoomUser,
    );
    onAdd(roomUser);
  };

  return (
    <UserManagementSearch
      label={t('room.sidebar.roomDetails.modals.membersManagement.search.label')}
      placeholder={t('room.sidebar.roomDetails.modals.membersManagement.search.placeholder')}
      validationRules={userSearchValidation(
        t,
        (value: string) =>
          Boolean(filteredUsers?.find((user) => user.email === value || user.username === value)),
        (value: string) =>
          Boolean(addedUsers?.find((user) => user.email === value || user.username === value)),
      )}
      disableButton={isInitialLoad || disabled}
      isLoading={isInitialLoad}
      onClick={handleAddUser}
    />
  );
};
