import { Fragment, useCallback } from 'react';
import { FC } from 'types'
import { Box, HStack, Spacer } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'appkit-react';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { PopAlertFail } from 'services/PopAlertFail';
import { useApiClient } from 'hooks/useApiClient';
import { IAttachmentFile } from 'typings/interfaces/dto/IAttachmentFile';
import { IAttachmentDownloadInfo } from 'typings/interfaces/dto/IAttachmentDownloadInfo';
import { AttachmentFileStatus } from 'typings/enums/AttachmentFileStatus';
import { triggerLinkDownload } from 'utils/triggerLinkDownload';

interface IAttachmentDownloadProps {
  file: IAttachmentFile;
  downloadUrl: string;
  onRemove?(fileId: string): void;
}
export const AttachmentDownload: FC<IAttachmentDownloadProps> = ({
  file,
  downloadUrl,
  onRemove,
}) => {
  const { t } = useTranslation();
  const [getDownloadInfo, { pending }] = useApiClient<IAttachmentDownloadInfo>({
    url: downloadUrl,
    method: HttpMethod.Get,
  });

  const isDownloadable = file.fileStatus && file.fileStatus === AttachmentFileStatus.Ready;

  const handleDownload = useCallback(async () => {
    if (pending || !isDownloadable) {
      return;
    }
    try {
      const { data: downloadInfo, error } = await getDownloadInfo();
      if (error) {
        throw error;
      }
      if (!downloadInfo) {
        throw Error('Missing download SAS token');
      }
      if (
        downloadInfo.file.fileStatus &&
        downloadInfo.file.fileStatus === AttachmentFileStatus.Ready
      ) {
        triggerLinkDownload(downloadInfo.sasToken);
      } else {
        throw Error('File is not ready for download');
      }
    } catch (error: any) { // eslint-disable-line
      PopAlertFail(t('form.addon.helpText.downloadFail'));
    }
  }, [getDownloadInfo, isDownloadable, pending, t]);

  return (
    <Fragment>
      <HStack
        className={`upload-drop-small a-apply-theme-background-gray-level-${pending ? '2' : '1'}`}
        spacing={20}
      >
        <Tooltip disabled={isDownloadable} content={t(`upload.status.${file.fileStatus}`)}>
          <Box onClick={handleDownload} cursor={pending ? 'auto' : 'pointer'}>
            <Box
              as="span"
              mr={12}
              width="16px"
              h="16px"
              className={pending ? 'a-default a-loading' : 'appkiticon icon-file-fill a-font-16'}
            />
            {file.fileName}
          </Box>
        </Tooltip>

        <Spacer />

        {file.lastEditedUtc && (
          <Box as="span">{format(new Date(file.lastEditedUtc), 'MMM d, yyyy')}</Box>
        )}

        {isDownloadable && (
          <StyledButton onClick={handleDownload} isLoading={pending}>
            {t('action.download.label')}
          </StyledButton>
        )}

        {onRemove && !pending && (
          <Box
            as="button"
            type="button"
            className="appkiticon icon-delete-outline"
            onClick={() => pending || onRemove(file.fileId)}
          />
        )}
      </HStack>
    </Fragment>
  );
};
