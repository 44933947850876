// additional filename changes might appear depending on the browser support

export const getFileNameFromHeader = (dispositionHeader: string) => {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName: string | null = null;

  if (utf8FilenameRegex.test(dispositionHeader)) {
    fileName = decodeURIComponent(utf8FilenameRegex.exec(dispositionHeader)?.[1] ?? '') || null;
  } else {
    fileName = asciiFilenameRegex.exec(dispositionHeader)?.[2] || null;
  }

  return fileName;
};
