import { Attribute } from '@datx/core';
import { computed } from 'mobx';

import { FormConclusionType } from 'typings/enums/FormConclusionType';
import { IFormConditionGroup } from 'typings/interfaces/IFormConditionGroup';
import { Resource } from 'models/Resource';
import { IAttachmentFile } from 'typings/interfaces/dto/IAttachmentFile';

export class FormConclusion extends Resource {
  public static type = 'conclusions';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute({ defaultValue: FormConclusionType.Inclusive })
  public conclusionType!: FormConclusionType;

  @Attribute()
  public attachments!: Array<IAttachmentFile>;

  @Attribute()
  public conclusionText!: string;

  @Attribute()
  public conditionGroup!: IFormConditionGroup;

  @computed
  public get isPlaceholder() {
    return typeof this.id === 'number';
  }

  @computed
  public get initialDto() {
    return {
      id: undefined,
      conclusionType: this.conclusionType,
      conditionGroup: {
        ...this.conditionGroup,
        id: undefined,
      },
    };
  }

  @computed
  public get dto() {
    return {
      id: this.id,
      conclusionType: this.conclusionType,
      attachments: this.attachments,
      conclusionText: this.conclusionText,
      conditionGroup: this.conditionGroup,
    };
  }
}
