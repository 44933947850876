import { Box, Text, Wrap } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { Droppable } from 'react-beautiful-dnd';

import { InfoTooltip } from 'components/core/InfoTooltip/InfoTooltip';
import { FormComponent } from 'models/FormComponent';
import { FormEditorDroppable } from 'typings/enums/FormEditorDroppable';

import { QuestionAddonButton } from '../QuestionAddonButton/QuestionAddonButton';
import { QuestionSettingsEditorButton } from '../QuestionSettingsEditorButton/QuestionSettingsEditorButton';

interface IAddonAreaProps {
  model: FormComponent;
  isDropDisabled: boolean;
  onPlaceholderSettingsClick?(): void;
}
export const AddonArea: FC<IAddonAreaProps> = ({
  model,
  isDropDisabled,
  onPlaceholderSettingsClick,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Text size="sm" opacity="0.87">
        {t('form.editor.addons').toUpperCase()}
      </Text>
      <Droppable
        droppableId={`${FormEditorDroppable.QuestionAddons}-${model.id}`}
        isDropDisabled={isDropDisabled}
      >
        {(provided, snapshot) => (
          <Wrap
            ref={provided.innerRef}
            {...provided.droppableProps}
            bgColor={snapshot.isDraggingOver ? 'grey.lvl4' : undefined}
            sx={{
              minH: '36px',
              mt: 12,
              pl: 4,
              pr: 20,
              pt: '2px',
              position: 'relative',
              border: ({ colors }) => `1px solid ${colors.grey.lvl6}`,
              overflow: 'unset',
            }}
          >
            <QuestionSettingsEditorButton
              question={model}
              onPlaceholderSettingsClick={onPlaceholderSettingsClick}
            />

            {model.addons?.map((addon) => (
              <QuestionAddonButton key={addon.addonType} addon={addon} question={model} />
            ))}

            <Box display="none">{provided.placeholder}</Box>
            <Box position="absolute" right="2px" top="1px">
              <InfoTooltip />
            </Box>
          </Wrap>
        )}
      </Droppable>
    </Box>
  );
};
