import { ConditionOperator } from 'typings/enums/ConditionOperator';
import { ConditionRuleOperator } from 'typings/enums/ConditionRuleOperator';
import { ConditionType } from 'typings/enums/ConditionType';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { ISkipCondition } from 'typings/interfaces/ISkipCondition';

// stringified as valueJson
export type FormComponentValue = {
  index: number;
  value: string | number | boolean;
};

export type FormSectionSettings = {
  namePrefix: string | null;
};

// Confirmation
export type ConfirmationSettings = ISkipCondition & {
  confirmationText: string;
};

// Country
export enum CountryFormatType {
  Country = 'Country',
  Language = 'Language',
}

export enum UserInputFormatType {
  Name = 'Name',
  Code = 'Code',
}

export enum ExportFormatType {
  Name = 'Name',
  Code = 'Code',
  Both = 'Both',
}

export type CountrySettings = {
  formatType: CountryFormatType;
  userInputFormatType: UserInputFormatType;
  exportAndStatisticsFormat: ExportFormatType;
};

// Date
export const DateFormats = ['yyyy-mm-dd', 'yyyy-mm', 'yyyy', 'dd-mm-yyyy', 'mm-yyyy'] as const;

export type SupportedDateFormat = typeof DateFormats[number];
export type DateSettings = {
  dateFormat: SupportedDateFormat;
  dateRangePicker: boolean;
};

// Numbers
export enum NumbersComponentType {
  Number = 'Number',
  Currency = 'Currency',
  Percentage = 'Percentage',
  Score = 'Score',
}

export type NumbersSettings = {
  decimalDigits: number;
  numberType: NumbersComponentType;
  selectedCurrency: string | null;
};

// Choice
export type Choice = ISkipCondition & {
  index: number;
  text: string;
  scoring?: number;
};

export type ChoiceQuestionValue = ISkipCondition & {
  index: number;
  value: string;
  scoring?: number;
};

// Choice
export type ChoiceValuesFormField = {
  choices: Array<Choice>;
  noneOfTheAboveText?: string;
  addNoneOfTheAbove?: boolean;
};

// Single Choice
export type SingleChoiceValuesFormField = {
  choices: Array<Choice>;
};

// Multiple Choice
export type MultipleChoiceValuesFormField = {
  choices: Array<Choice>;
  noneOfTheAboveText: string;
  addNoneOfTheAbove: boolean;
};

export type MultipleChoiceSettings = {
  exclusiveOptionText?: string;
};

// Scale
export enum ScaleType {
  Default = 'Default',
  Range = 'Range',
  Interval = 'Interval',
}

export enum ScaleIntervalInputType {
  Number = 'Number',
  Text = 'Text',
}

export type ScaleSettings = Partial<{
  scaleType: ScaleType;
  title: string;
  numberSettings: NumbersSettings;
  minimumScale: number;
  maximumScale: number;
}>;

export type IntervalValue = ISkipCondition & {
  index: number;
  value: string | number;
};
export type IntervalTextValue = ISkipCondition & {
  index: number;
  label: string;
};
export type IntervalNumberValue = ISkipCondition & {
  index: number;
  label: number;
};

export type IntervalValuesFormField = {
  marksText: Array<IntervalTextValue>;
  marksNumber: Array<IntervalNumberValue>;
};

export type ScaleSettingsFormFields = {
  scaleType: ScaleType;
  title: string;
  maximumScale: number;
  minimumScale: number;
  numberType: NumbersComponentType;
  selectedCurrency: string | null;
  inputType?: ScaleIntervalInputType;
};

export type ScaleConfig = {
  componentType: FormComponentType.ScaleNumber | FormComponentType.ScaleText;
  settings: ScaleSettings | null;
  values: Array<IntervalValue>;
};

// Help text addon
export type HelperTextSettings = {
  Text: string;
  FileIds: Array<string>;
};

// Skip logic and condition addon
export type ConditionRule = {
  componentId: string;
  operator: ConditionOperator;
  stringValue: string;
  conditionType: ConditionType;
};

export type SkipCondition = ConditionRule & {
  skipToQuestionId: string;
};

export type SkipConditionAddonSettings = {
  skipConditions: Array<SkipCondition>;
};

export type ConditionRuleGroup = {
  name: string;
  operator: ConditionRuleOperator;
  conditionRules: Array<ConditionRule>;
  conditionRuleGroups: Array<ConditionRuleGroup>;
};

export type ConditionAddonSettings = {
  conditionRuleGroup: ConditionRuleGroup;
};
