import { VStack, useBoolean, Box } from '@chakra-ui/react';
import { FC } from 'types';
import { useCallback } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { observer } from 'mobx-react';

import { FormComponent } from 'models/FormComponent';
import { DraggingAction } from 'typings/enums/DraggingAction';
import { Question } from 'components/form-editor/Question/Question';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { useDatx } from 'hooks/useDatx';
import { FormSectionSettings } from 'typings/types/FormComponentSettings';

import { FormEditorSectionHeader } from './components/FormEditorSectionHeader/FormEditorSectionHeader';

interface IFormEditorSectionProps {
  index: number;
  model: FormComponent;
}

export const FormEditorSection: FC<IFormEditorSectionProps> = observer(({ index, model }) => {
  const [isExpanded, setExpanded] = useBoolean(false);
  const datx = useDatx();
  const {
    placeholder,
    dragAction,
    disableEdit,
    allowLimitedEditOnFinalized,
    addComponent,
    removeComponent,
    updateComponent,
    removePlaceholder,
  } = useFormEditor();

  const handleSaveClick = useCallback(
    async (sectionName: string, sectionNamePrefix: string | null) => {
      if (disableEdit && !allowLimitedEditOnFinalized) {
        return;
      }
      model.text = sectionName;
      model.settingsJson = sectionNamePrefix
        ? JSON.stringify({ namePrefix: sectionNamePrefix })
        : null;
      if (model.isPlaceholder) {
        removePlaceholder();
        await addComponent(model);
      } else {
        await updateComponent(model);
      }
    },
    [
      addComponent,
      allowLimitedEditOnFinalized,
      disableEdit,
      model,
      removePlaceholder,
      updateComponent,
    ],
  );

  const handleRemoveClick = useCallback(() => {
    if (disableEdit) {
      return;
    }
    if (model.isPlaceholder) {
      datx.removeOne(FormComponent, model.id);
      removePlaceholder();
    } else {
      removeComponent(model);
    }
  }, [datx, disableEdit, model, removeComponent, removePlaceholder]);

  const children = datx
    .findAll<FormComponent>(FormComponent)
    .filter((component) => component.parentComponentId === model.id)
    .sort((a, b) => {
      if (a.index !== b.index) {
        return a.index - b.index;
      }
      if (a.isPlaceholder) {
        return -1;
      }
      if (b.isPlaceholder) {
        return 1;
      }
      return 0;
    });

  const isDropDisabled =
    disableEdit ||
    Boolean(placeholder) ||
    (dragAction !== DraggingAction.AddQuestionComponent &&
      dragAction !== DraggingAction.MoveQuestion);

  const isDragDisabled = disableEdit || isExpanded;

  return (
    <Draggable draggableId={`section-${model.id}`} index={index} isDragDisabled={isDragDisabled}>
      {(provided) => (
        <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <FormEditorSectionHeader
            name={model.text}
            prefix={(model.parsedSettings as FormSectionSettings)?.namePrefix}
            isExpanded={isExpanded}
            allowEdit={!disableEdit || allowLimitedEditOnFinalized}
            onSave={handleSaveClick}
            onDelete={handleRemoveClick}
            onToggleExpand={setExpanded.toggle}
          />
          {isExpanded && (
            <Droppable droppableId={`section-content-${model.id}`} isDropDisabled={isDropDisabled}>
              {(provided, snapshot) => (
                <VStack
                  spacing={20}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  bgGradient={
                    !children?.length && snapshot.isDraggingOver
                      ? 'linear(to-b, grey.lvl3, grey.lvl2)'
                      : undefined
                  }
                  sx={{
                    minH: '100px',
                    pt: 16,
                    mb: 20,
                    border: (props) =>
                      snapshot.isDraggingOver
                        ? `0.5px dashed ${props.colors.brand.orange}`
                        : 'none',
                  }}
                >
                  {children.map((question, index) => (
                    <Question key={question.id} index={index} model={question} />
                  ))}
                  <Box
                    bgGradient={
                      children.length && snapshot.isDraggingOver
                        ? 'linear(to-b, grey.lvl3, grey.lvl2)'
                        : undefined
                    }
                    width="100%"
                  >
                    {provided.placeholder}
                  </Box>
                </VStack>
              )}
            </Droppable>
          )}
        </Box>
      )}
    </Draggable>
  );
});
