import React, { Factory, useMemo } from 'react';
import { DateTimeWidgetProps } from 'react-awesome-query-builder';

import { CalendarPickerField } from 'fields/CalendarPickerField/CalendarPickerField';
import { RuleWrapper } from '../LogicBuilder.elements';

import { formatISOToRoundUTCDate } from 'utils/dates';

export const AQBDate: Factory<DateTimeWidgetProps> = (props) => {
  const { value, setValue, readonly, customProps, dateFormat, placeholder } =
    props as React.Attributes & DateTimeWidgetProps;

  const handleChange = (value: Date) => {
    setValue(formatISOToRoundUTCDate(value));
  };

  const minDetail = useMemo(() => {
    switch (dateFormat) {
      case 'yyyy':
        return 'decade';
      default:
        return 'decade';
    }
  }, [dateFormat]);

  const maxDetail = useMemo(() => {
    switch (dateFormat) {
      case 'yyyy':
        return 'decade';

      default:
        return 'month';
    }
  }, [dateFormat]);

  return (
    <RuleWrapper>
      <CalendarPickerField
        readOnly={readonly}
        disabled={readonly}
        placeholder={!readonly ? placeholder : ''}
        format={dateFormat}
        value={value ? new Date(value) : null}
        minDetail={minDetail}
        maxDetail={maxDetail}
        onChange={handleChange}
        {...customProps}
      />
    </RuleWrapper>
  );
};
