import { ButtonProps, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { StyledActionsMenuItem } from './ActionsMenuItem.elements';

interface IActionsMenuItemProps extends ButtonProps {
  label: string;
  iconName: string;
  isLoading?: boolean;
  onClick(): void;
}

export const ActionsMenuItem: FC<IActionsMenuItemProps> = ({
  label,
  iconName,
  isLoading,
  onClick,
  ...rest
}) => {
  return (
    <StyledActionsMenuItem onClick={onClick} {...rest}>
      {isLoading && <Text as="span" className="a-loading a-default" width="14px" height="14px" />}
      {!isLoading && <Text as="span" fontSize="md" className={`appkiticon ${iconName}`} />}
      <Text ml="10px">{label}</Text>
    </StyledActionsMenuItem>
  );
};
