import { isArray } from 'lodash';

import { DateSettings } from 'typings/types/FormComponentSettings';

export function getValidSettings(settings: string | null): DateSettings | null {
  if (!settings) {
    return null;
  }
  const settingsJson = JSON.parse(settings);

  if (!('dateFormat' in settingsJson) || !('dateRangePicker' in settingsJson)) {
    return null;
  }

  return settingsJson;
}

export const parseValue = (
  value: string | Array<string | null> | null,
): Date | Array<Date> | null => {
  if (value === null) {
    return null;
  }

  if (isArray(value)) {
    if (value[0] === null) {
      return null;
    }

    if (value[1] === null) {
      return new Date(value[0]);
    }

    return [new Date(value[0]), new Date(value[1])];
  }

  return new Date(value);
};

export const parseOnChange = (
  changedValue: Date | Array<Date> | null,
  isRange: boolean,
): Array<string | null> | string | null => {
  if (changedValue === null) {
    return isRange ? [null, null] : null;
  }

  if (isArray(changedValue)) {
    return changedValue.map((date) => date.toString());
  }

  return isRange ? [changedValue.toString(), null] : changedValue.toString();
};
