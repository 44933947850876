import { HStack, useBoolean } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

import { ISelectionItem, useTrashActions } from '../RoomsTrashView.hooks';

interface IRoomsTrashViewActions {
  selection: Array<ISelectionItem>;
  isSelectAll: boolean;
  resetSelection(): void;
}

export const RoomsTrashViewActions: FC<IRoomsTrashViewActions> = ({
  selection,
  resetSelection,
}) => {
  const [isRestoring, setIsRestoring] = useBoolean();
  const [isDeleting, setIsDeleting] = useBoolean();

  const { deleteItems, restore } = useTrashActions(selection);
  const { t } = useTranslation();

  const handleDelete = async () => {
    setIsDeleting.on();
    try {
      await deleteItems();
      PopAlertSuccess(t('rooms.trashView.successMessages.delete'));
    } catch (response: any) { // eslint-disable-line
      const message = response.message || t('rooms.trashView.errors.delete');
      PopAlertFail(message);
    } finally {
      setIsDeleting.off();
      resetSelection();
    }
  };

  const handleRestore = async () => {
    setIsRestoring.on();
    try {
      await restore();
      PopAlertSuccess(t('rooms.trashView.successMessages.restore'));
    } catch (response: any) { // eslint-disable-line
      const message = response.message || t('rooms.trashView.errors.restore');
      PopAlertFail(message);
    } finally {
      setIsRestoring.off();
      resetSelection();
    }
  };

  return (
    <HStack spacing={20} position="absolute" right="0" bottom="0">
      <StyledButton
        onClick={handleRestore}
        size="md"
        kind="secondary"
        isLoading={isRestoring}
        disabled={isDeleting || isRestoring || selection.length < 1}
      >
        {t('action.restore.label')}
      </StyledButton>
      <StyledButton
        onClick={handleDelete}
        size="md"
        isLoading={isDeleting}
        disabled={isDeleting || isRestoring || selection.length < 1}
      >
        {t('action.delete.label')}
      </StyledButton>
    </HStack>
  );
};
