import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { FormModal } from 'components/modal/FormModal/FormModal';
import { Form } from 'models/Form';
import { useApiClient } from 'hooks/useApiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { Room } from 'models/Room';
import { RoomFolder } from 'models/RoomFolder';

import { useAvailableFormUsers } from '../CreateFormModal/CreateFormModal.hooks';
import { DuplicateFormForm } from '../DuplicateFormForm/DuplicateFormForm';

interface ICreateDuplicateFormModalProps {
  form: Form;
  onClose(): void;
}

export const CreateDuplicateFormModal: FC<ICreateDuplicateFormModalProps> = ({ form, onClose }) => {
  const { t } = useTranslation();
  const { roomUsers } = useAvailableFormUsers(form.room.id, form.parentFolderId);

  const [createDuplicate, { pending }] = useApiClient({
    url: `${Room.type}/${form.room.id}/${Form.type}/${form.id}/duplicate`,
    method: HttpMethod.Post,
    mutateKeys: [`rooms/${form.room.id}/${Form.type}`, `rooms/${form.room.id}/${RoomFolder.type}`],
  });

  return (
    <FormModal isOpen maxWidth="940px" title={t('createForm.title')} onClose={onClose}>
      <DuplicateFormForm
        availableUsers={roomUsers}
        isLoading={pending}
        onSave={createDuplicate}
        onCancel={onClose}
        mt={20}
      />
    </FormModal>
  );
};
