import { FC } from 'types';
import { Fragment } from 'react';
import { Select, SelectOption } from 'appkit-react';
import { Box, Heading, Text, useBoolean, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { RoomDetailsRow } from 'components/room/RoomDetails/components/RoomDetailsRow/RoomDetailsRow';
import { RoomTypeConfirmationModal } from 'components/room/RoomTypeConfirmationModal/RoomTypeConfirmationModal';
import { IRoomDetailsRowProps } from 'typings/interfaces/IDetailsRowProps';
import { RoomType } from 'typings/enums/RoomType';
import { useRoomSetExternal } from 'hooks/useRoomSetExternal';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

export const RoomTypeRow: FC<IRoomDetailsRowProps> = ({
  model,
  disableEdit = false,
  readonly = false,
  onStartEdit,
  onStopEdit,
}) => {
  const [isEditMode, setEditMode] = useBoolean(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSaving, setIsSaving] = useBoolean(false);
  const { changeRoomToExternal, mutateRooms } = useRoomSetExternal();
  const { t } = useTranslation();

  const saveChanges = async () => {
    setIsSaving.on();
    try {
      await changeRoomToExternal();
      PopAlertSuccess(
        t('room.sidebar.roomDetails.modals.typeConfirmation.successMessages.changed'),
      );
    } catch (error: any) { // eslint-disable-line
      PopAlertFail(t('room.errors.changeRoomType.unexpected'), error.Message);
      // this is not in the finally, because in case of a success, the model will be mutated, and the component unmounted
      setIsSaving.off();
    }

    try {
      await mutateRooms();
    } catch (error: any) { // eslint-disable-line
      // this is useful while developing/testing, could be removed after project goes to production
      // or alternatively do not show warnings in production env
      PopAlertFail(t('room.errors.changeRoomType.state'), error.Message, true);
      // this is not in the finally, because in case of a success, the model will be mutated, and the component unmounted
      setIsSaving.off();
    }
  };

  const stopEditing = () => {
    setEditMode.off();
    onStopEdit?.();
    onClose();
  };

  const handleClickEdit = () => {
    if (isEditMode) {
      setEditMode.off();
      onStopEdit?.();
    } else {
      setEditMode.on();
      onStartEdit?.();
    }
  };

  const handleClickConfirm = async () => {
    if (isSaving && !isEditMode && !readonly && isOpen) {
      return;
    }

    await saveChanges();
    stopEditing();
  };

  const handleClickCancel = () => {
    if (isSaving || !isEditMode) {
      return;
    }
    stopEditing();
  };

  const handleChange = (newValue: RoomType) => {
    if (newValue === RoomType.External) {
      onOpen();
    } else {
      stopEditing();
    }
  };

  return (
    <Fragment>
      <RoomDetailsRow>
        <Heading as="h5" size="sm" w="100px">
          {t('room.sidebar.roomDetails.rows.type')}
        </Heading>
        <Box w="200px">
          {!isEditMode && <Text>{model.roomType}</Text>}
          {isEditMode && (
            <Select defaultValue={model.roomType} onSelect={handleChange}>
              <SelectOption key={RoomType.Internal} value={RoomType.Internal}>
                {RoomType.Internal}
              </SelectOption>
              <SelectOption key={RoomType.External} value={RoomType.External}>
                {RoomType.External}
              </SelectOption>
            </Select>
          )}
        </Box>
        <Box w="47px">
          {!readonly && (
            <StyledButton
              size="md"
              kind="transparent"
              onClick={handleClickEdit}
              disabled={disableEdit && !isEditMode}
            >
              {t('action.edit.label')}
            </StyledButton>
          )}
        </Box>
      </RoomDetailsRow>
      {isOpen && (
        <RoomTypeConfirmationModal
          isSaving={isSaving}
          onConfirm={handleClickConfirm}
          onCancel={handleClickCancel}
        />
      )}
    </Fragment>
  );
};
