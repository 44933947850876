import { Box, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { FC } from 'types';
import { useState } from 'react';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { FormSectionSettings } from 'typings/types/FormComponentSettings';
import { ToggleExpandButton } from 'components/core/ToggleExpandButton/ToggleExpandButton';

import { AssessmentQuestion } from '../AssessmentQuestion/AssessmentQuestion';
import { useAssessmentSectionQuestions } from './AssessmentSection.hooks';

interface IAssessmentSectionProps {
  section: AssessmentFormComponent;
  disabled?: boolean;
}

export const AssessmentSection: FC<IAssessmentSectionProps> = ({ section, disabled }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const questions = useAssessmentSectionQuestions(section.id);

  return (
    <Box width="100%">
      <HStack
        width="100%"
        spacing={20}
        justify="space-between"
        sx={{
          height: '42px',
          mb: isExpanded || 16,
          borderBottom: ({ colors }) => `2px solid ${colors.grey.lvl3}`,
        }}
      >
        <Text size="lg">{(section.parsedSettings as FormSectionSettings)?.namePrefix}</Text>
        <Spacer />

        <Text size="lg">{section.text}</Text>
        <Spacer />

        <ToggleExpandButton
          isExpanded={isExpanded}
          onClick={() => setIsExpanded((previous) => !previous)}
        />
      </HStack>
      <VStack spacing={20} width="100%" mt={16}>
        {isExpanded &&
          questions.map(({ component, readonly }) => (
            <AssessmentQuestion
              key={component.id}
              disabled={disabled || readonly}
              question={component}
            />
          ))}
      </VStack>
    </Box>
  );
};
