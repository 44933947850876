import { useSWRConfig } from 'swr';
import { ChangeEvent, useRef, useState } from 'react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/layout';
import { Input as ChakraInput } from '@chakra-ui/react';

import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { Modal } from 'components/modal/Modal/Modal';
import { ParseAndPopAlertFail } from 'services/ParseAndPopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { IApiClientError } from 'typings/interfaces/IApiClientError';
import { apiClient } from 'utils/apiClient';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

interface IImportModalProps {
  onCancel(): void;
}

export const ImportModal: FC<IImportModalProps> = ({ onCancel }) => {
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();
  const headerDeps = useDependencyHeaders();

  const [isUploading, setIsUploading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    const formData = new FormData();
    const arrayBuffer = await file.arrayBuffer();
    const blob = new Blob([new Uint8Array(arrayBuffer)], { type: 'text/csv' });

    formData.append('file', blob, file.name);

    setIsUploading(true);

    try {
      const mutateKey = `users@${headerDeps['x-site-id']}`;

      await apiClient('users/import', HttpMethod.Post, {
        headers: headerDeps,
        body: formData,
      });

      mutate(mutateKey);

      PopAlertSuccess(t('siteAdmin.user.successMessages.upload'));
    } catch (response: any) { // eslint-disable-line
      ParseAndPopAlertFail(response as IApiClientError, t);
    } finally {
      setIsUploading(false);
      formRef.current?.reset();
    }
  };

  return (
    <Modal isVisible sx={{ maxWidth: '780px', mx: 'auto' }}>
      <ModalHeader onCancel={onCancel}>
        <ModalTitle>{t('siteAdmin.user.importModal.title').toUpperCase()}</ModalTitle>
      </ModalHeader>
      <ModalBody sx={{ px: 40, py: 40, textAlign: 'center' }}>
        <Text className="a-text-yellow a-font-14">
          <Text
            as="span"
            className="appkiticon icon-alert-outline a-text-yellow a-font-14"
            display="inline-block"
            pr={2}
          />
          {t('siteAdmin.user.importModal.warning')}
        </Text>
        <StyledButton
          isLoading={isUploading}
          disabled={isUploading}
          size="lg"
          mt={5}
          onClick={() => fileInputRef.current?.click()}
        >
          {t('siteAdmin.user.importModal.action')}
        </StyledButton>
        <Box display="none">
          <form ref={formRef}>
            <ChakraInput
              ref={fileInputRef}
              type="file"
              accept=".csv"
              display="none"
              onChange={onFileChange}
            />
          </form>
        </Box>
      </ModalBody>
    </Modal>
  );
};
