import React, { Factory } from 'react';
import { Select, SelectOption } from 'appkit-react';
import { FieldItems, FieldProps } from 'react-awesome-query-builder';
import truncate from 'lodash/truncate';

import { RuleWrapper } from '../LogicBuilder.elements';

export const AQBFieldSelect: Factory<FieldProps> = (props) => {
  const renderOptions = (fields: FieldItems) =>
    Object.keys(fields).map((fieldKey) => {
      const field = fields[fieldKey];
      const { label, disabled, key } = field;

      return (
        <SelectOption disabled={disabled} key={key} value={key} title={label}>
          {truncate(label, { length: 25 })}
        </SelectOption>
      );
    });

  const onChange = (value: string) => {
    props?.setField(value);
  };

  const hasValue = Boolean(props?.selectedKey);

  return (
    <RuleWrapper>
      <Select
        label={props?.placeholder}
        onSelect={onChange}
        placeholder="Select a field"
        defaultValue={hasValue ? props?.selectedKey : ''}
        key={props?.selectedLabel}
        disabled={props?.readonly}
      >
        {renderOptions(props?.items as FieldItems)}
      </Select>
    </RuleWrapper>
  );
};
