export enum FormAction {
  Unknown = 'Unknown',
  Finalize = 'Finalize',
  Withdraw = 'Withdraw',
  Review = 'Review',
  Submit = 'Submit',
  Reject = 'Reject',
  Approve = 'Approve',
  Edit = 'Edit',
  NoAction = 'NoAction',
  Initiate = 'Initiate',
  EditFinalized = 'EditFinalized',
}
