import { useRadioGroup, Stack, StackDirection, StackProps } from '@chakra-ui/react';
import React from 'react';
import { FC } from 'types';

import { RadioCard } from './components/RadioCard/RadioCard';

interface IRadioCardGroup extends Omit<StackProps, 'onChange'> {
  name: string;
  defaultValue: string;
  direction?: StackDirection;
  disabled?: boolean;
  onChange(value: string): void;
}
export const RadioCardGroup: FC<IRadioCardGroup> = ({
  name,
  defaultValue,
  direction = 'row',
  disabled,
  children,
  onChange,
  ...rest
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
  });
  const group = getRootProps();

  return (
    <Stack direction={direction} spacing={16} {...group} alignItems="stretch" {...rest}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        const value = child.props.value as string;
        const radio = getRadioProps({ value });

        return (
          <RadioCard key={value} {...radio} isDisabled={disabled}>
            {child}
          </RadioCard>
        );
      })}
    </Stack>
  );
};
