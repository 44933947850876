import { Box, Text, Stack, UnorderedList, ListItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BoxHeader } from '../../components/core/BoxHeader/BoxHeader';
import { BackButton } from '../../components/core/BoxHeader/components/BackButton';
import { BoxTitle } from '../../components/core/BoxHeader/components/BoxTitle';
import { MainLayout } from '../../components/layouts/MainLayout/MainLayout';

export const DataPrivacyPage = () => {
  const { t } = useTranslation();

  return (
    <MainLayout>
      <BoxHeader align="center" justify="space-between">
        <BackButton />
        <BoxTitle>{t('dataPrivacy.title')}</BoxTitle>
        <Box />
      </BoxHeader>
      <Box maxW="768px" margin="auto" whiteSpace="pre-line" mb={5}>
        <Stack spacing={5}>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('dataPrivacy.intro.title')}
            </Text>
            <Text>{t('dataPrivacy.intro.text')}</Text>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('dataPrivacy.responsiblePerson.title')}
            </Text>
            <Text pb={5}>{t('dataPrivacy.responsiblePerson.text')}</Text>
            <Text>{t('dataPrivacy.responsiblePerson.company')}</Text>
            <Text>{t('dataPrivacy.responsiblePerson.email')}</Text>
            <Text>{t('dataPrivacy.responsiblePerson.switchboard')}</Text>
            <Text>{t('dataPrivacy.responsiblePerson.fax')}</Text>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('dataPrivacy.dataProtectionOfficer.title')}
            </Text>
            <Text pb={5}>{t('dataPrivacy.dataProtectionOfficer.text')}</Text>
            <Text>{t('dataPrivacy.dataProtectionOfficer.email')}</Text>
            <Text pb={5}>{t('dataPrivacy.dataProtectionOfficer.phone')}</Text>
            <Text>{t('dataPrivacy.dataProtectionOfficer.addressTitle')}</Text>
            <Text>{t('dataPrivacy.dataProtectionOfficer.company')}</Text>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('dataPrivacy.legalBasis.title')}
            </Text>
            <Text>{t('dataPrivacy.legalBasis.text')}</Text>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('dataPrivacy.categoriesOfRecipients.title')}
            </Text>
            <Stack spacing={5}>
              <Text>{t('dataPrivacy.categoriesOfRecipients.text1')}</Text>
              <Text>{t('dataPrivacy.categoriesOfRecipients.first')}</Text>
              <Text>{t('dataPrivacy.categoriesOfRecipients.text2')}</Text>
              <Text>{t('dataPrivacy.categoriesOfRecipients.text3')}</Text>
              <Text>{t('dataPrivacy.categoriesOfRecipients.text4')}</Text>
              <Text>{t('dataPrivacy.categoriesOfRecipients.text5')}</Text>
              <Text>{t('dataPrivacy.categoriesOfRecipients.second')}</Text>
              <Text>{t('dataPrivacy.categoriesOfRecipients.text6')}</Text>
              <Text>{t('dataPrivacy.categoriesOfRecipients.text7')}</Text>
              <Text>{t('dataPrivacy.categoriesOfRecipients.text8')}</Text>
              <Text>{t('dataPrivacy.categoriesOfRecipients.text9')}</Text>
              <Text>{t('dataPrivacy.categoriesOfRecipients.third')}</Text>
              <Text>{t('dataPrivacy.categoriesOfRecipients.text10')}</Text>
              <Text>{t('dataPrivacy.categoriesOfRecipients.text11')}</Text>
            </Stack>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('dataPrivacy.dataSubject.title')}
            </Text>
            <Text>{t('dataPrivacy.dataSubject.text')}</Text>
            <Stack spacing={5}>
              <Text>{t('dataPrivacy.dataSubject.rightToInformation.text1')}</Text>
              <Text>{t('dataPrivacy.dataSubject.rightToInformation.text2')}</Text>
              <Text>{t('dataPrivacy.dataSubject.rightOfRectification.text')}</Text>
              <Box>
                <Text pb={5}>{t('dataPrivacy.dataSubject.rightOfDeletion.text')}</Text>
                <Text>{t('dataPrivacy.dataSubject.rightOfDeletion.list.title')}</Text>
                <UnorderedList fontSize={14}>
                  <ListItem listStyleType="initial">
                    {t('dataPrivacy.dataSubject.rightOfDeletion.list.text1')}
                  </ListItem>
                  <ListItem listStyleType="initial">
                    {t('dataPrivacy.dataSubject.rightOfDeletion.list.text2')}
                  </ListItem>
                </UnorderedList>
              </Box>
              <Text>{t('dataPrivacy.dataSubject.rightToLimit.text')}</Text>
              <Box>
                <Text pb={5}>{t('dataPrivacy.dataSubject.rightToData.text')}</Text>
                <Text>{t('dataPrivacy.dataSubject.rightToData.list.title')}</Text>
                <UnorderedList fontSize={14}>
                  <ListItem listStyleType="initial">
                    {t('dataPrivacy.dataSubject.rightToData.list.text1')}
                  </ListItem>
                  <ListItem listStyleType="initial">
                    {t('dataPrivacy.dataSubject.rightToData.list.text2')}
                  </ListItem>
                </UnorderedList>
              </Box>
              <Text>{t('dataPrivacy.dataSubject.rightToObject.text')}</Text>
              <Text>{t('dataPrivacy.dataSubject.text')}</Text>
              <Text>{t('dataPrivacy.dataSubject.byEmail')}</Text>
              <Text>{t('dataPrivacy.dataSubject.byMail')}</Text>
            </Stack>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('dataPrivacy.rightToAppeal.title')}
            </Text>
            <Text>{t('dataPrivacy.rightToAppeal.text')}</Text>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('dataPrivacy.durationOfDataStorage.title')}
            </Text>
            <Text>{t('dataPrivacy.durationOfDataStorage.text')}</Text>
          </Box>
        </Stack>
      </Box>
    </MainLayout>
  );
};
