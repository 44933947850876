import { Image } from '@chakra-ui/image';
import { Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { FC } from 'types'
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/modal/Modal/Modal';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { IModalProps } from 'typings/interfaces/IModalProps';

interface IFeatureModalProps extends IModalProps {
  contentId: string;
}

export const FeatureModal: FC<IFeatureModalProps> = ({ contentId, onCancel }) => {
  const { t } = useTranslation();

  const content: Record<string, Record<string, string>> = {
    reporting: {
      title: t('welcomeBox.features.reporting.title'),
      description: t('welcomeBox.features.reporting.description.modal'),
      previewUrl: '/assets/images/statistics-preview.png',
      alt: t('welcomeBox.images.reportingAlt'),
    },
    communication: {
      title: t('welcomeBox.features.communication.title'),
      description: t('welcomeBox.features.communication.description.modal'),
      previewUrl: '/assets/images/initiating-preview.png',
      alt: t('welcomeBox.images.communicationAlt'),
    },
    collaboration: {
      title: t('welcomeBox.features.collaboration.title'),
      description: t('welcomeBox.features.collaboration.description.modal'),
      previewUrl: '/assets/images/rooms-preview.png',
      alt: t('welcomeBox.images.collaborationAlt'),
    },
  };

  return (
    <Modal isVisible sx={{ width: '700px' }} backdropClosable>
      <ModalHeader
        onCancel={onCancel}
        sx={{
          '.a-close-btn': {
            top: '-1.5rem',
            right: '-1.5rem',
            color: (theme) => `${theme.colors.brand.orange} !important`,
            fontSize: 16,
          },
        }}
      />
      <ModalBody sx={{ pt: '10px', px: '10px', pb: '48px' }}>
        <Image
          src={content[contentId].previewUrl}
          alt={content[contentId].alt}
          width="100%"
          height="auto"
        />
        <Heading textAlign="center" mb="10px" mt="20px" fontSize={22}>
          {content[contentId].title}
        </Heading>
        <Text width="520px" mx="auto" fontSize={14} lineHeight="24px">
          {content[contentId].description}
        </Text>
      </ModalBody>
    </Modal>
  );
};
