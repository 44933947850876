import React from 'react';
import { FC } from 'types'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

interface IRoomsListViewEnterRoomCellProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowData: Record<string, any>;
}

export const RoomsListViewEnterRoomCell: FC<IRoomsListViewEnterRoomCellProps> = ({ rowData }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const roomId = rowData.original.id;
  const handleEnterRoom = (roomId: string) => {
    history.push(`/rooms/${roomId}`);
  };
  return (
    <StyledButton onClick={() => handleEnterRoom(roomId)} size="sm" kind="primary">
      {t('rooms.listView.tableCell.action.enter')}
    </StyledButton>
  );
};
