import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { RoomDeleteModal } from 'components/room/RoomDeleteModal/RoomDeleteModal';
import { useModal } from 'hooks/useModal';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

interface IRoomDeleteProps {
  disabled?: boolean;
}

export const RoomDelete: FC<IRoomDeleteProps> = ({ disabled = false }) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useModal();

  return (
    <Fragment>
      <StyledButton size="md" kind="secondary" onClick={onOpen} disabled={disabled}>
        {t('room.sidebar.roomDetails.action.delete.label')}
      </StyledButton>
      {isOpen && <RoomDeleteModal onClose={onClose} />}
    </Fragment>
  );
};
