import { Box, BoxProps, HStack, Spacer, Text } from '@chakra-ui/react';
import { Slider as AppKitSlider } from 'appkit-react-slider';
import { FC } from 'types';
import { useState } from 'react';

import { StyledInputNumber } from 'components/core/StyledAppKit/StyledAppKit';

interface ISliderProps extends Omit<BoxProps, 'defaultValue' | 'value' | 'onChange'> {
  name: string;
  label?: string;
  symbol?: string;
  min?: number;
  max?: number;
  step?: number;
  defaultValue?: number;
  value?: number;
  disabled?: boolean;
  onChange?(value: number): void;
}
export const Slider: FC<ISliderProps> = ({
  name,
  label,
  symbol,
  min = -Infinity,
  max = Infinity,
  step = 1,
  value,
  defaultValue,
  disabled,
  onChange,
  ...rest
}) => {
  const [sliderValue, setSliderValue] = useState(value);

  const handleChange = (newValue: number) => {
    setSliderValue(newValue);
    if (!disabled) {
      onChange?.(newValue);
    }
  };

  return (
    <Box className="a-slider-wrapper" {...rest}>
      <HStack pb={20}>
        <Text>{label}</Text>
        <Spacer />
        <StyledInputNumber
          kind="arrow"
          inputBoxSize="lg"
          inputAttr={{ name }}
          min={min}
          max={max}
          step={step}
          value={sliderValue}
          disabled={disabled}
          onChange={handleChange}
        />
        {symbol && <Text ml={16}>{symbol}</Text>}
      </HStack>
      <AppKitSlider
        kind="slider"
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={handleChange}
      />
    </Box>
  );
};
