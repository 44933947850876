import { Text, Box, useBoolean, useDisclosure, Heading } from '@chakra-ui/react';
import { Fragment, useState } from 'react';
import { FC } from 'types'
import { useTranslation } from 'react-i18next';

import { RoomNameConfirmationModal } from 'components/room/RoomNameConfirmationModal/RoomNameConfirmationModal';
import { RoomNameInput } from 'components/room/RoomNameInput/RoomNameInput';
import { useUpdateRoom } from 'components/room/room.hooks';
import { IRoomDetailsRowProps } from 'typings/interfaces/IDetailsRowProps';
import { RoomDetailsRow } from 'components/room/RoomDetails/components/RoomDetailsRow/RoomDetailsRow';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

export const RoomNameRow: FC<IRoomDetailsRowProps> = ({
  model,
  disableEdit = false,
  readonly = false,
  onStartEdit,
  onStopEdit,
}) => {
  const [isEditMode, setEditMode] = useBoolean(false);
  const [updatedValue, setUpdatedValue] = useState(model.name);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { save, isSaving } = useUpdateRoom(model.id);
  const { t } = useTranslation();

  const stopEditing = () => {
    setEditMode.off();
    onStopEdit?.();
    onClose();
  };

  const handleClickConfirm = async () => {
    if (isSaving && !isEditMode && !readonly && isOpen) {
      return;
    }
    await save({ ...model.upsertDTO, name: updatedValue });
    stopEditing();
  };

  const handleClickCancel = () => {
    if (isSaving || !isEditMode) {
      return;
    }
    stopEditing();
  };

  const handleClickEdit = () => {
    if (isEditMode) {
      setEditMode.off();
      onStopEdit?.();
    } else {
      setEditMode.on();
      onStartEdit?.();
    }
  };

  const handleOnInputBlur = (newValue: string) => {
    setUpdatedValue(newValue);
    if (newValue === model.name) {
      stopEditing();
      return;
    }

    onOpen();
  };

  return (
    <Fragment>
      <RoomDetailsRow>
        <Heading as="h5" size="sm" w="100px">
          {t('room.sidebar.roomDetails.rows.name')}
        </Heading>
        <Box w="200px">
          {!isEditMode && <Text>{model.name}</Text>}
          {isEditMode && <RoomNameInput initialValue={model.name} onBlur={handleOnInputBlur} />}
        </Box>
        <Box w="47px">
          {!readonly && (
            <StyledButton
              size="md"
              kind="transparent"
              onClick={handleClickEdit}
              disabled={disableEdit && !isEditMode}
            >
              {t('action.edit.label')}
            </StyledButton>
          )}
        </Box>
      </RoomDetailsRow>
      {isOpen && (
        <RoomNameConfirmationModal
          isSaving={isSaving}
          onConfirm={handleClickConfirm}
          onCancel={handleClickCancel}
        />
      )}
    </Fragment>
  );
};
