import { FC } from 'types';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Tr, Td, HStack, Text, Center } from '@chakra-ui/react';

import { Avatar } from 'components/core/Avatar/Avatar';
import { ICreateRoomFields } from 'typings/interfaces/ICreateRoomFields';
import { RoomUserRole } from 'typings/enums/RoomUserRole';
import { IRoomUser } from 'typings/interfaces/IRoomUser';
import { CalendarPickerField } from 'fields/CalendarPickerField/CalendarPickerField';

import { CreateRoomUserRoleButtonGroupField } from '../CreateRoomUserRoleButtonGroupField/CreateRoomUserRoleButtonGroupField';

interface ICreateRoomUserManagementRowProps {
  user: IRoomUser;
  index: number;
  control: Control<ICreateRoomFields>;
  onRemove(): void;
  onExpirationDateChange(index: number, date: Date): void;
}
export const CreateRoomUserManagementRow: FC<ICreateRoomUserManagementRowProps> = ({
  user,
  index,
  control,
  onRemove,
  onExpirationDateChange,
}) => {
  const { t } = useTranslation();
  const watchRoomUsers = useWatch({ control, name: 'roomUsers' });
  const numberOfAdmins = watchRoomUsers.reduce(
    (acc, user) => (user.roomUserRole === RoomUserRole.Admin ? acc + 1 : acc),
    0,
  );

  const isLastAdmin =
    watchRoomUsers[index].roomUserRole !== RoomUserRole.Admin || numberOfAdmins > 1;

  return (
    <Tr key={user.userId}>
      <HStack as={Td}>
        <Avatar name={user.username} />
        <Text overflowWrap="anywhere">{user.displayName ?? user.username}</Text>
      </HStack>
      <Td>
        <CreateRoomUserRoleButtonGroupField
          numberOfAdmins={numberOfAdmins}
          control={control}
          name={`roomUsers.${index}.roomUserRole`}
        />
      </Td>
      <Td>
        <CalendarPickerField
          format="dd/mm/yyyy"
          name="expirationDate"
          placeholder={t('rooms.createRoom.usersManagement.datePicker.placeholder')}
          minDate={new Date()}
          onChange={(date: Date) => onExpirationDateChange(index, date)}
          sx={{
            width: '150px',
            mr: '10px',
            '.react-date-picker': {
              width: '100%',
              '.a-react-date-picker-calendar': {
                right: '0 !important',
                left: 'unset !important',
              },
            },
          }}
        />
      </Td>
      <Center as={Td}>
        <Box
          as="span"
          className="appkiticon icon-circle-delete-outline a-font-24 a-text-orange"
          cursor="pointer"
          onClick={isLastAdmin ? onRemove : undefined}
        />
      </Center>
    </Tr>
  );
};
