import { FC } from 'types';
import { Box, HStack, Flex, Text, useBoolean } from '@chakra-ui/react';

import { CampaignPermissionGroup } from 'models/CampaignPermissionGroup';
import { PermissionGroupsFormGroupsItemInput } from './components/PermissionGroupsFormGroupsItemInput/PermissionGroupsFormGroupsItemInput';

interface IPermissionGroupsFormGroupsItemProps {
  permissionGroup: CampaignPermissionGroup;
  isDefault: boolean;
  onDelete(groupId: string): void;
  onEdit(groupId: string, value: string): void;
}

export const PermissionGroupsFormGroupsItem: FC<IPermissionGroupsFormGroupsItemProps> = ({
  permissionGroup,
  isDefault,
  onDelete,
  onEdit,
}) => {
  const [isEditMode, setEditMode] = useBoolean(false);

  const handleClickEdit = () => {
    if (!isDefault) {
      if (isEditMode) {
        setEditMode.off();
      } else {
        setEditMode.on();
      }
    }
  };

  const handleOnInputBlur = (newValue: string) => {
    if (newValue === permissionGroup.name) {
      setEditMode.off();
      return;
    }
    onEdit(permissionGroup.id, newValue);
    setEditMode.off();
  };

  return (
    <HStack
      height="60px"
      spacing="10px"
      width="100%"
      sx={{
        borderBottom: ({ colors }) => `2px solid ${colors.grey.lvl3}`,
      }}
    >
      <Flex alignItems="center" justifyContent="space-between" w="100%">
        {!isEditMode && <Text onClick={handleClickEdit}>{permissionGroup.name}</Text>}
        {isEditMode && !isDefault && (
          <PermissionGroupsFormGroupsItemInput
            initialValue={permissionGroup.name}
            onBlur={handleOnInputBlur}
          />
        )}
        {!isDefault && (
          <Box
            className="appkiticon icon-circle-delete-outline a-font-18 a-text-orange"
            cursor="pointer"
            onClick={() => onDelete(permissionGroup.id)}
          />
        )}
      </Flex>
    </HStack>
  );
};
