import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { FormComponent } from 'models/FormComponent';
import { Addon } from 'components/form-editor/Question/components/Addon/Addon';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { useModal } from 'hooks/useModal';

import { ChoiceEditorModal } from '../ChoiceEditorModal/ChoiceEditorModal';

interface IChoiceEditorProps {
  question: FormComponent;
  orderTag: string;
}
export const ChoiceEditor: FC<IChoiceEditorProps> = observer(({ orderTag, question }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useModal();

  const name = t(
    question.componentType === FormComponentType.MultipleChoice
      ? 'form.settings.multipleChoice.name'
      : 'form.settings.singleChoice.name',
  );

  return (
    <Fragment>
      <Addon
        isInvalid={!question.hasValidSettings}
        name={name}
        disabled={!question.text}
        onClick={onOpen}
      />
      {isOpen && <ChoiceEditorModal question={question} orderTag={orderTag} onClose={onClose} />}
    </Fragment>
  );
});
