import { useRouteMatch } from 'react-router-dom';
import { Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { FormEditorViewMenuItem } from './FormEditorViewMenuItem';
import { FormEditorViewMenuContainer } from './FormEditorViewMenu.elements';

export const FormEditorViewMenu: FC = () => {
  const { t } = useTranslation();
  const {
    params: { roomId, formId },
    url,
  } = useRouteMatch<{ roomId: string; formId: string }>();

  const editorViewUrl = `/rooms/${roomId}/forms/${formId}`;
  const isEditViewVisible = url !== editorViewUrl && url !== `${editorViewUrl}/`;

  const sortingViewUrl = `/rooms/${roomId}/forms/${formId}/sorting`;
  // const isSortViewVisible = url !== sortingViewUrl && url !== `${sortingViewUrl}/`;

  const testingViewUrl = `/rooms/${roomId}/forms/${formId}/assessment`;
  const isTestViewVisible = url !== testingViewUrl && url !== `${testingViewUrl}/`;

  return (
    <FormEditorViewMenuContainer>
      <FormEditorViewMenuItem isVisible={isEditViewVisible} linkTo={editorViewUrl}>
        <Text size="sm">{t('form.view.edit')}</Text>
      </FormEditorViewMenuItem>
      <FormEditorViewMenuItem isVisible={isTestViewVisible} linkTo={testingViewUrl}>
        <Text size="sm">{t('form.view.test')}</Text>
      </FormEditorViewMenuItem>
      {/* TODO: uncomment when ready 
      <FormEditorViewMenuItem isVisible={url !== sortingViewUrl} linkTo={sortingViewUrl}> 
      */}
      <FormEditorViewMenuItem isVisible={false} linkTo={sortingViewUrl}>
        <Text size="sm">{t('form.view.sort')}</Text>
      </FormEditorViewMenuItem>
    </FormEditorViewMenuContainer>
  );
};
