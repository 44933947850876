import { Attribute } from '@datx/core';
import { computed } from 'mobx';
import { format } from 'date-fns';

import { Resource } from './Resource';
import { CampaignResponseActivationStatus } from 'typings/enums/CampaignResponseActivationStatus';
import { CampaignUserRole } from 'typings/enums/CampaignUserRole';

export class CampaignResponse extends Resource {
  public static type = 'campaign_response';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public name!: string;

  @Attribute()
  public assessmentCount!: number;

  @Attribute()
  public initiatorsCount!: number;

  @Attribute()
  public dueDate!: string;

  @Attribute()
  public activationStatus!: CampaignResponseActivationStatus;

  @Attribute()
  public currentUserRole!: CampaignUserRole;

  @computed
  public get formattedDueDate() {
    if (this.dueDate) {
      return format(new Date(this.dueDate), 'PPpp');
    }
    return '/';
  }
}
