import { Text } from '@chakra-ui/react';
import { FC } from 'types';
import { Badge } from 'appkit-react';
import { useTranslation } from 'react-i18next';

export const AuditItemTypeBadge: FC<{ type: string }> = ({ type }) => {
  const { t } = useTranslation();

  const badgeType: Record<string, string> = {
    Room: 'primary',
    RoomUser: 'secondary',
    RoomFolder: 'info',
    Form: 'warning',
    FormUser: 'secondary',
    FormComment: 'info',
    FormApproval: 'secondary',
    Component: 'primary',
    Assessment: 'warning',
    AssessmentPermissionGroupUser: 'secondary',
    AssessmentApprover: 'info',
    AssessmentReviewComment: 'info',
    AssessmentApproval: 'secondary',
  };

  const translatedText: Record<string, string> = {
    Room: t('room.sidebar.roomDetails.modals.history.auditItem.badgeType.room').toUpperCase(),
    RoomUser: t(
      'room.sidebar.roomDetails.modals.history.auditItem.badgeType.roomUser',
    ).toUpperCase(),
    RoomFolder: t(
      'room.sidebar.roomDetails.modals.history.auditItem.badgeType.roomFolder',
    ).toUpperCase(),
    Form: t('room.sidebar.roomDetails.modals.history.auditItem.badgeType.form').toUpperCase(),
    FormUser: t(
      'room.sidebar.roomDetails.modals.history.auditItem.badgeType.formUser',
    ).toUpperCase(),
    FormComment: t(
      'room.sidebar.roomDetails.modals.history.auditItem.badgeType.formComment',
    ).toUpperCase(),
    FormApproval: t(
      'room.sidebar.roomDetails.modals.history.auditItem.badgeType.formApproval',
    ).toUpperCase(),
    Component: t('room.history.auditItem.badgeType.component').toUpperCase(),
    Assessment: t('assessment.history.auditItem.badgeType.assessment').toUpperCase(),
    AssessmentPermissionGroupUser: t(
      'assessment.history.auditItem.badgeType.assessmentPermissionGroupUser',
    ).toUpperCase(),
    AssessmentApprover: t(
      'assessment.history.auditItem.badgeType.assessmentApprover',
    ).toUpperCase(),
    AssessmentReviewComment: t(
      'assessment.history.auditItem.badgeType.assessmentComment',
    ).toUpperCase(),
    AssessmentApproval: t(
      'assessment.history.auditItem.badgeType.assessmentApproval',
    ).toUpperCase(),
  };

  return (
    <Badge size="small" type={badgeType[type]}>
      <Text
        sx={{
          fontSize: '12px',
          lineHeight: '18px',
          letterSpacing: '0',
        }}
      >
        {translatedText[type]}
      </Text>
    </Badge>
  );
};
