import { useAuth } from 'oidc-react';

import { User } from 'models/User';
import { useDatx } from 'hooks/useDatx';

export const useCurrentUser = () => {
  const { userData } = useAuth();
  const datx = useDatx();

  const currentUser = datx
    .findAll<User>(User)
    .find((user) => user.email.toLowerCase() === userData?.profile.preferredMail?.toLowerCase());

  return currentUser ?? null;
};
