import { FC, SetState } from 'types';
import { Table } from 'appkit-react';
import { TFunction, useTranslation } from 'react-i18next';
import { Box, useBoolean } from '@chakra-ui/react';

import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { CampaignResponse } from 'models/CampaignResponse';
import { dateSortMethod } from 'utils/dateSortMethod';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { Checkbox } from 'appkit-react';

import { FormCampaignsTableViewCellNameWrapper } from './components/FormCampaignsTableViewCellNameWrapper/FormCampaignsTableViewCellNameWrapper';
import { useEffect, useState } from 'react';
import { CampaignExportModal } from 'components/form-campaigns/CampaignExportModal/CampaignExportModal';
import { TableCellRow } from 'typings/types/TableCellRow';

interface IFormCampaignsTableViewProps {
  campaigns: Array<CampaignResponse>;
  formId: string;
  isLoading: boolean;
}

const campaignsColumns = (
  t: TFunction,
  selectedIds: string[],
  setSelectedIds: SetState<string[]>,
  campaigns: Array<CampaignResponse>,
) => [
  {
    Cell: (row: TableCellRow) => {
      const id = row.original.id;
      const isChecked = selectedIds.includes(id);
      return (
        <Checkbox
          defaultChecked={isChecked}
          onChange={() => {
            if (isChecked) {
              setSelectedIds((prev) => prev.filter((x) => x !== id));
            } else {
              setSelectedIds((prev) => [...prev, id]);
            }
          }}
        />
      );
    },
    minWidth: 30,
    Header: () => {
      const isChecked = selectedIds.length === campaigns.length;
      return (
        <Checkbox
          defaultChecked={isChecked}
          onChange={() => {
            if (isChecked) {
              setSelectedIds([]);
            } else {
              setSelectedIds(campaigns.map((x) => x.id));
            }
          }}
        />
      );
    },
  },
  {
    Header: t('campaigns.tableView.tableColumns.name'),
    accessor: 'name',
    Cell: FormCampaignsTableViewCellNameWrapper,
    minWidth: 300,
  },
  {
    Header: t('campaigns.tableView.tableColumns.totalAssessments'),
    accessor: 'assessmentCount',
    minWidth: 200,
  },
  {
    Header: t('campaigns.tableView.tableColumns.totalInitiators'),
    accessor: 'initiatorsCount',
    minWidth: 200,
  },
  {
    Header: t('campaigns.tableView.tableColumns.dueDate'),
    accessor: 'formattedDueDate',
    minWidth: 200,
    sortMethod: dateSortMethod,
  },
];

export const FormCampaignsTableView: FC<IFormCampaignsTableViewProps> = ({
  campaigns,
  formId,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [isExportModalVisible, { on, off }] = useBoolean(false);
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);

  useEffect(() => {
    if (!isLoading) {
      setSelectedIds(campaigns.map((x) => x.id));
    }
  }, [isLoading]);

  return (
    <TableWrapper isLoading={isLoading}>
      <Table
        columns={campaignsColumns(t, selectedIds, setSelectedIds, campaigns)}
        data={campaigns}
        showPageSizeOptions={false}
        defaultPageSize={25}
        defaultSorted={[{ id: 'name', asc: true }]}
      />
      <Box position="absolute" right="0" bottom="0">
        <StyledButton size="md" kind="secondary" onClick={on} disabled={selectedIds.length === 0}>
          {t('export.action.campaigns')}
        </StyledButton>
        {isExportModalVisible && (
          <CampaignExportModal formId={formId} selectedIds={selectedIds} onClose={off} />
        )}
      </Box>
    </TableWrapper>
  );
};
