import { Breadcrumb } from 'appkit-react';
import { NavLink } from 'react-router-dom';
import { Skeleton } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { BreadcrumbItem, BreadcrumbItemText } from 'components/core/BreadcrumbItem/BreadcrumbItem';

interface IFormBreadcrumbsProps {
  formId: string;
  roomId: string;
  campaignId?: string;
  formName?: string;
  campaignName?: string;
}

export const FormBreadcrumbs: FC<IFormBreadcrumbsProps> = ({
  roomId,
  formId,
  campaignId,
  formName = '',
  campaignName = '',
}) => {
  const { t } = useTranslation();

  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <NavLink to="/forms">
          <span className="appkiticon icon-news-outline a-font-24" />
        </NavLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <NavLink to={`/rooms/${roomId}/forms/${formId}`}>
          <BreadcrumbItemText className="a-breadcrumb-item-text a-font-16">
            <Skeleton isLoaded={Boolean(formName)} startColor="brand.orange">
              {formName || '*'}
            </Skeleton>
          </BreadcrumbItemText>
        </NavLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <NavLink to={`/rooms/${roomId}/forms/${formId}/campaign`}>
          <BreadcrumbItemText className="a-breadcrumb-item-text a-font-16">
            <Skeleton isLoaded={Boolean(formName)} startColor="brand.orange">
              {t('form.breadcrumbs.campaign')}
            </Skeleton>
          </BreadcrumbItemText>
        </NavLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <NavLink to={`/rooms/${roomId}/forms/${formId}/campaign/${campaignId}`}>
          <BreadcrumbItemText className="a-breadcrumb-item-text a-font-16">
            <Skeleton isLoaded={Boolean(formName && campaignName)} startColor="brand.orange">
              {campaignName}
            </Skeleton>
          </BreadcrumbItemText>
        </NavLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};
