import { useMemo } from 'react';
import { useTenant } from './useTenant';

export const useDependencyHeaders = () => {
  const { activeTenant } = useTenant();

  const dependencyHeadersObject = useMemo(
    () => ({
      'x-site-id': activeTenant?.siteId,
    }),
    [activeTenant?.siteId],
  );
  return dependencyHeadersObject;
};
