import { useMemo } from 'react';

import { useDatx } from 'hooks/useDatx';
import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { useAssessmentForm } from 'context/AssessmentContext/AssessmentContextProvider';
import { getPermittedComponents } from 'services/assessmentPermissionGroups';
import { UserAssessmentComponent } from 'typings/types/UserAssessmentComponent';
import { useCurrentUser } from 'hooks/useCurrentUser';

export const useAssessmentSetQuestions = (questionSetId: string) => {
  const { isBookmarkFilterOn, assessment, assessmentId, isInitiator, skippedComponents } =
    useAssessmentForm();
  const datx = useDatx();
  const currentUser = useCurrentUser();

  const joint = `/${skippedComponents.join('/')}/`; // used as a dependency to trigger recalculation

  return useMemo(() => {
    const componentIds = getPermittedComponents(datx, assessmentId, currentUser);

    const sections = datx
      .findAll<AssessmentFormComponent>(AssessmentFormComponent)
      .reduce((acc: Array<UserAssessmentComponent>, component) => {
        if (
          component.parentComponentId !== questionSetId ||
          joint.includes(`/${component.id}/`) ||
          (isBookmarkFilterOn && !component.isBookmarked)
        ) {
          return acc;
        }

        const canEdit = assessment?.isTest || componentIds.includes(component.formComponentId);
        if (canEdit) {
          acc.push({ readonly: false, component });
          return acc;
        }

        if (isInitiator) {
          acc.push({ readonly: true, component });
          return acc;
        }

        return acc;
      }, [])
      .sort((s1, s2) => s1.component.index - s2.component.index);

    return sections;
  }, [
    assessment?.isTest,
    assessmentId,
    currentUser,
    datx,
    isBookmarkFilterOn,
    isInitiator,
    joint,
    questionSetId,
  ]);
};
