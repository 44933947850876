export const Text = {
  baseStyle: {
    fontWeight: 'normal',
    fontFamily: 'body',
    color: 'white',
    lineHeight: '1.5em',
  },
  sizes: {
    '2xs': {
      fontSize: '8',
      // lineHeight: '12',
    },
    xs: {
      fontSize: '10',
      // lineHeight: '16',
    },
    sm: {
      fontSize: '12',
      // lineHeight: '18',
    },
    md: {
      fontSize: '14',
      // lineHeight: '22',
    },
    lg: {
      fontSize: '16',
      // lineHeight: '24',
    },
    xl: {
      fontSize: '18',
      // lineHeight: '27',
    },
    '2xl': {
      fontSize: '22',
      // lineHeight: '33',
    },
    '3xl': {
      fontSize: '24',
      // lineHeight: '36',
    },
    '4xl': {
      fontSize: '28',
      // lineHeight: '42',
    },
    '5xl': {
      fontSize: '32',
      // lineHeight: '48',
    },
    '6xl': {
      fontSize: '40',
      // lineHeight: '70',
    },
  },
  defaultProps: {
    size: 'md',
  },
};
