import { useDisclosure } from '@chakra-ui/react';
import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { useAssessmentApproval } from 'hooks/useAssessmentApproval';
import { useAssessment } from 'hooks/useAssessment';
import { AssessmentStatus } from 'typings/enums/AssessmentStatus';
import { AssessmentViewActivationStatus } from 'typings/enums/AssessmentViewActivationStatus';

import { ValidateAndSubmitModal } from '../ValidateAndSubmitModal/ValidateAndSubmitModal';

interface IValidateAndSubmitButtonProps {
  assessmentId: string;
}
export const ValidateAndSubmitButton: FC<IValidateAndSubmitButtonProps> = ({ assessmentId }) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { approval, isInitialLoad: isInitialApprovalLoad } = useAssessmentApproval(assessmentId);
  const { assessment, isInitialLoad: isInitialAssessmentLoad } = useAssessment(assessmentId);

  const isLoading = Boolean(isInitialApprovalLoad || isInitialAssessmentLoad);
  const disabled =
    isLoading ||
    assessment?.activationStatus === AssessmentViewActivationStatus.Deactivated ||
    assessment?.status === AssessmentStatus.Overdue ||
    assessment?.status === AssessmentStatus.Submitted ||
    assessment?.status === AssessmentStatus.Initiated ||
    assessment?.status === AssessmentStatus.Created ||
    (approval?.isSetUp && assessment?.status !== AssessmentStatus.Approved);

  return (
    <Fragment>
      <StyledButton size="md" disabled={disabled} onClick={onOpen}>
        {t('assessment.action.submitButton')}
      </StyledButton>
      {isOpen && <ValidateAndSubmitModal onClose={onClose} />}
    </Fragment>
  );
};
