import { Attribute } from '@datx/core';
import { format } from 'date-fns';
import { computed } from 'mobx';

import { Resource } from 'models/Resource';
import { RoomStatus } from 'typings/enums/RoomStatus';
import { RoomType } from 'typings/enums/RoomType';

export class RoomAdmin extends Resource {
  public static type = 'rooms_for_admin';
  public static endpoint = 'rooms/admin';

  @Attribute({ isIdentifier: true })
  public readonly id!: string;

  @Attribute()
  public readonly description!: string;

  @Attribute()
  public readonly name!: string;

  @Attribute({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parse: (_, data: Record<string, any>) => data.roomStatusChange.requestedAt,
  })
  public readonly requestedAt!: string;

  @Attribute({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parse: (_, data: Record<string, any>) => data.roomStatusChange.requestedBy,
  })
  public readonly requestedBy!: string;

  @Attribute({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parse: (_, data: Record<string, any>) => data.roomStatusChange.lastStatusChangeAt,
  })
  public readonly lastStatusChangeAt!: string;

  @Attribute({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parse: (_, data: Record<string, any>) => data.roomStatusChange.lastStatusChangeBy,
  })
  public readonly lastStatusChangeBy!: string;

  @Attribute()
  public roomStatus!: RoomStatus;

  @Attribute()
  public roomType!: RoomType;

  @computed
  public get formattedRequestedAt() {
    if (this.requestedAt) {
      return format(new Date(this.requestedAt), 'PPp');
    }

    return '/';
  }

  @computed
  public get formattedLastStatusChangeAt() {
    if (this.lastStatusChangeAt) {
      return format(new Date(this.lastStatusChangeAt), 'PPp');
    }

    return '/';
  }
}
