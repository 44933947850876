import React from 'react';
import { FC } from 'types'
import { TableWrapperLoading } from './components/TableWrapperLoading';
import { StyledTableWrapper } from './TableWrapper.elements';

interface ITableProps {
  isLoading: boolean;
}

export const TableWrapper: FC<ITableProps> = ({ isLoading, children }) => {
  return (
    <StyledTableWrapper>
      {isLoading && <TableWrapperLoading />}
      {children}
    </StyledTableWrapper>
  );
};
