import { chakra } from '@chakra-ui/react';

export const ActionsMenu = chakra('div', {
  baseStyle: {
    position: 'fixed',
    width: '200px',
    py: 12,
    bg: 'utils.grey.300',
    boxShadow: 'mainWhiteSmall',
  },
});
