import { Box, Text, Flex, Input } from '@chakra-ui/react';
import { useState, KeyboardEvent } from 'react';
import { FC } from 'types';
import { SelectOption } from 'appkit-react';
import { Control, useFieldArray, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledSelectField } from 'fields/SelectField/SelectField';
import { ControlledInputField } from 'fields/InputField/InputField';
import { RemoveButton } from 'components/form-editor/Question/components/RemoveButton/RemoveButton';
import { DomainType } from 'typings/enums/DomainType';
import { DomainStatus } from 'typings/enums/DomainStatus';
import { IFormData } from './SiteManagementForm';

interface IDomainManagementProps {
  control: Control<IFormData>;
}

export const DomainManagement: FC<IDomainManagementProps> = ({ control }) => {
  const [domain, setDomain] = useState<string>('');

  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'domains',
  });

  const handleKeypress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && domain !== '') {
      e.preventDefault();
      // @ts-expect-error
      append({
        name: domain,
        id: '',
        type: DomainType.Dominant,
        status: DomainStatus.Active,
      });
      setDomain('');
    }
  };

  return (
    <Box mt={20}>
      <Flex
        alignItems="center"
        sx={{
          backgroundColor: 'grey.lvl3',
          height: '34px',
          padding: 12,
        }}
      >
        <Text size="md" width="300px">
          {t('applicationAdmin.site.modal.form.domains.table.columns.name')}
        </Text>
        <Text size="md" width="300px">
          {t('applicationAdmin.site.modal.form.domains.table.columns.id')}
        </Text>
        <Text size="md" width="220px">
          {t('applicationAdmin.site.modal.form.domains.table.columns.status')}
        </Text>
        <Text size="md" width="220px">
          {t('applicationAdmin.site.modal.form.domains.table.columns.type')}
        </Text>
      </Flex>

      <Box
        sx={{
          '> div': {
            backgroundColor: 'grey.lvl6',
            ':nth-of-type(even)': {
              backgroundColor: 'grey.lvl5',
            },
          },
        }}
      >
        {fields.map((field, index) => (
          <Flex
            key={`${field.id}-${index}`}
            alignItems="center"
            sx={{
              height: '34px',
              py: 12,
            }}
          >
            <ControlledInputField
              control={control as unknown as Control<FieldValues>}
              htmlFor={`domains.${index}.name`}
              name={`domains.${index}.name` as const}
              placeholder={t('applicationAdmin.site.modal.form.domains.table.placeholders.name')}
              defaultValue={field.name}
              sx={{ width: '300px' }}
            />

            <ControlledInputField
              control={control as unknown as Control<FieldValues>}
              htmlFor={`domains.${index}.id`}
              name={`domains.${index}.id` as const}
              placeholder={t('applicationAdmin.site.modal.form.domains.table.placeholders.domain')}
              defaultValue={field.id}
              sx={{ width: '300px' }}
            />

            <ControlledSelectField
              control={control as unknown as Control<FieldValues>}
              htmlFor={`domains.${index}.status`}
              name={`domains.${index}.status` as const}
              defaultValue={DomainStatus.Active}
              sx={{ width: '220px' }}
            >
              <SelectOption value={DomainStatus.Active}>{DomainStatus.Active}</SelectOption>
              <SelectOption value={DomainStatus.Inactive}>{DomainStatus.Inactive}</SelectOption>
            </ControlledSelectField>

            <ControlledSelectField
              control={control as unknown as Control<FieldValues>}
              htmlFor={`domains.${index}.type`}
              name={`domains.${index}.type` as const}
              defaultValue={DomainType.Dominant}
              sx={{ width: '220px' }}
            >
              <SelectOption value={DomainType.Dominant}>{DomainType.Dominant}</SelectOption>
              <SelectOption value={DomainType.Secondary}>{DomainType.Secondary}</SelectOption>
            </ControlledSelectField>

            <RemoveButton
              onClick={() => {
                remove(index);
              }}
              sx={{
                ml: 2,
              }}
            />
          </Flex>
        ))}
        <Flex w="100%">
          <Input
            variant="unstyled"
            placeholder={t('applicationAdmin.site.modal.form.domains.table.input')}
            value={domain}
            onChange={(event) => setDomain(event.target.value)}
            onKeyPress={handleKeypress}
            sx={{
              _placeholder: {
                color: 'grey.lvl4',
              },
              height: '34px',
              padding: 12,
            }}
          />
        </Flex>
      </Box>
    </Box>
  );
};
