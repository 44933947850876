import { BoxProps, useBoolean } from '@chakra-ui/react';
import { FC } from 'types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useRoomId } from 'components/room/room.hooks';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { Form } from 'models/Form';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { RoomCellMenu } from 'components/room/room.elements';

import { RoomCellMenuItem } from '../RoomCellMenuItem/RoomCellMenuItem';
import { useFormDelete, useFormMutate } from '../../RoomsItemsList.hooks';

interface IFormMenuProps extends BoxProps {
  form: Form;
  onClose(): void;
  onInitiateCampaignOpen(): void;
  onEditModalOpen(): void;
  onShareModalOpen(): void;
  onDuplicateModalOpen(): void;
}

export const FormMenu: FC<IFormMenuProps> = ({
  form,
  onInitiateCampaignOpen,
  onEditModalOpen,
  onShareModalOpen,
  onDuplicateModalOpen,
  onClose,
  ...props
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const roomId = useRoomId();

  const deleteMutation = useFormDelete(form.id);
  const favoriteMutation = useFormMutate(form, HttpMethod.Post, `/${form.id}/setFavorite`);

  const [isDeleting, setIsDeleting] = useBoolean();
  const [isFavoriting, setIsFavoriting] = useBoolean();

  const handleOpen = () => {
    history.push(`/rooms/${roomId}/forms/${form.id}`);
    onClose();
  };

  const handleRename = () => {
    onEditModalOpen();
    onClose();
  };

  const handleShare = () => {
    onShareModalOpen();
    onClose();
  };

  const handleDuplicate = () => {
    onDuplicateModalOpen();
    onClose();
  };

  // For V2
  // const handleCopy = () => {
  //   onCopyModalOpen();
  //   onClose();
  // };

  const handleInitiateCampaign = () => {
    onInitiateCampaignOpen();
    onClose();
  };

  const handleDelete = async () => {
    setIsDeleting.on();
    try {
      await deleteMutation.mutate();
      PopAlertSuccess(t('form.delete.success', { formName: form.name }));
      onClose();
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 403) {
        PopAlertFail(t('form.delete.error.title'), t('form.delete.error.permission'));
      } else {
        PopAlertFail(
          t('form.delete.error.title'),
          response.message || t('form.delete.error.unexpected'),
        );
      }
    } finally {
      setIsDeleting.off();
    }
  };

  const handleFavorite = async () => {
    setIsFavoriting.on();
    try {
      await favoriteMutation.mutate({
        isFavorite: !form.isFavorite,
      });
    } catch (response: any) { // eslint-disable-line
      PopAlertFail(t('forms.favorite.error.title'), response.message);
    } finally {
      setIsFavoriting.off();
    }
  };

  return (
    <RoomCellMenu {...props}>
      <RoomCellMenuItem
        label={t('form.menu.open')}
        iconName="icon-purpose-outline"
        onClick={handleOpen}
      />
      <RoomCellMenuItem
        label={t('form.menu.initiateCampaign')}
        iconName="icon-email-outline"
        onClick={handleInitiateCampaign}
      />
      <RoomCellMenuItem
        label={t('form.menu.shareWith')}
        iconName="icon-add-user-outline"
        onClick={handleShare}
      />
      <RoomCellMenuItem
        label={t('form.menu.duplicate')}
        iconName="icon-duplicate-outline"
        onClick={handleDuplicate}
      />
      {/* Not ready on the backend */}
      {/* <RoomCellMenuItem label="Move to" iconName="icon-move-outline" onClick={console.log} /> */}
      {/* For V2 */}
      {/* <RoomCellMenuItem
        label={t('form.menu.sendCopy')}
        iconName="icon-duplicate-outline"
        onClick={handleCopy}
      /> */}
      <RoomCellMenuItem
        label={form.isFavorite ? t('form.menu.removeFromFavorites') : t('form.menu.addToFavorites')}
        iconName={form.isFavorite ? 'icon-rating-fill' : 'icon-rating-outline'}
        isLoading={isFavoriting}
        onClick={handleFavorite}
      />
      <RoomCellMenuItem
        label={t('form.menu.rename')}
        iconName="icon-edit-outline"
        onClick={handleRename}
      />
      <RoomCellMenuItem
        label={t('form.menu.remove')}
        iconName="icon-delete-outline"
        isLoading={isDeleting}
        onClick={handleDelete}
      />
    </RoomCellMenu>
  );
};
