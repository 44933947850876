import { Box, BoxProps } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { FC } from 'types'
import { useTranslation } from 'react-i18next';
import { Control, FieldValues, useForm } from 'react-hook-form';

import { IApiClientFnResult } from 'hooks/useApiClient';
import { ControlledInputField } from 'fields/InputField/InputField';
import { FormUserManagement } from 'components/form-users/FormUserManagement/FormUserManagement';
import { NotificationTextFormField } from 'components/core/NotificationTextFormField/NotificationTextFormField';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { IFormUserManagementFields } from 'typings/interfaces/IFormUserManagementFields';
import { IResourceUser } from 'typings/interfaces/IResourceUser';
import { isApiFormError } from 'utils/isApiFormError';
import { PopAlertFail } from 'services/PopAlertFail';
import { parseApiFormError } from 'utils/parseApiFormError';
import { PopAlertSuccess } from 'services/PopAlertSuccess';

interface IDuplicateFormFormValues extends IFormUserManagementFields {
  name: string;
  skipSendingNotification: boolean;
  notificationText: string;
}
interface IDuplicateFormFormProps extends BoxProps {
  availableUsers: Array<IResourceUser>;
  isLoading?: boolean;
  onSave(data?: Record<string, unknown> | undefined): Promise<IApiClientFnResult<unknown>>;
  onSuccess?(): void;
  onCancel(): void;
}

export const DuplicateFormForm: FC<IDuplicateFormFormProps> = ({
  availableUsers,
  isLoading,
  onSave,
  onSuccess,
  onCancel,
  ...rest
}) => {
  const { t } = useTranslation();
  const {
    control,
    setError,
    clearErrors,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IDuplicateFormFormValues>({
    defaultValues: {
      name: '', // Prevent =>  Warning: A component is changing an uncontrolled input to be controlled
      notificationText: '',
      skipSendingNotification: false,
      users: [],
    },
  });
  const watchUsers = watch('users');
  useEffect(() => {
    if (errors.users && watchUsers.length > 0) {
      clearErrors('users');
    }
  }, [clearErrors, errors.users, watchUsers]);

  const handleSave = useCallback(
    async (formValues: IDuplicateFormFormValues) => {
      clearErrors();

      if (!formValues.users.length) {
        setError('users', { message: t('userManagementSearch.validation.empty') });
        return;
      }

      if (!formValues.skipSendingNotification && !formValues.notificationText) {
        setError('notificationText', {
          message: t('notificationTextField.validation.empty'),
        });
        return;
      }

      const { error } = await onSave({
        ...formValues,
        users: formValues.users.map((user) => ({
          id: user.userId,
          role: user.role,
          defaultInitiatorStatus: user.defaultInitiatorStatus,
        })),
      });
      if (error) {
        if (isApiFormError(error)) {
          const filedErrors = parseApiFormError<IDuplicateFormFormValues>(error);
          filedErrors.forEach((fieldError) =>
            setError(fieldError.name, { message: fieldError.message }),
          );
        }
        PopAlertFail(t('room.errors.createForm.main'), error.message);
      } else {
        onSuccess?.();
        PopAlertSuccess(
          t('room.modals.createForm.successMessages.created', { name: formValues.name }),
        );
        onCancel();
      }
    },
    [clearErrors, onCancel, onSave, onSuccess, setError, t],
  );

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)} {...rest}>
      <ControlledInputField
        control={control as unknown as Control<FieldValues>}
        htmlFor="name"
        name="name"
        rules={{ required: { value: true, message: t('validation.required') } }}
        label={t('createForm.formSettings.formName.label')}
      />
      <FormUserManagement
        control={control as unknown as Control<IFormUserManagementFields>}
        availableUsers={availableUsers}
        mt={20}
      />
      <NotificationTextFormField
        control={control as unknown as Control<FieldValues>}
        nameTextEditor="notificationText"
        nameCheckBox="skipSendingNotification"
        mt={24}
      />

      <FormActions>
        <FormButtonCancel onClick={onCancel} />
        <FormButtonSubmit isLoading={isLoading} disabled={isLoading} />
      </FormActions>
    </Box>
  );
};
