import { Box, HStack, VStack } from '@chakra-ui/react';
import { FC } from 'types';
import { SelectOption } from 'appkit-react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledSelectField } from 'fields/SelectField/SelectField';
import { SwitchField } from 'fields/SwitchField/SwitchField';
import { DateFormats, DateSettings } from 'typings/types/FormComponentSettings';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { FormFieldHelp } from 'components/core/FormFieldHelp/FormFieldHelp';

interface IDateSettingsFormProps {
  settings: DateSettings;
  disabled?: boolean;
  onSubmit(value: DateSettings): void;
  onCancel(): void;
}

export const DateSettingsForm: FC<IDateSettingsFormProps> = ({
  settings,
  disabled,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<DateSettings>({
    defaultValues: {
      dateFormat: settings.dateFormat,
      dateRangePicker: settings.dateRangePicker,
    },
  });

  const handleSave = (values: DateSettings) => {
    if (disabled) {
      return;
    }
    onSubmit(values);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)}>
      <VStack spacing={20}>
        <HStack width="100%" alignItems="flex-end">
          <ControlledSelectField
            control={control as unknown as Control<FieldValues>}
            name="dateFormat"
            label={t('form.settings.date.dateFormatLabel')}
            disabled={disabled}
          >
            {DateFormats.map((format, index) => (
              <SelectOption key={index} value={format}>
                {format}
              </SelectOption>
            ))}
          </ControlledSelectField>
          <FormFieldHelp>Helper text</FormFieldHelp>
        </HStack>
        <HStack width="100%" alignItems="center">
          <SwitchField
            control={control as unknown as Control<FieldValues>}
            name="dateRangePicker"
            label={t('form.settings.date.dateRangePickerLabel')}
            disabled={disabled}
          />
          <FormFieldHelp>Helper text</FormFieldHelp>
        </HStack>
      </VStack>

      <FormActions>
        <FormButtonCancel onClick={onCancel} />
        <FormButtonSubmit disabled={disabled} />
      </FormActions>
    </Box>
  );
};
