export enum DraggingAction {
  AddSectionComponent = 'AddSectionComponent',
  AddQuestionComponent = 'AddQuestionComponent',
  AddAddon = 'AddAddon',
  AddConditionAddon = 'AddConditionAddon',
  AddSkipConditionAddon = 'AddSkipConditionAddon',
  AddMoreAnswersAddon = 'AddMoreAnswersAddon',
  MoveSection = 'MoveSection',
  MoveQuestion = 'MoveQuestion',
  MoveAddon = 'MoveAddon',
  AddConclusion = 'AddConlusion',
  MoveConclusion = 'MoveConslusion',
  AddConditionGroup = 'AddConditionGroup',
  MoveConditionGroup = 'MoveConditionGroup',
  None = 'None',
}
