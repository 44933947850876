import { FC } from 'types';
import { Draggable } from 'react-beautiful-dnd';
import { TableRowProps, Tr } from '@chakra-ui/react';

import { DraggableTrProvider } from 'context/DraggableTrContext';

export interface IDraggableTrProps extends TableRowProps {
  draggableId: string;
  index: number;
}
export const DraggableTr: FC<IDraggableTrProps> = ({
  draggableId,
  index,
  children,
  sx,
  ...rest
}) => {
  return (
    <Draggable key={draggableId} draggableId={`${draggableId}`} index={index}>
      {(provided) => (
        <Tr ref={provided.innerRef} {...provided.draggableProps} sx={sx} {...rest}>
          <DraggableTrProvider dragHandleProps={provided.dragHandleProps}>
            {children}
          </DraggableTrProvider>
        </Tr>
      )}
    </Draggable>
  );
};
