import { Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ConfirmationModal } from 'components/modal/ConfirmationModal/ConfirmationModal';
import { useDeleteForm } from 'hooks/useRoomForms';
import { ParseAndPopAlertFail } from 'services/ParseAndPopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';

interface IFormDeleteModalProps {
  roomId: string;
  formId: string;
  onClose(): void;
}
export const FormDeleteModal: FC<IFormDeleteModalProps> = ({ formId, roomId, onClose }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { deleteForm } = useDeleteForm(formId, roomId);
  const history = useHistory();

  const handleDelete = async () => {
    setIsLoading(true);
    const { error } = await deleteForm();
    if (error) {
      ParseAndPopAlertFail(error, t);
    } else {
      PopAlertSuccess(t('form.delete.success'));
      history.push('/forms');
    }
    setIsLoading(false);
  };

  return (
    <ConfirmationModal
      maxWidth="480px"
      title={t('form.delete.modal.title')}
      confirmText={t('form.delete.modal.action.confirm')}
      onCancel={onClose}
      onConfirm={handleDelete}
      showLoader={isLoading}
    >
      <Text size="lg" mt={20} mb={16}>
        {t('form.delete.modal.text')}
      </Text>
    </ConfirmationModal>
  );
};
