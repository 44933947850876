import { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

type UseDebouncedSearch = [string, (value: string) => void];


export const useDebouncedSearch = (debounceValue = 500): UseDebouncedSearch => {
  const [searchQuery, setSearchQuery] = useState('');

  const debouncedFnMemo = useMemo(() => debounce(setSearchQuery, debounceValue), [debounceValue]);
  
  const handleSearchInputChange = useCallback(
    (value: string) => debouncedFnMemo(value),
    [debouncedFnMemo],
  );

  return [searchQuery, handleSearchInputChange];
};
