import { FC } from 'types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormComponent } from 'models/FormComponent';
import { FormAddonType } from 'typings/enums/FormAddonType';
import { FormModal } from 'components/modal/FormModal/FormModal';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { HelperTextSettings } from 'typings/types/FormComponentSettings';
import { IFormComponentAddonDto } from 'typings/interfaces/dto/IFormComponentAddonDto';

import { HelpTextAddonSettingsForm } from '../HelpTextAddonSettingsForm/HelpTextAddonSettingsForm';

interface IHelpTextAddonSettingsEditorModalProps {
  addon: IFormComponentAddonDto;
  question: FormComponent;
  onClose(): void;
}
export const HelpTextAddonSettingsEditorModal: FC<IHelpTextAddonSettingsEditorModalProps> = ({
  addon,
  question,
  onClose,
}) => {
  const { t } = useTranslation();
  const { roomId, formId, updateComponent, disableEdit, allowLimitedEditOnFinalized } =
    useFormEditor();

  const handleClickSave = useCallback(
    async (addonSettings: HelperTextSettings) => {
      if (disableEdit && !allowLimitedEditOnFinalized) {
        return;
      }
      const addons =
        question.addons?.filter((addon) => addon.addonType !== FormAddonType.HelpText) ?? [];
      addons.push({
        addonType: FormAddonType.HelpText,
        settingsJson: JSON.stringify(addonSettings),
      });
      question.addons = addons;
      await updateComponent(question, { displayErrorNotification: true });
      onClose();
    },
    [disableEdit, allowLimitedEditOnFinalized, question, updateComponent, onClose],
  );

  return (
    <FormModal
      isOpen
      title={t('form.settings.modal.title', {
        questionNo: question.orderTag,
        questionType: t('form.addon.helpText.name'),
      })}
      maxWidth="724px"
      onClose={onClose}
    >
      <HelpTextAddonSettingsForm
        disabled={disableEdit && !allowLimitedEditOnFinalized}
        addon={addon}
        roomId={roomId}
        formId={formId}
        onSubmit={handleClickSave}
        onCancel={onClose}
      />
    </FormModal>
  );
};
