import { Box, Heading, HStack, VStack } from '@chakra-ui/react';
import { FC } from 'types';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { FormFieldHelp } from 'components/core/FormFieldHelp/FormFieldHelp';
import { NotificationTextFormField } from 'components/core/NotificationTextFormField/NotificationTextFormField';
import { SwitchField } from 'fields/SwitchField/SwitchField';
import { Assessment } from 'models/Assessment';
import {
  AssessmentCollaboratorsFields,
  UpsertAssessmentCollaboratorsDTO,
} from 'typings/types/dto/AssessmentDTO';

import { AssessmentCollaborators } from './components/AssessmentCollaborators/AssessmentCollaborators';
import { useCollaboratorsFormChoices } from './CollaboratorsForm.hooks';

interface ICollaboratorsFormProps {
  assessment: Assessment;
  isLoading?: boolean;
  onSubmit(formData: UpsertAssessmentCollaboratorsDTO): Promise<void>;
  onCancel(): void;
}
export const CollaboratorsForm: FC<ICollaboratorsFormProps> = ({
  assessment,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const collaborators = useCollaboratorsFormChoices(assessment);

  const { control, handleSubmit, setError } = useForm<AssessmentCollaboratorsFields>({
    defaultValues: {
      allowAnonymousReply: false,
      skipSendingNotification: false,
      notificationText: '',
      permissionGroupUsers: collaborators,
    },
  });

  const handleSave = async (formValues: AssessmentCollaboratorsFields) => {
    if (!formValues.skipSendingNotification && formValues.notificationText === '') {
      setError('notificationText', {
        message: t('assessment.collaborators.notificationText.invalidText'),
      });
      return;
    }

    onSubmit({
      // allowAnonymousReply: formValues.allowAnonymousReply, // wait for BE
      skipSendingNotification: formValues.skipSendingNotification,
      notificationText: formValues.notificationText,
      permissionGroupUsers: formValues.permissionGroupUsers.map((collaborator) => ({
        permissionGroupId: collaborator.permissionGroupId,
        userId: collaborator.userId,
      })),
    });
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)}>
      <HStack spacing={40} alignItems="flex-start">
        <VStack spacing={20} alignItems="flex-start" width="45%">
          <Heading as="h2" size="xl">
            {t('assessment.collaborators.modal.title')}
          </Heading>
          <Box width="100%">
            <NotificationTextFormField
              control={control as unknown as Control<FieldValues>}
              nameTextEditor="notificationText"
              defaultChecked={false}
              nameCheckBox="skipSendingNotification"
              labelIsTitle={true}
            />
          </Box>
          <HStack width="100%">
            <SwitchField
              control={control as unknown as Control<FieldValues>}
              name="allowAnonymousReply"
              label={t('assessment.collaborators.allowAnonymousReply.label')}
              disabled
            />
            <FormFieldHelp>{t('assessment.collaborators.allowAnonymousReply.help')}</FormFieldHelp>
          </HStack>
        </VStack>

        <VStack h="100%" w="55%" spacing={20}>
          <Heading as="h3" size="lg" width="100%">
            {t('assessment.collaborators.modal.title')}
          </Heading>
          <AssessmentCollaborators
            assessment={assessment}
            control={control}
            permissionGroups={assessment.permissionGroups}
          />
        </VStack>
      </HStack>
      <FormActions>
        <FormButtonCancel onClick={onCancel} />
        <FormButtonSubmit isLoading={isLoading} />
      </FormActions>
    </Box>
  );
};
