import { Box } from '@chakra-ui/react';
import React from 'react';

import { StyledTableWrapperLoading } from '../TableWrapper.elements';

export const TableWrapperLoading = () => {
  return (
    <StyledTableWrapperLoading>
      <Box className="a-loading a-primary" />
    </StyledTableWrapperLoading>
  );
};
