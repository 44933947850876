import { Attribute } from '@datx/core';

import { FormUser } from 'models/FormUser';
import { Resource } from 'models/Resource';
import { Form } from 'models/Form';

export class FormApproval extends Resource {
  public static type = 'forms_approval';

  @Attribute({ isIdentifier: true, map: 'formId' })
  public id!: string;

  @Attribute()
  public isFormEditable!: boolean;

  @Attribute()
  public requiredApprovals!: number;

  @Attribute()
  public currentApprovals!: number;

  @Attribute()
  public notificationText!: string;

  @Attribute()
  public skipSendingNotification!: boolean;

  @Attribute()
  public formId!: string;

  @Attribute({ toOne: 'forms', map: 'formId' })
  public form!: Form;

  @Attribute({ toMany: FormUser })
  public approvers!: Array<FormUser>;

  public hasApprover(email: string) {
    return this.approvers?.some((approver) => approver.email.toLowerCase() === email) ?? false;
  }
}
