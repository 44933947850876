import { Box, HStack } from '@chakra-ui/react';
import { useState } from 'react';
import { Table, Tooltip } from 'appkit-react';
import { TFunction, useTranslation } from 'react-i18next';

import { TableSearch } from 'components/table/TableSearch/TableSearch';
import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { useFilteredResources } from 'hooks/useFilteredResources';
import { dateSortMethod } from 'utils/dateSortMethod';
import { Domain } from 'models/Domain';
import { CellText } from 'components/core/CellText/CellText';
import { DomainManagementModal } from 'components/site-admin/domain-management/DomainManagementModal';
import { useTenant } from 'hooks/useTenant';
import { DomainNameCell } from 'components/site-admin/domain-management/DomainNameCell/DomainNameCell';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { ExportSiteData } from 'components/export-data/ExportSiteData/ExportSiteData';

const exampleColumns = (onSelect: (domain: Domain) => void, t: TFunction) => [
  {
    Header: t('applicationAdmin.domain.tableColumns.name'),
    accessor: 'name',
    Cell: (row: Record<string, unknown>) => (
      <DomainNameCell
        domain={row.original as Domain}
        onClick={() => onSelect(row.original as Domain)}
      />
    ),
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.domain'),
    accessor: 'id',
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.type'),
    accessor: 'type',
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.contact'),
    accessor: 'contact',
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.numberOfUsers'),
    accessor: 'numberOfDomainUsers',
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.libraryAccess'),
    accessor: 'hasLibraryAccess',
    Cell: (row: Record<string, unknown>) => (
      <CellText>
        {(row.value as string)
          ? t('applicationAdmin.domain.libraryAccess.enabled')
          : t('applicationAdmin.domain.libraryAccess.disabled')}
      </CellText>
    ),
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.status'),
    accessor: 'status',
    Cell: (row: Record<string, unknown>) => (
      <CellText textTransform="capitalize">{row.value as string}</CellText>
    ),
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.expirationDate'),
    accessor: 'formattedExpiration',
    sortMethod: dateSortMethod,
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.lastEdit'),
    accessor: 'formattedLastEdit',
    sortMethod: dateSortMethod,
  },
];

export const SiteAdminDomainManagementPage = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState<Domain>();

  const { activeTenant } = useTenant();

  const { handleSearchInputChange, data, isInitialLoad, isValidating } = useFilteredResources(
    Domain,
    'name',
  );

  const disabledManaging =
    !activeTenant?.manageDominantDomains && !activeTenant?.manageNonDominantDomains;

  const onAddClick = () => {
    if (activeTenant?.manageDominantDomains || activeTenant?.manageNonDominantDomains) {
      setIsModalVisible(true);
    }
  };

  const onUserSelect = (domain: Domain) => {
    setSelectedDomain(domain);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setSelectedDomain(undefined);
    setIsModalVisible(false);
  };

  return (
    <Box mt={20}>
      <TableWrapper isLoading={isInitialLoad || isValidating}>
        <TableSearch onChange={handleSearchInputChange} />
        <Table
          columns={exampleColumns(onUserSelect, t)}
          data={data}
          showPageSizeOptions={false}
          defaultPageSize={10}
          defaultSorted={[{ id: 'name', asc: true }]}
        />
        <HStack spacing={20} position="absolute" right="0" bottom="0">
          <ExportSiteData />
          {disabledManaging ? (
            <Tooltip content={t('siteAdmin.domainRestrictions.all')}>
              <StyledButton size="md" onClick={onAddClick} disabled={disabledManaging}>
                {t('applicationAdmin.domain.action.add')}
              </StyledButton>
            </Tooltip>
          ) : (
            <StyledButton size="md" onClick={onAddClick}>
              {t('applicationAdmin.domain.action.add')}
            </StyledButton>
          )}
        </HStack>
      </TableWrapper>
      {isModalVisible && (
        <DomainManagementModal domain={selectedDomain} isVisible onCancel={handleCancel} />
      )}
    </Box>
  );
};
