import { BoxProps, useBoolean } from '@chakra-ui/react';
import { FC } from 'types';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-use';

import { RoomCellMenuItem } from '../RoomCellMenuItem/RoomCellMenuItem';
import { RoomCellMenu } from '../../../room.elements';
import { useFolderMenuActions } from './FolderMenu.hooks';
import { RoomFolder } from 'models/RoomFolder';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';

interface IFolderMenuProps extends BoxProps {
  folder: RoomFolder;
  onClose(): void;
  onEditModalOpen(): void;
  onShareModalOpen(): void;
}

export const FolderMenu: FC<IFolderMenuProps> = ({
  folder,
  onEditModalOpen,
  onShareModalOpen,
  onClose,
  ...props
}) => {
  const history = useHistory();
  const location = useLocation();

  const { deleteMutation } = useFolderMenuActions(folder);
  const [isDeleting, setIsDeleting] = useBoolean();

  const handleDelete = async () => {
    setIsDeleting.on();
    try {
      await deleteMutation.mutate();
      PopAlertSuccess(`Folder ${folder.name} successfully deleted`);
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 403) {
        PopAlertFail('Deleting folder failed', `You don't have permission to delete this folder.`);
      } else {
        PopAlertFail('Deleting folder failed', response.message || 'An unexpected error occured.');
      }
    } finally {
      setIsDeleting.off();
    }
  };

  const handleOpen = () => {
    history.push(`${location.pathname}/${folder.id}`);
    onClose();
  };

  const handleRename = () => {
    onEditModalOpen();
    onClose();
  };

  const handleShare = () => {
    onShareModalOpen();
    onClose();
  };

  return (
    <RoomCellMenu {...props}>
      <RoomCellMenuItem label="Open" iconName="icon-purpose-outline" onClick={handleOpen} />
      <RoomCellMenuItem label="Share with" iconName="icon-add-user-outline" onClick={handleShare} />
      {/* Not ready on the backend */}
      {/* <RoomCellMenuItem label="Move to" iconName="icon-move-outline" onClick={console.log} /> */}
      <RoomCellMenuItem label="Rename" iconName="icon-edit-outline" onClick={handleRename} />
      <RoomCellMenuItem
        label="Remove"
        iconName="icon-delete-outline"
        isLoading={isDeleting}
        onClick={handleDelete}
      />
    </RoomCellMenu>
  );
};
