import { FC } from 'types';
import { Fragment } from 'react';

import { useModal } from 'hooks/useModal';
import { IAttachmentFile } from 'typings/interfaces/dto/IAttachmentFile';

import { QuestionIconButton } from '../AssessmentQuestionCard/components/QuestionIconButton';
import { AssessmentQuestionAttachmentModal } from '../AssessmentQuestionAttachmentModal/AssessmentQuestionAttachmentModal';

interface IAssessmentQuestionAttachmentProps {
  assessmentId: string;
  orderTag: string;
  attachments: Array<IAttachmentFile>;
  isEditDisabled?: boolean;
  isViewDisabled?: boolean;
  handleSave(attachments: Array<IAttachmentFile>): void;
}
export const AssessmentQuestionAttachment: FC<IAssessmentQuestionAttachmentProps> = ({
  orderTag,
  assessmentId,
  attachments,
  handleSave,
  isEditDisabled,
  isViewDisabled,
}) => {
  const { isOpen, onClose, onOpen } = useModal();

  if (isViewDisabled) {
    // when in testing view
    return <QuestionIconButton icon={'icon-upload-fill'} label="attachment" isDisabled />;
  }

  return (
    <Fragment>
      {isOpen ? (
        <QuestionIconButton icon={'icon-upload-fill'} label="attachment" onClick={onClose} />
      ) : (
        <QuestionIconButton icon={'icon-upload-outline'} label="attachment" onClick={onOpen} />
      )}
      {isOpen && (
        <AssessmentQuestionAttachmentModal
          onClose={onClose}
          assessmentId={assessmentId}
          orderTag={orderTag}
          handleSave={handleSave}
          attachments={attachments}
          disabled={isEditDisabled}
        />
      )}
    </Fragment>
  );
};
