import { Box, Flex, Text, Image, chakra, useDisclosure } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox } from 'appkit-react';
import { useTranslation } from 'react-i18next';

import { Features } from '../Features/Features';
import { useProfile } from '../../../hooks/useProfile';
import { useTenant } from '../../../hooks/useTenant';
import { FeatureModal } from '../FeatureModal/FeatureModal';

const CButton = chakra(Button);

export const WelcomeBox = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const profile = useProfile();
  const { activeTenant } = useTenant();

  const [contentId, setContentId] = useState('');
  const [shouldShowWindow, setShouldShowWindow] = useState(() => {
    return !document.cookie.includes('hideWelcome');
  });

  const { t } = useTranslation();

  const handleFeatureClick = useCallback(
    (key: string) => {
      setContentId(key);
      onOpen();
    },
    [onOpen],
  );

  const getStartedLink = useMemo(() => {
    if (activeTenant) {
      return '/rooms';
    } else {
      return '/application-admin';
    }
  }, [activeTenant]);

  useEffect(() => {
    if (!shouldShowWindow) {
      document.cookie = 'hideWelcome=true; expires=Fri, 31 Dec 2030 00:00:00 GMT; Secure';
    } else {
      document.cookie =
        'hideWelcome=true; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None; Secure';
    }
  }, [shouldShowWindow]);

  return (
    <Box
      sx={{
        boxShadow: 'mainWhite',
        borderRadius: '3px',
        bg: 'grey.lvl3',
        width: '992px',
        pb: '34px',
        position: 'relative',
      }}
    >
      <Flex
        sx={{
          height: '226px',
          borderRadius: '3px 3px 0 0',
          bg: 'white',
          justifyContent: 'center',
        }}
      >
        <Text fontSize="28px" fontWeight="500" mt="34px" color="utils.grey.300">
          {t('welcomeBox.welcomeMsg', { fullName: profile?.profile?.name })}
        </Text>
      </Flex>
      <Box px="34px">
        <Features onFeatureClick={handleFeatureClick} />
        <Flex justify="center" mt="57px">
          <Link to={getStartedLink}>
            <CButton size="xl" textTransform="uppercase">
              {t('welcomeBox.action.getStarted')}
            </CButton>
          </Link>
        </Flex>
        <Flex align="center" mt="62px">
          <Checkbox
            name="showWindow"
            id="showWindow"
            checked={shouldShowWindow}
            onChange={() => setShouldShowWindow((prevState) => !prevState)}
          />
          <Box
            as="label"
            htmlFor="showWindow"
            sx={{
              ml: '10px',
              color: 'white',
              fontSize: '14px',
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            {t('welcomeBox.checkbox.showWindow')}
          </Box>
        </Flex>
        <Image
          src="/assets/images/welcome-illustration-bottom.png"
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '300px',
          }}
          alt={t('welcomeBox.images.welcomeAlt')}
        />
      </Box>
      {isOpen && <FeatureModal contentId={contentId} onCancel={onClose} />}
    </Box>
  );
};
