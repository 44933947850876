import { Attribute } from '@datx/core';
import { computed } from 'mobx';

import { Resource } from './Resource';

export class Currency extends Resource {
  public static type = 'keydata/currencies';

  @Attribute({ isIdentifier: true })
  public readonly code!: string;
  @Attribute()
  public readonly name!: string;
  @Attribute()
  public readonly symbol!: string;

  @computed
  public get displayValue(): string {
    return this.symbol ? `${this.name} [${this.symbol}]` : this.name;
  }
}
