import { useMemo } from 'react';

import { Form } from 'models/Form';
import { Room } from 'models/Room';
import { RoomFolder } from 'models/RoomFolder';
import { RoomFolderUser } from 'models/RoomFolderUser';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { IFormUser } from 'typings/interfaces/IFormUser';
import { useDatx } from 'hooks/useDatx';
import { transformUserToFormUser } from 'services/transformUserToFormUser';
import { useApiClient } from 'hooks/useApiClient';

export const useCreateForm = (roomId: string) => {
  return useApiClient<void>({
    url: `rooms/${roomId}/${Form.type}`,
    method: HttpMethod.Post,
    mutateKeys: [`rooms/${roomId}/${Form.type}`, `rooms/${roomId}/${RoomFolder.type}`],
  });
};

export const useAvailableFormUsers = (roomId: string, folderId?: string) => {
  const datx = useDatx();

  const roomUsers = useMemo(() => {
    const formRoom = datx.findOne(Room, roomId);
    const formFolder = folderId ? datx.findOne(RoomFolder, folderId) : null;

    if (!formFolder && formRoom) {
      return formRoom.roomUsers.map((user) => transformUserToFormUser(user));
    }

    if (formFolder && formRoom) {
      return formFolder.users.reduce((acc: Array<IFormUser>, user: RoomFolderUser) => {
        const roomUser = formRoom.roomUsers.find((roomUser) => user.userId === roomUser.userId);
        if (roomUser) {
          acc.push(transformUserToFormUser(roomUser));
        }
        return acc;
      }, []);
    }

    return [];
  }, [datx, folderId, roomId]);

  return {
    roomUsers,
  };
};
