import { useSWRConfig } from 'swr';

import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { RoomFolder } from 'models/RoomFolder';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { useRoomId } from 'components/room/room.hooks';

export const useFolderDelete = (folder: RoomFolder) => {
  const depHeaders = useDependencyHeaders();
  const roomId = useRoomId();
  const { mutate: globalMutate } = useSWRConfig();

  const apiUrl = `rooms/${roomId}/${RoomFolder.type}/${folder.id}`;
  const key = `rooms/${roomId}/${RoomFolder.type}@${depHeaders['x-site-id']}`;

  const mutate = async (options?: Record<string, unknown>) => {
    await apiClient(apiUrl, HttpMethod.Delete, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
    });

    globalMutate(key);
  };

  return {
    mutate,
  };
};

export const useFolderMutate = (folder: RoomFolder | undefined) => {
  const depHeaders = useDependencyHeaders();
  const roomId = useRoomId();
  const { mutate: globalMutate } = useSWRConfig();

  const postUrl = `rooms/${roomId}/${RoomFolder.type}`;
  const putUrl = `rooms/${roomId}/${RoomFolder.type}/${folder?.id}`;
  const key = `rooms/${roomId}/${RoomFolder.type}@${depHeaders['x-site-id']}`;

  const mutate = async (data?: Record<string, unknown>, options?: Record<string, unknown>) => {
    await apiClient(folder ? putUrl : postUrl, folder ? HttpMethod.Put : HttpMethod.Post, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
      body: JSON.stringify(data),
    });

    globalMutate(key);
  };

  const mutateUsers = async (data?: Record<string, unknown>, options?: Record<string, unknown>) => {
    await apiClient(`${putUrl}/user`, HttpMethod.Put, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
      body: JSON.stringify(data),
    });

    globalMutate(key);
  };

  return {
    mutate,
    mutateUsers,
  };
};

export const useFolderMenuActions = (folder: RoomFolder) => {
  const deleteMutation = useFolderDelete(folder);
  const saveMutation = useFolderMutate(folder);

  return {
    deleteMutation,
    saveMutation,
  };
};
