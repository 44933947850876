import groupBy from 'lodash/groupBy';

import { AppStore } from 'store/AppStore';
import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { IAssessmentComponentDto } from 'typings/interfaces/dto/IAssessmentComponentDto';
import {
  IAssessmentTestDto,
  IAssessmentTestRenamedDto,
  ITestAssessmentComponentDto,
} from 'typings/interfaces/dto/IAssessmentTestDto';
import { IFormComponentDto } from 'typings/interfaces/dto/IFormComponentDto';
import { IAssessmentFormComponent } from 'typings/interfaces/IAssessmentFormComponent';
import { sumArrayValues } from 'utils/sumArrayValues';

export const transformTestAssessmentComponentDto = ({
  testAssessmentId,
  ...rest
}: ITestAssessmentComponentDto) => ({ ...rest, assessmentId: testAssessmentId });

export const transformTestAssessmentDto = ({
  testAssessmentComponents,
  ...rest
}: IAssessmentTestDto): IAssessmentTestRenamedDto => ({
  ...rest,
  assessmentComponents: testAssessmentComponents.map(transformTestAssessmentComponentDto),
});

interface IDto {
  id: string;
  assessmentComponents: Array<IAssessmentComponentDto>;
  components: Array<IFormComponentDto>;
}

type Transformed<T extends IDto> = Omit<T, 'assessmentComponents' | 'components'> & {
  assessmentFormComponents: Array<IAssessmentFormComponent>;
};

export const transformAssessmentComponents = <TDto extends IDto>(dto: TDto): Transformed<TDto> => {
  let assessmentFormComponents: Array<IAssessmentFormComponent> = [];
  const assessmentId = dto.id;

  if (dto.components.length > 0) {
    const groups = groupBy(dto.components, 'parentComponentId');

    const sortedComponents: Array<IFormComponentDto> = [];
    const childrenCounts: Array<number> = [];

    const sortChildren = (group: string) => {
      groups[group]
        .sort((a, b) => a.index - b.index)
        .forEach((question) => {
          sortedComponents.push(question);

          if (groups[question.id]?.length) {
            sortChildren(question.id);
          }
        });
    };

    groups['null']
      .sort((a, b) => a.index - b.index)
      .forEach((section) => {
        sortedComponents.push(section);

        const childrenCount = groups[section.id]?.length ?? 0;
        childrenCounts.push(childrenCount);

        if (childrenCount) {
          sortChildren(section.id);
        }
      });

    assessmentFormComponents = sortedComponents.map((item: IFormComponentDto, index: number) => ({
      ...item,
      formComponentId: item.id,
      id: `${item.id}@${assessmentId}`,
      indexInAssessment: index,
      assessmentId,
      childStartIndex: item.parentComponentId
        ? null
        : sumArrayValues(childrenCounts, item.index - 1),
      parentComponentId: item.parentComponentId
        ? `${item.parentComponentId}@${assessmentId}`
        : null,
      assessmentComponent:
        item.componentType === FormComponentType.Section
          ? undefined
          : {
              ...getAssessmentComponent(dto.assessmentComponents, item.id),
              componentId: `${item.id}@${assessmentId}`,
            },
    }));
  }

  return { ...trimAssessment(dto), assessmentFormComponents };
};

const getAssessmentComponent = (
  assessmentQuestionDtoArray: Array<IAssessmentComponentDto>,
  formComponentId: string,
) => {
  const dto = assessmentQuestionDtoArray.find((dto) => dto.componentId === formComponentId);
  if (!dto) {
    throw Error('Missing assessment component');
  }
  return dto;
};

const trimAssessment = <IData extends IDto>({
  // eslint-disable-next-line
  assessmentComponents: _assessmentComponents,
  // eslint-disable-next-line
  components: _components,
  ...rest
}: IData): Omit<IData, 'assessmentComponents' | 'components'> => rest;

export const removeDeletedFormComponents = (
  datx: AppStore,
  assessmentId: string,
  result: Array<IAssessmentFormComponent>,
) => {
  const old = datx
    .findAll(AssessmentFormComponent)
    .filter((component) => component.assessmentId === assessmentId);

  old.forEach((component) => {
    if (!result.some((formComponent) => formComponent.id === component.id)) {
      datx.removeOne(AssessmentFormComponent, component.id);
    }
  });
};
