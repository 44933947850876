import { Attribute } from '@datx/core';
import { computed } from 'mobx';

import { Resource } from 'models/Resource';
import {
  getConditionDependencies,
  getSkipDependents,
} from 'services/formComponents/getConditionDependencies';
import { isFirstDescendantOfSecond } from 'services/formComponents/isFirstDescendantOfSecond';
import { FormAddonType } from 'typings/enums/FormAddonType';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { IFormComponentAddonDto } from 'typings/interfaces/dto/IFormComponentAddonDto';
import { UpsertFormComponentDTO } from 'typings/types/dto/FormComponentDTO';
import {
  ConditionAddonSettings,
  FormComponentValue,
  ScaleSettings,
  ScaleType,
  SkipCondition,
  SkipConditionAddonSettings,
} from 'typings/types/FormComponentSettings';

export class FormComponent extends Resource {
  public static type = 'components';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public text!: string;

  @Attribute()
  public valueJson!: string | null;

  @Attribute()
  public settingsJson!: string | null;

  @Attribute()
  public addons!: Array<IFormComponentAddonDto>;

  @Attribute()
  public componentType!: FormComponentType;

  @Attribute()
  public index!: number;

  @Attribute()
  public parentComponentId!: string | null;

  @Attribute()
  public childStartIndex!: number;

  @Attribute({
    map: 'parentComponentId',
    toOne: 'components',
  })
  public parent!: FormComponent | null;

  @Attribute()
  public formId!: string;

  @computed
  public get isSection() {
    return this.componentType === FormComponentType.Section;
  }

  @computed
  public get isScale() {
    return (
      this.componentType === FormComponentType.ScaleNumber ||
      this.componentType === FormComponentType.ScaleText
    );
  }

  @computed
  public get isRange() {
    return this.isScale && (this.parsedSettings as Record<string, string>).scaleType === 'Range';
  }

  @computed
  public get isQuestionSet() {
    return this.componentType === FormComponentType.QuestionSet;
  }

  @computed
  public get orderTag(): string {
    if (this.isSection || !this.parent) {
      return '';
    }

    if (this.parent.isSection) {
      return `${this.parent.childStartIndex + this.index + 1}`;
    }

    if (this.parent.isQuestionSet) {
      return `${this.parent.orderTag}.${this.index + 1}`;
    }

    return '';
  }

  @computed
  public get isInQuestionSet(): boolean {
    return Boolean(this.parent?.isQuestionSet);
  }

  @computed
  public get isPlaceholder(): boolean {
    return typeof this.id === 'number';
  }

  @computed
  public get level(): number {
    return this.isSection || !this.parent ? 0 : this.parent.level + 1;
  }

  @computed
  public get parsedSettings(): null | unknown {
    if (!this.settingsJson) {
      return null;
    }
    return JSON.parse(this.settingsJson) as unknown;
  }

  @computed
  public get parsedValue(): null | Array<FormComponentValue> {
    if (!this.valueJson) {
      return null;
    }
    return JSON.parse(this.valueJson) as Array<FormComponentValue>;
  }

  @computed
  public get displayValue() {
    if (this.isSection || this.isPlaceholder) {
      return this.text;
    }
    return `${this.orderTag}\t${this.text}`;
  }

  @computed
  public get order(): Array<number> {
    if (!this.parent) {
      return [this.index];
    }
    return [...this.parent.order, this.index];
  }

  @computed
  public get isFirstQuestionParent(): boolean {
    return !this.order.some((order) => order !== 0);
  }

  @computed
  public get hasValidSettings(): boolean {
    if (
      (this.componentType === FormComponentType.Confirmation ||
        this.componentType === FormComponentType.Country ||
        this.componentType === FormComponentType.Date ||
        this.componentType === FormComponentType.Number ||
        this.componentType === FormComponentType.ScaleNumber ||
        this.componentType === FormComponentType.ScaleText) &&
      (!this.settingsJson || this.settingsJson === '{}')
    ) {
      return false;
    }

    if (
      (this.componentType === FormComponentType.SingleChoice ||
        this.componentType === FormComponentType.MultipleChoice) &&
      !this.parsedValue?.length
    ) {
      return false;
    }

    if (this.componentType === FormComponentType.ScaleText) {
      if (!this.parsedValue?.length) {
        return false;
      }
      if (!this.parsedSettings) {
        return false;
      }
      return true;
    }

    if (this.componentType === FormComponentType.ScaleNumber) {
      if (!this.parsedSettings) {
        return false;
      }

      const scaleNumberSettings = this.parsedSettings as ScaleSettings;
      if (scaleNumberSettings.scaleType === ScaleType.Interval && !this.parsedValue?.length) {
        return false;
      }
      return true;
    }

    return true;
  }

  @computed
  public get hasConditionAddon(): boolean {
    const conditionAddon = this.addons.find((addon) => addon.addonType === FormAddonType.Condition);
    return Boolean(conditionAddon);
  }

  @computed
  public get upsertDto(): UpsertFormComponentDTO {
    return {
      text: this.text,
      componentType: this.componentType,
      addons: this.addons,
      valueJson: this.valueJson,
      settingsJson: this.settingsJson,
      index: this.index,
      parentComponentId: this.parentComponentId,
    };
  }

  @computed
  public get dependents(): Array<string> {
    const skipAddon = this.addons.find((addon) => addon.addonType === FormAddonType.SkipCondition);
    if (!skipAddon) {
      return [];
    }
    if (!skipAddon.settingsJson) {
      return [];
    }

    const parsedSettings = JSON.parse(skipAddon.settingsJson) as SkipConditionAddonSettings;
    if (!parsedSettings) {
      return [];
    }

    return getSkipDependents(parsedSettings.skipConditions);
  }
  @computed
  public get skipConditions(): Array<SkipCondition> {
    const skipAddon = this.addons.find((addon) => addon.addonType === FormAddonType.SkipCondition);
    if (!skipAddon) {
      return [];
    }
    if (!skipAddon.settingsJson) {
      return [];
    }

    const parsedSettings = JSON.parse(skipAddon.settingsJson) as SkipConditionAddonSettings;
    if (!parsedSettings) {
      return [];
    }

    return parsedSettings.skipConditions;
  }

  @computed
  public get dependencies(): Array<string> {
    const conditionAddon = this.addons.find((addon) => addon.addonType === FormAddonType.Condition);
    if (!conditionAddon) {
      return [];
    }

    if (!conditionAddon.settingsJson) {
      return [];
    }

    const parsedSettings = JSON.parse(conditionAddon.settingsJson) as ConditionAddonSettings;
    if (!parsedSettings) {
      return [];
    }

    return getConditionDependencies(parsedSettings.conditionRuleGroup);
  }

  public isDescendantOf(formComponent: FormComponent): boolean {
    return isFirstDescendantOfSecond(this, formComponent);
  }

  public getDescendants(): Array<FormComponent> {
    if (!this.isQuestionSet && !this.isSection) {
      return [];
    }

    return (
      this.meta.collection?.findAll(FormComponent).filter((fc) => fc.isDescendantOf(this)) ?? []
    );
  }

  public getDepth(): number {
    if (!this.isSection && !this.isQuestionSet) {
      // any question contributes to the tree depth as 1
      return 1;
    }

    const descendants = this.getDescendants();
    if (this.isSection && descendants.length === 0) {
      // sections are level 0 and do not contribute to the depth
      return 0;
    }

    if (this.isQuestionSet && descendants.length === 0) {
      // Business rule: limit the depth of any tree to 6. Question set can't be added to a question set on level 4 or 5
      // even empty question sets contribute to the depth as 2, because they are useless when empty
      return 2;
    }

    const levelOfDeepestDescendant = this.getDescendants().reduce(
      (max, descendant) => Math.max(max, descendant.level),
      this.level,
    );

    if (this.isSection) {
      return levelOfDeepestDescendant;
    }

    // this is QuestionSet
    return levelOfDeepestDescendant - this.level + 1;
  }
}
