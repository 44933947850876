import React from 'react';
import { FC } from 'types'
import { Box, Text } from '@chakra-ui/react';
import { Badge } from 'appkit-react';

export const RoomCardLastEdit: FC = ({ children }) => {
  return (
    <Box as={Badge} type="light" size="medium" m="0 auto 20px auto">
      <Text
        size="sm"
        sx={{
          color: 'utils.grey.200',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {children}
      </Text>
    </Box>
  );
};
