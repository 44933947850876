import { Center, useRadio, UseRadioProps, VStack } from '@chakra-ui/react';
import { FC } from 'types';

import { CheckboxMock } from './RadioCard.elements';

export const RadioCard: FC<UseRadioProps> = ({ children, ...rest }) => {
  const { getInputProps, getCheckboxProps } = useRadio(rest);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <VStack as="label" spacing={20}>
      {children}
      <Center>
        <CheckboxMock {...checkbox} />
      </Center>
      <input {...input} />
    </VStack>
  );
};
