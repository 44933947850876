import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { Modal } from 'components/modal/Modal/Modal';
import { Domain } from 'models/Domain';
import { DomainManagementForm } from 'forms/SiteAdmin/DomainManagementForm/DomainManagementForm';

interface IDomainManagementModalProps {
  isVisible: boolean;
  domain?: Domain;
  onCancel(): void;
}

export const DomainManagementModal: FC<IDomainManagementModalProps> = ({
  isVisible,
  onCancel,
  domain,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      sx={{ maxWidth: '1160px', width: '100%', mx: 'auto', mt: '10%' }}
      isVisible={isVisible}
      {...rest}
    >
      <ModalHeader onCancel={onCancel} mb="20px">
        <ModalTitle>{t('applicationAdmin.domain.modal.title')}</ModalTitle>
      </ModalHeader>
      <ModalBody px="40px">
        {isVisible && <DomainManagementForm onCancel={onCancel} domain={domain} />}
      </ModalBody>
    </Modal>
  );
};
