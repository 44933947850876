import { Grid, GridProps } from '@chakra-ui/react';
import React from 'react';
import { FC } from 'types'
import { useTranslation } from 'react-i18next';

import { FeatureItem } from '../FeatureItem/FeatureItem';

interface IFeaturesProps extends GridProps {
  onFeatureClick(key: string): void;
}

export const Features: FC<IFeaturesProps> = ({ onFeatureClick, sx, ...rest }) => {
  const { t } = useTranslation();

  const handleFeatureClick = (key: string) => {
    return () => {
      onFeatureClick(key);
    };
  };

  return (
    <Grid
      sx={{
        mt: '-110px',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '54px',
        ...sx,
      }}
      {...rest}
    >
      <FeatureItem
        illustrationSrc={[
          '/assets/images/illustration-social-marketing.png',
          '/assets/images/illustration-analysis.png',
        ]}
        illustrationAlt={t('welcomeBox.images.collaborationAlt')}
        title={t('welcomeBox.features.collaboration.title')}
        content={t('welcomeBox.features.collaboration.description.main')}
        onClick={handleFeatureClick('collaboration')}
      />
      <FeatureItem
        illustrationSrc="/assets/images/illustration-email-checklist.png"
        illustrationAlt={t('welcomeBox.images.communicationAlt')}
        title={t('welcomeBox.features.communication.title')}
        content={t('welcomeBox.features.communication.description.main')}
        onClick={handleFeatureClick('communication')}
      />
      <FeatureItem
        illustrationSrc="/assets/images/illustration-finance-analysis.png"
        illustrationAlt={t('welcomeBox.images.financeAlt')}
        title={t('welcomeBox.features.reporting.title')}
        content={t('welcomeBox.features.reporting.description.main')}
        onClick={handleFeatureClick('reporting')}
      />
    </Grid>
  );
};
