import { useDisclosure } from '@chakra-ui/hooks';
import { Box, Center, Flex, Text } from '@chakra-ui/layout';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Comment } from 'models/Comment';
import { Scrollbar } from 'components/core/ScrollBar/Scrollbar';
import { useComments } from 'hooks/useComments';
import { useCurrentRoom } from 'hooks/useCurrentRoom';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

import { CommentModal } from '../AddCommentModal/CommentModal';
import { CommentBox } from '../CommentBox/CommentBox';

export const NoticeBoard = () => {
  const comments = useComments();
  const [selectedComment, setSelectedComment] = useState<Comment | null | undefined>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { room } = useCurrentRoom();
  const { t } = useTranslation();

  const handleEditComment = (comment: Comment) => {
    setSelectedComment(comment);
    onOpen();
  };

  const handleCloseModal = () => {
    onClose();
    setSelectedComment(null);
  };

  return (
    <Box minHeight="300px" pt={30}>
      {comments.isInitialLoad && (
        <Center flexDirection="column">
          <Box className="a-loading a-primary" />
          <Text mt={20} size="xl">
            {t('loading.fetching.comments')}
          </Text>
        </Center>
      )}
      <Scrollbar maxHeight="720px">
        {comments.data?.map((comment) => (
          <CommentBox key={comment.id} comment={comment} onEdit={handleEditComment} />
        ))}
      </Scrollbar>
      {room?.isRoomAdmin && (
        <Fragment>
          <Flex justify="end" mt={40}>
            <StyledButton size="lg" onClick={onOpen}>
              {t('room.sidebar.noticeBoard.action.add')}
            </StyledButton>
          </Flex>
          {isOpen && <CommentModal comment={selectedComment} onCancel={handleCloseModal} />}
        </Fragment>
      )}
    </Box>
  );
};
