import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { useResetTestAssessment, useRevalidateAssessment } from 'hooks/useAssessment';
import { IAssessmentBase } from 'typings/interfaces/IAssessmentBase';
import { useAssessmentForm } from 'context/AssessmentContext/AssessmentContextProvider';
import {
  recalculateSkippedComponents,
  setSpinner,
} from 'context/AssessmentContext/AssessmentContext.utils';
import { useDatx } from 'hooks/useDatx';

interface IAssessmentResetActionButtonProps {
  assessment: IAssessmentBase | undefined;
  onReset(): void;
}
export const AssessmentResetActionButton: FC<IAssessmentResetActionButtonProps> = ({
  assessment,
  onReset,
}) => {
  const { t } = useTranslation();
  const datx = useDatx();
  const { assessmentId, isLoading, dispatch, timestamp } = useAssessmentForm();
  const { resetAssessment } = useResetTestAssessment(assessment?.id);
  const revalidate = useRevalidateAssessment(assessment, timestamp);

  const handleClickReset = async () => {
    if (!assessment) {
      return;
    }
    setSpinner(dispatch, true);
    await resetAssessment();
    await revalidate();
    recalculateSkippedComponents(dispatch, datx, assessmentId);
    onReset();
    setSpinner(dispatch, false);
  };

  return (
    <StyledButton size="md" disabled={!assessment || isLoading} onClick={handleClickReset}>
      {t('assessment.action.reset')}
    </StyledButton>
  );
};
