import { FC } from 'types';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { Radio } from 'appkit-react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { getValidValue } from './AssessmentSingleChoiceField.utils';
import { RadioGroupField } from 'fields/RadioGroupField/RadioGroupField';

interface IAssessmentSingleChoiceFieldProps {
  assessmentQuestion: AssessmentFormComponent;
  disabled?: boolean;
  index?: number;
}

export const AssessmentSingleChoiceField: FC<IAssessmentSingleChoiceFieldProps> = ({
  assessmentQuestion,
  disabled,
  index = 0,
}) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const value = getValidValue(assessmentQuestion.valueJson);

  return (
    <Fragment>
      <RadioGroupField
        control={control}
        name={assessmentQuestion.fieldName(index)}
        direction="column"
        rules={{
          required: assessmentQuestion.isRequired ? t<string>('validation.required') : false,
        }}
      >
        {value?.map((item) => (
          <Radio key={item.index} value={item.value} disabled={disabled}>
            {item.value}
          </Radio>
        ))}
      </RadioGroupField>

      <ErrorMessage
        errors={errors}
        name={assessmentQuestion.fieldName(index)}
        render={({ message }) => <Text className="a-form-error">{message}</Text>}
      />
    </Fragment>
  );
};
