import { FC } from 'types';
import { Tooltip } from 'appkit-react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { FormComponent } from 'models/FormComponent';

import { getSettingsComponent } from './QuestionSettingsEditorButton.utils';

interface IQuestionSettingsButtonProps {
  question: FormComponent;
  onPlaceholderSettingsClick?(): void;
}
export const QuestionSettingsEditorButton: FC<IQuestionSettingsButtonProps> = observer(
  ({ question, onPlaceholderSettingsClick }) => {
    const { t } = useTranslation();
    const Editor = getSettingsComponent(question.componentType);

    return Editor ? (
      <Tooltip
        disabled={Boolean(question.text)}
        content={t('form.settings.unsavedQuestionText')}
        onClick={onPlaceholderSettingsClick}
      >
        <Editor question={question} orderTag={question.orderTag} />
      </Tooltip>
    ) : null;
  },
);
