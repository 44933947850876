import formatISO from 'date-fns/formatISO';

// returns ISO formatted, rounded UTC date
// use instead of date.toISOString() when sending the UTC date (without time) to the server, without calculating timezone offsets
// fix for when backend expects date only, and cuts off the time part:
// Example: Some event start date is 30.06., browser sends it as "2021-06-29T22:00:00.000Z" and server interprets it as "2021-06-29T00:00:00Z"
// Instead we send: "2021-06-30T00:00:00.000Z"
// P.S. the appended 'T00:00:00.000Z' is not needed. but is here for readability while debugging
export function formatISOToRoundUTCDate(date: Date | string | null) {
  if (!date) {
    return null;
  }
  const dateValue = typeof date === 'string' ? new Date(date) : date;
  return `${formatISO(dateValue, { representation: 'date' })}T00:00:00.000Z`;
}
