import { Center, Text } from '@chakra-ui/react';
import { FC } from 'types';

interface IAddonProps {
  name: string;
  disabled?: boolean;
  isInvalid?: boolean;
  onClick?(): void;
}
export const Addon: FC<IAddonProps> = ({ name, disabled, isInvalid, onClick }) => {
  return (
    <Center
      sx={{
        w: '150px',
        h: '30px',
        bgColor: disabled ? 'grey.lvl6' : 'grey.lvl2',
        border: ({ colors }) =>
          isInvalid ? `1px solid ${colors.brand.red}` : `1px solid ${colors.grey.lvl6}`,
      }}
      onClick={!disabled ? onClick : undefined}
    >
      <Text size="sm" textTransform="uppercase">
        {name}
      </Text>
    </Center>
  );
};
