import { FC } from 'types';
import { HStack, Spacer } from '@chakra-ui/react';

import { AssessmentStatusDisplay } from 'typings/types/AssessmentStatusDisplay';

import { ToggleBookmarkedIconButton } from '../ToggleBookmarkedIconButton/ToggleBookmarkedIconButton';
import { DeleteAssessmentIconButton } from '../DeleteAssessmentIconButton/DeleteAssessmentIconButton';
import { CollaboratorsIconButton } from '../CollaboratorsIconButton/CollaboratorsIconButton';
import { HistoryIconButton } from '../HistoryIconButton/HistoryIconButton';
import { ApproversIconButton } from '../ApproversIconButton/ApproversIconButton';
import { ExportAssessmentIconButton } from '../ExportAssessmentIconButton/ExportAssessmentIconButton';

interface IAssessmentToolbarProps {
  assessmentId: string;
  status?: AssessmentStatusDisplay;
}
export const AssessmentToolbar: FC<IAssessmentToolbarProps> = ({ assessmentId, status }) => {
  return (
    <HStack spacing={12} color="brand.orange">
      <Spacer w="30px" />
      <ToggleBookmarkedIconButton />
      <CollaboratorsIconButton assessmentId={assessmentId} />
      <ApproversIconButton status={status} />
      <ExportAssessmentIconButton assessmentId={assessmentId} />
      <HistoryIconButton assessmentId={assessmentId} />
      <DeleteAssessmentIconButton />
    </HStack>
  );
};
