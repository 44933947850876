import { Box, HStack, Square, Text, VStack } from '@chakra-ui/react';
import { FC } from 'types';
import { SelectOption, Tooltip } from 'appkit-react';
import { Control, FieldValues, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Currency } from 'models/Currency';
import { ControlledSelectField } from 'fields/SelectField/SelectField';
import { InputNumberField } from 'fields/InputNumberField/InputNumberField';
import { NumbersComponentType, NumbersSettings } from 'typings/types/FormComponentSettings';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { useResources } from 'hooks/useResources';

interface INumbersSettingsFormProps {
  settings: NumbersSettings;
  disabled?: boolean;
  disableCurrencyChange: boolean;
  onSubmit(value: NumbersSettings): void;
  onCancel(): void;
}
export const NumbersSettingsForm: FC<INumbersSettingsFormProps> = ({
  settings,
  disabled,
  disableCurrencyChange,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { data } = useResources(Currency, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { control, handleSubmit } = useForm<NumbersSettings>({
    defaultValues: {
      decimalDigits: settings.decimalDigits,
      numberType: settings.numberType,
      selectedCurrency: settings.selectedCurrency,
    },
  });

  const selectedNumberType = useWatch({
    control,
    name: 'numberType',
  });

  const disableSave =
    disabled && (selectedNumberType !== NumbersComponentType.Currency || disableCurrencyChange);

  const handleSave = (values: NumbersSettings) => {
    if (disableSave) {
      return;
    }

    onSubmit(values);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)}>
      <VStack spacing={20}>
        <HStack width="100%" alignItems="flex-end">
          <ControlledSelectField
            control={control as unknown as Control<FieldValues>}
            name="numberType"
            label={t('form.settings.numbers.numberTypeLabel')}
            disabled={disabled}
          >
            <SelectOption key={NumbersComponentType.Number} value={NumbersComponentType.Number}>
              {t('form.settings.numbers.numbersComponentType.Number')}
            </SelectOption>
            <SelectOption key={NumbersComponentType.Currency} value={NumbersComponentType.Currency}>
              {t('form.settings.numbers.numbersComponentType.Currency')}
            </SelectOption>
            <SelectOption
              key={NumbersComponentType.Percentage}
              value={NumbersComponentType.Percentage}
            >
              {t('form.settings.numbers.numbersComponentType.Percentage')}
            </SelectOption>
            <SelectOption key={NumbersComponentType.Score} value={NumbersComponentType.Score}>
              {t('form.settings.numbers.numbersComponentType.Score')}
            </SelectOption>
          </ControlledSelectField>
          <Square size="34px">
            <Tooltip content="Helper text" placement="top">
              <Text
                size="3xl"
                as="span"
                className="appkiticon icon-help-question-fill"
                color="brand.orange"
              />
            </Tooltip>
          </Square>
        </HStack>

        <HStack width="100%" alignItems="flex-end">
          <InputNumberField
            min={0}
            max={4}
            control={control as unknown as Control<FieldValues>}
            name="decimalDigits"
            label={t('form.settings.numbers.decimalDigitsLabel')}
            disabled={disabled}
          />
          <Square size="34px">
            <Tooltip content="Helper text" placement="top">
              <Text
                size="3xl"
                as="span"
                className="appkiticon icon-help-question-fill"
                color="brand.orange"
              />
            </Tooltip>
          </Square>
        </HStack>
        {selectedNumberType && selectedNumberType === NumbersComponentType.Currency && (
          <HStack width="100%" alignItems="flex-end">
            <ControlledSelectField
              control={control as unknown as Control<FieldValues>}
              name="selectedCurrency"
              label={t('form.settings.numbers.selectedCurrencyLabel')}
              disabled={disableCurrencyChange}
              showSearchOnToggle
              rules={{
                required:
                  selectedNumberType === NumbersComponentType.Currency
                    ? t<string>('validation.required')
                    : false,
              }}
            >
              {data ? (
                (data as Array<Currency>).map((currency, index) => (
                  <SelectOption key={index} value={currency.code}>
                    {currency.displayValue}
                  </SelectOption>
                ))
              ) : (
                <SelectOption disabled>{t('loading.fetching.data')}...</SelectOption>
              )}
            </ControlledSelectField>
            <Square size="34px">
              <Tooltip content="Helper text" placement="top">
                <Text
                  size="3xl"
                  as="span"
                  className="appkiticon icon-help-question-fill"
                  color="brand.orange"
                />
              </Tooltip>
            </Square>
          </HStack>
        )}
      </VStack>

      <FormActions>
        <FormButtonCancel onClick={onCancel} />
        <FormButtonSubmit disabled={disableSave} />
      </FormActions>
    </Box>
  );
};
