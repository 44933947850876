export enum ToolbarActionIcon {
  Save = 'icon-save-outline',
  View = 'icon-view-outline',
  Back = 'icon-left-chevron-outline',
  Forward = 'icon-right-chevron-outline',
  User = 'icon-person-outline',
  UserActive = 'icon-person-fill',
  Confirm = 'icon-confirmed-outline',
  ConfirmActive = 'icon-confirmed-fill',
  Share = 'icon-share-outline',
  Download = 'icon-download-outline',
  Time = 'icon-time-outline',
  TimeActive = 'icon-time-fill',
  Delete = 'icon-delete-outline',
  DeleteActive = 'icon-delete-fill',
  Bookmark = 'icon-archive-outline',
  BookmarkActive = 'icon-archive-fill',
}
