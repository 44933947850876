import { Box } from '@chakra-ui/react';
import { FC } from 'types';
import { Droppable } from 'react-beautiful-dnd';

import { FormComponent } from 'models/FormComponent';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { FormEditorDroppable } from 'typings/enums/FormEditorDroppable';
import { useDatx } from 'hooks/useDatx';
import { QuestionClone } from 'components/form-editor/QuestionClone/QuestionClone';

import { Question } from '../../Question';

interface IQuestionSetChildrenProps {
  model: FormComponent;
  isDropDisabled: boolean;
}
export const QuestionSetChildren: FC<IQuestionSetChildrenProps> = ({ model, isDropDisabled }) => {
  const datx = useDatx();

  const children =
    model.componentType === FormComponentType.QuestionSet
      ? datx
          .findAll<FormComponent>(FormComponent)
          .filter((component) => component.parentComponentId === model.id)
          .sort((a, b) => {
            if (a.index !== b.index) {
              return a.index - b.index;
            }
            if (a.isPlaceholder) {
              return -1;
            }
            if (b.isPlaceholder) {
              return 1;
            }
            return 0;
          })
      : null;

  return (
    <Droppable
      droppableId={`${FormEditorDroppable.QuestionSetQuestions}-${model.id}`}
      isDropDisabled={isDropDisabled}
      renderClone={(provided, snapshot, rubric) => {
        const component = children?.[rubric.source.index];

        return (
          <Box {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            {component && <QuestionClone model={component} />}
          </Box>
        );
      }}
    >
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          mt={12}
          sx={{
            border: (props) =>
              snapshot.isDraggingOver ? `0.5px dashed ${props.colors.brand.orange}` : 'none',
            borderLeft: 'none',
          }}
        >
          {children?.map((question, index) => (
            <Question key={question.id} index={index} model={question} />
          ))}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
};
