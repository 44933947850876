import { FormAddonType } from 'typings/enums/FormAddonType';
import { ConditionAddonSettingsEditor } from 'components/form-editor/ConditionAddonSettingsEditor/ConditionAddonSettingsEditor';
import { HelpTextAddonSettingsEditor } from 'components/form-editor/HelpTextAddonSettingsEditor/HelpTextAddonSettingsEditor';
import { SkipConditionAddonSettingsEditor } from 'components/form-editor/SkipConditionAddonSettingsEditor/SkipConditionAddonSettingsEditor';

export function getAddon(addonType: FormAddonType) {
  switch (addonType) {
    case FormAddonType.HelpText:
      return HelpTextAddonSettingsEditor;
    case FormAddonType.Condition:
      return ConditionAddonSettingsEditor;
    case FormAddonType.SkipCondition:
      return SkipConditionAddonSettingsEditor;
    case FormAddonType.Attachment:
    case FormAddonType.Mandatory:
    case FormAddonType.MoreAnswers:
    case FormAddonType.Label:
    default:
      return null;
  }
}
