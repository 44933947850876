import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { HistoryModal } from 'components/modal/HistoryModal/HistoryModal';
import { RoomHistoryContent } from './components/RoomHistoryContent/RoomHistoryContent';
import { useRoomAudit } from 'hooks/useAudit';
import { useCurrentRoom } from 'hooks/useCurrentRoom';

interface IRoomHistoryModalProps {
  onClose(): void;
}
export const RoomHistoryModal: FC<IRoomHistoryModalProps> = ({ onClose }) => {
  const { data, isInitialLoad } = useRoomAudit();
  const { room } = useCurrentRoom();

  const { t } = useTranslation();

  return (
    <HistoryModal title={t(`room.sidebar.roomDetails.modals.history.title`)} onClose={onClose}>
      <RoomHistoryContent isLoading={isInitialLoad} data={data} room={room} />
    </HistoryModal>
  );
};
