import { FC } from 'types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormComponent } from 'models/FormComponent';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { FormModal } from 'components/modal/FormModal/FormModal';
import { ChoiceQuestionValue } from 'typings/types/FormComponentSettings';
import { FormAddonType } from 'typings/enums/FormAddonType';
import { FormComponentType } from 'typings/enums/FormComponentType';
import {
  parseSkipConditionSettings,
  getSkipConditionSettingsFromChoices,
} from 'services/skipConditionAddonSettings';

import { ChoiceSettingsForm } from '../ChoiceSettingsForm/ChoiceSettingsForm';
import { parseChoiceValues, parseMultipleChoiceSettings } from './ChoiceEditorModal.utils';

interface IChoiceEditorProps {
  question: FormComponent;
  orderTag: string;
  onClose(): void;
}
export const ChoiceEditorModal: FC<IChoiceEditorProps> = ({ orderTag, question, onClose }) => {
  const { t } = useTranslation();
  const { upsertComponent, disableEdit } = useFormEditor();

  const isMultipleChoice = question.componentType === FormComponentType.MultipleChoice;
  const parsed = parseChoiceValues(question.valueJson);

  const skipConditionAddon = question.addons?.find(
    (addon) => addon.addonType === FormAddonType.SkipCondition,
  );
  const parsedSkipConditionSettings = skipConditionAddon
    ? parseSkipConditionSettings(skipConditionAddon)
    : undefined;

  const parsedMultipleChoiceSettings = isMultipleChoice
    ? parseMultipleChoiceSettings(question.settingsJson)
    : undefined;

  const handleClickSave = useCallback(
    async (values: Array<ChoiceQuestionValue>, noneOfTheAbove?: string) => {
      if (disableEdit) {
        return;
      }
      if (skipConditionAddon) {
        const addons =
          question.addons?.filter((addon) => addon.addonType !== FormAddonType.SkipCondition) ?? [];
        addons.push({
          addonType: FormAddonType.SkipCondition,
          settingsJson: JSON.stringify(getSkipConditionSettingsFromChoices(question.id, values)),
        });
        question.addons = addons;
      }
      if (isMultipleChoice) {
        question.settingsJson = noneOfTheAbove
          ? JSON.stringify({ exclusiveOptionText: noneOfTheAbove })
          : null;
      }
      question.valueJson = values.length ? JSON.stringify(values) : null;
      await upsertComponent(question);
      onClose();
    },
    [disableEdit, isMultipleChoice, onClose, question, skipConditionAddon, upsertComponent],
  );

  return (
    <FormModal
      isOpen
      title={t('form.settings.modal.title', {
        questionNo: orderTag,
        questionType: isMultipleChoice
          ? t('form.settings.multipleChoice.name')
          : t('form.settings.singleChoice.name'),
      })}
      maxWidth="1600px"
      onClose={onClose}
    >
      <ChoiceSettingsForm
        question={question}
        settings={parsed}
        noneOfTheAboveText={parsedMultipleChoiceSettings}
        skipConditionSettings={parsedSkipConditionSettings}
        disabled={disableEdit}
        onSubmit={handleClickSave}
        onCancel={onClose}
      />
    </FormModal>
  );
};
