import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { FormApproval } from 'models/FormApproval';
import { PopAlertFail } from 'services/PopAlertFail';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { FormApprovalDTO, UpsertFormApprovalDTO } from 'typings/types/dto/ApprovalDTO';
import { apiClient } from 'utils/apiClient';
import { useDatx } from 'hooks/useDatx';

export const useFormApproval = (roomId: string, formId: string) => {
  const { t } = useTranslation();
  const dependencyHeaders = useDependencyHeaders();
  const datx = useDatx();

  const siteId = dependencyHeaders['x-site-id'];
  const key = siteId ? `form-${formId}-approval@${siteId}` : null;

  const swr = useSWR(
    key,
    async () => {
      const response = await apiClient(`rooms/${roomId}/forms/${formId}/approval`, HttpMethod.Get, {
        headers: {
          ...dependencyHeaders,
        },
      });
      return datx.add(response as FormApprovalDTO, FormApproval); // tell ts that response is an object and not an array
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  const updateApproval = async (dto: UpsertFormApprovalDTO, errorMessage?: string) => {
    const url = `rooms/${roomId}/forms/${formId}/approval`;
    const method = HttpMethod.Post;

    try {
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify(dto),
      });
    } catch (response: any) { // eslint-disable-line
      const title = errorMessage ?? t('alerts.fail.title');
      const message = response.message ?? t('form.errors.generic');
      PopAlertFail(title, message);
    } finally {
      await swr.mutate();
    }
  };

  return {
    updateApproval,
    isInitialLoad: !swr.data && !swr.error,
    approval: swr.data,
    ...swr,
  };
};
