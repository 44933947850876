import { createContext, useContext } from 'react';
import { FC } from 'types';
import { Box, Center, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import { FullScreenLoadingOverlay } from 'components/core/FullScreenLoadingOverlay/FullScreenLoadingOverlay';
import { IErrorResponse } from 'typings/interfaces/IErrorResponse';
import { IAssessmentBase } from 'typings/interfaces/IAssessmentBase';
import { useCurrentUser } from 'hooks/useCurrentUser';

import { useAssessmentState } from './AssessmentContext.hooks';
import { AssessmentStatusDispatch } from './AssessmentContext.utils';

type AssessmentContextValue = {
  assessmentId: string | undefined;
  assessment: IAssessmentBase | undefined;
  isBookmarkFilterOn: boolean;
  isEditable: boolean;
  skippedComponents: Array<string>;
  isInitiator: boolean;
  isLoading: boolean;
  isUpdatingResult: boolean;
  updatingComponentIds: Array<string>;
  isRevalidating: boolean;
  timestamp: string | undefined;
  dispatch: AssessmentStatusDispatch;
};

const AssessmentContext = createContext<AssessmentContextValue | undefined>(undefined);

const useAssessmentForm = () => {
  const context = useContext(AssessmentContext);
  if (context === undefined) {
    throw new Error('useAssessmentForm must be used within a AssessmentProvider');
  }

  return context;
};

interface IFormEditorProviderProps {
  assessmentId: string | undefined;
  assessment: IAssessmentBase | undefined;
  isLoaded: boolean;
  timestamp?: string;
  error?: IErrorResponse;
}
const AssessmentProvider: FC<IFormEditorProviderProps> = ({
  assessmentId,
  assessment,
  error,
  isLoaded,
  timestamp,
  children,
}) => {
  const state = useAssessmentState(assessmentId, assessment, timestamp);
  const currentUser = useCurrentUser();
  const { t } = useTranslation();

  return (
    <ErrorBoundary FallbackComponent={AssessmentErrorFallback}>
      <AssessmentContext.Provider value={state}>
        <FullScreenLoadingOverlay
          isLoading={
            (!isLoaded && !error) || !currentUser || state.isLoading || state.isRevalidating
          }
        >
          {/* TODO: when assessment is deleted, BE returns 403
        Do we want to show some error message, or redirect to 404 page */}
          {error && (
            <Center height="100%">
              <VStack>
                <Box>{error.message}</Box>
                {error?.statusCode === 404 && <Box>{t('verifySite')}</Box>}
              </VStack>
            </Center>
          )}

          {isLoaded && !error && children}
        </FullScreenLoadingOverlay>
      </AssessmentContext.Provider>
    </ErrorBoundary>
  );
};

export { AssessmentProvider, useAssessmentForm };

interface IAssessmentErrorFallbackProps {
  error: Error;
}
const AssessmentErrorFallback: FC<IAssessmentErrorFallbackProps> = ({ error }) => {
  return (
    <Center height="100%">
      <VStack>
        <Box>{error.message}</Box>
      </VStack>
    </Center>
  );
};
