import { Flex, Box } from '@chakra-ui/react';
import { Pagination } from 'appkit-react';
import { FC } from 'types';

import { Room } from 'models/Room';
import { RoomsCardSort } from 'typings/enums/RoomsCardSort';
import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';

import { RoomCard } from '../RoomCard/RoomCard';
import { getRoomsSortFn } from './RoomsCardView.utils';

const DEFAULT_PAGE_SIZE = 10;

interface IRoomsCardViewProps {
  rooms: Array<Room>;
  sortMode?: RoomsCardSort;
  isLoading: boolean;
  pageNumber: number;
  onPageChange(pageNumber: number): void;
}

export const RoomsCardView: FC<IRoomsCardViewProps> = ({
  rooms,
  isLoading,
  sortMode = RoomsCardSort.None,
  pageNumber,
  onPageChange,
}) => {
  if (sortMode !== RoomsCardSort.None) {
    rooms.sort(getRoomsSortFn(sortMode));
  }

  return (
    <Box>
      <TableWrapper isLoading={isLoading}>
        <Flex wrap="wrap" gridColumnGap="20px" gridRowGap="20px" mb="20px">
          {rooms
            .slice((pageNumber - 1) * DEFAULT_PAGE_SIZE, pageNumber * DEFAULT_PAGE_SIZE)
            .map((room) => (
              <RoomCard key={room.id} room={room} />
            ))}
        </Flex>
      </TableWrapper>
      <Pagination
        current={pageNumber}
        total={rooms.length}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        onChange={(navigationType: string, pageNumber: number) => {
          onPageChange(pageNumber);
        }}
      />
    </Box>
  );
};
