import { FC } from 'types';
import { Control, useFieldArray } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { User } from 'models/User';
import { useResources } from 'hooks/useResources';
import { AssessmentCollaboratorsFields } from 'typings/types/dto/AssessmentDTO';
import { Total } from 'components/core/Total/Total';
import { IPermissionGroupDto } from 'typings/interfaces/dto/IPermissionGroupDto';
import { UserManagementSearch } from 'components/user-management/UserManagementSearch/UserManagementSearch';
import { userSearchValidation } from 'services/validation/userSearch';

import { AssessmentCollaboratorsList } from '../AssessmentCollaboratorsList/AssessmentCollaboratorsList';
import { Assessment } from 'models/Assessment';
import useSWR from 'swr';
import { apiClient } from 'utils/apiClient';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { HttpMethod } from 'typings/enums/HttpMethod';

interface IAssessmentCollaboratorsProps {
  assessment: Assessment;
  control: Control<AssessmentCollaboratorsFields>;
  permissionGroups: Array<IPermissionGroupDto>;
}

export const AssessmentCollaborators: FC<IAssessmentCollaboratorsProps> = ({
  assessment,
  control,
  permissionGroups,
}) => {
  const { t } = useTranslation();
  const { data, isInitialLoad } = useResources(User);
  const { fields, append, remove, update } = useFieldArray<AssessmentCollaboratorsFields>({
    control,
    name: 'permissionGroupUsers',
  });

  const dependencyHeaders = useDependencyHeaders();
  const siteId = dependencyHeaders['x-site-id'];
  const key = siteId ? `assessment-collaborators@${siteId}` : null;

  const { data: collaborators } = useSWR(
    key,
    async () => {
      const response = await apiClient(
        `Assessments/${assessment.id}/collaborators`,
        HttpMethod.Get,
        {
          headers: {
            ...dependencyHeaders,
          },
        },
      );

      return response;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const handleAdd = (email: string) => {
    const user = (data as Array<User>).find((user) => user.email === email);
    if (user) {
      append({
        userId: user.id,
        username: user.username,
        email: user.email,
        permissionGroupId: permissionGroups[0].id,
      });
    }
  };

  const handleChangeGroup = (index: number, permissionGroupId: string) => {
    update(index, { ...fields[index], permissionGroupId });
  };

  return (
    <Box width="100%">
      <UserManagementSearch
        label={t('approval.form.approvers.searchUsersLabel')}
        placeholder={t('approval.form.approvers.searchUsersPlaceholder')}
        validationRules={userSearchValidation(
          t,
          (value: string) =>
            Boolean(
              (data as Array<User>).find((user) => user.email === value || user.username === value),
            ),
          (value: string) =>
            Boolean(fields?.find((user) => user.email === value || user.username === value)),
        )}
        disableButton={isInitialLoad}
        onClick={handleAdd}
      />
      <Total
        label={t('approval.form.approvers.totalUsers')}
        total={fields.length + (collaborators ?? []).length}
        w="100%"
        mt={20}
      />
      <AssessmentCollaboratorsList
        collaborators={collaborators}
        permissionGroups={permissionGroups}
        addedFields={fields}
        onChangeGroup={handleChangeGroup}
        onRemove={remove}
      />
    </Box>
  );
};
