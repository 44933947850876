import { Box, Center, Text, Image } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { ScaleType } from 'typings/types/FormComponentSettings';
import { RadioCardGroup } from 'components/core/RadioCardGroup/RadioCardGroup';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormActionButton } from 'components/core/FormActions/FormActionButton';

interface IScaleSliderSelectionProps {
  activeType: ScaleType;
  disabled?: boolean;
  onChange(type: ScaleType): void;
  onCancel(): void;
  onNext(): void;
}
// TODO: replace images
export const ScaleSliderSelection: FC<IScaleSliderSelectionProps> = ({
  activeType,
  disabled,
  onCancel,
  onNext,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Center>
        <RadioCardGroup
          name="scaleType"
          onChange={onChange}
          defaultValue={activeType}
          disabled={disabled}
        >
          <Box
            width="270px"
            boxShadow="mainWhiteSmall"
            flex="1"
            // @ts-expect-error
            value={ScaleType.Default as string}
          >
            <Center bg="grey.lvl1" height="150px">
              <Image
                src="/assets/images/default-slider-preview.png"
                alt={t('form.settings.scale.images.defaultSliderAlt')}
                width="100%"
                height="auto"
              />
            </Center>
            <Text size="lg" pt={8} px={16}>
              {t('form.settings.scale.sliderType.default.title')}
            </Text>
            <Text size="xs" py={20} px={16}>
              {t('form.settings.scale.sliderType.default.description')}
            </Text>
          </Box>
          {/* @ts-expect-error */}
          <Box width="270px" boxShadow="mainWhiteSmall" flex="1" value={ScaleType.Range as string}>
            <Center bg="grey.lvl1" height="150px">
              <Image
                src="/assets/images/range-slider-preview.png"
                alt={t('form.settings.scale.images.rangeSliderAlt')}
                width="100%"
                height="auto"
              />
            </Center>
            <Text size="lg" pt={8} px={16}>
              {t('form.settings.scale.sliderType.range.title')}
            </Text>
            <Text size="xs" py={20} px={16}>
              {t('form.settings.scale.sliderType.range.description')}
            </Text>
          </Box>
          <Box
            width="270px"
            boxShadow="mainWhiteSmall"
            flex="1"
            // @ts-expect-error
            value={ScaleType.Interval as string}
          >
            <Center bg="grey.lvl1" height="150px">
              <Image
                src="/assets/images/interval-slider-preview.png"
                alt={t('form.settings.scale.images.intervalSliderAlt')}
                width="100%"
                height="auto"
              />
            </Center>
            <Text size="lg" pt={8} px={16}>
              {t('form.settings.scale.sliderType.interval.title')}
            </Text>
            <Text size="xs" py={20} px={16}>
              {t('form.settings.scale.sliderType.interval.description')}
            </Text>
          </Box>
        </RadioCardGroup>
      </Center>
      <FormActions>
        <FormButtonCancel onClick={onCancel} />
        <FormActionButton kind="secondary" disabled>
          {t('action.back.label')}
        </FormActionButton>
        <FormActionButton kind="primary" onClick={onNext}>
          {t('action.next.label')}
        </FormActionButton>
      </FormActions>
    </Box>
  );
};
