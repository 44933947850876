import { Box, Heading, HStack, VStack, Spacer } from '@chakra-ui/react';
import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormActions } from 'components/core/FormActions/FormActions';
import { Comment } from 'models/Comment';
import { FormActionButton } from 'components/core/FormActions/FormActionButton';
import { Total } from 'components/core/Total/Total';
import { ReviewAction } from 'typings/enums/ReviewAction';
import { IReviewer } from 'typings/interfaces/IReviewer';
import { ReviewComment } from 'typings/types/ReviewComment';
import { ApprovalEntity } from 'typings/enums/ApprovalEntity';
import { useCurrentUserEmail } from 'hooks/useCurrentUserEmail';

import { ReviewAddComment } from '../ReviewAddComment/ReviewAddComment';
import { ReviewApprovalStatus } from '../ReviewApprovalStatus/ReviewApprovalStatus';
import { ReviewEditButton } from '../ReviewEditButton/ReviewEditButton';
import { ReviewRejectButton } from '../ReviewRejectButton/ReviewRejectButton';
import { ReviewerComment } from '../ReviewerComment/ReviewerComment';
import { IAsyncResult } from 'typings/interfaces/IAsyncResult';

interface IReviewFormProps {
  entityType: ApprovalEntity;
  currentApprovals: number | undefined;
  requiredApprovals: number | undefined;
  comments: Array<Comment>;
  approvers: Array<IReviewer>;
  isUnderReview: boolean;
  onReviewAction: (action: ReviewAction) => Promise<void>;
  onSubmit(reviewComment: ReviewComment): Promise<IAsyncResult>;
  onCancel(): void;
}
export const ReviewForm: FC<IReviewFormProps> = ({
  entityType,
  currentApprovals,
  requiredApprovals,
  comments,
  approvers,
  isUnderReview,
  onReviewAction,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const currentUserEmail = useCurrentUserEmail();
  const isApprover =
    approvers.some((approver) => approver.email.toLowerCase() === currentUserEmail) ?? false;

  const totalApprovals = `${currentApprovals ?? '?'}/${requiredApprovals ?? '?'}`;

  const handelApprove = async () => {
    setIsLoading(true);
    await onReviewAction(ReviewAction.Approve);
    setIsLoading(false);
  };

  return (
    <Box>
      <HStack spacing={40} alignItems="flex-start">
        <VStack spacing={20} alignItems="flex-start" width="45%">
          <Heading as="h2" size="xl">
            {t('review.modal.title', { entity: t(`approval.entity.${entityType}`) })}
          </Heading>
          <Heading as="h3" size="lg" mb={20}>
            {t('review.comments.title')}
          </Heading>

          <VStack spacing={20} width="100%" maxHeight="330px" overflow="auto">
            {comments.map((comment) => (
              <ReviewerComment key={comment.id} model={comment} />
            ))}
          </VStack>

          <ReviewAddComment onSubmit={onSubmit} />
        </VStack>
        <VStack h="100%" w="55%" spacing={0}>
          <Heading as="h3" size="lg" width="100%">
            {t('review.modal.subtitle')}
          </Heading>
          <Total
            label={t('review.totalApprovals')}
            total={totalApprovals}
            w="100%"
            pt={20}
            pb={8}
          />
          <VStack
            spacing={0}
            width="100%"
            alignItems="start"
            pb={20}
            sx={{
              borderTop: ({ colors }) => `2px solid ${colors.grey.lvl3}`,
            }}
          >
            {approvers.map((reviewer) => (
              <ReviewApprovalStatus
                key={reviewer.id}
                username={reviewer.username}
                status={reviewer.reviewAction}
              />
            ))}
          </VStack>
          <Spacer flex={1} />
          <FormActions width="100%">
            <ReviewEditButton
              entityType={entityType}
              disabled={!isApprover || !isUnderReview}
              onConfirm={async () => await onReviewAction(ReviewAction.Withdraw)}
            />
            <ReviewRejectButton
              entityType={entityType}
              disabled={!isApprover || !isUnderReview}
              onConfirm={async () => await onReviewAction(ReviewAction.Reject)}
            />
            <FormActionButton
              disabled={!isApprover || !isUnderReview}
              isLoading={isLoading}
              onClick={handelApprove}
            >
              {t('review.action.approve')}
            </FormActionButton>
          </FormActions>
        </VStack>
      </HStack>
    </Box>
  );
};
