import { Box } from '@chakra-ui/react';
import { FC } from 'types';
import { useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useModal } from 'hooks/useModal';
import { CampaignResponse } from 'models/CampaignResponse';
import { StyledLink } from 'components/core/StyledLink/StyledLink';
import { useMenuActions } from 'hooks/useMenuActions';
import { CampaignModalWrapper } from 'components/campaign/CampaignModalWrapper/CampaignModalWrapper';
import { CampaignExportModal } from 'components/form-campaigns/CampaignExportModal/CampaignExportModal';

import { FormCampaignsTableViewMenu } from '../FormCampaignsTableViewMenu/FormCampaignsTableViewMenu';

interface IFormCampaignsTableViewCellNameProps {
  campaignViewItem: CampaignResponse;
}

export const FormCampaignsTableViewCellName: FC<IFormCampaignsTableViewCellNameProps> = ({
  campaignViewItem,
}) => {
  const {
    params: { roomId, formId },
  } = useRouteMatch<{ roomId: string; formId: string }>();

  const {
    isOpen: isManageModalOpen,
    onOpen: onManageModalOpen,
    onClose: onManageModalClose,
  } = useModal();

  const linkRef = useRef<HTMLAnchorElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);

  const {
    isOpen: isMenuOpen,
    onClose: onMenuClose,
    menuPosition,
  } = useMenuActions(linkRef, cellRef);

  const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onClose: onExportModalClose,
  } = useModal();

  return (
    <Box ref={cellRef} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
      <StyledLink
        title={campaignViewItem.name}
        cursor="pointer"
        to={`/rooms/${roomId}/forms/${formId}/campaign/${campaignViewItem.id}`}
        ref={linkRef}
      >
        {campaignViewItem.name}
      </StyledLink>

      {isMenuOpen && (
        <FormCampaignsTableViewMenu
          top={menuPosition?.y}
          left={menuPosition?.x}
          roomId={roomId}
          formId={formId}
          campaignViewItem={campaignViewItem}
          onManageModalOpen={onManageModalOpen}
          onExportModalOpen={onExportModalOpen}
          onClose={onMenuClose}
        />
      )}
      {isManageModalOpen && (
        <CampaignModalWrapper
          formId={formId}
          roomId={roomId}
          campaignId={campaignViewItem.id}
          onCancel={onManageModalClose}
        />
      )}
      {isExportModalOpen && (
        <CampaignExportModal
          formId={formId}
          selectedIds={[campaignViewItem.id]}
          onClose={onExportModalClose}
        />
      )}
    </Box>
  );
};
