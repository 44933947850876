import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useState } from 'react';
import { useSWRConfig } from 'swr';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Comment as CommentModel } from 'models/Comment';
import { apiClient } from 'utils/apiClient';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { useCurrentRoom } from 'hooks/useCurrentRoom';
import { WordBreakingText } from 'components/core/WordBreakingText/WordBreakingText';
import { RichTextViewer } from 'components/core/RichTextViewer/RichTextViewer';
import { Avatar } from 'components/core/Avatar/Avatar';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

import {
  StyledCommentBoxFooter,
  StyledCommentBoxHeader,
  StyledCommentBoxWrapper,
} from './CommentBox.elements';

interface ICommentProps {
  comment: CommentModel;
  onEdit(comment: CommentModel): void;
}
export const CommentBox: FC<ICommentProps> = ({ comment, onEdit }) => {
  const { roomId } = useParams<{ roomId?: string }>();
  const { mutate } = useSWRConfig();

  const [isDeleting, setIsDeleting] = useState(false);
  const dependencyHeaders = useDependencyHeaders();
  const { room } = useCurrentRoom();

  const { t } = useTranslation();

  const deleteComment = async () => {
    setIsDeleting(true);

    try {
      await apiClient(`rooms/${roomId}/comments/${comment.id}`, HttpMethod.Delete, {
        headers: {
          'content-type': 'application/json',
          ...dependencyHeaders,
        },
      });
      mutate(`comments-${roomId}@${dependencyHeaders['x-site-id']}`);
      PopAlertSuccess(t('room.sidebar.noticeBoard.commentBox.successMessages.deleted'));
    } catch (response: any) { // eslint-disable-line
      const message = response.message || t('room.errors.comments.delete');

      PopAlertFail(message);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <StyledCommentBoxWrapper>
      <StyledCommentBoxHeader>
        <Flex alignItems="center">
          <Avatar name={comment.user.username} mr="15px" />
          <WordBreakingText
            breakAt="@"
            sx={{
              fontSize: '16px',
              lineHeight: '28px',
              fontWeight: '500',
              letterSpacing: '0',
            }}
          >
            {comment.user.username}
          </WordBreakingText>
        </Flex>
        <Text
          sx={{
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: '0',
            minWidth: '130px',
          }}
        >
          {comment.formattedCreatedAt}
        </Text>
      </StyledCommentBoxHeader>
      <RichTextViewer px="10px" dangerouslySetInnerHTML={{ __html: comment.comment }} />
      {room?.isRoomAdmin && (
        <StyledCommentBoxFooter>
          <StyledButton
            kind="transparent"
            className="a-mr-10"
            isLoading={isDeleting}
            disabled={isDeleting}
            onClick={deleteComment}
          >
            {t('action.delete.label')}
          </StyledButton>
          <StyledButton disabled={isDeleting} onClick={() => onEdit(comment)}>
            {t('action.edit.label')}
          </StyledButton>
        </StyledCommentBoxFooter>
      )}
    </StyledCommentBoxWrapper>
  );
};
