import { Box } from '@chakra-ui/react';
import { FC } from 'types';
import { useRef } from 'react';

import { useRoomId } from 'components/room/room.hooks';
import { StyledLink } from 'components/core/StyledLink/StyledLink';
import { useMenuActions } from 'hooks/useMenuActions';
import { Form } from 'models/Form';
import { useModal } from 'hooks/useModal';
import { InitiateCampaignModalWrapper } from 'components/campaign/InitiateCampaignModalWrapper/InitiateCampaignModalWrapper';
import { CreateDuplicateFormModal } from 'components/room/CreateDuplicateFormModal/CreateDuplicateFormModal';

import { FormMenu } from '../FormMenu/FormMenu';
import { FormRenameModal } from '../FormRenameModal/FormRenameModal';
import { FormUserManagementModal } from '../FormUserManagementModal/FormUserManagementModal';

interface IRoomCellNameFormProps {
  form: Form;
}

export const RoomCellNameForm: FC<IRoomCellNameFormProps> = ({ form }) => {
  const roomId = useRoomId();

  const {
    isOpen: isEditModalOpen,
    onClose: onEditModalClose,
    onOpen: onEditModalOpen,
  } = useModal();

  const {
    isOpen: isInitiateModalOpen,
    onClose: onInitiateModalClose,
    onOpen: onInitiateModalOpen,
  } = useModal();

  const {
    isOpen: isMembersModalOpen,
    onClose: onMembersModalClose,
    onOpen: onMembersModalOpen,
  } = useModal();

  const {
    isOpen: isDuplicateModalOpen,
    onClose: onDuplicateModalClose,
    onOpen: onDuplicateModalOpen,
  } = useModal();

  // for V2
  // const {
  //   isOpen: isCopyModalOpen,
  //   onClose: onCopyModalClose,
  //   onOpen: onCopyModalOpen,
  // } = useModal();

  const linkRef = useRef<HTMLAnchorElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);

  const {
    isOpen: isMenuOpen,
    onClose: onMenuClose,
    menuPosition,
  } = useMenuActions(linkRef, cellRef);

  return (
    <Box ref={cellRef} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
      <StyledLink
        title={form.name}
        cursor="pointer"
        to={`/rooms/${roomId}/forms/${form.id}`}
        ref={linkRef}
      >
        {form.name}
      </StyledLink>

      {isMenuOpen && (
        <FormMenu
          top={menuPosition?.y}
          left={menuPosition?.x}
          form={form}
          onInitiateCampaignOpen={onInitiateModalOpen}
          onEditModalOpen={onEditModalOpen}
          onShareModalOpen={onMembersModalOpen}
          onDuplicateModalOpen={onDuplicateModalOpen}
          onClose={onMenuClose}
        />
      )}
      {isEditModalOpen && <FormRenameModal form={form} onCancel={onEditModalClose} />}
      {isMembersModalOpen && <FormUserManagementModal form={form} onCancel={onMembersModalClose} />}
      {isDuplicateModalOpen && (
        <CreateDuplicateFormModal form={form} onClose={onDuplicateModalClose} />
      )}
      {/* For V2 */}
      {/* {isCopyModalOpen && <FormCopyModal form={form} onCancel={onCopyModalClose} />} */}
      {isInitiateModalOpen && (
        <InitiateCampaignModalWrapper
          formId={form.id}
          roomId={roomId}
          onCancel={onInitiateModalClose}
        />
      )}
    </Box>
  );
};
