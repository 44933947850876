import { useSWRConfig } from 'swr';

import { RoomAdmin } from 'models/RoomAdmin';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { buildUrl } from 'utils/buildUrl';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';

export function useRoomApproval(id: string) {
  const dependencyHeaders = useDependencyHeaders();
  const { mutate } = useSWRConfig();

  const key = `${buildUrl([RoomAdmin])}@${dependencyHeaders['x-site-id']}`;

  const approveCreation = async () => {
    await apiClient(`rooms/${id}/approveCreation`, HttpMethod.Post, {
      headers: {
        ...dependencyHeaders,
      },
    });
  };

  const approveUpgrade = async () => {
    await apiClient(`rooms/${id}/changeToExternalApproval`, HttpMethod.Post, {
      headers: {
        ...dependencyHeaders,
      },
    });
  };

  const reject = async () => {
    await apiClient(`rooms/${id}/changeToExternalReject`, HttpMethod.Post, {
      headers: {
        ...dependencyHeaders,
      },
    });
  };

  return {
    approveCreation,
    approveUpgrade,
    reject,
    refresh: async () => mutate(key),
  };
}
