import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Spacer } from '@chakra-ui/react';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { FormAction } from 'typings/enums/FormAction';
import { FormEditorReviewButton } from 'components/form-editor/FormEditorReviewButton/FormEditorReviewButton';
import { useFormAction } from 'hooks/useRoomForms';
import { Form } from 'models/Form';
import { useCurrentUserEmail } from 'hooks/useCurrentUserEmail';

import { FormEditorInitiateButton } from '../FormEditorInitiateButton/FormEditorInitiateButton';
import { FormEditorActionTooltip } from '../FormEditorActionTooltip/FormEditorActionTooltip';

import { getFormAction } from './FormEditorActionButton.utils';

interface IFormEditorActionButtonProps {
  form: Form | undefined;
  roomId: string;
  formId: string;
  isEditing: boolean;
}

export const FormEditorActionButton: FC<IFormEditorActionButtonProps> = ({
  form,
  formId,
  roomId,
  isEditing,
}) => {
  const { doFormReviewAction, isLoading } = useFormAction(roomId, formId);
  const currentUserEmail = useCurrentUserEmail();
  const { t } = useTranslation();

  const { formStatus, isActive = false, hasApproverFlag = false } = form ?? {};

  if (!isActive) {
    return <Spacer width="50px" />;
  }

  const action = getFormAction(
    formStatus,
    hasApproverFlag,
    form?.hasApproverWithEmail(currentUserEmail) ?? false,
    form?.hasEditor(currentUserEmail) ?? false,
  );

  if (action === FormAction.Review) {
    return (
      <FormEditorReviewButton
        roomId={roomId}
        formId={formId}
        formStatus={formStatus}
        disabled={isEditing}
        isLoading={isLoading}
      />
    );
  }

  if (action === FormAction.Initiate) {
    return (
      <FormEditorInitiateButton
        roomId={roomId}
        formId={formId}
        isLoading={isLoading}
        disabled={isEditing}
      />
    );
  }

  if (action === FormAction.EditFinalized) {
    return (
      <Fragment>
        <FormEditorActionTooltip content={t('form.editTooltip')} />
        <FormEditorInitiateButton
          roomId={roomId}
          formId={formId}
          isLoading={isLoading}
          disabled={isEditing}
        />
      </Fragment>
    );
  }

  return (
    <StyledButton
      size="md"
      disabled={isEditing}
      minWidth="50px"
      onClick={() => doFormReviewAction(action)}
      isLoading={isLoading}
    >
      {t(`form.action.${action}`)}
    </StyledButton>
  );
};
