import { Center, chakra } from '@chakra-ui/react';

export const FormEditorSidebarMenuItemElement = chakra(Center, {
  baseStyle: {
    w: '150px',
    minH: '30px',
    bg: 'grey.lvl4',
    textAlign: 'center',
    fontSize: '12',
    lineHeight: '18',
    _hover: {
      opacity: '0.75',
    },
  },
});
