import { FC } from 'types';
import { useTranslation, TFunction } from 'react-i18next';
import { Table } from 'appkit-react';

import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { FormsTableCellStatus } from './components/FormsTableCellStatus/FormsTableCellStatus';
import { FormsTableCellNameWrapper } from './components/FormsTableCellNameWrapper/FormsTableCellNameWrapper';
import { FormsTableFavoriteCell } from './components/FormsTableFavoriteCell/FormsTableFavoriteCell';
import { TableCellRow } from 'typings/types/TableCellRow';
import { Form } from 'models/Form';
import { useCurrentUserEmail } from 'hooks/useCurrentUserEmail';
import { dateSortMethod } from 'utils/dateSortMethod';

interface IFormsTableProps {
  forms: Array<Form>;
  isLoading: boolean;
}

const formsColumns = (t: TFunction, userEmail: string) => [
  {
    Cell: (row: TableCellRow) => <FormsTableFavoriteCell form={row.original} />,
    minWidth: 25,
    id: 'type',
    accessor: (form: Form) => {
      return form.isFavorited(userEmail);
    },
    sortMethod(a: boolean, b: boolean) {
      return b > a ? 1 : -1;
    },
  },
  {
    Header: t('forms.tableView.tableColumns.name'),
    accessor: 'name',
    Cell: FormsTableCellNameWrapper,
    minWidth: 300,
  },
  {
    Header: t('forms.tableView.tableColumns.roomName'),
    accessor: 'room.name',
    minWidth: 200,
  },
  {
    Header: t('forms.tableView.tableColumns.owner'),
    accessor: 'ownerUser.username',
    minWidth: 200,
  },
  {
    Header: t('forms.tableView.tableColumns.lastEdited'),
    accessor: 'formattedLastEdit',
    minWidth: 200,
    sortMethod: dateSortMethod,
  },
  {
    Header: t('forms.tableView.tableColumns.status'),
    accessor: 'formStatus',
    Cell: (row: TableCellRow) => <FormsTableCellStatus form={row.original} />,
    minWidth: 100,
  },
];

export const FormsTable: FC<IFormsTableProps> = ({ forms, isLoading }) => {
  const { t } = useTranslation();
  const userEmail = useCurrentUserEmail();

  return (
    <TableWrapper isLoading={isLoading}>
      <Table
        columns={formsColumns(t, userEmail)}
        data={forms}
        showPageSizeOptions={false}
        defaultPageSize={25}
        defaultSorted={[{ id: 'name', asc: true }]}
      />
    </TableWrapper>
  );
};
