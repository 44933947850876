import useSWR from 'swr';

import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { useDatx } from 'hooks/useDatx';
import { AssessmentView } from 'models/AssessmentView';

export const useFormCampaignAssessmentsTable = (
  roomId: string,
  formId: string,
  campaignId: string,
  searchQuery: string,
) => {
  const datx = useDatx();
  const dependencyHeaders = useDependencyHeaders();

  const siteId = dependencyHeaders['x-site-id'];
  const key = siteId ? `form-campaign-assessments@${siteId}` : null;

  const swr = useSWR(
    key,
    async () => {
      const response = await apiClient(
        `rooms/${roomId}/forms/${formId}/campaign/${campaignId}/assessments`,
        HttpMethod.Get,
        {
          headers: {
            ...dependencyHeaders,
          },
        },
      );

      const assessments: Array<AssessmentView> = datx.add(response, AssessmentView);
      return assessments;
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  const filteredData = () => {
    if (swr.data) {
      return swr.data.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
    } else {
      return [];
    }
  };

  return {
    isInitialLoad: !swr.data && !swr.error,
    filteredData,
    ...swr,
  };
};

export const useAssessmentMenuActions = () => {
  const dependencyHeaders = useDependencyHeaders();

  const activateAssessment = async (assessmentId: string) => {
    const url = `assessments/${assessmentId}/activation`;
    return await apiClient(url, HttpMethod.Post, {
      headers: {
        'content-type': 'application/json',
        ...dependencyHeaders,
      },
    });
  };

  const deleteAssessment = async (assessmentId: string) => {
    const url = `assessments/${assessmentId}`;

    return await apiClient(url, HttpMethod.Delete, {
      headers: {
        'content-type': 'application/json',
        ...dependencyHeaders,
      },
    });
  };

  return {
    activateAssessment,
    deleteAssessment,
  };
};
