import { FlexProps, Flex } from '@chakra-ui/react';
import { ModalFooter as AppkitModalFooter } from 'appkit-react';
import React from 'react';
import { FC } from 'types'

export const ModalFooter: FC<FlexProps> = ({ sx, ...props }) => {
  return (
    <Flex
      as={AppkitModalFooter}
      align="center"
      justify="flex-end"
      sx={{
        bg: (props) => `${props.colors.grey.lvl4} !important`,
        ...sx,
      }}
      {...props}
    />
  );
};
