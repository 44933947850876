import { FC } from 'types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormModal } from 'components/modal/FormModal/FormModal';
import { useFormReview } from 'hooks/useFormReview';
import { ReviewForm } from 'components/review/ReviewForm/ReviewForm';
import { useFormApproval } from 'hooks/useFormApproval';
import { ReviewAction } from 'typings/enums/ReviewAction';
import { useFormAction } from 'hooks/useRoomForms';
import { ApprovalEntity } from 'typings/enums/ApprovalEntity';
import { usePopAlertFail } from 'hooks/usePopAlertFail';
import { FormStatus } from 'typings/enums/FormStatus';
import { ParseAndPopAlertFail } from 'services/ParseAndPopAlertFail';

interface IFormEditorReviewModalProps {
  roomId: string;
  formId: string;
  formStatus: FormStatus | undefined;
  onClose(): void;
}
export const FormEditorReviewModal: FC<IFormEditorReviewModalProps> = ({
  roomId,
  formId,
  formStatus,
  onClose,
}) => {
  const { t } = useTranslation();
  const { approval } = useFormApproval(roomId, formId);
  const { review, addReviewComment, error, isInitialLoad } = useFormReview(roomId, formId);

  const { doReviewAction } = useFormAction(roomId, formId);
  usePopAlertFail(error, async () => setTimeout(() => onClose(), 500));

  const handleReviewAction = useCallback(
    async (action: ReviewAction) => {
      const { error } = await doReviewAction(action);
      error && ParseAndPopAlertFail(error, t);
    },
    [doReviewAction, t],
  );

  return (
    <FormModal isOpen minHeight="500px" maxWidth="1440px" onClose={onClose}>
      {isInitialLoad && t('loading.fetching.data')}
      {!error && review && (
        <ReviewForm
          entityType={ApprovalEntity.Form}
          currentApprovals={approval?.currentApprovals}
          requiredApprovals={approval?.requiredApprovals}
          comments={review.comments}
          approvers={review.approvers}
          isUnderReview={formStatus === FormStatus.WaitingForApproval}
          onReviewAction={handleReviewAction}
          onSubmit={addReviewComment}
          onCancel={onClose}
        />
      )}
    </FormModal>
  );
};
