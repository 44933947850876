import { NavLink } from 'react-router-dom';

import { useCurrentRoom } from 'hooks/useCurrentRoom';
import { BreadcrumbItem, BreadcrumbItemText } from 'components/core/BreadcrumbItem/BreadcrumbItem';

import { useRoomFolderBreadcrumbs } from './RoomBreadcrumbs.hooks';
import { StyledBreadcrumb } from 'components/core/StyledAppKit/StyledAppKit';

export const RoomBreadcrumbs = () => {
  const { room } = useCurrentRoom();
  const items = useRoomFolderBreadcrumbs();

  return (
    <StyledBreadcrumb>
      <BreadcrumbItem>
        <NavLink to="/rooms">
          <span className="appkiticon icon-grid-view-outline a-font-24" />
        </NavLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <NavLink to={`/rooms/${room?.id}`}>
          <BreadcrumbItemText className="a-breadcrumb-item-text a-font-16" maxWidth="150px">
            {room?.name}
          </BreadcrumbItemText>
        </NavLink>
      </BreadcrumbItem>
      {items?.map((item) => (
        <BreadcrumbItem key={item.id}>
          <NavLink to={`${item.link}`}>
            <BreadcrumbItemText className="a-breadcrumb-item-text a-font-16">
              {item.value}
            </BreadcrumbItemText>
          </NavLink>
        </BreadcrumbItem>
      ))}
    </StyledBreadcrumb>
  );
};
