import React, { Factory } from 'react';
import { TimePicker } from 'appkit-react-datepicker';
import { DateTimeWidgetProps } from 'react-awesome-query-builder';

import { RuleWrapper } from '../LogicBuilder.elements';

export const AQBTime: Factory<DateTimeWidgetProps> = (props) => {
  const handleChange = (value: string) => {
    props?.setValue(value);
  };

  return (
    <RuleWrapper>
      <TimePicker
        placeholder={!props?.readonly ? props?.placeholder : ''}
        format={props?.timeFormat}
        defaultValue={props?.value}
        onChange={handleChange}
        {...props?.customProps}
      />
    </RuleWrapper>
  );
};
