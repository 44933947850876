import { Text, Image, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { MainLayout } from 'components/layouts/MainLayout/MainLayout';
import { BoxHeader } from 'components/core/BoxHeader/BoxHeader';
import { BackButton } from 'components/core/BoxHeader/components/BackButton';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <MainLayout>
      <BoxHeader align="center" justify="space-between">
        <BackButton />
      </BoxHeader>
      <Flex maxW="1055px" margin="auto" direction="column">
        <Text fontSize="40px" lineHeight="100px" mb={20}>
          {t('notFound.text')}
        </Text>
        <Image
          src="/assets/images/404-error.png"
          alt={t('notFound.imageAlt')}
          height="680px"
          objectFit="contain"
        />
      </Flex>
    </MainLayout>
  );
};
