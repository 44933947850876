import { updateModel } from '@datx/core';
import { useSWRConfig } from 'swr';

import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { Campaign } from 'models/Campaign';
import { transformCampaignDto } from 'services/transformDto/transformCampaignData';
import { InitialCampaignDTO, UpdateCampaignDTO } from 'typings/types/dto/CampaignDTO';
import { useDatx } from 'hooks/useDatx';
import { CampaignStatus } from 'typings/enums/CampaignStatus';

export const useCampaignCreate = (roomId: string, formId: string) => {
  const depHeaders = useDependencyHeaders();
  const datx = useDatx();

  const url = `rooms/${roomId}/forms/${formId}/${Campaign.type}`;

  return async (data: InitialCampaignDTO, options?: Record<string, unknown>) => {
    const response = await apiClient(url, HttpMethod.Post, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
      body: JSON.stringify(data),
    });

    return datx.add(transformCampaignDto(response as Campaign), Campaign);
  };
};

export const useCampaignCommonActions = (roomId: string, formId: string) => {
  const depHeaders = useDependencyHeaders();
  const { mutate } = useSWRConfig();

  const updateCampaign = async (
    campaign: Campaign,
    data?: Partial<UpdateCampaignDTO>,
    options?: Record<string, unknown>,
  ) => {
    const url = `rooms/${roomId}/forms/${formId}/${Campaign.type}/${campaign.id}`;

    const dto = { ...campaign.updateDto, ...data };

    const response = await apiClient(url, HttpMethod.Put, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
      body: JSON.stringify(dto),
    });

    if (campaign.campaignStatus === CampaignStatus.Initiated) {
      // refresh form users to get the users that were added by campaign initiation
      await mutate(`rooms/${roomId}/forms@${depHeaders['x-site-id']}`);
    }

    return updateModel(campaign, transformCampaignDto(response));
  };

  const deleteCampaign = async (campaignId: string, options?: Record<string, unknown>) => {
    const url = `rooms/${roomId}/forms/${formId}/${Campaign.type}/${campaignId}`;

    return await apiClient(url, HttpMethod.Delete, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
    });
  };

  const activateCampaign = async (campaignId: string, options?: Record<string, unknown>) => {
    const url = `rooms/${roomId}/forms/${formId}/${Campaign.type}/${campaignId}/activation`;

    return await apiClient(url, HttpMethod.Post, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
    });
  };

  return {
    updateCampaign,
    deleteCampaign,
    activateCampaign,
  };
};
