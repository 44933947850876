import { TextProps, Heading } from '@chakra-ui/react';
import { FC } from 'types';

export const BoxTitle: FC<TextProps> = ({ sx, ...props }) => {
  return (
    <Heading
      as="h3"
      sx={{
        color: 'white',
        fontSize: 22,
        lineHeight: 36,
        fontWeight: 'medium',
        ...sx,
      }}
      {...props}
    />
  );
};
