import { TFunction } from 'react-i18next';

import { assessmentExportFetcher } from 'fetchers/assessmentExport';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { exportDownload } from 'utils/exportDownload';

type AssessmentsExportOption = 'excel' | 'pdf' | 'json';
export type AssessmentsExportFormValues = Record<AssessmentsExportOption, boolean>;

interface IExportOption {
  label: string;
  value: string;
}

export const assessmentExportOptions: Array<IExportOption> = [
  { label: 'Excel', value: 'excel' },
  { label: 'Pdf + Word', value: 'pdf' },
  { label: 'JSON', value: 'json' },
];

export const handleAssessmentExport = async (
  t: TFunction,
  siteId: string,
  format: string,
  selectedIds: string[],
  campaignId?: string,
) => {
  try {
    const result = await exportDownload(
      assessmentExportFetcher(siteId, format, selectedIds, campaignId),
    );
    if (result === null) {
      selectedIds.length === 1
        ? PopAlertSuccess(t('export.modal.success.assessment'))
        : PopAlertSuccess(t('export.modal.success.assessments'));
    }
  } catch {
    PopAlertFail(t('export.errors.dataDownload.main'));
  }
};
