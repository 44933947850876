import { chakra } from '@chakra-ui/react';
import { BreadcrumbItem as AppkitBreadcrumbItem } from 'appkit-react';

export const BreadcrumbItem = chakra(AppkitBreadcrumbItem);

export const BreadcrumbItemText = chakra('span', {
  baseStyle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
  },
});
