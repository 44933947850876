import { FormComponent } from 'models/FormComponent';

export const isFirstDescendantOfSecond = (first: FormComponent, second: FormComponent): boolean => {
  if (first.formId !== second.formId || first.isSection || second.id === first.id) {
    return false;
  }

  if (second.isSection) {
    return second.orderTag.startsWith(`${second.childStartIndex + first.index + 1}.`);
  }

  if (second.isQuestionSet) {
    return second.isQuestionSet && first.orderTag.startsWith(second.orderTag);
  }

  return false;
};
