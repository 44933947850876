import { FC } from 'types';
import { ButtonGroupItem } from 'appkit-react';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonGroupField } from 'fields/ButtonGroupField/ButtonGroupField';
import { FolderUserRole } from 'typings/enums/FolderUserRole';
import { IFolderUserManagementFields } from 'typings/interfaces/IFolderUserManagementFields';
import { IButtonGroupProps } from 'typings/appkit-react/IButtonGroupProps';

interface IFolderUserRoleButtonGroupFieldProps extends IButtonGroupProps {
  name: string;
  control: Control<IFolderUserManagementFields>;
}
export const FolderUserRoleButtonGroupField: FC<IFolderUserRoleButtonGroupFieldProps> = ({
  control,
  name,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <ButtonGroupField
      control={control as unknown as Control<FieldValues>}
      name={name}
      size="md"
      {...rest}
    >
      <ButtonGroupItem data-value={FolderUserRole.Member} disabled>
        {t('folder.folderMembers.permissions.member').toUpperCase()}
      </ButtonGroupItem>
      <ButtonGroupItem data-value={FolderUserRole.Owner} disabled>
        {t('folder.folderMembers.permissions.owner').toUpperCase()}
      </ButtonGroupItem>
    </ButtonGroupField>
  );
};
