import { FC } from 'types';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { CalendarPickerField } from 'fields/CalendarPickerField/CalendarPickerField';

import { getValidSettings, parseOnChange, parseValue } from './AssessmentDateField.utils';

interface IAssessmentDateFieldProps {
  assessmentQuestion: AssessmentFormComponent;
  disabled?: boolean;
  index?: number;
}

export const AssessmentDateField: FC<IAssessmentDateFieldProps> = ({
  assessmentQuestion,
  disabled,
  index = 0,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const settings = getValidSettings(assessmentQuestion.settingsJson);

  return (
    <Controller
      control={control}
      name={assessmentQuestion.fieldName(index)}
      rules={{
        required: assessmentQuestion.isRequired ? t<string>('validation.required') : false,
      }}
      // eslint-disable-next-line
      render={({ field: { ref: _ref, value, onChange, ...fieldProps } }) => {
        const handleChange = (value: Date | Array<Date> | null) =>
          onChange(parseOnChange(value, Boolean(settings?.dateRangePicker)));

        return (
          <CalendarPickerField
            sx={{ w: '350px', mx: 'auto' }}
            selectRange={settings?.dateRangePicker}
            format={settings?.dateFormat}
            value={parseValue(value ?? null)}
            disabled={disabled}
            onChange={handleChange}
            {...fieldProps}
          />
        );
      }}
    />
  );
};
