import { Radio } from 'appkit-react';
import React, { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { HStack } from '@chakra-ui/layout';

import { Tenant } from 'models/Tenant';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { useProfile } from 'hooks/useProfile';
import { useTenants } from 'hooks/useTenants';
import { useTenant } from 'hooks/useTenant';
import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { Modal } from 'components/modal/Modal/Modal';
import { useLastLoginTrigger } from 'hooks/useLastLoginTrigger';
import { ParseAndPopAlertFail } from 'services/ParseAndPopAlertFail';
import { isApiClientError } from 'utils/isApiClientError';
import { AbsoluteLoader } from 'components/core/LoadingIndicator/AbsoluteLoader';
import { FavoriteIcon } from './SiteSelectModal.elements';

interface ISiteSelectModalProps {
  isVisible: boolean;
  onCancel(): void;
}

export const SiteSelectModal: FC<ISiteSelectModalProps> = observer(
  ({ isVisible, onCancel, ...rest }) => {
    const profile = useProfile();
    const { t } = useTranslation();

    const { data, isValidating } = useTenants();
    const { activeTenant, mutate } = useTenant();
    const triggerLastLogin = useLastLoginTrigger();

    const [favoriting, setFavoriting] = useState<string | null>(null);

    const handleChange = async (_: unknown, id: string) => {
      sessionStorage.setItem('x-site-id', id);
      await mutate();
      await triggerLastLogin();
    };

    const handleFavorite = (tenant: Tenant) => {
      return async (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        const prevFavorite = data?.find((tenant: Tenant) => tenant.isFavorite);

        setFavoriting(tenant.siteId);

        if (prevFavorite) {
          try {
            await apiClient('users/isfavoritesite', HttpMethod.Post, {
              headers: {
                'x-id-token': profile?.id_token,
                'x-site-id': prevFavorite.siteId,
                'content-type': 'application/json',
              },
              body: JSON.stringify({
                isFavorite: false,
              }),
            });
            mutate();
          } catch (response: any) { // eslint-disable-line
            if (isApiClientError(response)) {
              ParseAndPopAlertFail(response, t);
            }
          } finally {
            setFavoriting(null);
          }
        }

        if (tenant.siteId !== prevFavorite?.siteId || !tenant.isFavorite) {
          try {
            await apiClient('users/isfavoritesite', HttpMethod.Post, {
              headers: {
                'x-id-token': profile?.id_token,
                'x-site-id': tenant.siteId,
                'content-type': 'application/json',
              },
              body: JSON.stringify({
                isFavorite: true,
              }),
            });

            mutate();
          } catch (response: any) { // eslint-disable-line
            if (isApiClientError(response)) {
              ParseAndPopAlertFail(response, t);
            }
          } finally {
            setFavoriting(null);
          }
        }
      };
    };

    return (
      <Modal maxWidth="500px" height="auto !important" isVisible={isVisible} {...rest}>
        <ModalHeader onCancel={onCancel}>
          <ModalTitle>{t('header.siteSelectModal.title')}</ModalTitle>
        </ModalHeader>
        <ModalBody p={40}>
          {isValidating && <AbsoluteLoader />}

          {data?.map((tenant) => (
            <HStack key={tenant.siteId} spacing={8} alignItems="center" mb={8}>
              <Radio
                checked={tenant.siteId === activeTenant?.siteId}
                value={tenant.siteId}
                onChange={handleChange}
              >
                {tenant.siteName}
              </Radio>
              {favoriting === tenant.siteId && (
                <FavoriteIcon className="a-primary a-loading a-loading-14" />
              )}
              {!(favoriting === tenant.siteId) && (
                <FavoriteIcon
                  cursor="pointer"
                  className={`appkiticon ${
                    tenant.isFavorite ? 'icon-rating-fill' : 'icon-rating-outline a-text-orange'
                  } a-text-14`}
                  // @ts-ignore
                  onClick={handleFavorite(tenant)}
                />
              )}
            </HStack>
          ))}
        </ModalBody>
      </Modal>
    );
  },
);
