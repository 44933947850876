import { FC } from 'types';
import { Fragment } from 'react';
import { Box } from '@chakra-ui/react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { FormComponentType } from 'typings/enums/FormComponentType';
import { FormAddonType } from 'typings/enums/FormAddonType';
import { FormEditorDraggable } from 'typings/enums/FormEditorDraggable';
import { FormEditorSidebarItemType } from 'typings/enums/FormEditorSidebarItemType';
import { FormConclusionDropType } from 'typings/enums/FormConclusionDropType';

import { FormEditorSidebarMenuItemElement } from './FormEditorSidebarMenuItem.elements';

interface IFormEditorSidebarMenuItemProps {
  type: FormEditorSidebarItemType;
  id: FormComponentType | FormAddonType | FormConclusionDropType;
  index: number;
  disabled?: boolean;
}
export const FormEditorSidebarMenuItem: FC<IFormEditorSidebarMenuItemProps> = ({
  id,
  type,
  index,
  disabled,
  children,
}) => {
  let draggableId = id as string;
  if (type === 'addons') {
    draggableId = `${FormEditorDraggable.SidebarAddon}-${id}`;
  } else if (type === 'components' && id === FormComponentType.Section) {
    draggableId = `${FormEditorDraggable.SidebarSection}`;
  } else if (type === 'components') {
    draggableId = `${FormEditorDraggable.SidebarQuestion}-${id}`;
  }

  // TODO: remove this after it is implemented
  const isAddonDisabled = disabled || id === FormAddonType.Label;

  return (
    <Droppable droppableId={`${type}-${id}`} isDropDisabled>
      {(provided) => (
        <Box ref={provided.innerRef} {...provided.droppableProps}>
          <Draggable draggableId={draggableId} index={index} isDragDisabled={isAddonDisabled}>
            {(provided, snapshot) => (
              <Fragment>
                <FormEditorSidebarMenuItemElement
                  id={id}
                  type={type}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  opacity={snapshot.isDragging ? 0.75 : 1}
                  cursor={isAddonDisabled ? 'not-allowed' : undefined}
                >
                  {children}
                </FormEditorSidebarMenuItemElement>
                {snapshot.isDragging && (
                  <FormEditorSidebarMenuItemElement>{children}</FormEditorSidebarMenuItemElement>
                )}
              </Fragment>
            )}
          </Draggable>
          <Box display="none">{provided.placeholder}</Box>
        </Box>
      )}
    </Droppable>
  );
};
