import { Tenant } from 'models/Tenant';
import { useTenants } from 'hooks/useTenants';

export const useTenant = () => {
  const { data, ...swr } = useTenants();
  let activeTenant: Tenant | null | undefined;

  if (data?.length === 0) {
    activeTenant = null;
  } else {
    const savedItem = data?.find((tenant) => tenant.isSelected);
    const favoriteItem = data?.find((tenant) => tenant.isFavorite);

    activeTenant = savedItem || favoriteItem || data?.[0];
  }

  return {
    isUserSiteAdmin: activeTenant && activeTenant.isSiteAdmin,
    activeTenant,
    data,
    ...swr,
  };
};
