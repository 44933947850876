import { Box } from '@chakra-ui/react';
import { FC } from 'types';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { CreateFolderModal } from 'components/room/CreateFolderModal/CreateFolderModal';
import { StyledLink } from 'components/core/StyledLink/StyledLink';
import { RoomFolder } from 'models/RoomFolder';
import { useMenuActions } from 'hooks/useMenuActions';
import { useModal } from 'hooks/useModal';

import { FolderMenu } from '../FolderMenu/FolderMenu';
import { FolderMembersModal } from '../FolderMembersModal/FolderMembersModal';

interface IRoomCellNameProps {
  folder: RoomFolder;
}

export const RoomCellNameFolder: FC<IRoomCellNameProps> = ({ folder }) => {
  const location = useLocation();
  const newPath = `${location.pathname}/${folder.id}`;
  const {
    isOpen: isEditModalOpen,
    onClose: onEditModalClose,
    onOpen: onEditModalOpen,
  } = useModal();
  const {
    isOpen: isMembersModalOpen,
    onClose: onMembersModalClose,
    onOpen: onMembersModalOpen,
  } = useModal();

  const linkRef = useRef<HTMLAnchorElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);

  const {
    isOpen: isMenuOpen,
    onClose: onMenuClose,
    menuPosition,
  } = useMenuActions(linkRef, cellRef);

  return (
    <Box ref={cellRef} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
      <StyledLink title={folder.name} cursor="pointer" to={newPath} ref={linkRef}>
        {folder.name}
      </StyledLink>

      {isMenuOpen && (
        <FolderMenu
          top={menuPosition?.y}
          left={menuPosition?.x}
          folder={folder}
          onEditModalOpen={onEditModalOpen}
          onShareModalOpen={onMembersModalOpen}
          onClose={onMenuClose}
        />
      )}
      {isEditModalOpen && <CreateFolderModal folder={folder} onCancel={onEditModalClose} />}
      {isMembersModalOpen && <FolderMembersModal folder={folder} onClose={onMembersModalClose} />}
    </Box>
  );
};
