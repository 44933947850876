import { Box, useBoolean } from '@chakra-ui/react';
import { FC, SetState } from 'types';
import { Checkbox } from 'appkit-react';
import { Fragment, useState, useEffect } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { Table } from 'appkit-react';
import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { AssessmentView } from 'models/AssessmentView';
import { TableCellRow } from 'typings/types/TableCellRow';
import { dateSortMethod } from 'utils/dateSortMethod';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

import { AssessmentsExportModal } from '../AssessmentsExportModal/AssessmentsExportModal';

import { AssessmentTableViewFavoriteCell } from './components/AssessmentTableViewFavoriteCell/AssessmentTableViewFavoriteCell';
import { AssessmentTableViewNameCellWrapper } from './components/AssessmentTableViewNameCellWrapper/AssessmentTableViewNameCellWrapper';

interface IAssessmentsTableViewProps {
  assessments: Array<AssessmentView>;
  isLoading: boolean;
}

const assessmentsColumns = (
  t: TFunction,
  selectedIds: string[],
  setSelectedIds: SetState<string[]>,
  assessments: Array<AssessmentView>,
) => [
  {
    Cell: (row: TableCellRow) => {
      const id = row.original.id;
      const isChecked = selectedIds.includes(id);
      return (
        <Checkbox
          key={id}
          defaultChecked={isChecked}
          onChange={() => {
            if (isChecked) {
              setSelectedIds((prev) => prev.filter((x) => x !== id));
            } else {
              setSelectedIds((prev) => [...prev, id]);
            }
          }}
        />
      );
    },
    minWidth: 30,
    Header: () => {
      const isChecked = selectedIds.length === assessments.length;
      return (
        <Checkbox
          defaultChecked={isChecked}
          onChange={() => {
            if (isChecked) {
              setSelectedIds([]);
            } else {
              setSelectedIds(assessments.map((x) => x.id));
            }
          }}
        />
      );
    },
  },
  {
    Cell: (row: TableCellRow) => (
      <AssessmentTableViewFavoriteCell assessmentViewItem={row.original} />
    ),
    minWidth: 25,
  },
  {
    Header: t('assessments.tableView.tableColumns.name'),
    accessor: 'name',
    Cell: AssessmentTableViewNameCellWrapper,
    minWidth: 300,
  },
  {
    Header: t('assessments.tableView.tableColumns.recipient'),
    accessor: 'recipientCount',
    minWidth: 200,
  },
  {
    Header: t('assessments.tableView.tableColumns.permissionGroup'),
    accessor: 'permissionGroupCount',
    minWidth: 200,
  },
  {
    Header: t('assessments.tableView.tableColumns.lastEdit'),
    accessor: 'formattedLastEdit',
    minWidth: 200,
    sortMethod: dateSortMethod,
  },
  {
    Header: t('assessments.tableView.tableColumns.dueDate'),
    accessor: 'formattedDueDate',
    minWidth: 200,
    sortMethod: dateSortMethod,
  },
  {
    Header: t('assessments.tableView.tableColumns.status'),
    accessor: 'displayStatus',
    Cell: (row: TableCellRow) => t(`assessment.status.${row.value}`),

    minWidth: 100,
  },
];

export const AssessmentsTableView: FC<IAssessmentsTableViewProps> = ({
  assessments,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [isExportModalVisible, { on, off }] = useBoolean(false);
  const [selectedIds, setSelectedIds] = useState<Array<string>>(assessments.map((x) => x.id));

  useEffect(() => {
    if (!isLoading) {
      setSelectedIds(assessments.map((x) => x.id));
    }
  }, [isLoading]);

  return (
    <Fragment>
      <TableWrapper isLoading={isLoading}>
        <Table
          columns={assessmentsColumns(t, selectedIds, setSelectedIds, assessments)}
          data={assessments}
          showPageSizeOptions={false}
          defaultPageSize={25}
          defaultSorted={[{ id: 'name', asc: true }]}
        />
        <Box position="absolute" right="0" bottom="0">
          <StyledButton size="md" kind="secondary" onClick={on} disabled={selectedIds.length === 0}>
            {t('assessments.tableView.action.export.label')}
          </StyledButton>
          {isExportModalVisible && (
            <AssessmentsExportModal selectedIds={selectedIds} onClose={off} />
          )}
        </Box>
      </TableWrapper>
    </Fragment>
  );
};
