import { FC } from 'types';
import { Fragment } from 'react';
import { Box, Text, HStack, useBoolean, VStack, Heading } from '@chakra-ui/react';
import { Room } from 'models/Room';
import { RoomType } from 'typings/enums/RoomType';
import { RoomContactRow } from 'components/room/RoomContactRow/RoomContactRow';
import { RoomDelete } from 'components/room/RoomDelete/RoomDelete';
import { RoomDescriptionRow } from 'components/room/RoomDescriptionRow/RoomDescriptionRow';
import { RoomHistory } from 'components/room/RoomHistory/RoomHistory';
import { RoomMembersRow } from 'components/room/RoomMembersRow/RoomMembersRow';
import { RoomNameRow } from 'components/room/RoomNameRow/RoomNameRow';
import { RoomTypeRow } from 'components/room/RoomTypeRow/RoomTypeRow';

import { RoomDetailsRow } from './components/RoomDetailsRow/RoomDetailsRow';
import { useTranslation } from 'react-i18next';

interface IRoomDetailsProps {
  room: Room;
}

export const RoomDetails: FC<IRoomDetailsProps> = ({ room }) => {
  const [isEditInProgress, setEditInProgress] = useBoolean(false);
  const { t } = useTranslation();

  const allowEdit = Boolean(room && room.roomType !== RoomType.MyRoom && room.isRoomAdmin);

  return (
    <Fragment>
      <VStack spacing={16} marginTop={36}>
        <Fragment>
          <RoomNameRow
            model={room}
            disableEdit={isEditInProgress}
            readonly={!allowEdit}
            onStartEdit={setEditInProgress.on}
            onStopEdit={setEditInProgress.off}
          />
          <RoomDescriptionRow
            model={room}
            disableEdit={isEditInProgress}
            readonly={!allowEdit}
            onStartEdit={setEditInProgress.on}
            onStopEdit={setEditInProgress.off}
          />
          <RoomContactRow
            model={room}
            disableEdit={isEditInProgress}
            readonly={!allowEdit}
            onStartEdit={setEditInProgress.on}
            onStopEdit={setEditInProgress.off}
          />
          <RoomMembersRow
            model={room}
            disableEdit={isEditInProgress}
            onStartEdit={setEditInProgress.on}
            onStopEdit={setEditInProgress.off}
          />
          <RoomTypeRow
            model={room}
            disableEdit={isEditInProgress}
            readonly={!allowEdit || room.roomType !== RoomType.Internal}
            onStartEdit={setEditInProgress.on}
            onStopEdit={setEditInProgress.off}
          />
          <RoomDetailsRow>
            <Heading as="h5" size="sm" w="100px">
              {t('room.sidebar.roomDetails.rows.status')}
            </Heading>
            <Box w="200px">
              <Text>{t(`room.roomStatus.${room.roomStatus}`)}</Text>
            </Box>
          </RoomDetailsRow>
          <RoomDetailsRow>
            <Heading as="h5" size="sm" w="100px">
              {t('room.sidebar.roomDetails.rows.created')}
            </Heading>
            <Box w="200px">
              <Text>{room.formattedCreatedAt}</Text>
            </Box>
          </RoomDetailsRow>
          <RoomDetailsRow>
            <Heading as="h5" size="sm" w="100px">
              {t('room.sidebar.roomDetails.rows.lastEdit')}
            </Heading>
            <Box w="200px">
              <Text>{room.formattedLastEdit}</Text>
            </Box>
          </RoomDetailsRow>
        </Fragment>
      </VStack>
      <HStack justifyContent="flex-end" spacing={20} mt={20}>
        {allowEdit && <RoomDelete disabled={isEditInProgress} />}
        <RoomHistory />
      </HStack>
    </Fragment>
  );
};
