import { Box, chakra } from '@chakra-ui/react';

export const ConclusionContainer = chakra(Box, {
  baseStyle: {
    bgColor: 'grey.lvl4',
    minH: '110px',
    width: '100%',
    pt: 12,
    pr: 20,
    pb: 20,
    pl: 12,
  },
});
