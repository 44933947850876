import { useSWRConfig } from 'swr';

import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { AssessmentApprovalAction } from 'typings/enums/AssessmentApprovalAction';
import { ReviewAction } from 'typings/enums/ReviewAction';
import { isApiClientError } from 'utils/isApiClientError';
import { IAsyncResult } from 'typings/interfaces/IAsyncResult';

const assessmentApprovalActionMap = new Map([
  [AssessmentApprovalAction.RequestApproval, 'submit'],
  [AssessmentApprovalAction.Withdraw, 'withdraw'],
  [AssessmentApprovalAction.Reject, 'reject'],
  [AssessmentApprovalAction.Approve, 'approve'],
  [AssessmentApprovalAction.Edit, 'edit'],
]);
const reviewActionMap = new Map([
  [ReviewAction.Withdraw, 'withdraw'],
  [ReviewAction.Reject, 'reject'],
  [ReviewAction.Approve, 'approve'],
]);

export const useAssessmentApprovalAction = (assessmentId: string) => {
  const dependencyHeaders = useDependencyHeaders();
  const { mutate } = useSWRConfig();

  const doApprovalAction = async (action: AssessmentApprovalAction): Promise<IAsyncResult> => {
    const result: IAsyncResult = {
      data: null,
      error: null,
    };
    const urlAction = assessmentApprovalActionMap.get(action);
    if (!urlAction) {
      return result;
    }
    const url = `assessments/${assessmentId}/approval/${urlAction}`;
    const method = HttpMethod.Post;
    try {
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
      });
      await mutate(`assessment-${assessmentId}@${dependencyHeaders['x-site-id']}`);
    } catch (response: any) { // eslint-disable-line
      if (isApiClientError(response)) {
        result.error = response;
      }
    }
    return result;
  };

  const doReviewAction = async (action: ReviewAction): Promise<IAsyncResult> => {
    const result: IAsyncResult = {
      data: null,
      error: null,
    };
    const urlAction = reviewActionMap.get(action);
    if (!urlAction) {
      return result;
    }
    const url = `assessments/${assessmentId}/approval/${urlAction}`;
    const method = HttpMethod.Post;

    try {
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
      });
    } catch (response: any) { // eslint-disable-line
      if (isApiClientError(response)) {
        result.error = response;
      }
    } finally {
      await Promise.all([
        mutate(`assessment-${assessmentId}@${dependencyHeaders['x-site-id']}`),
        mutate(`assessment-${assessmentId}-review@${dependencyHeaders['x-site-id']}`),
        action === ReviewAction.Approve
          ? mutate(`assessment-${assessmentId}-approval@${dependencyHeaders['x-site-id']}`)
          : undefined,
      ]);
    }
    return result;
  };

  return { doApprovalAction, doReviewAction };
};
