import { FC } from 'types';
import { Fragment } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Control, FieldValues, useFormState } from 'react-hook-form';

import { FieldErrorText } from './components/FieldErrorText';

interface IFormFieldErrorProps {
  name: string;
  control: Control<FieldValues>;
}
export const FormFieldError: FC<IFormFieldErrorProps> = ({ name, control }) => {
  const { errors } = useFormState({ control });

  const errors_ = errors[name];

  if (errors_?.root) {
    return <FieldErrorText>{errors_?.root.message as string}</FieldErrorText>;
  }

  return (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ message, messages }) => (
        <Fragment>
          {message && <FieldErrorText>{message}</FieldErrorText>}
          {messages &&
            Object.entries(messages).map(([type, message]) => (
              <FieldErrorText key={type}>{message}</FieldErrorText>
            ))}
        </Fragment>
      )}
    />
  );
};
