import { Attribute } from '@datx/core';
import { computed } from 'mobx';
import { Utils as QbUtils } from 'react-awesome-query-builder';

import { CampaignStatus } from 'typings/enums/CampaignStatus';
import { AssessmentType } from 'typings/enums/AssessmentType';
import { IAssessmentPermissionGroupFormValues } from 'typings/interfaces/formValues/IAssessmentSetupFormValues';
import { InitialCampaignDTO, UpdateCampaignDTO } from 'typings/types/dto/CampaignDTO';

import { AssessmentPermissionGroup } from './AssessmentPermissionGroup';
import { CampaignPermissionGroup } from './CampaignPermissionGroup';
import { CampaignUser } from './CampaignUser';
import { Form } from './Form';
import { FormComponent } from './FormComponent';
import { Resource } from './Resource';
import { CampaignResponseActivationStatus } from 'typings/enums/CampaignResponseActivationStatus';

export class Campaign extends Resource {
  public static type = 'campaign';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public formId!: string;

  @Attribute()
  public formName!: string;

  @Attribute()
  public name!: string;

  @Attribute()
  public manageAssessmentCollaborator!: boolean;

  @Attribute()
  public oneByOne!: boolean;

  @Attribute()
  public editAfterSubmission!: boolean;

  @Attribute()
  public allowAnonymousReplies!: boolean;

  @Attribute()
  public alertForInitiator!: boolean;

  @Attribute({ defaultValue: true })
  public isOneAssessmentPerUser!: boolean;

  @Attribute()
  public dueDate!: string;

  @Attribute()
  public initiatorReminderDate!: string;

  @Attribute()
  public recipientReminderDate!: string;

  @Attribute()
  public plannedInitiationDate!: string;

  @Attribute()
  public notificationText!: string;

  @Attribute({ toMany: CampaignUser })
  public initiators!: Array<CampaignUser>;

  @Attribute({ toMany: CampaignPermissionGroup, defaultValue: [] })
  public permissionGroups!: Array<CampaignPermissionGroup>;

  @Attribute({ toMany: AssessmentPermissionGroup, defaultValue: [] })
  public assessmentPermissionGroups!: Array<AssessmentPermissionGroup>;

  @Attribute()
  public campaignStatus!: CampaignStatus;

  @Attribute()
  public activationStatus!: CampaignResponseActivationStatus;

  @Attribute({
    toOne: Form,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parse: (_, data: Record<string, any>) => (data as Campaign).formId,
  })
  public form!: Form;

  @computed
  public get isDraft(): boolean {
    return this.id === 'new';
  }

  @computed
  public get components() {
    return this.meta.collection
      ?.findAll(FormComponent)
      .filter((component) => component.formId === this.formId);
  }

  @computed
  public get assessmentsPermissionGroupFormValues(): Array<IAssessmentPermissionGroupFormValues> {
    return (
      this.assessmentPermissionGroups?.flatMap((assessment) =>
        assessment.permissionGroups?.map(
          (permissionGroup) =>
            ({
              id: assessment.id,
              keyId: `${assessment.name}@${QbUtils.uuid()}`,
              name: assessment.name,
              permissionGroupId: permissionGroup.id,
              recipientEmails: permissionGroup.recipientEmails ?? [],
            } ?? []),
        ),
      ) ?? []
    );
  }

  @computed
  public get maxAssessmentNumber(): number | null {
    if (this.isOneAssessmentPerUser) {
      return null;
    }

    return this.assessmentPermissionGroups.length || null;
  }

  @computed
  public get filteredPermissionsGroups() {
    if (this.id !== 'new') {
      return this.permissionGroups.length > 0
        ? this.permissionGroups.filter((group) => group.recipientEmails.length > 0)
        : [];
    } else {
      return [];
    }
  }

  @computed
  public get assessmentType() {
    if (this.id === 'new') {
      return AssessmentType.EachRecipient;
    }

    return this.isOneAssessmentPerUser
      ? AssessmentType.EachRecipient
      : AssessmentType.OneRecipientGroup;
  }

  @computed
  public get isNameValid() {
    return Boolean(this.name);
  }

  @computed
  public get areInitiatorsValid() {
    return Boolean(this.initiators?.length);
  }

  @computed
  public get isMissingRecipients(): boolean {
    if (this.isOneAssessmentPerUser) {
      return !this.permissionGroups.some((permissionGroup) => !permissionGroup.isEmpty);
    } else {
      return !this.assessmentPermissionGroups.some((apg) => !apg.isEmpty);
    }
  }

  @computed
  public get areRecipientsValid(): boolean {
    if (this.isMissingRecipients) {
      return true;
    }
    return this.isOneAssessmentPerUser
      ? !this.permissionGroups.some((permissionGroup) => !permissionGroup.hasValidRecipients)
      : !this.assessmentPermissionGroups.some((apg) => !apg.hasValidRecipients);
  }

  @computed
  public get isValidForInitialization(): boolean {
    return (
      this.isNameValid &&
      this.areInitiatorsValid &&
      !this.isMissingRecipients &&
      this.areRecipientsValid
    );
  }

  @computed
  public get createDto(): InitialCampaignDTO {
    return {
      name: this.name,
      manageAssessmentCollaborator: this.manageAssessmentCollaborator,
      oneByOne: this.oneByOne,
      allowAnonymousReplies: this.allowAnonymousReplies,
      alertForInitiator: this.alertForInitiator,
      editAfterSubmission: this.editAfterSubmission,
      dueDate: this.dueDate,
      initiatorReminderDate: this.initiatorReminderDate,
      recipientReminderDate: this.recipientReminderDate,
      plannedInitiationDate: this.plannedInitiationDate,
      initiators:
        this.initiators?.map((campaignUser) => ({
          id: campaignUser.userId,
          role: campaignUser.role,
        })) ?? null,
      permissionGroups:
        this.permissionGroups?.map((permissionGroup) => permissionGroup.upsertDto) ?? null,
    };
  }

  @computed
  public get updateDto(): UpdateCampaignDTO {
    return {
      ...this.createDto,
      isOneAssessmentPerUser: this.isOneAssessmentPerUser,
      notificationText: this.notificationText,
      campaignStatus: this.campaignStatus,
      assessmentPermissionGroups: this.assessmentPermissionGroups.map((apg) => apg.upsertDto),
    };
  }
}
