import React from 'react';
import { FC } from 'types'
import { chakra, Box, FlexProps } from '@chakra-ui/react';

const StyledWrapper = chakra('div', {
  baseStyle: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    w: '100%',
    height: '100%',
    bg: 'grey.lvl2',
    left: 0,
    top: 0,
    zIndex: 1,
    opacity: 0.5,
  },
});

export const AbsoluteLoader: FC<FlexProps> = (props) => (
  <StyledWrapper {...props}>
    <Box className="a-loading a-default" />
  </StyledWrapper>
);
