import { Badge } from 'appkit-react';
import { Factory } from 'react';

// I've tried to add FieldProps to the factory but for some reason typescript
// would complain about selectedField prop, docs define FieldProps as type for this component

// eslint-disable-next-line
export const AfterWidgetBadge: Factory<any> = (props) => {
  return (
    <Badge
      size="medium"
      className="a-mr-5"
      type={props?.selectedField === 'score' ? 'warning' : 'primary'}
    >
      {props?.selectedField === 'score' ? 'SCORE' : 'LOGIC'}
    </Badge>
  );
};
