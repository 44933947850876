import { Attribute } from '@datx/core';

import { Resource } from 'models/Resource';
import { IAttachmentFile } from 'typings/interfaces/dto/IAttachmentFile';

export class AssessmentComponent extends Resource {
  public static type = 'assessment-component';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public result!: Array<string>;

  @Attribute()
  public attachments!: Array<IAttachmentFile>;

  @Attribute()
  public componentId!: string;

  @Attribute()
  public isBookmarked!: boolean;

  public isResultChanged(result: Array<string | null> | null): boolean {
    return result?.toString() !== this.result?.toString();
  }
}
