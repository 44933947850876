import { Box, Grid } from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';

import { FormCampaignsTableView } from 'components/form/FormCampaigns/FormCampaignsTableView/FormCampaignsTableView';
import { useFormCampaignsTableView } from 'components/form/FormCampaigns/FormCampaignsTableView/FormCampaignsTableView.hooks';
import { useRoomForm } from 'hooks/useRoomForms';
import { FormBreadcrumbs } from 'components/form/FormBreadcrumbs/FormBreadcrumbs';
import { MainLayout } from 'components/layouts/MainLayout/MainLayout';
import { BoxHeader } from 'components/core/BoxHeader/BoxHeader';
import { BackButton } from 'components/core/BoxHeader/components/BackButton';

export const FormCampaignsPage = () => {
  const {
    params: { roomId, formId },
  } = useRouteMatch<{ roomId: string; formId: string }>();
  const { data, isInitialLoad, isValidating } = useFormCampaignsTableView(roomId, formId);
  const { form } = useRoomForm(roomId, formId);

  return (
    <MainLayout>
      <Grid
        gridTemplateAreas="'header' 'main'"
        gridTemplateRows="min-content"
        gridTemplateColumns="auto"
        height="100%"
        rowGap={50}
      >
        <BoxHeader gridArea="header" align="center">
          <BackButton />

          <Box pt={20}>
            <Box width="fit-content">
              <FormBreadcrumbs roomId={roomId} formId={formId} formName={form?.name} />
            </Box>
          </Box>
        </BoxHeader>

        <Box gridArea="main" height="100%">
          <FormCampaignsTableView
            formId={formId}
            campaigns={data}
            isLoading={isInitialLoad || isValidating}
          />
        </Box>
      </Grid>
    </MainLayout>
  );
};
