import { Fragment, ReactElement } from 'react';
import { FC } from 'types'
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { MoreAnswersAddonIconButton } from 'components/assessment/AssessmentQuestionCard/components/MoreAnswersAddonIconButton';
import { QuestionIconButton } from 'components/assessment/AssessmentQuestionCard/components/QuestionIconButton';
import { defaultValue } from 'services/assessmentFormComponent';

interface IAssessmentFieldArrayProps {
  assessmentQuestion: AssessmentFormComponent;
  disabled?: boolean;
  render(assessmentQuestion: AssessmentFormComponent, index: number): ReactElement;
}
export const AssessmentFieldArray: FC<IAssessmentFieldArrayProps> = ({
  render,
  disabled,
  assessmentQuestion,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: assessmentQuestion.id,
  });

  return (
    <Fragment>
      {fields.map((field, index) => {
        return (
          <Box key={field.id}>
            <HStack justify="space-between" mt={20} mr={index === 0 ? 45 : 12}>
              <Box flex={1}>{render(assessmentQuestion, index)}</Box>
              {index > 0 && (
                <QuestionIconButton
                  icon={'icon-close-outline'}
                  label={t('action.remove.label')}
                  isDisabled={disabled}
                  onClick={() => remove(index)}
                  alignSelf="flex-start"
                />
              )}
            </HStack>
          </Box>
        );
      })}
      <HStack justify="flex-end" mt={20} mr={12}>
        <MoreAnswersAddonIconButton
          isDisabled={disabled}
          onClick={() => append(defaultValue(assessmentQuestion.componentType))}
        />
      </HStack>
    </Fragment>
  );
};
