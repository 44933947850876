import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { ConfirmationModal } from 'components/modal/ConfirmationModal/ConfirmationModal';

interface IRoomDescriptionEditModalProps {
  readonly?: boolean;
  isSaving?: boolean;
  onCancel(): void;
  onConfirm(): void;
}

export const RoomDescriptionEditModal: FC<IRoomDescriptionEditModalProps> = ({
  readonly = false,
  isSaving = false,
  onCancel,
  onConfirm,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      title={t('room.sidebar.roomDetails.modals.description.title')}
      maxWidth="1415px"
      showLoader={isSaving}
      disabled={isSaving || readonly}
      confirmText={t('action.save.label')}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      {children}
    </ConfirmationModal>
  );
};
