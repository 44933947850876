import useSWR from 'swr';

import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { Comment } from 'models/Comment';
import { useDatx } from 'hooks/useDatx';
import { AssessmentApprover } from 'models/AssessmentApprover';
import { AssessmentReview } from 'models/AssessmentReview';
import { ReviewComment } from 'typings/types/ReviewComment';
import { isApiClientError } from 'utils/isApiClientError';
import { IApiClientError } from 'typings/interfaces/IApiClientError';
import { IAsyncResult } from 'typings/interfaces/IAsyncResult';
import { transformAssessmentApproversData } from 'services/transformDto/transformAssessmentApproversData';

export const useAssessmentReview = (assessmentId: string) => {
  const dependencyHeaders = useDependencyHeaders();
  const datx = useDatx();

  const key = `assessment-${assessmentId}-review@${dependencyHeaders['x-site-id']}`;

  const swr = useSWR<AssessmentReview, IApiClientError>(
    key,
    async () => {
      const response = await apiClient(
        `assessments/${assessmentId}/approval/review`,
        HttpMethod.Get,
        {
          headers: {
            ...dependencyHeaders,
          },
        },
      );

      const reviewResponse = response as {
        comments: Array<Comment>;
        approvers: Array<AssessmentApprover>;
      };
      const review = datx.add(
        {
          comments: reviewResponse.comments.sort(
            (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          ),
          approvers: transformAssessmentApproversData(reviewResponse.approvers, assessmentId),
          assessmentId,
        },
        AssessmentReview,
      );
      return review;
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  const addReviewComment = async (reviewComment: ReviewComment): Promise<IAsyncResult> => {
    const url = `assessments/${assessmentId}/approval/review`;
    const method = HttpMethod.Post;
    const result: IAsyncResult = {
      data: null,
      error: null,
    };

    try {
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify(reviewComment),
      });
    } catch (response: any) { // eslint-disable-line
      if (isApiClientError(response)) {
        result.error = response;
      }
    } finally {
      await swr.mutate();
    }
    return result;
  };

  return {
    isInitialLoad: !swr.data && !swr.error,
    review: swr.data,
    addReviewComment,
    ...swr,
  };
};
