export enum AuditEntity {
  Room = 'Room',
  RoomUser = 'RoomUser',
  RoomFolder = 'RoomFolder',
  Form = 'Form',
  FormUser = 'FormUser',
  FormComment = 'FormComment',
  FormApproval = 'FormApproval',
  Component = 'Component',
  Assessment = 'Assessment',
  AssessmentPermissionGroupUser = 'AssessmentPermissionGroupUser',
  AssessmentApprover = 'AssessmentApprover',
  AssessmentReviewComment = 'AssessmentReviewComment',
  AssessmentApproval = 'AssessmentApproval',
}
