import { useEffect, useState } from 'react';

export function useAnimation(isVisible: boolean, mountTimeout = 100) {
  const [shouldRender, setRender] = useState(isVisible);
  const [isAnimationReady, setIsAnimationReady] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setRender(true);
    }
    const timeout = setTimeout(() => setIsAnimationReady(isVisible), isVisible ? mountTimeout : 0);

    return (): void => clearTimeout(timeout);
  }, [isVisible, mountTimeout]);

  const transitionEnd = () => {
    if (!isVisible) {
      setRender(false);
      setIsAnimationReady(false);
    }
  };

  return {
    transitionEnd,
    isAnimationReady,
    shouldRender,
  };
}
