import { FC } from 'types';
import { Text, Box, HStack, Tr, Td, Flex } from '@chakra-ui/react';

import { Avatar } from 'components/core/Avatar/Avatar';

import { IUser } from '../../UserDeleteModal';

interface IUserManagementRowProps {
  user: IUser;
  onRemove(): void;
}
export const UserManagementRow: FC<IUserManagementRowProps> = ({ user, onRemove }) => {
  return (
    <Tr key={user.id}>
      <HStack as={Td}>
        <Avatar name={user.username} />
        <Text overflowWrap="anywhere">{user.displayName ?? user.username}</Text>
      </HStack>
      <Td>
        <Flex justifyContent="flex-end" pr={8}>
          <Box
            as="span"
            className="appkiticon icon-circle-delete-outline a-font-24 a-text-orange"
            cursor="pointer"
            onClick={onRemove}
          />
        </Flex>
      </Td>
    </Tr>
  );
};
