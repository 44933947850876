import { Flex, FlexProps, Image } from '@chakra-ui/react';
import { FC } from 'types';

interface IIllustrationBoxProps extends FlexProps {
  src: string | Array<string>;
  alt: string;
}

export const IllustrationBox: FC<IIllustrationBoxProps> = ({ src, alt, sx, ...rest }) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: typeof src === 'string' ? 'center' : 'space-between',
        width: '250px',
        height: '140px',
        boxShadow: '0 7px 18px 1px rgba(255,255,255,0.16)',
        bg: 'grey.lvl3',
        p: '10px',
        mx: 'auto',
        img: {
          height: '100%',
          objectFit: 'contain',
        },
        ...sx,
      }}
      {...rest}
    >
      {typeof src === 'string' && <Image src={src} alt={alt} />}
      {typeof src === 'object' &&
        src.map((url, index) => <Image src={url} alt={alt} key={index} />)}
    </Flex>
  );
};
