import { useBoolean } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { RoomFolder } from 'models/RoomFolder';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { UpsertRoomFolderDTO } from 'typings/types/dto/RoomFolderDTO';
import { useFolderMutate } from 'components/room/RoomItemsList/components/FolderMenu/FolderMenu.hooks';

export const useUpdateRoomFolder = (folder: RoomFolder) => {
  const [isSaving, setIsSaving] = useBoolean(false);
  const saveMutation = useFolderMutate(folder);
  const { t } = useTranslation();

  const save = async (roomFolderData: UpsertRoomFolderDTO) => {
    setIsSaving.on();
    try {
      await saveMutation.mutate(roomFolderData);
      PopAlertSuccess(t('room.successMessages.updateFolder'));
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('room.errors.updatingFolder.main'), response.errors?.name);
      } else {
        PopAlertFail(t('room.errors.updatingFolder.unexpected'), response.message);
      }
    } finally {
      setIsSaving.off();
    }
  };
  return { save, isSaving };
};
export const useUpdateRoomFolderUsers = (folder: RoomFolder) => {
  const [isSaving, setIsSaving] = useBoolean(false);
  const saveMutation = useFolderMutate(folder);
  const { t } = useTranslation();

  const save = async (roomFolderData: UpsertRoomFolderDTO) => {
    setIsSaving.on();
    try {
      await saveMutation.mutateUsers(roomFolderData);
      PopAlertSuccess(t('room.successMessages.updateFolder'));
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('room.errors.updatingFolder.main'), response.errors?.name);
      } else {
        PopAlertFail(t('room.errors.updatingFolder.unexpected'), response.message);
      }
    } finally {
      setIsSaving.off();
    }
  };
  return { save, isSaving };
};
