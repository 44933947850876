import { Attribute } from '@datx/core';
import { computed } from 'mobx';
import { ReviewStatus } from 'typings/enums/ReviewStatus';
import { IReviewer } from 'typings/interfaces/IReviewer';

import {
  AssessmentApproverDTO,
  UpdateAssessmentApproverDTO,
} from 'typings/types/dto/AssessmentApproverDTO';

import { Resource } from './Resource';
import { User } from './User';

export class AssessmentApprover extends Resource implements IReviewer {
  public static type = 'assessment_approver';

  @Attribute({
    isIdentifier: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parse: (_, data: Record<string, any>) => {
      const dto = data as AssessmentApproverDTO;
      return `${dto.id}@${dto.assessmentId}`;
    },
  })
  public id!: string;

  @Attribute({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parse: (_, data: Record<string, any>) => (data as AssessmentApproverDTO).id,
  })
  public userId!: string;

  @Attribute()
  public username!: string;

  @Attribute()
  public email!: string;

  @Attribute()
  public approved!: boolean;

  @computed
  public get user() {
    return this.meta.collection?.findAll(User).find((user) => user.id === this.userId);
  }

  @computed
  public get reviewAction(): ReviewStatus {
    if (this.approved === true) {
      return ReviewStatus.Approved;
    }
    if (this.approved === false) {
      return ReviewStatus.Rejected;
    }
    return ReviewStatus.None;
  }

  @computed
  public get updateDTO(): UpdateAssessmentApproverDTO {
    return {
      id: this.userId,
    };
  }
}
