import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FormActionButton } from 'components/core/FormActions/FormActionButton';
import { useModal } from 'hooks/useModal';

import { UserDeleteModal } from '../UserDeleteModal/UserDeleteModal';

interface IUserDeleteButtonProps {
  userEmail: string;
  disabled?: boolean;
  isLoading?: boolean;
  onDeleteWithoutReplace(): Promise<void>;
  onDeleteWithReplace(newUserIds: Array<string>): Promise<void>;
}
export const UserDeleteButton: FC<IUserDeleteButtonProps> = ({
  userEmail,
  disabled,
  isLoading,
  onDeleteWithoutReplace,
  onDeleteWithReplace,
}) => {
  const { isOpen, onClose, onOpen } = useModal();
  const { t } = useTranslation();

  return (
    <Fragment>
      <FormActionButton
        negative
        disabled={disabled || isOpen}
        isLoading={isLoading}
        onClick={onOpen}
      >
        {t('siteAdmin.user.action.delete')}
      </FormActionButton>
      {isOpen && (
        <UserDeleteModal
          isLoading={isLoading}
          userEmail={userEmail}
          onClose={onClose}
          onDeleteWithoutReplace={onDeleteWithoutReplace}
          onDeleteWithReplace={onDeleteWithReplace}
        />
      )}
    </Fragment>
  );
};
