import { Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ConfirmationModal } from 'components/modal/ConfirmationModal/ConfirmationModal';
import { useDeleteAssessment } from 'hooks/useAssessment';

interface IAssessmentDeletionModalProps {
  assessmentId: string;
  onClose(): void;
}
export const AssessmentDeletionModal: FC<IAssessmentDeletionModalProps> = ({
  assessmentId,
  onClose,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { deleteAssessment } = useDeleteAssessment(assessmentId);
  const history = useHistory();

  const handleDelete = async () => {
    setIsLoading(true);
    await deleteAssessment(() => history.push('/assessments'));
    setIsLoading(false);
  };

  return (
    <ConfirmationModal
      maxWidth="480px"
      title={t('assessment.delete.modal.title')}
      confirmText={t('assessment.delete.modal.action.confirm')}
      onCancel={onClose}
      onConfirm={handleDelete}
      showLoader={isLoading}
    >
      <Text size="lg" mt={20} mb={16}>
        {t('assessment.delete.modal.text')}
      </Text>
    </ConfirmationModal>
  );
};
