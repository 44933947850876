import { Attribute } from '@datx/core';

import { Resource } from './Resource';

export class CampaignPermissionGroupComponent extends Resource {
  public static type = 'campaign_permission_group_component';

  @Attribute({ isIdentifier: true })
  public componentId!: string;
}
