export const triggerBlobDownload = (blob: Blob, fileName: string) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);

  link.download = fileName;
  document.body.appendChild(link);
  link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  link.remove();
  window.URL.revokeObjectURL(link.href);
};
