import { useResources } from 'hooks/useResources';
import { CampaignResponse } from 'models/CampaignResponse';

export const useFormCampaignsTableView = (roomId: string, formId: string) => {
  CampaignResponse.endpoint = `rooms/${roomId}/forms/${formId}/campaign`;
  const campaigns = useResources(CampaignResponse);
  CampaignResponse.endpoint = undefined;

  return {
    ...campaigns,
    data: campaigns.data as Array<CampaignResponse>,
  };
};

export const useFormCampaign = (roomId: string, formId: string, campaignId: string) => {
  const { data, ...rest } = useFormCampaignsTableView(roomId, formId);
  const campaign = data?.find((campaign) => campaign.id === campaignId);

  return {
    campaign,
    ...rest,
  };
};
