import { FC } from 'types';

import { AssessmentView } from 'models/AssessmentView';
import { FormCampaignAssessmentsTableCellName } from '../FormCampaignAssessmentsTableCellName/FormCampaignAssessmentsTableCellName';

interface IFormCampaignAssessmentsTableCellNameWrapperProps {
  original: AssessmentView;
}

export const FormCampaignAssessmentsTableCellNameWrapper: FC<IFormCampaignAssessmentsTableCellNameWrapperProps> =
  ({ original }) => {
    return <FormCampaignAssessmentsTableCellName assessmentViewItem={original as AssessmentView} />;
  };
