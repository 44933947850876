import React, { useEffect, useState } from 'react';
import { InputNumber, Select, SelectOption } from 'appkit-react';

// IGNORE THIS FILE
// V2 feature

// TODO check types
// eslint-disable-next-line
export const AQBScore = (props: any) => {
  const { setValue, readonly, min, max, step, placeholder } = props;

  const [questions, setQuestions] = useState<Array<Record<string, unknown>>>([]);
  const [score, setScore] = useState(0);

  useEffect(() => {
    setValue({
      questions,
      score,
    });
  }, [questions, score, setValue]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ minWidth: '150px', marginRight: '10px' }}>
        <Select
          multiple
          showSelectAll
          label={!readonly ? placeholder : ''}
          onSelect={(value: Array<Record<string, unknown>>) => {
            setQuestions(value);
          }}
          placeholder="Please select a scoring questions"
          disabled={readonly}
        >
          {Object.keys(props.config.fields)
            .filter((key) => key !== 'score')
            .map((key: string) => (
              <SelectOption key={key} value={key}>
                {props.config.fields[key].label}
              </SelectOption>
            ))}
        </Select>
      </div>
      <InputNumber min={min} max={max} step={step} placeholder={placeholder} onChange={setScore} />
    </div>
  );
};
