import { Resource } from 'models/Resource';
import { useResources } from 'hooks/useResources';
import { useDebouncedSearch } from './useDebouncedSearch';

export const useFilteredResources = (resource: typeof Resource, searchField: string) => {
  const { data, ...rest } = useResources(resource);
  const [searchQuery, handleSearchInputChange] = useDebouncedSearch();

  return {
    // eslint-disable-next-line
    data: data.filter((item: any) => item[searchField].toLowerCase().includes(searchQuery)),
    handleSearchInputChange,
    ...rest,
  };
};
