import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { FormComponent } from 'models/FormComponent';
import { Addon } from 'components/form-editor/Question/components/Addon/Addon';
import { useModal } from 'hooks/useModal';

import { ConfirmationSettingsEditorModal } from '../ConfirmationSettingsEditorModal/ConfirmationSettingsEditorModal';

interface IConfirmationSettingsEditorProps {
  question: FormComponent;
  orderTag: string;
}
export const ConfirmationSettingsEditor: FC<IConfirmationSettingsEditorProps> = observer(
  ({ orderTag, question }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useModal();

    return (
      <Fragment>
        <Addon
          isInvalid={!question.hasValidSettings}
          name={t('form.settings.confirmation.name')}
          disabled={!question.text}
          onClick={onOpen}
        />
        {isOpen && (
          <ConfirmationSettingsEditorModal
            orderTag={orderTag}
            question={question}
            onClose={onClose}
          />
        )}
      </Fragment>
    );
  },
);
