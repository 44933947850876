import { useSWRConfig } from 'swr';

import { HttpMethod } from 'typings/enums/HttpMethod';
import { TrashItemType } from 'typings/enums/TrashItemType';
import { apiClient } from 'utils/apiClient';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { Form } from 'models/Form';
import { Trash } from 'models/Trash';
import { useProfile } from 'hooks/useProfile';
import { useResources } from 'hooks/useResources';

export const useForms = (searchQuery: string) => {
  const swr = useResources(Form);

  // Filter out all forms from My Room until My Room is fully implemented
  return {
    ...swr,
    data: (swr.data as Array<Form>).filter(
      (form) =>
        form.room.name !== 'My Room' && form.name.toLowerCase().includes(searchQuery.toLowerCase()),
    ),
  };
};

export const useFormFavorite = (form: Form) => {
  const depHeaders = useDependencyHeaders();
  const { mutate: globalMutate } = useSWRConfig();

  const revalidateKey = `${Form.type}@${depHeaders['x-site-id']}`;
  const url = `rooms/${form?.room.id}/${Form.type}/${form.id}/setFavorite`;

  const mutate = async (data?: Record<string, unknown>, options?: Record<string, unknown>) => {
    await apiClient(url, HttpMethod.Post, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
      body: JSON.stringify(data),
    });

    globalMutate(revalidateKey);
  };

  return {
    mutate,
  };
};

export const useCurrentFormUser = (form: Form) => {
  const profile = useProfile();

  return form.users.find((user) => user.email === profile?.profile?.preferredMail);
};

export const useFormsMenuActions = () => {
  const dependencyHeaders = useDependencyHeaders();

  const submitForReviewForm = async (roomId: string, formId: string) => {
    const url = `rooms/${roomId}/${Form.type}/${formId}/approval/submit`;

    return await apiClient(url, HttpMethod.Post, {
      headers: {
        'Content-Type': 'application/json',
        ...dependencyHeaders,
      },
    });
  };

  const approveForm = async (roomId: string, formId: string) => {
    const url = `rooms/${roomId}/${Form.type}/${formId}/approval/approve`;

    return await apiClient(url, HttpMethod.Post, {
      headers: {
        'Content-Type': 'application/json',
        ...dependencyHeaders,
      },
    });
  };

  const finalizeForm = async (roomId: string, formId: string) => {
    const url = `rooms/${roomId}/${Form.type}/${formId}/approval/finalise`;

    return await apiClient(url, HttpMethod.Post, {
      headers: {
        'Content-Type': 'application/json',
        ...dependencyHeaders,
      },
    });
  };

  const activateForm = async (roomId: string, formId: string) => {
    const url = `rooms/${roomId}/${Form.type}/${formId}/activation`;

    return await apiClient(url, HttpMethod.Post, {
      headers: {
        'Content-Type': 'application/json',
        ...dependencyHeaders,
      },
    });
  };

  const restoreForm = async (formId: string) => {
    const url = `${Trash.type}/${formId}/restore`;

    return await apiClient(url, HttpMethod.Post, {
      headers: {
        'Content-Type': 'application/json',
        ...dependencyHeaders,
      },
      body: JSON.stringify({ type: TrashItemType.Form }),
    });
  };

  const deleteForm = async (roomId: string, formId: string) => {
    const url = `rooms/${roomId}/${Form.type}/${formId}`;

    return await apiClient(url, HttpMethod.Delete, {
      headers: {
        'Content-Type': 'application/json',
        ...dependencyHeaders,
      },
    });
  };

  return {
    submitForReviewForm,
    approveForm,
    finalizeForm,
    activateForm,
    restoreForm,
    deleteForm,
  };
};
