import { FC } from 'types';
import { Box, Text, useBoolean } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Form } from 'models/Form';
import { useCurrentUserEmail } from 'hooks/useCurrentUserEmail';
import { useFormFavorite } from '../../FormsTable.hooks';
import { PopAlertFail } from 'services/PopAlertFail';

interface IFormsTableFavoriteCellProps {
  form: Form;
}

export const FormsTableFavoriteCell: FC<IFormsTableFavoriteCellProps> = ({ form }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useBoolean(false);
  const userEmail = useCurrentUserEmail();

  const { mutate } = useFormFavorite(form);

  const handleFavorite = async () => {
    setIsLoading.on();
    try {
      await mutate({
        isFavorite: !form.isFavorited(userEmail),
      });
    } catch (response: any) { // eslint-disable-line
      PopAlertFail(t('forms.favorite.error.title'), response.message);
    } finally {
      setIsLoading.off();
    }
  };

  const icon = form.isFavorited(userEmail) ? 'icon-rating-fill' : 'icon-rating-outline';

  return isLoading ? (
    <Text as="span" className="a-loading a-default" width="16px" height="16px" />
  ) : (
    <Box as="span" cursor="pointer" className={`appkiticon ${icon}`} onClick={handleFavorite} />
  );
};
