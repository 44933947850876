import { Flex, useBoolean } from '@chakra-ui/react';
import { FC } from 'types';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { Modal } from 'components/modal/Modal/Modal';
import { ControlledInputField } from 'fields/InputField/InputField';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { Form } from 'models/Form';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';

import { useFormMutate } from '../../RoomsItemsList.hooks';

interface IFormRenameModalProps {
  form?: Form;
  onCancel(): void;
}

interface IFormValues {
  name: string;
}

export const FormRenameModal: FC<IFormRenameModalProps> = ({ form, onCancel }) => {
  const [isLoading, setIsLoading] = useBoolean();

  const { mutate } = useFormMutate(form, HttpMethod.Put, `/${form?.id}`);
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm<IFormValues>({
    defaultValues: {
      name: '',
    },
  });

  const onSubmit = async ({ name }: IFormValues) => {
    setIsLoading.on();
    try {
      await mutate({
        ...form?.updateDTO,
        name,
      });
      setIsLoading.off();

      PopAlertSuccess(t('room.modals.createForm.successMessages.renamed'));
      onCancel();
    } catch (response: any) { // eslint-disable-line
      PopAlertFail(t('room.errors.createForm.unexpected'), response.message);
      setIsLoading.off();
    }
  };

  return (
    <Modal isVisible sx={{ width: '450px' }}>
      <ModalHeader onCancel={onCancel}>
        <ModalTitle>{t('room.modals.createForm.renameTitle')}</ModalTitle>
      </ModalHeader>
      <ModalBody pb={20}>
        <Flex as="form" direction="column" align="stretch" onSubmit={handleSubmit(onSubmit)}>
          <ControlledInputField
            control={control as unknown as Control<FieldValues>}
            label={t('room.modals.createForm.form.name.label').toUpperCase()}
            id="name"
            htmlFor="name"
            name="name"
            placeholder={t('room.modals.createForm.form.name.placeholder')}
            rules={{ required: { value: true, message: t('validation.required') } }}
          />
          <FormButtonSubmit isLoading={isLoading} disabled={isLoading} alignSelf="end" mt={12} />
        </Flex>
      </ModalBody>
    </Modal>
  );
};
