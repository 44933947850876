import useSWR from 'swr';

import { Campaign } from 'models/Campaign';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { transformCampaignDto } from 'services/transformDto/transformCampaignData';

import { useDatx } from './useDatx';
import { useDependencyHeaders } from './useDependencyHeaders';

export const useCampaign = (roomId: string, formId: string, campaignId: string) => {
  const dependencyHeaders = useDependencyHeaders();
  const datx = useDatx();

  const key = `campaign-${campaignId}@${dependencyHeaders['x-site-id']}`;
  const url = `rooms/${roomId}/forms/${formId}/campaign/${campaignId}`;

  const { data, ...swrRest } = useSWR(
    key,
    async () => {
      const response = await apiClient(url, HttpMethod.Get, {
        headers: {
          ...dependencyHeaders,
        },
      });

      const campaign: Campaign = datx.add(transformCampaignDto(response as Campaign), Campaign);

      return campaign;
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  return {
    campaign: data as Campaign,
    isInitialLoad: !swrRest.error && !data,
    ...swrRest,
  };
};
