import { Grid } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { WelcomeBox } from 'components/home/WelcomeBox/WelcomeBox';
import { useTenant } from 'hooks/useTenant';

export const HomePage = () => {
  const history = useHistory();
  const { activeTenant } = useTenant();

  useEffect(() => {
    if (document.cookie.includes('hideWelcome')) {
      if (activeTenant) {
        history.push('/rooms');
      } else {
        history.push('/application-admin');
      }
    }
  }, [history, activeTenant]);

  return (
    <Grid
      sx={{
        bgImage: 'url(/assets/images/welcome-background.png)',
        bgRepeat: 'no-repeat',
        bgSize: 'cover',
        width: '100vw',
        height: '100vh',
        placeItems: 'center',
      }}
    >
      <WelcomeBox />
    </Grid>
  );
};
