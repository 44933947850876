import { FC } from 'types';
import { Control, useFieldArray } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { IFolderUserManagementFields } from 'typings/interfaces/IFolderUserManagementFields';
import { IResourceUser } from 'typings/interfaces/IResourceUser';
import { UserManagementSearch } from 'components/user-management/UserManagementSearch/UserManagementSearch';
import { userSearchValidation } from 'services/validation/userSearch';
import { transformUserToFolderUser } from 'services/transformUserToFolderUser';

import { FolderUserManagementTable } from '../FolderUserManagementTable/FolderUserManagementTable';

interface IFormUserManagementProps {
  control: Control<IFolderUserManagementFields>;
  availableUsers: Array<IResourceUser>;
}
export const FolderUserManagement: FC<IFormUserManagementProps> = ({ availableUsers, control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });
  const { t } = useTranslation();

  const handleAddUser = (email: string) => {
    const selectedUser = availableUsers?.find((user) => user.email === email);
    if (!selectedUser) {
      return;
    }
    append(transformUserToFolderUser(selectedUser));
  };

  return (
    <Box>
      <UserManagementSearch
        label={t('folder.folderMembers.search.label')}
        placeholder={t('folder.folderMembers.search.placeholder')}
        validationRules={userSearchValidation(
          t,
          (value: string) =>
            Boolean(
              availableUsers?.find((user) => user.email === value || user.username === value),
            ),
          (value: string) =>
            Boolean(fields?.find((user) => user.email === value || user.username === value)),
        )}
        onClick={handleAddUser}
      />
      <FolderUserManagementTable control={control} fields={fields} remove={remove} />
    </Box>
  );
};
