import { Box, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Utils as QbUtils } from 'react-awesome-query-builder';

import { useAssessmentForm } from 'context/AssessmentContext/AssessmentContextProvider';
import { useAssessmentConclusionResult } from 'hooks/useAssessment';
import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { ToggleExpandButton } from 'components/core/ToggleExpandButton/ToggleExpandButton';

import { AssessmentConclusionCard } from './components/AssessmentConclusionCard';

export const AssessmentConclusion = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation();
  const { assessment, assessmentId, isUpdatingResult } = useAssessmentForm();
  const { conclusionResults, isValidating: isRevalidatingConclusion } =
    useAssessmentConclusionResult(assessmentId, assessment?.isTest);

  return (
    <Fragment>
      {conclusionResults && (
        <Box my={40}>
          <HStack
            spacing={20}
            justify="space-between"
            sx={{
              height: '42px',
              mb: 16,
              borderBottom: ({ colors }) => `2px solid ${colors.grey.lvl3}`,
            }}
          >
            <Text size="lg" textTransform="uppercase">
              {t('assessment.conclusions.title')}
            </Text>
            <Spacer />

            <ToggleExpandButton
              isExpanded={isExpanded}
              onClick={() => setIsExpanded((previous) => !previous)}
            />
          </HStack>
          {isExpanded && (
            <TableWrapper isLoading={isUpdatingResult || isRevalidatingConclusion}>
              <VStack spacing={20}>
                {assessment &&
                  conclusionResults.map((conclusion, index) => (
                    <AssessmentConclusionCard
                      key={`${index}@${QbUtils.uuid()}`}
                      orderNo={`C${index + 1}`}
                      text={conclusion.conclusionText}
                      attachments={conclusion.attachments}
                      assessment={assessment}
                    />
                  ))}
              </VStack>
            </TableWrapper>
          )}
        </Box>
      )}
    </Fragment>
  );
};
