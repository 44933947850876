import { useContext } from 'react';

import { DatxContext } from 'context/DatxContext';
import { AppStore } from 'store/AppStore';

export const useDatx = (): AppStore => {
  const client = useContext(DatxContext);

  if (!client) {
    throw Error('useDatx should be used inside DatxProvider');
  }

  return client as AppStore;
};
