import { Attribute } from '@datx/core';
import { computed } from 'mobx';
import { FormUserInitiatorStatus } from 'typings/enums/FormUserInitiatorStatus';

import { FormUserRole } from 'typings/enums/FormUserRole';
import { ReviewStatus } from 'typings/enums/ReviewStatus';
import { IReviewer } from 'typings/interfaces/IReviewer';
import { UpdateFormUserDTO } from 'typings/types/dto/FormUserDTO';

import { Resource } from './Resource';
import { User } from './User';

export class FormUser extends Resource implements IReviewer {
  public static type = 'form_users';

  @Attribute({
    isIdentifier: true,
  })
  public id!: string;

  @Attribute()
  public userId!: string;

  @Attribute()
  public username!: string;

  @Attribute()
  public email!: string;

  @Attribute()
  public isFavorite!: boolean;

  @Attribute()
  public isApprover!: boolean;

  @Attribute()
  public approved!: boolean;

  @Attribute()
  public role!: FormUserRole;

  @Attribute()
  public defaultInitiatorStatus!: FormUserInitiatorStatus;

  @computed
  public get displayName() {
    return this.username === this.email ? this.email : `${this.username} (${this.email})`;
  }

  @computed
  public get user() {
    return this.meta.collection?.findAll(User).find((user) => user.id === this.userId);
  }

  @computed
  public get reviewAction(): ReviewStatus {
    if (this.approved === true) {
      return ReviewStatus.Approved;
    }
    if (this.approved === false) {
      return ReviewStatus.Rejected;
    }
    return ReviewStatus.None;
  }

  @computed
  public get updateDTO(): UpdateFormUserDTO {
    return {
      id: this.userId,
      role: this.role,
      defaultInitiatorStatus: this.defaultInitiatorStatus,
    };
  }
}
