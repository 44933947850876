export const triggerLinkDownload = (url: string, fileName?: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  if (fileName) {
    link.download = fileName;
  }
  document.body.appendChild(link);
  link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  link.remove();
};
