import { useOutsideClick, useBoolean } from '@chakra-ui/react';
import { MutableRefObject, useRef } from 'react';

export const useOpenMenu = (): [
  MutableRefObject<null>,
  boolean,
  {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  },
] => {
  const [isOpen, setIsOpen] = useBoolean();
  const ref = useRef(null);
  useOutsideClick({
    ref: ref,
    handler: setIsOpen.off,
  });

  return [ref, isOpen, setIsOpen];
};
