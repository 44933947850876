import { Attribute } from '@datx/core';
import { computed } from 'mobx';

import { UpsertCampaignPermissionGroupDTO, IPermissionGroup } from 'typings/types/dto/CampaignDTO';
import { isEmail } from 'utils/isEmail';
import { Resource } from './Resource';

export class CampaignPermissionGroup extends Resource implements IPermissionGroup {
  public static type = 'campaign_permission_group';

  @Attribute()
  public readonly campaignId!: string;

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public name!: string;

  @Attribute()
  public assessmentName!: string;

  @Attribute()
  public recipientEmails!: Array<string>;

  @Attribute()
  public componentIds!: Array<string>;

  @Attribute()
  public recipientIds!: Array<string>;

  @computed
  public get isDraft() {
    return typeof this.id === 'number';
  }

  @computed
  public get isEmpty(): boolean {
    return !this.recipientEmails?.length;
  }

  @computed
  public get hasValidRecipients(): boolean {
    return this.isEmpty || !this.recipientEmails?.some((email) => !isEmail(email));
  }

  @computed
  public get upsertDto(): UpsertCampaignPermissionGroupDTO {
    return {
      id: this.isDraft ? null : this.id,
      name: this.name,
      assessmentName: this.assessmentName,
      recipientEmails: this.recipientEmails ?? null,
      componentIds: this.componentIds,
    };
  }
}
