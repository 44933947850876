import useSWRImmutable from 'swr/immutable';
import { Tenant } from 'models/Tenant';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';

import { useDatx } from './useDatx';
import { getIdToken } from 'utils/getIdToken';

export const useTenants = (idToken?: string, shouldFetch = true) => {
  const store = useDatx();

  const token = idToken || getIdToken();
  const key = 'tenants';

  const swr = useSWRImmutable(
    () => (token && shouldFetch ? key : null),
    async () => {
      const response = await apiClient('tenants', HttpMethod.Get);

      const savedTenantId = sessionStorage.getItem('x-site-id');

      const tenants: Array<Tenant> = store.add(response, 'tenants');
      const savedItem = tenants.find((tenant: Tenant) => tenant.siteId === savedTenantId);

      if (savedItem) {
        tenants.forEach((tenant) => (tenant.isSelected = false));
        savedItem.isSelected = true;
      }

      return tenants;
    },
  );

  return {
    ...swr,
    isInitialLoad: !swr.error && !swr.data,
  };
};
