import React, { Factory } from 'react';
import { ButtonGroupItem, ButtonGroup } from 'appkit-react';
import { ConjsProps, ConjunctionOption, TypedMap } from 'react-awesome-query-builder';

export const AQBConjs: Factory<ConjsProps> = (props) => {
  const renderOptions = () =>
    Object.keys(props?.conjunctionOptions as Record<string, unknown>).map((key) => {
      const { id, label, checked } = props?.conjunctionOptions?.[
        key
      ] as unknown as TypedMap<ConjunctionOption>;

      if (props?.readonly && !checked) return null;

      return (
        <ButtonGroupItem
          key={id}
          id={id}
          data-value={key}
          kind={checked ? 'primary' : 'secondary'}
          disabled={props?.readonly}
        >
          {label}
        </ButtonGroupItem>
      );
    });

  return (
    <ButtonGroup
      onChange={(event: unknown, value: string) => props?.setConjunction(value)}
      disabled={props?.readonly}
      size="lg"
      kind="gray"
      value={props?.selectedConjunction}
    >
      {renderOptions()}
    </ButtonGroup>
  );
};
