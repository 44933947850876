import { Box, VStack, Text, useBoolean } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import { TextEditor } from 'components/core/TextEditor/TextEditor';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { HelperTextSettings } from 'typings/types/FormComponentSettings';
import { Upload } from 'components/core/Upload/Upload';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { PopAlertFail } from 'services/PopAlertFail';
import { AttachmentFileStatus } from 'typings/enums/AttachmentFileStatus';
import { IAttachmentFile } from 'typings/interfaces/dto/IAttachmentFile';
import { IFormComponentAddonDto } from 'typings/interfaces/dto/IFormComponentAddonDto';

interface IHelpTextAddonSettingsFormProps {
  addon: IFormComponentAddonDto;
  roomId: string;
  formId: string;
  disabled?: boolean;
  onSubmit(value: HelperTextSettings): void;
  onCancel(): void;
}

interface IHelpTextAddonSettingsFormValues {
  formFiles: Array<IAttachmentFile>;
  message: string;
}

export const HelpTextAddonSettingsForm: FC<IHelpTextAddonSettingsFormProps> = ({
  addon,
  roomId,
  formId,
  disabled,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const depHeaders = useDependencyHeaders();
  const [isLoading, setLoading] = useBoolean();

  const parsedSettings: HelperTextSettings = addon.settingsJson
    ? JSON.parse(addon.settingsJson)
    : null;

  const { handleSubmit, control } = useForm<IHelpTextAddonSettingsFormValues>({
    defaultValues: {
      message: parsedSettings?.Text,
      formFiles: addon.attachments ?? [],
    },
  });

  const handleSave = async (values: IHelpTextAddonSettingsFormValues) => {
    if (disabled) {
      return;
    }
    try {
      setLoading.on();

      await Promise.all(
        (values.formFiles ?? []).reduce((acc: Array<Promise<unknown>>, item: IAttachmentFile) => {
          if (item.fileStatus === AttachmentFileStatus.Initiated) {
            acc.push(
              apiClient(
                `rooms/${roomId}/forms/${formId}/FormFiles/${item.fileId}/validate`,
                HttpMethod.Put,
                {
                  headers: {
                    ...depHeaders,
                  },
                },
              ),
            );
          }

          return acc;
        }, []),
      );

      onSubmit({
        Text: values?.message,
        FileIds: values?.formFiles?.map((item) => item.fileId) ?? [],
      });
    } catch (e) {
      PopAlertFail(t('form.addon.helpText.error'));
    } finally {
      setLoading.off();
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)}>
      <VStack spacing={20}>
        <VStack spacing={24} alignItems="flex-end" width="100%">
          <Box className="a-apply-theme-background-gray-level-1" width="100%">
            <Controller
              name="message"
              render={({ field: { ref, value, ...fieldProps } }) => (
                <TextEditor
                  textEditorRef={ref}
                  {...fieldProps}
                  initialHtmlContent={value}
                  className="a-apply-theme-background-gray-level-1"
                />
              )}
              control={control}
            />
          </Box>

          <VStack
            className="a-apply-theme-background-gray-level-1"
            alignItems="flex-start"
            width="100%"
            py={12}
            px={20}
          >
            <Text as="label" size="md">
              {`${t('form.addon.helpText.attachmentLabel')} ${t('upload.maxSizeWarning')}`}
            </Text>
            <Controller
              control={control}
              name="formFiles"
              // eslint-disable-next-line
              render={({ field: { ref: _ref, ...fieldProps } }) => (
                <Upload
                  getUploadUrlEndpoint={`rooms/${roomId}/forms/${formId}/FormFiles`}
                  getDownloadUrl={(fileId: string) =>
                    `rooms/${roomId}/forms/${formId}/formFiles/${fileId}`
                  }
                  disabled={disabled}
                  {...fieldProps}
                />
              )}
            />
          </VStack>
        </VStack>
      </VStack>

      <FormActions>
        <FormButtonCancel onClick={onCancel} />
        <FormButtonSubmit isLoading={isLoading} />
      </FormActions>
    </Box>
  );
};
