import { Attribute } from '@datx/core';
import { format, formatDistanceToNowStrict } from 'date-fns';
import { computed } from 'mobx';

import { Resource } from 'models/Resource';
import { Form } from 'models/Form';
import { RoomUser } from 'models/RoomUser';
import { User } from 'models/User';
import { RoomStatus } from 'typings/enums/RoomStatus';
import { RoomType } from 'typings/enums/RoomType';
import { RoomUserRole } from 'typings/enums/RoomUserRole';
import { UpsertRoomDTO } from 'typings/types/dto/RoomDTO';

export class Room extends Resource {
  public static type = 'rooms';

  @Attribute()
  public readonly createdUtc!: string;

  @Attribute()
  public readonly lastEditedUtc!: string;

  @Attribute()
  public readonly isFavorite!: boolean;

  @Attribute()
  public readonly currentUserRole!: RoomUserRole;

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public name!: string;

  @Attribute()
  public description!: string;

  @Attribute()
  public roomStatus!: RoomStatus;

  @Attribute()
  public roomType!: RoomType;

  @Attribute()
  public skipSendingNotification!: boolean;

  @Attribute()
  public notificationText!: string;

  @Attribute()
  public formCount!: number;

  @Attribute({ toMany: RoomUser })
  public roomUsers!: Array<RoomUser>;

  @Attribute({ toMany: Form })
  public roomForms!: Array<Form>;

  @Attribute({ toOne: User })
  public userContact!: User;

  @computed
  public get formattedCreatedAt() {
    if (this.createdUtc) {
      return format(new Date(this.createdUtc), 'PPp');
    }

    return '/';
  }

  @computed
  public get formattedLastEdit() {
    if (this.lastEditedUtc) {
      return format(new Date(this.lastEditedUtc), 'PPp');
    }

    return '/';
  }

  @computed
  public get formattedDistanceSinceLastEdit() {
    if (this.lastEditedUtc) {
      return formatDistanceToNowStrict(new Date(this.lastEditedUtc));
    }

    return '/';
  }

  @computed
  public get isRoomAdmin() {
    return this.currentUserRole === RoomUserRole.Admin;
  }

  @computed
  public get members() {
    return this.roomUsers?.length || 0;
  }

  @computed
  public get forms() {
    return this.roomForms?.length || 0;
  }

  @computed
  public get isMyRoom() {
    return this.roomType === RoomType.MyRoom;
  }

  @computed
  public get userContactId() {
    return this.userContact?.id;
  }

  @computed
  public get upsertDTO(): UpsertRoomDTO {
    return {
      name: this.name,
      description: this.description,
      roomType: this.roomType,
      skipSendingNotification: this.skipSendingNotification ?? true,
      notificationText: this.notificationText,
      userContactId: this.userContactId,
      roomUsers: this.roomUsers.map((roomUser) => roomUser.upsertDTO),
    };
  }
}
