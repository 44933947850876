import { Box, useBoolean } from '@chakra-ui/react';
import { useCallback, useMemo, useRef } from 'react';
import { FC } from 'types';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { ToggleExpandButton } from 'components/core/ToggleExpandButton/ToggleExpandButton';
import { FormComponent } from 'models/FormComponent';
import { StyledBadge, StyledInput } from 'components/core/StyledAppKit/StyledAppKit';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { useDatx } from 'hooks/useDatx';
import { FormEditorDraggable } from 'typings/enums/FormEditorDraggable';

import { AddonArea } from './components/AddonArea/AddonArea';
import { RemoveButton } from './components/RemoveButton/RemoveButton';
import { QuestionOrderTag } from './components/QuestionOrderTag/QuestionOrderTag';
import { QuestionSetChildren } from './components/QuestionSetChildren/QuestionSetChildren';
import { shouldDisableAddonDrop, shouldDisableQuestionDrop } from './Question.utils';
import { QuestionContainer } from './Question.elements';

interface IQuestionProps {
  model: FormComponent;
  index: number;
}

export const Question: FC<IQuestionProps> = ({ index, model }) => {
  const { t } = useTranslation();
  const datx = useDatx();
  const [isExpandedSet, setIsExpandedSet] = useBoolean(false);
  const questionTextRef = useRef(model.text || '');
  const {
    dragAction,
    dragComponentDepth,
    placeholder,
    disableEdit,
    allowLimitedEditOnFinalized,
    removePlaceholder,
    removeComponent,
    addComponent,
    updateComponent,
    // refreshComponents,
  } = useFormEditor();

  const handleClickRemove = useCallback(() => {
    if (disableEdit) {
      return;
    }
    if (model.isPlaceholder) {
      datx.removeOne(FormComponent, model.id);
      removePlaceholder();
    } else {
      removeComponent(model);
    }
  }, [datx, disableEdit, model, removeComponent, removePlaceholder]);

  // const handleClickDuplicate = useCallback(() => {
  //   if (disableEdit) {
  //     return;
  //   }
  //   refreshComponents();
  // }, []);

  const handleSaveText = useCallback(() => {
    if ((disableEdit && !allowLimitedEditOnFinalized) || questionTextRef.current === model.text) {
      return;
    }

    if (model.hasValidSettings) {
      model.text = questionTextRef.current;
      if (model.isPlaceholder) {
        removePlaceholder();
        addComponent(model);
      } else {
        updateComponent(model);
      }
    } else {
      model.update({ text: questionTextRef.current });
    }
  }, [
    addComponent,
    allowLimitedEditOnFinalized,
    disableEdit,
    model,
    removePlaceholder,
    updateComponent,
  ]);

  const isAddonDropDisabled = useMemo(
    () => disableEdit || shouldDisableAddonDrop(model, Boolean(placeholder), dragAction),
    [disableEdit, dragAction, model, placeholder],
  );
  const isSubQuestionDropDisabled = useMemo(
    () =>
      disableEdit ||
      shouldDisableQuestionDrop(
        Boolean(placeholder),
        dragAction,
        dragComponentDepth ?? 0,
        model.level,
      ),
    [disableEdit, dragAction, dragComponentDepth, model.level, placeholder],
  );

  const handleOnPlaceholderSettingsClick = useCallback(() => {
    if (!placeholder || model.text) {
      return;
    }
    handleSaveText();
  }, [handleSaveText, model.text, placeholder]);

  return (
    <Draggable
      draggableId={`${FormEditorDraggable.Question}-${model.id}`}
      index={index}
      isDragDisabled={disableEdit || (model.isQuestionSet && isExpandedSet)}
    >
      {(provided, snapshot) => (
        <QuestionContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          paddingRight={model.level > 1 ? '0' : 44}
        >
          <Box position="relative">
            <Box position="absolute" right="-35px" zIndex={100}>
              <StyledBadge type="primary" size="small">
                {t(`form.components.${model.componentType}`)}
              </StyledBadge>
              {model.isQuestionSet && (
                <ToggleExpandButton isExpanded={isExpandedSet} onClick={setIsExpandedSet.toggle} />
              )}
              <RemoveButton onClick={handleClickRemove} />
              {/* <DuplicateButton onClick={handleClickDuplicate} /> */}
            </Box>
            <Box position="relative">
              <QuestionOrderTag
                level={model.level}
                orderTag={model.isPlaceholder ? '*' : model.orderTag}
              />
              <StyledInput
                label={t('form.editor.question')}
                paddingBottom={12}
                defaultValue={model.text}
                onChange={(value: string) => (questionTextRef.current = value)}
                onBlur={handleSaveText}
                onEnterPress={handleSaveText}
                disabled={disableEdit && !allowLimitedEditOnFinalized}
              />
              <AddonArea
                model={model}
                isDropDisabled={isAddonDropDisabled}
                onPlaceholderSettingsClick={handleOnPlaceholderSettingsClick}
              />
              {model.isQuestionSet && isExpandedSet && (
                <QuestionSetChildren
                  model={model}
                  isDropDisabled={isSubQuestionDropDisabled || snapshot.isDragging}
                />
              )}
            </Box>
          </Box>
        </QuestionContainer>
      )}
    </Draggable>
  );
};
