import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FormComponent } from 'models/FormComponent';
import { useModal } from 'hooks/useModal';
import { IFormComponentAddonDto } from 'typings/interfaces/dto/IFormComponentAddonDto';

import { Addon } from '../Question/components/Addon/Addon';
import { ConditionAddonSettingsEditorModal } from '../ConditionAddonSettingsEditorModal/ConditionAddonSettingsEditorModal';

interface IConditionAddonSettingsEditorProps {
  addon: IFormComponentAddonDto;
  question: FormComponent;
}
export const ConditionAddonSettingsEditor: FC<IConditionAddonSettingsEditorProps> = ({
  addon,
  question,
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useModal();

  const hasInvalidSettings = addon.settingsJson === null;

  return (
    <Fragment>
      <Addon
        name={t('form.addon.condition.name')}
        isInvalid={hasInvalidSettings}
        onClick={onOpen}
      />
      {isOpen && (
        <ConditionAddonSettingsEditorModal addon={addon} question={question} onClose={onClose} />
      )}
    </Fragment>
  );
};
