import { HStack, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { Avatar } from 'components/core/Avatar/Avatar';
import { StyledBadge } from 'components/core/StyledAppKit/StyledAppKit';
import { ReviewStatus } from 'typings/enums/ReviewStatus';

interface IReviewApprovalStatusProps {
  username: string;
  status: ReviewStatus;
}

export const ReviewApprovalStatus: FC<IReviewApprovalStatusProps> = ({ username, status }) => {
  const { t } = useTranslation();

  return (
    <HStack
      spacing={16}
      w="100%"
      height="60px"
      sx={{
        borderBottom: ({ colors }) => `2px solid ${colors.grey.lvl3}`,
      }}
    >
      <Avatar name={username} />
      <Text flexGrow={1}>{username}</Text>
      {status === ReviewStatus.Approved && (
        <StyledBadge type="success">{t('review.approver.approved')}</StyledBadge>
      )}
      {status === ReviewStatus.Rejected && (
        <StyledBadge type="error">{t('review.approver.rejected')}</StyledBadge>
      )}
    </HStack>
  );
};
