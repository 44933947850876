import { Box, Center, Grid } from '@chakra-ui/react';
import { FloatingActionButton, Input, Tab } from 'appkit-react';
import { useHistory } from 'react-router';
import { useClickAway, useToggle } from 'react-use';
import { useRef } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MainLayout } from 'components/layouts/MainLayout/MainLayout';
import { BackButton } from 'components/core/BoxHeader/components/BackButton';
import { RoomItemsList } from 'components/room/RoomItemsList/RoomItemsList';
import { RoomBreadcrumbs } from 'components/rooms/RoomBreadcrumbs/RoomBreadcrumbs';
import { NoticeBoard } from 'components/room/NoticeBoard/NoticeBoard';
import { useCurrentRoom } from 'hooks/useCurrentRoom';
import { CreateDropdown } from 'components/room/CreateDropdown/CreateDropdown';
import { CreateFolderModal } from 'components/room/CreateFolderModal/CreateFolderModal';
import { BoxHeader } from 'components/core/BoxHeader/BoxHeader';
import { useDebouncedSearch } from 'hooks/useDebouncedSearch';
import { RoomDetails } from 'components/room/RoomDetails/RoomDetails';
import { RoomFolderDetails } from 'components/room-folder/RoomFolderDetails/RoomFolderDetails';
import { useRoomFolderMatch } from 'hooks/useRoomFolderMatch';
import { useModal } from 'hooks/useModal';
import { StyledTabs } from 'components/core/StyledAppKit/StyledAppKit';
import { CreateFormModal } from 'components/room/CreateFormModal/CreateFormModal';

export const RoomPage = () => {
  const { url } = useRouteMatch();
  const { room, isInitialLoad } = useCurrentRoom();
  const folderMatch = useRoomFolderMatch();
  const { t } = useTranslation();

  const dropdownRef = useRef(null);
  const [isDropdownVisible, setIsDropdownVisible] = useToggle(false);
  useClickAway(dropdownRef, () => {
    if (isDropdownVisible) {
      setIsDropdownVisible();
    }
  });

  const [searchQuery, setSearchQuery] = useDebouncedSearch();

  const {
    isOpen: isFolderModalOpen,
    onOpen: onFolderModalOpen,
    onClose: onFolderModalClose,
  } = useModal();

  const {
    isOpen: isFormModalOpen,
    onOpen: onFormModalOpen,
    onClose: onFormModalClose,
  } = useModal();

  const history = useHistory();
  if (!room && !isInitialLoad) {
    history.push('/rooms');
  }

  const isFolderPage = folderMatch && folderMatch.params.folderId;

  return (
    <MainLayout>
      <Grid
        gridTemplateAreas="'header header sidebar' 'main main sidebar' 'main main sidebar'"
        gridTemplateRows="minmax(max-content, 100px) auto auto"
        gridTemplateColumns="auto auto 367px"
        gridColumnGap="53px"
        height="100%"
      >
        <BoxHeader gridArea="header" align="center" justify="space-between" height="unset">
          <BackButton />
          <Box flex="1" pt="20px">
            <Box width="fit-content">
              <RoomBreadcrumbs />
            </Box>
          </Box>
          <Input
            prefix={
              <span
                className="appkiticon icon-filter-outline baseline-search-icon change-color-with-focus"
                style={{ fontSize: 16 }}
              />
            }
            placeholder={t('inputs.filter.placeholder')}
            onChange={setSearchQuery}
          />
          <Center ref={dropdownRef} position="relative">
            <FloatingActionButton size="sm" ml="19px" onClick={setIsDropdownVisible}>
              <Box as="span" className="appkiticon a-btn-icon icon-plus-fill" />
            </FloatingActionButton>
            <CreateDropdown
              isVisible={isDropdownVisible}
              onClose={setIsDropdownVisible}
              onFolderClick={onFolderModalOpen}
              onFormClick={onFormModalOpen}
            />
          </Center>
        </BoxHeader>
        <Box gridArea="main">
          <Switch>
            <Route path={`${url}/:folderId?`}>
              <RoomItemsList searchQuery={searchQuery} />
            </Route>
          </Switch>
        </Box>
        <Box gridArea="sidebar">
          <StyledTabs defaultValue="details" size="lg" secondary position="relative" mt={20} pt={8}>
            <Tab label={t('room.sidebar.tabs.details').toUpperCase()} value="details">
              {room && !isFolderPage && <RoomDetails room={room} />}
              {room && folderMatch && isFolderPage && (
                <RoomFolderDetails room={room} folderId={folderMatch.params.folderId} />
              )}
            </Tab>
            <Tab label={t('room.sidebar.tabs.noticeBoard').toUpperCase()} value="notifications">
              <NoticeBoard />
            </Tab>
          </StyledTabs>
        </Box>
      </Grid>

      {isFolderModalOpen && <CreateFolderModal onCancel={onFolderModalClose} />}
      {room && isFormModalOpen && (
        <CreateFormModal
          roomId={room.id}
          folderId={folderMatch?.params.folderId}
          onCancel={onFormModalClose}
        />
      )}
    </MainLayout>
  );
};
