import { IconButton, IconButtonProps } from '@chakra-ui/react';

import React from 'react';
import { FC } from 'types'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const BackButton: FC<Omit<IconButtonProps, 'aria-label'>> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <IconButton
      icon={
        <span className="appkiticon icon-left-chevron-circle-outline a-font-32 a-text-orange" />
      }
      onClick={() => history.goBack()}
      aria-label={t('action.back.label')}
      sx={{
        bg: 'transparent',
        cursor: 'pointer',
        'svg path': {
          fill: 'brand.orange',
        },
        _focus: {
          bg: 'transparent',
        },
        _active: {
          bg: 'transparent',
        },
        _hover: {
          bg: 'transparent',
        },
        ml: '-4px',
        ...props.sx,
      }}
      {...props}
    />
  );
};
