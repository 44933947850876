import { ModalHeader as AppkitModalHeader } from 'appkit-react';
import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'types';

interface IModalHeaderProps extends BoxProps {
  onCancel(): void;
}

export const ModalHeader: FC<IModalHeaderProps> = ({ onCancel, sx, ...props }) => (
  <Box
    as={AppkitModalHeader}
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      p: '0 20px 0 40px',
      height: 'auto',
      bg: (theme) => `${theme.colors.grey.lvl4} !important`,
      '.a-close-btn': {
        color: (props) => `${props.colors.brand.orange} !important`,
        fontSize: 16,
      },
      ...sx,
    }}
    onClick={onCancel}
    {...props}
  />
);
