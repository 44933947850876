export const Heading = {
  baseStyle: {
    fontWeight: 'medium',
    fontFamily: 'heading',
    color: 'white',
  },
  sizes: {
    // h6
    xs: {
      fontSize: '14',
      lineHeight: '20',
    },
    // h5
    sm: {
      fontSize: '16',
      lineHeight: '28',
    },
    // h4
    md: {
      fontSize: '18',
      lineHeight: '30',
    },
    // h3
    lg: {
      fontSize: '22',
      lineHeight: '36',
    },
    // h2
    xl: {
      fontSize: '28',
      lineHeight: '44',
    },
    // h1
    '2xl': {
      fontSize: '45',
      lineHeight: '70',
    },
  },
  defaultProps: {
    size: 'xl',
  },
};
