import React, { Factory } from 'react';
import { Select, SelectOption } from 'appkit-react';
import { SelectWidgetProps } from 'react-awesome-query-builder';
import { useTranslation } from 'react-i18next';

import { RuleWrapper } from '../LogicBuilder.elements';
import { useResources } from 'hooks/useResources';
import { Country } from 'models/Country';
import { Language } from 'models/Language';
import { CountryFormatType } from 'typings/types/FormComponentSettings';

export const AQBSelectCountry: Factory<SelectWidgetProps> = (props) => {
  const { t } = useTranslation();

  const { data: countries, isInitialLoad: isLoadingCountries } = useResources(Country, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { data: languages, isInitialLoad: isLoadingLanguages } = useResources(Language, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const onChange = (value: string) => {
    props?.setValue(value);
  };

  const isLoading = isLoadingCountries || isLoadingLanguages;

  return (
    <RuleWrapper>
      <Select
        showSearchOnToggle
        label={!props?.readonly ? props?.placeholder : ''}
        onSelect={onChange}
        placeholder={isLoading ? t('loading.fetching.data') : 'Please select an answer'}
        defaultValue={props?.value || ''}
        value={props?.value || ''}
        disabled={props?.readonly || isLoading}
      >
        {(props?.customProps as Record<string, string>)?.formatType === CountryFormatType.Country &&
          (countries as Array<Country>).map((country) => (
            <SelectOption key={country.alpha2Code} value={country.alpha2Code}>
              {country.name}
            </SelectOption>
          ))}
        {(props?.customProps as Record<string, string>)?.formatType ===
          CountryFormatType.Language &&
          (languages as Array<Language>).map((language) => (
            <SelectOption key={language.iso639_1} value={language.iso639_1}>
              {language.name}
            </SelectOption>
          ))}
      </Select>
    </RuleWrapper>
  );
};
