import { Box, BoxProps, chakra, Text } from '@chakra-ui/react';
import React from 'react';
import { FC } from 'types'
import { NavLink, NavLinkProps } from 'react-router-dom';

const Link = chakra(NavLink);

interface INavigationLinkProps extends NavLinkProps, Pick<BoxProps, 'sx'> {
  title: string;
  icon: string;
}

export const NavigationLink: FC<INavigationLinkProps> = ({ icon, title, sx, ...rest }) => {
  return (
    <Link
      sx={{
        textAlign: 'center',
        pt: '13px',
        px: '4px',
        width: '100%',
        height: '70px',
        '&.active': { bg: 'brand.orange' },
        '&:not(.active):hover': {
          bg: 'grey.lvl6',
        },
        ...sx,
      }}
      {...rest}
    >
      <Box as="span" className={`appkiticon ${icon} a-font-24`} />
      <Text size="xs" fontWeight="medium" mt="5px">
        {title}
      </Text>
    </Link>
  );
};
