import { ChoiceQuestionValue } from 'typings/types/FormComponentSettings';

export function getValidValue(value: string | null): Array<ChoiceQuestionValue> {
  if (!value) {
    throw new Error('Invalid single choice options');
  }
  const valueJson = JSON.parse(value);

  if (!Array.isArray(valueJson)) {
    throw new Error('Invalid single choice options');
  }

  if (valueJson.some((item) => !('index' in item) || !('value' in item))) {
    throw new Error('Invalid single choice options');
  }

  return valueJson;
}
