import { Box, Text, Flex } from '@chakra-ui/react';
import { FC } from 'types';
import { Control, Controller, FieldValues, useFieldArray } from 'react-hook-form';
import { observer } from 'mobx-react';
import { Select, SelectOption } from 'appkit-react';
import { useTranslation } from 'react-i18next';
import { updateModel } from '@datx/core';

import { RemoveButton } from 'components/form-editor/Question/components/RemoveButton/RemoveButton';
import { Campaign } from 'models/Campaign';
import { TagInput } from 'components/core/TagInput/TagInput';
import { IAssessmentSetupFormValues } from 'typings/interfaces/formValues/IAssessmentSetupFormValues';
import { FormFieldError } from 'components/core/FormFieldError/FormFieldError';
import { ControlledInputField } from 'fields/InputField/InputField';

interface IEachRecipientFormProps {
  campaign: Campaign;
  control: Control<IAssessmentSetupFormValues>;
}

export const EachRecipientForm: FC<IEachRecipientFormProps> = observer(({ campaign, control }) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'permissionGroups',
  });

  const onNewPermissionGroupSelect = (id: string) => {
    const group = campaign.permissionGroups.find((group) => group.id === id);

    if (group) {
      append({
        id,
        assessmentName: '',
        name: group.name,
        recipientEmails: [],
      });
    }
  };

  const onPermissionGroupRemove = (id: number, groupId: string) => {
    const group = campaign.permissionGroups.find((group) => group.id === groupId);

    // update fields when group is removed
    if (group) {
      updateModel(group, {
        recipientEmails: [],
        recipientIds: [],
      });
    }

    remove(id);
  };

  return (
    <Box>
      <Flex
        alignItems="center"
        sx={{
          backgroundColor: 'grey.lvl3',
          height: '34px',
          px: 12,
        }}
      >
        <Text size="md" width="300px">
          {t('campaign.setup.assessments')}
        </Text>
        <Text size="md" width="300px">
          {t('campaign.setup.permissionGroup')}
        </Text>
        <Text size="md">{t('campaign.setup.recipients')}</Text>
      </Flex>
      <Box
        sx={{
          '> div': {
            backgroundColor: 'grey.lvl6',
            ':nth-of-type(even)': {
              backgroundColor: 'grey.lvl5',
            },
          },
        }}
      >
        {fields.map((field, index) => (
          <Flex
            key={field.id}
            alignItems="center"
            sx={{
              minHeight: '34px',
              px: 12,
            }}
          >
            <ControlledInputField
              sx={{ w: '300px' }}
              control={control as unknown as Control<FieldValues>}
              htmlFor="name"
              name={`permissionGroups.${index}.assessmentName` as const}
              defaultValue={field.assessmentName}
              rules={{ required: { value: true, message: t('validation.required') } }}
            />

            <Text width="300px">{field?.name}</Text>

            <Flex alignItems="center" flex="2">
              <Controller
                control={control}
                name={`permissionGroups.${index}.recipientEmails` as const}
                render={({ field: { value, onChange } }) => (
                  <TagInput
                    sx={{ flex: '2' }}
                    tags={value || []}
                    placeholder={t('campaign.setup.emails')}
                    onChange={onChange}
                  />
                )}
              />
              <RemoveButton onClick={() => onPermissionGroupRemove(index, field.id)} />
            </Flex>
          </Flex>
        ))}
      </Box>
      <Select
        value=""
        onSelect={onNewPermissionGroupSelect}
        placeholder={t('campaign.setup.selectAssessment')}
      >
        {campaign.permissionGroups.map((permissionGroup, index) => (
          <SelectOption
            key={`${permissionGroup.id}-${index}`}
            value={permissionGroup.id}
            disabled={Boolean(fields.find((group) => group.id === permissionGroup.id))}
          >
            {permissionGroup.name}
          </SelectOption>
        ))}
      </Select>
      <FormFieldError
        name="permissionGroups"
        control={control as unknown as Control<FieldValues>}
      />
    </Box>
  );
});
