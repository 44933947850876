import { updateModel } from '@datx/core';
import { groupBy } from 'lodash';

import { Campaign } from 'models/Campaign';
import { IAssessmentSetupFormValues } from 'typings/interfaces/formValues/IAssessmentSetupFormValues';
import { AssessmentPermissionGroup } from 'models/AssessmentPermissionGroup';
import { AppStore } from 'store/AppStore';

export const updateCampaignPermissionGroups = (
  campaign: Campaign,
  { permissionGroups: formPermissionGroups }: IAssessmentSetupFormValues,
) => {
  let permissionGroups = formPermissionGroups.map((item) => {
    const groupModel = campaign.permissionGroups.find((group) => group.id === item.id);

    if (groupModel) {
      const updatedModel = updateModel(groupModel, {
        recipientEmails: item.recipientEmails,
        assessmentName: item.assessmentName,
      });

      return updatedModel;
    }

    return null;
  });

  permissionGroups = campaign.permissionGroups.map(
    (group) => permissionGroups.find((item) => item?.id === group.id) || group,
  );

  updateModel(campaign, {
    isOneAssessmentPerUser: true,
    permissionGroups,
  });
};

export const updateAssessmentPermissionGroups = (
  campaign: Campaign,
  datx: AppStore,
  { assessmentPermissionGroups: formAssessmentPermissionGroups }: IAssessmentSetupFormValues,
) => {
  // remove removed Assessment permission groups from campaign model
  campaign.assessmentPermissionGroups.forEach((capg) => {
    const isOnForm = formAssessmentPermissionGroups.some(
      (item) => item.id === capg.id || item.name === capg.name,
    );

    if (isOnForm) {
      return;
    }

    updateModel(campaign, {
      assessmentPermissionGroups: [
        ...campaign.assessmentPermissionGroups.filter((item) => item.id !== capg.id),
      ],
    });
  });

  // add new Assessment permission groups to campaign model
  formAssessmentPermissionGroups.forEach((apg) => {
    const campaignPermissionGroup = campaign.permissionGroups.find(
      (item) => item.id === apg.permissionGroupId,
    );

    if (!campaignPermissionGroup) {
      throw new Error(`Unexpected! Missing permission group ${apg.permissionGroupId}`);
    }

    const isInCampaign = campaign.assessmentPermissionGroups.some(
      (item) => item.id === apg.id || item.name === apg.name,
    );

    if (isInCampaign) {
      return;
    }

    // Add new APG to campaign
    const apgModel = datx.add(
      {
        name: apg.name,
        permissionGroups: [
          {
            id: apg.permissionGroupId,
            name: campaignPermissionGroup.name,
            componentIds: campaignPermissionGroup.componentIds,
            recipientEmails: apg.recipientEmails,
          },
        ],
      },
      AssessmentPermissionGroup,
    );

    updateModel(campaign, {
      assessmentPermissionGroups: [...campaign.assessmentPermissionGroups, apgModel],
    });
  });

  // Add permission groups to campaign Assessment permission groups
  const groupsByName = groupBy(formAssessmentPermissionGroups, 'name');
  Object.keys(groupsByName).forEach((assessmentName) => {
    const permissionGroupsForApg = groupsByName[assessmentName].map((formPermissionGroup) => {
      const campaignPermissionGroup = campaign.permissionGroups.find(
        (item) => item.id === formPermissionGroup.permissionGroupId,
      );
      if (!campaignPermissionGroup) {
        throw new Error(
          `Unexpected! Missing permission group ${formPermissionGroup.permissionGroupId}`,
        );
      }
      return {
        id: formPermissionGroup.permissionGroupId,
        campaignId: campaign.isDraft ? undefined : campaign.id,
        recipientEmails: formPermissionGroup.recipientEmails,
        name: campaignPermissionGroup.name,
        componentIds: campaignPermissionGroup.componentIds,
      };
    });

    // Find the campaign assessment permission group and replace its permission groups
    const campaignApgModel = campaign.assessmentPermissionGroups.find(
      (item) => item.name === assessmentName || item.id === groupsByName[assessmentName][0].id,
    );
    if (!campaignApgModel) {
      throw new Error(`Unexpected! Missing assessment permission group ${assessmentName}`);
    }
    updateModel(campaignApgModel, {
      name: assessmentName,
      permissionGroups: permissionGroupsForApg,
    });
  });
};
