import { FC } from 'types';

import { FormComponent } from 'models/FormComponent';

import { getComponentSettingsModal } from './QuestionSettingsModal.utils';

interface IQuestionSettingsModalProps {
  question: FormComponent;
  orderTag: string;
  onClose(): void;
}
export const QuestionSettingsModal: FC<IQuestionSettingsModalProps> = (props) => {
  const SettingsModal = getComponentSettingsModal(props.question.componentType);
  return SettingsModal ? <SettingsModal {...props} /> : null;
};
