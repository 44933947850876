import { FC } from 'types';

import { CellText } from 'components/core/CellText/CellText';
import { Domain } from 'models/Domain';
import { useTenant } from 'hooks/useTenant';
import { DomainType } from 'typings/enums/DomainType';

interface IProps {
  domain: Domain;
  onClick(): void;
}

export const DomainNameCell: FC<IProps> = ({ domain, onClick }) => {
  const { activeTenant } = useTenant();

  const canEdit =
    (domain.type === DomainType.Dominant && activeTenant?.manageDominantDomains) ||
    (domain.type === DomainType.Secondary && activeTenant?.manageNonDominantDomains);

  const handleClick = () => {
    if (!canEdit) {
      return;
    }
    onClick();
  };

  return (
    <CellText onClick={handleClick} cursor={canEdit ? 'pointer' : 'initial'}>
      {domain.name}
    </CellText>
  );
};
