import { Box, Grid, Flex } from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';
import { Input } from 'appkit-react';
import { useTranslation } from 'react-i18next';

import { FormBreadcrumbs } from 'components/form/FormBreadcrumbs/FormBreadcrumbs';
import { BoxHeader } from 'components/core/BoxHeader/BoxHeader';
import { BackButton } from 'components/core/BoxHeader/components/BackButton';
import { MainLayout } from 'components/layouts/MainLayout/MainLayout';
import { useFormCampaign } from 'components/form/FormCampaigns/FormCampaignsTableView/FormCampaignsTableView.hooks';
import { FormCampaignAssessmentsTable } from 'components/form/FormCampaignAssessments/FormCampaignAssessmentsTable/FormCampaignAssessmentsTable';
import { useRoomForm } from 'hooks/useRoomForms';
import { useDebouncedSearch } from 'hooks/useDebouncedSearch';
import { useFormCampaignAssessmentsTable } from 'components/form/FormCampaignAssessments/FormCampaignAssessmentsTable/FormCampaignAssessmentsTable.hooks';

export const FormCampaignAssessmentsPage = () => {
  const [assessmentsSearchQuery, setAssessmentsSearchQuery] = useDebouncedSearch();

  const {
    params: { roomId, formId, campaignId },
  } = useRouteMatch<{ roomId: string; formId: string; campaignId: string }>();
  const { form } = useRoomForm(roomId, formId);
  const { filteredData, isInitialLoad, isValidating } = useFormCampaignAssessmentsTable(
    roomId,
    formId,
    campaignId,
    assessmentsSearchQuery,
  );
  const { campaign } = useFormCampaign(roomId, formId, campaignId);
  const { t } = useTranslation();

  return (
    <MainLayout>
      <Grid
        gridTemplateAreas="'header' 'main'"
        gridTemplateRows="min-content"
        gridTemplateColumns="auto"
        height="100%"
      >
        <BoxHeader gridArea="header" align="center">
          <BackButton />

          <Box pt={20}>
            <Box width="fit-content">
              <FormBreadcrumbs
                roomId={roomId}
                formId={formId}
                campaignId={campaignId}
                formName={form?.name}
                campaignName={campaign?.name}
              />
            </Box>
          </Box>
        </BoxHeader>

        <Box gridArea="main" height="100%">
          <Flex justifyContent="flex-end" pb={16}>
            <Input
              prefix={
                <span
                  className="appkiticon icon-filter-outline baseline-search-icon change-color-with-focus"
                  style={{ fontSize: 16 }}
                />
              }
              placeholder={t('inputs.filter.placeholder')}
              onChange={setAssessmentsSearchQuery}
            />
          </Flex>
          <FormCampaignAssessmentsTable
            campaignId={campaignId}
            assessments={filteredData()}
            isLoading={isInitialLoad || isValidating}
          />
        </Box>
      </Grid>
    </MainLayout>
  );
};
