import { BasicConfig, Types } from 'react-awesome-query-builder';

export const types: Types = {
  ...BasicConfig.types,
  boolean: {
    ...BasicConfig.types.boolean,
    defaultOperator: 'Is',
    widgets: {
      boolean: {
        operators: ['Is', 'IsNot'],
      },
    },
  },
  text: {
    ...BasicConfig.types.text,
    widgets: {
      text: {
        operators: ['Is', 'IsNot'],
      },
    },
  },
  number: {
    ...BasicConfig.types.number,
    defaultOperator: 'Is',
    widgets: {
      number: {
        operators: [
          'Is',
          'IsNot',
          'LessThan',
          'LessThanOrEquals',
          'GreaterThan',
          'GreaterThanOrEquals',
        ],
      },
    },
  },
  select: {
    ...BasicConfig.types.select,
    defaultOperator: 'Is',
    widgets: {
      ...BasicConfig.types.select.widgets,
      select: {
        operators: ['Is', 'IsNot'],
      },
      multiselect: {
        operators: ['Is', 'IsNot'],
      },
    },
  },
  date: {
    ...BasicConfig.types.date,
    defaultOperator: 'Is',
    widgets: {
      date: {
        operators: ['Is', 'IsNot'],
      },
    },
  },
  select_country: {
    valueSources: ['value'],
    defaultOperator: 'Is',
    widgets: {
      select_country: {
        operators: ['Is', 'IsNot'],
      },
    },
  },
  score: {
    defaultOperator: 'Is',
    valueSources: ['value'],
    widgets: {
      score: {
        operators: [
          'Is',
          'IsNot',
          'LessThan',
          'LessThanOrEquals',
          'GreaterThan',
          'GreaterThanOrEquals',
        ],
      },
    },
  },
};
