import { Box, Flex } from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';

export const StyledCommentBoxWrapper = chakra(Box, {
  baseStyle: {
    py: '5px',
    '&:not(:last-child)': {
      mb: '27px',
    },
  },
});

export const StyledCommentBoxHeader = chakra(Box, {
  baseStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: '15px',
  },
});

export const StyledCommentBoxFooter = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    mt: '18px',
  },
});
