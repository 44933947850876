import { chakra } from '@chakra-ui/react';

export const LoadingOverlay = chakra('div', {
  baseStyle: {
    position: 'fixed',
    top: '50px',
    left: '70px',
    width: 'calc(100vw - 70px)',
    height: 'calc(100vh - 50px)',
    bg: 'rgba(0,0,0,0.2)',
    zIndex: 'overlay',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.a-loading': {
      width: '2rem',
      height: '2rem',
    },
  },
});
