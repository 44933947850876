import { FC } from 'types';
import { Box } from '@chakra-ui/react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ICampaignInitiatorsFormData } from 'typings/interfaces/ICampaignFormData';
import { useResources } from 'hooks/useResources';
import { User } from 'models/User';
import { transformUserToCampaignUser } from 'services/transformUserToCampaignUser';
import { UserManagementSearch } from 'components/user-management/UserManagementSearch/UserManagementSearch';
import { userSearchValidation } from 'services/validation/userSearch';

import { CampaignInitiatorManagementTable } from '../CampaignInitiatorManagementTable/CampaignInitiatorManagementTable';

interface ICampaignInitiatorManagementProps {
  campaignId: string;
  control: Control<ICampaignInitiatorsFormData>;
}
export const CampaignInitiatorManagement: FC<ICampaignInitiatorManagementProps> = ({
  campaignId,
  control,
}) => {
  const { data } = useResources(User);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'initiators',
  });
  const { t } = useTranslation();

  const handleAddUser = (email: string) => {
    const selectedUser = (data as Array<User>).find(
      (user) => user.email.toLocaleLowerCase() === email.toLocaleLowerCase(),
    );
    if (!selectedUser) {
      return;
    }
    append(transformUserToCampaignUser(selectedUser, campaignId));
  };

  return (
    <Box width="100%" height="100%">
      <UserManagementSearch
        label={t('form.formMembers.modalTitle')}
        placeholder={
          (data as Array<User> | undefined)
            ? t('form.formMembers.searchPlaceholder')
            : t('loading.fetching.data')
        }
        validationRules={userSearchValidation(
          t,
          (value: string) =>
            Boolean(
              (data as Array<User>).find(
                (user) =>
                  user.email.toLocaleLowerCase() === value.toLocaleLowerCase() ||
                  user.username === value,
              ),
            ),
          (value: string) =>
            Boolean(
              fields?.find(
                (user) =>
                  user.email?.toLocaleLowerCase() === value.toLocaleLowerCase() ||
                  user.username === value,
              ),
            ),
        )}
        onClick={handleAddUser}
      />
      <CampaignInitiatorManagementTable control={control} fields={fields} remove={remove} />
    </Box>
  );
};
