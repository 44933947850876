import {
  NumbersComponentType,
  NumbersSettings,
  ScaleSettings,
  ScaleType,
} from 'typings/types/FormComponentSettings';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { AssessmentFormComponent } from 'models/AssessmentFormComponent';

export function fillWithDefaultSettings(
  componentType: FormComponentType,
  settings?: ScaleSettings,
): ScaleSettings {
  if (componentType === FormComponentType.ScaleText) {
    return {
      scaleType: settings?.scaleType ?? ScaleType.Interval,
      title: settings?.title ?? '',
    };
  }

  if (componentType === FormComponentType.ScaleNumber) {
    return {
      scaleType: settings?.scaleType || ScaleType.Default,
      title: settings?.title ?? '',
      numberSettings: {
        decimalDigits: settings?.numberSettings?.decimalDigits ?? 2,
        numberType: settings?.numberSettings?.numberType ?? NumbersComponentType.Number,
        selectedCurrency: settings?.numberSettings?.selectedCurrency ?? null,
      },
      minimumScale: settings?.minimumScale ?? 0,
      maximumScale: settings?.maximumScale ?? 0,
    };
  }

  throw new Error('Invalid component type');
}

export function parseScaleSettings(
  settingsJson: string | null,
  componentType: FormComponentType = FormComponentType.ScaleNumber,
): ScaleSettings {
  try {
    if (!settingsJson) {
      throw new Error("Can't parse null component settings");
    }
    const parsedSettings = JSON.parse(settingsJson) as ScaleSettings;

    return fillWithDefaultSettings(componentType, parsedSettings);
  } catch (error: any) { // eslint-disable-line
    return fillWithDefaultSettings(componentType);
  }
}

export function parseIntervalValues(jsonStringValues: string) {
  const parsed = JSON.parse(jsonStringValues) as Array<{
    index: number;
    value: string;
  }>;

  return parsed.sort((a, b) => a.index - b.index).map((interval) => interval.value.toString());
}

export const getSymbol = (settings: NumbersSettings | undefined) => {
  if (!settings) {
    return undefined;
  }
  switch (settings.numberType) {
    case NumbersComponentType.Currency:
      return settings.selectedCurrency ?? 'N/A';
    case NumbersComponentType.Percentage:
      return '%';
    default:
      return undefined;
  }
};

export interface IScaleQuestionSettings {
  name: string;
  symbol?: string;
  scaleType?: ScaleType;
  title?: string;
  maximumScale?: number;
  minimumScale?: number;
}
export const getQuestionsScaleSettings = (
  assessmentQuestion: AssessmentFormComponent,
  index?: number,
): IScaleQuestionSettings => {
  const settings = parseScaleSettings(
    assessmentQuestion.settingsJson,
    assessmentQuestion.componentType,
  );

  return {
    symbol: getSymbol(settings.numberSettings),
    name: assessmentQuestion.fieldName(index),
    ...settings,
  };
};
