import { FC } from 'types';
import { Control, useFieldArray } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { User } from 'models/User';
import { useResources } from 'hooks/useResources';
import { ApprovalSettings } from 'typings/types/ApprovalSettings';
import { Total } from 'components/core/Total/Total';
import { UserManagementSearch } from 'components/user-management/UserManagementSearch/UserManagementSearch';
import { userSearchValidation } from 'services/validation/userSearch';

import { ApproversList } from '../ApproversList/ApproversList';

interface IApproversProps {
  control: Control<ApprovalSettings>;
}

export const ApproversSelect: FC<IApproversProps> = ({ control }) => {
  const { t } = useTranslation();
  const { data, isInitialLoad } = useResources(User);
  const { fields, append, remove } = useFieldArray<ApprovalSettings>({
    control,
    name: 'approverObjects',
  });

  const handleAdd = (email: string) => {
    const user = (data as Array<User>).find((user) => user.email === email);
    if (user) {
      // @ts-expect-error
      append({ id: user.id, username: user.username, email: user.email });
    }
  };

  return (
    <Box width="100%">
      <UserManagementSearch
        label={t('approval.form.approvers.searchUsersLabel')}
        placeholder={t('approval.form.approvers.searchUsersPlaceholder')}
        validationRules={userSearchValidation(
          t,
          (value: string) => Boolean((data as Array<User>).find((user) => user.email === value)),
          (value: string) => Boolean(fields?.find((user) => user.email === value)),
        )}
        disableButton={isInitialLoad}
        onClick={handleAdd}
      />
      <Total
        label={t('approval.form.approvers.totalUsers')}
        total={fields.length}
        w="100%"
        mt={20}
      />
      <ApproversList addedFields={fields} onRemove={remove} />
    </Box>
  );
};
