import {
  CountryFormatType,
  CountrySettings,
  ExportFormatType,
  UserInputFormatType,
} from 'typings/types/FormComponentSettings';

export function parseCountryQuestionSettings(stringifiedJSON: string | null): CountrySettings {
  if (!stringifiedJSON) {
    return {
      formatType: CountryFormatType.Country,
      userInputFormatType: UserInputFormatType.Code,
      exportAndStatisticsFormat: ExportFormatType.Code,
    };
  }

  try {
    const result = JSON.parse(stringifiedJSON) as CountrySettings;

    return result;
  } catch (error: any) { // eslint-disable-line
    return {
      formatType: CountryFormatType.Country,
      userInputFormatType: UserInputFormatType.Code,
      exportAndStatisticsFormat: ExportFormatType.Code,
    };
  }
}
