import { chakra } from '@chakra-ui/react';

export const FavoriteIcon = chakra('button', {
  baseStyle: {
    outline: 'none',
    display: 'inline-block',
    verticalAlign: 'text-top',
    ml: '5px',
    width: '14px',
    height: '14px',
  },
});
