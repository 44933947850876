import { FC } from 'types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormComponent } from 'models/FormComponent';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { FormModal } from 'components/modal/FormModal/FormModal';
import { ConfirmationSettings } from 'typings/types/FormComponentSettings';
import { FormAddonType } from 'typings/enums/FormAddonType';
import {
  getSkipConditionSettings,
  parseSkipConditionSettings,
} from 'services/skipConditionAddonSettings';

import { ConfirmationSettingsForm } from '../ConfirmationSettingsForm/ConfirmationSettingsForm';

import { parseConfirmationQuestionSettings } from './ConfirmationSettingsEditorModal.utils';

interface IConfirmationSettingsEditorModalProps {
  question: FormComponent;
  orderTag: string;
  onClose(): void;
}
export const ConfirmationSettingsEditorModal: FC<IConfirmationSettingsEditorModalProps> = ({
  orderTag,
  question,
  onClose,
}) => {
  const { t } = useTranslation();
  const { upsertComponent, disableEdit, allowLimitedEditOnFinalized } = useFormEditor();

  const parsed = parseConfirmationQuestionSettings(question.settingsJson);

  const skipConditionAddon = question.addons?.find(
    (addon) => addon.addonType === FormAddonType.SkipCondition,
  );
  const parsedSkipConditionSettings = skipConditionAddon
    ? parseSkipConditionSettings(skipConditionAddon)
    : undefined;

  const handleClickSave = useCallback(
    async (value: ConfirmationSettings) => {
      if (disableEdit && !allowLimitedEditOnFinalized) {
        return;
      }
      if (skipConditionAddon) {
        const addons =
          question.addons?.filter((addon) => addon.addonType !== FormAddonType.SkipCondition) ?? [];
        addons.push({
          addonType: FormAddonType.SkipCondition,
          settingsJson: value.skipTo
            ? JSON.stringify(getSkipConditionSettings(question.id, value.skipTo, 'true'))
            : null,
        });
        question.addons = addons;
      }
      question.settingsJson = value.confirmationText
        ? JSON.stringify({ confirmationText: value.confirmationText })
        : null;
      await upsertComponent(question);
      onClose();
    },
    [
      allowLimitedEditOnFinalized,
      disableEdit,
      onClose,
      question,
      skipConditionAddon,
      upsertComponent,
    ],
  );

  return (
    <FormModal
      isOpen
      title={t('form.settings.modal.title', {
        questionNo: orderTag,
        questionType: t('form.settings.confirmation.name'),
      })}
      maxWidth="550px"
      onClose={onClose}
    >
      <ConfirmationSettingsForm
        question={question}
        settings={parsed}
        skipConditionSettings={parsedSkipConditionSettings}
        disableTextChange={disableEdit && !allowLimitedEditOnFinalized}
        disabled={disableEdit}
        onSubmit={handleClickSave}
        onCancel={onClose}
      />
    </FormModal>
  );
};
