import { NumbersComponentType, NumbersSettings } from 'typings/types/FormComponentSettings';

export function parseNumbersQuestionSettings(stringifiedJSON: string | null): NumbersSettings {
  if (!stringifiedJSON) {
    return {
      decimalDigits: 0,
      numberType: NumbersComponentType.Number,
      selectedCurrency: null,
    };
  }
  try {
    const result = JSON.parse(stringifiedJSON) as NumbersSettings;

    return result;
  } catch (error: any) { // eslint-disable-line
    return {
      decimalDigits: 0,
      numberType: NumbersComponentType.Number,
      selectedCurrency: null,
    };
  }
}
