import { useBoolean } from '@chakra-ui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

import { ExportSiteDataModal } from '../ExportSiteDataModal/ExportSiteDataModal';

export const ExportSiteData = () => {
  const [isExportModalVisible, { on, off }] = useBoolean(false);

  const { t } = useTranslation();

  return (
    <Fragment>
      <StyledButton size="md" kind="secondary" onClick={on}>
        {t('export.action.all')}
      </StyledButton>
      {isExportModalVisible && <ExportSiteDataModal onCancel={off} />}
    </Fragment>
  );
};
