import React, { Factory } from 'react';
import { Select, SelectOption } from 'appkit-react';
import { SelectWidgetProps, ListItem } from 'react-awesome-query-builder';
import truncate from 'lodash/truncate';

import { RuleWrapper } from '../LogicBuilder.elements';

export const AQBSelect: Factory<SelectWidgetProps> = (props) => {
  const onChange = (value: string) => {
    props?.setValue(value);
  };

  return (
    <RuleWrapper>
      <Select
        showSelectAll={(props?.customProps as Record<string, unknown> | undefined)?.multiple}
        label={!props?.readonly ? props?.placeholder : ''}
        placeholder="Please select an answer"
        defaultValue={props?.value ?? ''}
        disabled={props?.readonly}
        onSelect={onChange}
        {...props?.customProps}
      >
        {(props?.listValues as Array<ListItem>).map((value) => (
          <SelectOption key={value.value} value={value.value} title={value.value}>
            {truncate(value.value, { length: 25 })}
          </SelectOption>
        ))}
      </Select>
    </RuleWrapper>
  );
};
