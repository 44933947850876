import { Attribute } from '@datx/core';

import { SiteStatus } from 'typings/enums/SiteStatus';
import { Resource } from './Resource';

export class SiteView extends Resource {
  public static type = 'site_view';
  public static endpoint = 'sites';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public siteName!: string;

  @Attribute()
  public pwcContact!: string;

  @Attribute()
  public allowLibraryAccess!: boolean;

  @Attribute()
  public maximumNumberOfUsers!: number;

  @Attribute()
  public siteStatus!: SiteStatus;
}
