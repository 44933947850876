type Config = {
  authEndpoint: string;
  tokenEndpoint: string;
  userinfoEndpoint: string;
  clientId: string;
  clientSecret: string;
  loginCallbackUrl: string;
  logoutCallbackUrl: string;
  issuerUrl: string;
  endSessionUrl: string;
  openidScopes: string;
  openidResponseType: string;
  apiUrl: string;
  adminRole: string;
  buildNumber: string;
  buildNumberFull: string;
};

// @ts-expect-error
// eslint-disable-next-line no-undef
const cfg: Config = (window?.config ?? {}) as Config;

export const config: Config = {
  authEndpoint: cfg.authEndpoint,
  tokenEndpoint: cfg.tokenEndpoint,
  userinfoEndpoint: cfg.userinfoEndpoint,
  clientId: cfg.clientId,
  clientSecret: cfg.clientSecret,
  loginCallbackUrl: cfg.loginCallbackUrl,
  logoutCallbackUrl: cfg.logoutCallbackUrl,
  issuerUrl: cfg.issuerUrl,
  endSessionUrl: cfg.endSessionUrl,
  openidScopes: cfg.openidScopes,
  openidResponseType: cfg.openidResponseType,
  apiUrl: cfg.apiUrl,
  adminRole: cfg.adminRole,
  // @ts-expect-error
  // eslint-disable-next-line no-undef
  buildNumber: process.env.REACT_APP_BUILD_NUMBER,
  // @ts-expect-error
  // eslint-disable-next-line no-undef
  buildNumberFull: process.env.REACT_APP_BUILD_NUMBER_FULL,
};
