import { ErrorMessage } from '@hookform/error-message';
import { Box, Text, FormControl, FormControlProps } from '@chakra-ui/react';
import { useCallback, useRef } from 'react';
import { FC } from 'types'
import { CalendarPicker } from 'appkit-react-datepicker';

import { IFieldProps } from 'typings/interfaces/IFieldProps';
import { FormFieldLabel } from 'components/core/FormFieldLabel/FormFieldLabel';

import { StyledClearValue } from './CalendarPickerField.elements';

interface ICalendarPickerFieldProps
  extends Omit<IFieldProps, 'value' | 'name'>,
    Pick<FormControlProps, 'sx'> {
  name?: string;
  value?: Date | Array<Date> | null;
  defaultValue?: Date | Array<Date> | null;
  minDate?: Date;
  format?: string;
  selectRange?: boolean;
  mode?: string;
  minDetail?: string;
  maxDetail?: string;
  onChange(value: Date | Array<Date> | null): void;
}

export const CalendarPickerField: FC<ICalendarPickerFieldProps> = ({
  name,
  label,
  errors,
  sx,
  htmlFor,
  onChange,
  selectRange = false,
  disabled,
  ...props
}) => {
  // eslint-disable-next-line
  const calendarRef = useRef<any>();

  const handleClear = useCallback(() => {
    if (disabled) {
      return;
    }
    calendarRef.current?.clearValue();
    onChange(null);
  }, [onChange, disabled]);

  return (
    <FormControl sx={sx}>
      {label && <FormFieldLabel htmlFor={htmlFor}>{label}</FormFieldLabel>}
      <Box
        position="relative"
        sx={{
          '.a-date-picker-header, .react-date-picker, .react-daterange-picker': { width: '100%' },
        }}
      >
        <CalendarPicker
          selectRange={selectRange}
          enforceTwoValue={selectRange}
          mode={selectRange ? 'showDoubleCalendarWithRangePanel' : 'default'}
          ref={calendarRef}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
        {!disabled && (
          <StyledClearValue
            className="appkiticon icon-delete-outline"
            cursor={disabled ? 'not-allowed' : 'pointer'}
            onClick={handleClear}
          />
        )}
      </Box>
      {errors && name && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <Text className="a-form-error">{message}</Text>}
        />
      )}
    </FormControl>
  );
};
