import { ChangeEvent } from 'react';
import { FC } from 'types';
import { useFormContext } from 'react-hook-form';
import { useCheckboxGroup, VStack, Text } from '@chakra-ui/react';
import { Checkbox } from 'appkit-react';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { getValidValue, getValidSettings } from './AssessmentMultipleChoiceField.utils';

interface IAssessmentMultipleChoiceFieldProps {
  assessmentQuestion: AssessmentFormComponent;
  disabled?: boolean;
  index?: number;
}

export const AssessmentMultipleChoiceField: FC<IAssessmentMultipleChoiceFieldProps> = ({
  assessmentQuestion,
  disabled,
  index = 0,
}) => {
  const { t } = useTranslation();
  const {
    setValue,
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const options = getValidValue(assessmentQuestion.valueJson);
  const settings = getValidSettings(assessmentQuestion.settingsJson);

  if (settings?.exclusiveOptionText) {
    options.push({ index: options.length, value: settings.exclusiveOptionText });
  }

  const handleChange = (e: Array<string>) => {
    setValue(assessmentQuestion.fieldName(index), e);
  };

  const oldValues = getValues()[assessmentQuestion.id][0];
  const { getCheckboxProps, setValue: setCheckboxGroupValue } = useCheckboxGroup({
    isNative: true,
    defaultValue: oldValues ? oldValues[assessmentQuestion.componentType] : null,
    onChange: handleChange,
  });

  const handleClick = ({ target: { checked, value } }: ChangeEvent<HTMLInputElement>) => {
    if (value === settings?.exclusiveOptionText) {
      const exclusiveText = settings.exclusiveOptionText;
      setCheckboxGroupValue(checked ? [exclusiveText] : []);
    } else {
      setCheckboxGroupValue((prev) => {
        const selectedOptions = [
          ...prev.filter((option) => option !== settings?.exclusiveOptionText && option !== value),
        ];
        if (checked) {
          selectedOptions.push(value);
        }
        return selectedOptions;
      });
    }
  };

  return (
    <VStack align="flex-start" spacing={8}>
      {options.map((item) => {
        const props = getCheckboxProps({ value: item.value });

        return (
          <Checkbox
            key={item.index}
            disabled={disabled}
            {...register(assessmentQuestion.fieldName(index), {
              required: assessmentQuestion.isRequired ? t<string>('validation.required') : false,
            })}
            {...props}
            onChange={handleClick}
          >
            {item.value}
          </Checkbox>
        );
      })}

      <ErrorMessage
        errors={errors}
        name={assessmentQuestion.fieldName(index)}
        render={({ message }) => <Text className="a-form-error">{message}</Text>}
      />
    </VStack>
  );
};
