export const guidRegex =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

// validate route path variables
export const asOneGuid = `(${guidRegex})`;
export const asZeroOrMoreGuidWithLeadingSlash = `(/${guidRegex})*`;

export const emailRegex = /^\w[\w.-]*@([\w-]+\.)+[\w-]+$/;

export const fileNameWithoutApostropheRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

export const trimmedLine = /^\S*$|^\S.+\S$/; // match one non-whitespace char in line or a line with non-whitespace characters on start and end of line
