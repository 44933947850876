import { Attribute } from '@datx/core';

import { Resource } from './Resource';

export class Language extends Resource {
  public static type = 'keydata/languages';

  @Attribute({ isIdentifier: true })
  public readonly iso639_1!: string;
  @Attribute()
  public readonly iso639_2!: string;
  @Attribute()
  public readonly name!: string;
}
