import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RoomFolder } from 'models/RoomFolder';
import { useUpdateRoomFolderUsers } from 'hooks/useUpdateRoomFolder';
import { FormModal } from 'components/modal/FormModal/FormModal';
import { FolderUserManagementForm } from 'components/room-folder-users/FolderUserManagementForm/FolderUserManagementForm';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { LoadingIndicator } from 'components/core/LoadingIndicator/LoadingIndicator';
import { IFolderUserManagementFields } from 'typings/interfaces/IFolderUserManagementFields';

import { useFolderManagementData } from './FolderUsersManagementModal.hooks';

interface IFolderUsersManagementModalProps {
  folder: RoomFolder;
  roomId: string;
  readonly?: boolean;
  onClose(): void;
}

export const FolderUsersManagementModal: FC<IFolderUsersManagementModalProps> = ({
  folder,
  roomId,
  readonly = false,
  onClose,
}) => {
  const { roomUsers, addedUsers } = useFolderManagementData(folder, roomId);
  const { save, isSaving } = useUpdateRoomFolderUsers(folder);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleSave = async (formValues: IFolderUserManagementFields) => {
    if (readonly || isSaving) {
      return;
    }
    setIsLoading(true);
    try {
      await save({
        ...folder.upsertDTO,
        users: formValues.users.map((user) => ({
          id: user.userId,
          role: user.folderUserRole,
        })),
      });
      PopAlertSuccess(t('folder.folderMembers.success.update'));
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('folder.folderMembers.error.main'), response.errors?.name);
      } else {
        PopAlertFail(t('folder.folderMembers.error.unexpected'), response.message);
      }
    } finally {
      setIsLoading(false);
      setTimeout(onClose, 100);
    }
  };

  return (
    <FormModal
      isOpen
      maxWidth="755px"
      title={t('folder.folderMembers.modal.title')}
      onClose={onClose}
    >
      {!addedUsers ? (
        <LoadingIndicator />
      ) : (
        <FolderUserManagementForm
          availableUsers={roomUsers}
          folderUsers={addedUsers}
          isLoading={isLoading}
          onSave={handleSave}
          onCancel={onClose}
        />
      )}
    </FormModal>
  );
};
