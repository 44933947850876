import { HttpMethod } from 'typings/enums/HttpMethod';
import { getIdToken } from 'utils/getIdToken';
import { config } from 'config'

const API_URL = `${config.apiUrl}/api/`;

export const siteAssessmentsExcelExportRequest = (siteId = '') =>
  fetch(`${API_URL}site/assessments/excelExport`, {
    headers: {
      'x-site-id': siteId,
      'x-id-token': getIdToken() ?? '',
    },
    method: HttpMethod.Post,
  });

export const siteAssessmentsJsonExportRequest = (siteId = '') =>
  fetch(`${API_URL}site/assessments/jsonExport`, {
    headers: {
      'x-site-id': siteId,
      'x-id-token': getIdToken() ?? '',
    },
    method: HttpMethod.Post,
  });

export const siteFormsExcelExportRequest = (siteId = '') =>
  fetch(`${API_URL}site/forms/excelExport`, {
    headers: {
      'x-site-id': siteId,
      'x-id-token': getIdToken() ?? '',
    },
    method: HttpMethod.Post,
  });

export const siteFormsJsonExportRequest = (siteId = '') =>
  fetch(`${API_URL}site/forms/jsonExport`, {
    headers: {
      'x-site-id': siteId,
      'x-id-token': getIdToken() ?? '',
    },
    method: HttpMethod.Post,
  });

export const siteDataFetcher =
  (siteId = '', body: Record<string, boolean>) =>
  () =>
    fetch(`${API_URL}site/export`, {
      headers: {
        'content-type': 'application/json',
        'x-site-id': siteId,
        'x-id-token': getIdToken() ?? '',
      },
      body: JSON.stringify(body),
      method: HttpMethod.Post,
    });
