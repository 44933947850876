import { FC } from 'types';

import { ScaleSettingsFormFields, ScaleType } from 'typings/types/FormComponentSettings';

import { ScaleSettingsIntervalForm } from '../ScaleSettingsIntervalForm/ScaleSettingsIntervalForm';
import { ScaleSettingsSliderForm } from '../ScaleSettingsSliderForm/ScaleSettingsSliderForm';

interface IScaleSettingsFormProps {
  sliderType: ScaleType;
  formValues: ScaleSettingsFormFields;
  disabled?: boolean;
  disableCurrencyChange: boolean;
  onNext(value: ScaleSettingsFormFields): Promise<void>;
  onCancel(): void;
  onBack(): void;
}

export const ScaleSettingsForm: FC<IScaleSettingsFormProps> = ({
  sliderType,
  formValues,
  disabled,
  disableCurrencyChange,
  onBack,
  onCancel,
  onNext,
}) => {
  return sliderType === ScaleType.Interval ? (
    <ScaleSettingsIntervalForm
      sliderType={sliderType}
      formValues={formValues}
      disabled={disabled}
      disableCurrencyChange={disableCurrencyChange}
      onNext={onNext}
      onCancel={onCancel}
      onBack={onBack}
    />
  ) : (
    <ScaleSettingsSliderForm
      sliderType={sliderType}
      formValues={formValues}
      disabled={disabled}
      disableCurrencyChange={disableCurrencyChange}
      onNext={onNext}
      onCancel={onCancel}
      onBack={onBack}
    />
  );
};
