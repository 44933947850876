import { FormComponentType } from 'typings/enums/FormComponentType';
import { DateSettingsEditorModal } from 'components/question-settings/DateSettingsEditorModal/DateSettingsEditorModal';
import { CountrySettingsEditorModal } from 'components/question-settings/CountrySettingsEditorModal/CountrySettingsEditorModal';
import { NumbersSettingsEditorModal } from 'components/question-settings/NumbersSettingsEditorModal/NumbersSettingsEditorModal';
import { ConfirmationSettingsEditorModal } from 'components/question-settings/ConfirmationSettingsEditorModal/ConfirmationSettingsEditorModal';
import { ScaleSettingsEditorModal } from 'components/question-settings/ScaleSettingsEditorModal/ScaleSettingsEditorModal';
import { ChoiceEditorModal } from 'components/question-settings/ChoiceEditorModal/ChoiceEditorModal';

export function getComponentSettingsModal(type: FormComponentType) {
  switch (type) {
    case FormComponentType.Date:
      return DateSettingsEditorModal;
    case FormComponentType.Country:
      return CountrySettingsEditorModal;
    case FormComponentType.Number:
      return NumbersSettingsEditorModal;
    case FormComponentType.Confirmation:
      return ConfirmationSettingsEditorModal;
    case FormComponentType.SingleChoice:
    case FormComponentType.MultipleChoice:
      return ChoiceEditorModal;
    case FormComponentType.ScaleNumber:
    case FormComponentType.ScaleText:
      return ScaleSettingsEditorModal;
    case FormComponentType.QuestionSet:
    case FormComponentType.Section:
    case FormComponentType.EmailAddress:
    case FormComponentType.Statement:
    case FormComponentType.FreeText:
    case FormComponentType.Name:
    default:
      return null;
  }
}
