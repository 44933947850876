import { Box } from '@chakra-ui/react';
import React from 'react';

import { BoxHeader } from '../components/core/BoxHeader/BoxHeader';
import { BackButton } from '../components/core/BoxHeader/components/BackButton';
import { BoxTitle } from '../components/core/BoxHeader/components/BoxTitle';
import { MainLayout } from '../components/layouts/MainLayout/MainLayout';

export const StatisticsPage = () => {
  return (
    <MainLayout>
      <BoxHeader align="center" justify="space-between">
        <BackButton />
        <BoxTitle>Statistics</BoxTitle>
        <Box />
      </BoxHeader>
    </MainLayout>
  );
};
