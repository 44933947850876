import { ChoiceQuestionValue, MultipleChoiceSettings } from 'typings/types/FormComponentSettings';

export function getValidValue(value: string | null): Array<ChoiceQuestionValue> {
  try {
    if (!value) {
      throw new Error('Missing values');
    }
    const valueJson = JSON.parse(value);

    if (!Array.isArray(valueJson)) {
      throw new Error('Invalid values');
    }

    if (valueJson.some((item) => !('index' in item) || !('value' in item))) {
      throw new Error('Invalid options');
    }

    return valueJson;
  } catch (error: any) { // eslint-disable-line
    throw new Error(`Invalid multiple choice field options (${error})`);
  }
}

export function getValidSettings(settings: string | null): MultipleChoiceSettings | null {
  if (!settings) {
    return null;
  }
  const settingsJson = JSON.parse(settings);

  if (!settingsJson || !('exclusiveOptionText' in settingsJson)) {
    return null;
  }

  return settingsJson;
}
