import { FC } from 'types';
import { Fragment } from 'react';

import { IFormComponentAddonDto } from 'typings/interfaces/dto/IFormComponentAddonDto';
import { AssessmentFormComponent } from 'models/AssessmentFormComponent';

import { QuestionIconButton } from '../AssessmentQuestionCard/components/QuestionIconButton';
import { AssessmentQuestionHelpModal } from '../AssessmentQuestionHelpModal/AssessmentQuestionHelpModal';
import { useOpenQuery } from 'hooks/useOpenQuery';

interface IAssessmentQuestionHelpProps {
  assessmentQuestion: AssessmentFormComponent;
  orderTag: string;
  addon?: IFormComponentAddonDto;
}

export const AssessmentQuestionHelp: FC<IAssessmentQuestionHelpProps> = ({
  addon,
  orderTag,
  assessmentQuestion,
}) => {
  const getAssessmentQuestionIdWithoutAssessmentIdPostfix = (id: string) => {
    if (id.includes('@')) {
      return id.slice(0, id.indexOf('@'));
    }

    return id;
  };

  const { isOpen, close, open } = useOpenQuery(
    getAssessmentQuestionIdWithoutAssessmentIdPostfix(assessmentQuestion.id),
    'help',
  );

  return (
    <Fragment>
      {isOpen ? (
        <QuestionIconButton icon={'icon-help-question-fill'} label="help" onClick={close} />
      ) : (
        <QuestionIconButton icon={'icon-help-question-outline'} label="help" onClick={open} />
      )}
      {isOpen && (
        <AssessmentQuestionHelpModal
          addon={addon}
          onClose={close}
          assessmentQuestion={assessmentQuestion}
          orderTag={orderTag}
        />
      )}
    </Fragment>
  );
};
