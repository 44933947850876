import { useAuth } from 'oidc-react';
import { useMemo } from 'react';

import { useDatx } from 'hooks/useDatx';
import { Campaign } from 'models/Campaign';
import { CampaignPermissionGroup } from 'models/CampaignPermissionGroup';
import { CampaignUser } from 'models/CampaignUser';
import { Form } from 'models/Form';
import { CampaignUserRole } from 'typings/enums/CampaignUserRole';
import { FormUserInitiatorStatus } from 'typings/enums/FormUserInitiatorStatus';
import { FormUser } from 'models/FormUser';

export const useCampaignInitiation = (form: Form | null, campaignId?: string) => {
  const store = useDatx();
  const { userData } = useAuth();

  const defaultUser = form?.users.find((user) => user.email.toLocaleLowerCase() === userData?.profile.preferredMail.toLocaleLowerCase());
  const defaultCampaignUser = store.add(
    {
      userId: defaultUser?.userId,
      username: defaultUser?.username,
      email: defaultUser?.email,
      role: CampaignUserRole.Initiator,
      defaultInitiatorStatus: defaultUser?.defaultInitiatorStatus,
    },
    CampaignUser,
  );

  const defaultInitiators = (form?.users ?? []).reduce(
    (acc: Array<CampaignUser>, formUser: FormUser) => {
      if (
        formUser.userId !== defaultUser?.userId &&
        (formUser.defaultInitiatorStatus === FormUserInitiatorStatus.Mandatory ||
          formUser.defaultInitiatorStatus === FormUserInitiatorStatus.Optional)
      ) {
        acc.push(
          store.add(
            {
              userId: formUser?.userId,
              username: formUser?.username,
              email: formUser?.email,
              role: CampaignUserRole.Initiator,
              defaultInitiatorStatus: formUser?.defaultInitiatorStatus,
            },
            CampaignUser,
          ),
        );
      }
      return acc;
    },
    [],
  );
  const componentIds = form?.components?.map((component) => component.id);

  const campaign = useMemo(() => {
    return (
      store.findOne(Campaign, campaignId ?? 'new') ??
      store.add(
        {
          id: 'new',
          formId: form?.id,
          formName: form?.name,
          permissionGroups: [
            store.add({ id: 1, name: 'Default', componentIds }, CampaignPermissionGroup),
          ],
          initiators: [defaultCampaignUser, ...defaultInitiators],
        },
        Campaign,
      )
    );
  }, [
    store,
    campaignId,
    form?.id,
    form?.name,
    componentIds,
    defaultCampaignUser,
    defaultInitiators,
  ]);

  return campaign;
};
