import { FC } from 'types';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { IButtonProps } from 'typings/appkit-react/IButtonProps';

export const FormActionButton: FC<Omit<IButtonProps, 'size' | 'type'>> = ({
  children,
  ...rest
}) => {
  return (
    <StyledButton type="button" size="lg" {...rest}>
      {children}
    </StyledButton>
  );
};
