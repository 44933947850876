import { Box } from '@chakra-ui/react';
import { FC } from 'types';

import { AssessmentConclusion } from '../AssessmentConclusion/AssessmentConclusion';
import { AssessmentForm } from '../AssessmentForm/AssessmentForm';

interface IAssessmentMainProps {
  isLoaded: boolean;
}
export const AssessmentMain: FC<IAssessmentMainProps> = ({ isLoaded }) => {
  return (
    <Box mb={40}>
      {isLoaded && <AssessmentForm />}
      {isLoaded && <AssessmentConclusion />}
    </Box>
  );
};
