import { observer } from 'mobx-react';
import { Box, BoxProps, Center, HStack, Spacer, Text } from '@chakra-ui/react';
import { FC } from 'types';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { FormAddonType } from 'typings/enums/FormAddonType';
import { useDebouncedUpdateAssessmentQuestion } from 'hooks/useAssessment';
import { IAttachmentFile } from 'typings/interfaces/dto/IAttachmentFile';

import { AssessmentQuestionHelp } from '../AssessmentQuestionHelp/AssessmentQuestionHelp';
import { AssessmentQuestionAttachment } from '../AssessmentQuestionAttachment/AssessmentQuestionAttachment';

import { MandatoryAddonBadge } from './components/MandatoryAddonBadge';
import { QuestionIconButton } from './components/QuestionIconButton';
import { QuestionOrderTag } from './components/QuestionOrderTag';
import { QuestionContainer } from './AssessmentQuestionCard.elements';

interface IAssessmentQuestionCardProps extends BoxProps {
  assessmentQuestion: AssessmentFormComponent;
  disabled?: boolean;
}

export const AssessmentQuestionCard: FC<IAssessmentQuestionCardProps> = observer(
  ({ children, assessmentQuestion, disabled, ...rest }) => {
    const {
      orderTag,
      text,
      isQuestionSet,
      isBookmarked,
      hasHelp,
      canAttach,
      isRequired,
      assessmentComponent,
      parent,
      assessment,
    } = assessmentQuestion;
    const { updateAssessmentAnswer, debouncedUpdateAssessmentAnswer } =
      useDebouncedUpdateAssessmentQuestion(assessmentQuestion, assessment?.isTest, 1000);

    const handleUpdateAttachmentAddon = async (attachments: Array<IAttachmentFile>) => {
      assessmentComponent.update({
        attachments: attachments.map(({ fileId }) => ({ fileId })),
      });
      await updateAssessmentAnswer();
    };

    const handleBookmark = async () => {
      assessmentComponent.update({ isBookmarked: !isBookmarked });
      await debouncedUpdateAssessmentAnswer();
    };

    const helpTextAddon = assessmentQuestion.addons.find(
      (item) => item.addonType === FormAddonType.HelpText,
    );

    const isTest = Boolean(assessmentQuestion.assessment?.isTest);

    return (
      <QuestionContainer {...rest} pl={parent?.isQuestionSet ? 0 : 12}>
        <HStack mr={12} pr={24}>
          <Center minWidth="44px">
            <QuestionOrderTag>{orderTag}</QuestionOrderTag>
          </Center>
          <Text as="span">{text}</Text>
          <Spacer />
          {isRequired && <MandatoryAddonBadge />}
          {canAttach && (
            <AssessmentQuestionAttachment
              orderTag={orderTag}
              assessmentId={assessmentQuestion.assessmentId}
              handleSave={handleUpdateAttachmentAddon}
              isEditDisabled={isTest || disabled}
              isViewDisabled={isTest}
              attachments={assessmentComponent.attachments}
            />
          )}
          {hasHelp && (
            <AssessmentQuestionHelp
              addon={helpTextAddon}
              orderTag={orderTag}
              assessmentQuestion={assessmentQuestion}
            />
          )}
          {!isTest && (
            <QuestionIconButton
              // eslint-disable-next-line
              icon={false ? 'icon-comment-fill' : 'icon-comment-outline'} // TODO: comments needs to be implemented
              label="comment"
              disabled
              // eslint-disable-next-line
              onClick={() => null} // TODO: comments needs to be implemented
            />
          )}
          {!isTest && (
            <QuestionIconButton
              icon={isBookmarked ? 'icon-archive-fill' : 'icon-archive-outline'}
              isDisabled={disabled}
              label="bookmark"
              onClick={handleBookmark}
            />
          )}
        </HStack>
        <Box pl={isQuestionSet ? 0 : 48} pt={24}>
          {children}
        </Box>
      </QuestionContainer>
    );
  },
);
