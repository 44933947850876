import { BasicConfig, Config, Fields } from 'react-awesome-query-builder';
import { TFunction } from 'react-i18next';

import { operators } from './operators';
import { widgets } from './widgets';
import { conjunctions } from './conjunctions';
import { settings } from './settings';
import { types } from './types';

interface IOptions {
  disabled: boolean;
  // some additional settings for the scoring in the future
}

type CustomConfig = (fields: Fields, t: TFunction, options: IOptions) => Config;

export const initConfig: CustomConfig = (fields, t, options) => ({
  ...BasicConfig,
  fields,
  widgets,
  types,
  conjunctions: conjunctions(t),
  settings: settings(options.disabled),
  operators: operators(t),
});
