import { FC } from 'types';
import { Fragment } from 'react';
import { JsonGroup, JsonRule } from 'react-awesome-query-builder';
import { Rule } from '../Rule/Rule';
import { StyledGroup, StyledGroupWrapperLeft, StyledGroupWrapperRight } from './Group.elements';

interface IProps {
  group: JsonGroup;
  isRoot: boolean;
}

const renderChildren = (group?: JsonGroup) => {
  if (!group?.children1) {
    return null;
  }

  return Object.entries(group.children1).map(([key, value]) => {
    if (value.type === 'group') {
      return <Group key={key} group={value as JsonGroup} isRoot={false} />;
    } else {
      return (
        <Rule key={key} rule={value as JsonRule} conjunction={group.properties?.conjunction} />
      );
    }
  });
};

export const Group: FC<IProps> = ({ isRoot, group }) => {
  return (
    <Fragment>
      {isRoot && group.properties?.conjunction}
      <StyledGroup>
        <StyledGroupWrapperLeft />
        <StyledGroupWrapperRight />
        {renderChildren(group)}
      </StyledGroup>
    </Fragment>
  );
};
