import { useSWRConfig } from 'swr';

import { IFetchOptions } from 'typings/interfaces/IFetchOptions';
import { useDependencyHeaders } from './useDependencyHeaders';

export const useMutate = ({ fetcher }: IFetchOptions, type: string) => {
  const depHeaders = useDependencyHeaders();
  const { mutate: globalMutate } = useSWRConfig();

  const mutate = async (
    data?: Record<string, unknown>,
    options?: Record<string, unknown>,
    mutateKey?: string,
  ) => {
    const key = `${mutateKey || type}@${depHeaders['x-site-id']}`;

    await fetcher(data, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
    });

    globalMutate(key);
  };

  return {
    mutate,
  };
};
