import { Route, Switch } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { ThemeContainer } from 'appkit-react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'oidc-react';

import { HomePage } from 'pages/home';
import { RoomsPage } from 'pages/rooms';
import { FormsPage } from 'pages/forms';
import { FormPage } from 'pages/form';
import { StatisticsPage } from 'pages/statistics';
import { ApplicationAdminPage } from 'pages/application-admin';
import { SiteAdminPage } from 'pages/site-admin';
import { LoginCallbackPage } from 'pages/callback';
import { RoomPage } from 'pages/room';
import { FullScreenLoading } from 'components/core/FullScreenLoading/FullScreenLoading';
import { theme } from 'styles/themes';
import { asOneGuid } from 'utils/regex';
import { AssessmentPage } from 'pages/assessment';
import { RoomsViewMode } from 'typings/enums/RoomsViewMode';
import { AssessmentsPage } from 'pages/assessments';
import { FormCampaignsPage } from 'pages/form/form-campaigns';
import { FormCampaignAssessmentsPage } from 'pages/form/form-campaign-assessments';
import { ImprintPage } from 'pages/imprint';
import { DataPrivacyPage } from 'pages/data-privacy';
import { NotFound } from 'pages/not-found/NotFound';
import { FormTestAssessmentPage } from 'pages/form/form-test-assessment';
import { DownloadPage } from 'pages/download';

import 'appkit-react/style/appkit-react.default.css';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import './styles/react-tag-input.css';

const App = () => {
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <ThemeContainer theme="theme-dark" level="level2">
      <ChakraProvider theme={theme}>
        {auth.isLoading && <FullScreenLoading>{t('loading.authentication')}</FullScreenLoading>}
        {!auth.isLoading && (
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/login/callback" component={LoginCallbackPage} />
            <Route path="/application-admin" component={ApplicationAdminPage} />
            <Route path="/site-admin" component={SiteAdminPage} />
            <Route
              path={`/rooms/:roomId${asOneGuid}/forms/:formId${asOneGuid}/campaign/:campaignId`}
              component={FormCampaignAssessmentsPage}
            />
            <Route
              path={`/rooms/:roomId${asOneGuid}/forms/:formId${asOneGuid}/campaign`}
              component={FormCampaignsPage}
            />
            <Route
              path={`/rooms/:roomId${asOneGuid}/forms/:formId${asOneGuid}/assessment`}
              component={FormTestAssessmentPage}
            />
            <Route
              path={`/rooms/:roomId${asOneGuid}/forms/:formId${asOneGuid}`}
              component={FormPage}
            />
            <Route path="/forms" component={FormsPage} />
            <Route path={`/rooms/:roomId${asOneGuid}`} component={RoomPage} />
            <Route path="/rooms" component={RoomsPage} />
            <Route
              path="/trash"
              component={() => <RoomsPage initialViewMode={RoomsViewMode.Trash} />}
            />
            <Route path="/assessment/:assessmentId" component={AssessmentPage} />
            <Route path="/assessments" component={AssessmentsPage} />
            <Route path="/statistics" component={StatisticsPage} />
            <Route path="/download/*" component={DownloadPage} />
            <Route path="/imprint" component={ImprintPage} />
            <Route path="/data-privacy" component={DataPrivacyPage} />
            <Route path="*" component={NotFound} />
          </Switch>
        )}
      </ChakraProvider>
    </ThemeContainer>
  );
};

export default App;
