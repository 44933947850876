import { Checkbox } from 'appkit-react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { useSWRConfig } from 'swr';

import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { PopAlertFail } from 'services/PopAlertFail';

interface IRoomsListViewFavoriteCellProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowData: Record<string, any>;
}

export const RoomsListViewFavoriteCell: FC<IRoomsListViewFavoriteCellProps> = ({ rowData }) => {
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();
  const dependencyHeaders = useDependencyHeaders();
  const room = rowData.original;

  const onSetFavorite = async () => {
    const url = `rooms/${room.id}/setFavorite`;
    const newValue = !room.isFavorite;

    try {
      await apiClient(url, HttpMethod.Post, {
        headers: {
          'content-type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify({
          isFavorite: newValue,
        }),
      });
      await mutate(`rooms@${dependencyHeaders['x-site-id']}`);
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('rooms.errors.updatingRoomStatus.main'), response.errors?.name);
      } else {
        PopAlertFail(t('rooms.errors.updatingRoomStatus.unexpected'), response.message);
      }
    }
  };
  return (
    <Checkbox
      defaultChecked={room.isFavorite}
      checkedIcon={<span style={{ color: 'inherit' }} className={'appkiticon icon-rating-fill'} />}
      uncheckedIcon={<span className={'appkiticon icon-rating-outline'} />}
      onChange={onSetFavorite}
    />
  );
};
