import { useTranslation } from 'react-i18next';
import { FC } from 'types';
import { useCallback } from 'react';

import { FormModal } from 'components/modal/FormModal/FormModal';
import { ReviewForm } from 'components/review/ReviewForm/ReviewForm';
import { useAssessmentReview } from 'hooks/useAssessmentReview';
import { useAssessmentApprovalAction } from 'hooks/useAssessmentApprovalAction';
import { ReviewAction } from 'typings/enums/ReviewAction';
import { useAssessmentApproval } from 'hooks/useAssessmentApproval';
import { ApprovalEntity } from 'typings/enums/ApprovalEntity';
import { LoadingIndicator } from 'components/core/LoadingIndicator/LoadingIndicator';
import { useAssessment } from 'hooks/useAssessment';
import { AssessmentStatus } from 'typings/enums/AssessmentStatus';
import { usePopAlertFail } from 'hooks/usePopAlertFail';
import { ParseAndPopAlertFail } from 'services/ParseAndPopAlertFail';

interface IAssessmentReviewModalProps {
  assessmentId: string;
  onClose(): void;
}
export const AssessmentReviewModal: FC<IAssessmentReviewModalProps> = ({
  assessmentId,
  onClose,
}) => {
  const { approval } = useAssessmentApproval(assessmentId);
  const { review, addReviewComment, error, isValidating } = useAssessmentReview(assessmentId);
  const { doReviewAction } = useAssessmentApprovalAction(assessmentId);
  const { assessment } = useAssessment(assessmentId);
  const { t } = useTranslation();

  usePopAlertFail(error, async () => setTimeout(() => onClose(), 500));

  const handleReviewAction = useCallback(
    async (action: ReviewAction) => {
      const { error } = await doReviewAction(action);
      error && ParseAndPopAlertFail(error, t);
    },
    [doReviewAction, t],
  );

  return (
    <FormModal isOpen minHeight="500px" maxWidth="1440px" onClose={onClose}>
      {isValidating && <LoadingIndicator />}
      {!isValidating && review && (
        <ReviewForm
          entityType={ApprovalEntity.Assessment}
          currentApprovals={approval?.currentApprovals}
          requiredApprovals={approval?.requiredApprovals}
          comments={review.comments}
          approvers={review.approvers}
          isUnderReview={assessment?.status === AssessmentStatus.UnderReview}
          onReviewAction={handleReviewAction}
          onSubmit={addReviewComment}
          onCancel={onClose}
        />
      )}
    </FormModal>
  );
};
