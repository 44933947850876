import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { useAssessmentApproval } from 'hooks/useAssessmentApproval';

import { AssessmentReviewModal } from '../AssessmentReviewModal/AssessmentReviewModal';

interface IAssessmentReviewButtonProps {
  assessmentId: string;
}

export const AssessmentReviewButton: FC<IAssessmentReviewButtonProps> = ({ assessmentId }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { approval } = useAssessmentApproval(assessmentId);

  return (
    <Fragment>
      <StyledButton
        kind="secondary"
        size="md"
        isLoading={!approval}
        disabled={!approval?.isSetUp}
        onClick={onOpen}
      >
        {t('assessment.action.review')}
      </StyledButton>
      {isOpen && <AssessmentReviewModal onClose={onClose} assessmentId={assessmentId} />}
    </Fragment>
  );
};
