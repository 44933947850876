import groupBy from 'lodash/groupBy';

import { FormComponent } from 'models/FormComponent';

export function getFlatComponentTree(formComponents: Array<FormComponent>, rootId: string) {
  const groups = groupBy(formComponents, 'parentComponentId');

  const getFlat = (rootId: string): Array<string> =>
    groups[rootId]
      ? [rootId, ...groups[rootId].flatMap((component) => getFlat(component.id))]
      : [rootId];

  return getFlat(rootId);
}
