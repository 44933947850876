import { Box, HStack, VStack, Text, Flex, useBoolean } from '@chakra-ui/react';
import { FC } from 'types';
import { Button, SelectOption, Tooltip } from 'appkit-react';
import { useTranslation } from 'react-i18next';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { mutate } from 'swr';

import { DomainType } from 'typings/enums/DomainType';
import { DomainStatus } from 'typings/enums/DomainStatus';
import { ControlledSelectField } from 'fields/SelectField/SelectField';
import { ControlledInputField } from 'fields/InputField/InputField';
import { CalendarPickerField } from 'fields/CalendarPickerField/CalendarPickerField';
import { formatISOToRoundUTCDate } from 'utils/dates';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { useDomainMutation } from './DomainManagementForm.hooks';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { useMutate } from 'hooks/useMutate';
import { DomainAppAdmin } from 'models/DomainAppAdmin';
import { useResources } from 'hooks/useResources';
import { SiteView } from 'models/SiteView';
import { SwitchField } from 'fields/SwitchField/SwitchField';

interface IDomainManagementFormProps {
  domain?: DomainAppAdmin;
  onCancel(): void;
}

interface IFormData {
  name: string;
  id: string;
  siteId: string;
  contact: string;
  type: DomainType;
  status: DomainStatus;
  hasLibraryAccess: boolean;
  expiration: string | null;
}

export const DomainManagementForm: FC<IDomainManagementFormProps> = ({ domain, onCancel }) => {
  const saveMutation = useMutate(
    domain ? DomainAppAdmin.update() : DomainAppAdmin.create(),
    DomainAppAdmin.type,
  );
  const deleteMutation = useDomainMutation(domain as DomainAppAdmin);
  const { t } = useTranslation();
  const dependencyHeaders = useDependencyHeaders();
  const sites = useResources(SiteView);

  const [isSaving, setIsSaving] = useBoolean();
  const [isDeleting, setIsDeleting] = useBoolean();

  const { handleSubmit, control, setValue, setError } = useForm<IFormData>({
    defaultValues: {
      name: domain?.name || '',
      id: domain?.id || '',
      siteId: domain?.siteId || '',
      status: domain?.status || DomainStatus.Active,
      type: domain?.type || DomainType.Dominant,
      expiration: domain?.expiration || null,
      contact: domain?.contact || '',
      hasLibraryAccess: domain?.hasLibraryAccess || false,
    },
  });

  const onSubmit = async (values: IFormData) => {
    setIsSaving.on();
    try {
      await saveMutation.mutate({ ...values }, undefined, DomainAppAdmin.endpoint);
      PopAlertSuccess(
        domain?.id
          ? t('applicationAdmin.domain.modal.successMessages.updated', { name: values.name })
          : t('applicationAdmin.domain.modal.successMessages.created', { name: values.name }),
      );
      setIsSaving.off();
      onCancel();
    } catch (response: any) { // eslint-disable-line
      if (response.errors) {
        Object.keys(response.errors).forEach((fieldName) => {
          setError(fieldName as keyof IFormData, { message: response.errors[fieldName][0] });
        });
      } else {
        PopAlertFail(
          domain?.id
            ? t('applicationAdmin.errors.domain.update')
            : t('applicationAdmin.errors.domain.create'),
        );
        setIsSaving.off();
      }
    }
  };

  const onDelete = async () => {
    setIsDeleting.on();
    try {
      await deleteMutation.mutate(`${DomainAppAdmin.endpoint}@${dependencyHeaders['x-site-id']}`);
      PopAlertSuccess(
        t('applicationAdmin.domain.modal.successMessages.deleted', { name: domain?.name }),
      );
      await mutate(`domains@${dependencyHeaders['x-site-id']}`);
      setIsDeleting.off();
      onCancel();
    } catch (response: any) { // eslint-disable-line
      setIsDeleting.off();
      PopAlertFail(t('applicationAdmin.errors.domain.delete'));
    }
  };

  const handleDatePickerChange = (value: Date | null) => {
    setValue('expiration', formatISOToRoundUTCDate(value));
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <VStack align="stretch" spacing="20px">
        <HStack spacing="86px" align="flex-end">
          <ControlledInputField
            sx={{ width: '100%' }}
            control={control as unknown as Control<FieldValues>}
            label={t('applicationAdmin.domain.modal.form.name.label').toUpperCase()}
            id="name"
            htmlFor="name"
            name="name"
            placeholder={t('applicationAdmin.domain.modal.form.name.placeholder')}
            defaultValue={domain?.name}
            rules={{ required: { value: true, message: t('validation.required') } }}
          />
          <CalendarPickerField
            label={t('applicationAdmin.domain.modal.form.calendarPicker.label').toUpperCase()}
            name="expirationDate"
            placeholder={t('applicationAdmin.domain.modal.form.calendarPicker.placeholder')}
            minDate={new Date()}
            defaultValue={domain?.expiration ? new Date(domain.expiration) : null}
            onChange={handleDatePickerChange}
          />
        </HStack>
        <HStack spacing="86px">
          <ControlledInputField
            sx={{ width: '100%' }}
            control={control as unknown as Control<FieldValues>}
            label={t('applicationAdmin.domain.modal.form.domain.label').toUpperCase()}
            id="id"
            htmlFor="id"
            name="id"
            defaultValue={domain?.id}
            placeholder={t('applicationAdmin.domain.modal.form.domain.placeholder')}
            disabled={Boolean(domain)}
            rules={{ required: { value: true, message: t('validation.required') } }}
          />
          <ControlledSelectField
            control={control as unknown as Control<FieldValues>}
            name="status"
            label={t('applicationAdmin.domain.modal.form.status.label').toUpperCase()}
            defaultValue={domain?.status || DomainStatus.Active}
            placeholder={t('applicationAdmin.domain.modal.form.status.placeholder')}
            rules={{ required: { value: true, message: t('validation.required') } }}
          >
            <SelectOption value={DomainStatus.Active}>{DomainStatus.Active}</SelectOption>
            <SelectOption value={DomainStatus.Inactive}>{DomainStatus.Inactive}</SelectOption>
          </ControlledSelectField>
        </HStack>
        <HStack spacing="86px" alignItems="flex-end">
          {sites.isInitialLoad && (
            <ControlledInputField
              sx={{ width: '100%' }}
              control={control as unknown as Control<FieldValues>}
              label={t('applicationAdmin.domain.modal.form.domain.label').toUpperCase()}
              id="siteId"
              htmlFor="siteId"
              name="siteId"
              placeholder={t('applicationAdmin.domain.modal.form.site.loading')}
              disabled
            />
          )}
          {!sites.isInitialLoad && (
            <ControlledSelectField
              control={control as unknown as Control<FieldValues>}
              name="siteId"
              label={t('applicationAdmin.domain.modal.form.site.label').toUpperCase()}
              defaultValue={domain?.siteId}
              placeholder={t('applicationAdmin.domain.modal.form.site.placeholder')}
              rules={{ required: { value: true, message: t('validation.required') } }}
            >
              {(sites.data as Array<SiteView>)?.map((site) => (
                <SelectOption key={site.id} value={site.id}>
                  {site.siteName}
                </SelectOption>
              ))}
            </ControlledSelectField>
          )}
          <Box width="100%">
            <Flex align="flex-end">
              <ControlledSelectField
                sx={{ mr: '20px', flex: '2' }}
                control={control as unknown as Control<FieldValues>}
                name="type"
                label={t('applicationAdmin.domain.modal.form.type.label').toUpperCase()}
                defaultValue={domain?.type || DomainType.Dominant}
                placeholder={t('applicationAdmin.domain.modal.form.type.placeholder')}
                rules={{ required: { value: true, message: t('validation.required') } }}
              >
                <SelectOption value={DomainType.Dominant}>{DomainType.Dominant}</SelectOption>
                <SelectOption value={DomainType.Secondary}>{DomainType.Secondary}</SelectOption>
              </ControlledSelectField>
              <Tooltip
                content={t('applicationAdmin.domain.modal.form.type.tooltipContent')}
                placement="top"
              >
                <Text
                  as="span"
                  className="appkiticon icon-help-question-fill"
                  color="brand.orange"
                  fontSize="24"
                />
              </Tooltip>
            </Flex>
          </Box>
        </HStack>
        <HStack spacing="86px">
          <ControlledInputField
            sx={{ width: '100%' }}
            control={control as unknown as Control<FieldValues>}
            label={t('applicationAdmin.domain.modal.form.contact.label').toUpperCase()}
            id="contact"
            htmlFor="contact"
            name="contact"
            defaultValue={domain?.contact}
            placeholder={t('applicationAdmin.domain.modal.form.contact.placeholder')}
          />
          <Box w="100%" pt={9}>
            <SwitchField
              control={control as unknown as Control<FieldValues>}
              name="hasLibraryAccess"
              label={t('applicationAdmin.domain.modal.form.access.label')}
              defaultChecked={domain?.hasLibraryAccess || false}
            />
          </Box>
        </HStack>
      </VStack>
      <HStack align="center" spacing="86px" mt={40} pb={20}>
        <Box width="100%">
          {domain && (
            <Button
              negative
              size="lg"
              type="button"
              disabled={isSaving || isDeleting}
              isLoading={isDeleting}
              onClick={onDelete}
            >
              {t('applicationAdmin.domain.modal.action.delete.label').toUpperCase()}
            </Button>
          )}
        </Box>
        <Flex width="100%" justifyContent="flex-end">
          <Button
            kind="secondary"
            size="lg"
            className="a-ml-10"
            type="button"
            disabled={isSaving || isDeleting}
            onClick={onCancel}
          >
            {t('action.cancel.label').toUpperCase()}
          </Button>
          <Button
            size="lg"
            className="a-ml-10"
            type="submit"
            isLoading={isSaving}
            disabled={isDeleting || isSaving}
          >
            {t('action.save.label').toUpperCase()}
          </Button>
        </Flex>
      </HStack>
    </Box>
  );
};
