import { Avatar as ChakraAvatar, AvatarProps } from '@chakra-ui/react';
import { FC } from 'types';

export const Avatar: FC<AvatarProps> = ({ name, sx, ...props }) => {
  return (
    <ChakraAvatar
      name={name}
      width="32px"
      height="32px"
      color="white"
      bg="brand.orange"
      sx={{
        '.chakra-avatar__initials': {
          fontWeight: 'normal',
          fontSize: 14,
          lineHeight: 20,
        },
        ...sx,
      }}
      {...props}
    />
  );
};
