import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { ModalFooter } from 'components/modal/Modal/components/ModalFooter';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { Modal } from 'components/modal/Modal/Modal';
import { FormActionButton } from 'components/core/FormActions/FormActionButton';

interface IHistoryModalProps {
  title: string;
  onClose(): void;
}
export const HistoryModal: FC<IHistoryModalProps> = ({ title, onClose, children }) => {
  const { t } = useTranslation();

  return (
    <Modal isVisible maxWidth="1162px" maxHeight="700px">
      <ModalHeader onCancel={onClose}>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody maxHeight="530px" pr={36}>
        {children}
      </ModalBody>
      <ModalFooter height="74px" pr={36}>
        <FormActionButton kind="secondary" onClick={onClose}>
          {t('action.close.label')}
        </FormActionButton>
      </ModalFooter>
    </Modal>
  );
};
