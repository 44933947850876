import { ModalBody as AppkitModalBody } from 'appkit-react';
import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'types';

export const ModalBody: FC<BoxProps> = ({ sx, ...props }) => (
  <Box
    as={AppkitModalBody}
    sx={{ px: '40px', bg: (theme) => `${theme.colors.grey.lvl4} !important`, ...sx }}
    {...props}
  />
);
