import { Box, chakra } from '@chakra-ui/react';

export const QuestionContainer = chakra(Box, {
  baseStyle: {
    bgColor: 'grey.lvl3',
    minH: '178px',
    width: '100%',
    pt: 12,
    pr: 44,
    pb: 20,
    pl: 56,
  },
});
