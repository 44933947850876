import { FC } from 'types';
import { useCallback } from 'react';

import { FormModal } from 'components/modal/FormModal/FormModal';

import { CreateRoomForm } from '../CreateRoomForm/CreateRoomForm';

interface ICreateRoomModalProps {
  isVisible: boolean;
  onClose(): void;
}

export const CreateRoomModal: FC<ICreateRoomModalProps> = ({ onClose }) => {
  const handleSuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <FormModal isOpen maxWidth="1160px" onClose={onClose}>
      <CreateRoomForm onSuccess={handleSuccess} onClose={onClose} />
    </FormModal>
  );
};
