import { IWithId, IWithUserId, transformResourceUserDto } from './transformResourceUserDto';

interface IRoom extends IWithId {
  roomUsers: Array<IWithId> | null;
}

interface ITransformedRoom extends IWithId {
  roomUsers: Array<IWithUserId> | null;
}
const transformRoomUsersData = (roomUsersDto: Array<IWithId>, roomId: string) =>
  roomUsersDto.map((roomUserDto) => transformResourceUserDto(roomUserDto, roomId));

const transformRoomDto = (roomDto: IRoom): ITransformedRoom => ({
  ...roomDto,
  roomUsers: roomDto.roomUsers ? transformRoomUsersData(roomDto.roomUsers, roomDto.id) : null,
});

export const transformRoomsData = (data: Array<IRoom>): Array<ITransformedRoom> =>
  data.map(transformRoomDto);
