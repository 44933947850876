import { FormControl, FormControlProps, Stack, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { RadioGroup } from 'appkit-react';
import { ErrorMessage } from '@hookform/error-message';
import { useController, UseControllerProps } from 'react-hook-form';

import { FormFieldLabel } from 'components/core/FormFieldLabel/FormFieldLabel';

interface IRadioGroupFieldProps
  extends UseControllerProps,
    Omit<FormControlProps, 'onChange' | 'defaultValue'> {
  direction?: 'row' | 'column';
  htmlFor?: string;
  label?: string;
}

export const RadioGroupField: FC<IRadioGroupFieldProps> = ({
  control,
  name,
  defaultValue,
  rules,
  label,
  direction = 'column',
  htmlFor,
  sx,
  children,
  ...rest
}) => {
  const {
    field: { onChange, ...restField },
    fieldState: { error },
  } = useController({ control, name, defaultValue, rules });
  return (
    <FormControl sx={sx}>
      {label && <FormFieldLabel htmlFor={htmlFor}>{label}</FormFieldLabel>}
      <Stack
        as={RadioGroup}
        direction={direction}
        spacing="0"
        // Radio group onChange is in conflict with Stack onChange
        // and has signature onChange(e:Event, value:string)=>void
        // @ts-ignore
        onChange={(_, value: string) => onChange(value)}
        {...restField}
        {...rest}
      >
        {children}
      </Stack>
      {error && (
        <ErrorMessage
          errors={error}
          name={name}
          render={({ message }) => <Text className="a-form-error">{message}</Text>}
        />
      )}
    </FormControl>
  );
};
