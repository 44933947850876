import { Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { Audit } from 'models/Audit';
import { HistoryModalContent } from 'components/modal/HistoryModal/components/HistoryModalContent';
import { AuditItem } from 'components/audit/AuditItem/AuditItem';
import { getAuditRecord } from './AssessmentHistoryContent.utils';

interface IAssessmentHistoryContentProps {
  isLoading: boolean;
  data?: Array<Audit>;
}

export const AssessmentHistoryContent: FC<IAssessmentHistoryContentProps> = ({
  isLoading,
  data,
}) => {
  const { t } = useTranslation();

  return (
    <HistoryModalContent isLoading={isLoading}>
      {data?.length ? (
        (data as Array<Audit>).map((item, index) => {
          return item.skip ? null : (
            <AuditItem key={index} auditItem={item} text={getAuditRecord(item, t)} />
          );
        })
      ) : (
        <Text>{t('state.empty')}</Text>
      )}
    </HistoryModalContent>
  );
};
