import { HttpMethod } from 'typings/enums/HttpMethod';
import { IErrorResponse } from 'typings/interfaces/IErrorResponse';
import { getIdToken } from './getIdToken';
import { config } from 'config'

const API_URL = `${config.apiUrl}/api/`;

export const apiClient = async (
  resourceUrl: string,
  method: HttpMethod,
  // eslint-disable-next-line
  options?: Record<string, any>,
) => {
  const headers = {
    'x-id-token': getIdToken(),
    ...options?.headers,
  };

  const requestOptions = {
    headers,
    method,
    body: options?.body,
  };

  const response = await fetch(`${API_URL}${resourceUrl}`, requestOptions);

  if (response.status === 204) {
    try {
      const { data } = await response.json();

      return {
        statusCode: 204,
        data,
      };
    } catch (error: any) { // eslint-disable-line
      return {
        statusCode: 204,
        data: {},
      };
    }
  }

  const data = await response.json();

  if (response.ok) {
    return data.data;
  }

  // TODO: discuss this inconsistency with BE
  if (data.StatusCode) {
    throw {
      statusCode: data.StatusCode,
      message: data.Message,
      errors: data.Errors,
    } as IErrorResponse;
  } else {
    throw data as IErrorResponse;
  }
};
