import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { ConfirmationSettings } from 'typings/types/FormComponentSettings';

export function getValidSettings(
  assessmentQuestion: AssessmentFormComponent,
): ConfirmationSettings {
  try {
    if (
      assessmentQuestion.componentType !== FormComponentType.Confirmation ||
      !assessmentQuestion.settingsJson
    ) {
      throw new Error('Invalid settings');
    }
    const settings = JSON.parse(assessmentQuestion.settingsJson);
    if (!settings['confirmationText']) {
      throw new Error('Missing ConfirmationText');
    }
    return settings;
  } catch (error: any) { // eslint-disable-line
    throw new Error(
      `Invalid assessment field settings: ${assessmentQuestion.componentType} {${assessmentQuestion.id}} (${error})`,
    );
  }
}
