import { Box, BoxProps, Center, HStack, Spacer, VStack } from '@chakra-ui/react';
import { FC } from 'types';

import { TextEditor } from 'components/core/TextEditor/TextEditor';
import { QuestionOrderTag } from 'components/assessment/AssessmentQuestionCard/components/QuestionOrderTag';
import { AttachmentDownload } from 'components/core/AttachmentDownload/AttachmentDownload';
import { IAssessmentBase } from 'typings/interfaces/IAssessmentBase';
import { IAttachmentFile } from 'typings/interfaces/dto/IAttachmentFile';

import { ConclusionContainer } from './AssessmentConclusionCard.elements';

interface IAssessmentConclusionCardProps extends BoxProps {
  orderNo: string;
  text: string | null;
  assessment: IAssessmentBase;
  attachments: Array<IAttachmentFile> | null;
}

export const AssessmentConclusionCard: FC<IAssessmentConclusionCardProps> = ({
  text,
  orderNo,
  assessment,
  attachments,
}) => {
  return (
    <ConclusionContainer>
      <HStack mr={24}>
        <Center minWidth="44px">
          <QuestionOrderTag>{orderNo}</QuestionOrderTag>
        </Center>
        <Spacer />
      </HStack>
      <Box pl={48} pt={24} pr={24}>
        <TextEditor
          toolbarStyle={{ display: 'none' }}
          editorProps={{ readOnly: true }}
          initialHtmlContent={text}
        />
      </Box>
      <VStack mt={12}>
        {attachments?.map((attachment) => (
          <AttachmentDownload
            key={attachment.fileId}
            file={attachment}
            downloadUrl={
              assessment.isTest
                ? `rooms/${assessment.roomId}/forms/${assessment.formId}/formFiles/${attachment.fileId}`
                : `assessments/${assessment.id}/formFiles/${attachment.fileId}`
            }
          />
        ))}
      </VStack>
    </ConclusionContainer>
  );
};
