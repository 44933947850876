import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { Control, useFieldArray } from 'react-hook-form';
import { Box, BoxProps, Heading } from '@chakra-ui/react';

import { RoomType } from 'typings/enums/RoomType';
import { CreateRoomUserManagementSearch } from './components/CreateRoomUserManagementSearch/CreateRoomUserManagementSearch';
import { ICreateRoomFields } from 'typings/interfaces/ICreateRoomFields';
import { CreateRoomUserManagementTable } from './components/CreateRoomUserManagementTable/CreateRoomUserManagementTable';

interface ICreateRoomUserManagementProps extends BoxProps {
  control: Control<ICreateRoomFields>;
  roomType: RoomType;
}

export const CreateRoomUserManagement: FC<ICreateRoomUserManagementProps> = ({
  control,
  roomType,
}) => {
  const { t } = useTranslation();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'roomUsers',
    rules: {
      validate: (value) => {
        return value.length > 0 ? undefined : t('rooms.errors.createRoom.noUserAdd');
      },
    },
  });

  const handleExpirationDateChange = (index: number, date: Date) => {
    update(index, { ...fields[index], expirationDate: date?.toISOString() ?? null });
  };

  return (
    <Box width="65%">
      <Heading as="h3" mb="24px" fontSize={22} lineHeight={36} fontWeight="400">
        {t('rooms.createRoom.usersManagement.title')}
      </Heading>
      <CreateRoomUserManagementSearch roomType={roomType} addedUsers={fields} onAdd={append} />
      <CreateRoomUserManagementTable
        fields={fields}
        control={control}
        remove={remove}
        onExpirationDateChange={handleExpirationDateChange}
      />
    </Box>
  );
};
