import { Attribute } from '@datx/core';

import { IAssessmentBase } from 'typings/interfaces/IAssessmentBase';
import { AssessmentStatusDisplay } from 'typings/types/AssessmentStatusDisplay';

import { AssessmentFormComponent } from './AssessmentFormComponent';
import { Resource } from './Resource';

export class AssessmentTest extends Resource implements IAssessmentBase {
  public static type = 'test-assessments';

  @Attribute({ isIdentifier: true })
  public readonly id!: string;

  @Attribute()
  public readonly name!: string;

  @Attribute()
  public readonly roomId!: string;

  @Attribute()
  public readonly formId!: string;

  @Attribute({ toMany: 'assessment-form-components' })
  public readonly assessmentFormComponents!: Array<AssessmentFormComponent>;

  // Mocked constant values for test view
  public readonly isEditable: boolean = true;
  public readonly isTest: boolean = true;
  public readonly displayStatus: AssessmentStatusDisplay = 'Test';
  public hasInitiator(email: string) {
    return typeof email === 'string'; // always return true
  }
  public hasRecipient(email: string) {
    return typeof email === 'string'; // always return true
  }

  public isValid(skippedComponents: Array<string>): boolean {
    return !this.assessmentFormComponents.some(
      (component) => !component.isValid && !skippedComponents.includes(component.id),
    );
  }
}
