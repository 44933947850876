import { FC } from 'types';
import { useBoolean } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { Form } from 'models/Form';
import { LoadingIndicator } from 'components/core/LoadingIndicator/LoadingIndicator';
import { FormUserManagementForm } from 'components/form-users/FormUserManagementForm/FormUserManagementForm';
import { FormModal } from 'components/modal/FormModal/FormModal';
import { IFormUserManagementFields } from 'typings/interfaces/IFormUserManagementFields';

import { useFormMutate } from '../../RoomsItemsList.hooks';
import { useFormManagementData } from './FormUserManagementModal.hooks';

/*
  case 1: Form Creation -> we have room and room users and folder and folders users
*/

/*
  [case 2]: Room items -> we have room and room users and folder and folders users
    - we should have form loaded here also
    - on save revalidate all room forms
*/

/*
  case 3: Form builder -> we have form loaded
    - preload rooms and folder to get users
    - on save revalidate current form and all other calls => approval, review, ... ?
*/

/*
  case 4: My Forms page -> we need to load form model to get the roomId, and then load rooms (check with BE if we can ger GET /rooms/{id}) to get room users
    - we should have form loaded here 
    - preload rooms
    - get roomUsers after both have loaded
    - on save revalidate all my forms
*/

interface IFormUserManagementModalProps {
  form: Form;
  onCancel(): void;
}

export const FormUserManagementModal: FC<IFormUserManagementModalProps> = ({ form, onCancel }) => {
  const { roomUsers, addedUsers } = useFormManagementData(form);
  const saveMutation = useFormMutate(form, HttpMethod.Put, `/${form.id}`);
  const [isSaving, setIsSaving] = useBoolean(false);
  const { t } = useTranslation();

  const saveChanges = async (formValues: IFormUserManagementFields) => {
    setIsSaving.on();
    try {
      await saveMutation.mutateUsers({
        ...form.updateDTO,
        users: formValues.users.map((user) => ({
          id: user.userId,
          role: user.role,
          defaultInitiatorStatus: user.defaultInitiatorStatus,
        })),
      });
      PopAlertSuccess(t('form.formMembers.success.update'));
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('form.formMembers.error.main'), response.errors?.name);
      } else {
        PopAlertFail(t('form.formMembers.error.unexpected'), response.message);
      }
    } finally {
      setIsSaving.off();
      setTimeout(onCancel, 100);
    }
  };

  return (
    <FormModal isOpen maxWidth="930px" title={t('form.formMembers.modalTitle')} onClose={onCancel}>
      {!addedUsers ? (
        <LoadingIndicator />
      ) : (
        <FormUserManagementForm
          availableUsers={roomUsers}
          formUsers={addedUsers}
          isLoading={isSaving}
          onCancel={onCancel}
          onSave={saveChanges}
        />
      )}
    </FormModal>
  );
};
