import { FC } from 'types';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonGroupItem } from 'appkit-react';

import { ButtonGroupField } from 'fields/ButtonGroupField/ButtonGroupField';
import { IButtonGroupProps } from 'typings/appkit-react/IButtonGroupProps';
import { RoomUserRole } from 'typings/enums/RoomUserRole';
import { ICreateRoomFields } from 'typings/interfaces/ICreateRoomFields';

interface ICreateRoomUserRoleButtonGroupFieldProps extends IButtonGroupProps {
  name: string;
  control: Control<ICreateRoomFields>;
  numberOfAdmins: number;
}
export const CreateRoomUserRoleButtonGroupField: FC<ICreateRoomUserRoleButtonGroupFieldProps> = ({
  control,
  name,
  numberOfAdmins,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <ButtonGroupField
      control={control as unknown as Control<FieldValues>}
      name={name}
      size="md"
      {...rest}
    >
      <ButtonGroupItem data-value={RoomUserRole.Admin}>
        {t('roles.admin').toUpperCase()}
      </ButtonGroupItem>
      <ButtonGroupItem data-value={RoomUserRole.Member} disabled={numberOfAdmins < 2}>
        {t('roles.member').toUpperCase()}
      </ButtonGroupItem>
    </ButtonGroupField>
  );
};
