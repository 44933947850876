import { useTranslation } from 'react-i18next';
import { FC } from 'types';
import { useState } from 'react';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { useAssessment } from 'hooks/useAssessment';
import { useAssessmentApproval } from 'hooks/useAssessmentApproval';
import { useAssessmentApprovalAction } from 'hooks/useAssessmentApprovalAction';
import { useCurrentUserEmail } from 'hooks/useCurrentUserEmail';
import { AssessmentApprovalAction } from 'typings/enums/AssessmentApprovalAction';

import { getUserAction } from './AssessmentApprovalActionButton.utils';

interface IAssessmentApprovalActionButtonProps {
  assessmentId: string;
}

export const AssessmentApprovalActionButton: FC<IAssessmentApprovalActionButtonProps> = ({
  assessmentId,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { assessment } = useAssessment(assessmentId);
  const { approval } = useAssessmentApproval(assessmentId);
  const currentUserEmail = useCurrentUserEmail();

  const { doApprovalAction } = useAssessmentApprovalAction(assessmentId);

  const action = getUserAction(assessment);
  const handleApprovalActionClick = async () => {
    setIsLoading(true);
    await doApprovalAction(action);
    setIsLoading(false);
  };

  const isCollaborator = assessment?.hasRecipient(currentUserEmail);
  const disabled =
    !isCollaborator ||
    !approval ||
    !approval.isSetUp ||
    (action === AssessmentApprovalAction.Edit && !approval.isAssessmentEditable) ||
    action === AssessmentApprovalAction.Unknown;

  if (action === AssessmentApprovalAction.Unknown) {
    return null;
  }

  return (
    <StyledButton
      kind="secondary"
      size="md"
      disabled={disabled}
      isLoading={isLoading}
      onClick={handleApprovalActionClick}
    >
      {t(`assessment.approval.action.${action}`)}
    </StyledButton>
  );
};
