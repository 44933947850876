import { ChoiceEditor } from 'components/question-settings/ChoiceEditor/ChoiceEditor';
import { ConfirmationSettingsEditor } from 'components/question-settings/ConfirmationSettingsEditor/ConfirmationSettingsEditor';
import { CountrySettingsEditor } from 'components/question-settings/CountrySettingsEditor/CountrySettingsEditor';
import { DateSettingsEditor } from 'components/question-settings/DateSettingsEditor/DateSettingsEditor';
import { NumbersSettingsEditor } from 'components/question-settings/NumbersSettingsEditor/NumbersSettingsEditor';
import { ScaleSettingsEditor } from 'components/question-settings/ScaleSettingsEditor/ScaleSettingsEditor';
import { FormComponentType } from 'typings/enums/FormComponentType';

export function getSettingsComponent(type: FormComponentType) {
  switch (type) {
    case FormComponentType.Date:
      return DateSettingsEditor;
    case FormComponentType.Country:
      return CountrySettingsEditor;
    case FormComponentType.Number:
      return NumbersSettingsEditor;
    case FormComponentType.Confirmation:
      return ConfirmationSettingsEditor;
    case FormComponentType.SingleChoice:
    case FormComponentType.MultipleChoice:
      return ChoiceEditor;
    case FormComponentType.ScaleNumber:
    case FormComponentType.ScaleText:
      return ScaleSettingsEditor;
    case FormComponentType.QuestionSet:
    case FormComponentType.Section:
    case FormComponentType.EmailAddress:
    case FormComponentType.Statement:
    case FormComponentType.FreeText:
    case FormComponentType.Name:
    default:
      return null;
  }
}
