import { Attribute } from '@datx/core';
import { computed } from 'mobx';
import { format } from 'date-fns';

import { TrashItemType } from 'typings/enums/TrashItemType';
import { Resource } from './Resource';
import { RoomUser } from './RoomUser';

export class TrashItem extends Resource {
  public static type = 'trash_item';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public name!: string;

  @Attribute()
  public roomName!: string;

  @Attribute()
  public type!: TrashItemType;

  @Attribute()
  public deletedAt!: string;

  @Attribute()
  public lastEdit!: string;

  @Attribute({ toOne: RoomUser })
  public owner!: RoomUser;

  @Attribute({ toOne: RoomUser })
  public deletedBy!: RoomUser;

  @computed
  public get formattedDeletedAt() {
    if (this.deletedAt) {
      return format(new Date(this.deletedAt), 'PPpp');
    }

    return '/';
  }

  @computed
  public get formattedLastEdit() {
    if (this.lastEdit) {
      return format(new Date(this.lastEdit), 'PPpp');
    }

    return '/';
  }

  @computed
  public get formattedType() {
    return TrashItemType[this.type as keyof typeof TrashItemType];
  }
}
