import { FieldValues } from 'react-hook-form';

import { IApiFormError } from 'typings/interfaces/IApiFormError';

export const parseApiFormError = <TFieldValues extends FieldValues = FieldValues>(
  error: IApiFormError,
) => {
  const filedErrors = Object.keys(error.errors).map((fieldName) => ({
    name: fieldName as keyof TFieldValues, // TODO: type of this seems to be incorrect for errors in nested fields
    topLevelFieldName: fieldName.split('[')[0] as keyof TFieldValues, // use when error is in the nested field but we need to display it for the top level component
    message: error.errors[fieldName].join(', '),
  }));

  return filedErrors;
};
