import { Text, Box, useBoolean, useDisclosure, Heading } from '@chakra-ui/react';
import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { IFolderDetailsRowProps } from 'typings/interfaces/IDetailsRowProps';
import { RoomDetailsRow } from 'components/room/RoomDetails/components/RoomDetailsRow/RoomDetailsRow';
import { FolderUsersManagementModal } from 'components/room-folder/FolderUsersManagementModal/FolderUsersManagementModal';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

export const FolderUsersRow: FC<IFolderDetailsRowProps> = ({
  model,
  roomId,
  disableEdit = false,
  readonly = false,
  onStartEdit,
  onStopEdit,
}) => {
  const [isEditMode, setEditMode] = useBoolean(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const stopEditing = () => {
    setEditMode.off();
    onStopEdit?.();
    onClose();
  };

  const handleClickEdit = () => {
    if (isEditMode) {
      stopEditing();
    } else {
      setEditMode.on();
      onStartEdit?.();
      onOpen();
    }
  };

  return (
    <Fragment>
      <RoomDetailsRow>
        <Heading as="h5" size="sm" w="100px">
          {t('room.sidebar.folderDetails.rows.users')}
        </Heading>
        <Box w="200px">
          <Text>{model.numberOfUsers.toString()}</Text>
        </Box>
        <Box w="47px">
          <StyledButton
            size="md"
            kind="transparent"
            onClick={handleClickEdit}
            disabled={disableEdit}
          >
            {t('action.edit.label')}
          </StyledButton>
        </Box>
      </RoomDetailsRow>
      {isOpen && (
        <FolderUsersManagementModal
          folder={model}
          roomId={roomId as string}
          readonly={readonly}
          onClose={stopEditing}
        />
      )}
    </Fragment>
  );
};
