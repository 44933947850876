import React from 'react';
import { FC } from 'types'
import { useTranslation } from 'react-i18next';
import { FormAddonType } from 'typings/enums/FormAddonType';

import { Addon } from '../Addon/Addon';

interface INoSettingsAddonProps {
  addonType: FormAddonType;
  disabled?: boolean;
  onClick?(): void;
}

export const NoSettingsAddon: FC<INoSettingsAddonProps> = ({ addonType }) => {
  const { t } = useTranslation();

  return <Addon name={t(`form.addons.${addonType}`)} />;
};
