import { Fragment, useCallback, useState } from 'react';
import { useBoolean, HStack } from '@chakra-ui/react';
import { Table } from 'appkit-react';
import { TFunction, useTranslation } from 'react-i18next';

import { TableSearch } from 'components/table/TableSearch/TableSearch';
import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { useFilteredResources } from 'hooks/useFilteredResources';
import { User } from 'models/User';
import { PopAlertFail } from 'services/PopAlertFail';
import { fileDownload } from 'utils/fileDownload';
import { useModal } from 'hooks/useModal';
import { CellText } from 'components/core/CellText/CellText';
import { dateSortMethod } from 'utils/dateSortMethod';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { ExportSiteData } from 'components/export-data/ExportSiteData/ExportSiteData';
import { useTenant } from 'hooks/useTenant';

import { UserManagementModal } from './UserManagementModal';
import { ImportModal } from './ImportModal';

type OnSelect = (user: User) => void;

const exampleColumns = (onSelect: OnSelect, t: TFunction) => [
  {
    Header: t('siteAdmin.user.tableColumns.name'),
    accessor: 'username',
    Cell: (row: Record<string, unknown>) => (
      <CellText onClick={() => onSelect(row.original as User)} cursor="pointer">
        {row.value as string}
      </CellText>
    ),
  },
  {
    Header: t('siteAdmin.user.tableColumns.role'),
    accessor: 'formattedRole',
  },
  {
    Header: t('siteAdmin.user.tableColumns.contact'),
    accessor: 'thirdPartyUserContact',
  },
  {
    Header: t('siteAdmin.user.tableColumns.domain'),
    accessor: 'domain.name',
  },
  {
    Header: t('siteAdmin.user.tableColumns.status'),
    accessor: 'status',
  },
  {
    Header: t('siteAdmin.user.tableColumns.libraryAccess'),
    accessor: 'hasLibraryAccess',
    Cell: (row: Record<string, unknown>) => (
      <CellText>
        {(row.value as string)
          ? t('siteAdmin.user.tableRows.libraryAccess.enabled')
          : t('siteAdmin.user.tableRows.libraryAccess.disabled')}
      </CellText>
    ),
  },
  {
    Header: t('siteAdmin.user.tableColumns.expirationDate'),
    accessor: 'formattedExpirationDate',
    sortMethod: dateSortMethod,
  },
  {
    Header: t('siteAdmin.user.tableColumns.lastLogin'),
    accessor: 'formattedLastLogin',
    sortMethod: dateSortMethod,
  },
];

export const UserManagementTable = () => {
  const { t } = useTranslation();
  const siteId = useTenant().activeTenant?.siteId;

  const managementModal = useModal();
  const importModal = useModal();

  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isTemplateDownloading, setIsTemplateDownloading] = useBoolean();

  const { data, isValidating, isInitialLoad, handleSearchInputChange } = useFilteredResources(
    User,
    'username',
  );

  const onUserSelect = useCallback(
    (user: User) => {
      setSelectedUser(user);
      managementModal.onOpen();
    },
    [setSelectedUser, managementModal],
  );

  const handleCancel = useCallback(() => {
    setSelectedUser(null);
    managementModal.onClose();
  }, [setSelectedUser, managementModal]);

  const handleUploadClick = useCallback(() => {
    importModal.onOpen();
  }, [importModal]);

  const handleTemplateDownload = async () => {
    try {
      setIsTemplateDownloading.on();

      await fileDownload('users/importExample', {
        headers: {
          'x-site-id': siteId ?? '',
        },
      });
    } catch (response: any) { // eslint-disable-line
      PopAlertFail(response.Message);
    } finally {
      setIsTemplateDownloading.off();
    }
  };

  return (
    <Fragment>
      <TableWrapper isLoading={isInitialLoad || isValidating}>
        <TableSearch onChange={handleSearchInputChange} />
        <Table
          columns={exampleColumns(onUserSelect, t)}
          data={data}
          showPageSizeOptions={false}
          defaultPageSize={10}
          defaultSorted={[{ id: 'username', asc: true }]}
        />
        <HStack spacing={20} position="absolute" right="0" bottom="0">
          <ExportSiteData />
          <StyledButton
            size="md"
            kind="secondary"
            onClick={handleTemplateDownload}
            isLoading={isTemplateDownloading}
            disabled={isTemplateDownloading}
          >
            {t('siteAdmin.user.action.importExampleDownload')}
          </StyledButton>
          <StyledButton size="md" kind="secondary" onClick={handleUploadClick}>
            {t('siteAdmin.user.action.import')}
          </StyledButton>
          <StyledButton size="md" onClick={managementModal.onOpen}>
            {t('siteAdmin.user.action.add')}
          </StyledButton>
        </HStack>
      </TableWrapper>
      {managementModal.isOpen && (
        <UserManagementModal
          user={selectedUser}
          isVisible={managementModal.isOpen}
          onCancel={handleCancel}
        />
      )}
      {importModal.isOpen && <ImportModal onCancel={importModal.onClose} />}
    </Fragment>
  );
};
