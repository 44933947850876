import { Box } from '@chakra-ui/react';
import { FC } from 'types';
import { Fragment } from 'react';

import { LoadingOverlay } from './FullScreenLoadingOverlay.elements';

interface IFullScreenLoadingOverlayProps {
  isLoading: boolean;
}
export const FullScreenLoadingOverlay: FC<IFullScreenLoadingOverlayProps> = ({
  isLoading,
  children,
}) => {
  return (
    <Fragment>
      {isLoading && (
        <LoadingOverlay>
          <Box className="a-loading a-primary" />
        </LoadingOverlay>
      )}
      {children}
    </Fragment>
  );
};
