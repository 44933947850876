import { FC } from 'types';
import { Control, useFieldArray } from 'react-hook-form';
import { Box, BoxProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { IResourceUser } from 'typings/interfaces/IResourceUser';
import { FormUserManagementTable } from 'components/form-users/FormUserManagementTable/FormUserManagementTable';
import { IFormUserManagementFields } from 'typings/interfaces/IFormUserManagementFields';
import { UserManagementSearch } from 'components/user-management/UserManagementSearch/UserManagementSearch';
import { transformUserToFormUser } from 'services/transformUserToFormUser';
import { userSearchValidation } from 'services/validation/userSearch';

interface IFormUserManagementProps extends BoxProps {
  control: Control<IFormUserManagementFields>;
  availableUsers: Array<IResourceUser> | undefined;
}
export const FormUserManagement: FC<IFormUserManagementProps> = ({
  availableUsers,
  control,
  ...rest
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });
  const { t } = useTranslation();

  const handleAddUser = (email: string) => {
    const selectedUser = availableUsers?.find((user) => user.email === email);
    if (!selectedUser) {
      return;
    }
    append(transformUserToFormUser(selectedUser));
  };

  return (
    <Box {...rest}>
      <UserManagementSearch
        label={t('form.formMembers.modalTitle')}
        placeholder={
          availableUsers ? t('form.formMembers.searchPlaceholder') : t('loading.fetching.data')
        }
        validationRules={userSearchValidation(
          t,
          (value: string) => Boolean(availableUsers?.find((user) => user.email === value)),
          (value: string) => Boolean(fields?.find((user) => user.email === value)),
        )}
        onClick={handleAddUser}
      />
      <FormUserManagementTable control={control} fields={fields} remove={remove} />
    </Box>
  );
};
