import React from 'react';
import { FC } from 'types'
import { Circle, Text } from '@chakra-ui/react';

interface IRoomCardStatsProps {
  label: string;
  value: string | number;
  visible?: boolean;
}

export const RoomCardStats: FC<IRoomCardStatsProps> = ({ label, value, visible = true }) => {
  return (
    <Circle
      size="42px"
      bg="grey.lvl1"
      boxShadow="0 3px 7px 1px rgba(255,255,255,0.16)"
      position="relative"
      opacity={visible ? 1 : 0}
      _before={{
        content: `"${label}"`,
        position: 'absolute',
        top: '-30px',
        color: 'white',
        fontSize: '18',
        fontWeight: 'medium',
        lineHeight: '30px',
        fontFamily: 'body',
        letterSpacing: '0',
        textAlign: 'center',
      }}
    >
      <Text
        size="2xl"
        sx={{
          fontWeight: 'medium',
          textAlign: 'center',
        }}
      >
        {value}
      </Text>
    </Circle>
  );
};
