export const NumberInput = {
  variants: {
    transparent: {
      field: {
        px: 0,
        bg: 'transparent',
        _invalid: {
          borderBottom: '2px solid',
          borderColor: 'status.error',
        },
      },
    },
  },
};
