import { AppStore } from 'store/AppStore';
import { compareFormComponentOrder } from 'services/formComponents/compareFormComponentOrder';
import {
  getOrderAfterMove,
  IComponentToShift,
} from 'services/formComponents/getComponentOrderChanges';
import { FormComponent } from 'models/FormComponent';

import { getSkipDependencies } from './getConditionDependencies';

export const moveComponentAndUpdateAffectedComponents = (
  store: AppStore,
  model: FormComponent,
  toIndex: number,
  toParentId: string | null = null,
): Array<IComponentToShift> => {
  const newParentId = toParentId === null ? model.parentComponentId : toParentId;
  const newParent = newParentId ? store.findOne(FormComponent, newParentId) : null;
  const fromParentId = model.parentComponentId;
  const fromIndex = model.index;

  model.update({ parentComponentId: newParentId, parent: newParent, index: toIndex });
  const componentsToShift = getOrderAfterMove(store, model, fromIndex, fromParentId);
  componentsToShift.forEach((componentToShift) => {
    if (componentToShift.id === model.id) {
      return;
    }
    const component = store.findOne(FormComponent, componentToShift.id);
    component?.update({ index: componentToShift.index });
  });

  return componentsToShift;
};

interface IDependencyError {
  id: string;
  dependencyId?: string;
}
export function validateDependencies(store: AppStore, formId: string): Array<IDependencyError> {
  const skipDependencies = getSkipDependencies(store, formId);
  const report: Array<IDependencyError> = [];
  const components = store.findAll(FormComponent).filter((fc) => fc.formId === formId);

  components.forEach((fc) => {
    if (fc.hasConditionAddon && fc.parent?.isFirstQuestionParent && fc.index === 0) {
      report.push({ id: fc.id });
    }
    const dependencies = [...fc.dependencies, ...Array.from(skipDependencies[fc.id] ?? [])];
    if (!dependencies) {
      return;
    }
    dependencies.forEach((dependencyId: string) => {
      const dependencyComponent = store.findOne(FormComponent, dependencyId);
      if (dependencyComponent && compareFormComponentOrder(dependencyComponent, fc) > 0) {
        report.push({ id: fc.id, dependencyId });
      }
    });
  });

  return report;
}

export const validateMoveComponent = (
  store: AppStore,
  modelId: string,
  toIndex: number,
  toParentId: string | null = null,
): Array<IDependencyError> => {
  const model = store.findOne(FormComponent, modelId);
  if (!model) {
    return [];
  }
  moveComponentAndUpdateAffectedComponents(store, model, toIndex, toParentId);

  return validateDependencies(store, model.formId);
};
