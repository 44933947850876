import { FC } from 'types';
import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface IRemoveButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  onClick(): void;
}
export const RemoveButton: FC<IRemoveButtonProps> = ({ sx, onClick }) => {
  const { t } = useTranslation();

  return (
    <IconButton
      isRound
      size="xs"
      icon={<span className="appkiticon icon-close-outline a-font-16" />}
      onClick={onClick}
      aria-label={t('action.remove.label')}
      sx={{
        ml: 20,
        bg: 'transparent',
        cursor: 'pointer',
        _focus: {
          bg: 'transparent',
        },
        _active: {
          bg: 'transparent',
        },
        _hover: {
          opacity: '0.75',
          bg: 'transparent',
        },
        ...sx,
      }}
    />
  );
};
