import { Box, Heading, HStack, VStack } from '@chakra-ui/react';
import { FC } from 'types';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { FormFieldHelp } from 'components/core/FormFieldHelp/FormFieldHelp';
import { NotificationTextFormField } from 'components/core/NotificationTextFormField/NotificationTextFormField';
import { SwitchField } from 'fields/SwitchField/SwitchField';
import { InputNumberFieldHorizontal } from 'fields/InputNumberField/InputNumberField';
import { ApprovalSettings } from 'typings/types/ApprovalSettings';
import { ApprovalEntity } from 'typings/enums/ApprovalEntity';

import { ApproversSelect } from '../ApproversSelect/ApproversSelect';

interface IApprovalSettingsFormProps {
  entity: ApprovalEntity;
  control: Control<ApprovalSettings>;
  defaultSkipSendingNotification: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onSubmit(): Promise<void>;
  onCancel(): void;
}

export const ApprovalSettingsForm: FC<IApprovalSettingsFormProps> = ({
  entity,
  control,
  defaultSkipSendingNotification,
  disabled,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <Box as="form" onSubmit={onSubmit}>
      <HStack spacing={40} alignItems="flex-start">
        <VStack spacing={20} alignItems="flex-start" width="55%">
          <Heading as="h2" size="xl">
            {t('approval.modal.title')}
          </Heading>
          <HStack width="100%">
            <SwitchField
              control={control as unknown as Control<FieldValues>}
              name="isEditableAfter"
              disabled={entity === ApprovalEntity.Form}
              label={t('approval.form.isEditableLabel', {
                entity: t(`approval.entity.${entity}`),
              })}
            />
            <FormFieldHelp>{t('approval.form.isFormEditableHelp')}</FormFieldHelp>
          </HStack>

          <HStack spacing={20} justify="space-between" width="100%">
            <InputNumberFieldHorizontal
              control={control as unknown as Control<FieldValues>}
              kind="separate"
              name="requiredApprovals"
              label={t('approval.form.requiredApprovals.label')}
              helperText={t('approval.form.requiredApprovals.help')}
              rules={{
                min: { value: 1, message: t('approval.form.requiredApprovals.min') },
              }}
            />
          </HStack>

          <Box width="100%">
            <NotificationTextFormField
              control={control as unknown as Control<FieldValues>}
              nameTextEditor="notificationText"
              defaultChecked={defaultSkipSendingNotification}
              nameCheckBox="skipSendingNotification"
              labelIsTitle={true}
            />
          </Box>
        </VStack>

        <VStack h="100%" w="45%" spacing={20}>
          <Heading as="h3" size="lg" width="100%">
            {t('approval.modal.subtitle')}
          </Heading>
          <ApproversSelect control={control} />
        </VStack>
      </HStack>
      <FormActions>
        <FormButtonCancel onClick={onCancel} />
        <FormButtonSubmit isLoading={isLoading} disabled={disabled} />
      </FormActions>
    </Box>
  );
};
