import { Assessment } from 'models/Assessment';
import { AssessmentApprovalAction } from 'typings/enums/AssessmentApprovalAction';
import { AssessmentStatus } from 'typings/enums/AssessmentStatus';
import { AssessmentViewActivationStatus } from 'typings/enums/AssessmentViewActivationStatus';

export const getUserAction = (assessment: Assessment | undefined): AssessmentApprovalAction => {
  if (assessment?.activationStatus === AssessmentViewActivationStatus.Deactivated) {
    return AssessmentApprovalAction.Unknown;
  }

  const assessmentStatus = assessment?.status;
  if (
    assessmentStatus === AssessmentStatus.InProgress ||
    assessmentStatus === AssessmentStatus.Created ||
    assessmentStatus === AssessmentStatus.Initiated
  ) {
    return AssessmentApprovalAction.RequestApproval;
  }
  if (assessmentStatus === AssessmentStatus.UnderReview) {
    return AssessmentApprovalAction.Withdraw;
  }
  if (assessmentStatus === AssessmentStatus.Approved) {
    return AssessmentApprovalAction.Edit;
  }

  return AssessmentApprovalAction.Unknown;
};
