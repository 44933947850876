import { Box, VStack } from '@chakra-ui/react';
import { FC } from 'types';
import { Droppable } from 'react-beautiful-dnd';

import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { DraggingAction } from 'typings/enums/DraggingAction';
import { FormConclusionGroup } from 'models/FormConclusionGroup';

import { FormEditorConditionGroup } from '../FormEditorConditionGroup/FormEditorConditionGroup';

interface IFormEditorConclusionsProps {
  conclusionGroup: FormConclusionGroup;
}

export const FormEditorConclusions: FC<IFormEditorConclusionsProps> = ({ conclusionGroup }) => {
  const { dragAction } = useFormEditor();

  return (
    <Droppable
      droppableId={`conclusionGroup-${conclusionGroup.id}`}
      isDropDisabled={dragAction !== DraggingAction.AddConditionGroup}
    >
      {(provided, snapshot) => (
        <VStack
          spacing="10px"
          ref={provided.innerRef}
          {...provided.droppableProps}
          sx={{
            bgGradient:
              !conclusionGroup.conclusions?.length &&
              snapshot.isDraggingOver &&
              'linear(to-b, grey.lvl3, grey.lvl2)',
            minH: '100px',
            pt: 16,
            mb: 8,
          }}
        >
          {conclusionGroup.conclusions.map((conclusion, index) => (
            <FormEditorConditionGroup
              key={conclusion.id}
              index={index + 1}
              conclusion={conclusion}
              conclusionGroup={conclusionGroup}
            />
          ))}
          <Box
            sx={{
              width: '100%',
              bgGradient:
                conclusionGroup.conclusions?.length &&
                snapshot.isDraggingOver &&
                'linear(to-b, grey.lvl3, grey.lvl2)',
            }}
          >
            {provided.placeholder}
          </Box>
        </VStack>
      )}
    </Droppable>
  );
};
