import { useMemo } from 'react';

import { FormComponentType } from 'typings/enums/FormComponentType';
import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { useDatx } from 'hooks/useDatx';
import { getPermittedComponents } from 'services/assessmentPermissionGroups';
import { useAssessmentForm } from 'context/AssessmentContext/AssessmentContextProvider';
import { UserAssessmentComponent } from 'typings/types/UserAssessmentComponent';
import { useCurrentUser } from 'hooks/useCurrentUser';

export const useAssessmentSections = (assessmentId: string | undefined) => {
  const { isInitiator, skippedComponents, assessment } = useAssessmentForm();
  const currentUser = useCurrentUser();
  const datx = useDatx();

  const joint = `/${skippedComponents.join('/')}/`; // used as a dependency to trigger recalculation

  return useMemo(() => {
    if (!assessmentId) {
      return [];
    }

    const componentIds = getPermittedComponents(datx, assessmentId, currentUser);
    const components = datx
      .findAll<AssessmentFormComponent>(AssessmentFormComponent)
      .reduce((acc: Array<UserAssessmentComponent>, component) => {
        if (
          component.assessmentId !== assessmentId ||
          joint.includes(`/${component.id}/`) ||
          component.componentType !== FormComponentType.Section
        ) {
          return acc;
        }

        const canEdit = assessment?.isTest || componentIds.includes(component.formComponentId);

        if (canEdit) {
          acc.push({ readonly: false, component });
          return acc;
        }

        if (isInitiator) {
          acc.push({ readonly: true, component });
          return acc;
        }

        return acc;
      }, [])
      .sort((s1, s2) => s1.component.index - s2.component.index);

    return components;
  }, [assessment?.isTest, assessmentId, currentUser, datx, isInitiator, joint]);
};
