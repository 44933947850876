import { useDisclosure } from '@chakra-ui/react';
import { Fragment } from 'react';

import { ToolbarIconButton } from 'components/core/ToolbarIconButton/ToolbarIconButton';
import { ToolbarActionIcon } from 'typings/enums/ToolbarActionIcon';
import { useAssessmentForm } from 'context/AssessmentContext/AssessmentContextProvider';

import { AssessmentDeletionModal } from '../AssessmentDeletionModal/AssessmentDeletionModal';

export const DeleteAssessmentIconButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { assessmentId } = useAssessmentForm();

  return (
    <Fragment>
      {isOpen ? (
        <ToolbarIconButton
          action={ToolbarActionIcon.DeleteActive}
          disabled={!assessmentId}
          onClick={onClose}
        />
      ) : (
        <ToolbarIconButton
          action={ToolbarActionIcon.Delete}
          disabled={!assessmentId}
          onClick={onOpen}
        />
      )}
      {isOpen && assessmentId && (
        <AssessmentDeletionModal assessmentId={assessmentId} onClose={onClose} />
      )}
    </Fragment>
  );
};
