import { BasicConfig, Operators } from 'react-awesome-query-builder';
import { TFunction } from 'react-i18next';

import { ConditionOperator } from 'typings/enums/ConditionOperator';

export const operators = (t: TFunction): Operators => ({
  ...BasicConfig.operators,
  Is: {
    ...BasicConfig.operators.equal,
    valueSources: ['value'],
    label: t(`form.operators.${ConditionOperator.Is}`),
  },
  IsNot: {
    ...BasicConfig.operators.equal,
    valueSources: ['value'],
    label: t(`form.operators.${ConditionOperator.IsNot}`),
  },
  GreaterThan: {
    ...BasicConfig.operators.greater,
    valueSources: ['value'],
    label: t(`form.operators.${ConditionOperator.GreaterThan}`),
  },
  LessThan: {
    ...BasicConfig.operators.less,
    valueSources: ['value'],
    label: t(`form.operators.${ConditionOperator.LessThan}`),
  },
  GreaterThanOrEquals: {
    ...BasicConfig.operators.greater_or_equal,
    valueSources: ['value'],
    label: t(`form.operators.${ConditionOperator.GreaterThanOrEquals}`),
  },
  LessThanOrEquals: {
    ...BasicConfig.operators.less_or_equal,
    valueSources: ['value'],
    label: t(`form.operators.${ConditionOperator.LessThanOrEquals}`),
  },
});
