import { useMemo } from 'react';
import { useDatx } from 'hooks/useDatx';

import { Room } from 'models/Room';
import { RoomFolder } from 'models/RoomFolder';
import {
  transformToFolderUsers,
  transformUserToFolderUser,
} from 'services/transformUserToFolderUser';

export const useAvailableFolderUsers = (folder: RoomFolder, roomId: string) => {
  const datx = useDatx();

  const currentUsers = useMemo(() => {
    const folderRoom = datx.findOne(Room, roomId);
    if (folderRoom) {
      return transformToFolderUsers(folder, folderRoom);
    }
    return [];
  }, [datx, folder, roomId]);

  const roomUsers = useMemo(() => {
    const folderRoom = datx.findOne(Room, roomId);
    const parentFolder = folder.parentFolder;

    if (!parentFolder && folderRoom) {
      return folderRoom.roomUsers.map((user) => transformUserToFolderUser(user));
    }

    if (parentFolder && folderRoom) {
      return transformToFolderUsers(parentFolder, folderRoom);
    }

    return [];
  }, [datx, folder.parentFolder, roomId]);

  return {
    roomUsers,
    currentUsers,
  };
};

export const useFolderManagementData = (folder: RoomFolder, roomId: string) => {
  const { currentUsers, roomUsers } = useAvailableFolderUsers(folder, roomId);

  return {
    roomUsers,
    addedUsers: currentUsers,
  };
};
