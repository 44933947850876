import { useEffect, useRef, useState } from 'react';
import { useSWRConfig } from 'swr';

import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { TrashItemType } from 'typings/enums/TrashItemType';
import { TrashItem } from 'models/TrashItem';

export interface ISelectionItem {
  entityId: string;
  type: TrashItemType;
}

export const useTrashTable = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [selection, setSelection] = useState<Array<ISelectionItem>>([]);

  // eslint-disable-next-line
  const selectTable = useRef<any>();

  useEffect(() => {
    selectTable.current.props.data.length === selection.length && selection.length !== 0
      ? setSelectAll(true)
      : setSelectAll(false);
  }, [selection]);

  const toggleAll = () => {
    const isSelectAll = selectAll ? false : true;
    const selection: Array<ISelectionItem> = [];

    if (isSelectAll) {
      const wrappedInstance = selectTable?.current?.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;

      // eslint-disable-next-line
      currentRecords.forEach((item: any) => {
        if (item._original) {
          selection.push({ entityId: item._original.id, type: item._original.type });
        }
      });
    }
    setSelectAll(isSelectAll);
    setSelection(selection);
  };

  const toggleSelection = (_: unknown, __: unknown, rowItem: TrashItem) => {
    let selected = [...selection];
    const keyIndex = selected.findIndex((item) => item.entityId === rowItem.id);

    if (keyIndex >= 0) {
      selected = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
    } else {
      selected.push({ entityId: rowItem.id, type: rowItem.type });
    }
    setSelection(selected);
  };

  const isSelected = (key: string) => {
    return selection?.find((item) => item.entityId === key);
  };

  const resetSelection = () => {
    setSelection([]);
  };

  return {
    resetSelection,
    selectTable,
    selection,
    isSelected,
    selectAll,
    toggleSelection,
    toggleAll,
  };
};

export const useTrashActions = (selectedItems: Array<ISelectionItem>) => {
  const dependencyHeaders = useDependencyHeaders();
  const { mutate } = useSWRConfig();

  const deleteItems = async () => {
    await apiClient(`trash/multiple`, HttpMethod.Delete, {
      headers: {
        'content-type': 'application/json',
        ...dependencyHeaders,
      },
      body: JSON.stringify({
        multiSelectTrash: [...selectedItems],
      }),
    });

    await mutate(`trash@${dependencyHeaders['x-site-id']}`);
  };

  const restore = async () => {
    await apiClient(`trash/multiple/restore`, HttpMethod.Post, {
      headers: {
        'content-type': 'application/json',
        ...dependencyHeaders,
      },
      body: JSON.stringify({
        multiSelectTrash: [...selectedItems],
      }),
    });

    await mutate(`trash@${dependencyHeaders['x-site-id']}`);
  };

  return {
    deleteItems,
    restore,
  };
};
