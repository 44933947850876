import { SimpleGrid } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { FormAddonType } from 'typings/enums/FormAddonType';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { FormConclusionDropType } from 'typings/enums/FormConclusionDropType';
import { FormEditorSidebarItemType } from 'typings/enums/FormEditorSidebarItemType';

import { FormEditorSidebarMenuItem } from '../FormEditorSidebarMenuItem/FormEditorSidebarMenuItem';

const items = {
  components: [
    FormComponentType.Confirmation,
    FormComponentType.Number,
    FormComponentType.Country,
    FormComponentType.QuestionSet,
    FormComponentType.Date,
    FormComponentType.ScaleNumber,
    FormComponentType.EmailAddress,
    FormComponentType.Section,
    FormComponentType.FreeText,
    FormComponentType.SingleChoice,
    FormComponentType.MultipleChoice,
    FormComponentType.Statement,
    FormComponentType.Name,
  ],
  addons: [
    FormAddonType.Attachment,
    FormAddonType.Mandatory,
    FormAddonType.HelpText,
    FormAddonType.MoreAnswers,
    FormAddonType.Label,
    FormAddonType.Condition,
    FormAddonType.SkipCondition,
  ],
  conclusions: [FormConclusionDropType.Conclusion, FormConclusionDropType.ConditionGroup],
};

interface IFormEditorSidebarMenuProps {
  type: FormEditorSidebarItemType;
  disabled?: boolean;
}

export const FormEditorSidebarMenu: FC<IFormEditorSidebarMenuProps> = ({ disabled, type }) => {
  const { t } = useTranslation();

  return (
    <SimpleGrid columns={2} spacing="10px 40px">
      {items[type].map((item, index) => (
        <FormEditorSidebarMenuItem
          key={item}
          id={item}
          type={type}
          index={index}
          disabled={disabled}
        >
          {t(`form.${type}.${item}`).toUpperCase()}
        </FormEditorSidebarMenuItem>
      ))}
    </SimpleGrid>
  );
};
