import { Fragment, useState } from 'react';
import { FC } from 'types'
import { Select, SelectOption } from 'appkit-react';
import { Box, Heading, Text, useBoolean, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { RoomDetailsRow } from 'components/room/RoomDetails/components/RoomDetailsRow/RoomDetailsRow';
import { IRoomDetailsRowProps } from 'typings/interfaces/IDetailsRowProps';
import { RoomContactConfirmationModal } from 'components/room/RoomContactConfirmationModal/RoomContactConfirmationModal';
import { useUpdateRoom } from 'components/room/room.hooks';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

export const RoomContactRow: FC<IRoomDetailsRowProps> = ({
  model,
  disableEdit = false,
  readonly = false,
  onStartEdit,
  onStopEdit,
}) => {
  const [isEditMode, setEditMode] = useBoolean(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [updatedValue, setUpdatedValue] = useState(model.userContactId);
  const { save, isSaving } = useUpdateRoom(model.id);
  const { t } = useTranslation();

  const stopEditing = () => {
    setEditMode.off();
    onStopEdit?.();
    onClose();
  };

  const handleSelect = (selectValue: string) => {
    if (selectValue !== model.userContactId) {
      setUpdatedValue(selectValue);
      onOpen();
    } else {
      stopEditing();
    }
  };

  const handleClickEdit = () => {
    if (isEditMode) {
      setEditMode.off();
      onStopEdit?.();
    } else {
      setEditMode.on();
      onStartEdit?.();
    }
  };

  const handleClickConfirm = async () => {
    if (isSaving && !isEditMode && !readonly && isOpen) {
      return;
    }
    await save({ ...model.upsertDTO, userContactId: updatedValue });
    stopEditing();
  };

  const handleClickCancel = () => {
    if (isSaving || !isEditMode) {
      return;
    }
    stopEditing();
  };

  return (
    <Fragment>
      <RoomDetailsRow>
        <Heading as="h5" size="sm" w="100px">
          {t('room.sidebar.roomDetails.rows.contact')}
        </Heading>
        <Box w={isEditMode ? '257px' : '200px'}>
          {!isEditMode && <Text>{model.userContact?.username}</Text>}
          {isEditMode && (
            <Select onSelect={handleSelect} defaultValue={model?.userContact?.id}>
              {model.roomUsers.map((roomUser) => (
                <SelectOption key={roomUser.userId} value={roomUser.userId}>
                  {roomUser.username}
                </SelectOption>
              ))}
            </Select>
          )}
        </Box>
        {!isEditMode && !readonly && (
          <Box w="47px">
            <StyledButton
              size="md"
              kind="transparent"
              onClick={handleClickEdit}
              disabled={disableEdit}
            >
              {t('action.edit.label')}
            </StyledButton>
          </Box>
        )}
      </RoomDetailsRow>
      {isOpen && (
        <RoomContactConfirmationModal
          isSaving={isSaving}
          onConfirm={handleClickConfirm}
          onCancel={handleClickCancel}
        />
      )}
    </Fragment>
  );
};
