import { Input } from 'appkit-react';
import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IRoomNameInputProps {
  initialValue: string;
  onBlur(inputValue: string): void;
}

export const RoomNameInput: FC<IRoomNameInputProps> = ({ initialValue, onBlur }) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const [inputError, setInputError] = useState<string | null>(null);
  const { t } = useTranslation();

  const handleBlur = () => {
    if (inputError) {
      return;
    }

    onBlur(inputValue);
  };

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setInputError(t('room.sidebar.roomDetails.inputs.name.validation.required'));
      return;
    }
    if (value.length > 50) {
      setInputError(t('room.sidebar.roomDetails.inputs.name.validation.maxLength'));
      return;
    }

    setInputError(null);
    setInputValue(value);
  };

  return (
    <Input
      defaultValue={initialValue}
      hasError={Boolean(inputError)}
      errMsg={inputError}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};
