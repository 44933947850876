import useSWR, { useSWRConfig } from 'swr';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { debounce } from 'lodash';

import { HttpMethod } from 'typings/enums/HttpMethod';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { apiClient } from 'utils/apiClient';
import { useDatx } from 'hooks/useDatx';
import { Assessment } from 'models/Assessment';
import { PopAlertFail } from 'services/PopAlertFail';
import { UpsertAssessmentCollaboratorsDTO } from 'typings/types/dto/AssessmentDTO';
import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { FormConclusionResultDTO } from 'typings/types/dto/FormConclusionResultDTO';
import { IAssessmentDto } from 'typings/interfaces/dto/IAssessmentDto';
import { IAssessmentTestRenamedDto } from 'typings/interfaces/dto/IAssessmentTestDto';
import { AssessmentTest } from 'models/AssessmentTest';
import { IAssessmentBase } from 'typings/interfaces/IAssessmentBase';
import {
  removeDeletedFormComponents,
  transformAssessmentComponents,
  transformTestAssessmentDto,
} from 'services/transformAssessmentComponents';
import { useAssessmentForm } from 'context/AssessmentContext/AssessmentContextProvider';
import {
  decrementUpdatingAnswer,
  incrementUpdatingAnswer,
} from 'context/AssessmentContext/AssessmentContext.utils';

export const useDebouncedUpdateAssessmentQuestion = (
  assessmentFormComponent: AssessmentFormComponent,
  isTest = false,
  wait = 0,
) => {
  const { dispatch } = useAssessmentForm();
  const dependencyHeaders = useDependencyHeaders();
  const { t } = useTranslation();

  const updateAssessmentAnswer = useCallback(async () => {
    const upsertDTO = assessmentFormComponent.updateAssessmentComponentDTO;
    if (!upsertDTO) {
      throw 'Unexpected! Missing question form component result';
    }
    const { assessmentId } = assessmentFormComponent;

    const url = `${isTest ? 'test' : ''}assessments/${assessmentId}/components/${
      assessmentFormComponent.assessmentComponent.id
    }`;
    const method = HttpMethod.Put;

    try {
      incrementUpdatingAnswer(dispatch, assessmentFormComponent.id);

      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify(upsertDTO),
      });
      decrementUpdatingAnswer(dispatch, assessmentFormComponent.id);
    } catch (response: any) { // eslint-disable-line
      const message = response.message || t('assessment.errors.update');
      PopAlertFail(t('alerts.fail.title'), message);
    }
  }, [assessmentFormComponent, dependencyHeaders, dispatch, isTest, t]);

  const debouncedUpdateAssessmentAnswer = useMemo(
    () => debounce(updateAssessmentAnswer, wait),
    [updateAssessmentAnswer, wait],
  );

  return { updateAssessmentAnswer, debouncedUpdateAssessmentAnswer };
};

export const useRevalidateAssessment = (
  assessment: IAssessmentBase | undefined,
  timestamp: string | undefined,
) => {
  const dependencyHeaders = useDependencyHeaders();
  const { mutate } = useSWRConfig();

  const revalidate = useCallback(async () => {
    if (!assessment) {
      return;
    }

    const keySuffix = timestamp ? `@${timestamp}` : '';
    // Do not await on purpose
    mutate(
      `${assessment.isTest ? 'test' : ''}assessments/${assessment.id}/conclusionResults@${
        dependencyHeaders['x-site-id']
      }`,
    );
    await mutate(
      `${assessment.isTest ? 'test' : ''}assessment-${
        assessment.isTest ? assessment.formId : assessment.id
      }@${dependencyHeaders['x-site-id']}${keySuffix}`,
    );
  }, [assessment, dependencyHeaders, mutate, timestamp]);

  return revalidate;
};

export const useUpdateCollaborators = (assessmentId: string) => {
  const dependencyHeaders = useDependencyHeaders();
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();

  const updateAssessmentCollaborators = async (upsertDTO: UpsertAssessmentCollaboratorsDTO) => {
    const url = `assessments/${assessmentId}/collaborators`;
    const method = HttpMethod.Post;

    try {
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify(upsertDTO),
      });
      await mutate(`assessment-${assessmentId}@${dependencyHeaders['x-site-id']}`);
    } catch (response: any) { // eslint-disable-line
      const message = response.message || t('assessment.errors.collaborators');
      PopAlertFail(t('alerts.fail.title'), message);
    }
  };
  return { updateAssessmentCollaborators };
};

export const useDeleteAssessment = (assessmentId: string) => {
  const dependencyHeaders = useDependencyHeaders();
  const { t } = useTranslation();

  const deleteAssessment = async (onSuccess?: () => void) => {
    const url = `assessments/${assessmentId}`;
    const method = HttpMethod.Delete;
    try {
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
      });
      onSuccess?.();
    } catch (response: any) { // eslint-disable-line
      const message = response.message || t('assessment.errors.delete');
      PopAlertFail(t('alerts.fail.title'), message);
    }
  };
  return { deleteAssessment };
};

export const useSubmitAssessment = (assessmentId: string | undefined) => {
  const dependencyHeaders = useDependencyHeaders();
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();

  const submitAssessment = async () => {
    const url = `assessments/${assessmentId}/submit`;
    const method = HttpMethod.Post;

    if (!assessmentId) {
      PopAlertFail(t('alerts.fail.title'));
      return;
    }
    try {
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
      });
      await mutate(`assessment-${assessmentId}@${dependencyHeaders['x-site-id']}`);
    } catch (response: any) { // eslint-disable-line
      const message = response.message || t('assessment.errors.submit');
      PopAlertFail(t('alerts.fail.title'), message);
    }
  };
  return { submitAssessment };
};

export const useAssessment = (assessmentId: string) => {
  const dependencyHeaders = useDependencyHeaders();
  const datx = useDatx();

  const key = `assessment-${assessmentId}@${dependencyHeaders['x-site-id']}`;

  const swr = useSWR(
    dependencyHeaders['x-site-id'] ? key : null,
    async () => {
      const response = await apiClient(`assessments/${assessmentId}`, HttpMethod.Get, {
        headers: {
          ...dependencyHeaders,
        },
      });

      const transformedResult = transformAssessmentComponents<IAssessmentDto>(response);

      removeDeletedFormComponents(
        datx,
        transformedResult.id,
        transformedResult.assessmentFormComponents,
      );
      const assessment: Assessment = datx.add(transformedResult, Assessment);

      return assessment;
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  return {
    isInitialLoad: !swr.data && !swr.error,
    assessment: swr.data,
    ...swr,
  };
};

export const useTestAssessment = (roomId: string, formId: string, timestamp: string) => {
  const dependencyHeaders = useDependencyHeaders();
  const datx = useDatx();
  const siteId = dependencyHeaders['x-site-id'];
  const keySuffix = timestamp ? `@${timestamp}` : '';
  const key = siteId ? `testassessment-${formId}@${siteId}${keySuffix}` : null;
  const url = `rooms/${roomId}/forms/${formId}/testAssessment`;

  const swr = useSWR<AssessmentTest>(
    key,
    async () => {
      const response = await apiClient(url, HttpMethod.Get, {
        headers: {
          ...dependencyHeaders,
        },
      });

      const transformedResult = transformAssessmentComponents<IAssessmentTestRenamedDto>(
        transformTestAssessmentDto(response),
      );

      removeDeletedFormComponents(
        datx,
        transformedResult.id,
        transformedResult.assessmentFormComponents,
      );

      const assessment: AssessmentTest = datx.add(transformedResult, AssessmentTest);

      return assessment;
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  return {
    isInitialLoad: !swr.data && !swr.error,
    assessment: swr.data,
    ...swr,
  };
};

export const useAssessmentConclusionResult = (assessmentId: string | undefined, isTest = false) => {
  const dependencyHeaders = useDependencyHeaders();

  const siteId = dependencyHeaders['x-site-id'];
  const key =
    assessmentId && siteId
      ? `${isTest ? 'test' : ''}assessments/${assessmentId}/conclusionResults@${siteId}`
      : null;

  const url = `${isTest ? 'test' : ''}assessments/${assessmentId}/conclusionResults`;

  const swr = useSWR(
    key,
    async () => {
      const response = await apiClient(url, HttpMethod.Get, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
      });

      return response as Array<FormConclusionResultDTO>;
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );
  const isInitialLoad = !swr.data && !swr.error;

  return {
    isInitialLoad,
    conclusionResults: swr.data,
    ...swr,
  };
};

export const useResetTestAssessment = (assessmentId: string | undefined) => {
  const dependencyHeaders = useDependencyHeaders();
  const { t } = useTranslation();

  const resetAssessment = async () => {
    const url = `testAssessments/${assessmentId}/reset`;
    const method = HttpMethod.Post;

    if (!assessmentId) {
      PopAlertFail(t('alerts.fail.title'));
      return;
    }
    try {
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
      });
    } catch (response: any) { // eslint-disable-line
      PopAlertFail(t('alerts.fail.title'));
    }
  };
  return { resetAssessment };
};
