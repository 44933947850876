import { FC } from 'types';
import { FieldArrayWithId } from 'react-hook-form';
import { Box, Center, Text, VStack } from '@chakra-ui/react';
import { Select, SelectOption } from 'appkit-react';
import { ButtonGroup, ButtonGroupItem } from 'appkit-react';
import { AssessmentCollaboratorsFields } from 'typings/types/dto/AssessmentDTO';
import { FieldArrayListItem } from 'components/core/FieldArrayListItem/FieldArrayListItem';
import { IPermissionGroupDto } from 'typings/interfaces/dto/IPermissionGroupDto';
import { LoadingIndicator } from 'components/core/LoadingIndicator/LoadingIndicator';

interface IAssessmentCollaboratorsListProps {
  collaborators: Array<any> | null;
  addedFields: Array<
    FieldArrayWithId<AssessmentCollaboratorsFields, 'permissionGroupUsers', 'userId'>
  >;
  permissionGroups: Array<IPermissionGroupDto>;
  onRemove(index: number): void;
  onChangeGroup(index: number, permissionGroupId: string): void;
}

export const AssessmentCollaboratorsList: FC<IAssessmentCollaboratorsListProps> = ({
  collaborators,
  addedFields,
  permissionGroups,
  onChangeGroup,
  onRemove,
}) => {
  return (
    <VStack
      spacing={0}
      width="100%"
      alignItems="start"
      sx={{
        borderTop: ({ colors }) => `2px solid ${colors.grey.lvl3}`,
      }}
    >
      {addedFields.map((item, index) => (
        <FieldArrayListItem
          key={item.userId}
          username={item.username}
          email={item.email}
          onRemove={() => {
            onRemove(index);
          }}
        >
          <Box width="300px">
            <Select
              value={item.permissionGroupId}
              defaultValue={item.permissionGroupId}
              showSearchOnToggle
              onSelect={(value: string) => onChangeGroup(index, value)}
            >
              {permissionGroups.map((permissionGroup) => (
                <SelectOption key={permissionGroup.id} value={permissionGroup.id}>
                  {permissionGroup.name}
                </SelectOption>
              ))}
            </Select>
          </Box>
        </FieldArrayListItem>
      ))}
      {!collaborators ? (
        <LoadingIndicator />
      ) : (
        collaborators.map((item) => (
          <FieldArrayListItem key={item.id} username={item.email} email={item.email}>
            {item.isDefaultInitiator ? (
              <Center mr={20} py={4} px={15} bg="brand.orange">
                <Text size="sm">DEFAULT INITIATOR</Text>
              </Center>
            ) : null}

            <ButtonGroup value={item.role}>
              <ButtonGroupItem data-value="Initiator">INITIATOR</ButtonGroupItem>
              <ButtonGroupItem data-value="Viewer">VIEWER</ButtonGroupItem>
            </ButtonGroup>
          </FieldArrayListItem>
        ))
      )}
    </VStack>
  );
};
