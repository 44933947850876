import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { InputFieldV7 } from 'fields/InputField/InputField';
import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { trimmedLine } from 'utils/regex';

interface IAssessmentNameFieldProps {
  assessmentQuestion: AssessmentFormComponent;
  disabled?: boolean;
  index?: number;
}

export const AssessmentNameField: FC<IAssessmentNameFieldProps> = ({
  assessmentQuestion,
  disabled,
  index = 0,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Fragment>
      <InputFieldV7
        placeholder={t('assessment.form.name.firstName')}
        sx={{ mb: 8 }}
        name={`${assessmentQuestion.id}.${index}.firstName`}
        control={control}
        disabled={disabled}
        rules={{
          pattern: { value: trimmedLine, message: t('validation.trimmedLine') },
        }}
      />
      <InputFieldV7
        placeholder={t('assessment.form.name.middleName')}
        sx={{ mb: 8 }}
        disabled={disabled}
        name={`${assessmentQuestion.id}.${index}.middleName`}
        control={control}
        rules={{
          pattern: { value: trimmedLine, message: t('validation.trimmedLine') },
        }}
      />
      <InputFieldV7
        placeholder={t('assessment.form.name.lastName')}
        sx={{ mb: 8 }}
        name={`${assessmentQuestion.id}.${index}.lastName`}
        disabled={disabled}
        control={control}
        rules={{
          pattern: { value: trimmedLine, message: t('validation.trimmedLine') },
        }}
      />
    </Fragment>
  );
};
