import { TFunction } from 'react-i18next';

import { PopAlertFail } from 'services/PopAlertFail';
import { exportDownload } from 'utils/exportDownload';
import { campaignExportFetcher } from 'fetchers/assessmentExport';
import { PopAlertSuccess } from 'services/PopAlertSuccess';

export const handleCampaignExport = async (
  t: TFunction,
  siteId: string,
  formId: string,
  format: string,
  campaignIds: string[],
) => {
  try {
    const result = await exportDownload(campaignExportFetcher(siteId, formId, format, campaignIds));
    if (result === null) {
      PopAlertSuccess(t('export.modal.success.campaign'));
    }
  } catch {
    PopAlertFail(t('export.errors.dataDownload.main'));
  }
};
