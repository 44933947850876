import { HStack, StackProps } from '@chakra-ui/react';
import { FC } from 'types';

export const FormActions: FC<StackProps> = ({ children, ...rest }) => {
  return (
    <HStack spacing={20} justify="flex-end" mt={40} {...rest}>
      {children}
    </HStack>
  );
};
