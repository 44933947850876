import { FC } from 'types';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { emailRegex } from 'utils/regex';
import { InputFieldV7 } from 'fields/InputField/InputField';

interface IAssessmentEmailAddressFieldProps {
  assessmentQuestion: AssessmentFormComponent;
  disabled?: boolean;
  index?: number;
}

export const AssessmentEmailAddressField: FC<IAssessmentEmailAddressFieldProps> = ({
  assessmentQuestion,
  disabled,
  index = 0,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Fragment>
      <InputFieldV7
        placeholder={t('assessment.form.email.email')}
        sx={{ mb: 8 }}
        disabled={disabled}
        name={assessmentQuestion.fieldName(index)}
        control={control}
        rules={{
          required: assessmentQuestion.isRequired ? t<string>('validation.required') : false,
          pattern: { value: emailRegex, message: t('validation.validEmail') },
        }}
      />
    </Fragment>
  );
};
