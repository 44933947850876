import { Flex, Box, Text } from '@chakra-ui/react';
import React from 'react';
import { FC } from 'types'

export const FullScreenLoading: FC = ({ children }) => {
  return (
    <Flex height="100vh" width="100vw" justify="center" align="center" direction="column">
      <Box className="a-loading a-primary" />
      <Text mt="20px" size="xl">
        {children}
      </Text>
    </Flex>
  );
};
