import { Box, Center } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

interface ILoadingIndicatorProps {
  hideDefaultText?: boolean;
}
export const LoadingIndicator: FC<ILoadingIndicatorProps> = ({ hideDefaultText, children }) => {
  const { t } = useTranslation();

  return (
    <Center flexDir="column">
      <Box className="a-loading a-primary" />
      {!hideDefaultText && <Box>{t('loading.fetching.data')}</Box>}
      {children && <Box>{children}</Box>}
    </Center>
  );
};
