import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { mutate } from 'swr';
import { useBoolean } from '@chakra-ui/react';

import { ActionsMenu } from 'components/core/ActionsMenu/ActionsMenu.elements';
import { ActionsMenuItem } from 'components/core/ActionsMenu/ActionsMenuItem/ActionsMenuItem';
import { AssessmentView } from 'models/AssessmentView';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { AssessmentViewActivationStatus } from 'typings/enums/AssessmentViewActivationStatus';
import { useAssessmentMenuActions } from '../../FormCampaignAssessmentsTable.hooks';

interface IFormCampaignAssessmentsTableMenuProps {
  assessmentViewItem: AssessmentView;
  top?: number;
  left?: number;
  onClose(): void;
  onEditCollaboratorOpen(): void;
  onExportModalOpen(): void;
}

export const FormCampaignAssessmentsTableMenu: FC<IFormCampaignAssessmentsTableMenuProps> = ({
  assessmentViewItem,
  onClose,
  onEditCollaboratorOpen,
  onExportModalOpen,
  ...props
}) => {
  const [isDeleting, setIsDeleting] = useBoolean();
  const [isActivating, setIsActivating] = useBoolean();
  const [isTogglingSubmissionAlert, setIsTogglingSubmissionAlert] = useBoolean();

  const { t } = useTranslation();
  const history = useHistory();
  const dependencyHeaders = useDependencyHeaders();

  const { activateAssessment, deleteAssessment } = useAssessmentMenuActions();

  const handleOpen = () => {
    history.push(`/assessment/${assessmentViewItem.id}`);
    onClose();
  };

  const handleEditCollaborator = () => {
    onEditCollaboratorOpen();
    onClose();
  };

  const handleSubmissionAlert = async () => {
    setIsTogglingSubmissionAlert.on();
    const url = `assessments/${assessmentViewItem.id}/alert`;
    try {
      await apiClient(url, HttpMethod.Post, {
        headers: {
          'content-type': 'application/json',
          ...dependencyHeaders,
        },
      });
      await mutate(`form-campaign-assessments@${dependencyHeaders['x-site-id']}`);
      PopAlertSuccess(t('assessments.tableView.successMessages.submissionAlert'));
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('assessments.tableView.errors.submissionAlert.main'));
      } else {
        PopAlertFail(
          t('assessments.tableView.errors.submissionAlert.unexpected'),
          response.message,
        );
      }
    } finally {
      setIsTogglingSubmissionAlert.off();
      onClose();
    }
  };

  const handleActivation = async () => {
    setIsActivating.on();
    try {
      await activateAssessment(assessmentViewItem.id);
      await mutate(`form-campaign-assessments@${dependencyHeaders['x-site-id']}`);
      PopAlertSuccess(t('assessments.tableView.successMessages.activationStatus'));
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('assessments.tableView.errors.activationStatus.main'));
      } else {
        PopAlertFail(
          t('assessments.tableView.errors.activationStatus.unexpected'),
          response.message,
        );
      }
    } finally {
      setIsActivating.off();
      onClose();
    }
  };

  const handleDelete = async () => {
    setIsDeleting.on();
    try {
      await deleteAssessment(assessmentViewItem.id);
      await mutate(`form-campaign-assessments@${dependencyHeaders['x-site-id']}`);
      PopAlertSuccess(t('assessments.tableView.successMessages.delete'));
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('assessments.tableView.errors.delete.main'));
      } else {
        PopAlertFail(t('assessments.tableView.errors.delete.unexpected'), response.message);
      }
    } finally {
      setIsDeleting.off();
      onClose();
    }
  };

  return (
    <ActionsMenu {...props} sx={{ width: '250px' }}>
      <ActionsMenuItem
        label={t('assessments.tableView.menu.items.open')}
        iconName="icon-purpose-outline"
        onClick={handleOpen}
      />
      {assessmentViewItem.manageAssessmentCollaborator && (
        <ActionsMenuItem
          label={t('assessments.tableView.menu.items.editCollaborator')}
          iconName="icon-add-user-outline"
          onClick={handleEditCollaborator}
        />
      )}
      <ActionsMenuItem
        label={
          assessmentViewItem.hasSubmissionAlert
            ? t('assessments.tableView.menu.items.submissionAlertActivated')
            : t('assessments.tableView.menu.items.submissionAlertDeactivated')
        }
        iconName={
          assessmentViewItem.hasSubmissionAlert
            ? 'icon-notification-fill'
            : 'icon-notification-outline'
        }
        onClick={handleSubmissionAlert}
        isLoading={isTogglingSubmissionAlert}
      />
      <ActionsMenuItem
        label={t('assessments.tableView.menu.items.export')}
        iconName="icon-download-outline"
        onClick={() => {
          onExportModalOpen();
          onClose();
        }}
      />
      <ActionsMenuItem
        label={
          assessmentViewItem.activationStatus === AssessmentViewActivationStatus.Active
            ? t('assessments.tableView.menu.items.deactivate')
            : t('assessments.tableView.menu.items.activate')
        }
        iconName={
          assessmentViewItem.activationStatus === AssessmentViewActivationStatus.Active
            ? 'icon-end-process-outline'
            : 'icon-check-mark-outline'
        }
        onClick={handleActivation}
        isLoading={isActivating}
      />
      <ActionsMenuItem
        label={t('assessments.tableView.menu.items.delete')}
        iconName="icon-delete-outline"
        onClick={handleDelete}
        isLoading={isDeleting}
      />
    </ActionsMenu>
  );
};
