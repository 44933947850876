import useSWR from 'swr';

import { HttpMethod } from 'typings/enums/HttpMethod';
import { Audit } from 'models/Audit';
import { apiClient } from 'utils/apiClient';
import { useDatx } from 'hooks/useDatx';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { useCurrentRoom } from './useCurrentRoom';
import { dateSortMethod } from 'utils/dateSortMethod';

export const useFormAudit = (formId: string) => {
  const depHeaders = useDependencyHeaders();

  const datx = useDatx();

  const key = `form-audit-${formId}@${depHeaders['x-site-id']}`;

  const swr = useSWR(
    key,
    async () => {
      const response = await apiClient(`forms/${formId}/audit`, HttpMethod.Get, {
        headers: {
          ...depHeaders,
        },
      });

      const records: Array<Audit> = datx.add(response, Audit);

      return records;
    },
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  return {
    isInitialLoad: !swr.data && !swr.error,
    ...swr,
  };
};

export const useRoomAudit = () => {
  const depHeaders = useDependencyHeaders();

  const { room } = useCurrentRoom();
  const datx = useDatx();

  const key = `room-audit-${room?.id}@${depHeaders['x-site-id']}`;

  const swr = useSWR(
    room?.id ? key : null,
    async () => {
      const response = await apiClient(`rooms/${room?.id}/audit`, HttpMethod.Get, {
        headers: {
          ...depHeaders,
        },
      });

      const records: Array<Audit> = datx.add(response, Audit);

      const sortedData = records.sort((a, b) => dateSortMethod(a.time, b.time));

      return sortedData;
    },
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  return {
    isInitialLoad: !swr.data && !swr.error,
    ...swr,
  };
};

export const useAssessmentAudit = (assessmentId: string) => {
  const depHeaders = useDependencyHeaders();

  const datx = useDatx();

  const key = `assessment-audit-${assessmentId}@${depHeaders['x-site-id']}`;

  const swr = useSWR(
    key,
    async () => {
      const response = await apiClient(`assessments/${assessmentId}/audit`, HttpMethod.Get, {
        headers: {
          ...depHeaders,
        },
      });

      const records: Array<Audit> = datx.add(response, Audit);

      return records;
    },
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  return {
    isInitialLoad: !swr.data && !swr.error,
    ...swr,
  };
};
