export const compareNumberByNumberArrayFn = (arrayA: Array<number>, arrayB: Array<number>) => {
  const minLength = Math.min(arrayA.length, arrayB.length);

  for (let i = 0; i < minLength; i++) {
    const a = arrayA[i];
    const b = arrayB[i];

    if (a > b) {
      return 1;
    }

    if (a < b) {
      return -1;
    }
  }

  if (arrayA.length > arrayB.length) {
    return 1;
  } else if (arrayA.length < arrayB.length) {
    return -1;
  } else {
    return 0;
  }
};
