import { PopAlert } from 'appkit-react';

export const PopAlertSuccess = (message = '') => {
  return PopAlert({
    oneLineDisplay: true,
    duration: 5000,
    status: 'success',
    message: message,
    className: 'alert-fixed alert-fixed-bottom-right appkit-animation animated fadeInRight',
  });
};
