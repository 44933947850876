import { FC } from 'types';
import { IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface IRemoveAddonButtonProps {
  onClick(): void;
}
export const RemoveAddonButton: FC<IRemoveAddonButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <IconButton
      isRound
      size="xs"
      icon={<span className="appkiticon icon-close-outline a-font-12" />}
      onClick={onClick}
      aria-label={t('action.remove.label')}
      sx={{
        position: 'absolute',
        top: '-8px',
        right: '-8px',
        bg: 'brand.orange',
        cursor: 'pointer',
        zIndex: 1000,
        _focus: {
          bg: 'transparent',
        },
        _active: {
          bg: 'transparent',
        },
        _hover: {
          bg: 'brand.orange',
        },
      }}
    />
  );
};
