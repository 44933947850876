export const colors = {
  white: '#ffffff',
  black: '#000000',
  brand: {
    orange: '#e45c2b',
    digitalRose: '#d93954',
    red: '#e0301e',
    yellow: '#ffb600',
    tangerine: '#eb8c00',
  },
  grey: {
    lvl1: '#111111',
    lvl2: '#191919',
    lvl3: '#252525',
    lvl4: '#303030',
    lvl5: '#3b3b3b',
    lvl6: '#474747',
  },
  status: {
    error: '#c52a1a',
    warning: '#ffbf1f',
    success: '#22992e',
  },
  utils: {
    orange: {
      50: '#feb791',
      100: '#fd6412',
      200: '#e45c2b',
      300: '#933401',
      400: '#571f01',
    },
    grey: {
      50: '#f2f2f2',
      100: '#DEDEDE',
      200: '#7D7D7D',
      300: '#2D2D2D',
      400: '#141414',
    },
    rose: {
      50: '#F1BAC3',
      100: '#E27588',
      200: '#D93954',
      300: '#A43E50',
      400: '#6E2A35',
    },
    tangerine: {
      50: '#FFDCA9',
      100: '#FFA929',
      200: '#EB8C00',
      300: '#AE6800',
      400: '#714300',
    },
    red: {
      50: '#f7c8c4',
      100: '#e86153',
      200: '#e0301e',
      300: '#aa2417',
      400: '#741910',
    },
    yellow: {
      50: '#ffecbd',
      100: '#ffc83d',
      200: '#ffb600',
      300: '#c28a00',
      400: '#855f00',
    },
    blue: {
      50: '#b3dcf9',
      100: '#4dacf1',
      200: '#0089eb',
      300: '#0060d7',
      400: '#003dab',
    },
    purple: {
      50: '#deb8ff',
      100: '#b15afe',
      200: '#9013fe',
      300: '#6a1ce2',
      400: '#4b06b2',
    },
    green: {
      50: '#c4fc9f',
      100: '#86db4f',
      200: '#4eb523',
      300: '#2c8646',
      400: '#175c2c',
    },
  },
};
