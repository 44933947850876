import { Box, Heading } from '@chakra-ui/react';
import { FC } from 'types';
import { Fragment } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { FormEditorConclusionGroup } from 'components/form-editor/FormEditorConclusionGroup/FormEditorConclusionGroup';
import { DraggingAction } from 'typings/enums/DraggingAction';

interface IFormEditorConclusionsContainerProps {
  formId?: string;
}

export const FormEditorConclusionsContainer: FC<IFormEditorConclusionsContainerProps> = () => {
  const { t } = useTranslation();
  const { conclusions, dragAction } = useFormEditor();

  return (
    <Fragment>
      <Heading size="lg" mb={8}>
        {t('form.conclusions.title')}
      </Heading>
      <Droppable
        droppableId="conclusions"
        isDropDisabled={dragAction !== DraggingAction.AddConclusion}
      >
        {(provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            bgGradient={snapshot.isDraggingOver ? 'linear(to-b, grey.lvl3, grey.lvl2)' : undefined}
            minHeight="400px"
          >
            {conclusions?.map((conclusion, index) => (
              <FormEditorConclusionGroup key={index} index={index} conclusionGroup={conclusion} />
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </Fragment>
  );
};
