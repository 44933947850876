import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormModal } from 'components/modal/FormModal/FormModal';
import { useAssessmentApproval } from 'hooks/useAssessmentApproval';
import { UpsertAssessmentApprovalDTO } from 'typings/types/dto/ApprovalDTO';
import { PopAlertFail } from 'services/PopAlertFail';
import { LoadingIndicator } from 'components/core/LoadingIndicator/LoadingIndicator';
import { usePopAlertFail } from 'hooks/usePopAlertFail';

import { AssessmentApprovalSettingsForm } from '../AssessmentApprovalSettingsForm/AssessmentApprovalSettingsForm';

interface IApproversModalProps {
  assessmentId: string;
  onClose(): void;
}
export const ApproversModal: FC<IApproversModalProps> = ({ assessmentId, onClose }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { approval, updateApproval, isValidating, error } = useAssessmentApproval(assessmentId);

  usePopAlertFail(error, async () => setTimeout(() => onClose(), 500));

  const handleSubmit = async (upsertDTO: UpsertAssessmentApprovalDTO) => {
    setIsLoading(true);
    await updateApproval(upsertDTO, (response) => {
      const title = t('alerts.fail.title');
      const message = response.message;
      PopAlertFail(title, message);
    });
    setTimeout(() => onClose(), 500);
    setIsLoading(false);
  };
  return (
    <FormModal isOpen maxWidth="1440px" onClose={onClose}>
      {isValidating && <LoadingIndicator />}
      {!isValidating && approval && (
        <AssessmentApprovalSettingsForm
          approval={approval}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onCancel={onClose}
        />
      )}
    </FormModal>
  );
};
