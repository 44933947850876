import React, { Factory } from 'react';
import { Input } from 'appkit-react';
import { TextWidgetProps } from 'react-awesome-query-builder';

import { RuleWrapper } from '../LogicBuilder.elements';

export const AQBText: Factory<TextWidgetProps> = (props) => {
  const onChange = (value?: string) => {
    let val = value;
    if (val === '') val = undefined; // don't allow empty value
    props?.setValue(val);
  };

  return (
    <RuleWrapper>
      <Input
        disabled={props?.readonly}
        placeholder={props?.placeholder}
        defaultValue={props?.value}
        onChange={onChange}
      />
    </RuleWrapper>
  );
};
