import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { HTMLChakraProps } from '@chakra-ui/react';

import { FormActionButton } from 'components/core/FormActions/FormActionButton';

interface IFormButtonCancelProps {
  children?: string;
  onClick(): void;
}
export const FormButtonCancel: FC<IFormButtonCancelProps & HTMLChakraProps<'button'>> = ({
  children,
  onClick,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <FormActionButton kind="secondary" onClick={onClick} {...rest}>
      {children ?? t('action.cancel.label')}
    </FormActionButton>
  );
};
