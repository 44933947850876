const fonts = {
  heading: 'PwC Helvetica Neue',
  body: 'PwC Helvetica Neue',
};

const fontSizes = {
  8: '0.5rem',
  10: '0.625rem',
  12: '0.75rem',
  14: '0.875rem',
  16: '1rem',
  18: '1.125rem',
  22: '1.375rem',
  24: '1.5rem',
  28: '1.75rem',
  32: '2rem',
  40: '2.5rem',
  45: '2.8125rem',
};

const fontWeights = {
  light: '300',
  normal: '400',
  medium: '500',
  bold: '700',
};

export const fontStyles = {
  fonts,
  fontSizes,
  fontWeights,
};
