import { FormComponent } from 'models/FormComponent';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { DraggingAction } from 'typings/enums/DraggingAction';

export function shouldDisableQuestionDrop(
  isPlaceholderActive: boolean,
  dragAction: DraggingAction,
  dragComponentDepth: number,
  currentQuestionSetLevel: number,
): boolean {
  return (
    isPlaceholderActive ||
    currentQuestionSetLevel + dragComponentDepth > 6 || // Business rule: limit nesting question sets to 6
    (dragAction != DraggingAction.AddQuestionComponent && dragAction != DraggingAction.MoveQuestion)
  );
}

export function shouldDisableAddonDrop(
  question: FormComponent,
  isPlaceholderActive: boolean,
  dragAction: DraggingAction,
): boolean {
  if (isPlaceholderActive) {
    return true;
  }
  if (dragAction === DraggingAction.AddAddon) {
    return false;
  }

  if (dragAction === DraggingAction.AddMoreAnswersAddon) {
    if (question.isQuestionSet || question.componentType === FormComponentType.MultipleChoice) {
      return true;
    }

    return (
      !question.isInQuestionSet &&
      (question.componentType === FormComponentType.Confirmation ||
        question.componentType === FormComponentType.ScaleNumber ||
        question.componentType === FormComponentType.ScaleText ||
        question.componentType === FormComponentType.Statement ||
        question.componentType === FormComponentType.QuestionSet)
    );
  }

  if (dragAction === DraggingAction.AddSkipConditionAddon) {
    // Business rule: Skip logic" can only be used for single choice, confirmation and scale.
    return (
      question.componentType !== FormComponentType.Confirmation &&
      question.componentType !== FormComponentType.SingleChoice &&
      question.componentType !== FormComponentType.ScaleNumber &&
      question.componentType !== FormComponentType.ScaleText
    );
  }
  if (dragAction === DraggingAction.AddConditionAddon) {
    // Business rule: "Condition" can be used for all types of questions, except to the first question.
    return Boolean(
      question.parent?.isSection && question.parent.index === 0 && question.index === 0,
    );
  }

  return true;
}
