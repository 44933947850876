import { getModelType, Model } from '@datx/core';

import { HttpMethod } from '../typings/enums/HttpMethod';
import { IFetchOptions } from '../typings/interfaces/IFetchOptions';
import { apiClient } from '../utils/apiClient';
import { buildUrl } from '../utils/buildUrl';

export class Resource extends Model {
  public static endpoint: string | undefined;

  public static create(): IFetchOptions {
    const url = buildUrl([this]);

    return {
      key: url,
      model: getModelType(this),
      fetcher: async (data: Record<string, unknown>, options?: Record<string, unknown>) => {
        await apiClient(url, HttpMethod.Post, {
          body: JSON.stringify(data),
          ...options,
        });

        return [];
      },
    };
  }

  public static delete(id?: string): IFetchOptions {
    const url = buildUrl([this, id]);

    return {
      key: url,
      model: getModelType(this),
      fetcher: (_, options) => apiClient(url, HttpMethod.Delete, options),
    };
  }

  public static update(id?: string): IFetchOptions {
    const url = buildUrl([this, id]);

    return {
      key: url,
      model: getModelType(this),
      fetcher: async (data: Record<string, unknown>, options?: Record<string, unknown>) => {
        await apiClient(url, HttpMethod.Put, {
          body: JSON.stringify(data),
          ...options,
        });

        return [];
      },
    };
  }
}
