import { Box, Input, Table, Tfoot, Thead, Tr, Th, Td, HStack, Spacer } from '@chakra-ui/react';
import { useState, KeyboardEvent, useCallback, Fragment } from 'react';
import { FC } from 'types'
import { Control, FieldValues, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OnDragEndResponder } from 'react-beautiful-dnd';

import { ChoiceQuestionValue, ChoiceValuesFormField } from 'typings/types/FormComponentSettings';
import { RemoveButton } from 'components/form-editor/Question/components/RemoveButton/RemoveButton';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { useSetFocusWithDelay } from 'hooks/useSetFocusWithDelay';
import { FormComponent } from 'models/FormComponent';
import { SkipConditionSelect } from 'components/form-editor/SkipConditionSelect/SkipConditionSelect';
import { DraggableTr } from 'components/draggable-table/DraggableTr/DraggableTr';
import { DraggableTrDragHandle } from 'components/draggable-table/DraggableTrDragHandle/DraggableTrDragHandle';
import { DroppableTBody } from 'components/draggable-table/DroppableTBody/DroppableTBody';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { CheckboxField } from 'fields/CheckboxField/CheckboxField';

interface IChoiceSettingsFormProps {
  question: FormComponent;
  settings: Array<ChoiceQuestionValue>;
  noneOfTheAboveText: string | null | undefined; // text | not defined | single choice component
  skipConditionSettings?: Record<string, string>;
  disabled?: boolean;
  onSubmit(value: Array<ChoiceQuestionValue>, noneOfTheAbove: string | undefined): void;
  onCancel(): void;
}

export const ChoiceSettingsForm: FC<IChoiceSettingsFormProps> = ({
  question,
  settings,
  noneOfTheAboveText,
  skipConditionSettings,
  disabled,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [newOption, setNewOption] = useState<string>('');

  const { elementRef: inputRef, setFocusWithDelay } = useSetFocusWithDelay<HTMLInputElement>();
  const { control, register, handleSubmit } = useForm<ChoiceValuesFormField>({
    defaultValues: {
      choices: settings
        .sort((a, b) => a.index - b.index)
        .map((choice) => ({
          index: choice.index,
          text: choice.value,
          skipTo: skipConditionSettings ? skipConditionSettings[choice.value] || null : undefined,
        })),
      addNoneOfTheAbove: Boolean(noneOfTheAboveText),
      noneOfTheAboveText: noneOfTheAboveText ?? undefined,
    },
  });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'choices',
  });

  const hasAddNoneOfTheAbove = useWatch({
    control,
    name: 'addNoneOfTheAbove',
  });

  const onDragEnd: OnDragEndResponder = useCallback(
    ({ source, destination }) => {
      if (!destination) {
        return;
      }
      move(source.index, destination.index);
    },
    [move],
  );

  const handleSave = (formValue: ChoiceValuesFormField) => {
    const updatedValues = formValue.choices.map((choice, index) => ({
      value: choice.text,
      index,
      skipTo: choice.skipTo,
    }));
    onSubmit(
      updatedValues,
      (formValue.addNoneOfTheAbove && formValue.noneOfTheAboveText) || undefined,
    );
  };

  const handleKeypress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newOption !== '') {
      e.preventDefault();
      append({ text: newOption, index: fields.length });
      setNewOption('');
      setFocusWithDelay();
    }
  };
  const handleKeypressNone = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setFocusWithDelay();
    }
  };

  const isMultipleChoice = question.componentType === FormComponentType.MultipleChoice;
  const hasSkipLogic = Boolean(skipConditionSettings);
  const numberOfColumns = 2 + (hasSkipLogic ? 1 : 0);

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)}>
      <Table size="sm" variant="dragTable">
        <Thead>
          <Tr>
            <Th>{t('form.settings.singleChoice.choicesHeader')}</Th>
            {hasSkipLogic && <Th>{t('form.settings.singleChoice.skipLogicHeader')}</Th>}
            <Th width="140px" />
          </Tr>
        </Thead>

        <DroppableTBody droppableId="choices" droppableType="choices" onDragEnd={onDragEnd}>
          {fields.map((field, index) => (
            <DraggableTr key={field.id} draggableId={`${field.id}`} index={index}>
              <Td>
                <Input
                  variant="unstyled"
                  size="sm"
                  {...register(`choices.${index}.text` as const)}
                />
              </Td>
              {hasSkipLogic && (
                <Td>
                  <SkipConditionSelect
                    variant="transparent"
                    control={control as unknown as Control<FieldValues>}
                    name={`choices.${index}.skipTo` as const}
                    currentComponentOrder={question.order}
                  />
                </Td>
              )}

              <HStack as={Td} spacing={32} mr={24} justifyContent="flex-end">
                <DraggableTrDragHandle />
                <RemoveButton onClick={() => remove(index)} />
              </HStack>
            </DraggableTr>
          ))}
        </DroppableTBody>

        <Tfoot>
          <Tr bgColor={fields.length % 2 === 0 ? 'grey.lvl6' : 'grey.lvl5'}>
            <Td colSpan={numberOfColumns} px={12}>
              <Input
                ref={inputRef}
                size="sm"
                variant="unstyled"
                placeholder={t('form.settings.singleChoice.choicesPlaceholder')}
                value={newOption}
                onChange={(event) => setNewOption(event.target.value)}
                onKeyPress={handleKeypress}
                sx={{
                  _placeholder: {
                    color: 'grey.lvl4',
                  },
                }}
              />
            </Td>
          </Tr>
          {isMultipleChoice && hasAddNoneOfTheAbove && (
            <Tr bgColor={fields.length % 2 === 0 ? 'grey.lvl5' : 'grey.lvl6'}>
              <Td colSpan={numberOfColumns} px={12}>
                <Input
                  {...register(`noneOfTheAboveText`)}
                  size="sm"
                  placeholder={t('form.settings.multipleChoice.noneOfTheAbovePlaceholderText')}
                  onKeyPress={handleKeypressNone}
                  sx={{
                    _placeholder: {
                      color: 'grey.lvl4',
                    },
                  }}
                />
              </Td>
            </Tr>
          )}
        </Tfoot>
      </Table>

      <FormActions>
        {isMultipleChoice && (
          <Fragment>
            <CheckboxField
              control={control as unknown as Control<FieldValues>}
              name="addNoneOfTheAbove"
            >
              {t('form.settings.multipleChoice.noneOfTheAboveCheckboxText')}
            </CheckboxField>
            <Spacer />
          </Fragment>
        )}
        <FormButtonCancel onClick={onCancel} />
        <FormButtonSubmit disabled={disabled} />
      </FormActions>
    </Box>
  );
};
