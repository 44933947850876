import { Attribute } from '@datx/core';
import { computed } from 'mobx';

import { CampaignUserInitiatorStatus } from 'typings/enums/CampaignUserInitiatorStatus';
import { CampaignUserRole } from 'typings/enums/CampaignUserRole';

import { Resource } from './Resource';

export class CampaignUser extends Resource {
  public static type = 'campaign_user';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public userId!: string;

  @Attribute()
  public username!: string;

  @Attribute()
  public email!: string;

  @Attribute()
  public role!: CampaignUserRole;

  @Attribute()
  public defaultInitiatorStatus!: CampaignUserInitiatorStatus; // Should this be readonly?

  @computed
  public get displayName() {
    return this.username === this.email ? this.email : `${this.username} (${this.email})`;
  }

  @computed
  public get updateDTO() {
    return {
      id: this.userId,
      role: this.role,
      defaultInitiatorStatus: this.defaultInitiatorStatus,
    };
  }
}
