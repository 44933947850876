import { Box } from '@chakra-ui/react';
import { Select, SelectOption } from 'appkit-react';
import React from 'react';
import { FC } from 'types'

import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { RoomStatus } from 'typings/enums/RoomStatus';
import { TableCellRow } from 'typings/types/TableCellRow';

import { useRoomApproval } from './StatusColumn.hooks';
import { getOptionsForStatus } from './StatusColumn.utils';

interface IStatusColumnProps {
  rowData: TableCellRow;
}

export const StatusColumn: FC<IStatusColumnProps> = ({ rowData }) => {
  const { approveCreation, approveUpgrade, reject, refresh } = useRoomApproval(rowData.original.id);

  const isPending =
    rowData.value === RoomStatus.CreationApprovalPending ||
    rowData.value === RoomStatus.UpgradeToExternalPending;

  const onStatusChange = async (newValue: RoomStatus) => {
    if (newValue === rowData.original.roomStatus) {
      return;
    }

    let action = null;
    if (isPending) {
      if (
        rowData.original.roomStatus === RoomStatus.CreationApprovalPending &&
        newValue === RoomStatus.Approved
      ) {
        action = approveCreation;
      } else if (
        rowData.original.roomStatus === RoomStatus.UpgradeToExternalPending &&
        newValue === RoomStatus.Approved
      ) {
        action = approveUpgrade;
      } else if (newValue === RoomStatus.Rejected) {
        action = reject;
      }
    }

    try {
      if (!action) {
        throw Error('Invalid room status');
      }
      await action();
      PopAlertSuccess('Success');
      refresh();
    } catch (response: any) { // eslint-disable-line
      PopAlertFail(response.message);
    }
  };

  return (
    <Box width="150px">
      {isPending ? (
        <Select onSelect={onStatusChange} defaultValue={rowData.value} dropdownRenderMode="portal">
          {getOptionsForStatus(rowData.value).map((option) => (
            <SelectOption key={option.value} value={option.value}>
              {option.text}
            </SelectOption>
          ))}
        </Select>
      ) : (
        rowData.value
      )}
    </Box>
  );
};
