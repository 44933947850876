import { useState, KeyboardEvent } from 'react';
import { FC } from 'types'
import { Box, VStack, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { InputField } from 'fields/InputField/InputField';
import { Scrollbar } from 'components/core/ScrollBar/Scrollbar';
import { PermissionGroupsFormGroupsItem } from '../PermissionGroupsFormGroupsItem.tsx/PermissionGroupsFormGroupsItem';
import { CampaignPermissionGroup } from 'models/CampaignPermissionGroup';
import { StyledBadge } from 'components/core/StyledAppKit/StyledAppKit';

interface IPermissionGroupsFormGroupsProps {
  permissionGroups: Array<CampaignPermissionGroup>;
  onDelete(groupId: string): void;
  onAdd(value: string): void;
  onEdit(groupId: string, value: string): void;
}

export const PermissionGroupsFormGroups: FC<IPermissionGroupsFormGroupsProps> = ({
  permissionGroups,
  onDelete,
  onAdd,
  onEdit,
}) => {
  const { t } = useTranslation();
  const [groupNameValue, setGroupNameValue] = useState<string>('');

  const handleKeypress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && groupNameValue === '') {
      e.preventDefault();
    }

    if (e.key === 'Enter' && groupNameValue !== '') {
      e.preventDefault();
      onAdd(groupNameValue);
      setGroupNameValue('');
    }
  };

  return (
    <Box w="100%">
      <InputField
        label={t('campaign.formFields.permissionGroupName')}
        name="permissionGroupName"
        placeholder={t('campaign.formPlaceholders.permissionGroupName')}
        value={groupNameValue}
        onChange={(value: string) => setGroupNameValue(value)}
        onKeyPress={handleKeypress}
      />
      <Flex mb="5px" mt="20px">
        <Text mr="20px">{t('campaign.totalGroups')}</Text>
        <StyledBadge size="small">{permissionGroups.length}</StyledBadge>
      </Flex>
      <Scrollbar height="500px">
        <VStack
          spacing="0"
          width="100%"
          alignItems="start"
          sx={{
            borderTop: ({ colors }) => `2px solid ${colors.grey.lvl3}`,
          }}
        >
          {permissionGroups.map((permissionGroup, index: number) => (
            <PermissionGroupsFormGroupsItem
              key={permissionGroup.id}
              permissionGroup={permissionGroup}
              isDefault={index === 0}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          ))}
        </VStack>
      </Scrollbar>
    </Box>
  );
};
