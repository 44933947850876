import { FC } from 'types';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ConfirmationModal } from 'components/modal/ConfirmationModal/ConfirmationModal';

interface IFolderNameConfirmationModalProps {
  isSaving?: boolean;
  onCancel(): void;
  onConfirm(): void;
}

export const FolderNameConfirmationModal: FC<IFolderNameConfirmationModalProps> = ({
  isSaving = false,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      title={t('room.sidebar.folderDetails.modals.nameConfirmation.title')}
      maxWidth="480px"
      showLoader={isSaving}
      disabled={isSaving}
      confirmText={t('action.save.label')}
      cancelText={t('action.discard.label')}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <Text size="lg">{t('room.sidebar.folderDetails.modals.nameConfirmation.text')}</Text>
    </ConfirmationModal>
  );
};
