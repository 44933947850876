import { Box, Text, BoxProps } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

interface IProps extends BoxProps {
  error: string;
}

export const ConditionAddonSettingsValidation: FC<IProps> = ({ error, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Box {...rest}>
      <Text fontSize={20}>{t('form.settings.condition.validation.title')}</Text>
      <Box bg="grey.lvl3" p="10px" mt={12}>
        <Text fontWeight="bold" mb={12}>
          {t('form.settings.condition.validation.result')}
        </Text>
        <Text mb={12}>{error}</Text>
      </Box>
    </Box>
  );
};
