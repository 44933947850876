import { FC } from 'types';
import { Control, useFieldArray } from 'react-hook-form';
import { Box, BoxProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { User } from 'models/User';
import { UserManagementSearch as Search } from 'components/user-management/UserManagementSearch/UserManagementSearch';
import { userSearchValidation } from 'services/validation/userSearch';

import { ISiteUsersFields } from '../../UserDeleteModal';

import { UserManagementTable } from '../UserManagementTable/UserManagementTable';

interface ISiteUserManagementProps extends BoxProps {
  userEmail: string;
  control: Control<ISiteUsersFields>;
  availableUsers: Array<User> | undefined;
}

export const SiteUserManagement: FC<ISiteUserManagementProps> = ({
  userEmail,
  availableUsers,
  control,
  ...rest
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });
  const { t } = useTranslation();

  const handleAddUser = (email: string) => {
    const selectedUser = availableUsers?.find((user) => user.email === email);
    if (!selectedUser) {
      return;
    }
    append({
      id: selectedUser.id,
      email: selectedUser.email,
      username: selectedUser.username,
      displayName: selectedUser.displayName,
    });
  };

  return (
    <Box {...rest}>
      <Search
        label={t('form.formMembers.modalTitle')}
        placeholder={
          availableUsers ? t('form.formMembers.searchPlaceholder') : t('loading.fetching.data')
        }
        validationRules={userSearchValidation(
          t,
          (value: string) =>
            Boolean(
              availableUsers?.find((user) => user.email === value || user.username === value),
            ),
          (value: string) =>
            Boolean(fields?.find((user) => user.email === value || user.username === value)),
          {
            isSameUser: (value: string) =>
              !(userEmail === value) || (t('form.formMembers.validation.isSameUser') as string),
          },
        )}
        onClick={handleAddUser}
      />
      <UserManagementTable control={control} fields={fields} remove={remove} />
    </Box>
  );
};
