import { Box, HStack, VStack, Flex, useBoolean } from '@chakra-ui/react';
import { FC } from 'types';
import { Button, SelectOption } from 'appkit-react';
import { useForm, Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSWRConfig } from 'swr';

import { User } from 'models/User';
import { useMutate } from 'hooks/useMutate';
import { UserStatus } from 'typings/enums/UserStatus';
import { UserRole } from 'typings/enums/UserRole';
import { ControlledInputField } from 'fields/InputField/InputField';
import { ControlledSelectField } from 'fields/SelectField/SelectField';
import { CalendarPickerField } from 'fields/CalendarPickerField/CalendarPickerField';
import { formatISOToRoundUTCDate } from 'utils/dates';
import { UserDeleteButton } from 'components/site-users/UserDeleteButton/UserDeleteButton';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { SwitchField } from 'fields/SwitchField/SwitchField';

interface IUserManagementFormProps {
  user?: User | null;
  onCancel(): void;
}

interface IFormData {
  username: string;
  email: string;
  thirdPartyUserContact: string;
  domain: string;
  phoneNumber: string;
  expirationDate: string | null;
  role: UserRole;
  status: UserStatus;
  hasLibraryAccess: boolean;
  isSiteAdmin: boolean;
}

export const UserManagementForm: FC<IUserManagementFormProps> = ({ user, onCancel }) => {
  const saveMutation = useMutate(user ? User.update() : User.create(), User.type);
  const deleteMutation = useMutate(User.delete(user?.id), User.type);
  const dependencyHeaders = useDependencyHeaders();
  const { t } = useTranslation();

  const [isSaving, setIsSaving] = useBoolean();
  const [isDeleting, setIsDeleting] = useBoolean();
  const { mutate: globalMutate } = useSWRConfig();

  const { handleSubmit, control, setValue, setError } = useForm<IFormData>({
    defaultValues: {
      username: user?.username || '',
      phoneNumber: user?.phoneNumber || '',
      role: user?.role || UserRole.SiteAdmin,
      isSiteAdmin: user?.role === UserRole.SiteAdmin ? true : false,
      thirdPartyUserContact: user?.thirdPartyUserContact || '',
      email: user?.email || '',
      domain: user?.domain.name || '',
      status: user?.status || UserStatus.Active,
      expirationDate: user?.expirationDate || '',
      hasLibraryAccess: user?.hasLibraryAccess || false,
    },
  });

  const onSubmit = async (values: IFormData) => {
    setIsSaving.on();
    try {
      await saveMutation.mutate({
        ...values,
        domain: values.domain || undefined,
        expirationDate: values.expirationDate || null,
        id: user?.id,
      });
      await globalMutate(`sites/${dependencyHeaders['x-site-id']}`);
      await globalMutate('tenants');
      PopAlertSuccess(
        user?.id
          ? t('siteAdmin.user.modal.successMessages.updated', { name: values.username })
          : t('siteAdmin.user.modal.successMessages.created', { name: values.username }),
      );
      onCancel();
    } catch (response: any) { // eslint-disable-line
      Object.keys(response.errors).forEach((fieldName) => {
        setError(fieldName as keyof IFormData, { message: response.errors[fieldName][0] });
      });
    } finally {
      setIsSaving.off();
    }
  };

  const onDeleteWithoutReplace = async () => {
    setIsDeleting.on();
    try {
      await deleteMutation.mutate();
      PopAlertSuccess(t('siteAdmin.user.modal.successMessages.deleted', { name: user?.username }));
      onCancel();
    } catch (response: any) { // eslint-disable-line
      PopAlertFail(t('siteAdmin.errors.user.delete'));
    } finally {
      setIsDeleting.off();
    }
  };

  const onDeleteWithReplace = async (newUserIds: Array<string>) => {
    setIsDeleting.on();
    try {
      await apiClient(`users/${user?.id}/replace`, HttpMethod.Delete, {
        headers: {
          'content-type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify({
          newUserIds,
        }),
      });
      PopAlertSuccess(t('siteAdmin.user.modal.successMessages.deleted', { name: user?.username }));
      globalMutate(`users@${dependencyHeaders['x-site-id']}`);
      onCancel();
    } catch (response: any) { // eslint-disable-line
      PopAlertFail(t('siteAdmin.errors.user.delete'));
    } finally {
      setIsDeleting.off();
    }
  };

  const handleDatePickerChange = (value: Date | null) => {
    setValue('expirationDate', formatISOToRoundUTCDate(value));
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} mt={20}>
      <VStack align="stretch" spacing="20px">
        <HStack spacing="86px">
          <ControlledInputField
            sx={{ width: '100%' }}
            control={control as unknown as Control<FieldValues>}
            label={t('siteAdmin.user.modal.form.name.label').toUpperCase()}
            id="username"
            htmlFor="username"
            name="username"
            placeholder={t('siteAdmin.user.modal.form.name.placeholder')}
            defaultValue={user?.username}
            rules={{ required: { value: true, message: t('validation.required') } }}
          />
          <ControlledInputField
            sx={{ width: '100%' }}
            control={control as unknown as Control<FieldValues>}
            label={t('siteAdmin.user.modal.form.email.label').toUpperCase()}
            id="email"
            htmlFor="email"
            name="email"
            placeholder={t('siteAdmin.user.modal.form.email.placeholder')}
            defaultValue={user?.email}
            rules={{
              pattern: {
                value: /^\w[\w.-]*@([\w-]+\.)+[\w-]+$/,
                message: t('validation.validEmail'),
              },
            }}
          />
        </HStack>
        <HStack spacing="86px">
          <Flex width="100%" alignItems="flex-end">
            <ControlledInputField
              sx={{ flex: '2', mr: '20px' }}
              control={control as unknown as Control<FieldValues>}
              label={t('siteAdmin.user.modal.form.role.label').toUpperCase()}
              id="role"
              htmlFor="role"
              name="role"
              placeholder={t('siteAdmin.user.modal.form.role.placeholder')}
              disabled
              defaultValue={user?.formattedRole}
            />
            <Box>
              <SwitchField
                control={control as unknown as Control<FieldValues>}
                name="isSiteAdmin"
                label={t('siteAdmin.user.modal.form.role.switch.label')}
                defaultChecked={user?.role === UserRole.SiteAdmin}
              />
            </Box>
          </Flex>
          <ControlledInputField
            sx={{ width: '100%' }}
            control={control as unknown as Control<FieldValues>}
            label={t('siteAdmin.user.modal.form.phone.label').toUpperCase()}
            id="phoneNumber"
            htmlFor="phoneNumber"
            name="phoneNumber"
            placeholder={t('siteAdmin.user.modal.form.phone.placeholder')}
            defaultValue={user?.phoneNumber}
            rules={{ required: { value: true, message: t('validation.required') } }}
          />
        </HStack>
        <HStack spacing="86px" alignItems="flex-end">
          <ControlledInputField
            sx={{ width: '100%' }}
            control={control as unknown as Control<FieldValues>}
            label={t('siteAdmin.user.modal.form.thirdParty.label').toUpperCase()}
            id="thirdPartyUserContact"
            htmlFor="thirdPartyUserContact"
            name="thirdPartyUserContact"
            placeholder={t('siteAdmin.user.modal.form.thirdParty.placeholder')}
            defaultValue={user?.thirdPartyUserContact}
          />
          <ControlledInputField
            sx={{ width: '100%' }}
            control={control as unknown as Control<FieldValues>}
            label={t('siteAdmin.user.modal.form.domain.label').toUpperCase()}
            id="domain"
            htmlFor="domain"
            name="domain"
            placeholder={t('siteAdmin.user.modal.form.domain.placeholder')}
            disabled
            defaultValue={user?.domain.name}
          />
        </HStack>
        <HStack spacing="86px" align="flex-end">
          <ControlledSelectField
            control={control as unknown as Control<FieldValues>}
            name="status"
            label={t('siteAdmin.user.modal.form.status.label').toUpperCase()}
            defaultValue={user?.status || UserStatus.Active}
            placeholder={t('siteAdmin.user.modal.form.status.placeholder')}
          >
            <SelectOption value={UserStatus.Active}>
              {t('siteAdmin.user.modal.form.status.select.activeOption')}
            </SelectOption>
            <SelectOption value={UserStatus.Inactive}>
              {t('siteAdmin.user.modal.form.status.select.inactiveOption')}
            </SelectOption>
          </ControlledSelectField>
          <CalendarPickerField
            sx={{ width: '100%' }}
            label={t('siteAdmin.user.modal.form.expirationDate.label').toUpperCase()}
            name="expirationDate"
            placeholder={t('siteAdmin.user.modal.form.expirationDate.placeholder')}
            minDate={new Date()}
            defaultValue={user?.expirationDate ? new Date(user.expirationDate) : null}
            onChange={handleDatePickerChange}
          />
        </HStack>
      </VStack>
      <HStack align="center" spacing="86px" mt={40} pb={20}>
        <Box width="100%">
          {user && (
            <UserDeleteButton
              userEmail={user.email}
              disabled={isSaving || isDeleting}
              isLoading={isDeleting}
              onDeleteWithoutReplace={onDeleteWithoutReplace}
              onDeleteWithReplace={onDeleteWithReplace}
            />
          )}
        </Box>
        <Flex width="100%">
          <Box flex={2}>
            <SwitchField
              control={control as unknown as Control<FieldValues>}
              name="hasLibraryAccess"
              label={t('siteAdmin.user.modal.form.libraryAccess.switch.label')}
              defaultChecked={user?.hasLibraryAccess}
            />
          </Box>
          <Button
            kind="secondary"
            size="lg"
            type="button"
            disabled={isSaving || isDeleting}
            onClick={onCancel}
          >
            {t('action.cancel.label').toUpperCase()}
          </Button>
          <Button
            size="lg"
            className="a-ml-10"
            type="submit"
            isLoading={isSaving}
            disabled={isDeleting || isSaving}
          >
            {t('action.save.label').toUpperCase()}
          </Button>
        </Flex>
      </HStack>
    </Box>
  );
};
