import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PopAlertFail } from 'services/PopAlertFail';
import { IApiClientError } from 'typings/interfaces/IApiClientError';
import { parseApiClientError } from 'utils/parseApiClientError';

export const usePopAlertFail = (error: IApiClientError | undefined, callback?: () => void) => {
  const { t } = useTranslation();
  const callbackRef = useRef(callback);

  useEffect(() => {
    if (error) {
      const parsedError = parseApiClientError(error, t);
      PopAlertFail(parsedError?.message, parsedError?.errors);
      callbackRef.current?.();
    }
  }, [error, t]);
};
