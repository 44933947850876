import { FC } from 'types';
import { Fragment } from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { Modal } from 'components/modal/Modal/Modal';
import { SiteManagementForm } from 'forms/ApplicationAdmin/SiteManagementForm/SiteManagementForm';
import { Site } from 'models/Site';
import { AbsoluteLoader } from 'components/core/LoadingIndicator/AbsoluteLoader';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { useDatx } from 'hooks/useDatx';

interface ISiteManagementModalProps {
  siteId: string | null;
  onCancel(): void;
  onSiteCreated(siteName: string): void;
}

export const SiteManagementModal: FC<ISiteManagementModalProps> = ({
  siteId,
  onCancel,
  onSiteCreated,
  ...rest
}) => {
  const datx = useDatx();
  const { t } = useTranslation();

  // TODO: Replace this with new reusable hook in the future
  const { data, isValidating } = useSWR(
    siteId ? `sites/${siteId}` : null,
    async () => {
      const response = await apiClient(`sites/${siteId}`, HttpMethod.Get);
      const model = datx.add(response, Site) as unknown as Site;

      return model;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  // TODO: show popup error message (after hook refactor)

  const formKey = `${siteId}/${Boolean(data)}/${Boolean(isValidating)}`;

  return (
    <Modal isVisible sx={{ maxWidth: '1160px', width: '100%', mx: 'auto', mt: '10%' }} {...rest}>
      <ModalHeader mb={20} onCancel={onCancel}>
        <ModalTitle>{t('applicationAdmin.site.modal.title')}</ModalTitle>
      </ModalHeader>
      <ModalBody sx={{ px: 40 }}>
        <Fragment>
          {isValidating && <AbsoluteLoader />}
          <SiteManagementForm
            key={formKey}
            site={data}
            onCancel={onCancel}
            onSiteCreated={onSiteCreated}
          />
        </Fragment>
      </ModalBody>
    </Modal>
  );
};
