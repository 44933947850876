import { Attribute } from '@datx/core';
import { FormConclusion } from './FormConclusion';

import { Resource } from './Resource';

export class FormConclusionGroup extends Resource {
  public static type = 'form_conclusion_group';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute({ toMany: FormConclusion })
  public conclusions!: Array<FormConclusion>;
}
