import useSWR, { SWRConfiguration } from 'swr';

import { Resource } from 'models/Resource';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { getModelUrl } from 'utils/getModelUrl';
import { getResourceTransform } from 'services/transformDto/getResourceTransform';

import { useDatx } from './useDatx';

export const useResourcesWithoutSiteId = (model: typeof Resource, config?: SWRConfiguration) => {
  const datx = useDatx();
  const url = getModelUrl(model);

  const transformData = getResourceTransform(model);

  const swr = useSWR(
    url,
    () =>
      apiClient(url, HttpMethod.Get, {
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      ...config,
    },
  );

  const isInitialLoad = !swr.data && !swr.error;

  let data: Array<Resource> = [];

  if (swr.data) {
    data = datx.add(transformData(swr.data), model.type);
  }

  return {
    ...swr,
    data,
    isInitialLoad,
  };
};
