import { isModel } from '@datx/core';
import qs from 'query-string';

import { Resource } from '../models/Resource';
import { UseResource } from '../typings/types/UseResource';
import { getModelUrl } from './getModelUrl';

export const buildUrl = (
  requirements: UseResource,
  queryParams?: Record<string, unknown>,
): string => {
  const url = requirements
    .filter(Boolean)
    .map((item) => (isModel(item) ? getModelUrl(item as typeof Resource) : item))
    .join('/');

  if (queryParams) {
    const parsedParams = qs.stringify(queryParams);

    return `${url}?${parsedParams}`;
  }

  return url;
};
