import { Box, HStack, Text, Spacer, BoxProps } from '@chakra-ui/react';
import { FC } from 'types';

import { Avatar } from 'components/core/Avatar/Avatar';
import { WordBreakingText } from 'components/core/WordBreakingText/WordBreakingText';
import { RichTextViewer } from 'components/core/RichTextViewer/RichTextViewer';
import { Comment } from 'models/Comment';

interface IReviewerCommentProps extends BoxProps {
  model: Comment;
}

export const ReviewerComment: FC<IReviewerCommentProps> = ({ model, ...rest }) => {
  return (
    <Box width="100%" {...rest}>
      <HStack spacing={16} alignItems="center" mb={12}>
        <Avatar name={model.user.username} />
        <WordBreakingText breakAt="@">{model.user.username}</WordBreakingText>
        <Spacer flex={1} />
        <Text size="sm" pr={8}>
          {model.formattedCreatedAt}
        </Text>
      </HStack>
      <RichTextViewer px={12} dangerouslySetInnerHTML={{ __html: model.comment }} />
    </Box>
  );
};
