import { Tooltip } from 'appkit-react';
import { ReactNode } from 'react';
import { FC } from 'types'

interface IInfoTooltipProps {
  content?: string | ReactNode;
}
export const InfoTooltip: FC<IInfoTooltipProps> = ({ content, children }) => {
  return (
    <Tooltip content={content ?? children}>
      <span className="appkiticon icon-information-fill a-font-16 a-text-warning" />
    </Tooltip>
  );
};
