import { FormControl, FormControlProps } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { FC } from 'types';
import { Checkbox } from 'appkit-react';
import { useController, UseControllerProps } from 'react-hook-form';

import { FormFieldError } from 'components/core/FormFieldError/FormFieldError';

interface ICheckboxFieldProps
  extends UseControllerProps,
    Omit<FormControlProps, 'onChange' | 'defaultValue'> {
  disabled?: boolean;
  readonly?: boolean;
  label?: string;
}

export const CheckboxField: FC<ICheckboxFieldProps> = ({
  control,
  name,
  rules,
  sx,
  children,
  ...rest
}) => {
  const {
    field: { value, onChange, ...restField },
    fieldState: { error },
  } = useController({ control, name, rules });

  return (
    <FormControl sx={sx}>
      <Checkbox
        defaultChecked={value}
        onChange={(e: ChangeEvent<HTMLInputElement>, value: string) =>
          onChange(e.target.checked, value)
        }
        {...restField}
        {...rest}
      >
        {children}
      </Checkbox>
      {error && control && <FormFieldError name={name} control={control} />}
    </FormControl>
  );
};
