import { FC } from 'types';
import { Control, Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HStack, Box, useBoolean } from '@chakra-ui/react';

import { ModalFooter } from 'components/modal/Modal/components/ModalFooter';
import { NotificationTextFormField } from 'components/core/NotificationTextFormField/NotificationTextFormField';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { ControlledInputField } from 'fields/InputField/InputField';
import { ControlledTextAreaField } from 'fields/TextareaField/TextareaField';
import { useMutate } from 'hooks/useMutate';
import { Room } from 'models/Room';
import { RoomType } from 'typings/enums/RoomType';
import { ICreateRoomFields } from 'typings/interfaces/ICreateRoomFields';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';

import { CreateRoomUserManagement } from '../CreateRoomUserManagement/CreateRoomUserManagement';
import { CreateRoomFormTypes } from './components/CreateRoomFormTypes/CreateRoomFormTypes';

interface ICreateRoomFormProps {
  onSuccess(): void;
  onClose(): void;
}

export const CreateRoomForm: FC<ICreateRoomFormProps> = ({ onSuccess, onClose }) => {
  const [isSaving, setIsSaving] = useBoolean();
  const { t } = useTranslation();

  const saveMutation = useMutate(Room.create(), Room.type);

  const { handleSubmit, control, watch, setError } = useForm<ICreateRoomFields>({
    defaultValues: {
      roomType: RoomType.Internal,
      name: '',
      description: '',
      roomUsers: [],
      skipSendingNotification: false,
      notificationText: '',
    },
  });
  const watchRoomType = watch('roomType');

  const onSubmit = async (values: ICreateRoomFields) => {
    setIsSaving.on();
    try {
      await saveMutation.mutate({
        ...values,
      });
      PopAlertSuccess(t('rooms.createRoom.successMessages.createdRoom', { name: values.name }));
      onSuccess();
    } catch (response: any) {
      // eslint-disable-line
      if (response.errors) {
        Object.keys(response.errors).forEach((fieldName) => {
          setError(fieldName as keyof ICreateRoomFields, {
            message: response.errors[fieldName][0],
          });
        });
      } else {
        PopAlertFail(t('rooms.errors.createRoom.main'));
      }
    } finally {
      setIsSaving.off();
    }
  };

  return (
    // @ts-ignore
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <HStack spacing="80px" align="flex-start">
        <Box width="35%">
          <Controller
            control={control}
            name="roomType"
            render={({ field: { onChange, value } }) => {
              return <CreateRoomFormTypes activeType={value} onChange={onChange} />;
            }}
          />
          <ControlledInputField
            sx={{ my: '20px' }}
            control={control as unknown as Control<FieldValues>}
            label={t('rooms.createRoom.form.name.label').toUpperCase()}
            id="name"
            htmlFor="name"
            name="name"
            placeholder={t('rooms.createRoom.form.name.placeholder')}
            rules={{
              required: { value: true, message: t('validation.required') },
              maxLength: {
                value: 50,
                message: t('rooms.createRoom.form.name.validation.maxLength'),
              },
            }}
          />
          <ControlledTextAreaField
            sx={{ textarea: { height: '125px' } }}
            control={control as unknown as Control<FieldValues>}
            label={t('rooms.createRoom.form.description.label').toUpperCase()}
            id="description"
            name="description"
            placeholder={t('rooms.createRoom.form.name.placeholder')}
          />
        </Box>
        <CreateRoomUserManagement control={control} roomType={watchRoomType} />
      </HStack>
      <NotificationTextFormField
        control={control as unknown as Control<FieldValues>}
        nameTextEditor="notificationText"
        nameCheckBox="skipSendingNotification"
        mb={20}
        mt={36}
      />
      <ModalFooter pb={20} pr={0}>
        <FormButtonCancel onClick={onClose} disabled={isSaving} />
        <FormButtonSubmit isLoading={isSaving} disabled={isSaving} ml={20}>
          {t('action.create.label').toUpperCase()}
        </FormButtonSubmit>
      </ModalFooter>
    </Box>
  );
};
