import { Attribute } from '@datx/core';
import { format } from 'date-fns';
import { computed } from 'mobx';

import { FolderUserRole } from 'typings/enums/FolderUserRole';
import { UpsertRoomFolderDTO } from 'typings/types/dto/RoomFolderDTO';
import { Form } from './Form';

import { Resource } from './Resource';
import { RoomFolderUser } from './RoomFolderUser';

export class RoomFolder extends Resource {
  public static type = 'roomfolders';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public name!: string;

  @Attribute()
  public isInRoot!: boolean;

  @Attribute()
  public lastEditedUtc!: string;

  @Attribute({ toMany: 'forms' })
  public forms!: Array<Form>;

  @Attribute({ toMany: 'roomfolders' })
  public childRoomFolders!: Array<RoomFolder>;

  @Attribute({ toMany: 'room_folders_users' })
  public users!: Array<RoomFolderUser>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Attribute({ toOne: 'roomfolders', parse: (_, data: Record<string, any>) => data.parentFolderId })
  public parentFolder!: RoomFolder;

  @computed
  public get folderOwner() {
    return this.users.find((user) => user.folderUserRole === FolderUserRole.Owner);
  }

  @computed
  public get parentFolderId() {
    return this.parentFolder.id;
  }

  @computed
  public get ownerUsername() {
    return this.folderOwner?.user?.username;
  }

  @computed
  public get isFolderOwner() {
    return this.users;
  }

  @computed
  public get numberOfUsers() {
    return this.users ? this.users.length : 0;
  }

  @computed
  public get formattedLastEdit() {
    if (this.lastEditedUtc) {
      return format(new Date(this.lastEditedUtc), 'PPp');
    }

    return '/';
  }

  @computed
  public get folderPath(): Array<string> {
    return this.parentFolder ? [...this.parentFolder.folderPath, this.id] : [this.id];
  }

  @computed
  public get ancestorNamesAndIds(): Array<{
    folderId: string;
    folderName: string;
    folderPath: Array<string>;
  }> {
    const parentArray = this.parentFolder ? this.parentFolder.ancestorNamesAndIds : [];

    return [
      ...parentArray,
      { folderId: this.id, folderName: this.name, folderPath: this.folderPath },
    ];
  }

  @computed
  public get upsertDTO(): UpsertRoomFolderDTO {
    return {
      parentFolderId: this.parentFolderId,
      name: this.name,
      users: this.users.map((user) => user.upsertDTO),
    };
  }

  public userIsOwner(email: string) {
    return this.folderOwner?.email?.toLowerCase() === email;
  }
}
