import { FC } from 'types';

import { CampaignResponse } from 'models/CampaignResponse';
import { FormCampaignsTableViewCellName } from '../FormCampaignsTableViewCellName/FormCampaignsTableViewCellName';

interface IFormCampaignsTableViewCellNameWrapperProps {
  original: CampaignResponse;
}

export const FormCampaignsTableViewCellNameWrapper: FC<IFormCampaignsTableViewCellNameWrapperProps> =
  ({ original }) => {
    return <FormCampaignsTableViewCellName campaignViewItem={original as CampaignResponse} />;
  };
