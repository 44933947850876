import { createContext, useContext } from 'react';
import { FC } from 'types';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

interface IDraggableTrContext {
  dragHandleProps: DraggableProvidedDragHandleProps | undefined;
}
const DraggableTrContext = createContext<IDraggableTrContext | undefined>(undefined);

interface IDraggableTrProviderProps {
  dragHandleProps: DraggableProvidedDragHandleProps | undefined;
}
const DraggableTrProvider: FC<IDraggableTrProviderProps> = ({ dragHandleProps, children }) => {
  return (
    <DraggableTrContext.Provider value={{ dragHandleProps }}>
      {children}
    </DraggableTrContext.Provider>
  );
};

const useDraggableTr = () => {
  const context = useContext(DraggableTrContext);
  if (context === undefined) {
    throw new Error('useDraggableTr must be used within a DraggableTr');
  }

  return context;
};

export { DraggableTrProvider, useDraggableTr };
