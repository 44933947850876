import { FC } from 'types';
import { useHover } from 'react-use';
import { Box } from '@chakra-ui/react';

import { FormComponent } from 'models/FormComponent';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { IFormComponentAddonDto } from 'typings/interfaces/dto/IFormComponentAddonDto';

import { RemoveAddonButton } from '../RemoveAddonButton/RemoveAddonButton';
import { NoSettingsAddon } from '../NoSettingsAddon/NoSettingsAddon';

import { getAddon } from './QuestionAddonButton.utils';

interface IQuestionAddonButtonProps {
  addon: IFormComponentAddonDto;
  question: FormComponent;
}
export const QuestionAddonButton: FC<IQuestionAddonButtonProps> = ({ addon, question }) => {
  const { updateComponent, disableEdit } = useFormEditor();
  const AddonButton = getAddon(addon.addonType);

  const handleRemoveAddon = async () => {
    if (disableEdit) {
      return;
    }
    const addons = question.addons.filter(
      (questionAddon) => questionAddon.addonType !== addon.addonType,
    );
    question.addons = addons;
    await updateComponent(question);
  };

  const [hoverable] = useHover((hovered: boolean) => (
    <Box position="relative">
      {AddonButton ? (
        <AddonButton addon={addon} question={question} />
      ) : (
        <NoSettingsAddon addonType={addon.addonType} />
      )}
      {hovered && <RemoveAddonButton onClick={handleRemoveAddon} />}
    </Box>
  ));

  return hoverable;
};
