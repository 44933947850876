import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import { Control, FieldValues, useForm, useWatch } from 'react-hook-form';

import { FormModal } from 'components/modal/FormModal/FormModal';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { useTenant } from 'hooks/useTenant';
import { CheckboxField } from 'fields/CheckboxField/CheckboxField';

import {
  exportOptions,
  FormExportFormValues,
  handleFormExcelExport,
  handleFormJsonExport,
} from './FormExportModal.utils';

interface IFormExportModalProps {
  formId: string;
  onClose(): void;
}
export const FormExportModal: FC<IFormExportModalProps> = ({ formId, onClose }) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormExportFormValues>({
    defaultValues: {
      formJson: true,
      formXlsx: true,
    },
  });

  const watchAll = useWatch({ control });
  const anySelected = Object.values(watchAll).some((value) => value);

  const { t } = useTranslation();
  const siteId = useTenant().activeTenant?.siteId;

  const handleExport = async (formValues: FormExportFormValues) => {
    if (!siteId || !anySelected) {
      return;
    }
    await Promise.allSettled([
      formValues.formXlsx && handleFormExcelExport(t, siteId, formId),
      formValues.formJson && handleFormJsonExport(t, siteId, formId),
    ]);
    onClose();
  };

  return (
    <FormModal isOpen maxWidth="500px" title={t('export.modal.title.form')} onClose={onClose}>
      <Box as="form" onSubmit={handleSubmit(handleExport)}>
        {exportOptions.map((option) => (
          <CheckboxField
            key={option.value}
            control={control as unknown as Control<FieldValues>}
            name={option.value}
            className="a-mb-20"
            disabled={option.disabled}
          >
            {t(option.label)}
          </CheckboxField>
        ))}
        <FormActions>
          <FormButtonCancel onClick={onClose} />
          <FormButtonSubmit
            isLoading={isSubmitting || !siteId}
            disabled={!siteId || !anySelected || isSubmitting}
          >
            {t('action.export.label')}
          </FormButtonSubmit>
        </FormActions>
      </Box>
    </FormModal>
  );
};
