import { useResources } from 'hooks/useResources';
import { useRoomFolderMatch } from 'hooks/useRoomFolderMatch';
import { RoomFolder } from 'models/RoomFolder';

export const useCurrentRoomFolders = () => {
  const match = useRoomFolderMatch();

  RoomFolder.endpoint = `rooms/${match?.params.roomId}/roomfolders`;
  const result = useResources(RoomFolder);
  RoomFolder.endpoint = undefined;

  return result;
};
