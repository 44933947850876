import { FC } from 'types';
import { Fragment } from 'react';

import { AssessmentQuestion } from 'components/assessment/AssessmentQuestion/AssessmentQuestion';
import { AssessmentFormComponent } from 'models/AssessmentFormComponent';

import { useAssessmentSetQuestions } from './AssessmentQuestionSetField.hooks';

interface IAssessmentQuestionSetFieldProps {
  disabled?: boolean;
  questionSet: AssessmentFormComponent;
}

export const AssessmentQuestionSetField: FC<IAssessmentQuestionSetFieldProps> = ({
  questionSet,
  disabled,
}) => {
  const questions = useAssessmentSetQuestions(questionSet.id);

  return (
    <Fragment>
      {questions.map(({ component, readonly }) => (
        <AssessmentQuestion
          key={component.id}
          question={component}
          disabled={disabled || readonly}
        />
      ))}
    </Fragment>
  );
};
