import { FC } from 'types';
import { ButtonGroupItem } from 'appkit-react';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormUserInitiatorStatus } from 'typings/enums/FormUserInitiatorStatus';
import { ButtonGroupField } from 'fields/ButtonGroupField/ButtonGroupField';
import { IFormUserManagementFields } from 'typings/interfaces/IFormUserManagementFields';
import { IButtonGroupProps } from 'typings/appkit-react/IButtonGroupProps';

interface IFormUserDefaultInitiatorButtonGroupFieldProps extends IButtonGroupProps {
  name: string;
  control: Control<IFormUserManagementFields>;
}
export const FormUserDefaultInitiatorButtonGroupField: FC<IFormUserDefaultInitiatorButtonGroupFieldProps> =
  ({ control, name, ...rest }) => {
    const { t } = useTranslation();

    return (
      <ButtonGroupField
        control={control as unknown as Control<FieldValues>}
        name={name}
        size="md"
        {...rest}
      >
        <ButtonGroupItem data-value={FormUserInitiatorStatus.Na}>
          {t('form.formMembers.initiatorStatus.none').toUpperCase()}
        </ButtonGroupItem>
        <ButtonGroupItem data-value={FormUserInitiatorStatus.Optional}>
          {t('form.formMembers.initiatorStatus.optional').toUpperCase()}
        </ButtonGroupItem>
        <ButtonGroupItem data-value={FormUserInitiatorStatus.Mandatory}>
          {t('form.formMembers.initiatorStatus.mandatory').toUpperCase()}
        </ButtonGroupItem>
      </ButtonGroupField>
    );
  };
