import { FC } from 'types';
import { ConclusionActionButton } from '../ConclusionActionButton/ConclusionActionButton';

interface IConclusionCloseButtonProps {
  onClick(): void;
}

export const ConclusionCloseButton: FC<IConclusionCloseButtonProps> = ({ onClick }) => {
  return <ConclusionActionButton icon="icon-close-outline" label="Remove" onClick={onClick} />;
};
