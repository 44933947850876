import { useDisclosure } from '@chakra-ui/react';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { useClickAway } from 'react-use';

export const useMenuActions = (
  targetRef: RefObject<HTMLElement>,
  containerRef: RefObject<HTMLElement>,
) => {
  const [menuPosition, setMenuPosition] = useState<{ x: number; y: number }>();
  const { isOpen, onClose, onOpen } = useDisclosure();

  useClickAway(containerRef, () => {
    onClose();
  });

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      onOpen();
      setMenuPosition({ x: event.clientX, y: event.clientY });

      return false;
    },
    [onOpen],
  );

  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.oncontextmenu = handleContextMenu;
    }
  }, [handleContextMenu, targetRef]);

  return {
    isOpen,
    onClose,
    menuPosition,
  };
};
