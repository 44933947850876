import { FC } from 'types';
import { useState } from 'react';

import { FormModal } from 'components/modal/FormModal/FormModal';
import { useAssessment, useUpdateCollaborators } from 'hooks/useAssessment';
import { UpsertAssessmentCollaboratorsDTO } from 'typings/types/dto/AssessmentDTO';
import { LoadingIndicator } from 'components/core/LoadingIndicator/LoadingIndicator';
import { usePopAlertFail } from 'hooks/usePopAlertFail';

import { CollaboratorsForm } from '../CollaboratorsForm/CollaboratorsForm';

interface ICollaboratorsModalProps {
  assessmentId: string;
  onClose(): void;
}
export const CollaboratorsModal: FC<ICollaboratorsModalProps> = ({ assessmentId, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { updateAssessmentCollaborators } = useUpdateCollaborators(assessmentId);
  const { assessment, isValidating, error } = useAssessment(assessmentId);
  usePopAlertFail(error, async () => setTimeout(() => onClose(), 500));

  const handleSubmit = async (upsertDTO: UpsertAssessmentCollaboratorsDTO) => {
    setIsLoading(true);
    await updateAssessmentCollaborators(upsertDTO);
    setTimeout(() => onClose(), 500);
    setIsLoading(false);
  };

  return (
    <FormModal isOpen maxWidth="1400px" onClose={onClose}>
      {isValidating && <LoadingIndicator />}
      {!isValidating && assessment && (
        <CollaboratorsForm
          assessment={assessment}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onCancel={onClose}
        />
      )}
    </FormModal>
  );
};
