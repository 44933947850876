export enum FormComponentType {
  Confirmation = 'Confirmation',
  Country = 'Country',
  Date = 'Date',
  EmailAddress = 'EmailAddress',
  FreeText = 'FreeText',
  SingleChoice = 'SingleChoice',
  MultipleChoice = 'MultipleChoice',
  Name = 'Name',
  Number = 'Number',
  QuestionSet = 'QuestionSet',
  ScaleNumber = 'ScaleNumber', // Scale is split to number and text on BE
  ScaleText = 'ScaleText', // Scale is split to number and text on BE
  Section = 'Section',
  Statement = 'Statement',
}
