import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { FormConclusion } from 'models/FormConclusion';
import { useDatx } from './useDatx';
import { useDependencyHeaders } from './useDependencyHeaders';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { FormConclusionType } from 'typings/enums/FormConclusionType';
import { ConditionRuleOperator } from 'typings/enums/ConditionRuleOperator';
import { FormConclusionGroup } from 'models/FormConclusionGroup';
import { ParseAndPopAlertFail } from 'services/ParseAndPopAlertFail';
import { isApiClientError } from 'utils/isApiClientError';

export const useConclusionWithMutations = (
  roomId: string,
  formId: string,
  setIsLoading: (isLoading: boolean) => void,
) => {
  const { t } = useTranslation();
  const dependencyHeaders = useDependencyHeaders();
  const datx = useDatx();

  const siteId = dependencyHeaders['x-site-id'];
  const revalidateKey = siteId ? `forms-${formId}-conclusions@${siteId}` : null;
  const url = `rooms/${roomId}/forms/${formId}/conclusions`;

  const swr = useSWR(
    formId ? revalidateKey : null,
    async () => {
      const response = await apiClient(url, HttpMethod.Get, {
        headers: {
          ...dependencyHeaders,
        },
      });

      if (response?.length) {
        return datx.add(response, FormConclusionGroup);
      }

      return [];
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  const addConclusionGroup = async () => {
    setIsLoading(true);
    const initialConclusion = datx.add<FormConclusion>(
      {
        conclusionType: FormConclusionType.Inclusive,
        conditionGroup: {
          operator: ConditionRuleOperator.And,
          conditionRules: [],
          conditionRuleGroups: [],
        },
      },
      FormConclusion,
    );

    try {
      await apiClient(url, HttpMethod.Post, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify({ conclusions: [{ ...initialConclusion.initialDto }] }),
      });
      await swr.mutate();
    } catch (response: any) { // eslint-disable-line
      if (isApiClientError(response)) {
        ParseAndPopAlertFail(response, t);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const addConclusion = async (
    conclusionGroup: FormConclusionGroup,
    newConclusion: Record<string, unknown>,
  ) => {
    const updated = {
      conclusions: [...conclusionGroup.conclusions, newConclusion],
    };
    setIsLoading(true);

    try {
      await apiClient(`${url}/${conclusionGroup.id}`, HttpMethod.Put, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify(updated),
      });
      await swr.mutate();
    } catch (response: any) { // eslint-disable-line
      if (isApiClientError(response)) {
        ParseAndPopAlertFail(response, t);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateConclusion = async (
    conclusionGroup: FormConclusionGroup,
    conclusion: FormConclusion,
  ) => {
    const updated = {
      conclusions: [
        ...conclusionGroup.conclusions
          .filter((_conclusion) => conclusion.id !== _conclusion.id)
          .map((conclusion) => conclusion.dto),
        conclusion.dto,
      ],
    };
    setIsLoading(true);
    try {
      await apiClient(`${url}/${conclusionGroup.id}`, HttpMethod.Put, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify(updated),
      });
      await swr.mutate();
    } catch (response: any) { // eslint-disable-line
      if (isApiClientError(response)) {
        ParseAndPopAlertFail(response, t);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const removeConclusionGroup = async (conclusionGroup: FormConclusionGroup) => {
    setIsLoading(true);

    try {
      await apiClient(`${url}/${conclusionGroup.id}`, HttpMethod.Delete, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
      });
      await swr.mutate();
    } catch (response: any) { // eslint-disable-line
      if (isApiClientError(response)) {
        ParseAndPopAlertFail(response, t);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const removeConclusion = async (
    conclusionGroup: FormConclusionGroup,
    conclusions: Array<FormConclusion>,
  ) => {
    setIsLoading(true);
    const updated = {
      conclusions,
    };

    try {
      await apiClient(`${url}/${conclusionGroup.id}`, HttpMethod.Put, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify(updated),
      });
      await swr.mutate();
    } catch (response: any) { // eslint-disable-line
      if (isApiClientError(response)) {
        ParseAndPopAlertFail(response, t);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const removeAll = async () => {
    setIsLoading(true);
    try {
      await apiClient(`${url}`, HttpMethod.Delete, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
      });
      await swr.mutate();
    } catch (response: any) { // eslint-disable-line
      if (isApiClientError(response)) {
        ParseAndPopAlertFail(response, t);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    ...swr,
    isInitialLoad: !swr.data && !swr.error,
    removeConclusion,
    removeConclusionGroup,
    addConclusion,
    addConclusionGroup,
    updateConclusion,
    removeAll,
  };
};
