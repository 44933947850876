import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { useDependencyHeaders } from './useDependencyHeaders';

export const useLastLoginTrigger = () => {
  const headers = useDependencyHeaders();

  return async () => {
    await apiClient('users/lastlogin', HttpMethod.Put, {
      headers,
    });
  };
};
