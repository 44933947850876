import { Box } from '@chakra-ui/react';
import { FC } from 'types';

import { ToolbarIconButton } from 'components/core/ToolbarIconButton/ToolbarIconButton';
import { ToolbarActionIcon } from 'typings/enums/ToolbarActionIcon';
import { useOpenMenu } from 'hooks/useOpenMenu';

import { FormEditorViewMenu } from './components/FormEditorViewMenu';

export const FormEditorViewIconButton: FC = () => {
  const [ref, isOpen, setIsOpen] = useOpenMenu();

  return (
    <Box position="relative" ref={ref}>
      <ToolbarIconButton action={ToolbarActionIcon.View} onClick={setIsOpen.toggle} />
      {isOpen && <FormEditorViewMenu />}
    </Box>
  );
};
