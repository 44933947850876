import { Box, Center, Grid, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { Sidebar } from './components/Sidebar';
import { Header } from './components/Header';

export const MainLayout: FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Grid
      gridTemplateAreas="'header header header' 'sidebar main main' 'sidebar footer footer'"
      gridTemplateRows="50px 1fr 60px"
      gridTemplateColumns="70px auto auto"
      minHeight="100vh"
    >
      <Box gridArea="header">
        <Header />
      </Box>
      <Box gridArea="sidebar">
        <Sidebar />
      </Box>
      <Box
        p="20px"
        pb="30px"
        bgGradient="linear(to-b, brand.orange 140px, grey.lvl6 140px)"
        gridArea="main"
      >
        <Box bg="grey.lvl2" px="20px" height="100%">
          {children}
        </Box>
      </Box>
      <Center bg="grey.lvl2" color="white" gridArea="footer">
        <Text size="sm">
          {t('footer.copyrightContent', { currentYear: new Date().getFullYear() })}
        </Text>
      </Center>
    </Grid>
  );
};
