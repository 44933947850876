import { VStack } from '@chakra-ui/react';

import { useAssessmentForm } from 'context/AssessmentContext/AssessmentContextProvider';

import { AssessmentSection } from '../AssessmentSection/AssessmentSection';

import { useAssessmentSections } from './AssessmentForm.hooks';

export const AssessmentForm = () => {
  const { assessmentId, isEditable } = useAssessmentForm();
  const components = useAssessmentSections(assessmentId);

  return (
    <VStack spacing={40}>
      {components.map(({ component, readonly }) => (
        <AssessmentSection
          key={component.id}
          section={component}
          disabled={!isEditable || readonly}
        />
      ))}
    </VStack>
  );
};
