import { FC } from 'types';

import { FormsTableCellName } from '../FormsTableCellName/FormsTableCellName';
import { Form } from 'models/Form';

interface IFormsTableCellNameWrapperProps {
  original: Form;
}

export const FormsTableCellNameWrapper: FC<IFormsTableCellNameWrapperProps> = ({ original }) => {
  return <FormsTableCellName form={original} />;
};
