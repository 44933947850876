import { FC } from 'types';
import { FieldArrayWithId } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RoomType } from 'typings/enums/RoomType';
import { IRoomUser } from 'typings/interfaces/IRoomUser';
import { transformUserToRoomUser } from 'services/transformUserToRoomUser';
import { useResources } from 'hooks/useResources';
import { User } from 'models/User';
import { ICreateRoomFields } from 'typings/interfaces/ICreateRoomFields';
import { UserManagementSearch } from 'components/user-management/UserManagementSearch/UserManagementSearch';
import { userSearchValidation } from 'services/validation/userSearch';

interface ICreateRoomUserManagementSearchProps {
  roomType: RoomType;
  addedUsers: Array<FieldArrayWithId<ICreateRoomFields, 'roomUsers', 'id'>>;
  onAdd(user: IRoomUser): void;
}
export const CreateRoomUserManagementSearch: FC<ICreateRoomUserManagementSearchProps> = ({
  roomType,
  addedUsers,
  onAdd,
}) => {
  const { data, isInitialLoad } = useResources(User);
  const { t } = useTranslation();

  const filteredUsers = (data as Array<User>).filter((user) => {
    if (roomType === RoomType.Internal) {
      return user.isDominantUser;
    }

    return true;
  });

  const availableUsers = filteredUsers.map((user) => transformUserToRoomUser(user));

  const handleAddUser = (email: string) => {
    const selectedUser = availableUsers?.find((user) => user.email === email);
    if (!selectedUser) {
      return;
    }
    onAdd(selectedUser);
  };

  return (
    <UserManagementSearch
      label={t('form.formMembers.modalTitle')}
      buttonLabel={t('action.add.label')}
      placeholder={t('rooms.createRoom.usersManagement.search.placeholder')}
      validationRules={userSearchValidation(
        t,
        (value: string) => Boolean(filteredUsers?.find((user) => user.email === value)),
        (value: string) => Boolean(addedUsers?.find((user) => user.email === value)),
      )}
      disableButton={isInitialLoad}
      onClick={handleAddUser}
    />
  );
};
