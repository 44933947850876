import { FC } from 'types';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormApproval } from 'models/FormApproval';
import { UpsertFormApprovalDTO } from 'typings/types/dto/ApprovalDTO';
import { ApprovalSettings } from 'typings/types/ApprovalSettings';
import { ApprovalSettingsForm } from 'components/approval/ApprovalSettingsForm/ApprovalSettingsForm';
import { ApprovalEntity } from 'typings/enums/ApprovalEntity';

interface IFormApprovalSettingsFormProps {
  approval: FormApproval;
  isLoading?: boolean;
  onSubmit(formData: UpsertFormApprovalDTO): Promise<void>;
  onCancel(): void;
}

export const FormApprovalSettingsForm: FC<IFormApprovalSettingsFormProps> = ({
  approval,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setError } = useForm<ApprovalSettings>({
    defaultValues: {
      isEditableAfter: true,
      requiredApprovals: approval.requiredApprovals ?? 1,
      skipSendingNotification: approval.skipSendingNotification ?? false,
      notificationText: approval.notificationText ?? '',
      approverObjects:
        approval.approvers.map((approver) => ({
          id: approver.id,
          username: approver.username,
          email: approver.email,
        })) ?? [],
    },
  });
  const [requiredNumber, addedApprovers] = useWatch({
    control,
    name: ['requiredApprovals', 'approverObjects'],
  });
  const disableSubmit = requiredNumber < 1 || requiredNumber > addedApprovers.length;

  const handleSave = async (formValues: ApprovalSettings) => {
    if (!formValues.skipSendingNotification && formValues.notificationText === '') {
      setError('notificationText', {
        message: t('assessment.collaborators.notificationText.invalidText'),
      });

      return;
    }
    onSubmit({
      isFormEditable: formValues.isEditableAfter,
      requiredApprovals: formValues.requiredApprovals,
      skipSendingNotification: formValues.skipSendingNotification,
      notificationText: formValues.notificationText,
      approvers: formValues.approverObjects.map((approverIdObject) => approverIdObject.id),
    });
  };

  return (
    <ApprovalSettingsForm
      entity={ApprovalEntity.Form}
      control={control}
      defaultSkipSendingNotification={approval.skipSendingNotification}
      disabled={disableSubmit}
      isLoading={isLoading}
      onSubmit={handleSubmit(handleSave)}
      onCancel={onCancel}
    />
  );
};
