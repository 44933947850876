import { useMemo } from 'react';

import { useHistory } from 'react-router-dom';

function useQuery() {
  const { search } = window.location;
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const useOpenQuery = (id: string, queryKey: string) => {
  const history = useHistory();
  const query = useQuery();

  const open = () => {
    query.set(queryKey, `${id}`);
    history.replace({ search: query.toString() });
  };

  const close = () => {
    query.delete(queryKey);
    history.replace({ search: query.toString() });
  };

  const isOpen = query.get(queryKey) == id;

  return { open, close, isOpen };
};
