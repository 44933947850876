import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './i18n';

import reportWebVitals from './reportWebVitals';
import { OIDCProvider } from './context/OidcContext';
import { DatxProvider } from './context/DatxContext';

const { pathname, search } = window.location;

const postLoginRedirect = sessionStorage.getItem('postLoginRedirect');

if (!postLoginRedirect) {
  const searchParams = new URLSearchParams(search);
  const siteId = searchParams.get('siteId');
  searchParams.delete('siteId');
  if (siteId) {
    sessionStorage.setItem('x-site-id', siteId as string);
  }

  // @ts-expect-error size exists on searchParams
  const queryParams = searchParams.size === 0 ? '' : `?${searchParams.toString()}`;
  sessionStorage.setItem('postLoginRedirect', `${pathname}${queryParams}`);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <OIDCProvider>
    <DatxProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </DatxProvider>
  </OIDCProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
