import { Box, BoxProps, HStack, Spacer, Text } from '@chakra-ui/react';
import { Slider as AppKitSlider } from 'appkit-react-slider';
import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledInputNumber } from '../StyledAppKit/StyledAppKit';

const handleFromStyle = {
  background: '#22992e',
};

const handleToStyle = {
  background: '#c52a1a',
};

const trackStyle = {
  background: 'linear-gradient(to right,#22992e,#ffbf1f 47.83%,#c52a1a)',
};

interface ISliderWithRangeProps extends Omit<BoxProps, 'defaultValue' | 'value' | 'onChange'> {
  name: string;
  label?: string;
  symbol?: string;
  min?: number;
  max?: number;
  step?: number;
  defaultValue?: Array<number>;
  value?: Array<number>;
  disabled?: boolean;
  onChange?(valFrom: number, valTo: number): void;
}
export const SliderWithRange: FC<ISliderWithRangeProps> = ({
  name,
  label,
  symbol,
  min = -Infinity,
  max = Infinity,
  step = 1,
  value,
  defaultValue,
  disabled,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();
  const [rangeValue, setRangeValue] = useState(() => value ?? [min, max]);

  const handleChangeFrom = (newValue: number) => {
    setRangeValue((prev) => [Math.min(newValue, prev[1]), prev[1]]);
    if (!disabled) {
      onChange?.(Math.min(newValue, rangeValue[1]), rangeValue[1]);
    }
  };
  const handleChangeTo = (newValue: number) => {
    setRangeValue((prev) => [prev[0], Math.max(newValue, prev[0])]);
    if (!disabled) {
      onChange?.(rangeValue[0], Math.max(newValue, rangeValue[0]));
    }
  };

  const handleSliderChange = (values: Array<number>) => {
    setRangeValue(values);
    if (!disabled) {
      onChange?.(values[0], values[1]);
    }
  };

  return (
    <Box className="a-slider-wrapper" {...rest}>
      <HStack pb={20} alignSelf="flex-end">
        <Text>{label}</Text>
        <Spacer />

        <StyledInputNumber
          kind="arrow"
          inputBoxSize="lg"
          inputAttr={{ name: `${name}.from` }}
          min={min}
          max={max}
          step={step}
          value={rangeValue[0]}
          disabled={disabled}
          onChange={handleChangeFrom}
        />
        {symbol && <Text ml={16}>{symbol}</Text>}
        <span className="font-weight-normal a-px-10">{t('slider.label.to')}</span>
        <StyledInputNumber
          kind="arrow"
          inputBoxSize="lg"
          inputAttr={{ name: `${name}.to` }}
          min={min}
          max={max}
          step={step}
          value={rangeValue[1]}
          disabled={disabled}
          onChange={handleChangeTo}
        />
        {symbol && <Text ml={16}>{symbol}</Text>}
      </HStack>
      <AppKitSlider
        kind="range"
        min={min}
        max={max}
        step={step}
        value={rangeValue}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={handleSliderChange}
        handleStyle={[handleFromStyle, handleToStyle]}
        trackStyle={trackStyle}
      />
    </Box>
  );
};
