import { Fragment, useState } from 'react';
import { FC } from 'types'
import { Box, Heading, useBoolean, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { IRoomDetailsRowProps } from 'typings/interfaces/IDetailsRowProps';
import { RoomDescriptionEditModal } from 'components/room/RoomDescriptionEditModal/RoomDescriptionEditModal';
import { useUpdateRoom } from 'components/room/room.hooks';
import { RoomDetailsRow } from 'components/room/RoomDetails/components/RoomDetailsRow/RoomDetailsRow';
import { TextAreaField } from 'fields/TextareaField/TextareaField';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

export const RoomDescriptionRow: FC<IRoomDetailsRowProps> = ({
  model,
  disableEdit = false,
  readonly = false,
  onStartEdit,
  onStopEdit,
}) => {
  const [isEditMode, setEditMode] = useBoolean(false);
  const [updatedValue, setUpdatedValue] = useState(model.description);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { save, isSaving } = useUpdateRoom(model.id);
  const { t } = useTranslation();

  const handleClickConfirm = async () => {
    if (isSaving && !isEditMode && !readonly && isOpen) {
      return;
    }
    await save({ ...model.upsertDTO, description: updatedValue });
    setEditMode.off();
    onStopEdit?.();
    onClose();
  };

  const handleClickAction = () => {
    if (isEditMode) {
      return;
    }
    setEditMode.on();
    onStartEdit?.();
    onOpen();
  };

  const handleClickCancel = () => {
    if (isSaving || !isEditMode) {
      return;
    }
    setEditMode.off();
    onStopEdit?.();
    onClose();
  };

  return (
    <Fragment>
      <RoomDetailsRow>
        <Heading as="h5" size="sm" w="100px">
          {t('room.sidebar.roomDetails.rows.description')}
        </Heading>
        <Box w="200px" />
        <Box w="47px">
          <StyledButton
            size="md"
            kind="transparent"
            onClick={handleClickAction}
            disabled={disableEdit}
          >
            {readonly ? t('action.view.label') : t('action.edit.label')}
          </StyledButton>
        </Box>
      </RoomDetailsRow>
      {isOpen && (
        <RoomDescriptionEditModal
          isSaving={isSaving}
          onConfirm={handleClickConfirm}
          onCancel={handleClickCancel}
          readonly={readonly}
        >
          <TextAreaField
            autosize
            name="description"
            defaultValue={model.description}
            disabled={readonly || isSaving}
            onChange={!readonly ? setUpdatedValue : undefined}
            sx={{ textarea: { minHeight: '125px' } }}
          />
        </RoomDescriptionEditModal>
      )}
    </Fragment>
  );
};
