import React, { FC, MouseEvent } from 'react';
import { Radio, RadioGroup } from 'appkit-react';
import { VStack, BoxProps, Box } from '@chakra-ui/react';

import { useAnimation } from '../../../hooks/useAnimation';
import { RoomsCardSort } from '../../../typings/enums/RoomsCardSort';
import { useTranslation } from 'react-i18next';

interface IRoomsCardViewSortMenu extends BoxProps {
  isVisible: boolean;
  onSortClick?: (event: MouseEvent<HTMLDivElement>, value: RoomsCardSort) => void;
}

export const RoomsCardViewSortMenu: FC<IRoomsCardViewSortMenu> = ({
  isVisible,
  onSortClick,
  sx,
  ...rest
}) => {
  const { transitionEnd, isAnimationReady, shouldRender } = useAnimation(isVisible);
  const { t } = useTranslation();

  if (!shouldRender) {
    return null;
  }

  return (
    <Box
      onTransitionEnd={transitionEnd}
      sx={{
        boxShadow: 'mainWhite',
        width: '180px',
        height: '190px',
        borderRadius: '2px',
        bg: 'grey.lvl4',
        transform: `translateY(${isAnimationReady ? '0' : '-34px'})`,
        opacity: isAnimationReady ? '1' : '0',
        transition: 'all 0.5s ease',
        py: '10px',
        px: '20px',
        ...sx,
      }}
    >
      <VStack
        as={RadioGroup}
        align="start"
        // @ts-ignore
        onChange={onSortClick}
        {...rest}
      >
        <Box
          as={Radio}
          marginTop="8px"
          value={RoomsCardSort.AlphaAsc}
          uncheckedIcon={
            <span
              style={{ color: 'inherit' }}
              className="appkiticon icon-sorting-ascendant-outline"
            />
          }
          checkedIcon={<span className="appkiticon icon-sorting-ascendant-fill" />}
        >
          {t('rooms.sortMenu.aToZ')}
        </Box>
        <Radio
          value={RoomsCardSort.AlphaDesc}
          uncheckedIcon={
            <span
              style={{ color: 'inherit' }}
              className="appkiticon icon-sorting-descendant-outline"
            />
          }
          checkedIcon={<span className="appkiticon icon-sorting-descendant-fill" />}
        >
          {t('rooms.sortMenu.zToA')}
        </Radio>
        <Radio
          value={RoomsCardSort.LastEdited}
          uncheckedIcon={
            <span style={{ color: 'inherit' }} className="appkiticon icon-calendar-outline" />
          }
          checkedIcon={<span className="appkiticon icon-calendar-outline" />}
        >
          {t('rooms.sortMenu.lastEdited')}
        </Radio>
        <Radio
          value={RoomsCardSort.MostRecent}
          uncheckedIcon={
            <span style={{ color: 'inherit' }} className="appkiticon icon-calendar-fill" />
          }
          checkedIcon={<span className="appkiticon icon-calendar-fill" />}
        >
          {t('rooms.sortMenu.mostRecent')}
        </Radio>
        <Radio
          value={RoomsCardSort.Favorite}
          uncheckedIcon={
            <span style={{ color: 'inherit' }} className="appkiticon icon-rating-outline" />
          }
          checkedIcon={<span className="appkiticon icon-rating-fill" />}
        >
          {t('rooms.sortMenu.favorites')}
        </Radio>
      </VStack>
    </Box>
  );
};
