import { FC } from 'types';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'appkit-react';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { SelectField } from 'fields/SelectField/SelectField';
import {
  CountryFormatType,
  CountrySettings,
  UserInputFormatType,
} from 'typings/types/FormComponentSettings';
import { useResources } from 'hooks/useResources';
import { Country } from 'models/Country';
import { Language } from 'models/Language';

interface IAssessmentCountryFieldProps {
  assessmentQuestion: AssessmentFormComponent;
  disabled?: boolean;
  index?: number;
}

export const AssessmentCountryField: FC<IAssessmentCountryFieldProps> = ({
  assessmentQuestion,
  disabled,
  index = 0,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const { data: countries } = useResources(Country, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: languages } = useResources(Language, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const settings = assessmentQuestion.parsedSettings as CountrySettings;
  if (!settings) {
    throw new Error(
      `Invalid assessment field settings: ${assessmentQuestion.componentType} {${assessmentQuestion.id}}`,
    );
  }

  if (settings.formatType === CountryFormatType.Country) {
    return (
      <Controller
        control={control}
        name={assessmentQuestion.fieldName(index)}
        rules={{
          required: assessmentQuestion.isRequired ? t<string>('validation.required') : false,
        }}
        // eslint-disable-next-line
        render={({ field: { ref: _ref, ...fieldProps } }) => (
          <SelectField
            sx={{ w: '350px', mx: 'auto' }}
            disabled={disabled}
            placeholder={!countries ? `${t('loading.fetching.data')}...` : undefined}
            showSearchOnToggle={Boolean(countries)}
            {...fieldProps}
          >
            {((countries ?? []) as Array<Country>).map((country) => (
              <SelectOption key={country.alpha2Code} value={country.alpha2Code}>
                {settings.userInputFormatType === UserInputFormatType.Name
                  ? country.name
                  : country.alpha2Code}
              </SelectOption>
            ))}
          </SelectField>
        )}
      />
    );
  } else {
    return (
      <Controller
        control={control}
        name={assessmentQuestion.fieldName(index)}
        rules={{
          required: assessmentQuestion.isRequired ? t<string>('validation.required') : false,
        }}
        // eslint-disable-next-line
        render={({ field: { ref: _ref, ...fieldProps } }) => (
          <SelectField
            sx={{ w: '350px', mx: 'auto' }}
            disabled={disabled}
            placeholder={!languages ? `${t('loading.fetching.data')}...` : undefined}
            showSearchOnToggle={Boolean(languages)}
            {...fieldProps}
          >
            {((languages ?? []) as Array<Language>).map((language) => (
              <SelectOption key={language.iso639_1} value={language.iso639_1}>
                {settings.userInputFormatType === UserInputFormatType.Name
                  ? language.name
                  : language.iso639_1}
              </SelectOption>
            ))}
          </SelectField>
        )}
      />
    );
  }
};
