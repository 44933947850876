import { FC } from 'types';
import { ButtonGroupItem } from 'appkit-react';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormUserRole } from 'typings/enums/FormUserRole';
import { ButtonGroupField } from 'fields/ButtonGroupField/ButtonGroupField';
import { IFormUserManagementFields } from 'typings/interfaces/IFormUserManagementFields';
import { IButtonGroupProps } from 'typings/appkit-react/IButtonGroupProps';

interface IFormUserRoleButtonGroupFieldProps extends IButtonGroupProps {
  name: string;
  control: Control<IFormUserManagementFields>;
  isLastEditor?: boolean;
}
export const FormUserRoleButtonGroupField: FC<IFormUserRoleButtonGroupFieldProps> = ({
  control,
  name,
  isLastEditor,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <ButtonGroupField
      control={control as unknown as Control<FieldValues>}
      name={name}
      size="md"
      {...rest}
    >
      <ButtonGroupItem data-value={FormUserRole.View} disabled={Boolean(isLastEditor)}>
        {t('form.formMembers.permissions.view').toUpperCase()}
      </ButtonGroupItem>
      <ButtonGroupItem data-value={FormUserRole.Edit} disabled={Boolean(isLastEditor)}>
        {t('form.formMembers.permissions.edit').toUpperCase()}
      </ButtonGroupItem>
    </ButtonGroupField>
  );
};
