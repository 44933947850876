import { useConst } from '@chakra-ui/react';
import { FC } from 'types';
import { useMemo} from 'react';
import { useFormContext } from 'react-hook-form';
import { debounce } from 'lodash';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { ScaleType } from 'typings/types/FormComponentSettings';
import { SliderWithIntervals } from 'components/core/SliderWithIntervals/SliderWithIntervals';
import { SliderWithRange } from 'components/core/SliderWithRange/SliderWithRange';
import { Slider } from 'components/core/Slider/Slider';
import { useAssessmentForm } from 'context/AssessmentContext/AssessmentContextProvider';
import { useDebouncedUpdateAssessmentQuestion } from 'hooks/useAssessment';
import { recalculateSkippedComponents } from 'context/AssessmentContext/AssessmentContext.utils';
import { useDatx } from 'hooks/useDatx';

import {
  getQuestionsScaleSettings,
  IScaleQuestionSettings,
  parseIntervalValues,
} from './AssessmentScaleNumberField.utils';

interface IAssessmentScaleNumberFieldProps {
  assessmentQuestion: AssessmentFormComponent;
  disabled?: boolean;
  index?: number;
}
export const AssessmentScaleNumberField: FC<IAssessmentScaleNumberFieldProps> = ({
  assessmentQuestion,
  disabled,
  index = 0,
}) => {
  const { symbol, name, scaleType, title, maximumScale, minimumScale } =
    useConst<IScaleQuestionSettings>(() => getQuestionsScaleSettings(assessmentQuestion, index));

  const { setValue, getValues } = useFormContext();
  const datx = useDatx();
  const { isEditable, assessmentId, assessment, dispatch } = useAssessmentForm();
  const { updateAssessmentAnswer } = useDebouncedUpdateAssessmentQuestion(
    assessmentQuestion,
    assessment?.isTest,
    3000,
  );

  // update on change because on form blur is not working 100% for Slider component
  const updateAnswer = useMemo(
    () =>
      debounce(async () => {
        if (!isEditable || !assessmentQuestion.updateAnswer(getValues())) {
          return;
        }
        recalculateSkippedComponents(dispatch, datx, assessmentId);
        await updateAssessmentAnswer();
      }, 1500),
    [
      isEditable,
      assessmentQuestion,
      getValues,
      dispatch,
      datx,
      assessmentId,
      updateAssessmentAnswer,
    ],
  );

  if (scaleType === ScaleType.Default) {
    const stringValue = getValues(name);
    const parsedValue = stringValue ? parseFloat(stringValue) : undefined;

    return (
      <Slider
        name={name}
        label={title}
        symbol={symbol}
        min={minimumScale}
        max={maximumScale}
        value={parsedValue}
        disabled={disabled}
        onChange={(val: number) => {
          setValue(name, val);
          updateAnswer();
        }}
      />
    );
  }

  if (scaleType === ScaleType.Range) {
    const stringValue = getValues(name);

    const parsedFrom = stringValue.from ? parseFloat(stringValue.from) : minimumScale;
    const parsedTo = stringValue.to ? parseFloat(stringValue.to) : maximumScale;
    const parsedValue =
      parsedFrom === undefined || parsedTo === undefined ? undefined : [parsedFrom, parsedTo];

    return (
      <SliderWithRange
        name={name}
        label={title}
        symbol={symbol}
        min={minimumScale}
        max={maximumScale}
        value={parsedValue}
        disabled={disabled}
        onChange={(valFrom, valTo) => {
          setValue(`${name}.from`, valFrom);
          setValue(`${name}.to`, valTo);
          updateAnswer();
        }}
      />
    );
  }

  if (scaleType === ScaleType.Interval && assessmentQuestion.valueJson) {
    const marks = parseIntervalValues(assessmentQuestion.valueJson);
    const stringValue: string | undefined = getValues(name)?.toString();
    const indexOfValue = stringValue ? marks.indexOf(stringValue) : 0;

    return (
      <SliderWithIntervals
        name={name}
        label={title}
        symbol={symbol}
        value={indexOfValue}
        disabled={disabled}
        marks={marks}
        onChange={(value: number) => {
          setValue(name, marks[value]);
          updateAnswer();
        }}
      />
    );
  }

  throw new Error('Scale component not recognized');
};
