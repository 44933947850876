import { FormControlProps, HStack, Text, FormControl, Box } from '@chakra-ui/react';
import { FC } from 'types';
import { useController, UseControllerProps } from 'react-hook-form';

import { FormFieldHelp } from 'components/core/FormFieldHelp/FormFieldHelp';
import { StyledInputNumber } from 'components/core/StyledAppKit/StyledAppKit';
import { InputNumberKind } from 'typings/appkit-react/InputNumberKind';
import { InputBoxSize } from 'typings/appkit-react/InputBoxSize';
import { FormFieldLabel } from 'components/core/FormFieldLabel/FormFieldLabel';

interface IInputNumberFieldProps
  extends UseControllerProps,
    Omit<FormControlProps, 'onChange' | 'defaultValue'> {
  min?: number;
  max?: number;
  step?: number | string;
  inputBoxSize?: InputBoxSize;
  kind?: InputNumberKind;
  integerOnly?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  htmlFor?: string;
  label?: string;
  helperText?: string;
}

export const InputNumberField: FC<IInputNumberFieldProps> = ({
  control,
  name,
  defaultValue,
  rules,
  label,
  htmlFor,
  helperText,
  sx,
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, defaultValue, rules });

  return (
    <FormControl sx={sx}>
      {label && <FormFieldLabel htmlFor={htmlFor}>{label}</FormFieldLabel>}
      <HStack justify="space-between" alignItems="baseline">
        <StyledInputNumber {...field} {...rest} width="100%" />
        {helperText && <FormFieldHelp alignSelf="center">{helperText}</FormFieldHelp>}
      </HStack>
      {error && <Text className="a-form-error">{error.message}</Text>}
    </FormControl>
  );
};

// TODO: create variant
export const InputNumberFieldHorizontal: FC<IInputNumberFieldProps> = ({
  control,
  name,
  defaultValue,
  rules,
  label,
  htmlFor,
  helperText,
  step,
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, defaultValue, rules });

  return (
    <Box width="100%">
      <HStack as={FormControl} spacing={20} justify="space-between" alignItems="baseline">
        <FormFieldLabel size="md" textTransform="none" htmlFor={htmlFor}>
          {label}
        </FormFieldLabel>
        <StyledInputNumber
          flex={1}
          inputAttr={{ type: 'number', step: step }}
          step={step}
          {...field}
          {...rest}
        />

        {helperText && <FormFieldHelp alignSelf="center">{helperText}</FormFieldHelp>}
      </HStack>

      {error && <span className="a-form-error">{error.message}</span>}
    </Box>
  );
};

interface IInputNumberMaskedFieldProps extends IInputNumberFieldProps {
  numberOfDecimals?: number;
}
// TODO: create variant
export const InputNumberMaskedFieldHorizontal: FC<IInputNumberMaskedFieldProps> = ({
  control,
  name,
  defaultValue,
  rules,
  label,
  htmlFor,
  helperText,
  step,
  numberOfDecimals = 0,
  ...rest
}) => {
  const {
    field: { onChange, ...restField },
    fieldState: { error },
  } = useController({ control, name, defaultValue, rules });

  const handleChange = (inputValue: number) => {
    const masked = parseFloat(inputValue.toFixed(numberOfDecimals));
    onChange(masked);
  };

  return (
    <Box width="100%">
      <HStack as={FormControl} spacing={20} justify="space-between" alignItems="baseline">
        <FormFieldLabel size="md" htmlFor={htmlFor}>
          {label}
        </FormFieldLabel>
        <StyledInputNumber
          flex={1}
          inputAttr={{ type: 'number', step: step }}
          step={step}
          onChange={handleChange}
          {...restField}
          {...rest}
        />

        {helperText && <FormFieldHelp alignSelf="center">{helperText}</FormFieldHelp>}
      </HStack>

      {error && <span className="a-form-error">{error.message}</span>}
    </Box>
  );
};
