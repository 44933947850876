import { Box } from '@chakra-ui/react';
import { UserManagementTable } from 'components/site-admin/user-management/UserManagementTable';

export const SiteAdminUserManagementPage = () => {
  return (
    <Box mt="20px">
      <UserManagementTable />
    </Box>
  );
};
