import { IRoomFolderUser } from 'typings/interfaces/IRoomFolderUser';
import { UpsertRoomFolderUserDTO } from 'typings/types/dto/RoomFolderDTO';
import { IForm, ITransformedForm, transformFormsData } from './transformFormsData';

import { IWithId, IWithUserId, transformResourceUserDto } from './transformResourceUserDto';

interface IRoomFolder extends IWithId {
  users: Array<IWithId>;
  childRoomFolders: Array<IRoomFolder>;
  forms: Array<IForm>;
}
interface ITransformedRoomFolder extends IWithId {
  users: Array<IWithUserId>;
  forms: Array<ITransformedForm>;
  childRoomFolders: Array<ITransformedRoomFolder>;
}
const transformRoomFolderUsersData = (roomFolderUsersDto: Array<IWithId>, roomFolderId: string) =>
  roomFolderUsersDto.map((formUserDto) => transformResourceUserDto(formUserDto, roomFolderId));

const transformRoomFolderDto = (roomFolderDto: IRoomFolder): ITransformedRoomFolder => ({
  ...roomFolderDto,
  childRoomFolders: transformRoomFolderData(roomFolderDto.childRoomFolders), // recursively transform all child folders
  users: transformRoomFolderUsersData(roomFolderDto.users, roomFolderDto.id),
  forms: transformFormsData(roomFolderDto.forms),
});

export const transformRoomFolderData = (data: Array<IRoomFolder>): Array<ITransformedRoomFolder> =>
  data.map(transformRoomFolderDto);

export const transformFolderUsersToDto = (
  folderUsers: Array<IRoomFolderUser>,
): Array<UpsertRoomFolderUserDTO> =>
  folderUsers.map((user) => ({
    ...user,
    id: user.userId,
    role: user.folderUserRole,
    userId: undefined,
  }));
