import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';

import { useCampaignCommonActions } from 'hooks/useInitiateCampaign';
import { useCampaign } from 'hooks/useCampaign';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { CampaignModalTabs } from '../CampaignModalTabs/CampaignModalTabs';
import { CampaignModal } from '../CampaignModal/CampaignModal';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';

interface IEditCampaignModalProps {
  formId: string;
  roomId: string;
  campaignId: string;
  onCancel(): void;
}

export const EditCampaignModal: FC<IEditCampaignModalProps> = ({
  formId,
  roomId,
  campaignId,
  onCancel,
}) => {
  const { t } = useTranslation();
  const dependencyHeaders = useDependencyHeaders();

  const { campaign, isInitialLoad } = useCampaign(roomId, formId, campaignId);
  const { updateCampaign, deleteCampaign } = useCampaignCommonActions(roomId, formId);

  const handleClose = () => {
    onCancel();
  };

  const handleSaveCampaign = async () => {
    await updateCampaign(campaign);
    await mutate(`rooms/${roomId}/forms/${formId}/campaign@${dependencyHeaders['x-site-id']}`);
  };

  const handleDeleteCampaign = async () => {
    try {
      await deleteCampaign(campaign.id);
      PopAlertSuccess(t('campaign.delete.success'));
      handleClose();
    } catch (error: any) { // eslint-disable-line
      PopAlertFail(t('campaign.delete.error'));
      throw error;
    }
  };

  return (
    <CampaignModal onCancel={handleClose}>
      {isInitialLoad ? (
        <Flex mt={64} justify="center">
          <Box className="a-loading a-primary" />
        </Flex>
      ) : (
        <CampaignModalTabs
          campaign={campaign}
          onSave={handleSaveCampaign}
          onDelete={handleDeleteCampaign}
          onCancel={onCancel}
        />
      )}
    </CampaignModal>
  );
};
