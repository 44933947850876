export const appkit = {
  // modal-backdrop causes some weird bug where nothing is clickable
  '.a-modal .modal-backdrop': {
    display: 'none',
  },
  '.a-modal': {
    bg: 'transparent',
  },
  '.rc-slider-disabled': {
    bg: 'inherit',
  },
  '.alert-fixed': {
    position: 'fixed',
    zIndex: '1001',
    '&.alert-fixed-bottom-right': {
      bottom: '80px',
      right: '20px',
    },
  },
  '.a-form-label': {
    display: 'inline-block',
  },
  '.appkit-editor-toolbar .btn-group': {
    display: 'inline-flex',
  },
  '.appkit-editor-toolbar button, .btn': {
    padding: '0.375rem 0.75rem',
  },
  '.appkit-editor-main.appkit-editor': {
    height: '8.75rem',
  },
  '.btn-group.appkit-editor-link-buttons': {
    position: 'relative',
  },
};
