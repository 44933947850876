import { HttpMethod } from 'typings/enums/HttpMethod';

import { getFileNameFromHeader } from './getFileNameFromHeader';
import { getIdToken } from './getIdToken';
import { triggerBlobDownload } from './triggerBlobDownload';
import { config } from 'config'

const API_URL = `${config.apiUrl}/api/`;

export const fileDownload = async (
  endpointUrl: string,
  // eslint-disable-next-line
  options?: Record<string, any>,
) => {
  const headers = {
    'x-id-token': getIdToken(),
    ...options?.headers,
  };

  const requestOptions = {
    headers,
    method: options?.method ?? HttpMethod.Get,
    body: options?.body,
  };

  const response = await fetch(
    `${API_URL}${endpointUrl}`,
    requestOptions,
  );

  if (!response.ok) {
    throw await response.json();
  }

  const dispositionHeader = response.headers.get('content-disposition');
  const filename = getFileNameFromHeader(dispositionHeader ?? '');

  const blob = await response.blob();

  triggerBlobDownload(blob, filename ?? '');
  return response;
};
