import { Text, Box, useBoolean, useDisclosure, Heading } from '@chakra-ui/react';
import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { IRoomDetailsRowProps } from 'typings/interfaces/IDetailsRowProps';
import { RoomDetailsRow } from 'components/room/RoomDetails/components/RoomDetailsRow/RoomDetailsRow';
import { RoomMembersManagementModal } from 'components/room/RoomMembersManagementModal/RoomMembersManagementModal';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

export const RoomMembersRow: FC<IRoomDetailsRowProps> = ({
  model,
  disableEdit = false,
  readonly,
  onStartEdit,
  onStopEdit,
}) => {
  const [isEditMode, setEditMode] = useBoolean(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const stopEditing = () => {
    setEditMode.off();
    onStopEdit?.();
    onClose();
  };

  const handleClickEdit = () => {
    if (isEditMode) {
      stopEditing();
    } else {
      setEditMode.on();
      onStartEdit?.();
      onOpen();
    }
  };

  return (
    <Fragment>
      <RoomDetailsRow>
        <Heading as="h5" size="sm" w="100px">
          {t('room.sidebar.roomDetails.rows.members')}
        </Heading>
        <Box w="200px">
          <Text>{model.members.toString()}</Text>
        </Box>
        <Box w="47px">
          <StyledButton
            size="md"
            kind="transparent"
            onClick={handleClickEdit}
            disabled={disableEdit}
          >
            {t('action.edit.label')}
          </StyledButton>
        </Box>
      </RoomDetailsRow>
      {isOpen && (
        <RoomMembersManagementModal room={model} readonly={readonly} onClose={stopEditing} />
      )}
    </Fragment>
  );
};
