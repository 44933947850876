import { Box } from '@chakra-ui/react';
import { FC } from 'types';
import { useRef } from 'react';

import { StyledLink } from 'components/core/StyledLink/StyledLink';
import { useMenuActions } from 'hooks/useMenuActions';
import { Form } from 'models/Form';
import { FormUserManagementModal } from 'components/forms/FormUserManagementModal/FormUserManagementModal';
import { useModal } from 'hooks/useModal';
import { FormExportModal } from 'components/form/FormExportModal/FormExportModal';

import { FormsTableMenu } from '../FormsTableMenu/FormsTableMenu';

interface IFormsTableCellNameProps {
  form: Form;
}

export const FormsTableCellName: FC<IFormsTableCellNameProps> = ({ form }) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);

  const {
    isOpen: isMenuOpen,
    onClose: onMenuClose,
    menuPosition,
  } = useMenuActions(linkRef, cellRef);

  const {
    isOpen: isMembersModalOpen,
    onClose: onMembersModalClose,
    onOpen: onMembersModalOpen,
  } = useModal();

  const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onClose: onExportModalClose,
  } = useModal();

  // For V2
  // const {
  //   isOpen: isCopyModalOpen,
  //   onClose: onCopyModalClose,
  //   onOpen: onCopyModalOpen,
  // } = useModal();

  return (
    <Box ref={cellRef} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
      <StyledLink
        title={form.name}
        cursor="pointer"
        to={`/rooms/${form.room.id}/forms/${form.id}`}
        ref={linkRef}
      >
        {form.name}
      </StyledLink>

      {isMenuOpen && (
        <FormsTableMenu
          form={form}
          top={menuPosition?.y}
          left={menuPosition?.x}
          onMembersModalOpen={onMembersModalOpen}
          onExportModalOpen={onExportModalOpen}
          onClose={onMenuClose}
        />
      )}
      {isExportModalOpen && <FormExportModal formId={form.id} onClose={onExportModalClose} />}
      {isMembersModalOpen && <FormUserManagementModal form={form} onClose={onMembersModalClose} />}
      {/* For V2 */}
      {/* {isCopyModalOpen && (
        <FormCopyModal form={form} formRoomId={form.room.id} onCancel={onCopyModalClose} />
      )} */}
    </Box>
  );
};
