import { Attribute } from '@datx/core';
import { format } from 'date-fns';
import { computed } from 'mobx';

import { DomainStatus } from 'typings/enums/DomainStatus';
import { DomainType } from 'typings/enums/DomainType';

import { Resource } from './Resource';

export class DomainAppAdmin extends Resource {
  public static type = 'domains_app_admin';
  public static endpoint = 'domains/appAdmin';

  @Attribute()
  public readonly siteId!: string;

  @Attribute()
  public readonly siteName!: string;

  @Attribute()
  public readonly numberOfDomainUsers!: number;

  @Attribute()
  public readonly lastEdit!: string;

  @Attribute({ isIdentifier: true })
  public domainId!: string;

  @Attribute()
  public id!: string;

  @Attribute()
  public name!: string;

  @Attribute()
  public contact!: string;

  @Attribute()
  public hasLibraryAccess!: boolean;

  @Attribute()
  public status!: DomainStatus;

  @Attribute()
  public expiration!: string;

  @Attribute()
  public type!: DomainType;

  @computed
  public get formattedLastEdit() {
    if (this.lastEdit) {
      return format(new Date(this.lastEdit), 'PPpp');
    }

    return '/';
  }

  @computed
  public get formattedExpiration() {
    if (this.expiration) {
      return format(new Date(this.expiration), 'MMMM d, yyyy');
    }

    return '/';
  }
}
