import { Box } from '@chakra-ui/react';
import { FC } from 'types';
import { Droppable } from 'react-beautiful-dnd';

import { FormEditorSection } from 'components/form-editor/FormEditorSection/FormEditorSection';
import { DraggingAction } from 'typings/enums/DraggingAction';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';

interface IFormEditorSectionsContainerProps {
  formId?: string;
}

export const FormEditorSectionsContainer: FC<IFormEditorSectionsContainerProps> = () => {
  const { dragAction, placeholder, components, disableEdit } = useFormEditor();

  const sections = components
    .filter((component) => component.isSection)
    .sort((a, b) => {
      if (a.index !== b.index) {
        return a.index - b.index;
      }
      if (a.isPlaceholder) {
        return -1;
      }
      if (b.isPlaceholder) {
        return 1;
      }
      return 0;
    });

  const isDropDisabled =
    disableEdit ||
    Boolean(placeholder) ||
    !(
      dragAction === DraggingAction.MoveSection || dragAction === DraggingAction.AddSectionComponent
    );

  if (placeholder && placeholder.component.isSection) {
    sections.splice(placeholder.index, 0, placeholder.component);
  }

  return (
    <Droppable droppableId="sections" isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          bgGradient={snapshot.isDraggingOver ? 'linear(to-b, grey.lvl3, grey.lvl2)' : undefined}
          minHeight="400px"
        >
          {sections.map((section, index) => (
            <FormEditorSection key={section.id} index={index} model={section} />
          ))}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
};
