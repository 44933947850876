import { FC } from 'types';
import { Box, Grid, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Audit } from 'models/Audit';
import { Avatar } from 'components/core/Avatar/Avatar';
import { AuditItemTypeBadge } from '../AuditItemTypeBadge/AuditItemTypeBadge';

interface IAuditItemProps {
  auditItem: Audit;
  text: string;
}

export const AuditItem: FC<IAuditItemProps> = ({ auditItem, text }) => {
  const { t } = useTranslation();

  return (
    <Grid
      gridTemplateAreas="'initials username type datetime' 'description description description description'"
      gridTemplateRows="32px auto"
      gridTemplateColumns="32px 1fr auto auto"
      gridColumnGap="15px"
      gridRowGap="16px"
      alignItems="baseline"
      width="100%"
      height="100%"
    >
      <Avatar
        gridArea="initials"
        name={
          auditItem.isSystemNotice
            ? t('history.auditItem.systemUser.initials')
            : auditItem.user.username
        }
      />
      <Text gridArea="username" size="lg">
        {auditItem.isSystemNotice
          ? t('history.auditItem.systemUser.username')
          : auditItem.user.displayName}
      </Text>
      <Box gridArea="type">
        <AuditItemTypeBadge type={auditItem.entity} />
      </Box>
      <Text size="sm" gridArea="datetime">
        {auditItem.formattedTime}
      </Text>
      <Text gridArea="description" size="md" mx={8} maxW="80%">
        {text}
      </Text>
    </Grid>
  );
};
