import { BoxProps } from '@chakra-ui/react';
import { FC } from 'types';

import { StyledBadge } from 'components/core/StyledAppKit/StyledAppKit';

interface IQuestionOrderTagProps extends BoxProps {
  children: string | number;
}
export const QuestionOrderTag: FC<IQuestionOrderTagProps> = ({ children, ...rest }) => {
  return (
    <StyledBadge type="light" size="small" {...rest}>
      {children}
    </StyledBadge>
  );
};
