import { ConfirmationSettings } from 'typings/types/FormComponentSettings';

export function parseConfirmationQuestionSettings(
  stringifiedJSON: string | null,
): ConfirmationSettings {
  if (!stringifiedJSON) {
    return { confirmationText: '' };
  }
  try {
    const result = JSON.parse(stringifiedJSON) as ConfirmationSettings;

    return result;
  } catch (error: any) { // eslint-disable-line
    return { confirmationText: '' };
  }
}
