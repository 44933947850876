import useSWR from 'swr';

import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { useDependencyHeaders } from './useDependencyHeaders';
import { Trash } from 'models/Trash';
import { useDatx } from './useDatx';

export const useTrash = (searchQuery: string) => {
  const dependencyHeaders = useDependencyHeaders();

  const datx = useDatx();

  const key = `trash@${dependencyHeaders['x-site-id']}`;

  const swr = useSWR(
    key,
    async () => {
      const response = await apiClient('trash', HttpMethod.Get, {
        headers: {
          ...dependencyHeaders,
        },
      });

      return datx.add({ id: 'trash', ...response }, Trash) as unknown as Trash;
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  const filteredData = () => {
    let data = [];
    if (swr.data) {
      data = [
        ...swr.data.forms,
        ...swr.data.roomFolders,
        ...swr.data.assessments,
        ...swr.data.campaigns,
      ];
      return (data = data.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()),
      ));
    } else {
      return [];
    }
  };

  return {
    isInitialLoad: !swr.data && !swr.error,
    filteredData,
    ...swr,
  };
};
