import { chakra } from '@chakra-ui/react';

export const StyledRoomCellMenuItem = chakra('button', {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',

    p: '10px',
    color: 'white',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '21px',

    _hover: {
      bg: 'utils.grey.200',
    },
  },
});
