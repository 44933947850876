import { createContext, useCallback, useContext, useState } from 'react';
import { FC } from 'types';
import { DragDropContext, OnDragStartResponder, OnDragEndResponder } from 'react-beautiful-dnd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { FormComponent } from 'models/FormComponent';
import { DraggingAction } from 'typings/enums/DraggingAction';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { DropAction } from 'typings/enums/DropAction';
import { useFormsComponentsWithMutate } from 'hooks/useFormsComponents';
import { useDatx } from 'hooks/useDatx';
import { FormConclusion } from 'models/FormConclusion';
import { useConclusionWithMutations } from 'hooks/useConclusionWithMutations';
import { FormConclusionGroup } from 'models/FormConclusionGroup';
import { FormAddonType } from 'typings/enums/FormAddonType';
import { FullScreenLoadingOverlay } from 'components/core/FullScreenLoadingOverlay/FullScreenLoadingOverlay';
import { Form } from 'models/Form';
import { IComponentMutationOptions } from 'typings/interfaces/IComponentMutationOptions';
import { FormConclusionType } from 'typings/enums/FormConclusionType';
import { ConditionRuleOperator } from 'typings/enums/ConditionRuleOperator';
import { useCurrentUserEmail } from 'hooks/useCurrentUserEmail';
import { PopAlertFail } from 'services/PopAlertFail';
import { IComponentToShift } from 'services/formComponents/getComponentOrderChanges';
import {
  moveComponentAndUpdateAffectedComponents,
  validateMoveComponent,
} from 'services/formComponents/moveFormComponent';

import {
  addonHasSettings,
  getModelIdFromDraggable,
  inferDraggingAction,
  inferDropAction,
} from './FormEditorContext.utils';

type FormEditorContextValue = {
  roomId: string;
  formId: string;
  dragAction: DraggingAction;
  dragComponentDepth: number | undefined;
  placeholder: ComponentPlaceholder | null;
  components: Array<FormComponent>;
  conclusions: Array<FormConclusionGroup>;
  form: Form | undefined;
  disableEdit: boolean;
  allowLimitedEditOnFinalized: boolean;
  addComponent(formComponent: FormComponent): Promise<void>;
  reorderComponents(componentsToShift: Array<IComponentToShift>): Promise<void>;
  refreshComponents(): Promise<void>;
  updateComponent(formComponent: FormComponent, options?: IComponentMutationOptions): Promise<void>;
  removeComponent(formComponent: FormComponent): Promise<void>;
  addConclusionGroup(): Promise<void>;
  addConclusion(
    conclusionGroup: FormConclusionGroup,
    params: Record<string, unknown>,
  ): Promise<void>;
  updateConclusion(conclusionGroup: FormConclusionGroup, conclusion: FormConclusion): void;
  removeConclusion(conclusionGroup: FormConclusionGroup, conclusions: Array<FormConclusion>): void;
  removeConclusionGroup(conclusionGroup: FormConclusionGroup): void;
  upsertComponent(formComponent: FormComponent): Promise<void>;
  removePlaceholder(): void;
};
const FormEditorContext = createContext<FormEditorContextValue | undefined>(undefined);

type ComponentPlaceholder = {
  parent: string | null;
  index: number;
  component: FormComponent;
};

interface IFormEditorProviderProps {
  form: Form | undefined;
  roomId: string;
  formId: string;
  setIsEditing(isEditing: boolean): void;
}
const FormEditorProvider: FC<IFormEditorProviderProps> = observer(
  ({ form, roomId, formId, setIsEditing, children }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [dragAction, setDragAction] = useState<{
      action: DraggingAction;
      depth: number | undefined;
    }>(() => ({
      action: DraggingAction.None,
      depth: undefined,
    }));
    const [placeholder, setPlaceholder] = useState<ComponentPlaceholder | null>(null);

    const datx = useDatx();

    const {
      data: components,
      addComponent,
      reorderComponents,
      mutate: refreshComponents,
      removeComponent,
      updateComponent,
    } = useFormsComponentsWithMutate(roomId, formId, setIsLoading);

    const {
      data: conclusions,
      addConclusionGroup,
      addConclusion,
      updateConclusion,
      removeConclusion,
      removeConclusionGroup,
    } = useConclusionWithMutations(roomId, formId, setIsLoading);
    const currentUserEmail = useCurrentUserEmail();

    const onDragStart: OnDragStartResponder = useCallback(
      ({ draggableId, source }) => {
        const dragAction = inferDraggingAction(draggableId, source.droppableId);
        let depth: number | undefined;
        if (dragAction === DraggingAction.MoveQuestion) {
          const draggableModelId = getModelIdFromDraggable(draggableId);
          const model = datx.findOne(FormComponent, draggableModelId);
          depth = model?.getDepth();
        }

        setDragAction({
          action: dragAction,
          depth: depth,
        });
      },
      [datx],
    );

    const onDragEnd: OnDragEndResponder = useCallback(
      ({ source, destination, draggableId }) => {
        const { dropAction, draggableComponentType, destinationId, modelId } = inferDropAction(
          draggableId,
          source.droppableId,
          destination?.droppableId,
        );

        switch (dropAction) {
          case DropAction.AddAddon: {
            if (!destinationId || !draggableComponentType) {
              break;
            }

            const model = datx.findOne(FormComponent, destinationId);
            if (!model) {
              break;
            }

            const addonType = draggableComponentType as FormAddonType;
            const isAlreadyAdded = model.addons?.some((addon) => addon.addonType === addonType);
            if (isAlreadyAdded) {
              break;
            }

            if (
              addonType === FormAddonType.Condition &&
              model.index === 0 &&
              model.parent?.isSection &&
              model.parent.index === 0
            ) {
              // Business rule: "Condition" can be used for all types of questions, except to the first question.
              break;
            }

            if (
              addonType === FormAddonType.SkipCondition &&
              !(
                model.componentType === FormComponentType.Confirmation ||
                model.componentType === FormComponentType.SingleChoice ||
                model.componentType === FormComponentType.ScaleNumber ||
                model.componentType === FormComponentType.ScaleText
              )
            ) {
              // Business rule: Skip logic" can only be used for single choice, confirmation and scale.
              break;
            }

            if (addonType === FormAddonType.MoreAnswers) {
              if (model.isQuestionSet || model.componentType === FormComponentType.MultipleChoice) {
                // business rule;
                break;
              }

              if (
                !model.isInQuestionSet &&
                (model.componentType === FormComponentType.Confirmation ||
                  model.componentType === FormComponentType.ScaleNumber ||
                  model.componentType === FormComponentType.ScaleText ||
                  model.componentType === FormComponentType.Statement ||
                  model.componentType === FormComponentType.QuestionSet)
              ) {
                // business rule;
                break;
              }
            }

            const addons = model.addons;

            addons.push({ addonType, settingsJson: null });
            model.update({ addonsJson: JSON.stringify(addons) });

            if (!addonHasSettings(addonType)) {
              updateComponent(model);
            }
            break;
          }
          case DropAction.AddSectionComponent:
            if (destination) {
              const sectionPlaceholder = datx.add<FormComponent>(
                {
                  componentType: FormComponentType.Section,
                  index: destination.index,
                  formId: formId,
                },
                FormComponent,
              );
              setPlaceholder({
                parent: null,
                index: destination.index,
                component: sectionPlaceholder,
              });
              setIsEditing(true);
            }
            break;
          case DropAction.AddQuestionComponent:
            if (destination && destinationId && draggableComponentType) {
              const newParent = datx.findOne(FormComponent, destinationId);
              const questionPlaceholder = datx.add<FormComponent>(
                {
                  componentType: draggableComponentType,
                  formId: formId,
                  index: destination.index,
                  parentComponentId: destinationId,
                  parent: newParent,
                },
                FormComponent,
              );
              setPlaceholder({
                parent: destinationId,
                index: destination.index,
                component: questionPlaceholder,
              });
              setIsEditing(true);
            }
            break;
          case DropAction.MoveQuestion:
            if (destination && destinationId && modelId) {
              const model = datx.findOne(FormComponent, modelId);
              if (!model) {
                break;
              }

              const oldIndex = model.index;
              const oldParentId = model.parentComponentId;
              if (
                !oldParentId ||
                (oldParentId === destinationId && oldIndex === destination.index)
              ) {
                break;
              }

              const errors = validateMoveComponent(
                datx.getCopy(),
                model.id,
                destination.index,
                destinationId,
              );
              if (errors.length) {
                PopAlertFail(t('form.errors.components.moveConditional'));
                break;
              }

              const componentsToShift = moveComponentAndUpdateAffectedComponents(
                datx,
                model,
                destination.index,
                destinationId,
              );
              reorderComponents(componentsToShift);
            }
            break;
          case DropAction.MoveSection:
            if (destination && modelId) {
              const model = datx.findOne(FormComponent, modelId);
              if (!model) {
                break;
              }
              const oldIndex = model.index;
              if (oldIndex === destination.index) {
                break;
              }

              const errors = validateMoveComponent(datx.getCopy(), model.id, destination.index);
              if (errors.length) {
                PopAlertFail(t('form.errors.components.moveConditional'));
                break;
              }

              const componentsToShift = moveComponentAndUpdateAffectedComponents(
                datx,
                model,
                destination.index,
              );

              reorderComponents(componentsToShift);
            }
            break;
          case DropAction.AddConclusion:
            if (destination && modelId) {
              addConclusionGroup();
            }
            break;
          case DropAction.AddConditionGroup:
            if (destination && modelId) {
              const initialConclusion = datx.add<FormConclusion>(
                {
                  conclusionType: FormConclusionType.Inclusive,
                  conditionGroup: {
                    operator: ConditionRuleOperator.And,
                    conditionRules: [],
                    conditionRuleGroups: [],
                  },
                },
                FormConclusion,
              );
              const conclusionGroupId = destination.droppableId.substring(
                'conclusionGroup-'.length,
              );
              const conclusionGroup = datx.findOne(
                FormConclusionGroup,
                conclusionGroupId,
              ) as FormConclusionGroup;
              addConclusion(conclusionGroup, initialConclusion.initialDto);
            }
            break;
          case DropAction.MoveConclusion:
          case DropAction.None:
          default:
            break;
        }
        setDragAction({
          action: DraggingAction.None,
          depth: undefined,
        });
      },
      [
        datx,
        updateComponent,
        formId,
        setIsEditing,
        t,
        reorderComponents,
        addConclusionGroup,
        addConclusion,
      ],
    );

    const removePlaceholder = () => {
      setPlaceholder(null);
      setIsEditing(false);
    };

    const upsertComponent = (component: FormComponent) => {
      if (component.isPlaceholder) {
        setPlaceholder(null);
        setIsEditing(false);
        return addComponent(component);
      }
      return updateComponent(component);
    };

    const { isEditable = false } = form ?? {};
    const disableEdit = !isEditable || !form?.hasEditor(currentUserEmail);
    const allowLimitedEditOnFinalized = Boolean(form?.isEditableFinalizedFor(currentUserEmail));

    return (
      <FormEditorContext.Provider
        value={{
          form,
          formId,
          roomId,
          dragAction: dragAction.action,
          dragComponentDepth: dragAction.depth,
          components: components ?? [],
          placeholder,
          conclusions: conclusions ?? [],
          disableEdit,
          allowLimitedEditOnFinalized,
          addComponent,
          reorderComponents,
          // @ts-expect-error
          refreshComponents,
          updateComponent,
          removeComponent,
          removePlaceholder,
          addConclusion,
          addConclusionGroup,
          updateConclusion,
          removeConclusion,
          removeConclusionGroup,
          upsertComponent,
        }}
      >
        <FullScreenLoadingOverlay isLoading={!components || isLoading}>
          <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            {children}
          </DragDropContext>
        </FullScreenLoadingOverlay>
      </FormEditorContext.Provider>
    );
  },
);

const useFormEditor = () => {
  const context = useContext(FormEditorContext);
  if (context === undefined) {
    throw new Error('useFormEditor must be used within a FormEditorProvider');
  }

  return context;
};

export { FormEditorProvider, useFormEditor };
