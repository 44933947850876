import { FC } from 'types';
import { Box } from '@chakra-ui/react';
import { useTranslation, TFunction } from 'react-i18next';
import { SelectTable } from 'appkit-react';

import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { useTrash } from 'hooks/useTrash';
import { TableCellRow } from 'typings/types/TableCellRow';
import { dateSortMethod } from 'utils/dateSortMethod';

import { RoomsTrashViewActions } from './components/RoomsTrashViewActions';
import { useTrashTable } from './RoomsTrashView.hooks';

interface IRoomsTrashView {
  trashSearchQuery: string;
}

const trashItemsListColumns = (t: TFunction) => [
  {
    Header: t('rooms.trashView.tableColumns.name'),
    accessor: 'name',
    minWidth: 300,
  },
  {
    Header: t('rooms.trashView.tableColumns.roomName'),
    accessor: 'roomName',
    minWidth: 300,
  },
  {
    Header: t('rooms.trashView.tableColumns.type'),
    accessor: 'type',
    Cell: (props: TableCellRow) =>
      props.original.type ? t(`rooms.trashView.types.${props.original.type}`) : null,
    minWidth: 100,
  },
  {
    Header: t('rooms.trashView.tableColumns.owner'),
    accessor: 'owner.username',
    minWidth: 100,
  },
  {
    Header: t('rooms.trashView.tableColumns.deletedBy'),
    accessor: 'deletedBy.username',
    minWidth: 100,
  },
  {
    Header: t('rooms.trashView.tableColumns.deletedOn'),
    accessor: 'formattedDeletedAt',
    minWidth: 200,
    sortMethod: dateSortMethod,
  },
  {
    Header: t('rooms.trashView.tableColumns.lastEdit'),
    accessor: 'formattedLastEdit',
    minWidth: 200,
    sortMethod: dateSortMethod,
  },
];

export const RoomsTrashView: FC<IRoomsTrashView> = ({ trashSearchQuery }) => {
  const { filteredData, isInitialLoad, isValidating } = useTrash(trashSearchQuery);

  const { t } = useTranslation();
  const {
    isSelected,
    toggleAll,
    toggleSelection,
    selectTable,
    selectAll,
    selection,
    resetSelection,
  } = useTrashTable();

  const tableProps = { isSelected, toggleAll, toggleSelection, selectAll };

  return (
    <Box mt="20px">
      <TableWrapper isLoading={isInitialLoad || isValidating}>
        <SelectTable
          ref={selectTable}
          columns={trashItemsListColumns(t)}
          data={filteredData()}
          defaultPageSize={10}
          showPageSizeOptions={false}
          defaultSorted={[{ id: 'name', asc: true }]}
          selectType="checkbox"
          keyField="id"
          {...tableProps}
        />
        <RoomsTrashViewActions
          selection={selection}
          resetSelection={resetSelection}
          isSelectAll={selectAll}
        />
      </TableWrapper>
    </Box>
  );
};
