import { useSWRConfig } from 'swr';

import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { Domain } from 'models/Domain';
import { DomainAppAdmin } from 'models/DomainAppAdmin';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';

export const useDomainMutation = (domain: Domain | DomainAppAdmin) => {
  const dependencyHeaders = useDependencyHeaders();
  const { mutate: globalMutate } = useSWRConfig();

  const mutate = async (revalidateKey?: string) => {
    const url =
      domain.meta.type === DomainAppAdmin.type
        ? DomainAppAdmin.endpoint
        : (domain.meta.type as string);

    await apiClient(url, HttpMethod.Delete, {
      headers: {
        'Content-Type': 'application/json',
        ...dependencyHeaders,
      },
      body: JSON.stringify({ id: domain.id, siteId: domain.siteId }),
    });

    if (revalidateKey) {
      globalMutate(revalidateKey);
    }
  };

  return { mutate };
};
