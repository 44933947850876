import React, { Factory } from 'react';
import { Switch } from 'appkit-react';
import { BooleanWidgetProps } from 'react-awesome-query-builder';

export const AQBBoolean: Factory<BooleanWidgetProps> = (props) => {
  const onChange = () => {
    props?.setValue(!props?.value);
  };

  return (
    <Switch
      onText={props?.labelYes}
      offText={props?.labelNo}
      checked={!!props?.value}
      disabled={props?.readonly}
      onChange={onChange}
    />
  );
};
