import { getIdToken } from 'utils/getIdToken';
import { config } from 'config'

const API_URL = `${config.apiUrl}/api/`;

// Single form
export const formJsonExportFetcher = (siteId: string, formId: string) => () =>
  fetch(`${API_URL}forms/${formId}/jsonExport`, {
    headers: {
      'x-site-id': siteId,
      'x-id-token': getIdToken() ?? '',
    },
  });

export const formExcelExportFetcher = (siteId: string, formId: string) => () =>
  fetch(`${API_URL}forms/${formId}/excelExport`, {
    headers: {
      'x-site-id': siteId,
      'x-id-token': getIdToken() ?? '',
    },
  });

// For room
export const roomFormsExportFetcher = (siteId: string, roomId: string) => () =>
  fetch(`${API_URL}rooms/${roomId}/forms/export`, {
    headers: {
      'x-site-id': siteId,
      'x-id-token': getIdToken() ?? '',
    },
  });
