import { useSWRConfig } from 'swr';

import { Form } from 'models/Form';
import { RoomFolder } from 'models/RoomFolder';
import { useNestedFolders, useNestedForms, useRoomId } from '../room.hooks';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { useResources } from 'hooks/useResources';
import { useRoomForms } from 'hooks/useRoomForms';

export const useRoomItemsList = (searchQuery: string) => {
  const roomId = useRoomId();

  RoomFolder.endpoint = `rooms/${roomId}/${RoomFolder.type}`;
  const roomFolders = useResources(RoomFolder);
  RoomFolder.endpoint = undefined;

  const roomForms = useRoomForms(roomId);

  const folders = useNestedFolders(roomFolders.data as Array<RoomFolder>, searchQuery);
  const forms = useNestedForms(roomForms.data, searchQuery);
  const data = [...folders, ...forms];

  return {
    isInitialLoad: roomForms.isInitialLoad || roomFolders.isInitialLoad,
    isRevalidating: roomForms.isValidating || roomFolders.isValidating,
    data: data.filter((item) =>
      (item as RoomFolder | Form).name.toLowerCase().includes(searchQuery),
    ),
  };
};

export const useFormDelete = (id: string) => {
  const depHeaders = useDependencyHeaders();
  const roomId = useRoomId();
  const { mutate: globalMutate } = useSWRConfig();

  const revalidateKey = `rooms/${roomId}/${Form.type}`;
  const url = `${revalidateKey}/${id}`;

  const mutate = async (data?: Record<string, unknown>, options?: Record<string, unknown>) => {
    await apiClient(url, HttpMethod.Delete, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
      body: JSON.stringify(data),
    });

    globalMutate(`${revalidateKey}@${depHeaders['x-site-id']}`);
  };

  return { mutate };
};

export const useFormCopy = (form?: Form, roomId?: string) => {
  const depHeaders = useDependencyHeaders();

  const url = `rooms/${roomId}/${Form.type}/${form?.id}/sendCopy`;

  const mutate = async (data?: Record<string, unknown>, options?: Record<string, unknown>) => {
    await apiClient(url, HttpMethod.Post, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
      body: JSON.stringify(data),
    });
  };

  return {
    mutate,
  };
};

export const useFormMutate = (form?: Form, method = HttpMethod.Post, path?: string) => {
  const depHeaders = useDependencyHeaders();
  const roomId = useRoomId();
  const { mutate: globalMutate } = useSWRConfig();

  const revalidateKey = `rooms/${roomId}/${Form.type}`;

  const mutate = async (data?: Record<string, unknown>, options?: Record<string, unknown>) => {
    const url = `${revalidateKey}${path}`;
    await apiClient(url, method, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
      body: JSON.stringify(data),
    });

    globalMutate(`${revalidateKey}@${depHeaders['x-site-id']}`);
  };

  const mutateUsers = async (data?: Record<string, unknown>, options?: Record<string, unknown>) => {
    const url = `rooms/${roomId}/forms${path}/user`;
    await apiClient(url, method, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
      body: JSON.stringify(data),
    });

    globalMutate(`${revalidateKey}@${depHeaders['x-site-id']}`);
  };

  return {
    mutate,
    mutateUsers,
  };
};
