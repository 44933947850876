import { Room } from 'models/Room';
import { RoomFolder } from 'models/RoomFolder';
import { RoomFolderUser } from 'models/RoomFolderUser';
import { FolderUserRole } from 'typings/enums/FolderUserRole';
import { IFolderUser } from 'typings/interfaces/IFolderUser';
import { IResourceUser } from 'typings/interfaces/IResourceUser';

export const transformUserToFolderUser = (
  roomUser: IResourceUser,
  folderUserRole: FolderUserRole = FolderUserRole.Member,
): IFolderUser => ({
  id: roomUser.id,
  userId: roomUser.userId,
  username: roomUser.username,
  email: roomUser.email,
  folderUserRole: folderUserRole,
  displayName: roomUser.displayName,
});
export const transformToFolderUsers = (folder: RoomFolder, room: Room) => {
  return folder.users.reduce((acc: Array<IFolderUser>, folderUser: RoomFolderUser) => {
    const roomUser = room.roomUsers.find((roomUser) => folderUser.userId === roomUser.userId);
    if (roomUser) {
      acc.push(transformUserToFolderUser(roomUser, folderUser.folderUserRole));
    }
    return acc;
  }, []);
};
