import { FC } from 'types';
import { Props as SimpleBarProps } from 'simplebar-react';

import { StyledSimpleBar } from './Scrollbar.elements';

import 'simplebar/dist/simplebar.css';

interface IScrolbarProps extends SimpleBarProps {
  maxHeight?: string;
  height?: string;
}

export const Scrollbar: FC<IScrolbarProps> = ({
  maxHeight,
  height,
  autoHide = false,
  children,
}) => {
  return (
    <StyledSimpleBar
      autoHide={autoHide}
      sx={{
        height: height,
        maxHeight: maxHeight,
        pr: '20px',
      }}
    >
      {children}
    </StyledSimpleBar>
  );
};
