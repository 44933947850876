import { FC } from 'types';
import { Text, TextProps } from '@chakra-ui/react';

import { StyledBadge } from 'components/core/StyledAppKit/StyledAppKit';

interface ITotalProps extends TextProps {
  label: string;
  total: number | string;
}

export const Total: FC<ITotalProps> = ({ label, total, ...rest }) => {
  return (
    <Text size="md" mb={8} {...rest}>
      {label}
      <StyledBadge size="small" ml={12}>
        {total}
      </StyledBadge>
    </Text>
  );
};
