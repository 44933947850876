import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { FC } from 'types'

import { IllustrationBox } from '../IllustrationBox/IllustrationBox';

interface IFeatureItemProps extends BoxProps {
  illustrationSrc: string | Array<string>;
  illustrationAlt: string;
  title: string;
  content: string;
  onClick(): void;
}

export const FeatureItem: FC<IFeatureItemProps> = ({
  illustrationSrc,
  illustrationAlt,
  title,
  content,
  onClick,
}) => {
  return (
    <Box cursor="pointer" onClick={onClick}>
      <IllustrationBox src={illustrationSrc} alt={illustrationAlt} />
      <Box
        as="h3"
        sx={{
          fontSize: '22px',
          lineHeight: '36px',
          color: 'white',
          mt: '34px',
          mb: '14px',
          textAlign: 'center',
        }}
      >
        {title}
      </Box>
      <Box
        as="p"
        sx={{
          fontSize: '14px',
          lineHeight: '21px',
          color: 'white',
        }}
      >
        {content}
      </Box>
    </Box>
  );
};
