import { FC } from 'types';
import { Fragment } from 'react';
import { Box, Text, HStack, useBoolean, VStack, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useDatx } from 'hooks/useDatx';
import { Room } from 'models/Room';
import { RoomHistory } from 'components/room/RoomHistory/RoomHistory';
import { RoomDetailsRow } from 'components/room/RoomDetails/components/RoomDetailsRow/RoomDetailsRow';
import { FolderDelete } from 'components/room-folder/FolderDelete/FolderDelete';
import { FolderNameRow } from 'components/room-folder/FolderNameRow/FolderNameRow';
import { FolderUsersRow } from 'components/room-folder/FolderUsersRow/FolderUsersRow';
import { RoomFolder } from 'models/RoomFolder';
import { useCurrentRoomFolders } from 'hooks/useCurrentRoomFolders';
import { useCurrentUserEmail } from 'hooks/useCurrentUserEmail';

interface IRoomFolderDetailsProps {
  room: Room;
  folderId: string;
}

export const RoomFolderDetails: FC<IRoomFolderDetailsProps> = ({ room, folderId }) => {
  const datx = useDatx();
  const { isInitialLoad } = useCurrentRoomFolders();
  const [isEditInProgress, setEditInProgress] = useBoolean(false);
  const history = useHistory();
  const { t } = useTranslation();
  const currentUserEmail = useCurrentUserEmail();

  const folder = datx.findOne(RoomFolder, folderId);
  const isFolderOwner = folder?.userIsOwner(currentUserEmail);

  if (!folder && !isInitialLoad) {
    history.push('/rooms');
  }

  return folder ? (
    <Fragment>
      <VStack spacing={16} marginTop={36}>
        <Fragment>
          <FolderNameRow
            model={folder}
            roomId={room.id}
            disableEdit={isEditInProgress}
            readonly={!isFolderOwner || !room.isRoomAdmin}
            onStartEdit={setEditInProgress.on}
            onStopEdit={setEditInProgress.off}
          />
          <RoomDetailsRow>
            <Heading as="h5" size="sm" w="100px">
              {t('room.sidebar.folderDetails.rows.owner')}
            </Heading>
            <Box w="200px">
              <Text>{folder.folderOwner?.username}</Text>
            </Box>
          </RoomDetailsRow>
          <FolderUsersRow
            model={folder}
            roomId={room.id}
            disableEdit={isEditInProgress}
            onStartEdit={setEditInProgress.on}
            onStopEdit={setEditInProgress.off}
          />
          <RoomDetailsRow>
            <Heading as="h5" size="sm" w="100px">
              {t('room.sidebar.folderDetails.rows.lastEdit')}
            </Heading>
            <Box w="200px">
              <Text>{folder.formattedLastEdit}</Text>
            </Box>
          </RoomDetailsRow>
        </Fragment>
      </VStack>
      <HStack justifyContent="flex-end" spacing={20} mt={20}>
        {(room.isRoomAdmin || isFolderOwner) && (
          <FolderDelete disabled={isEditInProgress} roomId={room.id} model={folder} />
        )}
        <RoomHistory />
      </HStack>
    </Fragment>
  ) : null;
};
