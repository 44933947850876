import { Controller, useForm } from 'react-hook-form';
import { Box, Text, useBoolean, VStack } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/modal/Modal/Modal';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { useAssessment } from 'hooks/useAssessment';
import { PopAlertFail } from 'services/PopAlertFail';
import { Upload } from 'components/core/Upload/Upload';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { IAttachmentFile } from 'typings/interfaces/dto/IAttachmentFile';
import { AttachmentFileStatus } from 'typings/enums/AttachmentFileStatus';
import { PopAlertSuccess } from 'services/PopAlertSuccess';

interface IAssessmentQuestionAttachmentModalProps {
  assessmentId: string;
  orderTag: string;
  attachments: Array<IAttachmentFile>;
  disabled?: boolean;
  onClose(): void;
  handleSave(attachments: Array<IAttachmentFile>): void;
}

interface IAttachmentAddonSettingsFormValues {
  formFiles: Array<IAttachmentFile>;
}

export const AssessmentQuestionAttachmentModal: FC<IAssessmentQuestionAttachmentModalProps> = ({
  orderTag,
  assessmentId,
  disabled,
  onClose,
  handleSave,
  attachments,
}) => {
  const { t } = useTranslation();
  const dependencyHeaders = useDependencyHeaders();
  const { assessment } = useAssessment(assessmentId);
  const [loading, setLoading] = useBoolean();
  const [uploading, setUploading] = useBoolean();
  const { control, handleSubmit } = useForm<IAttachmentAddonSettingsFormValues>({
    defaultValues: { formFiles: attachments },
  });

  const onSubmit = async (values: IAttachmentAddonSettingsFormValues) => {
    if (disabled) {
      return;
    }

    try {
      setLoading.on();

      await Promise.all(
        (values.formFiles ?? []).reduce((acc: Array<Promise<unknown>>, item: IAttachmentFile) => {
          if (item.fileStatus === AttachmentFileStatus.Initiated) {
            acc.push(
              apiClient(
                `assessments/${assessment?.id}/AssessmentFiles/${item.fileId}/validate`,
                HttpMethod.Put,
                {
                  headers: {
                    ...dependencyHeaders,
                  },
                },
              ),
            );
          }
          return acc;
        }, []),
      );
      PopAlertSuccess(t('upload.success'));

      handleSave(values?.formFiles);
    } catch (e) {
      PopAlertFail(t('upload.error'));
    } finally {
      setLoading.off();
      onClose();
    }
  };

  return (
    <Modal isVisible maxWidth="750px">
      <ModalHeader onCancel={onClose}>
        <ModalTitle>{t('form.addon.attachment.title', { index: orderTag })}</ModalTitle>
      </ModalHeader>
      <ModalBody py={40} px={20}>
        <Box as="form" onSubmit={handleSubmit(onSubmit)}>
          <VStack
            className="a-apply-theme-background-gray-level-1"
            alignItems="flex-start"
            width="100%"
            py={12}
            px={20}
          >
            <Text as="label" size="md">
              {`${t('form.addon.attachment.label')} ${t('upload.maxSizeWarning')}`}
            </Text>
            <Controller
              control={control}
              name="formFiles"
              // eslint-disable-next-line
              render={({ field: { ref: _ref, ...fieldProps } }) => (
                <Upload
                  getUploadUrlEndpoint={`assessments/${assessment?.id}/AssessmentFiles`}
                  getDownloadUrl={(fileId: string) =>
                    `assessments/${assessmentId}/assessmentFiles/${fileId}`
                  }
                  onStartUploadFile={setUploading.on}
                  onEndUploadFile={setUploading.off}
                  disabled={disabled}
                  {...fieldProps}
                />
              )}
            />
          </VStack>

          <FormActions>
            <FormButtonCancel onClick={onClose} />
            <FormButtonSubmit disabled={disabled || loading || uploading} isLoading={loading} />
          </FormActions>
        </Box>
      </ModalBody>
    </Modal>
  );
};
