import { Box, VStack, Text, Center } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { Scrollbar } from 'components/core/ScrollBar/Scrollbar';

interface IHistoryModalContentProps {
  isLoading: boolean;
}

export const HistoryModalContent: FC<IHistoryModalContentProps> = ({ isLoading, children }) => {
  const { t } = useTranslation();

  return (
    <Scrollbar height="530px">
      {isLoading ? (
        <Center flexDirection="column">
          <Box className="a-loading a-primary" />
          <Text size="xl" mt={20}>
            {t('loading.fetching.data')}
          </Text>
        </Center>
      ) : (
        <VStack spacing={20} mr={12} pr={20}>
          {children}
        </VStack>
      )}
    </Scrollbar>
  );
};
