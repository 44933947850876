import { useDisclosure } from '@chakra-ui/react';
import { FC } from 'types';
import { Fragment } from 'react';

import { ToolbarIconButton } from 'components/core/ToolbarIconButton/ToolbarIconButton';
import { ToolbarActionIcon } from 'typings/enums/ToolbarActionIcon';

import { FormDeleteModal } from '../FormDeleteModal/FormDeleteModal';

interface IFormDeleteButtonProps {
  roomId: string;
  formId: string;
  disabled?: boolean;
}

export const FormDeleteButton: FC<IFormDeleteButtonProps> = ({ disabled, ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Fragment>
      {isOpen ? (
        <ToolbarIconButton
          action={ToolbarActionIcon.DeleteActive}
          disabled={disabled}
          onClick={onClose}
        />
      ) : (
        <ToolbarIconButton action={ToolbarActionIcon.Delete} disabled={disabled} onClick={onOpen} />
      )}
      {isOpen && <FormDeleteModal onClose={onClose} {...rest} />}
    </Fragment>
  );
};
