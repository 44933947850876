import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'types';

import { Form } from 'models/Form';
import { FormEditorSidebar } from 'components/form-editor/FormEditorSidebar/FormEditorSidebar';
import { FormEditorProvider } from 'context/FormEditorContext/FormEditorContextProvider';

import { FormEditorSectionsContainer } from './components/FormEditorSectionsContainer/FormEditorSectionsContainer';
import { FormEditorConclusionsContainer } from './components/FormEditorConclusionsContainer/FormEditorConclusionsContainer';

interface IFormEditorContainerProps {
  formId: string;
  roomId: string;
  form: Form | undefined;
  isEditing: boolean;
  setIsEditing(isEditing: boolean): void;
}

export const FormEditorContainer: FC<IFormEditorContainerProps> = ({
  roomId,
  formId,
  form,
  isEditing,
  setIsEditing,
}) => {
  return (
    <FormEditorProvider form={form} roomId={roomId} formId={formId} setIsEditing={setIsEditing}>
      <Flex height="100%">
        <Box sx={{ flex: '1', minHeight: '100%' }}>
          <FormEditorSectionsContainer />
          <FormEditorConclusionsContainer />
        </Box>
        <Box width="340px" ml={40}>
          <Box position="sticky" top="50px">
            <FormEditorSidebar form={form} formId={formId} roomId={roomId} isEditing={isEditing} />
          </Box>
        </Box>
      </Flex>
    </FormEditorProvider>
  );
};
