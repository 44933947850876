import { createContext, useState } from 'react';
import { FC } from 'types';
import { AppStore } from '../store/AppStore';

export const DatxContext = createContext<AppStore | null>(null);

export const DatxProvider: FC = ({ children }) => {
  const [store] = useState(() => {
    const store = new AppStore();
    if (process.env.NODE_ENV === 'development') {
      //@ts-ignore
      window['store'] = store;
    }

    return store;
  });

  return <DatxContext.Provider value={store}>{children}</DatxContext.Provider>;
};
