import { useDisclosure, Box } from '@chakra-ui/react';
import { FC } from 'types';
import { useRef } from 'react';

import { AssessmentView } from 'models/AssessmentView';
import { CollaboratorsModal } from 'components/assessment/CollaboratorsModal/CollaboratorsModal';
import { StyledLink } from 'components/core/StyledLink/StyledLink';
import { useMenuActions } from 'hooks/useMenuActions';

import { AssessmentTableViewMenu } from '../AssessmentTableViewMenu/AssessmentTableViewMenu';
import { AssessmentsExportModal } from 'components/assessments/AssessmentsExportModal/AssessmentsExportModal';

interface IAssessmentTableViewNameCellProps {
  assessmentViewItem: AssessmentView;
}

export const AssessmentTableViewNameCell: FC<IAssessmentTableViewNameCellProps> = ({
  assessmentViewItem,
}) => {
  const {
    isOpen: isEditCollaboratorModalOpen,
    onClose: onEditCollaboratorClose,
    onOpen: onEditCollaboratorOpen,
  } = useDisclosure();
  const {
    isOpen: isExportModalOpen,
    onClose: onExportModalClose,
    onOpen: onExportModalOpen,
  } = useDisclosure();

  const linkRef = useRef<HTMLAnchorElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);

  const {
    isOpen: isMenuOpen,
    onClose: onMenuClose,
    menuPosition,
  } = useMenuActions(linkRef, cellRef);

  return (
    <Box ref={cellRef} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
      <StyledLink
        title={assessmentViewItem.name}
        cursor="pointer"
        to={`assessment/${assessmentViewItem.id}`}
        ref={linkRef}
      >
        {assessmentViewItem.name}
      </StyledLink>

      {isMenuOpen && (
        <AssessmentTableViewMenu
          top={menuPosition?.y}
          left={menuPosition?.x}
          assessmentViewItem={assessmentViewItem}
          onEditCollaboratorOpen={onEditCollaboratorOpen}
          onExportModalOpen={onExportModalOpen}
          onClose={onMenuClose}
        />
      )}
      {isEditCollaboratorModalOpen && (
        <CollaboratorsModal
          assessmentId={assessmentViewItem.id}
          onClose={onEditCollaboratorClose}
        />
      )}
      {isExportModalOpen && (
        <AssessmentsExportModal
          selectedIds={[assessmentViewItem.id]}
          onClose={onExportModalClose}
        />
      )}
    </Box>
  );
};
