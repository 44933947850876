import { FC } from 'types';
import { useMemo} from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { Table } from 'appkit-react';
import { updateModel } from '@datx/core';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { Scrollbar } from 'components/core/ScrollBar/Scrollbar';
import { Campaign } from 'models/Campaign';
import { CampaignPermissionGroup } from 'models/CampaignPermissionGroup';
import { FormComponent } from 'models/FormComponent';
import { TableCellRow } from 'typings/types/TableCellRow';
import { useDatx } from 'hooks/useDatx';
import { getFlatComponentTree } from 'services/getFormComponentsFlatTree';
import { compareNumberByNumberArrayFn } from 'utils/compareNumberByNumberArrayFn';

import { PermissionGroupsFormTableColumns } from './components/PermissionGroupsFormTableColumns/PermissionGroupsFormTableColumns';

interface IPermissionGroupsFormTableProps extends BoxProps {
  campaign: Campaign;
  isLoading: boolean;
}

interface ISelectHandlerOptions {
  selectedComponentId: string;
  permissionGroup: CampaignPermissionGroup;
  shouldRemove: boolean;
  component?: FormComponent;
}

const permissionGroupsColumns = () => [
  {
    Header: 'Questions',
    accessor: 'displayValue',
    Cell: (props: TableCellRow) => (
      <Box as="span" whiteSpace="pre">
        {props.original.displayValue}
      </Box>
    ),
    minWidth: 300,
  },
];

export const PermissionGroupsFormTable: FC<IPermissionGroupsFormTableProps> = observer(
  ({ campaign, isLoading }) => {
    const { t } = useTranslation();
    const datx = useDatx();

    const components = useMemo(
      () =>
        (campaign.components?.sort((a, b) =>
          compareNumberByNumberArrayFn(a.order, b.order),
        ) as unknown as Array<FormComponent>) ?? [],
      [campaign.components],
    );

    const handleSelectAll = (permissionGroupId: string) => {
      const permissionGroup = campaign.permissionGroups.find(
        (group) => group.id === permissionGroupId,
      );

      const allComponents = campaign.components?.map((component) => component.id);
      const emptyComponents: Array<string> = [];

      updateModel(permissionGroup as unknown as CampaignPermissionGroup, {
        componentIds: permissionGroup?.componentIds?.length ? emptyComponents : allComponents,
      });
    };

    const handleComponentSelect = ({
      permissionGroup,
      selectedComponentId,
      shouldRemove,
    }: ISelectHandlerOptions) => {
      const component = datx.findOne(FormComponent, selectedComponentId) as FormComponent;
      const selectedIds: Array<string> =
        component.isQuestionSet || component.isSection
          ? getFlatComponentTree(datx.findAll(FormComponent), selectedComponentId)
          : [selectedComponentId];

      if (shouldRemove) {
        updateModel(permissionGroup, {
          componentIds: permissionGroup?.componentIds.filter(
            (component) => component !== selectedComponentId && !selectedIds.includes(component),
          ),
        });
      } else {
        updateModel(permissionGroup, {
          componentIds: [...(permissionGroup?.componentIds as Array<string>), ...selectedIds],
        });
      }
    };

    const handleSelect = (selectedComponentId: string, permissionGroupId: string) => {
      const permissionGroup = campaign.permissionGroups.find(
        (group) => group.id === permissionGroupId,
      ) as CampaignPermissionGroup;

      const shouldRemove = Boolean(
        permissionGroup?.componentIds.find((component) => component === selectedComponentId),
      );

      handleComponentSelect({ permissionGroup, shouldRemove, selectedComponentId });
    };

    return (
      <Box w="100%">
        <Scrollbar height="610px">
          <TableWrapper isLoading={isLoading}>
            <Table
              columns={[
                ...permissionGroupsColumns(),
                ...PermissionGroupsFormTableColumns(campaign, handleSelect, handleSelectAll),
              ]}
              data={[
                { displayValue: <p>{t('campaign.permissionGroups.selectUnselect')}</p> },
                ...components,
              ]}
              defaultPageSize={components.length + 1}
              showPageSizeOptions={false}
              showPagination={false}
            />
          </TableWrapper>
        </Scrollbar>
      </Box>
    );
  },
);
