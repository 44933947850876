import { useMemo } from 'react';

import { Room } from 'models/Room';
import { useResources } from 'hooks/useResources';
import { useRoomId } from 'components/room/room.hooks';

export const useCurrentRoom = () => {
  const roomId = useRoomId();

  const { data, ...rest } = useResources(Room);
  const room = useMemo(() => {
    let current: Room | null;

    if (data?.length === 0) {
      current = null;
    } else {
      current = (data as Array<Room>).find((item: Room) => item['id'] === roomId) || null;
    }

    return current;
  }, [data, roomId]);

  return {
    room,
    data,
    ...rest,
  };
};
