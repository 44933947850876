import { useAuth } from 'oidc-react';
import { useMemo } from 'react';
import { config } from 'config'

export const useProfile = () => {
  const auth = useAuth();

  const userData = useMemo(() => {
    const roles: string | Array<string> | undefined = auth.userData?.profile?.role;
    const appAdminRole = config.adminRole;
    const isAppAdmin =
      !appAdminRole || !roles || typeof roles === 'string' ? false : roles.includes(appAdminRole);

    return {
      ...auth.userData,
      isAppAdmin,
    };
  }, [auth.userData]);

  return userData;
};
