import { FC } from 'types';
import { useState } from 'react';
import { Input } from 'appkit-react';
import { Box } from '@chakra-ui/react';

interface IPermissionGroupsFormGroupsItemInputProps {
  initialValue: string;
  onBlur(inputValue: string): void;
}

export const PermissionGroupsFormGroupsItemInput: FC<IPermissionGroupsFormGroupsItemInputProps> = ({
  initialValue,
  onBlur,
}) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const [inputError, setInputError] = useState<string | null>(null);

  const handleBlur = () => {
    if (inputError) {
      return;
    }

    onBlur(inputValue);
    setInputValue('');
  };

  const handleChange = (value: string) => {
    if (value.length === 0) {
      return;
    }
    if (value.length > 50) {
      return;
    }

    setInputError(null);
    setInputValue(value);
  };

  return (
    <Box ml="1px">
      <Input defaultValue={initialValue} onChange={handleChange} onBlur={handleBlur} />
    </Box>
  );
};
