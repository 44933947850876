import { useSWRConfig } from 'swr';
import { useMemo } from 'react';

import { useResources } from 'hooks/useResources';
import { Room } from 'models/Room';
import { Form } from 'models/Form';
import { RoomFolder } from 'models/RoomFolder';
import { RoomFolderUser } from 'models/RoomFolderUser';
import { IFormUser } from 'typings/interfaces/IFormUser';
import { transformUserToFormUser } from 'services/transformUserToFormUser';
import { usePopAlertFail } from 'hooks/usePopAlertFail';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { apiClient } from 'utils/apiClient';

export const useAvailableFormUsers = (roomId: string, form: Form | null) => {
  RoomFolder.endpoint = `rooms/${roomId}/roomfolders`; // Refactor this
  const {
    data: roomFolders,
    isInitialLoad: isInitialLoadFolders,
    error: errorLoadingFolders,
    isValidating: isValidatingFolder,
  } = useResources(RoomFolder);
  RoomFolder.endpoint = undefined;

  const {
    isValidating: isValidatingRoom,
    data: rooms,
    isInitialLoad: isInitialLoadRooms,
    error: errorLoadingRooms,
  } = useResources(Room);

  const roomUsers = useMemo(() => {
    const formRoom = (rooms as Array<Room>).find((room) => room.id === roomId);
    const formFolder = (roomFolders as Array<RoomFolder>).find(
      (roomFolder) => roomFolder.id === form?.parentFolderId,
    );

    if (!formFolder && formRoom) {
      return formRoom.roomUsers.map((user) => transformUserToFormUser(user));
    }

    if (formFolder && formRoom) {
      return formFolder.users.reduce((acc: Array<IFormUser>, user: RoomFolderUser) => {
        const roomUser = formRoom.roomUsers.find((roomUser1) => user.userId === roomUser1.userId);
        if (roomUser) {
          acc.push(transformUserToFormUser(roomUser));
        }
        return acc;
      }, []);
    }

    return [];
  }, [form?.parentFolderId, roomFolders, roomId, rooms]);

  return {
    isValidating: isValidatingFolder || isValidatingRoom,
    roomUsers,
    isInitialLoad: !form || isInitialLoadFolders || isInitialLoadRooms,
    error: errorLoadingFolders || errorLoadingRooms,
  };
};

export const useFormManagementData = (
  formId: string,
  roomId: string,
  form: Form,
  onError: () => void,
) => {
  const {
    roomUsers,
    isValidating,
    isInitialLoad: isInitialLoadUsers,
    error: errorLoadingUsers,
  } = useAvailableFormUsers(roomId, form as Form | null);
  usePopAlertFail(errorLoadingUsers, async () => setTimeout(() => onError(), 500));

  return {
    isValidating,
    isInitialLoad: isInitialLoadUsers,
    roomUsers,
  };
};

export const useFormMutate = (form: Form, method = HttpMethod.Post, path?: string) => {
  const depHeaders = useDependencyHeaders();
  const roomId = form.room.id;
  const { mutate } = useSWRConfig();

  const url = `rooms/${roomId}/forms${path}`;

  const mutateForm = async (data?: Record<string, unknown>, options?: Record<string, unknown>) => {
    await apiClient(url, method, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
      body: JSON.stringify(data),
    });

    // TODO: check which keys need to be updated and where
    // /api/rooms/{roomId}/Forms
    // /api/forms/{id}
    // /api/forms
    // ?
    mutate(`rooms/${roomId}/forms@${depHeaders['x-site-id']}`);
  };
  const mutateFormUsers = async (
    data?: Record<string, unknown>,
    options?: Record<string, unknown>,
  ) => {
    const url = `rooms/${roomId}/forms${path}/user`;
    await apiClient(url, method, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
      body: JSON.stringify(data),
    });

    // TODO: check which keys need to be updated and where
    // /api/rooms/{roomId}/Forms
    // /api/forms/{id}
    // /api/forms
    // ?
    mutate(`rooms/${roomId}/forms@${depHeaders['x-site-id']}`);
  };

  return {
    mutateForm,
    mutateFormUsers,
  };
};
