import { useCallback, useEffect, useRef } from 'react';

export const useSetFocusWithDelay = <T extends HTMLElement>() => {
  const elementRef = useRef<T>(null);

  useEffect(() => {
    elementRef.current?.focus();
  }, []);

  const setFocusWithDelay = useCallback((delay?: number) => {
    setTimeout(() => {
      elementRef.current?.focus();
    }, delay);
  }, []);

  const setFocus = useCallback(() => {
    elementRef.current?.focus();
  }, []);

  return { elementRef, setFocus, setFocusWithDelay };
};
