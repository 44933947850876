import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FormComponent } from 'models/FormComponent';
import { useModal } from 'hooks/useModal';
import { IFormComponentAddonDto } from 'typings/interfaces/dto/IFormComponentAddonDto';

import { Addon } from '../Question/components/Addon/Addon';
import { HelpTextAddonSettingsEditorModal } from '../HelpTextAddonSettingsEditorModal/HelpTextAddonSettingsEditorModal';

interface IHelpTextAddonSettingsEditorProps {
  addon: IFormComponentAddonDto;
  question: FormComponent;
}
export const HelpTextAddonSettingsEditor: FC<IHelpTextAddonSettingsEditorProps> = ({
  addon,
  question,
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useModal();

  const hasInvalidSettings = addon.settingsJson === null;

  return (
    <Fragment>
      <Addon name={t('form.addon.helpText.name')} isInvalid={hasInvalidSettings} onClick={onOpen} />
      {isOpen && (
        <HelpTextAddonSettingsEditorModal addon={addon} question={question} onClose={onClose} />
      )}
    </Fragment>
  );
};
