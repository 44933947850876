import { FC } from 'types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormComponent } from 'models/FormComponent';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { FormModal } from 'components/modal/FormModal/FormModal';
import { DateSettings } from 'typings/types/FormComponentSettings';

import { DateSettingsForm } from '../DateSettingsForm/DateSettingsForm';
import { parseDateQuestionSettings } from './DateSettingsEditorModal.utils';

interface IDateSettingsEditorModalProps {
  question: FormComponent;
  orderTag: string;
  onClose(): void;
}
export const DateSettingsEditorModal: FC<IDateSettingsEditorModalProps> = ({
  orderTag,
  question,
  onClose,
}) => {
  const { t } = useTranslation();
  const { upsertComponent, disableEdit } = useFormEditor();

  const handleClickSave = useCallback(
    async (value: DateSettings) => {
      if (disableEdit) {
        return;
      }
      question.settingsJson = JSON.stringify(value);
      await upsertComponent(question);
      onClose();
    },
    [disableEdit, onClose, question, upsertComponent],
  );

  const parsed = parseDateQuestionSettings(question.settingsJson);

  return (
    <FormModal
      isOpen
      title={t('form.settings.modal.title', {
        questionNo: orderTag,
        questionType: t('form.settings.date.name'),
      })}
      maxWidth="550px"
      onClose={onClose}
    >
      <DateSettingsForm
        settings={parsed}
        disabled={disableEdit}
        onSubmit={handleClickSave}
        onCancel={onClose}
      />
    </FormModal>
  );
};
