import { FC } from 'types';
import { Box, BoxProps } from '@chakra-ui/react';

import { useDraggableTr } from 'context/DraggableTrContext';

export const DraggableTrDragHandle: FC<BoxProps> = ({ children, ...rest }) => {
  const { dragHandleProps } = useDraggableTr();

  if (children) {
    return (
      <Box {...dragHandleProps} {...rest}>
        {children}
      </Box>
    );
  }

  return (
    <Box
      visibility="hidden"
      as="span"
      className="appkiticon icon-move-outline a-font-16"
      w="24px"
      h="24px"
      {...dragHandleProps}
      {...rest}
    />
  );
};
