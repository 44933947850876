import { useDisclosure } from '@chakra-ui/react';
import { FC } from 'types';
import { Fragment } from 'react';

import { ToolbarIconButton } from 'components/core/ToolbarIconButton/ToolbarIconButton';
import { ToolbarActionIcon } from 'typings/enums/ToolbarActionIcon';
import { AssessmentHistoryModal } from '../HistoryModal/AssessmentHistoryModal';

interface IHistoryIconButtonProps {
  assessmentId: string;
}

export const HistoryIconButton: FC<IHistoryIconButtonProps> = ({ assessmentId }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Fragment>
      {isOpen ? (
        <ToolbarIconButton action={ToolbarActionIcon.TimeActive} onClick={onClose} />
      ) : (
        <ToolbarIconButton action={ToolbarActionIcon.Time} onClick={onOpen} />
      )}
      {isOpen && <AssessmentHistoryModal assessmentId={assessmentId} onClose={onClose} />}
    </Fragment>
  );
};
