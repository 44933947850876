import { IRoomUser } from 'typings/interfaces/IRoomUser';
import { UpsertRoomUserDTO } from 'typings/types/dto/RoomUserDTO';

import { IWithId, IWithUserId, transformResourceUserDto } from './transformResourceUserDto';

export interface IForm extends IWithId {
  users: Array<IWithId> | null;
}

export interface ITransformedForm extends IWithId {
  users: Array<IWithUserId> | null;
}

export const transformFormUsersData = (formUsersDto: Array<IWithId>, formId: string) =>
  formUsersDto.map((formUserDto) => transformResourceUserDto(formUserDto, formId));

const transformFormDto = (formDto: IForm): ITransformedForm => ({
  ...formDto,
  users: formDto.users ? transformFormUsersData(formDto.users, formDto.id) : null,
});

export const transformFormsData = (data: Array<IForm>): Array<ITransformedForm> =>
  data.map(transformFormDto);

export const transformRoomUsersToDto = (roomUsers: Array<IRoomUser>): Array<UpsertRoomUserDTO> =>
  roomUsers.map((user) => ({
    ...user,
    id: user.userId,
    userId: undefined,
  }));
