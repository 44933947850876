import { BasicConfig, Conjunctions } from 'react-awesome-query-builder';
import { TFunction } from 'react-i18next';

import { ConditionRuleOperator } from 'typings/enums/ConditionRuleOperator';

export const conjunctions = (t: TFunction): Conjunctions => ({
  And: {
    ...BasicConfig.conjunctions.AND,
    label: t(`form.conjunctions.${ConditionRuleOperator.And}`),
  },
  Or: {
    ...BasicConfig.conjunctions.OR,
    label: t(`form.conjunctions.${ConditionRuleOperator.Or}`),
  },
});
