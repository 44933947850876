import { Box, HStack, Spacer, Square, Text, VStack } from '@chakra-ui/react';
import { FC } from 'types';
import { Tooltip, Radio, SelectOption } from 'appkit-react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledSelectField } from 'fields/SelectField/SelectField';
import { RadioGroupField } from 'fields/RadioGroupField/RadioGroupField';
import {
  CountryFormatType,
  CountrySettings,
  ExportFormatType,
  UserInputFormatType,
} from 'typings/types/FormComponentSettings';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';

interface ICountrySettingsFormProps {
  settings: CountrySettings;
  disabled?: boolean;
  onSubmit(value: CountrySettings): void;
  onCancel(): void;
}

export const CountrySettingsForm: FC<ICountrySettingsFormProps> = ({
  settings,
  disabled,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<CountrySettings>({
    defaultValues: {
      formatType: settings.formatType,
      userInputFormatType: settings.userInputFormatType,
      exportAndStatisticsFormat: settings.exportAndStatisticsFormat,
    },
  });

  const handleSave = (values: CountrySettings) => {
    onSubmit(values);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)}>
      <VStack spacing={20}>
        <HStack width="100%" alignItems="flex-end">
          <RadioGroupField
            control={control as unknown as Control<FieldValues>}
            name="formatType"
            label={t('form.settings.country.formatTypeLabel').toUpperCase()}
            direction="row"
          >
            <Radio value={CountryFormatType.Country} disabled={disabled}>
              {t('form.settings.country.countryFormatType.Country')}
            </Radio>
            <Spacer />
            <Radio value={CountryFormatType.Language} disabled={disabled}>
              {t('form.settings.country.countryFormatType.Language')}
            </Radio>
            <Spacer />
          </RadioGroupField>
          <Square size="34px">
            <Tooltip content="Helper text" placement="top">
              <Text
                size="3xl"
                as="span"
                className="appkiticon icon-help-question-fill"
                color="brand.orange"
              />
            </Tooltip>
          </Square>
        </HStack>

        <HStack width="100%" alignItems="flex-end">
          <ControlledSelectField
            control={control as unknown as Control<FieldValues>}
            name="userInputFormatType"
            label={t('form.settings.country.userInputFormatTypeLabel')}
            disabled={disabled}
          >
            <SelectOption key={UserInputFormatType.Code} value={UserInputFormatType.Code}>
              {t('form.settings.country.userInputFormatType.Code')}
            </SelectOption>
            <SelectOption key={UserInputFormatType.Name} value={UserInputFormatType.Name}>
              {t('form.settings.country.userInputFormatType.Name')}
            </SelectOption>
          </ControlledSelectField>
          <Square size="34px">
            <Tooltip content="Helper text" placement="top">
              <Text
                size="3xl"
                as="span"
                className="appkiticon icon-help-question-fill"
                color="brand.orange"
              />
            </Tooltip>
          </Square>
        </HStack>

        <HStack width="100%" alignItems="flex-end">
          <ControlledSelectField
            control={control as unknown as Control<FieldValues>}
            name="exportAndStatisticsFormat"
            label={t('form.settings.country.exportAndStatisticsFormatLabel')}
            disabled={disabled}
          >
            <SelectOption key={ExportFormatType.Code} value={ExportFormatType.Code}>
              {t('form.settings.country.exportFormatType.Code')}
            </SelectOption>
            <SelectOption key={ExportFormatType.Name} value={ExportFormatType.Name}>
              {t('form.settings.country.exportFormatType.Name')}
            </SelectOption>
            <SelectOption key={ExportFormatType.Both} value={ExportFormatType.Both}>
              {t('form.settings.country.exportFormatType.Both')}
            </SelectOption>
          </ControlledSelectField>
          <Square size="34px">
            <Tooltip content="Helper text" placement="top">
              <Text
                size="3xl"
                as="span"
                className="appkiticon icon-help-question-fill"
                color="brand.orange"
              />
            </Tooltip>
          </Square>
        </HStack>
      </VStack>

      <FormActions>
        <FormButtonCancel onClick={onCancel} />
        <FormButtonSubmit disabled={disabled} />
      </FormActions>
    </Box>
  );
};
