import { FC } from 'types';
import { Fragment } from 'react';

import { Modal } from 'components/modal/Modal/Modal';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { ModalBody } from 'components/modal/Modal/components/ModalBody';

interface IFormModalProps {
  isOpen: boolean;
  maxWidth: string;
  minHeight?: string;
  title?: string;
  onClose(): void;
}

export const FormModal: FC<IFormModalProps> = ({
  title,
  maxWidth,
  minHeight,
  isOpen,
  onClose,
  children,
}) => {
  return (
    <Fragment>
      {isOpen && (
        <Modal isVisible={isOpen} maxWidth={maxWidth} minHeight={minHeight}>
          <ModalHeader onCancel={onClose}>{title && <ModalTitle>{title}</ModalTitle>}</ModalHeader>
          <ModalBody
            sx={{
              pt: 40,
              pr: 44,
              pb: 20,
              pl: 40,
            }}
          >
            {children}
          </ModalBody>
        </Modal>
      )}
    </Fragment>
  );
};
