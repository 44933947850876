import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { StatusIndicator } from 'components/core/StatusIndicator/StatusIndicator';
import { AssessmentStatusDisplay } from 'typings/types/AssessmentStatusDisplay';

interface IAssessmentStatusIndicatorProps {
  status?: AssessmentStatusDisplay;
}
export const AssessmentStatusIndicator: FC<IAssessmentStatusIndicatorProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <StatusIndicator textTransform={status === 'Test' ? 'capitalize' : 'uppercase'}>
      {status && t(`assessment.status.${status}`)}
    </StatusIndicator>
  );
};
