import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { RoomFolder } from 'models/RoomFolder';
import { FolderDeleteModal } from 'components/room-folder/FolderDeleteModal/FolderDeleteModal';
import { useModal } from 'hooks/useModal';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

interface IFolderDeleteProps {
  roomId: string;
  model: RoomFolder;
  disabled?: boolean;
}

export const FolderDelete: FC<IFolderDeleteProps> = ({ roomId, model, disabled = false }) => {
  const { isOpen, onOpen, onClose } = useModal();
  const { t } = useTranslation();

  return (
    <Fragment>
      <StyledButton size="md" kind="secondary" onClick={onOpen} disabled={disabled}>
        {t('room.sidebar.folderDetails.action.delete.label')}
      </StyledButton>
      {isOpen && <FolderDeleteModal roomId={roomId} roomFolder={model} onClose={onClose} />}
    </Fragment>
  );
};
