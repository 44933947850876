import { Box, Text, UnorderedList, ListItem, Link, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BoxHeader } from '../../components/core/BoxHeader/BoxHeader';
import { BackButton } from '../../components/core/BoxHeader/components/BackButton';
import { BoxTitle } from '../../components/core/BoxHeader/components/BoxTitle';
import { MainLayout } from '../../components/layouts/MainLayout/MainLayout';

const companies: Array<{ name: string; link: string }> = [
  {
    name: 'BDK Corporate Finance Beratung GmbH',
    link: 'https://www.pwc.de/en/imprint/company-information-bdk-corporate-finance-beratung.html',
  },
  {
    name: 'DRT Wirtschaftsberatungs- und Treuhandgesellschaft mbH',
    link: 'https://www.pwc.de/en/imprint/company-information-drt-wirtschaftsberatungs-und-treuhandgesellschaft-mbh.html',
  },
  {
    name: 'Fachverlag Moderne Wirtschaft GmbH',
    link: 'https://www.pwc.de/en/imprint/company-information-fachverlag-moderne-wirtschaft.html',
  },
  {
    name: 'Industrie-Beratung Europa GmbH',
    link: 'https://www.pwc.de/en/imprint/company-information-industrie-beratung-europa-gmbh.html',
  },
  {
    name: 'INTES Akademie für Familienunternehmen GmbH',
    link: 'https://www.pwc.de/en/imprint/company-information-intes-akademie-fuer-familienunternehmen-gmbh.html',
  },
  {
    name: 'kampus GmbH',
    link: 'https://www.pwc.de/en/imprint/company-information-kampus-gmbh.html',
  },
  {
    name: 'PwC Strategy& (Germany) GmbH',
    link: 'https://www.strategyand.pwc.com/de/de/impressum.html',
  },
  {
    name: 'PwC Strategy& (Austria) GmbH',
    link: 'https://www.strategyand.pwc.com/at/de/impressum.html',
  },
  {
    name: 'PwC Cyber Security Services GmbH',
    link: 'https://www.pwc.de/en/imprint/company-information-pwc-cyber-security-services-gmbh.html',
  },
  {
    name: 'PwC Certification Services GmbH',
    link: 'https://pwc-cert.com/impressum/',
  },
  {
    name: 'PwC Europe Advisory GmbH',
    link: 'https://www.pwc.de/en/imprint/company-information-pwc-advisory-europe-gmbh.html',
  },
  {
    name: 'PwC IT Services Europe GmbH',
    link: 'https://www.pwc.de/',
  },
  {
    name: 'PricewaterhouseCoopers Corporate Finance Beratung GmbH',
    link: 'https://www.pwc.de/en/imprint/company-information-pwc-corporate-finance-beratung.html',
  },
  {
    name: 'PricewaterhouseCoopers PRTM Management Consultants GmbH',
    link: 'https://www.pwc.de/en/imprint/company-information-pwc-prtm-management-consultants.html',
  },
  {
    name: 'PwC cundus AG',
    link: 'https://www.pwc.de/en/imprint/company-information-pricewaterhousecoopers-cundus-aktiengesellschaft.html',
  },
  {
    name: 'PwC FS Tax GmbH Wirtschaftsprüfungsgesellschaft',
    link: 'https://www.pwc.de/en/imprint/company-information-pwc-fs-tax-gmbh.html',
  },
  {
    name: 'PwC Treuhand GmbH Wirtschaftsprüfungsgesellschaft',
    link: 'https://www.pwc.de/en/imprint/company-information-pwc-treuhand-gmbh.html',
  },
  {
    name: 'WIBERA Wirtschaftsberatung AG Wirtschaftsprüfungsgesellschaft',
    link: 'https://www.pwc.de/en/imprint/company-information-wibera-wirtschaftsberatung.html',
  },
];

export const ImprintPage = () => {
  const { t } = useTranslation();

  return (
    <MainLayout>
      <BoxHeader align="center" justify="space-between">
        <BackButton />
        <BoxTitle>{t('imprint.title')}</BoxTitle>
        <Box />
      </BoxHeader>
      <Box maxW="768px" margin="auto" whiteSpace="pre-line" mb={5}>
        <Text>{t('imprint.text')}</Text>
        <Stack spacing={5}>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('imprint.chairman.title')}
            </Text>
            <Text>{t('imprint.chairman.text')}</Text>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('imprint.managementBoard.title')}
            </Text>
            <Text>{t('imprint.managementBoard.board')}</Text>
            <Text>{t('imprint.managementBoard.email')}</Text>
            <Text>{t('imprint.managementBoard.phone')}</Text>
            <Text>{t('imprint.managementBoard.fax')}</Text>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('imprint.supervisory.title')}
            </Text>
            <Text pb={5}>{t('imprint.supervisory.text1')}</Text>
            <Text>{t('imprint.supervisory.text2')}</Text>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('imprint.provisions.title')}
            </Text>
            <Text pb={5}>{t('imprint.provisions.text1')}</Text>
            <Text>{t('imprint.provisions.text2')}</Text>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('imprint.addedTax.title')}
            </Text>
            <Text>{t('imprint.addedTax.text')}</Text>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('imprint.insurance.title')}
            </Text>
            <Text>{t('imprint.insurance.text')}</Text>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md" fontWeight="bold">
              {t('imprint.codesOfConduct.title')}
            </Text>
            <Text pb={5}>{t('imprint.codesOfConduct.text1')}</Text>
            <Text>{t('imprint.codesOfConduct.text2')}</Text>
          </Box>
          <Box>
            <Text as="h2" pb={1} fontSize="md">
              {t('imprint.furtherCompanies.title')}
            </Text>
            <UnorderedList fontSize={14}>
              {companies.map((company, index) => {
                return (
                  <ListItem listStyleType="initial" key={`${company.name}-${index}`}>
                    <Link href={company.link} isExternal>
                      {company.name}
                    </Link>
                  </ListItem>
                );
              })}
            </UnorderedList>
          </Box>
        </Stack>
      </Box>
    </MainLayout>
  );
};
