import { Box, HStack, Square, Text } from '@chakra-ui/react';
import { Control, FieldValues, useForm, useWatch } from 'react-hook-form';
import { SelectOption, Tooltip } from 'appkit-react';
import { useTranslation } from 'react-i18next';
import { FC } from 'types';

import { Currency } from 'models/Currency';
import { FormActionButton } from 'components/core/FormActions/FormActionButton';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { ControlledInputField } from 'fields/InputField/InputField';
import { InputNumberField } from 'fields/InputNumberField/InputNumberField';
import { ControlledSelectField } from 'fields/SelectField/SelectField';
import {
  NumbersComponentType,
  ScaleSettingsFormFields,
  ScaleType,
} from 'typings/types/FormComponentSettings';
import { useResources } from 'hooks/useResources';

interface IScaleSettingsSliderFormProps {
  sliderType: ScaleType;
  formValues: ScaleSettingsFormFields;
  disabled?: boolean;
  disableCurrencyChange: boolean;
  onNext(value: ScaleSettingsFormFields): void;
  onCancel(): void;
  onBack(): void;
}

export const ScaleSettingsSliderForm: FC<IScaleSettingsSliderFormProps> = ({
  formValues,
  disabled,
  disableCurrencyChange,
  onBack,
  onCancel,
  onNext,
}) => {
  const { t } = useTranslation();

  const { data } = useResources(Currency, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { control, handleSubmit } = useForm<ScaleSettingsFormFields>({
    defaultValues: {
      title: formValues.title || '',
      maximumScale: formValues.maximumScale ?? 100,
      minimumScale: formValues.minimumScale ?? 0,
      numberType: formValues.numberType ?? NumbersComponentType.Number,
      selectedCurrency: formValues.selectedCurrency,
    },
  });

  const selectedNumberType = useWatch({
    control,
    name: 'numberType',
  });

  const disableSave =
    disabled && (selectedNumberType !== NumbersComponentType.Currency || disableCurrencyChange);

  const handleSave = async (values: ScaleSettingsFormFields) => {
    if (!disableSave) {
      onNext(values);
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)}>
      <HStack spacing={40} width="100%" mt={20}>
        <Box width="50%" pr={44}>
          <ControlledInputField
            control={control as unknown as Control<FieldValues>}
            htmlFor="title"
            name="title"
            label={t('form.settings.scale.titleLabel')}
            disabled={disabled}
          />
        </Box>

        <HStack width="50%" pr={44} alignItems="flex-end">
          <InputNumberField
            control={control as unknown as Control<FieldValues>}
            kind="separate"
            name="minimumScale"
            label={t('form.settings.scale.minimumScaleLabel')}
            min={-Infinity}
            max={Infinity}
            disabled={disabled}
          />
        </HStack>
      </HStack>

      <HStack spacing={40} width="100%" mt={20}>
        <HStack width="50%" alignItems="flex-end">
          <ControlledSelectField
            control={control as unknown as Control<FieldValues>}
            name="numberType"
            label={t('form.settings.scale.numberTypeLabel')}
            disabled={disabled}
            showSearchOnToggle
          >
            <SelectOption key={NumbersComponentType.Number} value={NumbersComponentType.Number}>
              {t('form.settings.numbers.numbersComponentType.Number')}
            </SelectOption>
            <SelectOption key={NumbersComponentType.Currency} value={NumbersComponentType.Currency}>
              {t('form.settings.numbers.numbersComponentType.Currency')}
            </SelectOption>
            <SelectOption
              key={NumbersComponentType.Percentage}
              value={NumbersComponentType.Percentage}
            >
              {t('form.settings.numbers.numbersComponentType.Percentage')}
            </SelectOption>
            <SelectOption key={NumbersComponentType.Score} value={NumbersComponentType.Score}>
              {t('form.settings.numbers.numbersComponentType.Score')}
            </SelectOption>
          </ControlledSelectField>
          <Square size="34px">
            <Tooltip content="Helper text" placement="top">
              <Text
                size="3xl"
                as="span"
                className="appkiticon icon-help-question-fill"
                color="brand.orange"
              />
            </Tooltip>
          </Square>
        </HStack>

        <HStack width="50%" pr={44} alignItems="flex-end">
          <InputNumberField
            control={control as unknown as Control<FieldValues>}
            kind="separate"
            name="maximumScale"
            label={t('form.settings.scale.maximumScaleLabel').toUpperCase()}
            min={-Infinity}
            max={Infinity}
            disabled={disabled}
          />
        </HStack>
      </HStack>

      <HStack spacing={40} pr={60} width="50%" mt={20}>
        {selectedNumberType && selectedNumberType === NumbersComponentType.Currency && (
          <HStack width="100%" alignItems="flex-end">
            <ControlledSelectField
              control={control as unknown as Control<FieldValues>}
              name="selectedCurrency"
              label={t('form.settings.scale.selectedCurrencyLabel')}
              disabled={disableCurrencyChange}
              placeholder={!data ? `${t('loading.fetching.data')}...` : undefined}
              showSearchOnToggle={Boolean(data)}
              rules={{
                required:
                  selectedNumberType === NumbersComponentType.Currency
                    ? t<string>('validation.required')
                    : false,
              }}
            >
              {((data ?? []) as Array<Currency>).map((currency, index) => (
                <SelectOption key={index} value={currency.code}>
                  {currency.displayValue}
                </SelectOption>
              ))}
            </ControlledSelectField>
          </HStack>
        )}
      </HStack>

      <FormActions mt={60}>
        <FormButtonCancel onClick={onCancel} />
        <FormActionButton kind="secondary" onClick={onBack}>
          {t('action.back.label')}
        </FormActionButton>
        <FormButtonSubmit disabled={disableSave} />
      </FormActions>
    </Box>
  );
};
