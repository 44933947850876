import { difference } from 'lodash';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { AppStore } from 'store/AppStore';
import { resolveCondition } from 'services/assessmentConditionResolver';

interface IAssessmentReducerState {
  isLoading: boolean;
  isInitiator: boolean;
  isBookmarkFilterOn: boolean;
  skippedComponents: Array<string>;
  pendingUpdatingAnswer: number;
  shouldRevalidate: boolean;
  updatingComponentIds: Array<string>;
}
export enum AssessmentStateReducerAction {
  toggleBookmarked = 'toggleBookmarked',
  setIsLoading = 'setIsLoading',
  setSkippedComponents = 'setSkippedComponents',
  initOnLoad = 'initOnLoad',
  incrementUpdatingAnswer = 'incrementUpdatingAnswer',
  decrementUpdatingAnswer = 'decrementUpdatingAnswer',
  resetShouldRevalidate = 'resetShouldRevalidate',
}
interface IAssessmentStateReducerPayload {
  isLoading?: boolean;
  isInitiator?: boolean;
  skippedComponents?: Array<string>;
  assessmentFormComponentId?: string;
}
interface IAssessmentStateReducerAction {
  type: AssessmentStateReducerAction;
  payload?: IAssessmentStateReducerPayload;
}
export function stateReducer(
  state: IAssessmentReducerState,
  { type, payload }: IAssessmentStateReducerAction,
) {
  switch (type) {
    case AssessmentStateReducerAction.initOnLoad:
      return {
        ...state,
        skippedComponents: payload?.skippedComponents ?? state.skippedComponents,
        isInitiator: payload?.isInitiator ?? state.isInitiator,
      };
    case AssessmentStateReducerAction.toggleBookmarked:
      return {
        ...state,
        isBookmarkFilterOn: !state.isBookmarkFilterOn,
      };
    case AssessmentStateReducerAction.setIsLoading:
      return {
        ...state,
        isLoading: payload?.isLoading ?? state.isLoading,
      };
    case AssessmentStateReducerAction.setSkippedComponents:
      return {
        ...state,
        skippedComponents: payload?.skippedComponents ?? state.skippedComponents,
      };
    case AssessmentStateReducerAction.incrementUpdatingAnswer: {
      const arr = payload?.assessmentFormComponentId
        ? [...state.updatingComponentIds, payload?.assessmentFormComponentId]
        : [...state.updatingComponentIds];

      return {
        ...state,
        pendingUpdatingAnswer: state.pendingUpdatingAnswer + 1,
        shouldRevalidate: false,
        updatingComponentIds: arr,
      };
    }
    case AssessmentStateReducerAction.decrementUpdatingAnswer: {
      const firstIndex = state.updatingComponentIds.indexOf(
        payload?.assessmentFormComponentId ?? '',
      );

      return {
        ...state,
        pendingUpdatingAnswer: state.pendingUpdatingAnswer - 1,
        shouldRevalidate: state.pendingUpdatingAnswer === 1,
        updatingComponentIds: [
          ...state.updatingComponentIds.filter((_, index) => index !== firstIndex),
        ],
      };
    }
    case AssessmentStateReducerAction.resetShouldRevalidate:
      return {
        ...state,
        shouldRevalidate: false,
      };
    default:
      return state;
  }
}
export type AssessmentStatusDispatch = (value: IAssessmentStateReducerAction) => void;

export const toggleBookmarked = (dispatch: AssessmentStatusDispatch) =>
  dispatch({ type: AssessmentStateReducerAction.toggleBookmarked });

export const setSpinner = (dispatch: AssessmentStatusDispatch, isLoading: boolean) =>
  dispatch({ type: AssessmentStateReducerAction.setIsLoading, payload: { isLoading } });

export const recalculateSkippedComponents = (
  dispatch: AssessmentStatusDispatch,
  datx: AppStore,
  assessmentId: string | undefined,
) =>
  dispatch({
    type: AssessmentStateReducerAction.setSkippedComponents,
    payload: { skippedComponents: getSkippedFormComponents(datx, assessmentId) },
  });

export const incrementUpdatingAnswer = (
  dispatch: AssessmentStatusDispatch,
  assessmentFormComponentId: string,
) =>
  dispatch({
    type: AssessmentStateReducerAction.incrementUpdatingAnswer,
    payload: { assessmentFormComponentId },
  });

export const decrementUpdatingAnswer = (
  dispatch: AssessmentStatusDispatch,
  assessmentFormComponentId: string,
) =>
  dispatch({
    type: AssessmentStateReducerAction.decrementUpdatingAnswer,
    payload: { assessmentFormComponentId },
  });

export function getSkippedFormComponents(datx: AppStore, assessmentId: string | undefined) {
  if (!assessmentId) {
    return [];
  }
  const components = datx
    .findAll<AssessmentFormComponent>(AssessmentFormComponent)
    ?.filter((component) => component.assessmentId === assessmentId);
  if (!components) {
    return [];
  }

  let skippedQuestionIds: Array<string> = [];
  let nextQuestionId: string | null = null;
  // precondition: components should be sorted already
  components.forEach((component) => {
    if (component.parentComponentId && skippedQuestionIds.find((e) => e == component.parentComponentId))
    {
      //if parent component is skipped (QuestionSet) then the subquestions are skipped as well
      skippedQuestionIds.push(component.id);
      return;
    }
    if (nextQuestionId && component.id !== nextQuestionId) {
      skippedQuestionIds.push(component.id);
      return;
    }
    if (nextQuestionId && nextQuestionId === component.id) {
      nextQuestionId = null;
      skippedQuestionIds = difference(skippedQuestionIds, component.predecessorIds);
    }
    if (hasUnfulfilledCondition(component, components, [...skippedQuestionIds])) {
      skippedQuestionIds.push(component.id);
      return;
    }
    if (component.skipTo) {
      nextQuestionId = `${component.skipTo}@${assessmentId}`;
    }
  });

  return skippedQuestionIds;
}

function hasUnfulfilledCondition(
  component: AssessmentFormComponent,
  components: Array<AssessmentFormComponent>,
  skippedComponentIds: Array<string>,
) {
  return !resolveCondition(component, components, skippedComponentIds);
}
