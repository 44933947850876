import { FC } from 'types';
import { Box } from '@chakra-ui/react';

import { Modal } from 'components/modal/Modal/Modal';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalBody } from 'components/modal/Modal/components/ModalBody';

interface ICampaignModalProps {
  onCancel(): void;
}

export const CampaignModal: FC<ICampaignModalProps> = ({ onCancel, children }) => {
  return (
    <Modal isVisible maxWidth="1162px">
      <ModalHeader onCancel={onCancel} px={40} />
      <ModalBody pb={20}>
        <Box width="100%" pt={20}>
          {children}
        </Box>
      </ModalBody>
    </Modal>
  );
};
