import { FC } from 'types';
import { Fragment } from 'react';
import { HStack, Text, Box, Center, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { RoomType } from 'typings/enums/RoomType';

import { CreateRoomFormType } from '../CreateRoomFormType/CreateRoomFormType';

interface ICreateRoomFormTypesProps {
  activeType: string;
  onChange(type: RoomType): void;
}

export const CreateRoomFormTypes: FC<ICreateRoomFormTypesProps> = ({ activeType, onChange }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <ModalTitle mt="0">{t('rooms.createRoom.formTypes.title')}</ModalTitle>
      <HStack spacing="10px" mt="20px" alignItems="stretch">
        <CreateRoomFormType
          isActive={activeType === RoomType.Internal}
          onClick={() => onChange(RoomType.Internal)}
        >
          <Center bg="utils.rose.100" height="85px">
            <Image
              src="/assets/images/illustration-research.png"
              alt={t('rooms.createRoom.images.researchAlt')}
              width="70%"
              height="auto"
            />
          </Center>
          <Box p="20px 10px">
            <Text size="lg" mb="5px">
              {t('rooms.createRoom.formTypes.internalRoom.title')}
            </Text>
            <Text size="xs">{t('rooms.createRoom.images.groupAlt')}</Text>
          </Box>
        </CreateRoomFormType>
        <CreateRoomFormType
          isActive={activeType === RoomType.External}
          onClick={() => onChange(RoomType.External)}
        >
          <Center bg="brand.yellow" height="85px">
            <Image
              src="/assets/images/illustration-group.png"
              alt={t('rooms.createRoom.images.groupAlt')}
              width="75%"
              height="auto"
            />
          </Center>
          <Box p="20px 10px">
            <Text size="lg" mb="5px">
              {t('rooms.createRoom.formTypes.externalRoom.title')}
            </Text>
            <Text size="xs">{t('rooms.createRoom.formTypes.externalRoom.description')}</Text>
          </Box>
        </CreateRoomFormType>
      </HStack>
    </Fragment>
  );
};
