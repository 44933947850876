import { useEffect } from 'react';
import { FC } from 'types';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Control, FieldValues, useForm } from 'react-hook-form';

import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { FormUserManagement } from 'components/form-users/FormUserManagement/FormUserManagement';
import { IResourceUser } from 'typings/interfaces/IResourceUser';
import { IFormUserManagementFields } from 'typings/interfaces/IFormUserManagementFields';
import { NotificationTextFormField } from 'components/core/NotificationTextFormField/NotificationTextFormField';
import { ControlledInputField } from 'fields/InputField/InputField';
import { FormType } from 'typings/enums/FormType';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { IApiClientFnResult } from 'hooks/useApiClient';
import { isApiFormError } from 'utils/isApiFormError';
import { parseApiFormError } from 'utils/parseApiFormError';

interface ICreateFormFormValues extends IFormUserManagementFields {
  name: string;
  skipSendingNotification: boolean;
  notificationText: string;
}

type ICreateFormFormProps = {
  formType: FormType;
  availableUsers: Array<IResourceUser>;
  isLoading?: boolean;
  folderId?: string;
  onSave(data?: Record<string, unknown> | undefined): Promise<IApiClientFnResult<void>>;
  onCancel(): void;
};
export const CreateFormForm: FC<ICreateFormFormProps> = ({
  formType,
  availableUsers,
  isLoading,
  folderId,
  onSave,
  onCancel,
}) => {
  const { t } = useTranslation();
  const {
    control,
    setError,
    clearErrors,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ICreateFormFormValues>({
    defaultValues: {
      name: '', // Prevent =>  Warning: A component is changing an uncontrolled input to be controlled
    },
  });

  const watchUsers = watch('users');

  useEffect(() => {
    if (errors.users && watchUsers.length > 0) {
      clearErrors('users');
    }
  }, [clearErrors, errors.users, watchUsers]);

  const handleSave = async (formValues: ICreateFormFormValues) => {
    clearErrors();

    if (!formValues.users.length) {
      setError('users', { message: t('userManagementSearch.validation.empty') });
      return;
    }

    if (!formValues.skipSendingNotification && !formValues.notificationText) {
      setError('notificationText', {
        message: t('notificationTextField.validation.empty'),
      });
      return;
    }

    const { error, pending } = await onSave({
      ...formValues,
      users: formValues.users.map((user) => ({
        id: user.userId,
        role: user.role,
        defaultInitiatorStatus: user.defaultInitiatorStatus,
      })),
      formType,
      parentFolderId: folderId ?? null,
    });

    if (error) {
      if (isApiFormError(error)) {
        const filedErrors = parseApiFormError<ICreateFormFormValues>(error);
        filedErrors.forEach((fieldError) =>
          setError(fieldError.name, { message: fieldError.message }),
        );
      }
      PopAlertFail(t('room.errors.createForm.main'), error.message);
    }

    if (!pending && !error) {
      PopAlertSuccess(
        t('room.modals.createForm.successMessages.created', { name: formValues.name }),
      );
      onCancel();
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)}>
      <ControlledInputField
        control={control as unknown as Control<FieldValues>}
        htmlFor="name"
        name="name"
        rules={{ required: { value: true, message: t('validation.required') } }}
        label={t('createForm.formSettings.formName.label')}
      />
      <FormUserManagement
        control={control as unknown as Control<IFormUserManagementFields>}
        availableUsers={availableUsers}
        mt={20}
      />
      <NotificationTextFormField
        control={control as unknown as Control<FieldValues>}
        nameTextEditor="notificationText"
        nameCheckBox="skipSendingNotification"
        mt={24}
      />

      <FormActions>
        <FormButtonCancel onClick={onCancel} />
        <FormButtonSubmit isLoading={isLoading} disabled={isLoading} />
      </FormActions>
    </Box>
  );
};
