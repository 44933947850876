import { chakra } from '@chakra-ui/react';

export const RichTextViewer = chakra('div', {
  baseStyle: {
    px: '10px',
    ol: {
      paddingLeft: '1rem',
      li: {
        listStyle: 'auto',
      },
    },
    ul: {
      paddingLeft: '1rem',
      li: {
        listStyle: 'disc',
      },
    },
  },
});
