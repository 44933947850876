import { Domain } from 'models/Domain';

export interface IDomainDto {
  id: string;
  siteId: string;
}
export interface ITransformedDomainDto extends IDomainDto {
  domainId: string;
}
export const transformDomain = (dto: IDomainDto): ITransformedDomainDto => ({
  ...dto,
  domainId: `${dto.id}@${dto.siteId}`,
});

export const transformDomains = (data: Array<Domain>) => data.map(transformDomain);
