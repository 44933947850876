import { Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Room } from 'models/Room';
import { useMutate } from 'hooks/useMutate';
import { useCurrentRoom } from 'hooks/useCurrentRoom';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { ConfirmationModal } from 'components/modal/ConfirmationModal/ConfirmationModal';

interface IRoomDeleteModal {
  onClose(): void;
}
export const RoomDeleteModal: FC<IRoomDeleteModal> = ({ onClose }) => {
  const { room } = useCurrentRoom();
  const deleteMutation = useMutate(Room.delete(room?.id), Room.type);
  const [isDeleting, setIsDeleting] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const handleCloseModal = () => {
    if (!isDeleting) {
      onClose();
    }

    return;
  };
  const handleDeleteRoom = async () => {
    setIsDeleting(true);

    try {
      await deleteMutation.mutate();
      PopAlertSuccess(
        t('room.sidebar.roomDetails.modals.delete.successMessages.changed', { name: room?.name }),
      );
      history.push('/rooms');
    } catch (error: any) { // eslint-disable-line
      PopAlertFail(t('room.errors.deleteRoom.main'), error.Message);
      onClose();
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <ConfirmationModal
      maxWidth="480px"
      title={t('room.sidebar.roomDetails.modals.delete.title')}
      showLoader={isDeleting}
      disabled={isDeleting}
      confirmText={t('room.sidebar.roomDetails.modals.delete.action.confirm')}
      onCancel={handleCloseModal}
      onConfirm={handleDeleteRoom}
    >
      <Text size="lg" my={40}>
        {t('room.sidebar.roomDetails.modals.delete.text')}
      </Text>
    </ConfirmationModal>
  );
};
