import { Box } from '@chakra-ui/layout';
import { chakra } from '@chakra-ui/system';

export const StyledReactTagInputWrapper = chakra(Box, {
  baseStyle: {
    '.react-tag-input': {
      bg: '#303030',

      border: '0.0625rem solid #474747',
      maxHeight: '100px',
      minHeight: '2.125rem',
      p: '0 0.625rem',
      fontSize: '0.875rem',
      ':focus': {
        boxShadow: '0 0 0 0.125rem #e45c2b',
      },
    },
    '.react-tag-input__input': {
      color: 'rgba(255, 255, 255, 0.87)',
      p: '0',
    },
    '.react-tag-input__tag': {
      bg: '#E45C2B',
      borderRadius: '.9375rem',
      color: '#fff',
    },
    '.react-tag-input__tag__remove': {
      bg: 'transparent',
      '&:before, &:after': {
        bg: '#fff',
      },
    },
  },
});
