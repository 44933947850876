import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { Text, Box, Spacer } from '@chakra-ui/react';

import { FormActionButton } from 'components/core/FormActions/FormActionButton';
import { FormModal } from 'components/modal/FormModal/FormModal';
import { FormActions } from 'components/core/FormActions/FormActions';
import { User } from 'models/User';
import { useResources } from 'hooks/useResources';

import { SiteUserManagement } from './components/SiteUserManagement/SiteUserManagement';

export interface IUser {
  id: string;
  username: string;
  email: string;
  displayName: string;
}
export interface ISiteUsersFields {
  users: Array<IUser>;
}

interface IUserDeleteModalFormProps {
  userEmail: string;
  isLoading?: boolean;
  onClose(): void;
  onDeleteWithoutReplace(): Promise<void>;
  onDeleteWithReplace(newUserIds: Array<string>): Promise<void>;
}
export const UserDeleteModal: FC<IUserDeleteModalFormProps> = ({
  userEmail,
  isLoading,
  onDeleteWithoutReplace,
  onDeleteWithReplace,
  onClose,
}) => {
  const { t } = useTranslation();
  const { data: availableUsers, isInitialLoad } = useResources(User);
  const { control } = useForm<ISiteUsersFields>({
    defaultValues: {
      users: [],
    },
  });

  const watchUsers = useWatch({ control, name: 'users' });

  return (
    <FormModal isOpen maxWidth="750px" onClose={onClose}>
      <Box as="form">
        <SiteUserManagement
          userEmail={userEmail}
          control={control}
          availableUsers={availableUsers as Array<User> | undefined}
        />
        <Text mt={40}>{t('siteAdmin.user.delete.warning')}</Text>
        <FormActions>
          <FormActionButton negative isLoading={isLoading} onClick={onDeleteWithoutReplace}>
            {t('siteAdmin.user.delete.deleteWithoutReplace')}
          </FormActionButton>
          <Spacer />
          <FormActionButton
            isLoading={isLoading}
            disabled={isInitialLoad || isLoading || !watchUsers.length}
            onClick={() => onDeleteWithReplace(watchUsers.map((user) => user.id))}
          >
            {t('siteAdmin.user.delete.deleteAndReplace')}
          </FormActionButton>
        </FormActions>
      </Box>
    </FormModal>
  );
};
