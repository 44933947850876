import { IconButton } from '@chakra-ui/react';
import { FC } from 'types';

interface IConclusionActionButtonProps {
  icon: string;
  label: string;
  onClick(): void;
}
export const ConclusionActionButton: FC<IConclusionActionButtonProps> = ({
  icon,
  label,
  onClick,
}) => {
  return (
    <IconButton
      isRound
      size="xs"
      icon={<span className={`appkiticon ${icon} a-font-16`} />}
      onClick={onClick}
      aria-label={`${label}`}
      sx={{
        color: 'brand.orange',
        ml: '20px',
        bg: 'transparent',
        cursor: 'pointer',
        'svg path': {
          fill: 'brand.orange',
        },
        _focus: {
          bg: 'transparent',
        },
        _active: {
          bg: 'transparent',
        },
        _hover: {
          bg: 'transparent',
        },
      }}
    />
  );
};
