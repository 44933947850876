import { chakra } from '@chakra-ui/react';

export const StyledConditionGroupContainer = chakra('div', {
  baseStyle: {
    bg: 'grey.lvl4',
    boxShadow: 'mainWhiteSmall',
    py: 20,
    px: 40,
    width: '100%',
  },
});

export const StyledConditionGroupHeader = chakra('div', {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
