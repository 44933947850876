import { FC } from 'types';
import { useForm, Control, FieldValues, RegisterOptions } from 'react-hook-form';
import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ControlledInputField } from 'fields/InputField/InputField';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

interface IFormValues {
  searchValue: string;
}

interface IUserManagementSearchProps {
  buttonLabel?: string;
  label?: string;
  placeholder?: string;
  disableButton?: boolean;
  validationRules?: RegisterOptions;
  isLoading?: boolean;
  onClick(searchValue: string): void;
}

export const UserManagementSearch: FC<IUserManagementSearchProps> = ({
  label,
  placeholder,
  buttonLabel,
  disableButton,
  validationRules,
  isLoading,
  onClick,
}) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues: {
      searchValue: '',
    },
  });

  const handleOnClick = () => {
    onClick(getValues('searchValue'));
    reset();
  };

  return (
    <HStack spacing={20} alignItems={errors.searchValue ? 'center' : 'flex-end'}>
      <ControlledInputField
        label={label}
        control={control as unknown as Control<FieldValues>}
        name="searchValue"
        rules={validationRules}
        placeholder={placeholder}
        disabled={isLoading}
      />
      <StyledButton
        size="lg"
        disabled={Boolean(errors.searchValue) || !getValues('searchValue') || disableButton}
        isLoading={isLoading}
        onClick={handleOnClick}
      >
        {buttonLabel ?? t('action.add.label')}
      </StyledButton>
    </HStack>
  );
};
