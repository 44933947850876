import { FC } from 'types';

import { Form } from 'models/Form';
import { useDatx } from 'hooks/useDatx';
import { IFormUserManagementFields } from 'typings/interfaces/IFormUserManagementFields';
import { FormUserManagementForm } from 'components/form-users/FormUserManagementForm/FormUserManagementForm';
import { IFormUser } from 'typings/interfaces/IFormUser';

interface IFormUserManagementFormWrapperProps {
  formId: string;
  availableUsers: Array<IFormUser>;
  isLoading: boolean;
  onSave(formValues: IFormUserManagementFields): Promise<void>;
  onCancel(): void;
}

export const FormUserManagementFormWrapper: FC<IFormUserManagementFormWrapperProps> = ({
  formId,
  isLoading,
  availableUsers,
  onCancel,
  onSave,
}) => {
  const datx = useDatx();
  const form = datx.findOne(Form, formId) as Form;
  const addedUsers = form.users;

  return (
    <FormUserManagementForm
      availableUsers={availableUsers}
      formUsers={addedUsers}
      isLoading={isLoading}
      onCancel={onCancel}
      onSave={onSave}
    />
  );
};
