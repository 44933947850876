import { HStack } from '@chakra-ui/react';
import { Input } from 'appkit-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { AssessmentsTableView } from 'components/assessments/AssessmentsTableView/AssessmentsTableView';
import { BoxHeader } from 'components/core/BoxHeader/BoxHeader';
import { BackButton } from 'components/core/BoxHeader/components/BackButton';
import { MainLayout } from 'components/layouts/MainLayout/MainLayout';
import { useDebouncedSearch } from 'hooks/useDebouncedSearch';
import { useAssessments } from 'components/assessments/AssessmentsTableView/AssessmentsTableView.hooks';

export const AssessmentsPage = () => {
  const [assessmentsSearchQuery, setAssessmentsSearchQuery] = useDebouncedSearch();
  const { filteredData, isInitialLoad, isValidating } = useAssessments(assessmentsSearchQuery);

  const { t } = useTranslation();

  return (
    <MainLayout>
      <BoxHeader align="center" justify="space-between">
        <BackButton />
        <HStack spacing="20px">
          <Input
            prefix={
              <span
                className="appkiticon icon-filter-outline baseline-search-icon change-color-with-focus"
                style={{ fontSize: 16 }}
              />
            }
            placeholder={t('inputs.filter.placeholder')}
            onChange={setAssessmentsSearchQuery}
          />
          <Link to="/trash">
            <StyledButton size="lg" kind="secondary">
              {t('assessments.tableView.link.trash.label')}
            </StyledButton>
          </Link>
        </HStack>
      </BoxHeader>
      <AssessmentsTableView
        assessments={filteredData()}
        isLoading={isInitialLoad || isValidating}
      />
    </MainLayout>
  );
};
