import {
  Box,
  HStack,
  Input,
  Text,
  NumberInput,
  NumberInputField,
  Table,
  Th,
  Thead,
  Tr,
  Tfoot,
  Td,
} from '@chakra-ui/react';
import { useState, KeyboardEvent, useMemo, Fragment } from 'react';
import { FC } from 'types'
import { Control, FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import { uniqBy } from 'lodash';

import {
  IntervalNumberValue,
  IntervalValue,
  IntervalValuesFormField,
  NumbersComponentType,
  ScaleIntervalInputType,
  ScaleSettingsFormFields,
} from 'typings/types/FormComponentSettings';
import { RemoveButton } from 'components/form-editor/Question/components/RemoveButton/RemoveButton';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormActionButton } from 'components/core/FormActions/FormActionButton';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { useSetFocusWithDelay } from 'hooks/useSetFocusWithDelay';
import { useCurrencyByCode } from 'hooks/useCurrencyByCode';
import { DroppableTBody } from 'components/draggable-table/DroppableTBody/DroppableTBody';
import { DraggableTr } from 'components/draggable-table/DraggableTr/DraggableTr';
import { SkipConditionSelect } from 'components/form-editor/SkipConditionSelect/SkipConditionSelect';
import { FormComponent } from 'models/FormComponent';
import { DraggableTrDragHandle } from 'components/draggable-table/DraggableTrDragHandle/DraggableTrDragHandle';

interface IScaleSettingsIntervalsProps {
  question: FormComponent;
  type: ScaleIntervalInputType;
  defaultValues: IntervalValuesFormField;
  settings: ScaleSettingsFormFields;
  skipConditionSettings?: Record<string, string>;
  disabled?: boolean;
  onCancel(): void;
  onBack(): void;
  onSubmit(value: Array<IntervalValue>): void;
}

export const ScaleSettingsIntervals: FC<IScaleSettingsIntervalsProps> = ({
  question,
  type,
  defaultValues,
  settings,
  skipConditionSettings,
  disabled,
  onCancel,
  onBack,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const selectedCurrency = useCurrencyByCode(settings.selectedCurrency);
  const [newOption, setNewOption] = useState<string>('');
  const { elementRef: inputRef, setFocusWithDelay } = useSetFocusWithDelay<HTMLInputElement>();

  const { control, register, handleSubmit } = useForm<IntervalValuesFormField>({
    defaultValues: {
      marksText: defaultValues.marksText
        .sort((a, b) => a.index - b.index)
        .map((mark) => ({
          index: mark.index,
          label: mark.label,
          skipTo: skipConditionSettings ? skipConditionSettings[mark.label] || null : undefined,
        })),
      marksNumber: defaultValues.marksNumber
        .sort((a, b) => a.index - b.index)
        .map((mark) => ({
          index: mark.index,
          label: mark.label,
          skipTo: skipConditionSettings ? skipConditionSettings[mark.label] || null : undefined,
        })),
    },
  });
  const {
    fields: numberFields,
    append: appendNumber,
    remove: removeNumber,
    move: moveNumber,
  } = useFieldArray({
    control,
    name: 'marksNumber',
  });
  const {
    fields: textFields,
    append: appendText,
    remove: removeText,
    move: moveText,
  } = useFieldArray({
    control,
    name: 'marksText',
  });

  const handleSave = (formValue: IntervalValuesFormField) => {
    if (type === ScaleIntervalInputType.Number) {
      const uniqueValues = uniqBy(formValue.marksNumber, (value) => value.label.toString());

      if (uniqueValues.length !== formValue.marksNumber.length) {
        return;
      }
    } else {
      const uniqueValues = uniqBy(formValue.marksText, (value) => value.label);

      if (uniqueValues.length !== formValue.marksText.length) {
        return;
      }
    }

    if (
      type === ScaleIntervalInputType.Number &&
      formValue.marksNumber.find(
        (value: IntervalNumberValue) =>
          value.label < settings.minimumScale || value.label > settings.maximumScale,
      )
    ) {
      return;
    }

    const updatedValues: Array<IntervalValue> =
      type === ScaleIntervalInputType.Number
        ? formValue.marksNumber.map((mark, index) => ({
            value: mark.label,
            index,
            skipTo: mark.skipTo,
          }))
        : formValue.marksText.map((mark, index) => ({
            value: mark.label,
            index,
            skipTo: mark.skipTo,
          }));
    onSubmit(updatedValues);
  };

  const handleKeypress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newOption !== '') {
      e.preventDefault();
      if (type === ScaleIntervalInputType.Number) {
        const parsed = parseFloat(newOption);
        if (numberFields.find((item) => item.label === parsed)) {
          return;
        }
        appendNumber({
          label: parsed,
          index: numberFields.length,
        });
      } else {
        if (textFields.find((item) => item.label === newOption)) {
          return;
        }
        appendText({
          label: newOption,
          index: textFields.length,
        });
      }
      setNewOption('');
      setFocusWithDelay();
    }
  };

  const onDragEnd: OnDragEndResponder = ({ source, destination }) => {
    if (!destination) {
      return;
    }
    if (type === ScaleIntervalInputType.Number) {
      moveNumber(source.index, destination.index);
    } else {
      moveText(source.index, destination.index);
    }
  };

  const currency = useMemo(
    () =>
      settings.numberType === NumbersComponentType.Currency ? (
        <Text size="md" width="300px">
          {`${t('form.settings.numbers.numbersComponentType.Currency')}, ${
            selectedCurrency?.displayValue
          }`}
        </Text>
      ) : null,
    [selectedCurrency?.displayValue, settings.numberType, t],
  );
  const hasSkipLogic = Boolean(skipConditionSettings);
  const numberOfColumns = 2 + (currency ? 1 : 0) + (hasSkipLogic ? 1 : 0);

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)}>
      <Table size="sm" variant="dragTable">
        <Thead>
          <Tr>
            <Th>{t('form.settings.scale.intervals.marksHeader')}</Th>
            {hasSkipLogic && <Th>{t('form.settings.scale.intervals.skipLogicHeader')}</Th>}
            {currency && <Th> {t('form.settings.numbers.name')}</Th>}
            <Th width="140px" />
          </Tr>
        </Thead>

        <DroppableTBody droppableId="marksNumber" droppableType="marksNumber" onDragEnd={onDragEnd}>
          {type === ScaleIntervalInputType.Number &&
            numberFields.map((field, index) => (
              <DraggableTr key={field.id} draggableId={`${field.id}`} index={index}>
                <Td>
                  <NumberInput
                    variant="transparent"
                    key={field.id}
                    size="sm"
                    max={settings?.maximumScale}
                    min={settings?.minimumScale}
                    keepWithinRange={false}
                    clampValueOnBlur={false}
                  >
                    <NumberInputField {...register(`marksNumber.${index}.label` as const)} />
                  </NumberInput>
                </Td>
                {hasSkipLogic && (
                  <Td>
                    <SkipConditionSelect
                      variant="transparent"
                      control={control as unknown as Control<FieldValues>}
                      name={`marksNumber.${index}.skipTo` as const}
                      currentComponentOrder={question.order}
                    />
                  </Td>
                )}
                {currency && <Td>{currency}</Td>}
                <HStack as={Td} spacing={32} mr={24} justifyContent="flex-end">
                  <DraggableTrDragHandle />
                  {!disabled && (
                    <Fragment>
                      <RemoveButton onClick={() => removeNumber(index)} />
                    </Fragment>
                  )}
                </HStack>
              </DraggableTr>
            ))}

          {type === ScaleIntervalInputType.Text &&
            textFields.map((field, index) => (
              <DraggableTr key={field.id} draggableId={`${field.id}`} index={index}>
                <Td>
                  <Input
                    variant="unstyled"
                    key={field.id}
                    {...register(`marksText.${index}.label` as const)}
                    size="sm"
                  />
                </Td>
                {hasSkipLogic && (
                  <Td>
                    <SkipConditionSelect
                      variant="transparent"
                      control={control as unknown as Control<FieldValues>}
                      name={`marksText.${index}.skipTo` as const}
                      currentComponentOrder={question.order}
                    />
                  </Td>
                )}
                <HStack as={Td} spacing={32} mr={24} justifyContent="flex-end">
                  <DraggableTrDragHandle />
                  {!disabled && (
                    <Fragment>
                      <RemoveButton onClick={() => removeText(index)} />
                    </Fragment>
                  )}
                </HStack>
              </DraggableTr>
            ))}
        </DroppableTBody>

        <Tfoot>
          <Tr bgColor={numberFields.length % 2 === 0 ? 'grey.lvl6' : 'grey.lvl5'}>
            <Td colSpan={numberOfColumns} px={12}>
              {type === ScaleIntervalInputType.Number && (
                <NumberInput
                  variant="unstyled"
                  size="sm"
                  max={settings?.maximumScale}
                  min={settings?.minimumScale}
                  keepWithinRange
                  value={newOption}
                  onChange={setNewOption}
                  onKeyPress={handleKeypress}
                >
                  <NumberInputField
                    ref={inputRef}
                    placeholder={t('form.settings.scale.intervals.marksPlaceholder')}
                    sx={{
                      _placeholder: {
                        color: 'grey.lvl4',
                      },
                    }}
                  />
                </NumberInput>
              )}

              {type === ScaleIntervalInputType.Text && (
                <Input
                  ref={inputRef}
                  size="sm"
                  value={newOption}
                  placeholder={t('form.settings.scale.intervals.marksPlaceholder')}
                  sx={{
                    _placeholder: {
                      color: 'grey.lvl4',
                    },
                  }}
                  onChange={(event) => setNewOption(event.target.value)}
                  onKeyPress={handleKeypress}
                />
              )}
            </Td>
          </Tr>
        </Tfoot>
      </Table>

      <FormActions>
        <FormButtonCancel onClick={onCancel} />
        <FormActionButton kind="secondary" onClick={onBack}>
          {t('action.back.label')}
        </FormActionButton>
        <FormButtonSubmit disabled={disabled} />
      </FormActions>
    </Box>
  );
};
