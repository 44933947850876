import { FC } from 'types';
import { BoxProps } from '@chakra-ui/react';
import { useTranslation, TFunction } from 'react-i18next';

import { Room } from 'models/Room';
import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { StyledTable } from 'components/core/StyledAppKit/StyledAppKit';

import { RoomsListViewEnterRoomCell } from './components/RoomsListViewEnterRoomCell/RoomsListViewEnterRoomCell';
import { RoomsListViewFavoriteCell } from './components/RoomsListViewFavoriteCell/RoomsListViewFavoriteCell';

interface IRoomsListViewProps extends BoxProps {
  rooms: Array<Room>;
  isLoading: boolean;
}

const roomListColumns = (t: TFunction) => [
  {
    width: 40,
    Cell: (row: Record<string, unknown>) => <RoomsListViewFavoriteCell rowData={row} />,
  },
  {
    Header: t('rooms.listView.tableColumns.name'),
    accessor: 'name',
    minWidth: 300,
  },
  {
    Header: t('rooms.listView.tableColumns.members'),
    accessor: 'members',
    minWidth: 100,
  },
  {
    Header: t('rooms.listView.tableColumns.folders'),
    accessor: 'folders',
    minWidth: 100,
  },
  {
    Header: t('rooms.listView.tableColumns.forms'),
    accessor: 'formCount',
    minWidth: 100,
  },
  {
    Header: t('rooms.listView.tableColumns.lastEdit'),
    accessor: 'formattedLastEdit',
    minWidth: 200,
  },
  {
    Cell: (row: Record<string, unknown>) => <RoomsListViewEnterRoomCell rowData={row} />,
    maxWidth: 80,
  },
];

export const RoomsListView: FC<IRoomsListViewProps> = ({ rooms, isLoading, sx }) => {
  const { t } = useTranslation();

  return (
    <TableWrapper isLoading={isLoading}>
      <StyledTable
        columns={roomListColumns(t)}
        data={rooms}
        defaultPageSize={10}
        showPageSizeOptions={false}
        sx={sx}
      />
    </TableWrapper>
  );
};
