import { TFunction } from 'react-i18next';

import { formExcelExportFetcher, formJsonExportFetcher } from 'fetchers/formExport';
import { PopAlertFail } from 'services/PopAlertFail';
import { exportDownload } from 'utils/exportDownload';

type FormExportOption = 'formJson' | 'formXlsx';
export type FormExportFormValues = Record<FormExportOption, boolean>;

interface IExportOption {
  label: string;
  value: FormExportOption;
  disabled?: boolean;
}

export const exportOptions: Array<IExportOption> = [
  { label: 'export.modal.checkbox.optionJsonLabel', value: 'formJson' },
  { label: 'export.modal.checkbox.optionXlsxLabel', value: 'formXlsx' },
];

export const handleFormJsonExport = async (t: TFunction, siteId: string, formId: string) => {
  try {
    await exportDownload(formJsonExportFetcher(siteId, formId));
  } catch {
    PopAlertFail(t('export.errors.dataDownload.main'));
  }
};

export const handleFormExcelExport = async (t: TFunction, siteId: string, formId: string) => {
  try {
    await exportDownload(formExcelExportFetcher(siteId, formId));
  } catch {
    PopAlertFail(t('export.errors.dataDownload.main'));
  }
};
