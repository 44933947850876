import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { FC } from 'types'
import { Input } from 'appkit-react';
import { useTranslation } from 'react-i18next';

interface ITableSearchProps extends Omit<BoxProps, 'onChange'> {
  onChange(value: string): void;
  placeholder?: string;
}

export const TableSearch: FC<ITableSearchProps> = ({ placeholder, onChange, ...props }) => {
  const { t } = useTranslation();

  return (
    <Box position="absolute" top="-55px" right="0" {...props}>
      <Input
        prefix={
          <span
            className="appkiticon icon-search-outline baseline-search-icon change-color-with-focus"
            style={{ fontSize: 16 }}
          />
        }
        placeholder={placeholder || t('inputs.search.placeholder')}
        onChange={onChange}
      />
    </Box>
  );
};
