import { FC } from 'types';
import { FieldArrayWithId } from 'react-hook-form';
import { VStack } from '@chakra-ui/react';

import { ApprovalSettings } from 'typings/types/ApprovalSettings';
import { FieldArrayListItem } from 'components/core/FieldArrayListItem/FieldArrayListItem';

interface IApproversListProps {
  addedFields: Array<FieldArrayWithId<ApprovalSettings, 'approverObjects', 'id'>>;
  onRemove(index: number): void;
}

export const ApproversList: FC<IApproversListProps> = ({ addedFields, onRemove }) => {
  return (
    <VStack
      spacing={0}
      width="100%"
      alignItems="start"
      sx={{
        borderTop: ({ colors }) => `2px solid ${colors.grey.lvl3}`,
      }}
    >
      {addedFields.map((item, index) => {
        return (
          <FieldArrayListItem
            key={item.id}
            username={item.username}
            email={item.email}
            onRemove={() => {
              onRemove(index);
            }}
          />
        );
      })}
    </VStack>
  );
};
