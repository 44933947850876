import { FC } from 'types';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { AssessmentConfirmationField } from 'components/assessment-fields/AssessmentConfirmationField/AssessmentConfirmationField';
import { AssessmentCountryField } from 'components/assessment-fields/AssessmentCountryField/AssessmentCountryField';
import { AssessmentDateField } from 'components/assessment-fields/AssessmentDateField/AssessmentDateField';
import { AssessmentEmailAddressField } from 'components/assessment-fields/AssessmentEmailAddressField/AssessmentEmailAddressField';
import { AssessmentFreeTextField } from 'components/assessment-fields/AssessmentFreeTextField/AssessmentFreeTextField';
import { AssessmentMultipleChoiceField } from 'components/assessment-fields/AssessmentMultipleChoiceField/AssessmentMultipleChoiceField';
import { AssessmentNameField } from 'components/assessment-fields/AssessmentNameField/AssessmentNameField';
import { AssessmentNumberField } from 'components/assessment-fields/AssessmentNumberField/AssessmentNumberField';
import { AssessmentSingleChoiceField } from 'components/assessment-fields/AssessmentSingleChoiceField/AssessmentSingleChoiceField';
import { AssessmentScaleNumberField } from 'components/assessment-fields/AssessmentScaleNumberField/AssessmentScaleNumberField';

interface IAssessmentFieldProps {
  assessmentQuestion: AssessmentFormComponent;
  disabled?: boolean;
  index?: number;
}
export const AssessmentField: FC<IAssessmentFieldProps> = ({
  assessmentQuestion,
  index,
  disabled,
}) => {
  switch (assessmentQuestion.componentType) {
    case FormComponentType.Name:
      return (
        <AssessmentNameField
          assessmentQuestion={assessmentQuestion}
          index={index}
          disabled={disabled}
        />
      );
    case FormComponentType.FreeText:
      return (
        <AssessmentFreeTextField
          assessmentQuestion={assessmentQuestion}
          index={index}
          disabled={disabled}
        />
      );
    case FormComponentType.EmailAddress:
      return (
        <AssessmentEmailAddressField
          assessmentQuestion={assessmentQuestion}
          index={index}
          disabled={disabled}
        />
      );
    case FormComponentType.Date:
      return (
        <AssessmentDateField
          assessmentQuestion={assessmentQuestion}
          index={index}
          disabled={disabled}
        />
      );
    case FormComponentType.Number:
      return (
        <AssessmentNumberField
          assessmentQuestion={assessmentQuestion}
          index={index}
          disabled={disabled}
        />
      );
    case FormComponentType.Confirmation:
      return (
        <AssessmentConfirmationField
          assessmentQuestion={assessmentQuestion}
          index={index}
          disabled={disabled}
        />
      );
    case FormComponentType.SingleChoice:
      return (
        <AssessmentSingleChoiceField
          assessmentQuestion={assessmentQuestion}
          index={index}
          disabled={disabled}
        />
      );
    case FormComponentType.MultipleChoice:
      return (
        <AssessmentMultipleChoiceField
          assessmentQuestion={assessmentQuestion}
          index={index}
          disabled={disabled}
        />
      );
    case FormComponentType.Country:
      return (
        <AssessmentCountryField
          assessmentQuestion={assessmentQuestion}
          index={index}
          disabled={disabled}
        />
      );
    case FormComponentType.ScaleNumber:
      return (
        <AssessmentScaleNumberField
          assessmentQuestion={assessmentQuestion}
          index={index}
          disabled={disabled}
        />
      );
    case FormComponentType.ScaleText:
      return (
        <AssessmentScaleNumberField
          assessmentQuestion={assessmentQuestion}
          index={index}
          disabled={disabled}
        />
      );
    default:
      return null;
  }
};
