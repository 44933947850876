import { FC } from 'types';

import { useFormsComponents } from 'hooks/useFormsComponents';
import { AbsoluteLoader } from 'components/core/LoadingIndicator/AbsoluteLoader';
import { usePopAlertFail } from 'hooks/usePopAlertFail';

import { EditCampaignModal } from '../EditCampaignModal/EditCampaignModal';

interface ICampaignModalWrapperProps {
  formId: string;
  roomId: string;
  campaignId: string;
  onCancel(): void;
}

export const CampaignModalWrapper: FC<ICampaignModalWrapperProps> = ({
  formId,
  roomId,
  campaignId,
  onCancel,
}) => {
  const { error, isInitialLoad } = useFormsComponents(roomId, formId);

  usePopAlertFail(error, async () => setTimeout(() => onCancel(), 500));

  if (isInitialLoad) {
    return <AbsoluteLoader />;
  }

  return (
    <EditCampaignModal
      formId={formId}
      roomId={roomId}
      campaignId={campaignId}
      onCancel={onCancel}
    />
  );
};
