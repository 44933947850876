import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import { Control, FieldValues, useForm, useWatch } from 'react-hook-form';

import { useTenant } from 'hooks/useTenant';
import { CheckboxField } from 'fields/CheckboxField/CheckboxField';
import { FormModal } from 'components/modal/FormModal/FormModal';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';

import {
  exportOptions,
  handleSiteAssessmentsExcelExportRequest,
  handleSiteAssessmentsJsonExportRequest,
  handleSiteDataCsvExport,
  handleSiteFormsExcelExportRequest,
  handleSiteFormsJsonExportRequest,
  SiteExportFormValues,
} from './ExportSiteDataModal.utils';

interface IExportSiteDataModalProps {
  onCancel(): void;
}
export const ExportSiteDataModal: FC<IExportSiteDataModalProps> = ({ onCancel }) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SiteExportFormValues>({
    defaultValues: {
      users: true,
      domains: true,
      formsJson: true,
      formsExcel: true,
      assessmentsExcel: true,
      assessmentsJson: true,
      statistics: false,
    },
  });

  const watchAll = useWatch({ control });
  const anySelected = Object.values(watchAll).some((value) => value);

  const { t } = useTranslation();
  const siteId = useTenant().activeTenant?.siteId;

  const handleExport = async (formValues: SiteExportFormValues) => {
    if (!siteId || !anySelected) {
      return;
    }
    await Promise.allSettled([
      formValues.formsExcel && handleSiteFormsExcelExportRequest(t, siteId),
      formValues.formsJson && handleSiteFormsJsonExportRequest(t, siteId),
      formValues.assessmentsExcel && handleSiteAssessmentsExcelExportRequest(t, siteId),
      formValues.assessmentsJson && handleSiteAssessmentsJsonExportRequest(t, siteId),
      (formValues.users || formValues.domains) && handleSiteDataCsvExport(t, siteId, formValues),
    ]);
  };

  return (
    <FormModal isOpen maxWidth="500px" title={t('export.modal.title.all')} onClose={onCancel}>
      <Box as="form" onSubmit={handleSubmit(handleExport)}>
        {exportOptions.map((option) => (
          <CheckboxField
            key={option.value}
            control={control as unknown as Control<FieldValues>}
            name={option.value}
            className="a-mb-20"
            disabled={option.disabled}
          >
            {t(option.label)}
          </CheckboxField>
        ))}
        {(watchAll.formsExcel || watchAll.formsJson) && (
          <Text color="brand.yellow">{t('export.modal.info.forms')}</Text>
        )}
        {(watchAll.assessmentsExcel || watchAll.assessmentsJson) && (
          <Text color="brand.yellow">{t('export.modal.info.assessments')}</Text>
        )}
        <FormActions>
          <FormButtonCancel onClick={onCancel} />
          <FormButtonSubmit
            isLoading={isSubmitting || !siteId}
            disabled={!siteId || !anySelected || isSubmitting}
          >
            {t('action.export.label')}
          </FormButtonSubmit>
        </FormActions>
      </Box>
    </FormModal>
  );
};
