import { Box, HStack, VStack, Text, Flex, useBoolean } from '@chakra-ui/react';
import { FC } from 'types';
import { useState } from 'react';
import { Button, Switch, SelectOption } from 'appkit-react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useSWRConfig } from 'swr';
import { useTranslation } from 'react-i18next';

import { useMutate } from 'hooks/useMutate';
import { SiteStatus } from 'typings/enums/SiteStatus';
import { IDomain } from 'typings/interfaces/IDomain';
import { ControlledInputField } from 'fields/InputField/InputField';
import { ControlledSelectField } from 'fields/SelectField/SelectField';
import { TagInputField } from 'fields/TagInputField/TagInputField';
import { Site } from 'models/Site';
import { isEmail } from 'utils/isEmail';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { InputNumberField } from 'fields/InputNumberField/InputNumberField';
import { SwitchField } from 'fields/SwitchField/SwitchField';

import { DomainManagement } from './DomainManagement';

interface ISiteManagementFormProps {
  site: Site | undefined;
  onCancel(): void;
  onSiteCreated(siteName: string): void;
}

export interface IFormData {
  siteName: string;
  siteAdmins: Array<string>;
  pwcContact: string | null;
  siteStatus: SiteStatus;
  maximumNumberOfUsers: number;
  allowLibraryAccess: boolean;
  allowThirdPartyUser: boolean;
  manageDominantDomains: boolean;
  manageNonDominantDomains: boolean;
  domains: Array<IDomain>;
}

export const SiteManagementForm: FC<ISiteManagementFormProps> = ({
  site,
  onCancel,
  onSiteCreated,
}) => {
  const saveMutation = useMutate(site ? Site.update() : Site.create(), Site.type);
  const deleteMutation = useMutate(Site.delete(site?.id), Site.type);
  const [siteAdmins, setSiteAdmins] = useState(site?.adminEmails || []);

  const [isSaving, setIsSaving] = useBoolean();
  const [isDeleting, setIsDeleting] = useBoolean();
  const [domainsError, setDomainsError] = useState('');

  const { mutate: globalMutate } = useSWRConfig();
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    control,
  } = useForm<IFormData>({
    defaultValues: {
      siteName: site?.siteName || '',
      siteAdmins: site?.adminEmails || [],
      siteStatus: site?.siteStatus || SiteStatus.Active,
      pwcContact: site?.pwcContact || '',
      allowLibraryAccess: site?.allowLibraryAccess || false,
      allowThirdPartyUser: site?.allowThirdPartyUser || false,
      manageDominantDomains: site?.manageDominantDomains || false,
      manageNonDominantDomains: site?.manageNonDominantDomains || false,
      maximumNumberOfUsers: site?.maximumNumberOfUsers,
      domains: site?.domains || [],
    },
  });

  const onSubmit = async (values: IFormData) => {
    if (siteAdmins.length === 0) {
      setError('siteAdmins', { message: t('applicationAdmin.site.modal.form.admins.error') });
      return;
    }

    if (values.domains.length === 0) {
      setDomainsError(t('applicationAdmin.site.modal.form.domains.errors.empty'));
      return;
    }

    const isAnyDomainsInputEmpty = values.domains.find(
      (domain) => domain.id === '' || domain.name === '',
    );

    if (isAnyDomainsInputEmpty) {
      setDomainsError(t('applicationAdmin.site.modal.form.domains.errors.input'));
      return;
    }

    setIsSaving.on();

    try {
      await saveMutation.mutate({
        ...values,
        siteAdmins,
        pwcContact: values.pwcContact || null,
        id: site?.id,
      });

      globalMutate('sites');
      if (site?.id) {
        globalMutate(`sites/${site?.id}`);
        globalMutate('tenants');
      }

      if (!site?.id) {
        onSiteCreated(values.siteName);
      }

      PopAlertSuccess(
        site?.id
          ? t('applicationAdmin.site.modal.successMessages.updated', { name: values.siteName })
          : t('applicationAdmin.site.modal.successMessages.created', { name: values.siteName }),
      );
      setIsSaving.off();
      onCancel();
    } catch (response: any) { // eslint-disable-line
      setIsSaving.off();
      if (response.errors) {
        Object.keys(response.errors).forEach((fieldName) => {
          setError(fieldName as keyof IFormData, { message: response.errors[fieldName][0] });
        });
      } else {
        PopAlertFail(
          site?.id
            ? t('applicationAdmin.errors.site.update')
            : t('applicationAdmin.errors.site.create'),
        );
      }
    }
  };

  const onDelete = async () => {
    setIsDeleting.on();
    try {
      await deleteMutation.mutate();
      await globalMutate('sites');

      PopAlertSuccess(
        t('applicationAdmin.site.modal.successMessages.deleted', {
          name: site?.siteName,
        }),
      );
      onCancel();
      setIsDeleting.off();
    } catch (response: any) { // eslint-disable-line
      setIsDeleting.off();
      PopAlertFail(t('applicationAdmin.errors.site.delete'));
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <VStack align="stretch" spacing="20px">
        <HStack spacing="86px" justify="space-between" align="flex-start">
          <ControlledInputField
            sx={{ width: '100%' }}
            control={control as unknown as Control<FieldValues>}
            label={t('applicationAdmin.site.modal.form.name.label').toUpperCase()}
            id="siteName"
            htmlFor="siteName"
            name="siteName"
            placeholder={t('applicationAdmin.site.modal.form.name.placeholder')}
            defaultValue={site?.siteName}
            rules={{ required: { value: true, message: t('validation.required') } }}
          />
          <ControlledSelectField
            control={control as unknown as Control<FieldValues>}
            name="siteStatus"
            label={t('applicationAdmin.site.modal.form.status.label').toUpperCase()}
            defaultValue={site?.siteStatus.toLowerCase() || SiteStatus.Active}
            rules={{ required: { value: true, message: t('validation.required') } }}
          >
            <SelectOption value={SiteStatus.Active}>
              {t('applicationAdmin.site.modal.form.status.select.activeOption')}
            </SelectOption>
            <SelectOption value={SiteStatus.Inactive}>
              {t('applicationAdmin.site.modal.form.status.select.inactiveOption')}
            </SelectOption>
          </ControlledSelectField>
        </HStack>
        <HStack spacing="86px" justify="space-between" align="flex-end">
          <TagInputField
            label={t('applicationAdmin.site.modal.form.admins.label').toUpperCase()}
            tags={siteAdmins}
            name="siteAdmins"
            placeholder={t('applicationAdmin.site.modal.form.admins.placeholder')}
            errors={errors.siteAdmins}
            validator={(value) => {
              if (isEmail(value)) {
                clearErrors('siteAdmins');
                return true;
              } else {
                setError('siteAdmins', { message: t('validation.validEmail') });
                return false;
              }
            }}
            onChange={(newTags) => {
              setValue('siteAdmins', newTags);
              setSiteAdmins(newTags);
            }}
          />
        </HStack>
        <HStack spacing="86px" justify="space-between" align="center">
          <Flex align="center" width="100%">
            <InputNumberField
              sx={{ flex: 2, ml: '10px' }}
              control={control as unknown as Control<FieldValues>}
              label={t('applicationAdmin.site.modal.form.numberOfUsers.label')}
              name="maximumNumberOfUsers"
              min={1}
              step={10}
              defaultValue={site?.maximumNumberOfUsers}
            />
          </Flex>
          <Flex width="100%" justify="space-between">
            <SwitchField
              control={control as unknown as Control<FieldValues>}
              name="manageDominantDomains"
              label={t('applicationAdmin.site.modal.form.manage.dominantDomains')}
              defaultChecked={site?.manageDominantDomains}
            />
            <SwitchField
              control={control as unknown as Control<FieldValues>}
              name="manageNonDominantDomains"
              label={t('applicationAdmin.site.modal.form.manage.secondaryDomains')}
              defaultChecked={site?.manageNonDominantDomains}
            />
          </Flex>
        </HStack>
        <HStack spacing="86px" justify="space-between" align="flex-end">
          <ControlledInputField
            sx={{ width: '100%' }}
            control={control as unknown as Control<FieldValues>}
            label={t('applicationAdmin.site.modal.form.contact.label').toUpperCase()}
            placeholder={t('applicationAdmin.site.modal.form.contact.placeholder')}
            id="pwcContact"
            htmlFor="pwcContact"
            name="pwcContact"
            defaultValue={site?.pwcContact}
            rules={{
              pattern: {
                value: /^\w[\w.-]*@([\w-]+\.)+[\w-]+$/,
                message: t('validation.validEmail'),
              },
            }}
          />
          <Flex width="100%">
            <Switch size="small" label={t('applicationAdmin.site.modal.form.execute.label')} />
            <SwitchField
              ml={20}
              control={control as unknown as Control<FieldValues>}
              name="allowLibraryAccess"
              label={t('applicationAdmin.site.modal.form.access.label')}
              defaultChecked={site?.allowLibraryAccess}
            />
          </Flex>
        </HStack>
      </VStack>

      <Flex width="100%" mt={40} direction="column" key={site?.domains.length}>
        <Text size="18px" lineHeight="30px">
          {t('applicationAdmin.site.modal.form.domains.title')}
        </Text>
        {domainsError && <span className="a-form-error">{domainsError}</span>}
        <DomainManagement control={control} />
      </Flex>

      <Flex align="center" justify={site ? 'space-between' : 'flex-end'} mt={40} pb={20}>
        {site && (
          <Button
            size="lg"
            type="button"
            negative
            disabled={isSaving || isDeleting}
            isLoading={isDeleting}
            onClick={onDelete}
          >
            {t('applicationAdmin.site.modal.action.delete.label').toUpperCase()}
          </Button>
        )}
        <Flex align="center">
          <Button
            kind="secondary"
            size="lg"
            type="button"
            disabled={isSaving || isDeleting}
            onClick={onCancel}
          >
            {t('action.cancel.label').toUpperCase()}
          </Button>
          <Button
            size="lg"
            className="a-ml-10"
            type="submit"
            isLoading={isSaving}
            disabled={isDeleting || isSaving}
          >
            {t('action.save.label').toUpperCase()}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
