import { chakra } from '@chakra-ui/react';

export const QuestionContainer = chakra('div', {
  baseStyle: {
    bgColor: 'grey.lvl3',
    minH: '110px',
    width: '100%',
    pt: 12,
    pb: 20,
    pl: 12,
  },
});
