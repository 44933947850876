import { Attribute } from '@datx/core';
import { computed, observable } from 'mobx';

import { UserRole } from 'typings/enums/UserRole';

import { Resource } from './Resource';

export class Tenant extends Resource {
  public static type = 'tenants';

  @Attribute({ isIdentifier: true })
  public siteId!: string;

  @Attribute()
  public siteName!: string;

  @Attribute()
  public siteRole!: string;

  @Attribute()
  public isFavorite!: boolean;

  @Attribute()
  public manageDominantDomains!: boolean;

  @Attribute()
  public manageNonDominantDomains!: boolean;

  @observable
  public isSelected!: boolean;

  @computed
  public get isSiteAdmin() {
    return this.siteRole === UserRole.SiteAdmin;
  }
}
