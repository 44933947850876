import { FormControl, FormControlProps, Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'types';
import { Input } from 'appkit-react';
import { useController, UseControllerProps } from 'react-hook-form';

import { IFieldProps } from 'typings/interfaces/IFieldProps';

interface IInputFieldProps extends IFieldProps, Omit<BoxProps, 'onChange' | 'defaultValue'> {
  defaultValue?: string | undefined;
  onChange?(value: string): void;
}

export const InputField: FC<IInputFieldProps> = ({
  name,
  label,
  errors,
  sx,
  onChange,
  ...props
}) => {
  return (
    <Box sx={sx}>
      <Input
        name={name}
        hasError={Boolean(errors)}
        errMsg={errors?.message}
        onChange={onChange}
        label={
          label && (
            <Box as="span" textTransform="uppercase">
              {label}
            </Box>
          )
        }
        {...props}
      />
    </Box>
  );
};

interface IInputFieldPropsV7
  extends UseControllerProps,
    Omit<FormControlProps, 'onChange' | 'defaultValue'> {
  disabled?: boolean;
  htmlFor?: string;
  label?: string;
}

export const InputFieldV7: FC<IInputFieldPropsV7> = ({
  control,
  name,
  defaultValue,
  rules,
  label,
  htmlFor,
  sx,
  ...rest
}) => {
  // we need to remove value from test of the props so input will remain uncontrolled
  const {
    // eslint-disable-next-line
    field: { value, ...restFieldProps },
    fieldState: { error },
  } = useController({ control, name, defaultValue, rules });

  return (
    <FormControl sx={sx}>
      <Input
        hasError={Boolean(error)}
        htmlFor={htmlFor}
        label={
          label && (
            <Box as="span" textTransform="uppercase">
              {label}
            </Box>
          )
        }
        errMsg={error?.message}
        {...restFieldProps}
        {...rest}
        defaultValue={value ?? defaultValue}
      />
    </FormControl>
  );
};

interface IControlledInputFieldProps
  extends UseControllerProps,
    Omit<FormControlProps, 'onChange' | 'defaultValue'> {
  disabled?: boolean;
  htmlFor?: string;
  label?: string;
}

export const ControlledInputField: FC<IControlledInputFieldProps> = ({
  control,
  name,
  defaultValue,
  rules,
  label,
  htmlFor,
  sx,
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, defaultValue, rules });

  return (
    <FormControl sx={sx}>
      <Input
        hasError={Boolean(error)}
        htmlFor={htmlFor}
        label={
          label && (
            <Box as="span" textTransform="uppercase">
              {label}
            </Box>
          )
        }
        errMsg={error?.message}
        {...field}
        {...rest}
      />
    </FormControl>
  );
};
