import { HStack, Spacer, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToggleExpandButton } from 'components/core/ToggleExpandButton/ToggleExpandButton';
import { StyledButton, StyledInput } from 'components/core/StyledAppKit/StyledAppKit';

import { SectionCloseButton } from '../SectionCloseButton/SectionCloseButton';

interface IFormEditorSectionHeaderProps {
  isExpanded: boolean;
  name: string | null;
  prefix: string | null;
  allowEdit: boolean;
  onToggleExpand(): void;
  onSave(name: string, namePrefix: string | null): void;
  onDelete(): void;
}

export const FormEditorSectionHeader: FC<IFormEditorSectionHeaderProps> = ({
  isExpanded,
  name,
  prefix,
  allowEdit,
  onToggleExpand,
  onSave,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [isEditMode, setEditMode] = useState(allowEdit && !name);
  const [changedName, setChangedName] = useState(name);
  const [changedPrefix, setChangedPrefix] = useState(prefix);

  const handleSave = () => {
    if (!allowEdit || !changedName || !isEditMode) {
      return;
    }
    onSave(changedName, changedPrefix);
    setEditMode(false);
  };

  const handleClickToggle = () => {
    if (!changedName || isEditMode) {
      return;
    }
    onToggleExpand();
  };

  const handleNameDoubleClick = () => {
    if (allowEdit) {
      setEditMode(true);
    }
  };

  return (
    <HStack
      spacing={20}
      justify="space-between"
      sx={{
        height: '42px',
        mb: isExpanded || 16,
        borderBottom: ({ colors }) => `2px solid ${colors.grey.lvl3}`,
      }}
    >
      {!isEditMode && (
        <Text size="lg" onDoubleClick={handleNameDoubleClick}>
          {changedPrefix}
        </Text>
      )}
      {isEditMode && (
        <StyledInput
          minWidth="350px"
          placeholder={t('form.section.prefixPlaceholder')}
          defaultValue={changedPrefix}
          onChange={setChangedPrefix}
        />
      )}
      <Spacer />
      {isEditMode && (
        <StyledInput
          minWidth="350px"
          placeholder={t('form.section.namePlaceholder')}
          defaultValue={changedName}
          onChange={setChangedName}
          onEnterPress={handleSave}
        />
      )}
      {!isEditMode && (
        <Text size="lg" onDoubleClick={handleNameDoubleClick}>
          {changedName}
        </Text>
      )}
      <Spacer />
      {isEditMode && (
        <StyledButton kind="transparent" disabled={!changedName} onClick={handleSave}>
          {t('action.save.label')}
        </StyledButton>
      )}

      <ToggleExpandButton isExpanded={isExpanded} onClick={handleClickToggle} />
      <SectionCloseButton onClick={onDelete} />
    </HStack>
  );
};
