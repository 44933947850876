import { FormStatus } from 'typings/enums/FormStatus';
import { FormAction } from 'typings/enums/FormAction';

export const getFormAction = (
  formStatus: FormStatus | undefined,
  hasApproverFlag: boolean,
  userIsApprover: boolean,
  userCanEdit: boolean,
): FormAction => {
  if (formStatus === FormStatus.InProgress && !hasApproverFlag) {
    return FormAction.Finalize;
  }
  if (formStatus === FormStatus.InProgress && hasApproverFlag) {
    return FormAction.Submit;
  }
  if (formStatus === FormStatus.WaitingForApproval && hasApproverFlag && !userIsApprover) {
    return FormAction.Withdraw;
  }
  if (formStatus === FormStatus.WaitingForApproval && hasApproverFlag && userIsApprover) {
    return FormAction.Review;
  }

  if (
    formStatus === FormStatus.Finalized &&
    ((hasApproverFlag && userIsApprover) || (!hasApproverFlag && userCanEdit))
  ) {
    // allows users to edit question and non-logic text
    return FormAction.EditFinalized;
  }

  if (formStatus === FormStatus.Finalized && !userCanEdit) {
    return FormAction.Initiate;
  }
  return FormAction.Unknown;
};
