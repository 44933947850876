import { Attribute } from '@datx/core';
import { computed } from 'mobx';
import { format } from 'date-fns';

import { AssessmentStatus } from 'typings/enums/AssessmentStatus';
import { AssessmentViewActivationStatus } from 'typings/enums/AssessmentViewActivationStatus';
import { Resource } from './Resource';
import { AssessmentStatusDisplay } from 'typings/types/AssessmentStatusDisplay';

export class AssessmentView extends Resource {
  public static type = 'assessments';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public name!: string;

  @Attribute()
  public recipientCount!: number;

  @Attribute()
  public permissionGroupCount!: number;

  @Attribute()
  public lastEdit!: string;

  @Attribute()
  public dueDate!: string;

  @Attribute()
  public status!: AssessmentStatus;

  @Attribute()
  public isFavorite!: boolean;

  @Attribute()
  public hasSubmissionAlert!: boolean;

  @Attribute()
  public activationStatus!: AssessmentViewActivationStatus;

  @Attribute()
  public manageAssessmentCollaborator!: boolean;

  @computed
  public get displayStatus(): AssessmentStatusDisplay {
    if (this.activationStatus === AssessmentViewActivationStatus.Deactivated) {
      return AssessmentViewActivationStatus.Deactivated;
    }
    return this.status;
  }

  @computed
  public get formattedLastEdit() {
    if (this.lastEdit) {
      return format(new Date(this.lastEdit), 'PPpp');
    }

    return '/';
  }

  @computed
  public get formattedDueDate() {
    if (this.dueDate) {
      return format(new Date(this.dueDate), 'PPpp');
    }

    return '/';
  }
}
