import React, { Factory } from 'react';
import { Button } from 'appkit-react';
import { ButtonProps } from 'react-awesome-query-builder';

type TypeToKind = Record<string, string>;

export const AQBButton: Factory<ButtonProps> = (props) => {
  const typeToIcon: TypeToKind = {
    addRule: 'appkiticon icon-circle-plus-outline',
    addGroup: 'appkiticon icon-circle-plus-outline',
  };

  const typeToKind: TypeToKind = {
    addRule: 'primary',
    addGroup: 'secondary',
  };

  const isDelete = props?.type.includes('del');

  if (isDelete) {
    return (
      <Button kind="transparent" negative size="md" onClick={props?.onClick}>
        REMOVE
      </Button>
    );
  } else {
    return (
      <Button
        size="md"
        onClick={props?.onClick}
        kind={typeToKind[props?.type ?? '']}
        className="a-mr-5"
      >
        <span className={`${typeToIcon[props?.type ?? '']} a-mr-5`} />
        {props?.label}
      </Button>
    );
  }
};
