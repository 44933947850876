import { FC } from 'types';

import { SectionActionButton } from '../SectionActionButton/SectionActionButton';

interface ISectionCloseButtonProps {
  onClick(): void;
}

export const SectionCloseButton: FC<ISectionCloseButtonProps> = ({ onClick }) => {
  return <SectionActionButton icon="icon-close-outline" label="Remove" onClick={onClick} />;
};
