import { Tabs, Tab } from 'appkit-react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { BoxHeader } from 'components/core/BoxHeader/BoxHeader';
import { BackButton } from 'components/core/BoxHeader/components/BackButton';
import { BoxTitle } from 'components/core/BoxHeader/components/BoxTitle';
import { MainLayout } from 'components/layouts/MainLayout/MainLayout';
import { useTenant } from 'hooks/useTenant';
import { PopAlertFail } from 'services/PopAlertFail';
import { FullScreenLoading } from 'components/core/FullScreenLoading/FullScreenLoading';

import { SiteAdminLibraryManagementPage } from './library-management';
import { SiteAdminRoomsManagementPage } from './rooms';
import { SiteAdminUserManagementPage } from './user-management';
import { SiteAdminDomainManagementPage } from './domain-management';

export const SiteAdminPage = () => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { activeTenant } = useTenant();

  const handleTabChange = (_: unknown, path: string) => {
    history.push(path);
  };

  useEffect(() => {
    if (activeTenant && !activeTenant.isSiteAdmin) {
      history.push('/rooms');
      PopAlertFail(
        t('alerts.unauthorizedSiteAccess.title'),
        t('alerts.unauthorizedSiteAccess.description'),
        false,
        8000,
      );
    }
  }, [activeTenant, activeTenant?.isSiteAdmin, history, t]);

  if (!activeTenant) {
    return <FullScreenLoading>{t('loading.authorization')}</FullScreenLoading>;
  }

  return (
    <MainLayout>
      <BoxHeader align="center" justifyContent="space-between">
        <BackButton />
        <BoxTitle>{t('siteAdmin.title')}</BoxTitle>
        <Box />
      </BoxHeader>
      <Flex justify="space-between">
        <Box width="270px">
          <Tabs onChange={handleTabChange} defaultValue={location.pathname}>
            <Tab label={t('siteAdmin.tabs.users').toUpperCase()} value={url} />
            <Tab
              label={t('siteAdmin.tabs.library').toUpperCase()}
              value={`${url}/library-management`}
            />
            <Tab label={t('siteAdmin.tabs.domains').toUpperCase()} value={`${url}/domains`} />
            <Tab label={t('siteAdmin.tabs.rooms').toUpperCase()} value={`${url}/rooms`} />
          </Tabs>
        </Box>
      </Flex>
      <Switch>
        <Route exact path={`${path}/(user-management)?`}>
          <SiteAdminUserManagementPage />
        </Route>
        <Route
          exact
          path={`${path}/library-management`}
          component={SiteAdminLibraryManagementPage}
        />
        <Route exact path={`${path}/domains`} component={SiteAdminDomainManagementPage} />
        <Route exact path={`${path}/rooms`} component={SiteAdminRoomsManagementPage} />
      </Switch>
    </MainLayout>
  );
};
