export const awesomeQueryBuilder = {
  '.query-builder-container .query-builder': {
    overflow: ' visible',
  },
  '.query-builder': {
    m: 0,
  },
  '.query-builder .group, .query-builder .group-or-rule, .query-builder .rule': {
    borderRadius: 0,
    background: '#303030',
  },
  '.query-builder .group': {
    borderColor: ' #fff',
  },
  '.query-builder .rule': {
    borderColor: '#474747',
  },
  '.query-builder .rule--body': {
    display: 'flex',
    alignItems: 'center',
  },
  '.query-builder .rule--body .rule--after-widget': {
    order: '-1',
  },
  '.group-or-rule-container': {
    mb: '15px !important',
    pr: '15px !important',
  },
  '.group--conjunctions': {
    display: 'flex',
    alignItems: 'center',
  },
  '.group--children > .group-or-rule-container > .group-or-rule::before, .group--children > .group-or-rule-container > .group-or-rule::after':
    {
      display: 'none',
    },
  '.preview': {
    width: '400px',
  },
  '.preview h3': {
    mb: '20px',
  },

  '.preview .preview-group': {
    borderLeft: '1px solid orange',
    borderRight: '1px solid orange',
    padding: '2px 10px',
    margin: ' 2px 0',
  },

  '.preview .preview-group .preview-group': {
    margin: '2px 10px',
  },
};
