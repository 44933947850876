import { chakra } from '@chakra-ui/react';
import SimpleBar from 'simplebar-react';

export const StyledSimpleBar = chakra(SimpleBar, {
  baseStyle: {
    '.simplebar-track.simplebar-vertical': {
      backgroundColor: 'grey.lvl6',
      width: '12px',
      '.simplebar-scrollbar.simplebar-visible': {
        _before: {
          backgroundColor: 'grey.lvl2',
          opacity: 1,
          left: '4px',
          right: '4px',
          borderRadius: '2px',
        },
      },
    },
  },
});
