import { useResources } from 'hooks/useResources';
import { Currency } from 'models/Currency';

export const useCurrencyByCode = (currencyCode: string | null): Currency | null => {
  const { data } = useResources(Currency, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  if (!data.length) {
    return null;
  }

  if (!currencyCode) {
    return null;
  }

  return (data as Array<Currency>).find((currency) => currency.code === currencyCode) ?? null;
};
