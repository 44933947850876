import { FC } from 'types';

import { AssessmentTableViewNameCell } from '../AssessmentTableViewNameCell/AssessmentTableViewNameCell';
import { AssessmentView } from 'models/AssessmentView';

interface IAssessmentTableViewNameCellWrapperProps {
  original: AssessmentView;
}

export const AssessmentTableViewNameCellWrapper: FC<IAssessmentTableViewNameCellWrapperProps> = ({
  original,
}) => {
  return <AssessmentTableViewNameCell assessmentViewItem={original as AssessmentView} />;
};
