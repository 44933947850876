import { useCallback } from 'react';
import { useSWRConfig } from 'swr';

import { useCurrentRoom } from 'hooks/useCurrentRoom';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';

export function useRoomSetExternal() {
  const { room } = useCurrentRoom();
  const { mutate } = useSWRConfig();
  const dependencyHeaders = useDependencyHeaders();

  const changeRoomToExternal = useCallback(async () => {
    const url = `rooms/${room?.id}/changeToExternal`;
    await apiClient(url, HttpMethod.Post, {
      headers: {
        ...dependencyHeaders,
      },
    });
  }, [room, dependencyHeaders]);

  const mutateRooms = useCallback(async () => {
    await mutate(`rooms@${dependencyHeaders['x-site-id']}`);
  }, [dependencyHeaders, mutate]);

  return { changeRoomToExternal, mutateRooms };
}
