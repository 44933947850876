import { Attribute } from '@datx/core';
import { computed } from 'mobx';
import { format } from 'date-fns';

import { FormStatus } from 'typings/enums/FormStatus';
import { FormType } from 'typings/enums/FormType';
import { IFormRoom } from 'typings/interfaces/IFormRoom';
import { FormActivationStatus } from 'typings/enums/FormActivationStatus';
import { UpdateFormDTO } from 'typings/types/dto/FormDTO';
import { FormUserRole } from 'typings/enums/FormUserRole';

import { FormComponent } from './FormComponent';
import { FormUser } from './FormUser';
import { Resource } from './Resource';
import { RoomFolder } from './RoomFolder';
import { User } from './User';
import { FormApproval } from './FormApproval';

export class Form extends Resource {
  public static type = 'forms';

  @Attribute()
  public readonly lastEditedUtc!: string;

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public name!: string;

  @Attribute()
  public notificationText!: string;

  @Attribute()
  public parentFolderId!: string;

  @Attribute()
  public formType!: FormType;

  @Attribute()
  public formStatus!: FormStatus;

  @Attribute({ map: 'hasApprover' })
  public hasApproverFlag!: boolean;

  @Attribute({ toOne: FormApproval, map: 'id' })
  public approval: FormApproval | undefined;

  @Attribute()
  public isFavorite!: boolean;

  @Attribute()
  public skipSendingNotification!: boolean;

  @Attribute({ toOne: User })
  public ownerUser!: User;

  @Attribute({ toMany: FormUser })
  public users!: Array<FormUser>;

  @Attribute()
  public room!: IFormRoom;

  @Attribute()
  public isDeleted!: boolean;

  @Attribute()
  public activationStatus!: FormActivationStatus;

  @computed
  public get isDeactivated() {
    return this.activationStatus === FormActivationStatus.Deactivated;
  }

  @computed
  public get isActive() {
    return !this.isDeleted && this.activationStatus === FormActivationStatus.Active;
  }

  @computed
  public get isEditable() {
    return this.isActive && this.formStatus === FormStatus.InProgress;
  }

  @computed
  public get ownerUsername() {
    return this.ownerUser?.username;
  }

  @computed
  public get formattedLastEdit() {
    if (this.lastEditedUtc) {
      return format(new Date(this.lastEditedUtc), 'PPp');
    }

    return '/';
  }

  @computed
  public get parentFolder(): RoomFolder | null | undefined {
    if (!this.parentFolderId) {
      return undefined;
    }
    return this.meta.collection?.findOne<RoomFolder>(RoomFolder, this.parentFolderId);
  }

  @computed
  public get components() {
    return this.meta.collection
      ?.findAll(FormComponent)
      .filter((component) => component.formId === this.id);
  }

  @computed
  public get updateDTO(): UpdateFormDTO {
    return {
      id: this.id,
      name: this.name,
      parentFolderId: this.parentFolderId,
      hasApprover: this.hasApproverFlag,
      formStatus: this.formStatus,
      users: this.users.map((user) => user.updateDTO),
      skipSendingNotification: true,
    };
  }

  public isFavorited(email: string): boolean {
    return Boolean(
      this.users.find((user) => user.isFavorite && user.email.toLowerCase() === email),
    );
  }

  public hasEditor(email: string): boolean {
    return (
      this.users.find((user) => user.email.toLowerCase() === email)?.role === FormUserRole.Edit
    );
  }
  public hasApproverWithEmail(email: string): boolean {
    const user = this.users.find((user) => user.email.toLowerCase() === email.toLowerCase());
    if (!user) {
      return false;
    }

    return Boolean(user.isApprover); // TODO: check with BE about:  || user.role === FormUserRole.Approve;
  }

  public isEditableFinalizedFor(email: string): boolean {
    if (!this.isActive || this.formStatus !== FormStatus.Finalized) {
      return false;
    }

    if (this.hasApproverFlag && this.hasApproverWithEmail(email)) {
      return true;
    }

    if (!this.hasApproverFlag && this.hasEditor(email)) {
      return true;
    }

    return false;
  }
}
