import { FC } from 'types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormComponent } from 'models/FormComponent';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { FormModal } from 'components/modal/FormModal/FormModal';
import { NumbersComponentType, NumbersSettings } from 'typings/types/FormComponentSettings';

import { NumbersSettingsForm } from '../NumbersSettingsForm/NumbersSettingsForm';
import { parseNumbersQuestionSettings } from '../NumbersSettingsEditorModal/NumbersSettingsEditorModal.utils';

interface INumbersSettingsEditorProps {
  question: FormComponent;
  orderTag: string;
  onClose(): void;
}
export const NumbersSettingsEditorModal: FC<INumbersSettingsEditorProps> = ({
  orderTag,
  question,
  onClose,
}) => {
  const { t } = useTranslation();
  const { upsertComponent, disableEdit, allowLimitedEditOnFinalized } = useFormEditor();

  const handleClickSave = useCallback(
    async (value: NumbersSettings) => {
      if (
        disableEdit &&
        (value.numberType !== NumbersComponentType.Currency || !allowLimitedEditOnFinalized)
      ) {
        return;
      }
      question.settingsJson = JSON.stringify(value);
      await upsertComponent(question);
      onClose();
    },
    [allowLimitedEditOnFinalized, disableEdit, onClose, question, upsertComponent],
  );

  const parsed = parseNumbersQuestionSettings(question.settingsJson);

  return (
    <FormModal
      isOpen
      title={t('form.settings.modal.title', {
        questionNo: orderTag,
        questionType: t('form.settings.numbers.name'),
      })}
      maxWidth="550px"
      onClose={onClose}
    >
      <NumbersSettingsForm
        settings={parsed}
        disabled={disableEdit}
        disableCurrencyChange={disableEdit && !allowLimitedEditOnFinalized}
        onSubmit={handleClickSave}
        onCancel={onClose}
      />
    </FormModal>
  );
};
