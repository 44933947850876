import { Attribute } from '@datx/core';
import { computed } from 'mobx';

import {
  UpsertAssessmentPermissionGroupDTO,
  IPermissionGroup,
} from 'typings/types/dto/CampaignDTO';
import { isEmail } from 'utils/isEmail';

import { Resource } from './Resource';

export class AssessmentPermissionGroup extends Resource {
  public static type = 'assessment_permission_group';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public name!: string;

  // this looks like CampaignPermissionGroup but it's not because it can have different recipientEmails
  @Attribute()
  public permissionGroups!: Array<IPermissionGroup>;

  @computed
  public get isDraft() {
    return typeof this.id === 'number';
  }

  @computed
  public get isEmpty(): boolean {
    if (!this.permissionGroups?.length) {
      return true;
    }

    return !this.permissionGroups.some((permissionGroup) =>
      Boolean(permissionGroup.recipientEmails?.length),
    );
  }

  @computed
  public get hasValidRecipients(): boolean {
    if (this.isEmpty) {
      return true;
    }
    const flatEmails = this.permissionGroups.flatMap(
      (permissionGroup) => permissionGroup?.recipientEmails ?? [],
    );

    return !flatEmails.some((email) => !isEmail(email));
  }

  @computed
  public get upsertDto(): UpsertAssessmentPermissionGroupDTO {
    return {
      id: this.isDraft ? null : this.id,
      name: this.name,
      permissionGroups:
        this.permissionGroups?.map((permissionGroup) => ({
          id: permissionGroup.id,
          name: permissionGroup.name,
          componentIds: permissionGroup.componentIds,
          recipientEmails: permissionGroup.recipientEmails,
        })) ?? null,
    };
  }
}
