import React from 'react';
import { FC } from 'types'
import { useTranslation } from 'react-i18next';

import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { Modal } from 'components/modal/Modal/Modal';
import { UserManagementForm } from 'forms/SiteAdmin/UserManagementForm/UserManagementForm';
import { User } from 'models/User';

interface IUserManagementModalProps {
  isVisible: boolean;
  user: User | null;
  onCancel(): void;
}

export const UserManagementModal: FC<IUserManagementModalProps> = ({
  isVisible,
  onCancel,
  user,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      sx={{ maxWidth: '1160px', width: '100%', mx: 'auto', mt: '10%' }}
      isVisible={isVisible}
      {...rest}
    >
      <ModalHeader onCancel={onCancel}>
        <ModalTitle>{t('siteAdmin.user.modal.title').toUpperCase()}</ModalTitle>
      </ModalHeader>
      <ModalBody sx={{ px: '40px' }}>
        <UserManagementForm onCancel={onCancel} user={user} />
      </ModalBody>
    </Modal>
  );
};
