import { Box, HStack, Square, Text, VStack } from '@chakra-ui/react';
import { FC } from 'types';
import { Tooltip } from 'appkit-react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledInputField } from 'fields/InputField/InputField';
import { ConfirmationSettings } from 'typings/types/FormComponentSettings';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { SkipConditionSelect } from 'components/form-editor/SkipConditionSelect/SkipConditionSelect';
import { FormComponent } from 'models/FormComponent';

interface IConfirmationSettingsFormProps {
  question: FormComponent;
  settings: ConfirmationSettings;
  disabled?: boolean;
  disableTextChange: boolean;
  skipConditionSettings?: Record<string, string>;
  onSubmit(value: ConfirmationSettings): void;
  onCancel(): void;
}

export const ConfirmationSettingsForm: FC<IConfirmationSettingsFormProps> = ({
  question,
  settings,
  skipConditionSettings,
  disabled,
  disableTextChange,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<ConfirmationSettings>({
    defaultValues: {
      confirmationText: settings.confirmationText ?? '',
      skipTo: skipConditionSettings?.['true'],
    },
  });

  const handleSave = (value: ConfirmationSettings) => {
    onSubmit(value);
  };
  const hasSkipLogic = Boolean(skipConditionSettings);

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)}>
      <VStack spacing={20}>
        <HStack width="100%" alignItems="flex-end">
          <ControlledInputField
            control={control as unknown as Control<FieldValues>}
            htmlFor="confirmationText"
            name="confirmationText"
            label={t('form.settings.confirmation.confirmationTextLabel')}
            disabled={disableTextChange}
          />
          <Square size="34px">
            <Tooltip content="Helper text" placement="top">
              <Text
                size="3xl"
                as="span"
                className="appkiticon icon-help-question-fill"
                color="brand.orange"
              />
            </Tooltip>
          </Square>
        </HStack>
        {hasSkipLogic && (
          <SkipConditionSelect
            label={t('form.settings.confirmation.skipLogic').toUpperCase()}
            control={control as unknown as Control<FieldValues>}
            name="skipTo"
            currentComponentOrder={question.order}
            disabled={disabled}
          />
        )}
      </VStack>

      <FormActions>
        <FormButtonCancel onClick={onCancel} />
        <FormButtonSubmit disabled={disabled && disableTextChange} />
      </FormActions>
    </Box>
  );
};
