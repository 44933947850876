import { FC } from 'types';
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/react';

import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { IResourceUser } from 'typings/interfaces/IResourceUser';
import { IFolderUser } from 'typings/interfaces/IFolderUser';
import { IFolderUserManagementFields } from 'typings/interfaces/IFolderUserManagementFields';

import { FolderUserManagement } from '../FolderUserManagement/FolderUserManagement';

interface IFolderUserManagementFormProps {
  availableUsers: Array<IResourceUser>;
  folderUsers: Array<IFolderUser>;
  isLoading: boolean;
  onSave(formValues: IFolderUserManagementFields): Promise<void>;
  onCancel(): void;
}
export const FolderUserManagementForm: FC<IFolderUserManagementFormProps> = ({
  availableUsers,
  folderUsers,
  isLoading,
  onSave,
  onCancel,
}) => {
  const { control, handleSubmit } = useForm<IFolderUserManagementFields>({
    defaultValues: {
      users: folderUsers,
    },
  });

  const handleSave = async (formValues: IFolderUserManagementFields) => {
    await onSave(formValues);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)}>
      <FolderUserManagement control={control} availableUsers={availableUsers} />
      <FormActions>
        <FormButtonCancel onClick={onCancel} />
        <FormButtonSubmit isLoading={isLoading} disabled={isLoading} />
      </FormActions>
    </Box>
  );
};
