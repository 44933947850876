import { DateFormats, DateSettings } from 'typings/types/FormComponentSettings';

export function parseDateQuestionSettings(stringifiedJSON: string | null): DateSettings {
  if (!stringifiedJSON) {
    return { dateFormat: DateFormats[0], dateRangePicker: false };
  }
  try {
    const result = JSON.parse(stringifiedJSON) as DateSettings;

    return {
      dateFormat: result.dateFormat ?? DateFormats[0],
      dateRangePicker: result.dateRangePicker ?? false,
    };
  } catch (error: any) { // eslint-disable-line
    return { dateFormat: DateFormats[0], dateRangePicker: false };
  }
}
