import { Box } from '@chakra-ui/react';
import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, ButtonGroupItem } from 'appkit-react';

import { FormComponent } from 'models/FormComponent';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { FormModal } from 'components/modal/FormModal/FormModal';
import {
  IntervalValue,
  ScaleIntervalInputType,
  ScaleSettingsFormFields,
  ScaleType,
} from 'typings/types/FormComponentSettings';
import { ScaleSettingsForm } from 'components/question-settings/ScaleSettingsForm/ScaleSettingsForm';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { FormAddonType } from 'typings/enums/FormAddonType';
import {
  getSkipConditionSettingsFromChoices,
  parseSkipConditionSettings,
} from 'services/skipConditionAddonSettings';

import { ScaleSliderSelection } from './components/ScaleSliderSelection/ScaleSliderSelection';
import { ScaleSettingsIntervals } from './components/ScaleSettingsIntervals/ScaleSettingsIntervals';
import { useScaleComponentState } from './ScaleSettingsEditorModal.hooks';
import { getSettingsFromFormValues, stringifyIntervalMarks } from './ScaleSettingsEditor.utils';

enum ScaleConfigStep {
  Type = 'type',
  Settings = 'settings',
  Intervals = 'intervals',
}
interface IScaleSettingsEditorModalProps {
  question: FormComponent;
  orderTag: string;
  onClose(): void;
}
export const ScaleSettingsEditorModal: FC<IScaleSettingsEditorModalProps> = ({
  orderTag,
  question,
  onClose,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<ScaleConfigStep>(ScaleConfigStep.Type);
  const { type, setType, settings, setSettings, intervalMarks, getComponentType } =
    useScaleComponentState(question);
  const { upsertComponent, disableEdit, allowLimitedEditOnFinalized } = useFormEditor();

  const skipConditionAddon = question.addons?.find(
    (addon) => addon.addonType === FormAddonType.SkipCondition,
  );
  const parsedSkipConditionSettings = skipConditionAddon
    ? parseSkipConditionSettings(skipConditionAddon)
    : undefined;

  const saveSettings = async (formValues: ScaleSettingsFormFields) => {
    if (disableEdit && !allowLimitedEditOnFinalized) {
      return;
    }
    question.componentType = FormComponentType.ScaleNumber;
    question.settingsJson = JSON.stringify({
      ...getSettingsFromFormValues(formValues),
      scaleType: type,
    });
    question.valueJson = null;
    await upsertComponent(question);
    setStep(ScaleConfigStep.Type);
    onClose();
  };

  const handleSaveSettings = async (formValues: ScaleSettingsFormFields) => {
    if (disableEdit && !allowLimitedEditOnFinalized) {
      return;
    }

    if (type === ScaleType.Interval) {
      if (
        disableEdit &&
        allowLimitedEditOnFinalized &&
        settings.selectedCurrency !== formValues.selectedCurrency
      ) {
        await saveSettings(formValues);
      } else {
        setSettings(formValues);
        setStep(ScaleConfigStep.Intervals);
      }
    } else {
      await saveSettings(formValues);
    }
  };

  const saveConfigWithIntervals = async (values: Array<IntervalValue>) => {
    if (disableEdit) {
      return;
    }
    if (skipConditionAddon) {
      const addons =
        question.addons?.filter((addon) => addon.addonType !== FormAddonType.SkipCondition) ?? [];
      addons.push({
        addonType: FormAddonType.SkipCondition,
        settingsJson: JSON.stringify(getSkipConditionSettingsFromChoices(question.id, values)),
      });
      question.addons = addons;
    }
    question.componentType = getComponentType();
    question.settingsJson = JSON.stringify(getSettingsFromFormValues(settings));
    question.valueJson = stringifyIntervalMarks(values, question.componentType);
    await upsertComponent(question);
    setStep(ScaleConfigStep.Type);
    onClose();
  };

  const handleClickSubmitIntervals = (values: Array<IntervalValue>) => {
    if (type !== ScaleType.Interval) {
      throw new Error('Unexpected scale settings state');
    }
    saveConfigWithIntervals(values);
  };

  const handleClickBack = () => {
    if (step === ScaleConfigStep.Intervals) {
      setStep(ScaleConfigStep.Settings);
      return;
    }
    if (step === ScaleConfigStep.Settings) {
      setStep(ScaleConfigStep.Type);
      return;
    }
    return;
  };

  return (
    <FormModal
      isOpen
      title={t('form.settings.modal.title', {
        questionNo: orderTag,
        questionType: t('form.settings.scale.name'),
      })}
      maxWidth="1400px"
      onClose={onClose}
    >
      <Box mb={20}>
        <ButtonGroup
          value={step}
          size="md"
          onChange={(_: unknown, value: ScaleConfigStep) => setStep(value)}
        >
          <ButtonGroupItem data-value="type">{t('form.settings.scale.step.type')}</ButtonGroupItem>
          <ButtonGroupItem data-value="settings">
            {t('form.settings.scale.step.settings')}
          </ButtonGroupItem>
          <ButtonGroupItem data-value="intervals" disabled={type !== ScaleType.Interval}>
            {t('form.settings.scale.step.intervals')}
          </ButtonGroupItem>
        </ButtonGroup>
      </Box>

      {step === ScaleConfigStep.Type && (
        <ScaleSliderSelection
          activeType={type}
          onChange={setType}
          onCancel={onClose}
          disabled={disableEdit}
          onNext={() => setStep(ScaleConfigStep.Settings)}
        />
      )}
      {step === ScaleConfigStep.Settings && (
        <ScaleSettingsForm
          sliderType={type}
          formValues={settings}
          disabled={disableEdit}
          disableCurrencyChange={disableEdit && !allowLimitedEditOnFinalized}
          onNext={handleSaveSettings}
          onCancel={onClose}
          onBack={handleClickBack}
        />
      )}
      {step === ScaleConfigStep.Intervals && (
        <ScaleSettingsIntervals
          question={question}
          type={settings.inputType ?? ScaleIntervalInputType.Number}
          settings={settings}
          defaultValues={intervalMarks}
          disabled={disableEdit}
          skipConditionSettings={parsedSkipConditionSettings}
          onCancel={onClose}
          onBack={handleClickBack}
          onSubmit={handleClickSubmitIntervals}
        />
      )}
    </FormModal>
  );
};
