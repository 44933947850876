import chunk from 'lodash/chunk';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import { ErrorOption } from 'react-hook-form';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { FormComponentType } from 'typings/enums/FormComponentType';
import {
  AssessmentFormFieldValue,
  NameValue,
  TextValue,
  DateStringValue,
  DateRangePickerValue,
  MultipleChoiceValue,
  RangeSliderValue,
  NumberValue,
  BooleanValue,
  AssessmentFormValues,
} from 'typings/types/AssessmentFormValues';
import { DateSettings, ScaleSettings, ScaleType } from 'typings/types/FormComponentSettings';
import { formatISOToRoundUTCDate } from 'utils/dates';
import { isEmail } from 'utils/isEmail';

interface IAssessmentFormAnswerError extends ErrorOption {
  name: string;
}
export function isAssessmentQuestionValueValid(
  component: AssessmentFormComponent,
  formValues: AssessmentFormValues,
): Array<IAssessmentFormAnswerError> {
  switch (component.componentType) {
    case FormComponentType.Name: {
      const errors: Array<IAssessmentFormAnswerError> = [];
      for (const key in formValues) {
        const questionValues = formValues[key];

        (questionValues as Array<NameValue>).forEach((answerValues, index) => {
          if ((answerValues.firstName || answerValues.middleName) && !answerValues.lastName) {
            errors.push({
              name: `${component.id}.${index}.lastName`,
              type: 'required',
            });
          }

          if ((answerValues.lastName || answerValues.middleName) && !answerValues.firstName) {
            errors.push({
              name: `${component.id}.${index}.firstName`,
              type: 'required',
            });
          }
        });
      }
      return errors;
    }
    default:
      return [];
  }
}

export function isResultValid(component: AssessmentFormComponent): boolean {
  if (!component.assessmentComponent) {
    // component is section or question set
    return true;
  }

  const result = component.assessmentComponent.result ?? [];
  if (result.length === 0) {
    return !component.isRequired;
  }

  if (component.componentType === FormComponentType.Name) {
    return !chunk(result, 3).some((chunk) => {
      const isDirty = chunk.some((value) => value);
      const isIncomplete = !chunk[0] || !chunk[2];

      return isIncomplete && (isDirty || component.isRequired);
    });
  }

  if (component.componentType === FormComponentType.EmailAddress) {
    return !result.some((value) => !isEmail(value));
  }

  return true;
}

const getNonEmptyOrNull = <T>(array: Array<T>): Array<T> | null => (array.length ? array : null);
const getResult = <T>(array: Array<T>, mapFn: (item: T) => Array<string> | null) => {
  return getNonEmptyOrNull(flatten(compact(array.map(mapFn))));
};

export function getResultFromFieldValue(
  component: AssessmentFormComponent,
  formFieldValue: Array<AssessmentFormFieldValue>,
): Array<string | null> | null {
  switch (component.componentType) {
    case FormComponentType.Name:
      return getResult<NameValue>(formFieldValue as Array<NameValue>, (question) => {
        if (question.firstName && question.lastName) {
          return [question.firstName, question.middleName ?? '', question.lastName];
        }
        return null;
      });

    case FormComponentType.EmailAddress:
      return getResult<TextValue>(formFieldValue as Array<TextValue>, (question) => {
        const answer = question[FormComponentType.EmailAddress];
        return answer && isEmail(answer) ? [answer] : null;
      });

    case FormComponentType.Number: {
      return getResult<NumberValue>(formFieldValue as Array<NumberValue>, (question) => {
        const answer = question[FormComponentType.Number];
        return answer === undefined || answer === null ? null : [answer.toString()];
      });
    }

    case FormComponentType.Confirmation:
      return getResult<BooleanValue>(formFieldValue as Array<BooleanValue>, (question) => {
        const answer = question[FormComponentType.Confirmation];
        return answer === undefined || answer === null ? null : [answer.toString()];
      });

    case FormComponentType.Country:
      return getResult<TextValue>(formFieldValue as Array<TextValue>, (question) => {
        const answer = question[FormComponentType.Country];
        return answer ? [answer.toString()] : null;
      });

    case FormComponentType.Statement:
      return getResult<TextValue>(formFieldValue as Array<TextValue>, (question) => {
        const answer = question[FormComponentType.Statement];
        return answer ? [answer.toString()] : null;
      });

    case FormComponentType.SingleChoice:
      return getResult<TextValue>(formFieldValue as Array<TextValue>, (question) => {
        const answer = question[FormComponentType.SingleChoice];
        return answer ? [answer.toString()] : null;
      });

    case FormComponentType.FreeText:
      return getResult<TextValue>(formFieldValue as Array<TextValue>, (question) => {
        const answer = question[FormComponentType.FreeText];
        return answer ? [answer.toString()] : null;
      });

    case FormComponentType.Date:
      if ((component.parsedSettings as DateSettings).dateRangePicker) {
        return getResult<DateRangePickerValue>(
          formFieldValue as Array<DateRangePickerValue>,
          (question) => {
            const dateValue = question[FormComponentType.Date];
            const [from, to] = dateValue ?? [null, null];
            const formattedFrom = formatISOToRoundUTCDate(from);
            const formattedTo = formatISOToRoundUTCDate(to);
            if (formattedFrom && formattedTo) {
              return [formattedFrom, formattedTo];
            }
            return null;
          },
        );
      } else {
        return getResult<DateStringValue>(formFieldValue as Array<DateStringValue>, (question) => {
          const answer = question[FormComponentType.Date];
          const formatted = formatISOToRoundUTCDate(answer);
          return formatted ? [formatted] : null;
        });
      }

    case FormComponentType.MultipleChoice: {
      const valueArray = formFieldValue as Array<MultipleChoiceValue>;
      const selectedOptions = valueArray[0].MultipleChoice;

      return selectedOptions;
    }
    case FormComponentType.ScaleNumber:
      if ((component.parsedSettings as ScaleSettings)?.scaleType === ScaleType.Range) {
        return getResult<RangeSliderValue>(
          formFieldValue as Array<RangeSliderValue>,
          (question) => {
            if ((question.from || question.from === 0) && (question.to || question.to === 0)) {
              return [question.from.toString(), question.to.toString()];
            }
            return null;
          },
        );
      } else {
        return getResult<NumberValue>(formFieldValue as Array<NumberValue>, (question) => {
          const answer = question[FormComponentType.ScaleNumber];
          return answer === null ? null : [answer.toString()];
        });
      }

    case FormComponentType.ScaleText:
      return getResult<TextValue>(formFieldValue as Array<TextValue>, (question) => {
        const answer = question[FormComponentType.ScaleText];
        return answer ? [answer.toString()] : null;
      });

    default:
      return null;
  }
}

export function getFieldValues(
  component: AssessmentFormComponent,
): Record<string, Array<AssessmentFormFieldValue>> | undefined {
  if (
    component.componentType === FormComponentType.Section ||
    component.componentType === FormComponentType.QuestionSet
  ) {
    return undefined;
  }

  const result = component.assessmentComponent.result;

  switch (component.componentType) {
    case FormComponentType.Name: {
      if (!result || !result.length) {
        return {
          [component.id]: [
            {
              firstName: undefined,
              middleName: undefined,
              lastName: undefined,
            },
          ],
        };
      }
      const names: Array<NameValue> = result.reduce<Array<NameValue>>(
        (list, _current, index, array) => {
          return index % 3 === 0
            ? [
                ...list,
                {
                  firstName: array[index],
                  middleName: array.length >= index + 1 ? array[index + 1] : undefined,
                  lastName: array.length >= index + 2 ? array[index + 2] : undefined,
                },
              ]
            : list;
        },
        [] as Array<NameValue>,
      );
      return {
        [component.id]: names,
      };
    }
    case FormComponentType.Confirmation: {
      if (!result || !result.length) {
        return { [component.id]: [{ [FormComponentType.Confirmation]: undefined }] };
      }

      return {
        [component.id]: result.map((value) => ({
          [FormComponentType.Confirmation]: value === 'true',
        })),
      };
    }
    case FormComponentType.MultipleChoice: {
      if (!result || !result.length) {
        return { [component.id]: [{ [FormComponentType.MultipleChoice]: null }] };
      }
      return {
        [component.id]: [
          {
            [FormComponentType.MultipleChoice]: [...result],
          },
        ],
      };
    }
    case FormComponentType.Number: {
      if (!result || !result.length) {
        return { [component.id]: [{ [FormComponentType.Number]: null }] };
      }
      return {
        [component.id]: result.map((value) => ({ [FormComponentType.Number]: parseFloat(value) })),
      };
    }
    case FormComponentType.Date: {
      if ((component.parsedSettings as DateSettings).dateRangePicker) {
        if (!result || !result.length) {
          return { [component.id]: [{ [FormComponentType.Date]: [null, null] }] };
        }

        return {
          [component.id]: chunk(result, 2).map((chunk) => ({ [FormComponentType.Date]: chunk })),
        };
      } else {
        if (!result || !result.length) {
          return { [component.id]: [{ [FormComponentType.Date]: null }] };
        }
        return {
          [component.id]: result.map((value) => ({ [component.componentType]: value })),
        };
      }
    }
    case FormComponentType.ScaleText:
    case FormComponentType.EmailAddress:
    case FormComponentType.Country:
    case FormComponentType.Statement:
    case FormComponentType.SingleChoice:
    case FormComponentType.FreeText: {
      if (!result || !result.length) {
        return { [component.id]: [{ [component.componentType]: undefined }] };
      }
      return {
        [component.id]: result.map((value) => ({ [component.componentType]: value })),
      };
    }
    case FormComponentType.ScaleNumber:
      if ((component.parsedSettings as ScaleSettings)?.scaleType === ScaleType.Range) {
        if (!result || !result.length) {
          return {
            [component.id]: [
              {
                from: null,
                to: null,
              },
            ],
          };
        }

        const ranges: Array<RangeSliderValue> = result.reduce<Array<RangeSliderValue>>(
          (list, _current, index, array) => {
            return index % 2 === 0
              ? [
                  ...list,
                  {
                    from: parseFloat(array[index]),
                    to: array.length >= index + 1 ? parseFloat(array[index + 1]) : null,
                  },
                ]
              : list;
          },
          [] as Array<RangeSliderValue>,
        );
        return {
          [component.id]: ranges,
        };
      }

      if ((component.parsedSettings as ScaleSettings)?.scaleType !== ScaleType.Range) {
        if (!result || !result.length) {
          return { [component.id]: [{ [FormComponentType.ScaleNumber]: null }] };
        }
        return {
          [component.id]: result.map((value) => ({
            [FormComponentType.ScaleNumber]: parseFloat(value),
          })),
        };
      }

      return undefined;

    default:
      return undefined;
  }
}

export const defaultValue = (type: FormComponentType) => {
  switch (type) {
    case FormComponentType.Name:
      return { firstName: undefined, middleName: undefined, lastName: undefined };
    default:
      return { [type]: undefined };
  }
};
