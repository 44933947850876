import { Box, BoxProps } from '@chakra-ui/react';
import { Modal as AppkitModal } from 'appkit-react';
import { FC } from 'types';

interface IModalProps extends BoxProps {
  isVisible: boolean;
  backdropClosable?: boolean;
}

export const Modal: FC<IModalProps> = ({ isVisible, sx, ...props }) => (
  <Box
    as={AppkitModal}
    sx={{
      width: '100%',
      mx: 'auto',
      mt: '5%',
      position: 'relative',
      '.modal-content': {
        bg: (theme) => `${theme.colors.grey.lvl4} !important`,
        boxShadow: 'mainWhiteBig',
        position: 'relative',
      },
      ...sx,
    }}
    visible={isVisible}
    {...props}
  />
);
