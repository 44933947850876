import React, { FC, useState } from 'react';
import { Button } from 'appkit-react';
import { useSWRConfig } from 'swr';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TextEditor } from 'components/core/TextEditor/TextEditor';
import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { Modal } from 'components/modal/Modal/Modal';
import { ModalFooter } from 'components/modal/Modal/components/ModalFooter';
import { Comment } from 'models/Comment';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

interface ICommentModalProps {
  comment?: Comment | null;
  onCancel(): void;
}

export const CommentModal: FC<ICommentModalProps> = ({ onCancel, comment }) => {
  const [value, setValue] = useState('');
  const { roomId } = useParams<{ roomId?: string }>();
  const [isSaving, setIsSaving] = useState(false);
  const dependencyHeaders = useDependencyHeaders();
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();

  const onSave = async () => {
    setIsSaving(true);
    const method = comment ? HttpMethod.Put : HttpMethod.Post;
    const url = comment ? `rooms/${roomId}/comments/${comment?.id}` : `rooms/${roomId}/comments`;

    try {
      await apiClient(url, method, {
        headers: {
          'content-type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify({
          text: value,
          roomId: roomId,
        }),
      });
      await mutate(`comments-${roomId}@${dependencyHeaders['x-site-id']}`);
      PopAlertSuccess(t('room.sidebar.noticeBoard.modal.comment.successMessages.saved'));
      setIsSaving(false);
      onCancel();
    } catch (response: any) { // eslint-disable-line
      const message = response.message || t('room.errors.comments.save');

      PopAlertFail(message);
      setIsSaving(false);
    }
  };

  return (
    <Modal isVisible maxWidth="1410px">
      <ModalHeader onCancel={onCancel}>
        <ModalTitle>{t('room.sidebar.noticeBoard.modal.comment.title')}</ModalTitle>
      </ModalHeader>
      <ModalBody my={20}>
        <TextEditor initialHtmlContent={comment?.comment} onChange={setValue} />
      </ModalBody>
      <ModalFooter mb={20}>
        <StyledButton size="lg" kind="secondary" mr={12} disabled={isSaving} onClick={onCancel}>
          {t('action.cancel.label')}
        </StyledButton>
        <Button size="lg" disabled={isSaving} isLoading={isSaving} onClick={onSave}>
          {t('action.save.label')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
