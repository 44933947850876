import { Link } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { FC } from 'types';

interface IFormEditorViewMenuItemProps {
  isVisible: boolean;
  linkTo: string;
}
export const FormEditorViewMenuItem: FC<IFormEditorViewMenuItemProps> = ({
  isVisible,
  linkTo,
  children,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Box
      sx={{
        _hover: { bgColor: 'grey.lvl5' },
      }}
    >
      <Link to={linkTo}>{children}</Link>
    </Box>
  );
};
