import { useState } from 'react';
import { useBoolean } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';

import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { useDatx } from 'hooks/useDatx';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { Form } from 'models/Form';
import { PopAlertFail } from 'services/PopAlertFail';
import { UpdateFormDTO } from 'typings/types/dto/FormDTO';
import { FormAction } from 'typings/enums/FormAction';
import { ReviewAction } from 'typings/enums/ReviewAction';
import { IAsyncResult } from 'typings/interfaces/IAsyncResult';
import { getResourceTransform } from 'services/transformDto/getResourceTransform';
import { isApiClientError } from 'utils/isApiClientError';

export const useRoomForms = (roomId: string) => {
  const dependencyHeaders = useDependencyHeaders();
  const datx = useDatx();

  const transformData = getResourceTransform(Form);

  const siteId = dependencyHeaders['x-site-id'];
  const key = siteId ? `rooms/${roomId}/forms@${siteId}` : null;

  const swr = useSWR(
    roomId ? key : null,
    async () => {
      const response = await apiClient(`rooms/${roomId}/forms`, HttpMethod.Get, {
        headers: {
          ...dependencyHeaders,
        },
      });

      const forms: Array<Form> = datx.add(transformData(response), Form);

      return forms;
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  return {
    isInitialLoad: !swr.data && !swr.error,
    ...swr,
  };
};

export const useRoomForm = (roomId: string, formId: string) => {
  const { data, ...rest } = useRoomForms(roomId);
  const form = data?.find((form) => form.id === formId);

  return {
    form,
    ...rest,
  };
};

export const useUpdateRoomForm = (formId: string, roomId: string) => {
  const [isSaving, setIsSaving] = useBoolean(false);
  const saveMutation = useRoomFormUpsert(formId, roomId);
  const { t } = useTranslation();

  const save = async (roomFormDTO: UpdateFormDTO) => {
    setIsSaving.on();
    try {
      await saveMutation.mutate(roomFormDTO);
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('form.errors.generic'), response.errors?.name);
      } else {
        PopAlertFail(t('form.errors.generic'), response.message);
      }
    } finally {
      setIsSaving.off();
    }
  };
  return { save, isSaving };
};

export const useRoomFormUpsert = (formId: string | null, roomId: string) => {
  const depHeaders = useDependencyHeaders();
  const { mutate: globalMutate } = useSWRConfig();

  const postUrl = `rooms/${roomId}/${Form.type}`;
  const putUrl = `rooms/${roomId}/${Form.type}/${formId}`;

  const key = `rooms/${roomId}/forms@${depHeaders['x-site-id']}`;

  const mutate = async (data?: UpdateFormDTO, options?: Record<string, unknown>) => {
    await apiClient(formId ? putUrl : postUrl, formId ? HttpMethod.Put : HttpMethod.Post, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...depHeaders,
      },
      body: JSON.stringify(data),
    });

    globalMutate(key);
  };

  return {
    mutate,
  };
};

export const useDeleteForm = (formId: string, roomId: string) => {
  const dependencyHeaders = useDependencyHeaders();

  const deleteForm = async (): Promise<IAsyncResult> => {
    const result: IAsyncResult = {
      data: null,
      error: null,
    };
    const url = `rooms/${roomId}/${Form.type}/${formId}`;
    const method = HttpMethod.Delete;
    try {
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
      });
    } catch (response: any) { // eslint-disable-line
      if (isApiClientError(response)) {
        result.error = response;
      }
    }
    return result;
  };
  return { deleteForm };
};

const formApprovalActionMap = new Map([
  [FormAction.Finalize, 'finalise'],
  [FormAction.Submit, 'submit'],
  [FormAction.Reject, 'reject'],
  [FormAction.Withdraw, 'withdraw'],
  [FormAction.Edit, 'edit'],
  [FormAction.Approve, 'approve'],
]);
const reviewActionMap = new Map([
  [ReviewAction.Withdraw, 'withdraw'],
  [ReviewAction.Reject, 'reject'],
  [ReviewAction.Approve, 'approve'],
]);

export const useFormAction = (roomId: string, formId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const dependencyHeaders = useDependencyHeaders();
  const { t } = useTranslation();
  const { mutate: globalMutate } = useSWRConfig();

  const doFormReviewAction = async (action: FormAction, errorMessage?: string) => {
    const urlAction = formApprovalActionMap.get(action);
    if (!urlAction) {
      return;
    }
    const url = `rooms/${roomId}/forms/${formId}/approval/${urlAction}`;
    const method = HttpMethod.Post;

    try {
      setIsLoading(true);
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
      });
    } catch (response: any) { // eslint-disable-line
      const title = errorMessage ?? t(`form.errors.approval.action.${action}`);
      const message = response.message ?? t('form.errors.generic');
      PopAlertFail(title, message);
    } finally {
      setIsLoading(false);
      await globalMutate(`rooms/${roomId}/forms@${dependencyHeaders['x-site-id']}`);
      if (action === FormAction.Approve) {
        await globalMutate(`form-${formId}-approval@${dependencyHeaders['x-site-id']}`);
      }
    }
  };

  const doReviewAction = async (action: ReviewAction): Promise<IAsyncResult> => {
    const result: IAsyncResult = {
      data: null,
      error: null,
    };
    const urlAction = reviewActionMap.get(action);
    if (!urlAction) {
      return result;
    }
    const url = `rooms/${roomId}/forms/${formId}/approval/${urlAction}`;
    const method = HttpMethod.Post;
    try {
      setIsLoading(true);
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
      });
    } catch (response: any) { // eslint-disable-line
      result.error = response;
    } finally {
      setIsLoading(false);
      await globalMutate(`rooms/${roomId}/forms@${dependencyHeaders['x-site-id']}`);
      if (action === ReviewAction.Approve) {
        await globalMutate(`form-${formId}-approval@${dependencyHeaders['x-site-id']}`);
      }
    }
    return result;
  };
  return { doFormReviewAction, doReviewAction, isLoading };
};
