import { Spacer } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { BoxHeader } from 'components/core/BoxHeader/BoxHeader';
import { BackButton } from 'components/core/BoxHeader/components/BackButton';
import { MainLayout } from 'components/layouts/MainLayout/MainLayout';
import { AssessmentStatusIndicator } from 'components/assessment/AssessmentStatusIndicator/AssessmentStatusIndicator';
import { BoxTitle } from 'components/core/BoxHeader/components/BoxTitle';
import { AssessmentToolbar } from 'components/assessment/AssessmentToolbar/AssessmentToolbar';
import { AssessmentProvider } from 'context/AssessmentContext/AssessmentContextProvider';
import { AssessmentMain } from 'components/assessment/AssessmentMain/AssessmentMain';
import { useAssessment } from 'hooks/useAssessment';
import { useResources } from 'hooks/useResources';
import { User } from 'models/User';
import { ValidateAndSubmitButton } from 'components/assessment/ValidateAndSubmitButton/ValidateAndSubmitButton';
import { AssessmentApprovalActionButton } from 'components/assessment/AssessmentApprovalActionButton/AssessmentApprovalActionButton';
import { AssessmentReviewButton } from 'components/assessment/AssessmentReviewButton/AssessmentReviewButton';

export const AssessmentPage = () => {
  useResources(User);
  const { assessmentId: assessmentIdQuery } = useParams<{ assessmentId: string }>();
  const assessmentId = assessmentIdQuery.includes('?')
    ? assessmentIdQuery.slice(0, assessmentIdQuery.indexOf('?'))
    : assessmentIdQuery;
  const { assessment, error } = useAssessment(assessmentId);
  return (
    <MainLayout>
      <AssessmentProvider
        assessmentId={assessmentId}
        assessment={assessment}
        error={error}
        isLoaded={Boolean(assessment)}
      >
        <BoxHeader align="center" justify="space-between">
          <BackButton />
          <AssessmentStatusIndicator status={assessment?.displayStatus} />
          <Spacer />
          <BoxTitle>{assessment?.name}</BoxTitle>
          <Spacer />
          <AssessmentToolbar assessmentId={assessmentId} status={assessment?.displayStatus} />
          <AssessmentApprovalActionButton assessmentId={assessmentId} />
          <AssessmentReviewButton assessmentId={assessmentId} />
          <ValidateAndSubmitButton assessmentId={assessmentId} />
        </BoxHeader>
        <AssessmentMain isLoaded={Boolean(assessment)} />
      </AssessmentProvider>
    </MainLayout>
  );
};
