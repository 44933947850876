import { Box, Flex, Grid, VStack, Text, BoxProps } from '@chakra-ui/react';
import { FC } from 'types';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';

import { Avatar } from 'components/core/Avatar/Avatar';
import { useAnimation } from 'hooks/useAnimation';
import { useTenant } from 'hooks/useTenant';
import { useProfile } from 'hooks/useProfile';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

import { StyledDownloadLink, StyledNavLink } from './UserDropdown.elements';
import { config } from 'config';

interface IUserDropdownProps extends BoxProps {
  isVisible: boolean;
}

export const UserDropdown: FC<IUserDropdownProps> = ({ isVisible, sx, ...rest }) => {
  const { transitionEnd, isAnimationReady, shouldRender } = useAnimation(isVisible);
  const { userData, signOutRedirect } = useAuth();
  const { t } = useTranslation();
  const { activeTenant } = useTenant();
  const profile = useProfile();

  if (!shouldRender) {
    return null;
  }

  const handleSignOut = async () => {
    await signOutRedirect();
  };

  return (
    <Box
      sx={{
        boxShadow: 'mainWhite',
        width: '310px',
        borderRadius: '2px',
        bg: 'grey.lvl4',
        position: 'absolute',
        bottom: '0',
        right: '0',
        transform: `translateY(${isAnimationReady ? '107%' : '105%'})`,
        opacity: isAnimationReady ? '1' : '0',
        transition: 'all 0.2s ease',
        zIndex: '900',
        ...sx,
      }}
      onTransitionEnd={transitionEnd}
      onClick={(e) => e.stopPropagation()}
      {...rest}
    >
      <Flex p="20px" borderBottom="1px solid" borderColor="utils.grey.50" align="center">
        <Avatar mr="15px" name={userData?.profile.name} />
        <Box ml="10px">
          <Text sx={{ color: 'white', fontWeight: 'medium' }}>{userData?.profile.name}</Text>
          <Text sx={{ color: 'utils.grey.200' }}>{userData?.profile.preferredMail}</Text>
        </Box>
      </Flex>
      <VStack spacing="10px" py="10px" px="20px" align="start">
        <StyledNavLink to="/site-management">
          {t('header.userDropdown.links.notificationSettingsLabel')}
        </StyledNavLink>
        {activeTenant?.isSiteAdmin && (
          <StyledNavLink to="/site-admin">
            {t('header.userDropdown.links.siteAdminLabel')}
          </StyledNavLink>
        )}
        {profile.isAppAdmin && (
          <StyledNavLink to="/application-admin">
            {t('header.userDropdown.links.applicationAdminLabel')}
          </StyledNavLink>
        )}
        <StyledNavLink to="/imprint">{t('header.userDropdown.links.imprintLabel')}</StyledNavLink>
        <StyledNavLink to="/data-privacy">
          {t('header.userDropdown.links.dataPrivacyLabel')}
        </StyledNavLink>
        {/* Leave for future use */}
        {/* <StyledDownloadLink href="/licences.csv" download>
          {t('header.userDropdown.links.licenseDownloadCsv')}
        </StyledDownloadLink> */}
        <StyledDownloadLink href="/tas-licenses.txt" download>
          {t('header.userDropdown.links.licenseDownload')}
        </StyledDownloadLink>
      </VStack>
      <Grid p="20px" borderTop="1px solid" borderColor="utils.grey.50" placeItems="center">
        <StyledButton size="lg" onClick={handleSignOut}>
          {t('header.userDropdown.action.signOut.label')}
        </StyledButton>
        <Text mt="10px" sx={{ color: 'utils.grey.200' }} title={config.buildNumberFull}>
          {config.buildNumber}
        </Text>
      </Grid>
    </Box>
  );
};
