import { FC } from 'types';
import { Fragment } from 'react';
import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/modal/Modal/Modal';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { ModalFooter } from 'components/modal/Modal/components/ModalFooter';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

interface IAlertModalProps {
  isOpen: boolean;
  title: string;
  maxWidth: string;
  closeText?: string;
  showLoader?: boolean;
  disabled?: boolean;
  onClose(): void;
}

export const AlertModal: FC<IAlertModalProps> = ({
  title,
  maxWidth,
  closeText,
  showLoader = false,
  disabled = false,
  isOpen,
  onClose,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {isOpen && (
        <Modal isVisible={isOpen} maxWidth={maxWidth}>
          <ModalHeader onCancel={onClose}>
            <ModalTitle>{title}</ModalTitle>
          </ModalHeader>
          <ModalBody py={40} px={20}>
            {children}
          </ModalBody>
          <ModalFooter pb={20}>
            <HStack spacing={20}>
              <StyledButton size="lg" onClick={onClose} disabled={disabled} isLoading={showLoader}>
                {closeText || t('action.close.label')}
              </StyledButton>
            </HStack>
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
};
