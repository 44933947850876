import { chakra, CSSObject } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

const styles: CSSObject = {
  color: 'white',
  fontSize: '14px',
  fontWeight: 'medium',
  lineHeight: '20px',
  _hover: { color: 'brand.orange' },
  '&.active': { color: 'brand.orange' },
};

export const StyledNavLink = chakra(NavLink, {
  baseStyle: {
    ...styles,
  },
});

export const StyledDownloadLink = chakra('a', {
  baseStyle: {
    ...styles,
  },
});
