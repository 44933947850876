import { FC } from 'types';
import { Fragment } from 'react';

import { ToolbarIconButton } from 'components/core/ToolbarIconButton/ToolbarIconButton';
import { ToolbarActionIcon } from 'typings/enums/ToolbarActionIcon';
import { AssessmentStatus } from 'typings/enums/AssessmentStatus';
import { useAssessmentForm } from 'context/AssessmentContext/AssessmentContextProvider';
import { AssessmentStatusDisplay } from 'typings/types/AssessmentStatusDisplay';
import { useModal } from 'hooks/useModal';

import { ApproversModal } from '../ApproversModal/ApproversModal';

interface IApproversIconButtonProps {
  status?: AssessmentStatusDisplay;
}
export const ApproversIconButton: FC<IApproversIconButtonProps> = ({ status }) => {
  const { assessmentId } = useAssessmentForm();
  const { isOpen, onClose, onOpen } = useModal();

  const isDisabled = !assessmentId || status !== AssessmentStatus.InProgress;

  return (
    <Fragment>
      {isOpen ? (
        <ToolbarIconButton action={ToolbarActionIcon.ConfirmActive} onClick={onClose} />
      ) : (
        <ToolbarIconButton
          action={ToolbarActionIcon.Confirm}
          disabled={isDisabled}
          onClick={onOpen}
        />
      )}
      {isOpen && assessmentId && <ApproversModal assessmentId={assessmentId} onClose={onClose} />}
    </Fragment>
  );
};
