import { Checkbox } from 'appkit-react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { useSWRConfig } from 'swr';

import { PopAlertFail } from 'services/PopAlertFail';
import { apiClient } from 'utils/apiClient';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { AssessmentView } from 'models/AssessmentView';

interface IAssessmentTableViewFavoriteCellProps {
  assessmentViewItem: AssessmentView;
}

export const AssessmentTableViewFavoriteCell: FC<IAssessmentTableViewFavoriteCellProps> = ({
  assessmentViewItem,
}) => {
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();
  const dependencyHeaders = useDependencyHeaders();

  const onSetFavorite = async () => {
    const url = `assessments/${assessmentViewItem.id}/setFavorite`;
    const newValue = !assessmentViewItem.isFavorite;
    try {
      await apiClient(url, HttpMethod.Post, {
        headers: {
          'content-type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify({
          isFavorite: newValue,
        }),
      });
      await mutate(`assessments@${dependencyHeaders['x-site-id']}`);
      PopAlertFail(t('assessments.tableView.successMessages.favorite'));
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('assessments.tableView.errors.favorite.main'), response.errors?.name);
      } else {
        PopAlertFail(t('assessments.tableView.errors.favorite.unexpected'), response.message);
      }
    }
  };

  return (
    <Checkbox
      defaultChecked={assessmentViewItem.isFavorite}
      checkedIcon={<span style={{ color: 'inherit' }} className={'appkiticon icon-rating-fill'} />}
      uncheckedIcon={<span className={'appkiticon icon-rating-outline'} />}
      onChange={onSetFavorite}
      disabled
    />
  );
};
