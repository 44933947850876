import { Builder, BuilderProps } from 'react-awesome-query-builder';

// Typed props like this since awesome-query-builder does not support FC generic for this component

export const BuilderWrapper = (props: BuilderProps) => (
  <div className="query-builder-container">
    <div className="query-builder qb-lite">
      <Builder {...props} />
    </div>
  </div>
);
