import { Box } from '@chakra-ui/layout';
import { FC } from 'types';
import { HStack, Heading, BoxProps } from '@chakra-ui/react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { Checkbox } from 'appkit-react';
import { useTranslation } from 'react-i18next';

import { TextEditor } from 'components/core/TextEditor/TextEditor';

import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel';
import { FormFieldError } from '../FormFieldError/FormFieldError';

interface INotificationTextFormFieldProps extends BoxProps {
  control: Control<FieldValues>;
  nameTextEditor: string;
  nameCheckBox: string;
  defaultChecked?: boolean;
  labelIsTitle?: boolean;
}

export const NotificationTextFormField: FC<INotificationTextFormFieldProps> = ({
  nameTextEditor,
  nameCheckBox,
  control,
  defaultChecked = false,
  labelIsTitle,
  ...rest
}) => {
  const { t } = useTranslation();
  const {
    field: { value, ref, ...restField },
    fieldState: { error: textEditorError },
  } = useController({
    control,
    name: nameTextEditor,
  });

  const {
    field: { value: cbValue, ...restCheckboxField },
    fieldState: { error: checkboxError },
  } = useController({
    control,
    name: nameCheckBox,
    defaultValue: defaultChecked,
  });

  return (
    <Box {...rest}>
      <HStack justify="space-between" alignItems="baseline">
        {labelIsTitle ? (
          <Heading as="h3" size="lg" mb={20}>
            {t('notificationTextField.label')}
          </Heading>
        ) : (
          <FormFieldLabel>{t('notificationTextField.label')}</FormFieldLabel>
        )}
        <Checkbox checked={cbValue} {...restCheckboxField}>
          {t('notificationTextField.checkboxLabel')}
        </Checkbox>
        {checkboxError && <FormFieldError name={nameTextEditor} control={control} />}
      </HStack>

      <TextEditor textEditorRef={ref} {...restField} initialHtmlContent={value} />
      {textEditorError && <FormFieldError name={nameTextEditor} control={control} />}
    </Box>
  );
};
