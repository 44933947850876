import { FC } from 'types';
import { Fragment } from 'react';
import { Box, VStack, HStack, Flex, useBoolean } from '@chakra-ui/react';
import { Button } from 'appkit-react';
import { useTranslation } from 'react-i18next';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { updateModel } from '@datx/core';
import { observer } from 'mobx-react';

import { Campaign } from 'models/Campaign';
import { ModalFooter } from 'components/modal/Modal/components/ModalFooter';
import { ControlledInputField } from 'fields/InputField/InputField';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { InitiateCampaignTabsValueType } from 'components/campaign/CampaignModalTabs/CampaignModalTabs';
import { CampaignStatus } from 'typings/enums/CampaignStatus';
import { FormFieldLabel } from 'components/core/FormFieldLabel/FormFieldLabel';
import { CampaignInitiatorManagement } from 'components/campaign-users/CampaignInitiatorManagement/CampaignInitiatorManagement';
import {
  ICampaignFormData,
  ICampaignInitiatorsFormData,
} from 'typings/interfaces/ICampaignFormData';
import { isApiFormError } from 'utils/isApiFormError';
import { parseApiFormError } from 'utils/parseApiFormError';
import { CalendarPickerField } from 'fields/CalendarPickerField/CalendarPickerField';
import { SwitchField } from 'fields/SwitchField/SwitchField';

import { CampaignSettingsTooltip } from './component/CampaignSettingsTooltip/CampaignSettingsTooltip';

export interface ICampaignSettingsFormProps {
  campaign: Campaign;
  onDelete(): Promise<void>;
  onSave(): Promise<void>;
  setSelectedTab(tab: InitiateCampaignTabsValueType): void;
}

export const CampaignSettingsForm: FC<ICampaignSettingsFormProps> = observer(
  ({ campaign, onSave, onDelete, setSelectedTab }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useBoolean(false);
    const [isDeleting, setIsDeleting] = useBoolean(false);

    const { setValue, control, getValues, trigger, setError } = useForm<ICampaignFormData>({
      defaultValues: {
        name: campaign?.name || '',
        manageAssessmentCollaborator: campaign?.manageAssessmentCollaborator || false,
        editAfterSubmission: campaign?.editAfterSubmission || false,
        allowAnonymousReplies: campaign?.allowAnonymousReplies || false,
        alertForInitiator: campaign?.alertForInitiator || false,
        dueDate: campaign?.dueDate || null,
        initiatorReminderDate: campaign?.initiatorReminderDate || null,
        recipientReminderDate: campaign?.recipientReminderDate || null,
        plannedInitiationDate: campaign?.plannedInitiationDate || null,
        initiators: campaign?.initiators || [],
      },
    });

    const handleDatePickerChange = (name: keyof ICampaignFormData) => {
      return (value: Date | null) => {
        if (value) {
          setValue(name, value.toISOString());
        } else {
          setValue(name, value);
        }
      };
    };

    const updateModelCampaign = () => {
      updateModel(campaign, { ...getValues() });
    };

    const handleSave = async () => {
      const isValid = await trigger();
      if (!isValid) {
        return;
      }
      setIsLoading.on();
      try {
        updateModelCampaign();
        await onSave();

        PopAlertSuccess(t('campaign.save.success'));
      } catch (error: any) {
        // eslint-disable-line
        if (isApiFormError(error)) {
          const filedErrors = parseApiFormError<ICampaignFormData>(error);
          filedErrors.forEach((fieldError) =>
            setError(fieldError.name, { message: fieldError.message }),
          );
        } else {
          PopAlertFail(t('campaign.save.error'));
        }
      } finally {
        setIsLoading.off();
      }
    };

    const handleDelete = async () => {
      setIsDeleting.on();
      await onDelete();
      setIsDeleting.off();
    };

    const handleNext = () => {
      updateModelCampaign();
      setSelectedTab(InitiateCampaignTabsValueType.PermissionGroups);
    };

    return (
      <Fragment>
        <Flex direction="column">
          <Flex width="100%">
            <HStack align="flex-start" width="40%" mr="20px">
              <Box width="100%">
                <ControlledInputField
                  sx={{ mb: '20px' }}
                  control={control as unknown as Control<FieldValues>}
                  label={t('campaign.formFields.formName').toUpperCase()}
                  id="formName"
                  htmlFor="formName"
                  name="formName"
                  defaultValue={campaign?.formName}
                  disabled
                />
                <ControlledInputField
                  sx={{ mb: '20px' }}
                  control={control as unknown as Control<FieldValues>}
                  label={t('campaign.formFields.initiationCampaignName').toUpperCase()}
                  id="name"
                  htmlFor="name"
                  name="name"
                  placeholder="Please enter the initiation campaign name here."
                  defaultValue={campaign?.name}
                  rules={{ required: { value: true, message: t('validation.required') } }}
                />

                <Flex width="100%" direction="column">
                  <FormFieldLabel>{t('campaign.formTitles.settings').toUpperCase()}</FormFieldLabel>
                  <VStack spacing="10px" width="100%" align="flex-start">
                    <Box display="flex" justifyContent="space-between" w="100%">
                      <SwitchField
                        control={control as unknown as Control<FieldValues>}
                        name="manageAssessmentCollaborator"
                        label={t('campaign.formFields.manageAssessmentCollaborator')}
                        defaultChecked={campaign?.manageAssessmentCollaborator}
                        disabled={campaign.campaignStatus === CampaignStatus.Initiated}
                      />
                      <CampaignSettingsTooltip content="Some helper text regarding manage Assessment Collaborator" />
                    </Box>
                    <Box display="flex" justifyContent="space-between" w="100%">
                      <SwitchField
                        control={control as unknown as Control<FieldValues>}
                        name="editAfterSubmission"
                        label={t('campaign.formFields.editAfterSubmission')}
                        defaultChecked={campaign?.editAfterSubmission}
                      />
                      <CampaignSettingsTooltip content="Some helper text regarding edit after submission" />
                    </Box>
                    {/* For V2 */}
                    {/* <Box display="flex" justifyContent="space-between" w="100%">
                      <Switch
                        size="small"
                        label={t('campaign.formFields.allowAnonymousReplies')}
                        defaultChecked={campaign?.allowAnonymousReplies}
                        onChange={handleSwitchChange('allowAnonymousReplies')}
                        disabled={campaign.campaignStatus === CampaignStatus.Initiated}
                      />
                      <SwitchField
                        control={control as unknown as Control<FieldValues>}
                        name="allowAnonymousReplies"
                        label={t('campaign.formFields.allowAnonymousReplies')}
                        defaultChecked={campaign?.allowAnonymousReplies}
                        disabled={campaign.campaignStatus === CampaignStatus.Initiated}
                      />
                      <CampaignSettingsTooltip content="Some helper text regarding allow anonymous replies" />
                    </Box> */}
                    <Box display="flex" justifyContent="space-between" w="100%">
                      <SwitchField
                        control={control as unknown as Control<FieldValues>}
                        name="alertForInitiator"
                        label={t('campaign.formFields.initiatorAlert')}
                        defaultChecked={campaign?.alertForInitiator}
                      />
                      <CampaignSettingsTooltip content="Some helper text regarding active submission alert for initiator" />
                    </Box>
                  </VStack>
                </Flex>

                <VStack spacing="10px" mt="20px" width="100%" align="flex-start">
                  <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                    <CalendarPickerField
                      sx={{ width: '65%' }}
                      name="dueDate"
                      placeholder={t('campaign.formFields.dueDate')}
                      mode="showTime"
                      defaultValue={campaign?.dueDate ? new Date(campaign.dueDate) : null}
                      onChange={handleDatePickerChange('dueDate')}
                    />
                    <CampaignSettingsTooltip content="Some helper text regarding due date" />
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                    <CalendarPickerField
                      sx={{ width: '65%' }}
                      name="initiatorReminderDate"
                      placeholder={t('campaign.formFields.reminderInitiator')}
                      mode="showTime"
                      defaultValue={
                        campaign?.initiatorReminderDate
                          ? new Date(campaign.initiatorReminderDate)
                          : null
                      }
                      onChange={handleDatePickerChange('initiatorReminderDate')}
                    />
                    <CampaignSettingsTooltip content="Some helper text regarding reminder - initiator" />
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                    <CalendarPickerField
                      sx={{ width: '65%' }}
                      name="recipientReminderDate"
                      placeholder={t('campaign.formFields.reminderRecipients')}
                      mode="showTime"
                      defaultValue={
                        campaign?.recipientReminderDate
                          ? new Date(campaign.recipientReminderDate)
                          : null
                      }
                      onChange={handleDatePickerChange('recipientReminderDate')}
                    />
                    <CampaignSettingsTooltip content="Some helper text regarding automatic reminder to recipients" />
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                    <CalendarPickerField
                      sx={{ width: '65%' }}
                      name="plannedInitiationDate"
                      placeholder={t('campaign.formFields.initiationDate')}
                      mode="showTime"
                      defaultValue={
                        campaign?.plannedInitiationDate
                          ? new Date(campaign.plannedInitiationDate)
                          : null
                      }
                      onChange={handleDatePickerChange('plannedInitiationDate')}
                    />
                    <CampaignSettingsTooltip content="Some helper text regarding planned initiation date" />
                  </Box>
                </VStack>
              </Box>
            </HStack>

            <HStack align="flex-start" width="60%">
              <CampaignInitiatorManagement
                campaignId={campaign.id}
                control={control as unknown as Control<ICampaignInitiatorsFormData>}
              />
            </HStack>
          </Flex>
        </Flex>

        <ModalFooter w="100%" padding="0px" mt="50px">
          <Button
            size="lg"
            negative
            className="a-mr-20"
            disabled={isDeleting || isLoading}
            isLoading={isDeleting}
            onClick={handleDelete}
          >
            {t('action.delete.label').toUpperCase()}
          </Button>
          <Flex ml="auto" alignItems="center">
            <Button size="lg" kind="secondary" type="button" disabled className="a-mr-20">
              {t('action.back.label').toUpperCase()}
            </Button>
            <Button
              size="lg"
              type="button"
              className="a-mr-20"
              disabled={isLoading || isDeleting}
              isLoading={isLoading}
              onClick={handleSave}
            >
              {t('action.save.label').toUpperCase()}
            </Button>
            <Button size="lg" type="button" disabled={isLoading || isDeleting} onClick={handleNext}>
              {t('action.next.label').toUpperCase()}
            </Button>
          </Flex>
        </ModalFooter>
      </Fragment>
    );
  },
);
