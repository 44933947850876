import { FC } from 'types';
import { useState } from 'react';

import { FormModal } from 'components/modal/FormModal/FormModal';
import { useFormApproval } from 'hooks/useFormApproval';
import { UpsertFormApprovalDTO } from 'typings/types/dto/ApprovalDTO';
import { LoadingIndicator } from 'components/core/LoadingIndicator/LoadingIndicator';
import { usePopAlertFail } from 'hooks/usePopAlertFail';

import { FormApprovalSettingsForm } from '../FormApprovalSettingsForm/FormApprovalSettingsForm';

interface IFormApproversModalProps {
  roomId: string;
  formId: string;
  onClose(): void;
}
export const FormApproversModal: FC<IFormApproversModalProps> = ({ roomId, formId, onClose }) => {
  const { updateApproval, approval, isValidating, error } = useFormApproval(roomId, formId);
  const [isLoading, setIsLoading] = useState(false);
  usePopAlertFail(error, async () => setTimeout(() => onClose(), 500));

  const handleSubmit = async (upsertDTO: UpsertFormApprovalDTO) => {
    setIsLoading(true);
    await updateApproval(upsertDTO);
    setTimeout(() => onClose(), 500); // TODO: need to find better solution. This is to prevent Warning: Can't perform a React state update on an unmounted component.
    setIsLoading(false);
  };

  return (
    <FormModal isOpen maxWidth="1440px" onClose={onClose}>
      {isValidating && <LoadingIndicator />}
      {!isValidating && approval && (
        <FormApprovalSettingsForm
          approval={approval}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onCancel={onClose}
        />
      )}
    </FormModal>
  );
};
