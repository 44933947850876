import { useMemo } from 'react';
import { useDatx } from 'hooks/useDatx';

import { Room } from 'models/Room';
import { Form } from 'models/Form';
import { RoomFolderUser } from 'models/RoomFolderUser';
import { IFormUser } from 'typings/interfaces/IFormUser';
import { transformUserToFormUser } from 'services/transformUserToFormUser';

export const useAvailableFormUsers = (form: Form) => {
  const datx = useDatx();

  const roomUsers = useMemo(() => {
    const formRoom = datx.findOne(Room, form.room.id);
    const formFolder = form.parentFolder;

    if (!formFolder && formRoom) {
      return formRoom.roomUsers.map((user) => transformUserToFormUser(user));
    }

    if (formFolder && formRoom) {
      return formFolder.users.reduce((acc: Array<IFormUser>, user: RoomFolderUser) => {
        const roomUser = formRoom.roomUsers.find((roomUser1) => user.userId === roomUser1.userId);
        if (roomUser) {
          acc.push(transformUserToFormUser(roomUser));
        }
        return acc;
      }, []);
    }

    return [];
  }, [datx, form.parentFolder, form.room.id]);

  return {
    roomUsers,
  };
};

export const useFormManagementData = (form: Form) => {
  const { roomUsers } = useAvailableFormUsers(form);

  return {
    roomUsers,
    addedUsers: form.users,
  };
};
