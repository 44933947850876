import { FC } from 'types';

import { useFormsComponents } from 'hooks/useFormsComponents';
import { AbsoluteLoader } from 'components/core/LoadingIndicator/AbsoluteLoader';
import { usePopAlertFail } from 'hooks/usePopAlertFail';

import { InitiateCampaignModal } from '../InitiateCampaignModal/InitiateCampaignModal';

interface IInitiateCampaignModalWrapperProps {
  formId: string;
  roomId: string;
  onCancel(): void;
}

export const InitiateCampaignModalWrapper: FC<IInitiateCampaignModalWrapperProps> = ({
  formId,
  roomId,
  onCancel,
}) => {
  const { error, isInitialLoad } = useFormsComponents(roomId, formId);

  usePopAlertFail(error, async () => setTimeout(() => onCancel(), 500));

  if (isInitialLoad) {
    return <AbsoluteLoader />;
  }

  return <InitiateCampaignModal formId={formId} roomId={roomId} onCancel={onCancel} />;
};
