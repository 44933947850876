import useSWR from 'swr';

import { AssessmentApproval } from 'models/AssessmentApproval';
import { HttpMethod } from 'typings/enums/HttpMethod';
import {
  FormApprovalDTO as AssessmentApprovalDTO,
  UpsertAssessmentApprovalDTO,
} from 'typings/types/dto/ApprovalDTO';
import { apiClient } from 'utils/apiClient';
import { useDatx } from 'hooks/useDatx';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { IErrorResponse } from 'typings/interfaces/IErrorResponse';

export const useAssessmentApproval = (assessmentId: string) => {
  const dependencyHeaders = useDependencyHeaders();
  const datx = useDatx();

  const key = `assessment-${assessmentId}-approval@${dependencyHeaders['x-site-id']}`;
  const url = `assessments/${assessmentId}/approval`;

  const swr = useSWR(
    key,
    async () => {
      const response = await apiClient(url, HttpMethod.Get, {
        headers: {
          ...dependencyHeaders,
        },
      });
      return datx.add(response as AssessmentApprovalDTO, AssessmentApproval); // tell ts that response is an object and not an array
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  const updateApproval = async (
    dto: UpsertAssessmentApprovalDTO,
    onError?: (response: IErrorResponse) => void,
  ) => {
    const method = HttpMethod.Post;

    try {
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify(dto),
      });
    } catch (response: any) { // eslint-disable-line
      onError?.(response);
    } finally {
      await swr.mutate();
    }
  };

  return {
    updateApproval,
    isInitialLoad: !swr.data && !swr.error,
    approval: swr.data,
    ...swr,
  };
};
