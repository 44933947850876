import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FullScreenLoading } from 'components/core/FullScreenLoading/FullScreenLoading';
import { useTenant } from 'hooks/useTenant';
import { getIdToken } from 'utils/getIdToken';
import { parseApiClientError } from 'utils/parseApiClientError';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { triggerLinkDownload } from 'utils/triggerLinkDownload';
import { config } from 'config';

const API_URL = `${config.apiUrl}/api/`;

export const DownloadPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { 0: url } = useParams<{ 0?: string }>(); // TODO: how to name param that has parts of url, including '/'?
  const { search } = useLocation();
  // https://localhost:3000/download/assessments/export/28d1684f-f801-4de2-373a-08dad157d9f9?siteId=7a102e59-4f7b-4fb8-965d-e493b3ca5515&pdf=true

  const siteId = useTenant().activeTenant?.siteId;

  useEffect(() => {
    if (!siteId) {
      return;
    }
    const searchParams = new URLSearchParams(search);
    searchParams.delete('siteId');

    // @ts-expect-error size exists on searchParams
    const queryParams = searchParams.size === 0 ? '' : `?${searchParams.toString()}`;

    if (!url) {
      history.push('/assessments');
    } else {
      fetch(`${API_URL}${url}${queryParams}`, {
        headers: {
          ['x-site-id']: siteId ?? '',
          ['x-id-token']: getIdToken() ?? '',
        },
        method: HttpMethod.Get,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error();
          }

          return response.json();
        })
        .then((json) => {
          // some endpoints, like the form files have different response
          // some endpoints return the url directly
          const url = json.data?.sasToken ?? json.data;
          triggerLinkDownload(url);
        })
        .catch((response) => {
          parseApiClientError(response, t);
        })
        .finally(() => {
          history.push('/assessments');
        });
    }
  }, [history, t, siteId, url]);

  return <FullScreenLoading>{t('assessments.tableView.download.label')}</FullScreenLoading>;
};
