import { useDisclosure } from '@chakra-ui/react';
import { FC } from 'types';
import { Fragment } from 'react';
import { Tooltip } from 'appkit-react';
import { useTranslation } from 'react-i18next';

import { ToolbarIconButton } from 'components/core/ToolbarIconButton/ToolbarIconButton';
import { ToolbarActionIcon } from 'typings/enums/ToolbarActionIcon';
import { AssessmentStatus } from 'typings/enums/AssessmentStatus';
import { useAssessment } from 'hooks/useAssessment';

import { CollaboratorsModal } from '../CollaboratorsModal/CollaboratorsModal';

interface ICollaboratorsIconButtonProps {
  assessmentId: string;
}
export const CollaboratorsIconButton: FC<ICollaboratorsIconButtonProps> = ({ assessmentId }) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { assessment } = useAssessment(assessmentId);

  const canManage = assessment?.campaign.manageAssessmentCollaborator ?? false;

  if (!canManage || assessment?.status === AssessmentStatus.Overdue) {
    return (
      <Tooltip content={t('assessment.collaborators.cannotManageCollaborators')}>
        <ToolbarIconButton action={ToolbarActionIcon.User} disabled />
      </Tooltip>
    );
  }

  return (
    <Fragment>
      {isOpen ? (
        <ToolbarIconButton action={ToolbarActionIcon.UserActive} onClick={onClose} />
      ) : (
        <ToolbarIconButton action={ToolbarActionIcon.User} onClick={onOpen} />
      )}
      {isOpen && <CollaboratorsModal assessmentId={assessmentId} onClose={onClose} />}
    </Fragment>
  );
};
