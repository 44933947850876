import { FC } from 'types';
import { ButtonGroupItem } from 'appkit-react';
import { useTranslation } from 'react-i18next';
import { Control, FieldValues } from 'react-hook-form';

import { CampaignUserRole } from 'typings/enums/CampaignUserRole';
import { IButtonGroupProps } from 'typings/appkit-react/IButtonGroupProps';
import { ICampaignInitiatorsFormData } from 'typings/interfaces/ICampaignFormData';
import { ButtonGroupField } from 'fields/ButtonGroupField/ButtonGroupField';

interface ICampaignUserRoleGroupFieldProps extends IButtonGroupProps {
  name: string;
  control: Control<ICampaignInitiatorsFormData>;
  isLastInitiator?: boolean;
}
export const CampaignUserRoleGroupField: FC<ICampaignUserRoleGroupFieldProps> = ({
  control,
  name,
  isLastInitiator,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <ButtonGroupField
      control={control as unknown as Control<FieldValues>}
      name={name}
      size="md"
      {...rest}
    >
      <ButtonGroupItem data-value={CampaignUserRole.Initiator} disabled={Boolean(isLastInitiator)}>
        {t('campaign.initiators.roles.initiator').toUpperCase()}
      </ButtonGroupItem>
      <ButtonGroupItem data-value={CampaignUserRole.Viewer} disabled={Boolean(isLastInitiator)}>
        {t('campaign.initiators.roles.viewer').toUpperCase()}
      </ButtonGroupItem>
    </ButtonGroupField>
  );
};
