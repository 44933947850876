import { ConditionOperator } from 'typings/enums/ConditionOperator';
import { ConditionType } from 'typings/enums/ConditionType';
import { IFormComponentAddonDto } from 'typings/interfaces/dto/IFormComponentAddonDto';
import { SkipCondition, SkipConditionAddonSettings } from 'typings/types/FormComponentSettings';

export function parseSkipConditionSettings(addon: IFormComponentAddonDto): Record<string, string> {
  const settingsJson = addon.settingsJson;

  if (!settingsJson) {
    return {};
  }
  const parsed = JSON.parse(settingsJson) as SkipConditionAddonSettings;

  return parsed.skipConditions.reduce((acc: Record<string, string>, obj: SkipCondition) => {
    acc[obj.stringValue] = obj.skipToQuestionId;
    return acc;
  }, {});
}

const getSkipConditionReducer =
  (componentId: string) =>
  (acc: Array<SkipCondition>, item: IValueWithSkipCondition): Array<SkipCondition> => {
    if (item.skipTo) {
      acc.push({
        skipToQuestionId: item.skipTo,
        componentId: componentId,
        conditionType: ConditionType.LogicCondition,
        operator: ConditionOperator.Is,
        stringValue: item.value.toString(),
      });
    }

    return acc;
  };
interface IValueWithSkipCondition {
  skipTo?: string | null;
  value: string | number;
}
export function getSkipConditionSettingsFromChoices(
  componentId: string,
  items: Array<IValueWithSkipCondition>,
): SkipConditionAddonSettings {
  const skipConditions: Array<SkipCondition> = items.reduce<Array<SkipCondition>>(
    getSkipConditionReducer(componentId),
    [],
  );

  return {
    skipConditions,
  };
}

export function getSkipConditionSettings(
  componentId: string,
  skipToQuestionId: string,
  stringValue: string,
): SkipConditionAddonSettings {
  return {
    skipConditions: [
      {
        skipToQuestionId,
        componentId,
        conditionType: ConditionType.LogicCondition,
        operator: ConditionOperator.Is,
        stringValue,
      },
    ],
  };
}
