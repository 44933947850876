import { FC } from 'types';
import { observer } from 'mobx-react';

import { Checkbox } from 'appkit-react';
import { CampaignPermissionGroup } from 'models/CampaignPermissionGroup';
import { TableCellRow } from 'typings/types/TableCellRow';
import { Campaign } from 'models/Campaign';

interface IPermissionGroupsFormTableCellProps {
  rowData: TableCellRow;
  campaign: Campaign;
  permissionGroup: CampaignPermissionGroup;
  handleSelect(componentId: string, permissionGroupId: string): void;
  handleSelectAll(permissionGroupId: string): void;
}

export const PermissionGroupsFormTableCell: FC<IPermissionGroupsFormTableCellProps> = observer(
  ({ rowData, campaign, permissionGroup, handleSelect, handleSelectAll }) => {
    const isSelectAll = rowData.index === 0;

    const isSelectAllChecked = permissionGroup.componentIds.length === campaign.components?.length;
    const isRegularChecked = Boolean(
      permissionGroup.componentIds.find((component) => component === rowData.original.id),
    );

    const isChecked = isSelectAll ? isSelectAllChecked : isRegularChecked;

    const action = () =>
      isSelectAll
        ? handleSelectAll(permissionGroup.id)
        : handleSelect(rowData.original.id, permissionGroup.id);

    return <Checkbox checked={isChecked} onChange={action} />;
  },
);
