import { Box, useBoolean } from '@chakra-ui/react';
import { FC, SetState } from 'types';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { Table } from 'appkit-react';

import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { AssessmentView } from 'models/AssessmentView';
import { dateSortMethod } from 'utils/dateSortMethod';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { Checkbox } from 'appkit-react';

import { FormCampaignAssessmentsTableCellNameWrapper } from './components/FormCampaignAssessmentsTableCellNameWrapper/FormCampaignAssessmentsTableCellNameWrapper';
import { AssessmentsExportModal } from 'components/assessments/AssessmentsExportModal/AssessmentsExportModal';
import { TableCellRow } from 'typings/types/TableCellRow';

interface IFormCampaignAssessmentsTableProps {
  campaignId: string;
  isLoading: boolean;
  assessments?: Array<AssessmentView>;
}

const assessmentsColumns = (
  t: TFunction,
  selectedIds: string[],
  setSelectedIds: SetState<string[]>,
  assessments: Array<AssessmentView>,
) => [
  {
    Cell: (row: TableCellRow) => {
      const id = row.original.id;
      const isChecked = selectedIds.includes(id);
      return (
        <Checkbox
          defaultChecked={isChecked}
          onChange={() => {
            if (isChecked) {
              setSelectedIds((prev) => prev.filter((x) => x !== id));
            } else {
              setSelectedIds((prev) => [...prev, id]);
            }
          }}
        />
      );
    },
    minWidth: 30,
    Header: () => {
      const isChecked = selectedIds.length === assessments.length;
      return (
        <Checkbox
          defaultChecked={isChecked}
          onChange={() => {
            if (isChecked) {
              setSelectedIds([]);
            } else {
              setSelectedIds(assessments.map((x) => x.id));
            }
          }}
        />
      );
    },
  },
  {
    Header: t('assessments.tableView.tableColumns.name'),
    accessor: 'name',
    Cell: FormCampaignAssessmentsTableCellNameWrapper,
    minWidth: 300,
  },
  {
    Header: t('assessments.tableView.tableColumns.recipient'),
    accessor: 'recipientCount',
    minWidth: 200,
  },
  {
    Header: t('assessments.tableView.tableColumns.permissionGroup'),
    accessor: 'permissionGroupCount',
    minWidth: 200,
  },
  {
    Header: t('assessments.tableView.tableColumns.lastEdit'),
    accessor: 'formattedLastEdit',
    minWidth: 200,
    sortMethod: dateSortMethod,
  },
  {
    Header: t('assessments.tableView.tableColumns.dueDate'),
    accessor: 'formattedDueDate',
    minWidth: 200,
    sortMethod: dateSortMethod,
  },
  {
    Header: t('assessments.tableView.tableColumns.status'),
    accessor: 'displayStatus',
    minWidth: 100,
  },
];

export const FormCampaignAssessmentsTable: FC<IFormCampaignAssessmentsTableProps> = ({
  assessments,
  campaignId,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [isExportModalVisible, { on, off }] = useBoolean(false);
  const [selectedIds, setSelectedIds] = useState<Array<string>>(
    (assessments ?? []).map((x) => x.id),
  );

  useEffect(() => {
    if (!isLoading) {
      setSelectedIds((assessments ?? []).map((x) => x.id));
    }
  }, [isLoading]);

  return (
    <Fragment>
      <TableWrapper isLoading={isLoading}>
        <Table
          columns={assessmentsColumns(t, selectedIds, setSelectedIds, assessments ?? [])}
          data={assessments}
          showPageSizeOptions={false}
          defaultPageSize={25}
          defaultSorted={[{ id: 'name', asc: true }]}
        />
        <Box position="absolute" right="0" bottom="0">
          <StyledButton size="md" kind="secondary" onClick={on} disabled={selectedIds.length === 0}>
            {t('export.action.assessments')}
          </StyledButton>
          {isExportModalVisible && (
            <AssessmentsExportModal
              campaignId={campaignId}
              selectedIds={selectedIds}
              onClose={off}
            />
          )}
        </Box>
      </TableWrapper>
    </Fragment>
  );
};
