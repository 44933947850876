import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { FormComponent } from 'models/FormComponent';
import { Addon } from 'components/form-editor/Question/components/Addon/Addon';
import { useModal } from 'hooks/useModal';

import { ScaleSettingsEditorModal } from '../ScaleSettingsEditorModal/ScaleSettingsEditorModal';

interface IScaleSettingsEditorProps {
  question: FormComponent;
  orderTag: string;
}

export const ScaleSettingsEditor: FC<IScaleSettingsEditorProps> = observer(
  ({ orderTag, question }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useModal();

    return (
      <Fragment>
        <Addon
          isInvalid={!question.hasValidSettings}
          name={t('form.settings.scale.name')}
          disabled={!question.text}
          onClick={onOpen}
        />
        {isOpen && (
          <ScaleSettingsEditorModal question={question} orderTag={orderTag} onClose={onClose} />
        )}
      </Fragment>
    );
  },
);
