import { FormComponent } from 'models/FormComponent';
import { FormComponentType } from 'typings/enums/FormComponentType';
import {
  IntervalNumberValue,
  IntervalTextValue,
  IntervalValue,
  NumbersComponentType,
  ScaleIntervalInputType,
  ScaleSettings,
  ScaleSettingsFormFields,
  ScaleType,
} from 'typings/types/FormComponentSettings';

export function getSettingsFromFormValues(formValues: ScaleSettingsFormFields): ScaleSettings {
  if (
    formValues.scaleType === ScaleType.Interval &&
    formValues.inputType === ScaleIntervalInputType.Text
  ) {
    return {
      title: formValues.title,
    };
  }

  return {
    scaleType: formValues.scaleType,
    title: formValues.title,
    numberSettings: {
      decimalDigits: 2,
      numberType: formValues.numberType,
      selectedCurrency:
        formValues.numberType === NumbersComponentType.Currency
          ? formValues.selectedCurrency
          : null,
    },
    minimumScale: formValues.minimumScale,
    maximumScale: formValues.maximumScale,
  };
}

function fillWithDefaultSettings(
  componentType: FormComponentType,
  settings?: ScaleSettings,
): ScaleSettings {
  if (componentType === FormComponentType.ScaleText) {
    return {
      scaleType: settings?.scaleType ?? ScaleType.Interval,
      title: settings?.title ?? '',
    };
  }

  if (componentType === FormComponentType.ScaleNumber) {
    return {
      scaleType: settings?.scaleType ?? ScaleType.Default,
      title: settings?.title ?? '',
      numberSettings: {
        decimalDigits: settings?.numberSettings?.decimalDigits ?? 2,
        numberType: settings?.numberSettings?.numberType ?? NumbersComponentType.Number,
        selectedCurrency: settings?.numberSettings?.selectedCurrency ?? null,
      },
      minimumScale: settings?.minimumScale ?? 0,
      maximumScale: settings?.maximumScale ?? 0,
    };
  }

  throw new Error('Invalid component type');
}

function parseScaleSettings(question: FormComponent): ScaleSettings {
  if (
    question.componentType !== FormComponentType.ScaleText &&
    question.componentType !== FormComponentType.ScaleNumber
  ) {
    throw new Error("Can't parse non-scale component settings");
  }

  try {
    if (!question.settingsJson) {
      throw new Error("Can't parse null component settings");
    }
    const parsedSettings = JSON.parse(question.settingsJson) as ScaleSettings;

    return fillWithDefaultSettings(question.componentType, parsedSettings);
  } catch (error: any) { // eslint-disable-line
    return fillWithDefaultSettings(question.componentType);
  }
}

export function parseFormFieldValues(question: FormComponent): ScaleSettingsFormFields {
  if (
    question.componentType !== FormComponentType.ScaleText &&
    question.componentType !== FormComponentType.ScaleNumber
  ) {
    throw new Error("Can't parse non-scale component settings");
  }
  const parsedSettings = parseScaleSettings(question);

  let intervalInputType;
  if (
    question.componentType === FormComponentType.ScaleText &&
    parsedSettings.scaleType === ScaleType.Interval
  ) {
    intervalInputType = ScaleIntervalInputType.Text;
  } else if (parsedSettings.scaleType === ScaleType.Interval) {
    intervalInputType = ScaleIntervalInputType.Number;
  }

  return {
    scaleType: parsedSettings.scaleType ?? ScaleType.Default,
    title: parsedSettings.title ?? '',
    maximumScale: parsedSettings.maximumScale ?? 0,
    minimumScale: parsedSettings.minimumScale ?? 0,
    numberType: parsedSettings.numberSettings?.numberType ?? NumbersComponentType.Number,
    selectedCurrency: parsedSettings.numberSettings?.selectedCurrency ?? null,
    inputType: intervalInputType,
  };
}

export function parseScaleNumberQuestionValues(
  stringifiedJSON: string | null,
): Array<IntervalNumberValue> {
  if (!stringifiedJSON) {
    return [];
  }
  try {
    const result = JSON.parse(stringifiedJSON) as Array<{ index: number; value: number }>;

    return result.map((intervalValue) => ({
      index: intervalValue.index,
      label: intervalValue.value,
    }));
  } catch (error: any) { // eslint-disable-line
    return [];
  }
}

export function parseScaleTextQuestionValues(
  stringifiedJSON: string | null,
): Array<IntervalTextValue> {
  if (!stringifiedJSON) {
    return [];
  }
  try {
    const result = JSON.parse(stringifiedJSON) as Array<{ index: number; value: string }>;

    return result.map((intervalValue) => ({
      index: intervalValue.index,
      label: intervalValue.value,
    }));
  } catch (error: any) { // eslint-disable-line
    return [];
  }
}

export function stringifyIntervalMarks(
  items: Array<IntervalValue>,
  componentType: FormComponentType,
) {
  if (componentType === FormComponentType.ScaleNumber) {
    const mapped = items.map((item) => ({
      index: item.index,
      value: typeof item.value !== 'number' ? parseFloat(item.value) : item.value,
    }));
    return JSON.stringify(mapped);
  }
  if (componentType === FormComponentType.ScaleText) {
    const mapped = items.map((item) => ({
      index: item.index,
      value: typeof item.value !== 'string' ? item.value.toString() : item.value,
    }));
    return JSON.stringify(mapped);
  }
  throw new Error("Can't stringify non-scale component settings");
}
