import { Circle, Center } from '@chakra-ui/react';
import React, { FC, MouseEvent } from 'react';

interface IRoomCardFavoriteProps {
  isFavorite?: boolean;
  isLoading?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => Promise<void>;
}

export const RoomCardFavorite: FC<IRoomCardFavoriteProps> = ({
  isFavorite = false,
  isLoading = true,
  onClick,
}) => {
  const favoriteIcon = isFavorite ? 'icon-rating-fill' : 'icon-rating-outline';
  const icon = isLoading ? 'a-loading a-primary' : favoriteIcon;
  return (
    <Center onClick={onClick}>
      <Circle
        cursor="pointer"
        className={`appkit-icon ${icon} a-text-primary a-font-32`}
        size="42px"
        sx={{
          mt: '30px',
          bg: 'white',
        }}
      />
    </Center>
  );
};
