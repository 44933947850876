import { useCallback, useState } from 'react';
import { FC } from 'types'
import { Box, BoxProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { FileObj } from 'typings/types/FileObj';
import { StyledAppKitUpload } from 'components/core/StyledAppKit/StyledAppKit';
import { FileObjStatus } from 'typings/enums/FileObjStatus';
import { PopAlertFail } from 'services/PopAlertFail';
import { IAttachmentDownloadInfo } from 'typings/interfaces/dto/IAttachmentDownloadInfo';
import { IAttachmentFile } from 'typings/interfaces/dto/IAttachmentFile';

import { AttachmentDownload } from '../AttachmentDownload/AttachmentDownload';

interface IUploadProps extends Omit<BoxProps, 'onChange' | 'value'> {
  getUploadUrlEndpoint: string;
  value: Array<IAttachmentFile>;
  disabled?: boolean;
  getDownloadUrl(fileId: string): string;
  onChange(files: Array<IAttachmentFile>): void;
  onStartUploadFile?(): void;
  onEndUploadFile?(): void;
  onUploadFileError?(): void;
}

export const Upload: FC<IUploadProps> = ({
  getUploadUrlEndpoint,
  value = [],
  disabled,
  onChange,
  onStartUploadFile,
  onEndUploadFile,
  onUploadFileError,
  getDownloadUrl,
  ...rest
}) => {
  const { t } = useTranslation();
  const dependencyHeaders = useDependencyHeaders();
  const [isLoading, setIsLoading] = useState(false);

  const handleAddFile = useCallback(
    async (file: FileObj) => {
      if (file.size > 26214400 /*25MiB*/) {
        PopAlertFail(t('upload.sizeError'));
        return;
      }

      if (file.status !== FileObjStatus.Ready) {
        return;
      }

      const fileName = file.originFile.name;
      setIsLoading(true);
      onStartUploadFile?.();

      try {
        const response: IAttachmentDownloadInfo = await apiClient(
          getUploadUrlEndpoint,
          HttpMethod.Post,
          {
            headers: {
              'Content-Type': 'application/json',
              ...dependencyHeaders,
            },
            body: JSON.stringify({ fileName }),
          },
        );

        await fetch(response.sasToken, {
          method: HttpMethod.Put,
          headers: {
            'x-ms-blob-type': 'BlockBlob',
          },
          body: file.originFile,
        });

        onChange([...value, response.file]);
        onEndUploadFile?.();
      } catch (e) {
        PopAlertFail(t('upload.error'));
        onUploadFileError?.();
      } finally {
        setIsLoading(false);
      }
    },
    [
      dependencyHeaders,
      getUploadUrlEndpoint,
      onChange,
      onEndUploadFile,
      onStartUploadFile,
      onUploadFileError,
      t,
      value,
    ],
  );

  const handleRemove = useCallback(
    (fileId: string) => {
      if (disabled) {
        return;
      }
      onChange(value.filter((file) => file.fileId !== fileId));
    },
    [disabled, onChange, value],
  );

  return isLoading ? (
    <Box className="a-loading a-default" />
  ) : (
    <Box position="relative">
      <StyledAppKitUpload
        multiple={false}
        action={''} // this is empty string because it's a required prop and we not using it and uploading files manually
        autoUpload={false}
        showFileList={false}
        onChange={handleAddFile}
        onError={() => PopAlertFail(t('upload.error'))}
        extraPropToFlowjs={{
          uploadMethod: HttpMethod.Put,
          testChunks: false,
          headers: { 'x-ms-blob-type': 'BlockBlob' },
        }}
        disabled={disabled}
        {...rest}
      >
        <Box className="upload-drop-small a-apply-theme-background-gray-level-1">
          <Box as="span" className="upload-drop-span">
            {t('upload.dragHelperText')}
          </Box>
          &nbsp;
          {/* data-mode is not documented except in one example, other value is 'folder' 
              without it, clicking on this text won't open file selection like it did in previous versions
          */}
          <Box as="span" className="upload-browse-span" data-mode="files">
            {t('upload.chooseFileHelperText')}
          </Box>
        </Box>
      </StyledAppKitUpload>
      {value.map((file) => (
        <AttachmentDownload
          key={file.fileId}
          file={file}
          downloadUrl={getDownloadUrl(file.fileId)}
          onRemove={handleRemove}
        />
      ))}
    </Box>
  );
};
