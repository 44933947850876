import { Box } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { JsonRule } from 'react-awesome-query-builder';

import { useDatx } from 'hooks/useDatx';
import { FormComponent } from 'models/FormComponent';
import { StyledRule } from './Rule.elements';

interface IProps {
  rule: JsonRule;
  conjunction?: string;
}

export const Rule: FC<IProps> = ({ rule, conjunction }) => {
  const datx = useDatx();
  const { t } = useTranslation();

  let component: FormComponent | null = null;

  if (rule.properties.field) {
    component = datx.findOne(FormComponent, rule.properties.field as string);

    return (
      <StyledRule>
        <Box as="strong" mr="10px">
          {conjunction?.toLowerCase()}
        </Box>{' '}
        {component?.text} {t(`form.operators.${rule.properties.operator}`).toLowerCase()}{' '}
        {String(rule.properties.value[0])}
      </StyledRule>
    );
  }

  return null;
};
