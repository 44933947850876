import { FC } from 'types';
import { Fragment } from 'react';

import { ToolbarIconButton } from 'components/core/ToolbarIconButton/ToolbarIconButton';
import { ToolbarActionIcon } from 'typings/enums/ToolbarActionIcon';
import { useModal } from 'hooks/useModal';
import { AssessmentsExportModal } from 'components/assessments/AssessmentsExportModal/AssessmentsExportModal';

interface IExportAssessmentIconButtonProps {
  assessmentId: string;
}
export const ExportAssessmentIconButton: FC<IExportAssessmentIconButtonProps> = ({
  assessmentId,
}) => {
  const { isOpen, onOpen, onClose } = useModal();

  return (
    <Fragment>
      <ToolbarIconButton onClick={onOpen} action={ToolbarActionIcon.Download} />
      {isOpen && <AssessmentsExportModal selectedIds={[assessmentId]} onClose={onClose} />}
    </Fragment>
  );
};
