import { useDisclosure } from '@chakra-ui/react';
import { FC } from 'types';
import { Fragment } from 'react';

import { ToolbarIconButton } from 'components/core/ToolbarIconButton/ToolbarIconButton';
import { ToolbarActionIcon } from 'typings/enums/ToolbarActionIcon';

import { FormUserManagementModal } from '../FormUserManagementModal/FormUserManagementModal';

interface IFormUserManagementButtonProps {
  roomId: string;
  formId: string;
  disabled?: boolean;
}

export const FormUserManagementButton: FC<IFormUserManagementButtonProps> = ({
  disabled,
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Fragment>
      {isOpen ? (
        <ToolbarIconButton
          action={ToolbarActionIcon.UserActive}
          disabled={disabled}
          onClick={onClose}
        />
      ) : (
        <ToolbarIconButton action={ToolbarActionIcon.User} disabled={disabled} onClick={onOpen} />
      )}
      {isOpen && <FormUserManagementModal onClose={onClose} {...rest} />}
    </Fragment>
  );
};
