import { FC } from 'types';
import { Fragment } from 'react';

import { ToolbarIconButton } from 'components/core/ToolbarIconButton/ToolbarIconButton';
import { ToolbarActionIcon } from 'typings/enums/ToolbarActionIcon';
import { useModal } from 'hooks/useModal';
import { FormExportModal } from 'components/form/FormExportModal/FormExportModal';

interface IFormEditorExportIconButtonProps {
  formId: string;
}
export const FormEditorExportIconButton: FC<IFormEditorExportIconButtonProps> = ({ formId }) => {
  const { isOpen, onOpen, onClose } = useModal();

  return (
    <Fragment>
      <ToolbarIconButton onClick={onOpen} action={ToolbarActionIcon.Download} />
      {isOpen && <FormExportModal formId={formId} onClose={onClose} />}
    </Fragment>
  );
};
