import { Attribute } from '@datx/core';
import { computed } from 'mobx';

import { IDomain } from 'typings/interfaces/IDomain';
import { SiteStatus } from 'typings/enums/SiteStatus';

import { Resource } from './Resource';
import { User } from './User';

type SiteRestrictions = Array<{ domain: string; isMain: boolean }>;

export class Site extends Resource {
  public static type = 'sites';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public siteName!: string;

  @Attribute()
  public restrictions!: SiteRestrictions;

  @Attribute()
  public maximumNumberOfUsers!: number;

  @Attribute()
  public pwcContact!: string;

  @Attribute()
  public siteStatus!: SiteStatus;

  @Attribute()
  public allowLibraryAccess!: boolean;

  @Attribute()
  public allowThirdPartyUser!: boolean;

  @Attribute()
  public manageDominantDomains!: boolean;

  @Attribute()
  public manageNonDominantDomains!: boolean;

  @Attribute({ toMany: 'users' })
  public siteAdmins!: Array<User>;

  @Attribute()
  public domains!: Array<IDomain>;

  @computed
  public get adminEmails() {
    return this.siteAdmins.map((admin) => admin.email);
  }
}
