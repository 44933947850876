import { Attribute } from '@datx/core';

import { FormUser } from 'models/FormUser';
import { Resource } from 'models/Resource';
import { Comment } from 'models/Comment';

export class FormReview extends Resource {
  public static type = 'forms_review';

  @Attribute({isIdentifier: true})
  public formId!: string;

  @Attribute({ toMany: FormUser })
  public approvers!: Array<FormUser>;

  @Attribute({ toMany: Comment })
  public comments!: Array<Comment>;
}
