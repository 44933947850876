import { Box, Flex, Grid, HStack, Image } from '@chakra-ui/react';
import { FloatingActionButton, Input } from 'appkit-react';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BoxHeader } from 'components/core/BoxHeader/BoxHeader';
import { BackButton } from 'components/core/BoxHeader/components/BackButton';
import { MainLayout } from 'components/layouts/MainLayout/MainLayout';
import { CreateRoomModal } from 'components/rooms/CreateRoomModal/CreateRoomModal';
import { RoomsCardView } from 'components/rooms/RoomsCardView/RoomsCardView';
import { RoomsListView } from 'components/rooms/RoomsListView/RoomsListView';
import { RoomsToggleButtons } from 'components/rooms/RoomsToggleButtons/RoomsToggleButtons';
import { RoomsTrashView } from 'components/rooms/RoomsTrashView/RoomsTrashView';
import { RoomsCardSort } from 'typings/enums/RoomsCardSort';
import { RoomsViewMode } from 'typings/enums/RoomsViewMode';
import { useFilteredResources } from 'hooks/useFilteredResources';
import { useDebouncedSearch } from 'hooks/useDebouncedSearch';
import { Room } from 'models/Room';
import { RoomType } from 'typings/enums/RoomType';

interface IRoomsPageProps {
  initialViewMode?: RoomsViewMode;
}

export const RoomsPage: FC<IRoomsPageProps> = ({ initialViewMode }) => {
  const [cardsSortMode, setCardsSortMode] = useState<RoomsCardSort>(RoomsCardSort.None);
  const [viewMode, setViewMode] = useState<RoomsViewMode>(initialViewMode || RoomsViewMode.Cards);
  const [pageNumber, setPageNumber] = useState(() => 1);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const [trashSearchQuery, setTrashSearchQuery] = useDebouncedSearch();
  const { data, isValidating, isInitialLoad, handleSearchInputChange } = useFilteredResources(
    Room,
    'name',
  );
  const { t } = useTranslation();

  const handleFilterChange = (value: string) => {
    handleSearchInputChange(value);
    setPageNumber(1);
  };

  const rooms = (data as Array<Room>).filter((room) => room.roomType !== RoomType.MyRoom); // TODO: ticket #18884 Remove after Move (moving forms through rooms) is implemented

  return (
    <MainLayout>
      <Grid gridTemplateRows="auto 1fr auto" height="100%">
        <BoxHeader align="center" justify="space-between">
          <BackButton />
          <HStack spacing="53px" height="100%">
            {!(viewMode === RoomsViewMode.Trash) && (
              <Input
                prefix={
                  <span
                    className="appkiticon icon-filter-outline baseline-search-icon change-color-with-focus"
                    style={{ fontSize: 16 }}
                  />
                }
                placeholder={t('inputs.filter.placeholder')}
                onChange={handleFilterChange}
              />
            )}
            {viewMode === RoomsViewMode.Trash && (
              <Input
                prefix={
                  <span
                    className="appkiticon icon-search-outline baseline-search-icon change-color-with-focus"
                    style={{ fontSize: 16 }}
                  />
                }
                placeholder={t('inputs.search.placeholder')}
                onChange={setTrashSearchQuery}
              />
            )}

            {!(viewMode === RoomsViewMode.Trash) && (
              <FloatingActionButton
                size="sm"
                ml="19px"
                onClick={() => setIsCreateModalVisible(true)}
              >
                <Box as="span" className="appkiticon a-btn-icon icon-plus-fill" />
              </FloatingActionButton>
            )}

            <RoomsToggleButtons
              onModeChange={setViewMode}
              viewMode={viewMode}
              onSortChange={setCardsSortMode}
            />
          </HStack>
        </BoxHeader>
        {viewMode === RoomsViewMode.List && (
          <RoomsListView
            rooms={rooms}
            isLoading={isInitialLoad || isValidating}
            sx={{ marginBottom: 'auto' }}
          />
        )}
        {viewMode === RoomsViewMode.Cards && (
          <RoomsCardView
            rooms={rooms}
            sortMode={cardsSortMode}
            isLoading={isInitialLoad || isValidating}
            pageNumber={pageNumber}
            onPageChange={setPageNumber}
          />
        )}
        {viewMode === RoomsViewMode.Trash && <RoomsTrashView trashSearchQuery={trashSearchQuery} />}
        <Flex flex="1" justify="flex-end" mr="-20px" maxHeight="238px">
          <Image
            src="assets/images/illustration-research.png"
            alt={t('rooms.imageAlt')}
            maxHeight="100%"
            width="auto"
          />
        </Flex>
      </Grid>
      {isCreateModalVisible && (
        <CreateRoomModal isVisible onClose={() => setIsCreateModalVisible(false)} />
      )}
    </MainLayout>
  );
};
