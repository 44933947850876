import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormModal } from 'components/modal/FormModal/FormModal';
import { Form } from 'models/Form';
import { LoadingIndicator } from 'components/core/LoadingIndicator/LoadingIndicator';
import { IFormUserManagementFields } from 'typings/interfaces/IFormUserManagementFields';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { FormUserManagementFormWrapper } from 'components/form-users/FormUserManagementFormWrapper/FormUserManagementFormWrapper';

import { useFormManagementData, useFormMutate } from './FormUserManagementModal.hooks';

/*
  case 1: Form Creation -> we have room and room users and folder and folders users
*/

/*
  case 2: Room items -> we have room and room users and folder and folders users
    - we should have form loaded here also
    - on save revalidate all room forms
*/

/*
  case 3: Form builder -> we have form loaded
    - preload rooms and folder to get users
    - on save revalidate current form and all other calls => approval, review, ... ?
*/

/*
  [case 4]: My Forms page -> we need to load form model to get the roomId, and then load rooms (check with BE if we can ger GET /rooms/{id}) to get room users
    - we should have form loaded here 
    - preload rooms
    - get roomUsers after both have loaded
    - on save revalidate all my forms
*/

interface IFormUserManagementModalProps {
  form: Form;
  onClose(): void;
}
export const FormUserManagementModal: FC<IFormUserManagementModalProps> = ({ form, onClose }) => {
  const { t } = useTranslation();
  const { roomUsers, isValidating } = useFormManagementData(form, onClose);
  const [isLoading, setIsLoading] = useState(false);
  const saveMutation = useFormMutate(form, HttpMethod.Put, `/${form.id}`);

  const handleSave = async (formValues: IFormUserManagementFields) => {
    setIsLoading(true);
    try {
      await saveMutation.mutateFormUsers({
        ...form.updateDTO,
        users: formValues.users.map((user) => ({
          id: user.userId,
          role: user.role,
          defaultInitiatorStatus: user.defaultInitiatorStatus,
        })),
      });
      PopAlertSuccess(t('form.formMembers.success.update'));
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('form.formMembers.error.main'), response.errors?.name);
      } else {
        PopAlertFail(t('form.formMembers.error.unexpected'), response.message);
      }
    } finally {
      setIsLoading(false);
      setTimeout(onClose, 100);
    }
  };

  return (
    <FormModal isOpen maxWidth="930px" title={t('form.formMembers.modalTitle')} onClose={onClose}>
      {isValidating ? (
        <LoadingIndicator />
      ) : (
        <FormUserManagementFormWrapper
          formId={form.id}
          availableUsers={roomUsers}
          isLoading={isLoading}
          onCancel={onClose}
          onSave={handleSave}
        />
      )}
    </FormModal>
  );
};
