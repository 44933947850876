import { HStack } from '@chakra-ui/react';
import { Input } from 'appkit-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BoxHeader } from 'components/core/BoxHeader/BoxHeader';
import { BackButton } from 'components/core/BoxHeader/components/BackButton';
import { MainLayout } from 'components/layouts/MainLayout/MainLayout';
import { FormsTable } from 'components/forms/FormsTable/FormsTable';
import { useForms } from 'components/forms/FormsTable/FormsTable.hooks';
import { useDebouncedSearch } from 'hooks/useDebouncedSearch';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

export const FormsPage = () => {
  const [formsSearchQuery, setFormsSearchQuery] = useDebouncedSearch();
  const { isInitialLoad, isValidating, data } = useForms(formsSearchQuery);

  const { t } = useTranslation();

  return (
    <MainLayout>
      <BoxHeader align="center" justify="space-between">
        <BackButton />
        <HStack spacing={20}>
          <Input
            prefix={
              <span
                className="appkiticon icon-filter-outline baseline-search-icon change-color-with-focus"
                style={{ fontSize: 16 }}
              />
            }
            placeholder={t('inputs.filter.placeholder')}
            onChange={setFormsSearchQuery}
          />
          <Link to="/trash">
            <StyledButton size="lg" kind="secondary">
              {t('forms.tableView.link.trash.label')}
            </StyledButton>
          </Link>
        </HStack>
      </BoxHeader>
      <FormsTable forms={data} isLoading={isInitialLoad || isValidating} />
    </MainLayout>
  );
};
