import {
  IAssessmentPermissionGroupFormValues,
  IPermissionGroupFormValues,
} from 'typings/interfaces/formValues/IAssessmentSetupFormValues';

export const getAvailablePermissionGroupId = (
  addedGroups: Array<IAssessmentPermissionGroupFormValues>,
  availableGroups: Array<IPermissionGroupFormValues>,
  assessmentId: string | undefined,
): string | undefined => {
  if (!assessmentId) {
    return availableGroups[0].id;
  }

  const keys = addedGroups.map((group) => `${group.id}@${group.permissionGroupId}`);
  return availableGroups.find((group) => !keys.includes(`${assessmentId}@${group.id}`))?.id;
};

const leftPad = (num: number) => '000'.slice(0, -num.toString().length) + num

export const assessmentName = (index: number) => `Assessment ${leftPad(index + 1)}`;
