import { Tbody } from '@chakra-ui/react';
import { FC } from 'types';
import { OnDragEndResponder, DragDropContext, Droppable } from 'react-beautiful-dnd';

interface IDroppableTBodyProps {
  droppableId: string;
  droppableType: string;
  onDragEnd: OnDragEndResponder;
}
export const DroppableTBody: FC<IDroppableTBodyProps> = ({
  droppableId,
  droppableType,
  onDragEnd,
  children,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId} type={droppableType}>
        {(provided) => (
          <Tbody ref={provided.innerRef} {...provided.droppableProps}>
            {children}
            {provided.placeholder}
          </Tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
};
