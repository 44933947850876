import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { HistoryModal } from 'components/modal/HistoryModal/HistoryModal';
import { FormHistoryModalContent } from './components/FormHistoryModalContent/FormHistoryModalContent';
import { useFormAudit } from 'hooks/useAudit';

interface IFormHistoryModalProps {
  formId: string;
  onClose(): void;
}
export const FormHistoryModal: FC<IFormHistoryModalProps> = ({ formId, onClose }) => {
  const { data, isInitialLoad } = useFormAudit(formId);

  const { t } = useTranslation();

  return (
    <HistoryModal title={t(`form.history.title`)} onClose={onClose}>
      <FormHistoryModalContent isLoading={isInitialLoad} data={data} />
    </HistoryModal>
  );
};
