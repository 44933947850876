import { HttpMethod } from 'typings/enums/HttpMethod';
import { getIdToken } from 'utils/getIdToken';
import { config } from 'config'

const API_URL = `${config.apiUrl}/api/`;

export const appDataFetcher = (body: Record<string, boolean>) => () =>
  fetch(`${API_URL}sites/export/appAdmin`, {
    headers: {
      'content-type': 'application/json',
      'x-id-token': getIdToken() ?? '',
    },
    body: JSON.stringify(body),
    method: HttpMethod.Post,
  });
