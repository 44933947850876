import { FC } from 'types';
import { Box, HStack, Text } from '@chakra-ui/react';

import { Avatar } from 'components/core/Avatar/Avatar';

interface IFieldArrayListItemProps {
  username: string;
  email: string;
  disabled?: boolean;
  onRemove?: () => void;
}

export const FieldArrayListItem: FC<IFieldArrayListItemProps> = ({
  username,
  email,
  disabled,
  onRemove,
  children,
}) => {
  return (
    <HStack
      spacing={16}
      w="100%"
      height="60px"
      sx={{
        borderBottom: ({ colors }) => `2px solid ${colors.grey.lvl3}`,
      }}
    >
      <Avatar name={username} />
      <Text flexGrow={1}>{username === email ? email : `${username} (${email})`}</Text>
      {children}
      {onRemove ? (
        <Box
          className={`appkiticon icon-circle-delete-outline a-font-24 ${
            disabled ? '' : 'a-text-orange'
          }`}
          sx={{
            cursor: 'pointer',
          }}
          onClick={onRemove}
        />
      ) : null}
    </HStack>
  );
};
