import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { useBoolean } from '@chakra-ui/react';

import { ActionsMenu } from 'components/core/ActionsMenu/ActionsMenu.elements';
import { ActionsMenuItem } from 'components/core/ActionsMenu/ActionsMenuItem/ActionsMenuItem';
import { AssessmentView } from 'models/AssessmentView';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';

interface IAssessmentTableViewMenuProps {
  assessmentViewItem: AssessmentView;
  top?: number;
  left?: number;
  onClose(): void;
  onEditCollaboratorOpen(): void;
  onExportModalOpen(): void;
}

export const AssessmentTableViewMenu: FC<IAssessmentTableViewMenuProps> = ({
  assessmentViewItem,
  onClose,
  onEditCollaboratorOpen,
  onExportModalOpen,
  ...props
}) => {
  const { mutate } = useSWRConfig();
  const [isDeleting, setIsDeleting] = useBoolean();

  const { t } = useTranslation();
  const history = useHistory();
  const dependencyHeaders = useDependencyHeaders();

  const handleOpen = () => {
    history.push(`assessment/${assessmentViewItem.id}`);
    onClose();
  };

  const handleEditCollaborator = () => {
    onEditCollaboratorOpen();
    onClose();
  };

  const handleExport = () => {
    onExportModalOpen();
    onClose();
  };

  const handleDelete = async () => {
    setIsDeleting.on();
    const url = `assessments/${assessmentViewItem.id}`;
    try {
      await apiClient(url, HttpMethod.Delete, {
        headers: {
          'content-type': 'application/json',
          ...dependencyHeaders,
        },
      });
      await mutate(`assessments@${dependencyHeaders['x-site-id']}`);
      PopAlertSuccess(t('assessments.tableView.successMessages.delete'));
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('assessments.tableView.errors.delete.main'), response.errors?.name);
      } else {
        PopAlertFail(t('assessments.tableView.errors.delete.unexpected'), response.message);
      }
    } finally {
      setIsDeleting.off();
    }
  };

  return (
    <ActionsMenu {...props}>
      <ActionsMenuItem
        label={t('assessments.tableView.menu.items.open')}
        iconName="icon-purpose-outline"
        onClick={handleOpen}
      />
      {assessmentViewItem.manageAssessmentCollaborator && (
        <ActionsMenuItem
          label={t('assessments.tableView.menu.items.editCollaborator')}
          iconName="icon-add-user-outline"
          onClick={handleEditCollaborator}
        />
      )}
      <ActionsMenuItem
        label={t('assessments.tableView.menu.items.export')}
        iconName="icon-download-outline"
        onClick={handleExport}
      />
      <ActionsMenuItem
        label={t('assessments.tableView.menu.items.delete')}
        iconName="icon-delete-outline"
        onClick={handleDelete}
        isLoading={isDeleting}
      />
    </ActionsMenu>
  );
};
