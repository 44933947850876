import { getFileNameFromHeader } from './getFileNameFromHeader';
import { triggerBlobDownload } from './triggerBlobDownload';

export const exportDownload = async (fetcher: () => Promise<Response>) => {
  const response = await fetcher();

  if (!response.ok) {
    throw await response.json();
  }

  if (response.status === 204) {
    return null;
  }

  const dispositionHeader = response.headers.get('content-disposition');

  const filename = getFileNameFromHeader(dispositionHeader ?? '');

  const blob = await response.blob();

  triggerBlobDownload(blob, filename ?? '');
  return response;
};
