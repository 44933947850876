import { useDisclosure } from '@chakra-ui/react';
import { FC } from 'types';
import { Fragment } from 'react';

import { ToolbarIconButton } from 'components/core/ToolbarIconButton/ToolbarIconButton';
import { ToolbarActionIcon } from 'typings/enums/ToolbarActionIcon';

import { FormHistoryModal } from '../FormEditorHistoryModal/FormHistoryModal';

interface IFormHistoryButtonProps {
  formId: string;
}

export const FormHistoryButton: FC<IFormHistoryButtonProps> = ({ formId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Fragment>
      {isOpen ? (
        <ToolbarIconButton action={ToolbarActionIcon.TimeActive} onClick={onClose} />
      ) : (
        <ToolbarIconButton action={ToolbarActionIcon.Time} onClick={onOpen} />
      )}
      {isOpen && <FormHistoryModal onClose={onClose} formId={formId} />}
    </Fragment>
  );
};
