import { Box, HStack } from '@chakra-ui/react';
import { useState } from 'react';
import { Table, Button } from 'appkit-react';
import { TFunction, useTranslation } from 'react-i18next';

import { DomainManagementModal } from 'components/application-admin/domain-management/DomainManagementModal';
import { TableSearch } from 'components/table/TableSearch/TableSearch';
import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { useFilteredResources } from 'hooks/useFilteredResources';
import { dateSortMethod } from 'utils/dateSortMethod';
import { DomainAppAdmin } from 'models/DomainAppAdmin';
import { CellText } from 'components/core/CellText/CellText';
import { ExportAppData } from 'components/export-data/ExportAppData/ExportAppData';

const exampleColumns = (onSelect: (domain: DomainAppAdmin) => void, t: TFunction) => [
  {
    Header: 'Site name',
    accessor: 'siteName',
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.name'),
    accessor: 'name',
    Cell: (row: Record<string, unknown>) => (
      <CellText onClick={() => onSelect(row.original as DomainAppAdmin)} cursor="pointer">
        {row.value as string}
      </CellText>
    ),
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.domain'),
    accessor: 'id',
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.type'),
    accessor: 'type',
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.contact'),
    accessor: 'contact',
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.numberOfUsers'),
    accessor: 'numberOfDomainUsers',
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.libraryAccess'),
    accessor: 'hasLibraryAccess',
    Cell: (row: Record<string, unknown>) => (
      <CellText>
        {(row.value as string)
          ? t('applicationAdmin.domain.libraryAccess.enabled')
          : t('applicationAdmin.domain.libraryAccess.disabled')}
      </CellText>
    ),
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.status'),
    accessor: 'status',
    Cell: (row: Record<string, unknown>) => (
      <CellText textTransform="capitalize">{row.value as string}</CellText>
    ),
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.expirationDate'),
    accessor: 'formattedExpiration',
    sortMethod: dateSortMethod,
  },
  {
    Header: t('applicationAdmin.domain.tableColumns.lastEdit'),
    accessor: 'formattedLastEdit',
    sortMethod: dateSortMethod,
  },
];

export const AppAdminDomainManagementPage = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState<DomainAppAdmin | null>(null);

  const { handleSearchInputChange, data, isInitialLoad, isValidating } = useFilteredResources(
    DomainAppAdmin,
    'name',
  );

  const onUserSelect = (domain: DomainAppAdmin) => {
    setSelectedDomain(domain);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setSelectedDomain(null);
    setIsModalVisible(false);
  };

  return (
    <Box mt={20}>
      <TableWrapper isLoading={isInitialLoad || isValidating}>
        <TableSearch onChange={handleSearchInputChange} />
        <Table
          columns={exampleColumns(onUserSelect, t)}
          data={data}
          showPageSizeOptions={false}
          defaultPageSize={10}
          defaultSorted={[{ id: 'name', asc: true }]}
        />
        <HStack spacing={20} position="absolute" right="0" bottom="0">
          <ExportAppData />
          <Button size="md" onClick={() => setIsModalVisible(true)}>
            {t('applicationAdmin.domain.action.add').toUpperCase()}
          </Button>
        </HStack>
      </TableWrapper>
      {isModalVisible && (
        <DomainManagementModal domain={selectedDomain} isVisible onCancel={handleCancel} />
      )}
    </Box>
  );
};
