import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { HistoryModal } from 'components/modal/HistoryModal/HistoryModal';
import { useAssessmentAudit } from 'hooks/useAudit';
import { AssessmentHistoryContent } from './components/AssessmentHistoryContent/AssessmentHistoryContent';

interface IAssessmentHistoryModalProps {
  assessmentId: string;
  onClose(): void;
}
export const AssessmentHistoryModal: FC<IAssessmentHistoryModalProps> = ({
  assessmentId,
  onClose,
}) => {
  const { data, isInitialLoad } = useAssessmentAudit(assessmentId);

  const { t } = useTranslation();

  return (
    <HistoryModal title={t('form.history.title')} onClose={onClose}>
      <AssessmentHistoryContent isLoading={isInitialLoad} data={data} />
    </HistoryModal>
  );
};
