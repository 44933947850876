import { FC } from 'types';
import { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { useAssessmentForm } from 'context/AssessmentContext/AssessmentContextProvider';
import { isAssessmentQuestionValueValid } from 'services/assessmentFormComponent';
import { AssessmentFormValues } from 'typings/types/AssessmentFormValues';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { useDebouncedUpdateAssessmentQuestion } from 'hooks/useAssessment';
import { recalculateSkippedComponents } from 'context/AssessmentContext/AssessmentContext.utils';
import { useDatx } from 'hooks/useDatx';

interface IAssessmentFieldFormWrapperProps {
  question: AssessmentFormComponent;
}
export const AssessmentFieldFormWrapper: FC<IAssessmentFieldFormWrapperProps> = ({
  question,
  children,
}) => {
  const datx = useDatx();
  const { isEditable, assessmentId, assessment, dispatch } = useAssessmentForm();
  const { updateAssessmentAnswer } = useDebouncedUpdateAssessmentQuestion(
    question,
    assessment?.isTest,
  );
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: question.getFieldValues(),
  });
  const {
    formState: { isValid },
    setError,
    clearErrors,
    getValues,
  } = methods;

  const handleBlur = useCallback(async () => {
    if (!isEditable || question.componentType === FormComponentType.ScaleNumber) {
      return;
    }
    if (question.componentType === FormComponentType.Name && !isValid) {
      return;
    }
    clearErrors();
    const formValues = getValues() as AssessmentFormValues;
    const formErrors = isAssessmentQuestionValueValid(question, formValues);

    if (formErrors.length) {
      formErrors.forEach((error) => {
        setError(error.name, { type: error.type });
      });
      return;
    }

    if (!question.updateAnswer(getValues())) {
      return;
    }
    recalculateSkippedComponents(dispatch, datx, assessmentId);

    await updateAssessmentAnswer();
  }, [
    isEditable,
    question,
    isValid,
    clearErrors,
    getValues,
    dispatch,
    datx,
    assessmentId,
    updateAssessmentAnswer,
    setError,
  ]);

  return (
    <FormProvider {...methods}>
      <Box as="form" onBlur={handleBlur}>
        {children}
      </Box>
    </FormProvider>
  );
};
