import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FormComponent } from 'models/FormComponent';
import { IFormComponentAddonDto } from 'typings/interfaces/dto/IFormComponentAddonDto';
import { useModal } from 'hooks/useModal';

import { Addon } from '../Question/components/Addon/Addon';
import { QuestionSettingsModal } from '../QuestionSettingsModal/QuestionSettingsModal';

interface ISkipConditionAddonSettingsEditorProps {
  addon: IFormComponentAddonDto;
  question: FormComponent;
}
export const SkipConditionAddonSettingsEditor: FC<ISkipConditionAddonSettingsEditorProps> = ({
  question,
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useModal();

  return (
    <Fragment>
      <Addon name={t('form.addon.skipCondition.name')} onClick={onOpen} />
      {isOpen && (
        <QuestionSettingsModal question={question} orderTag={question.orderTag} onClose={onClose} />
      )}
    </Fragment>
  );
};
