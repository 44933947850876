import { Text } from '@chakra-ui/react';
import { Fragment } from 'react';
import { PopAlert } from 'appkit-react';
import { TFunction } from 'i18next';

import { IApiClientError } from 'typings/interfaces/IApiClientError';
import { parseApiClientError } from 'utils/parseApiClientError';

export const ParseAndPopAlertFail = (error: IApiClientError, t: TFunction) => {
  const parsedError = parseApiClientError(error, t);

  return PopAlert({
    duration: 5000,
    status: 'error',
    message: (
      <Fragment>
        <Text size="lg" mb="10px" fontWeight="bold">
          {parsedError?.message}
        </Text>
        {parsedError?.errors?.map((error, index) => (
          <Text key={index}>{error}</Text>
        ))}
      </Fragment>
    ),
    className: 'alert-fixed alert-fixed-bottom-right appkit-animation animated fadeInRight',
  });
};
