import { TFunction } from 'i18next';

import { Audit } from 'models/Audit';
import { AuditActionType } from 'typings/enums/AuditActionType';

export const getComponentAuditRecord = (
  { type, newValuesObject, oldValuesObject }: Audit,
  t: TFunction,
) => {
  if (type === AuditActionType.Create) {
    if (newValuesObject) {
      return t('room.historyContent.component.create.main', {
        type: t(`form.components.${newValuesObject.ComponentType}`),
      });
    } else {
      return t('room.historyContent.component.create.catch');
    }
  }

  if (type === AuditActionType.Update) {
    if (newValuesObject) {
      return t('room.historyContent.component.update.main', {
        type: t(`form.components.${newValuesObject.ComponentType}`),
      });
    } else {
      return t('room.historyContent.component.update.catch');
    }
  }

  if (type === AuditActionType.Delete) {
    if (oldValuesObject) {
      return t('room.historyContent.component.delete.main', {
        type: t(`form.components.${oldValuesObject.ComponentType}`),
      });
    } else {
      return t('room.historyContent.component.delete.catch');
    }
  }

  return t('room.historyContent.component.unknown');
};

export const getFormAuditRecord = (
  { type, newValuesObject, oldValuesObject }: Audit,
  t: TFunction,
) => {
  if (type === AuditActionType.Create) {
    if (newValuesObject) {
      return t('room.historyContent.form.create.main', { name: newValuesObject.Name });
    } else {
      return t('room.historyContent.form.create.catch');
    }
  }

  if (type === AuditActionType.Update) {
    if (newValuesObject) {
      if (oldValuesObject.Name !== newValuesObject.Name) {
        return t('room.historyContent.form.update.name', {
          oldName: oldValuesObject.Name,
          newName: newValuesObject.Name,
        });
      }

      if (oldValuesObject.HasApprover !== newValuesObject.HasApprover) {
        return t(`room.historyContent.form.update.approver.${newValuesObject.HasApprover}`, {
          name: newValuesObject.Name,
        });
      }

      if (oldValuesObject.FormStatus !== newValuesObject.FormStatus) {
        return t('room.historyContent.form.update.status', {
          name: newValuesObject.Name,
          status: t(`form.status.${newValuesObject.FormStatus}`),
        });
      }

      if (oldValuesObject.IsDeleted !== newValuesObject.IsDeleted) {
        if (oldValuesObject.IsDeleted === null || oldValuesObject.IsDeleted === false) {
          return t('room.historyContent.form.update.deleted', { name: newValuesObject.Name });
        } else {
          return t('room.historyContent.form.update.restored', { name: newValuesObject.Name });
        }
      }

      return t('room.historyContent.form.update.main', { name: newValuesObject.Name });
    } else {
      return t('room.historyContent.form.update.catch');
    }
  }

  if (type === AuditActionType.Delete) {
    if (oldValuesObject) {
      return t('room.historyContent.form.delete.main', { name: oldValuesObject.Name });
    } else {
      return t('room.historyContent.form.delete.catch');
    }
  }

  return t('room.historyContent.form.unknown');
};

export const getFormUserAuditRecord = (
  { type, newValuesObject, oldValuesObject, replacedUser }: Audit,
  t: TFunction,
) => {
  if (type === AuditActionType.Create) {
    if (newValuesObject) {
      if (newValuesObject.IsApprover) {
        return t('room.historyContent.formUser.create.approver', {
          name: newValuesObject.Username,
        });
      }

      if (replacedUser) {
        return t('room.historyContent.formUser.create.userReplacement', {
          name: newValuesObject.Username,
          role: newValuesObject.FormUserRole,
          replacedUserName: replacedUser.username,
          status: newValuesObject.DefaultInitiatorStatus,
        });
      }

      return t('room.historyContent.formUser.create.main', {
        name: newValuesObject.Username,
        role: newValuesObject.FormUserRole,
        status: newValuesObject.DefaultInitiatorStatus,
      });
    } else {
      return t('room.historyContent.formUser.create.catch');
    }
  }

  if (type === AuditActionType.Update) {
    if (newValuesObject) {
      const roleChangeMessage = t('room.historyContent.formUser.update.roleChange', {
        name: newValuesObject.Username,
        oldRole: oldValuesObject.FormUserRole,
        newRole: newValuesObject.FormUserRole,
      });

      const initiatorStatusChangeMessage = t(
        'room.historyContent.formUser.update.initiatorStatusChange',
        {
          name: newValuesObject.Username,
          oldStatus: oldValuesObject.DefaultInitiatorStatus,
          newStatus: newValuesObject.DefaultInitiatorStatus,
        },
      );

      if (oldValuesObject.FormUserRole !== newValuesObject.FormUserRole) {
        if (oldValuesObject.DefaultInitiatorStatus !== newValuesObject.DefaultInitiatorStatus) {
          return `${roleChangeMessage}. ${initiatorStatusChangeMessage}`;
        } else {
          return roleChangeMessage;
        }
      }

      if (oldValuesObject.DefaultInitiatorStatus !== newValuesObject.DefaultInitiatorStatus) {
        return initiatorStatusChangeMessage;
      }

      if (oldValuesObject.Approved !== newValuesObject.Approved) {
        if (newValuesObject.Approved === null) {
          return t('room.historyContent.formUser.update.approved.reset', {
            name: newValuesObject.Username,
          });
        }

        return t(`room.historyContent.formUser.update.approved.${newValuesObject.Approved}`, {
          name: newValuesObject.Username,
        });
      }

      if (oldValuesObject.IsApprover !== newValuesObject.IsApprover) {
        return t('room.historyContent.formUser.update.approver', {
          name: newValuesObject.Username,
        });
      }

      return t('room.historyContent.formUser.update.catch');
    } else {
      return t('room.historyContent.formUser.update.catch');
    }
  }

  if (type === AuditActionType.Delete) {
    if (oldValuesObject) {
      if (oldValuesObject.IsApprover) {
        return t('room.historyContent.formUser.delete.approver', {
          name: oldValuesObject.Username,
        });
      }

      return t('room.historyContent.formUser.delete.main', {
        name: oldValuesObject.Username,
        role: oldValuesObject.FormUserRole,
      });
    } else {
      return t('room.historyContent.formUser.delete.catch');
    }
  }

  return t('room.historyContent.formUser.unknown');
};

export const getFormCommentAuditRecord = ({ type }: Audit, t: TFunction) => {
  if (type === AuditActionType.Create) {
    return t('room.historyContent.formComment.create.main');
  }
  return t('room.historyContent.formUser.unknown');
};

export const getFormApprovalAuditRecord = (
  { type, newValuesObject, oldValuesObject }: Audit,
  t: TFunction,
) => {
  if (type === AuditActionType.Create) {
    if (newValuesObject) {
      return t('room.historyContent.formApproval.create.main', {
        requiredNumber: newValuesObject.RequiredApprovals,
      });
    } else {
      return t('room.historyContent.formApproval.create.catch');
    }
  }

  if (type === AuditActionType.Update) {
    if (newValuesObject) {
      if (oldValuesObject.RequiredApprovals !== newValuesObject.RequiredApprovals) {
        return t('room.historyContent.formApproval.update.required', {
          requiredNumber: newValuesObject.RequiredApprovals,
        });
      }

      if (oldValuesObject.CurrentApprovals !== newValuesObject.CurrentApprovals) {
        return t('room.historyContent.formApproval.update.current', {
          numberOfApprovals: newValuesObject.CurrentApprovals,
          requiredNumber: newValuesObject.RequiredApprovals,
        });
      }
    } else {
      return t('room.historyContent.formApproval.update.catch');
    }
  }
  return t('room.historyContent.formApproval.unknown');
};
