export interface IWithId {
  id: string;
}
export interface IWithUserId extends IWithId {
  userId: string;
}

export const transformResourceUserDto = (
  resourceUserDto: IWithId,
  resourceId: string,
): IWithUserId => ({
  ...resourceUserDto,
  userId: resourceUserDto.id,
  id: `${resourceUserDto.id}@${resourceId}`,
});
