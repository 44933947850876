import { Attribute } from '@datx/core';
import { computed } from 'mobx';
import { format } from 'date-fns';

import { Resource } from 'models/Resource';
import { User } from 'models/User';
import { AuditEntity } from 'typings/enums/AuditEntity';
import { AuditActionType } from 'typings/enums/AuditActionType';
import { IAuditUser } from 'typings/interfaces/IAuditUser';

export class Audit extends Resource {
  public static type = 'audit';

  @Attribute()
  public readonly time!: string;

  @Attribute()
  public readonly entity!: AuditEntity;

  @Attribute()
  public readonly entityId!: string;

  @Attribute()
  public readonly type!: AuditActionType;

  @Attribute()
  public readonly oldValues!: string;

  @Attribute()
  public readonly newValues!: string;

  @Attribute()
  public readonly isSystemNotice!: boolean;

  @Attribute({ toOne: User })
  public user!: User;

  @Attribute()
  public readonly replacedUser!: IAuditUser;

  @computed
  public get formattedTime() {
    if (this.time) {
      return format(new Date(this.time), 'PPp');
    }

    return '/';
  }

  @computed
  public get oldValuesObject() {
    if (this.oldValues) {
      return JSON.parse(this.oldValues);
    }

    return null;
  }

  @computed
  public get newValuesObject() {
    if (this.newValues) {
      return JSON.parse(this.newValues);
    }

    return null;
  }

  @computed
  public get skip() {
    return (
      this.entity === AuditEntity.Room &&
      this.type === AuditActionType.Update &&
      this.oldValues === this.newValues
    );
  }
}
