import { FC } from 'types';

import { RoomFolder } from 'models/RoomFolder';
import { RoomCellNameFolder } from '../RoomCellNameFolder/RoomCelNameFolder';
import { RoomCellNameForm } from '../RoomCellNameForm/RoomCellNameForm';
import { Form } from 'models/Form';

interface IRoomCellNameProps {
  original: RoomFolder | Form;
}

export const RoomCellName: FC<IRoomCellNameProps> = ({ original }) => {
  return original.meta.type === 'roomfolders' ? (
    <RoomCellNameFolder folder={original as RoomFolder} />
  ) : (
    <RoomCellNameForm form={original as Form} />
  );
};
