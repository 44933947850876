import { TFunction } from 'i18next';

import {
  getComponentAuditRecord,
  getFormApprovalAuditRecord,
  getFormAuditRecord,
  getFormCommentAuditRecord,
  getFormUserAuditRecord,
} from 'components/modal/HistoryModal/components/HistoryModalContent.utils';
import { Audit } from 'models/Audit';
import { AuditEntity } from 'typings/enums/AuditEntity';

export const getAuditRecord = (audit: Audit, t: TFunction) => {
  switch (audit.entity) {
    case AuditEntity.Form:
      return getFormAuditRecord(audit, t);
    case AuditEntity.FormUser:
      return getFormUserAuditRecord(audit, t);
    case AuditEntity.FormComment:
      return getFormCommentAuditRecord(audit, t);
    case AuditEntity.FormApproval:
      return getFormApprovalAuditRecord(audit, t);
    case AuditEntity.Component:
      return getComponentAuditRecord(audit, t);
    default:
      return t('room.historyContent.unknownAuditAction');
  }
};
