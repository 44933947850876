// sessionStorage key => oidc.user:{authUrl}:{clientId}

import { User } from 'oidc-client';
import { config } from 'config'

export const getIdToken = (): string | undefined => {
  const authUrl = config.authEndpoint;
  const clientId = config.clientId;

  const authInfo = JSON.parse(
    sessionStorage.getItem(`oidc.user:${authUrl}:${clientId}`) ?? 'null',
  ) as User | null;

  return authInfo?.id_token;
};
