import { chakra } from '@chakra-ui/react';

export const StyledTableWrapper = chakra('div', {
  baseStyle: {
    position: 'relative',
  },
});

export const StyledTableWrapperLoading = chakra('div', {
  baseStyle: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    bg: 'rgba(0,0,0,0.2)',
    zIndex: '200',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.a-loading': {
      width: '2rem',
      height: '2rem',
    },
  },
});
