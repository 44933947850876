import { HStack, StackProps } from '@chakra-ui/react';
import { FC } from 'types';

export const RoomDetailsRow: FC<StackProps> = ({ children, sx, ...rest }) => {
  return (
    <HStack spacing={12} w="367px" minHeight="36px" alignItems="center" sx={sx} {...rest}>
      {children}
    </HStack>
  );
};
