import { Attribute } from '@datx/core';
import { format } from 'date-fns';
import { computed } from 'mobx';
import { DomainType } from 'typings/enums/DomainType';
import { UserRole } from '../typings/enums/UserRole';
import { UserStatus } from '../typings/enums/UserStatus';
import { Domain } from './Domain';

import { Resource } from './Resource';

export class User extends Resource {
  public static type = 'users';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public readonly lastEdit!: string;

  @Attribute()
  public readonly lastLoginDateTime!: string;

  @Attribute()
  public username!: string;

  @Attribute()
  public email!: string;

  @Attribute()
  public thirdPartyUserContact!: string;

  @Attribute({ toOne: Domain })
  public domain!: Domain;

  @Attribute()
  public expirationDate!: string;

  @Attribute()
  public phoneNumber!: string;

  @Attribute()
  public isActive!: boolean;

  @Attribute()
  public hasLibraryAccess!: boolean;

  @Attribute()
  public role!: UserRole;

  @Attribute()
  public status!: UserStatus;

  @computed
  public get formattedLastEdit() {
    if (this.lastEdit) {
      return format(new Date(this.lastEdit), 'PPpp');
    }

    return '';
  }
  @computed
  public get formattedLastLogin() {
    if (this.lastLoginDateTime) {
      return format(new Date(this.lastLoginDateTime), 'PPpp');
    }

    return '';
  }

  @computed
  public get formattedExpirationDate() {
    if (this.expirationDate) {
      return format(new Date(this.expirationDate), 'MMMM d, yyyy');
    }

    return '';
  }

  @computed
  public get formattedRole() {
    return this.role.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' ');
  }

  @computed
  public get isDominantUser() {
    return this.domain?.type === DomainType.Dominant;
  }

  @computed
  public get displayName() {
    return this.username === this.email ? this.email : `${this.username} (${this.email})`;
  }
}
