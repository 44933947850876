import { FC } from 'types';
import { Control, FieldArrayWithId, FieldValues } from 'react-hook-form';
import { Box, Table, Thead, Tr, Th, Tbody } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Total } from 'components/core/Total/Total';
import { IFormUserManagementFields } from 'typings/interfaces/IFormUserManagementFields';
import { FormFieldError } from 'components/core/FormFieldError/FormFieldError';

import { FormUserManagementRow } from './components/FormUserManagementRow/FormUserManagementRow';

interface IFormUserManagementTableProps {
  fields: Array<FieldArrayWithId<IFormUserManagementFields, 'users', 'id'>>;
  control: Control<IFormUserManagementFields>;
  remove(index: number): void;
}
export const FormUserManagementTable: FC<IFormUserManagementTableProps> = ({
  fields,
  control,
  remove,
}) => {
  const { t } = useTranslation();

  return (
    <Box overflowX="auto">
      <Table size="md" variant="list" mt={20}>
        <Thead>
          <Tr>
            <Th>
              <Total label={t('form.formMembers.totalUsers')} total={fields.length} />
            </Th>
            <Th>{t('form.formMembers.role')}</Th>
            <Th>{t('form.formMembers.defaultInitiator')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {fields.map((user, index) => (
            <FormUserManagementRow
              key={user.userId}
              user={user}
              index={index}
              control={control}
              onRemove={() => remove(index)}
            />
          ))}
        </Tbody>
      </Table>
      <FormFieldError name="users" control={control as unknown as Control<FieldValues>} />
    </Box>
  );
};
