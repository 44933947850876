import { Heading, HStack, Box } from '@chakra-ui/react';
import { FC } from 'types';
import { Select, SelectOption } from 'appkit-react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ConclusionCloseButton } from 'components/form-editor/FormEditorConclusionGroup/components/ConclusionCloseButton/ConclusionCloseButton';
import { FormConclusionType } from 'typings/enums/FormConclusionType';
import { IConditionGroupFormValues } from 'typings/interfaces/IConditionGroupFormValues';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { ToggleExpandButton } from 'components/core/ToggleExpandButton/ToggleExpandButton';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';

import { StyledConditionGroupHeader } from '../../FormEditorConditionGroup.elements';

interface IFormEditorConditionGroupHeaderProps {
  index: number;
  isExpanded: boolean;
  isDirty: boolean;
  control: Control<IConditionGroupFormValues>;
  onExpandToggle(): void;
  onDelete(): void;
}

export const FormEditorConditionGroupHeader: FC<IFormEditorConditionGroupHeaderProps> = ({
  index,
  isExpanded,
  control,
  onExpandToggle,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { disableEdit, allowLimitedEditOnFinalized } = useFormEditor();

  return (
    <StyledConditionGroupHeader>
      <Heading size="md" fontWeight="normal">
        {t('form.conclusions.ConclusionType')} - {index}
      </Heading>
      <HStack spacing={20}>
        {(!disableEdit || allowLimitedEditOnFinalized) && <FormButtonSubmit />}
        <Box width="150px">
          {/* check error for circulating reference of IFormConditionGroup */}
          {/* @ts-ignore */}
          <Controller
            control={control}
            name="conclusionType"
            render={({ field }) => (
              <Select
                defaultValue={field.value}
                onSelect={field.onChange}
                disabled={disableEdit}
                {...field}
              >
                <SelectOption value={FormConclusionType.Inclusive}>
                  {t('form.conclusions.Inclusive')}
                </SelectOption>
                <SelectOption value={FormConclusionType.Exclusive}>
                  {t('form.conclusions.Exclusive')}
                </SelectOption>
                <SelectOption value={FormConclusionType.ConditionedText}>
                  {t('form.conclusions.ConclusionText')}
                </SelectOption>
              </Select>
            )}
          />
        </Box>
        <ToggleExpandButton isExpanded={isExpanded} onClick={onExpandToggle} />
        {!disableEdit && <ConclusionCloseButton onClick={onDelete} />}
      </HStack>
    </StyledConditionGroupHeader>
  );
};
