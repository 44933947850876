import { Box, BoxProps, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { JsonGroup } from 'react-awesome-query-builder';
import { useTranslation } from 'react-i18next';

import { Group } from './Group/Group';

interface IProps extends BoxProps {
  tree?: JsonGroup;
  type?: string;
}

export const LogicPreview: FC<IProps> = ({ tree, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Box maxW="600px" {...rest}>
      <Text fontSize={20} mb="20px">
        {t('form.settings.condition.conditionPreview.title')}
      </Text>
      {tree?.type === 'group' && <Group isRoot group={tree} />}
    </Box>
  );
};
