import { NumbersComponentType, NumbersSettings } from 'typings/types/FormComponentSettings';

export function getValidSettings(settings: string | null): NumbersSettings | null {
  if (!settings) {
    return null;
  }
  const settingsJson = JSON.parse(settings) as NumbersSettings;
  if (!('numberType' in settingsJson)) {
    return null;
  }

  if (settingsJson.numberType === NumbersComponentType.Currency && !settingsJson.selectedCurrency) {
    return null;
  }

  settingsJson.decimalDigits = settingsJson.decimalDigits ?? 0;

  return settingsJson;
}

export const getLabel = (settings: NumbersSettings) => {
  switch (settings.numberType) {
    case NumbersComponentType.Currency:
      return settings.selectedCurrency;
    case NumbersComponentType.Percentage:
      return '%';
    default:
      return '';
  }
};
