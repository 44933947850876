import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { useAnimation } from 'hooks/useAnimation';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

import { StyledCreateDropdown, StyledCreateDropdownBody } from './CreateDropdown.elements';

interface ICreateDropdownProps extends BoxProps {
  isVisible: boolean;
  onClose(): void;
  onFolderClick(): void;
  onFormClick(): void;
}

export const CreateDropdown: FC<ICreateDropdownProps> = ({
  isVisible,
  onClose,
  onFolderClick,
  onFormClick,
  sx,
  ...rest
}) => {
  const { transitionEnd, isAnimationReady, shouldRender } = useAnimation(isVisible);
  const { t } = useTranslation();

  if (!shouldRender) {
    return null;
  }

  return (
    <StyledCreateDropdown
      sx={{
        transform: `translateY(${isAnimationReady ? '107%' : '105%'})`,
        opacity: isAnimationReady ? '1' : '0',
        ...sx,
      }}
      onTransitionEnd={transitionEnd}
      onClick={(e) => e.stopPropagation()}
      {...rest}
    >
      <Flex justify="flex-end" p="10px">
        <Box
          as="span"
          className="appkiticon icon-close-outline a-text-orange"
          cursor="pointer"
          onClick={onClose}
        />
      </Flex>
      <StyledCreateDropdownBody>
        <Flex justify="center" sx={{ button: { width: '100%' } }}>
          <StyledButton size="lg" kind="secondary" className="a-mr-10" onClick={onFolderClick}>
            <span className="appkiticon icon-folder-closed-outline" />
            {t('room.header.createDropdown.folder')}
          </StyledButton>
          <StyledButton size="lg" onClick={onFormClick}>
            <span className="appkiticon icon-survey-outline" />
            {t('room.header.createDropdown.form')}
          </StyledButton>
        </Flex>
      </StyledCreateDropdownBody>
    </StyledCreateDropdown>
  );
};
