export enum DropAction {
  AddSectionComponent = 'AddSectionComponent',
  AddQuestionComponent = 'AddQuestionComponent',
  AddAddon = 'AddAddon',
  MoveSection = 'MoveSection',
  MoveQuestion = 'MoveQuestion',
  AddConclusion = 'AddConclusion',
  MoveConclusion = 'MoveConclusion',
  AddConditionGroup = 'AddConditionGroup',
  MoveConditionGroup = 'MoveConditionGroup',
  None = 'None',
}
