import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { HTMLChakraProps } from '@chakra-ui/react';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { IButtonProps } from 'typings/appkit-react/IButtonProps';

export const FormButtonSubmit: FC<
  Omit<IButtonProps, 'type' | 'kind'> & HTMLChakraProps<'button'>
> = ({ children, ...rest }) => {
  const { t } = useTranslation();

  return (
    <StyledButton size="lg" type="submit" {...rest}>
      {children ?? t('action.save.label')}
    </StyledButton>
  );
};
