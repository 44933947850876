import { AuthProvider, AuthProviderProps } from 'oidc-react';
import { UserManager } from 'oidc-client';
import { FC } from 'types';
import { observer } from 'mobx-react';
import { config } from 'config'

const authProps: AuthProviderProps = {
  userManager: new UserManager({
    authority: config.authEndpoint,
    client_id: config.clientId,
    client_secret: config.clientSecret,
    scope: config.openidScopes,
    redirect_uri: config.loginCallbackUrl,
    response_type: config.openidResponseType,
    automaticSilentRenew: true,
    metadata: {
      issuer: config.issuerUrl,
      authorization_endpoint: config.authEndpoint,
      userinfo_endpoint: config.userinfoEndpoint,
      token_endpoint: config.tokenEndpoint,
      end_session_endpoint: config.endSessionUrl,
    },
  }),
};

export const OIDCProvider: FC<AuthProviderProps> = observer((props) => {
  return <AuthProvider {...authProps} {...props} />;
});
