import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React from 'react';
import { FC } from 'types'
import { useTranslation } from 'react-i18next';

import { ToolbarActionIcon } from 'typings/enums/ToolbarActionIcon';

interface IToolbarIconButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  action: ToolbarActionIcon;
  onClick?(): void;
}

export const ToolbarIconButton: FC<IToolbarIconButtonProps> = ({
  action,
  onClick,
  sx,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <IconButton
      isRound
      size="xs"
      icon={<span className={`appkiticon ${action} a-font-24`} />}
      onClick={onClick}
      aria-label={t(`action.${action}.label`)}
      sx={{
        bg: 'transparent',
        cursor: 'pointer',
        'svg path': {
          fill: 'brand.orange',
        },
        _focus: {
          bg: 'transparent',
        },
        _active: {
          bg: 'transparent',
        },
        _hover: {
          opacity: '0.75',
          bg: 'transparent',
        },
        sx,
      }}
      {...rest}
    />
  );
};
