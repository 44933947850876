import { Flex, useBoolean } from '@chakra-ui/react';
import { FC } from 'types';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { Modal } from 'components/modal/Modal/Modal';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { ControlledInputField } from 'fields/InputField/InputField';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { useRoomUser } from 'hooks/useRoomUser';
import { FolderUserRole } from 'typings/enums/FolderUserRole';
import { RoomFolder } from 'models/RoomFolder';

import { useCurrentFolder } from '../room.hooks';
import { useFolderMutate } from '../RoomItemsList/components/FolderMenu/FolderMenu.hooks';

interface ICreateFolderModalProps {
  folder?: RoomFolder;
  onCancel(): void;
}

interface IFormValues {
  name: string;
}

export const CreateFolderModal: FC<ICreateFolderModalProps> = ({ folder, onCancel }) => {
  const [isLoading, setIsLoading] = useBoolean();
  const { mutate } = useFolderMutate(folder);
  const currentFolder = useCurrentFolder();
  const roomUser = useRoomUser();
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm<IFormValues>({
    defaultValues: {
      name: '',
    },
  });

  const onSubmit = async ({ name }: IFormValues) => {
    setIsLoading.on();
    try {
      await mutate({
        name,
        parentFolderId: currentFolder?.id ?? null,
        users: folder?.users.map((folderUser) => folderUser.upsertDTO) ?? [
          {
            id: roomUser?.userId,
            role: FolderUserRole.Owner,
          },
        ],
      });
      setIsLoading.off();

      PopAlertSuccess(
        folder
          ? t('room.modals.createFolder.successMessages.renamed')
          : t('room.modals.createFolder.successMessages.created', { name }),
      );
      onCancel();
    } catch (response: any) { // eslint-disable-line
      PopAlertFail(t('room.errors.createFolder.unexpected'), response.message);
      setIsLoading.off();
    }
  };

  return (
    <Modal isVisible sx={{ width: '450px' }}>
      <ModalHeader onCancel={onCancel}>
        {folder && <ModalTitle>{t('room.modals.createFolder.title.rename')}</ModalTitle>}
        {!folder && <ModalTitle>{t('room.modals.createFolder.title.create')}</ModalTitle>}
      </ModalHeader>
      <ModalBody pb="20px">
        <Flex as="form" direction="column" align="stretch" onSubmit={handleSubmit(onSubmit)}>
          <ControlledInputField
            control={control as unknown as Control<FieldValues>}
            label={t('room.modals.createFolder.form.name.label').toUpperCase()}
            id="name"
            htmlFor="name"
            name="name"
            defaultValue={folder?.name}
            placeholder={t('room.modals.createFolder.form.name.placeholder')}
            rules={{ required: { value: true, message: t('validation.required') } }}
          />
          <FormButtonSubmit isLoading={isLoading} disabled={isLoading} alignSelf="end" mt={12} />
        </Flex>
      </ModalBody>
    </Modal>
  );
};
