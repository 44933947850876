import { Attribute } from '@datx/core';
import { format } from 'date-fns';
import { computed } from 'mobx';
import { Resource } from './Resource';
import { User } from './User';

export class Comment extends Resource {
  public static type = 'comments';

  @Attribute()
  public readonly comment!: string;

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public roomId!: string;

  @Attribute()
  public createdAt!: string;

  @Attribute()
  public updatedAt!: string;

  @Attribute({ toOne: User })
  public user!: User;

  @computed
  public get formattedCreatedAt() {
    if (this.createdAt) {
      return format(new Date(this.createdAt), 'PPp');
    }

    return '/';
  }

  @computed
  public get formattedUpdatedAt() {
    if (this.updatedAt) {
      return format(new Date(this.updatedAt), 'PPp');
    }

    return '/';
  }
}
