import { FC } from 'types';
import { Text } from '@chakra-ui/react';
import { Tooltip } from 'appkit-react';

interface ICampaignSettingsTooltipProps {
  content: string;
}

export const CampaignSettingsTooltip: FC<ICampaignSettingsTooltipProps> = ({ content }) => {
  return (
    <Tooltip content={content} placement="top">
      <Text
        as="span"
        className="appkiticon icon-help-question-fill"
        color="brand.orange"
        fontSize="3xl"
      />
    </Tooltip>
  );
};
