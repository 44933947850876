import { Box, HStack, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/modal/Modal/Modal';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { ModalFooter } from 'components/modal/Modal/components/ModalFooter';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { AttachmentDownload } from 'components/core/AttachmentDownload/AttachmentDownload';
import { TextEditor } from 'components/core/TextEditor/TextEditor';
import { IFormComponentAddonDto } from 'typings/interfaces/dto/IFormComponentAddonDto';
import { LoadingIndicator } from 'components/core/LoadingIndicator/LoadingIndicator';
import { AssessmentFormComponent } from 'models/AssessmentFormComponent';

interface IAssessmentQuestionHelpModalProps {
  orderTag: string;
  addon?: IFormComponentAddonDto;
  assessmentQuestion: AssessmentFormComponent;
  onClose(): void;
}
export const AssessmentQuestionHelpModal: FC<IAssessmentQuestionHelpModalProps> = ({
  addon,
  orderTag,
  assessmentQuestion,
  onClose,
}) => {
  const { t } = useTranslation();

  const addonSettings = useMemo(
    () => addon?.settingsJson && JSON.parse(addon.settingsJson),
    [addon],
  );

  if (!addonSettings) {
    throw new Error('Assessment question not found in store');
  }

  const { assessment } = assessmentQuestion;
  if (!assessment) {
    return <LoadingIndicator />;
  }

  return (
    <Modal isVisible maxWidth="750px">
      <ModalHeader onCancel={onClose}>
        <ModalTitle>{t('form.addon.helpText.title', { index: orderTag })}</ModalTitle>
      </ModalHeader>
      <ModalBody py={40} px={20}>
        <TextEditor
          toolbarStyle={{ display: 'none' }}
          editorProps={{ readOnly: true }}
          initialHtmlContent={addonSettings?.Text}
        />

        <Text size="xl" as="h3" mt={20} mb={8}>
          {t('assessment.help.attachment')}
        </Text>
        <Box p={20} position="relative" className="a-apply-theme-background-gray-level-1">
          {addon?.attachments?.map((file) => (
            <AttachmentDownload
              key={file.fileId}
              file={file}
              downloadUrl={
                assessment.isTest
                  ? `rooms/${assessment.roomId}/forms/${assessment.formId}/formFiles/${file.fileId}`
                  : `assessments/${assessment.id}/formFiles/${file.fileId}`
              }
            />
          ))}
        </Box>
      </ModalBody>
      <ModalFooter pb={20}>
        <HStack spacing={20}>
          <StyledButton size="lg" kind="secondary" onClick={onClose}>
            {t('action.close.label')}
          </StyledButton>
        </HStack>
      </ModalFooter>
    </Modal>
  );
};
