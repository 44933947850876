import { FC } from 'types';
import { useState } from 'react';
import { Box, HStack, useBoolean, VStack, Text } from '@chakra-ui/react';
import { updateModel } from '@datx/core';
import { observer } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LogicBuilder } from 'components/LogicBuilder/LogicBuilder';
import { FormConclusion } from 'models/FormConclusion';
import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { stateToDto } from 'components/LogicBuilder/LogicBuilder.utils';
import { FormConclusionType } from 'typings/enums/FormConclusionType';
import { FormConclusionGroup } from 'models/FormConclusionGroup';
import { IConditionGroupFormValues } from 'typings/interfaces/IConditionGroupFormValues';
import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { compareFormComponentOrder } from 'services/formComponents/compareFormComponentOrder';
import { AttachmentFileStatus } from 'typings/enums/AttachmentFileStatus';
import { IAttachmentFile } from 'typings/interfaces/dto/IAttachmentFile';
import { Upload } from 'components/core/Upload/Upload';

import { FormEditorConditionGroupHeader } from './components/FormEditorConditionGroupHeader/FormEditorConditionGroupHeader';
import { FormEditorConditionGroupText } from './components/FormEditorConditionGroupText/FormEditorConditionGroupText';
import { StyledConditionGroupContainer } from './FormEditorConditionGroup.elements';

interface IFormEditorConditionGroupProps {
  index: number;
  conclusion: FormConclusion;
  conclusionGroup: FormConclusionGroup;
}

export const FormEditorConditionGroup: FC<IFormEditorConditionGroupProps> = observer(
  ({ index, conclusion, conclusionGroup }) => {
    const { t } = useTranslation();
    const depHeaders = useDependencyHeaders();
    const {
      params: { roomId, formId },
    } = useRouteMatch<{ roomId: string; formId: string }>();
    const [isExpanded, setIsExpanded] = useBoolean(false);

    const {
      components,
      updateConclusion,
      removeConclusion,
      removeConclusionGroup,
      disableEdit,
      allowLimitedEditOnFinalized,
    } = useFormEditor();
    const { handleSubmit, formState, control, reset } = useForm<IConditionGroupFormValues>({
      defaultValues: {
        conclusionType: conclusion.conclusionType || FormConclusionType.Inclusive,
        conclusionText: conclusion.conclusionText || '',
        conditionGroup: conclusion.conditionGroup || {},
        attachments: conclusion.attachments || [],
      },
    });

    const [questionComponents] = useState(() => {
      return components
        .filter(
          (component) => !component.isSection && !component.isQuestionSet && !component.isRange,
        )
        .sort(compareFormComponentOrder);
    });

    const onSubmit = async (values: IConditionGroupFormValues) => {
      if (disableEdit && !allowLimitedEditOnFinalized) {
        return;
      }
      await Promise.all(
        (values.attachments ?? []).reduce((acc: Array<Promise<unknown>>, item: IAttachmentFile) => {
          if (item.fileStatus === AttachmentFileStatus.Initiated) {
            acc.push(
              apiClient(
                `rooms/${roomId}/forms/${formId}/FormFiles/${item.fileId}/validate`,
                HttpMethod.Put,
                {
                  headers: {
                    ...depHeaders,
                  },
                },
              ),
            );
          }

          return acc;
        }, []),
      );

      const updated = updateModel(conclusion, {
        ...values,
        attachments: values.attachments ?? [],
      });
      updateConclusion(conclusionGroup, updated);
      reset(values);
    };

    const handleDelete = () => {
      if (disableEdit) {
        return;
      }

      const conclusions = conclusionGroup.conclusions.filter(
        (_conclusion) => _conclusion.id !== conclusion.id,
      );

      if (conclusions.length === 0) {
        removeConclusionGroup(conclusionGroup);
      } else {
        removeConclusion(conclusionGroup, conclusions);
      }
    };

    return (
      <Box as="form" onSubmit={handleSubmit(onSubmit)} w="100%">
        <StyledConditionGroupContainer>
          <FormEditorConditionGroupHeader
            index={index}
            control={control}
            isExpanded={isExpanded}
            isDirty={formState.isDirty}
            onExpandToggle={setIsExpanded.toggle}
            onDelete={handleDelete}
          />
          {isExpanded && (
            <VStack spacing={30} width="100%" mt={20} alignItems="flex-start">
              {/* check error for circulating reference of IFormConditionGroup */}
              {/* @ts-ignore */}
              <Controller
                control={control}
                name="conditionGroup"
                render={({ field }) => (
                  <LogicBuilder
                    disabled={disableEdit}
                    components={questionComponents}
                    defaultState={conclusion.conditionGroup}
                    onChange={(state) => {
                      field.onChange(stateToDto(state));
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="conclusionText"
                render={({ field }) => (
                  <FormEditorConditionGroupText
                    initialHtmlContent={conclusion.conclusionText}
                    disabled={disableEdit && !allowLimitedEditOnFinalized}
                    onChange={field.onChange}
                  />
                )}
              />
              <HStack spacing={20} alignItems="center">
                <Text size="lg" fontWeight="bold">
                  {`${t('assessment.help.attachment')} ${t('upload.maxSizeWarning')}`}
                </Text>
                <Controller
                  control={control}
                  name="attachments"
                  // eslint-disable-next-line
                  render={({ field: { ref: _ref, ...fieldProps } }) => (
                    <Upload
                      getUploadUrlEndpoint={`rooms/${roomId}/forms/${formId}/FormFiles`}
                      getDownloadUrl={(fileId: string) =>
                        `rooms/${roomId}/forms/${formId}/formFiles/${fileId}`
                      }
                      disabled={disableEdit && !allowLimitedEditOnFinalized}
                      {...fieldProps}
                    />
                  )}
                />
              </HStack>
            </VStack>
          )}
        </StyledConditionGroupContainer>
      </Box>
    );
  },
);
