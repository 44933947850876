import { Attribute } from '@datx/core';
import { computed } from 'mobx';

import { AssessmentApprovalDTO, UpsertAssessmentApprovalDTO } from 'typings/types/dto/ApprovalDTO';
import { Resource } from 'models/Resource';
import { Assessment } from './Assessment';
import { AssessmentApprover } from './AssessmentApprover';

export class AssessmentApproval extends Resource {
  public static type = 'assessment_approval';

  @Attribute({
    isIdentifier: true,
  })
  public assessmentId!: string;

  @Attribute()
  public isAssessmentEditable!: boolean;

  @Attribute()
  public requiredApprovals!: number;

  @Attribute()
  public currentApprovals!: number;

  @Attribute()
  public notificationText!: string;

  @Attribute()
  public skipSendingNotification!: boolean;

  @Attribute({
    toOne: Assessment,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parse: (_, data: Record<string, any>) => (data as AssessmentApprovalDTO).assessmentId,
  })
  public assessment!: string;

  @Attribute({ toMany: AssessmentApprover })
  public approvers!: Array<AssessmentApprover>;

  @computed
  public get isSetUp(): boolean {
    return (
      (this.requiredApprovals ?? 0) > 0 &&
      (this.requiredApprovals ?? 0) <= (this.approvers.length ?? 0)
    );
  }

  @computed
  public get upsertDTO(): UpsertAssessmentApprovalDTO {
    return {
      isAssessmentEditable: this.isAssessmentEditable,
      requiredApprovals: this.requiredApprovals,
      skipSendingNotification: this.skipSendingNotification,
      notificationText: this.notificationText,
      approvers: this.approvers.map((approver) => approver.id),
    };
  }
}
