import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import { Control, FieldValues, useForm, useWatch } from 'react-hook-form';

import { FormModal } from 'components/modal/FormModal/FormModal';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { useTenant } from 'hooks/useTenant';
import { CheckboxField } from 'fields/CheckboxField/CheckboxField';
import { handleCampaignExport } from './CampaignExportModal.utils';
import {
  AssessmentsExportFormValues,
  assessmentExportOptions,
} from 'components/assessments/AssessmentsExportModal/AssessmentsExportModal.utils';

interface ICampaignExportModalProps {
  formId: string;
  selectedIds: string[];
  onClose(): void;
}
export const CampaignExportModal: FC<ICampaignExportModalProps> = ({
  formId,
  selectedIds,
  onClose,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<AssessmentsExportFormValues>({
    defaultValues: {
      excel: true,
      pdf: true,
      json: true,
    },
  });

  const watchAll = useWatch({ control });

  const { t } = useTranslation();
  const siteId = useTenant().activeTenant?.siteId;

  const handleExport = async (formValues: AssessmentsExportFormValues) => {
    if (!siteId) {
      return;
    }

    const types = Object.entries(formValues)
      .filter(([, value]) => value)
      .map(([key]) => key);

    await Promise.allSettled(
      types.map((type) => handleCampaignExport(t, siteId, formId, type, selectedIds)),
    );

    onClose();
  };

  return (
    <FormModal
      isOpen
      maxWidth="500px"
      title={t(
        selectedIds.length === 1 ? 'export.modal.title.campaign' : 'export.modal.title.campaigns',
      )}
      onClose={onClose}
    >
      <Box as="form" onSubmit={handleSubmit(handleExport)}>
        {assessmentExportOptions.map((option) => (
          <CheckboxField
            key={option.value}
            control={control as unknown as Control<FieldValues>}
            name={option.value}
            className="a-mb-20"
          >
            {t(option.label)}
          </CheckboxField>
        ))}

        {(watchAll.excel || watchAll.pdf) && (
          <Text color="brand.yellow">
            {t(
              selectedIds.length === 1
                ? 'export.modal.info.campaign'
                : 'export.modal.info.campaigns',
            )}
          </Text>
        )}
        <FormActions>
          <FormButtonCancel onClick={onClose} />
          <FormButtonSubmit isLoading={isSubmitting || !siteId} disabled={!siteId || isSubmitting}>
            {t('action.export.label')}
          </FormButtonSubmit>
        </FormActions>
      </Box>
    </FormModal>
  );
};
