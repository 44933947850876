import { TextProps, Text } from '@chakra-ui/react';
import { FC } from 'types';

interface IFormFieldLabel extends TextProps {
  htmlFor?: string;
}
export const FormFieldLabel: FC<IFormFieldLabel> = ({ children, size = 'sm', ...props }) => {
  return (
    <Text as="label" size={size} className="a-form-label" textTransform="uppercase" {...props}>
      {children}
    </Text>
  );
};
