import { FC } from 'types';

import { QuestionIconButton } from './QuestionIconButton';

interface IMoreAnswersAddonIconButtonProps {
  isDisabled?: boolean;
  onClick?(): void;
}
export const MoreAnswersAddonIconButton: FC<IMoreAnswersAddonIconButtonProps> = ({
  isDisabled,
  onClick,
}) => {
  return (
    <QuestionIconButton
      icon={'icon-circle-plus-fill'}
      label="more answers"
      isDisabled={isDisabled}
      onClick={onClick}
    />
  );
};
