import { chakra, useStyleConfig } from '@chakra-ui/react';
import {
  Input,
  Button,
  Tab,
  Tabs,
  InputNumber,
  Badge,
  Breadcrumb,
  Select,
  SelectOption,
  Table,
} from 'appkit-react';
import { Upload } from 'appkit-react-upload';
import { forwardRef, ReactNode } from 'react';

export const StyledInput = chakra(Input, {
  baseStyle: {
    label: {
      textTransform: 'uppercase',
    },
  },
});

export const StyledInputNumber = chakra(InputNumber);

export const StyledButton = chakra(Button, {
  baseStyle: {
    textTransform: 'uppercase',
  },
});

export const StyledTab = chakra(Tab);

export const StyledTabs = chakra(Tabs);

export const StyledBadge = chakra(Badge, {
  baseStyle: {
    textTransform: 'uppercase',
  },
});

export const StyledAppKitUpload = chakra(Upload);

export const StyledTable = chakra(Table);

export const StyledBreadcrumb = chakra(Breadcrumb, {
  baseStyle: {
    flexWrap: 'wrap',
    rowGap: 20,
    my: 20,
  },
});

export const StyledSelectOption = chakra(SelectOption); // Doesn't work with toggleMode="search"

const StyledAppkitSelect = chakra(Select);
interface IAppkitSelectProps {
  // fill the rest of appkit props as needed
  dropdownAlwaysDown?: boolean;
  children: ReactNode;
}
interface IStyledSelectProps extends IAppkitSelectProps {
  variant?: string;
}
export const StyledSelect = forwardRef<HTMLSelectElement, IStyledSelectProps>(
  ({ variant, children, ...rest }, ref) => {
    const styles = useStyleConfig('StyledSelect', { variant });
    return (
      <StyledAppkitSelect ref={ref} __css={styles} {...rest}>
        {children}
      </StyledAppkitSelect>
    );
  },
);
