import format from 'date-fns/format';
import { TFunction } from 'react-i18next';

import {
  getComponentAuditRecord,
  getFormAuditRecord,
} from 'components/modal/HistoryModal/components/HistoryModalContent.utils';
import { Audit } from 'models/Audit';
import { AuditActionType } from 'typings/enums/AuditActionType';
import { AuditEntity } from 'typings/enums/AuditEntity';

const getFolderAuditRecord = ({ type, newValuesObject }: Audit, t: TFunction) => {
  if (type === AuditActionType.Create) {
    try {
      return t('room.historyContent.folder.create.main', { name: newValuesObject.Name });
    } catch {
      return t('room.historyContent.folder.create.catch');
    }
  }

  if (type === AuditActionType.Update) {
    try {
      return t('room.historyContent.folder.update.main', { name: newValuesObject.Name });
    } catch {
      return t('room.historyContent.folder.update.catch');
    }
  }

  if (type === AuditActionType.Delete) {
    try {
      return t('room.historyContent.folder.remove.main', { name: newValuesObject.Name });
    } catch (error: any) { // eslint-disable-line
      return t('room.historyContent.folder.delete.catch');
    }
  }

  return t('room.historyContent.folder.unknown');
};

const getUserAuditRecord = ({ type, newValuesObject, oldValuesObject }: Audit, t: TFunction) => {
  if (type === AuditActionType.Create) {
    try {
      return t('room.historyContent.user.create.main', {
        name: newValuesObject.Username,
        role: newValuesObject.RoomUserRole,
      });
    } catch {
      return t('room.historyContent.user.create.catch');
    }
  }

  if (type === AuditActionType.Delete) {
    try {
      return t('room.historyContent.user.delete.main', {
        name: oldValuesObject.Username,
        role: oldValuesObject.RoomUserRole,
      });
    } catch {
      return t('room.historyContent.user.delete.catch');
    }
  }

  if (type === AuditActionType.Update) {
    try {
      if (oldValuesObject.RoomUserRole !== newValuesObject.RoomUserRole) {
        return t('room.historyContent.user.update.main', {
          name: newValuesObject.Username,
          role: newValuesObject.RoomUserRole,
        });
      }

      if (oldValuesObject.ExpirationDate !== newValuesObject.ExpirationDate) {
        const newExpirationText = newValuesObject.ExpirationDate
          ? t('room.historyContent.user.update.expiration.setTo', {
              date: format(new Date(newValuesObject.ExpirationDate), 'MMMM d, yyyy'),
            })
          : t('room.historyContent.user.update.expiration.removed');
        return `${newValuesObject.Username}'s expiration date has been ${newExpirationText}`;
      }

      return t('room.historyContent.user.update.sporadic', { name: newValuesObject.Username });
    } catch {
      return t('room.historyContent.user.update.catch');
    }
  }
  return t('room.historyContent.user.unknown');
};

const getRoomAuditRecord = (
  { type, newValuesObject, oldValuesObject }: Audit,
  roomCurrentName: string | undefined,
  t: TFunction,
) => {
  const roomName = roomCurrentName || t('room.historyContent.room.notAvailable');

  if (type === AuditActionType.Create) {
    try {
      const withNameChanged =
        roomName !== newValuesObject.Name
          ? t('room.historyContent.room.create.withName', { newName: newValuesObject.Name })
          : '';
      return t('room.historyContent.room.create.main', {
        currentName: roomName,
        oldName: withNameChanged,
        type: newValuesObject.RoomType,
      });
    } catch {
      return t('room.historyContent.room.create.catch', {
        currentName: roomName,
      });
    }
  }

  if (type === AuditActionType.Update) {
    try {
      if (oldValuesObject.Name !== newValuesObject.Name) {
        return t('room.historyContent.room.update.renamed', {
          currentName: roomName,
          oldName: oldValuesObject.Name,
          newName: newValuesObject.Name,
        });
      }

      if (oldValuesObject.RoomType !== newValuesObject.RoomType) {
        return t('room.historyContent.room.update.updated', {
          currentName: roomName,
          type: newValuesObject.RoomType,
        });
      }

      if (oldValuesObject.RoomStatus !== newValuesObject.RoomStatus) {
        return t('room.historyContent.room.update.approved', {
          currentName: roomName,
        });
      }
      return t('room.historyContent.room.update.main', {
        currentName: roomName,
      });
    } catch {
      return t('room.historyContent.room.update.catch', {
        currentName: roomName,
      });
    }
  }

  if (type === AuditActionType.Delete) {
    try {
      return t('room.historyContent.room.delete.main', {
        currentName: roomName,
      });
    } catch {
      return t('room.historyContent.room.delete.catch');
    }
  }

  return t('room.historyContent.room.unknown');
};

export const getAuditRecord = (
  roomAudit: Audit,
  roomCurrentName: string | undefined,
  t: TFunction,
) => {
  const roomName = roomCurrentName || t('room.historyContent.room.notAvailable');

  switch (roomAudit.entity) {
    case AuditEntity.RoomFolder:
      return getFolderAuditRecord(roomAudit, t);
    case AuditEntity.Form:
      return getFormAuditRecord(roomAudit, t);
    case AuditEntity.RoomUser:
      return getUserAuditRecord(roomAudit, t);
    case AuditEntity.Room:
      return getRoomAuditRecord(roomAudit, roomName, t);
    case AuditEntity.Component:
      return getComponentAuditRecord(roomAudit, t);
    default:
      return t('room.historyContent.unknownAuditAction');
  }
};
