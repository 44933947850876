import { TFunction } from 'react-i18next';

import {
  siteAssessmentsExcelExportRequest,
  siteAssessmentsJsonExportRequest,
  siteDataFetcher,
  siteFormsExcelExportRequest,
  siteFormsJsonExportRequest,
} from 'fetchers/siteExport';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { exportDownload } from 'utils/exportDownload';

export type SiteExportFormValues = Record<SiteExportOption, boolean>;

type SiteExportOption =
  | 'users'
  | 'domains'
  | 'formsExcel'
  | 'formsJson'
  | 'assessmentsExcel'
  | 'assessmentsJson'
  | 'statistics';

interface IExportOption {
  label: string;
  value: SiteExportOption;
  disabled?: boolean;
}
export const exportOptions: Array<IExportOption> = [
  { label: 'export.modal.checkbox.optionUsersLabel', value: 'users' },
  { label: 'export.modal.checkbox.optionDomainsLabel', value: 'domains' },

  {
    label: 'export.modal.checkbox.optionFormsExcelLabel',
    value: 'formsExcel',
  },
  {
    label: 'export.modal.checkbox.optionFormsJsonLabel',
    value: 'formsJson',
  },
  {
    label: 'export.modal.checkbox.optionAssessmentsExcelLabel',
    value: 'assessmentsExcel',
  },
  {
    label: 'export.modal.checkbox.optionAssessmentsJsonLabel',
    value: 'assessmentsJson',
  },
  {
    label: 'export.modal.checkbox.optionStatisticsLabel',
    value: 'statistics',
    disabled: true,
  },
];

const getSiteExportDto = ({ users, domains, statistics }: SiteExportFormValues) => ({
  doExportUsers: users,
  doExportDomains: domains,
  doExportStatistics: statistics,
});

export const handleSiteAssessmentsExcelExportRequest = async (t: TFunction, siteId: string) => {
  try {
    await siteAssessmentsExcelExportRequest(siteId);
    PopAlertSuccess(t('export.modal.success.assessments'));
  } catch {
    PopAlertFail(t('export.errors.dataDownload.main'));
  }
};

export const handleSiteAssessmentsJsonExportRequest = async (t: TFunction, siteId: string) => {
  try {
    await siteAssessmentsJsonExportRequest(siteId);
    PopAlertSuccess(t('export.modal.success.assessments'));
  } catch {
    PopAlertFail(t('export.errors.dataDownload.main'));
  }
};

export const handleSiteFormsExcelExportRequest = async (t: TFunction, siteId: string) => {
  try {
    await siteFormsExcelExportRequest(siteId);
    PopAlertSuccess(t('export.modal.success.forms'));
  } catch {
    PopAlertFail(t('export.errors.dataDownload.main'));
  }
};

export const handleSiteFormsJsonExportRequest = async (t: TFunction, siteId: string) => {
  try {
    await siteFormsJsonExportRequest(siteId);
    PopAlertSuccess(t('export.modal.success.forms'));
  } catch {
    PopAlertFail(t('export.errors.dataDownload.main'));
  }
};

export const handleSiteDataCsvExport = async (
  t: TFunction,
  siteId: string,
  values: SiteExportFormValues,
) => {
  try {
    await exportDownload(siteDataFetcher(siteId, getSiteExportDto(values)));
  } catch {
    PopAlertFail(t('export.errors.dataDownload.main'));
  }
};
