import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCampaignCreate, useCampaignCommonActions } from 'hooks/useInitiateCampaign';
import { Form } from 'models/Form';
import { useDatx } from 'hooks/useDatx';
import { Campaign } from 'models/Campaign';
import { useCampaignInitiation } from 'hooks/useCampaignInitiation';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';

import { CampaignModalTabs } from '../CampaignModalTabs/CampaignModalTabs';
import { CampaignModal } from '../CampaignModal/CampaignModal';

interface IInitiateCampaignModalProps {
  formId: string;
  roomId: string;
  campaignId?: string;
  onCancel(): void;
}

export const InitiateCampaignModal: FC<IInitiateCampaignModalProps> = ({
  formId,
  roomId,
  campaignId,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [currentCampaignId, setCurrentCampaignId] = useState(campaignId || 'new');

  const datx = useDatx();
  const form = datx.findOne(Form, formId);

  const campaign = useCampaignInitiation(form, currentCampaignId);
  const createCampaign = useCampaignCreate(roomId, formId);
  const { updateCampaign, deleteCampaign } = useCampaignCommonActions(roomId, formId);

  const handleSaveCampaign = async () => {
    if (campaign.isDraft) {
      const response = await createCampaign(campaign.createDto);
      setCurrentCampaignId(response.id);
    } else {
      const response = await updateCampaign(campaign);
      setCurrentCampaignId(response.id);
    }
  };

  const handleClose = () => {
    datx.removeOne(Campaign, 'new');
    onCancel();
  };

  const handleDeleteCampaign = async () => {
    if (campaign.isDraft) {
      PopAlertSuccess(t('campaign.delete.success'));
      handleClose();

      return;
    }
    try {
      await deleteCampaign(campaign.id);
      PopAlertSuccess(t('campaign.delete.success'));
      handleClose();
    } catch (error: any) { // eslint-disable-line
      PopAlertFail(t('campaign.delete.error'));
      throw error;
    }
  };

  return (
    <CampaignModal onCancel={handleClose}>
      <CampaignModalTabs
        campaign={campaign}
        onSave={handleSaveCampaign}
        onDelete={handleDeleteCampaign}
        onCancel={handleClose}
      />
    </CampaignModal>
  );
};
