import { FC } from 'types';
import { FieldArrayWithId, Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Table, Thead, Tr, Th, Tbody } from '@chakra-ui/react';

import { Total } from 'components/core/Total/Total';
import { FormFieldError } from 'components/core/FormFieldError/FormFieldError';
import { ICreateRoomFields } from 'typings/interfaces/ICreateRoomFields';

import { CreateRoomUserManagementRow } from './components/CreateRoomUserManagementRow/CreateRoomUserManagementRow';

interface ICreateRoomUserManagementTableProps {
  fields: Array<FieldArrayWithId<ICreateRoomFields, 'roomUsers', 'id'>>;
  control: Control<ICreateRoomFields>;
  remove(index: number): void;
  onExpirationDateChange(index: number, date: Date): void;
}

export const CreateRoomUserManagementTable: FC<ICreateRoomUserManagementTableProps> = ({
  fields,
  control,
  remove,
  onExpirationDateChange,
}) => {
  const { t } = useTranslation();

  return (
    <Box overflowX="auto" height="432px">
      <Table size="md" variant="list" mt={20}>
        <Thead>
          <Tr>
            <Th>
              <Total
                label={t('rooms.createRoom.usersManagement.totalUsers')}
                total={fields.length}
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {fields.map((user, index) => (
            <CreateRoomUserManagementRow
              key={user.userId}
              user={user}
              index={index}
              control={control}
              onRemove={() => remove(index)}
              onExpirationDateChange={onExpirationDateChange}
            />
          ))}
        </Tbody>
      </Table>
      <FormFieldError name="roomUsers" control={control as unknown as Control<FieldValues>} />
    </Box>
  );
};
