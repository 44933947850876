import { FC } from 'types';
import { Skeleton, Text, TextProps } from '@chakra-ui/react';

export const StatusIndicator: FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Skeleton isLoaded={Boolean(children)} startColor="brand.orange" minWidth="20px">
      <Text color="brand.orange" borderBottom="2px" pb={8} textTransform="uppercase" {...rest}>
        {children}
      </Text>
    </Skeleton>
  );
};
