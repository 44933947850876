import { TextEditor as AppkitTextEditor } from '@appkit4/react-text-editor';
import { SyntheticEvent } from 'react';
import { FC } from 'types';
import { RefCallBack } from 'react-hook-form';
import 'styles/appkit4.scss';

interface IAppkitReactEditorProps {
  //  add other props as needed
  initialHtmlContent?: string | null;
  toolbarStyle?: {
    display: 'none';
  };
  editorProps?: {
    readOnly: boolean;
  };
  className?: string;
  textEditorRef?: RefCallBack;
  onChange?(html: string): void;
  onBlur?(e: SyntheticEvent): void;
  onFocus?(e: SyntheticEvent): void;
}

export const TextEditor: FC<IAppkitReactEditorProps> = (props) => {
  const { initialHtmlContent, onChange, editorProps, toolbarStyle, textEditorRef, ...rest } = props;

  return (
    <>
      <AppkitTextEditor
        disabled={editorProps?.readOnly}
        config={{
          fontFamily: ['Arial', 'Arial Black', 'Lucida Grande', 'PwC Helvetica Neue'],
          toolbar:
            toolbarStyle?.display === 'none'
              ? []
              : [
                  'fontFamily',
                  'fontSize',
                  'bold',
                  'italic',
                  'bulletedList',
                  'numberedList',
                  'indent',
                  'outdent',
                  'alignment:left',
                  'alignment:center',
                  'alignment:right',
                  'alignment:justify',
                  'link',
                ],
        }}
        {...rest}
        data={initialHtmlContent}
        onChange={(_: any, __: any, html: string) => onChange?.(html)}
        onReady={textEditorRef}
      />
    </>
  );
};
