import { FC } from 'types';
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/react';

import { FormUser } from 'models/FormUser';
import { FormActions } from 'components/core/FormActions/FormActions';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';
import { FormButtonSubmit } from 'components/core/FormActions/FormButtonSubmit';
import { IResourceUser } from 'typings/interfaces/IResourceUser';
import { IFormUserManagementFields } from 'typings/interfaces/IFormUserManagementFields';
import { FormUserManagement } from 'components/form-users/FormUserManagement/FormUserManagement';

interface IFormUserManagementFormProps {
  availableUsers: Array<IResourceUser>;
  formUsers: Array<FormUser>;
  isLoading: boolean;
  onSave(formValues: IFormUserManagementFields): Promise<void>;
  onCancel(): void;
}
export const FormUserManagementForm: FC<IFormUserManagementFormProps> = ({
  availableUsers,
  formUsers,
  isLoading,
  onSave,
  onCancel,
}) => {
  const { control, handleSubmit } = useForm<IFormUserManagementFields>({
    defaultValues: {
      users: formUsers,
    },
  });

  const handleSave = async (formValues: IFormUserManagementFields) => {
    await onSave(formValues);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleSave)}>
      <FormUserManagement control={control} availableUsers={availableUsers} />
      <FormActions>
        <FormButtonCancel onClick={onCancel} />
        <FormButtonSubmit isLoading={isLoading} disabled={isLoading} />
      </FormActions>
    </Box>
  );
};
