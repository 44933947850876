import { Text, TextProps } from '@chakra-ui/react';
import { FC } from 'types';
import { Fragment } from 'react';

interface IWordBreakingTextProps extends TextProps {
  breakAt: string;
  children: string;
}
export const WordBreakingText: FC<IWordBreakingTextProps> = ({ children, breakAt, ...rest }) => {
  const breaks = children.split(breakAt);

  if (breaks.length <= 1) {
    return <Text {...rest}>{children}</Text>;
  }

  return (
    <Text {...rest}>
      {breaks.map((piece, index) => (
        <Fragment key={index}>
          {index > 0 && <wbr />}
          {index > 0 && breakAt}
          {piece}
        </Fragment>
      ))}
    </Text>
  );
};
