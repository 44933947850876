import { FC } from 'types';
import { IconButton } from '@chakra-ui/react';

interface IToggleExpandButtonProps {
  isExpanded: boolean;
  onClick(): void;
}
export const ToggleExpandButton: FC<IToggleExpandButtonProps> = ({ isExpanded, onClick }) => {
  const icon = isExpanded ? 'icon-up-chevron-outline' : 'icon-down-chevron-outline';
  const label = isExpanded ? 'collapse' : 'expand';

  return (
    <IconButton
      isRound
      size="xs"
      icon={<span className={`appkiticon ${icon} a-font-16`} />}
      onClick={onClick}
      aria-label={label}
      sx={{
        color: 'brand.orange',
        ml: 20,
        bg: 'transparent',
        cursor: 'pointer',
        'svg path': {
          fill: 'brand.orange',
        },
        _focus: {
          bg: 'transparent',
        },
        _active: {
          bg: 'transparent',
        },
        _hover: {
          bg: 'transparent',
        },
      }}
    />
  );
};
