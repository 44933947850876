import { FC } from 'types';
import { Fragment } from 'react';
import { Box, Flex, useBoolean } from '@chakra-ui/react';
import { Button } from 'appkit-react';
import { useTranslation } from 'react-i18next';
import { updateModel } from '@datx/core';

import { TextEditor } from 'components/core/TextEditor/TextEditor';
import { ModalFooter } from 'components/modal/Modal/components/ModalFooter';
import { Campaign } from 'models/Campaign';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { InitiateCampaignTabsValueType } from 'components/campaign/CampaignModalTabs/CampaignModalTabs';
import { CampaignStatus } from 'typings/enums/CampaignStatus';
import { useModal } from 'hooks/useModal';
import { AlertModal } from 'components/modal/AlertModal/AlertModal';
import { ParseAndPopAlertFail } from 'services/ParseAndPopAlertFail';
import { IAssessmentSetupFormValues } from 'typings/interfaces/formValues/IAssessmentSetupFormValues';
import { CampaignResponseActivationStatus } from 'typings/enums/CampaignResponseActivationStatus';

import { ICampaignSettingsFormProps } from '../CampaignSettingsForm/CampaignSettingsForm';
import { isApiClientError } from 'utils/isApiClientError';
import { isApiFormError } from 'utils/isApiFormError';
import { parseApiFormError } from 'utils/parseApiFormError';

interface IValidationAndMessageFormProps {
  campaign: Campaign;
  onCancel(): void;
  onDelete(): Promise<void>;
  onSave(data?: Record<string, unknown>): Promise<void>;
  setSelectedTab(tab: InitiateCampaignTabsValueType): void;
}

export const ValidationAndMessageForm: FC<IValidationAndMessageFormProps> = ({
  campaign,
  onSave,
  onCancel,
  onDelete,
  setSelectedTab,
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useModal();
  const [isLoading, setIsLoading] = useBoolean();
  const [isDeleting, setIsDeleting] = useBoolean();

  const handleChange = (notificationText: string) => {
    updateModel(campaign, {
      notificationText,
    });
  };

  const handleSave = async () => {
    setIsLoading.on();
    try {
      await onSave();
      PopAlertSuccess(t('campaign.save.success'));
    } catch (error: any) {
      // eslint-disable-line
      if (isApiFormError(error)) {
        const fieldErrors = parseApiFormError<
          ICampaignSettingsFormProps & IAssessmentSetupFormValues
        >(error);
        PopAlertFail(
          t('campaign.initiate.fail'),
          fieldErrors.map((e) => e.message),
        );
      } else if (isApiClientError(error)) {
        ParseAndPopAlertFail(error, t);
      } else {
        PopAlertFail(t('campaign.initiate.fail'));
      }
      throw error;
    } finally {
      setIsLoading.off();
    }
  };

  const handleInitiate = async () => {
    if (!campaign.isValidForInitialization) {
      onOpen();
      return;
    }
    try {
      updateModel(campaign, { campaignStatus: CampaignStatus.Initiated });
      await handleSave();
      PopAlertSuccess(t('campaign.initiate.success'));
      onCancel();
    } catch {
      return;
    }
  };

  const handleDelete = async () => {
    setIsDeleting.on();
    await onDelete();
    setIsDeleting.off();
  };

  return (
    <Flex flexDirection="column" minH="600px">
      <Box flex="2">
        <TextEditor initialHtmlContent={campaign.notificationText} onChange={handleChange} />
      </Box>
      <ModalFooter w="100%" p={0} mt={48}>
        <Button
          size="lg"
          negative
          className="a-mr-20"
          isLoading={isDeleting}
          disabled={isLoading || isDeleting}
          onClick={handleDelete}
        >
          {t('action.delete.label').toUpperCase()}
        </Button>
        <Flex ml="auto" alignItems="center">
          <Button
            size="lg"
            kind="secondary"
            type="button"
            className="a-mr-20"
            disabled={isLoading || isDeleting}
            onClick={() => setSelectedTab(InitiateCampaignTabsValueType.AssessmentSetup)}
          >
            {t('action.back.label').toUpperCase()}
          </Button>
          <Button
            size="lg"
            type="button"
            disabled={isLoading || isDeleting}
            onClick={handleSave}
            isLoading={isLoading}
            className="a-mr-20"
          >
            {t('action.save.label').toUpperCase()}
          </Button>
          {campaign.activationStatus !== CampaignResponseActivationStatus.Deactivated &&
            campaign.campaignStatus !== CampaignStatus.Initiated && (
              <Fragment>
                <Button
                  size="lg"
                  type="button"
                  disabled={isLoading || isDeleting}
                  isLoading={isLoading}
                  onClick={handleInitiate}
                >
                  {t('action.initiate.label').toUpperCase()}
                </Button>
                {isOpen && (
                  <AlertModal
                    title={t('campaign.validation.title')}
                    maxWidth="700px"
                    isOpen
                    onClose={onClose}
                  >
                    {campaign.isNameValid || t('campaign.validation.name')}
                    {campaign.areInitiatorsValid || t('campaign.validation.initiators')}
                    {campaign.isMissingRecipients && t('campaign.validation.noRecipients')}
                    {campaign.areRecipientsValid || t('campaign.validation.recipients')}
                  </AlertModal>
                )}
              </Fragment>
            )}
        </Flex>
      </ModalFooter>
    </Flex>
  );
};
