import { Attribute } from '@datx/core';
import { computed } from 'mobx';

import { FormComponent } from 'models/FormComponent';
import { AssessmentComponent } from 'models/AssessmentComponent';
import { FormAddonType } from 'typings/enums/FormAddonType';
import { FormComponentType } from 'typings/enums/FormComponentType';
import {
  getFieldValues,
  getResultFromFieldValue,
  isResultValid,
} from 'services/assessmentFormComponent';
import {
  ConditionAddonSettings,
  ConditionRuleGroup,
  ScaleSettings,
  ScaleType,
  SkipConditionAddonSettings,
} from 'typings/types/FormComponentSettings';
import { ConditionOperator } from 'typings/enums/ConditionOperator';
import { AssessmentFormValues } from 'typings/types/AssessmentFormValues';
import { IAssessmentComponentDto } from 'typings/interfaces/dto/IAssessmentComponentDto';
import { IAssessmentBase } from 'typings/interfaces/IAssessmentBase';

import { Assessment } from './Assessment';
import { AssessmentTest } from './AssessmentTest';

export class AssessmentFormComponent extends FormComponent {
  public static type = 'assessment-form-components';

  @Attribute()
  public readonly indexInAssessment!: number;

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute({
    toOne: AssessmentComponent,
  })
  public assessmentComponent!: AssessmentComponent;

  @Attribute({
    map: 'parentComponentId',
    toOne: 'assessment-form-components',
  })
  public parent!: AssessmentFormComponent | null;

  @Attribute()
  public assessmentId!: string;

  @computed
  public get assessment(): IAssessmentBase | undefined {
    const assessment = this.meta.collection?.findOne<Assessment>(Assessment, this.assessmentId);
    if (assessment) {
      return assessment;
    }

    const testAssessment = this.meta.collection?.findOne<AssessmentTest>(
      AssessmentTest,
      this.assessmentId,
    );
    if (testAssessment) {
      return testAssessment;
    }

    return undefined;
  }

  @Attribute()
  public formComponentId!: string;

  @computed
  public get isBookmarked(): boolean {
    return this.assessmentComponent?.isBookmarked ?? false;
  }

  @computed
  public get isRequired(): boolean {
    if (this.componentType === FormComponentType.Statement || this.componentType === FormComponentType.QuestionSet)
    {
      // Statement and QuestionSet do not have answer so it is never mandatory
      return false;
    }
    
    return Boolean(this.addons?.find((addon) => addon.addonType === FormAddonType.Mandatory));
  }

  @computed
  public get hasHelp(): boolean {
    return Boolean(this.addons?.find((addon) => addon.addonType === FormAddonType.HelpText));
  }

  @computed
  public get canAttach(): boolean {
    return Boolean(this.addons?.find((addon) => addon.addonType === FormAddonType.Attachment));
  }

  @computed
  public get canHaveMoreAnswers(): boolean {
    return Boolean(this.addons?.find((addon) => addon.addonType === FormAddonType.MoreAnswers));
  }

  @computed
  public get conditionGroup(): ConditionRuleGroup | undefined {
    const conditionAddon = this.addons.find((addon) => addon.addonType === FormAddonType.Condition);
    if (!conditionAddon) {
      return undefined;
    }

    if (!conditionAddon.settingsJson) {
      return undefined;
    }

    const parsedSettings = JSON.parse(conditionAddon.settingsJson) as ConditionAddonSettings;
    if (!parsedSettings) {
      return undefined;
    }

    return parsedSettings.conditionRuleGroup;
  }

  @computed
  public get skipTo(): string | undefined {
    const result = this.assessmentComponent?.result;
    if (!result || !result.length) {
      return undefined;
    }
    const skip = this.addons.find((addon) => addon.addonType === FormAddonType.SkipCondition);
    if (!skip) {
      return undefined;
    }

    if (!skip.settingsJson) {
      return undefined;
    }
    const parsedSettings = JSON.parse(skip.settingsJson) as SkipConditionAddonSettings;
    if (!parsedSettings) {
      return undefined;
    }

    const logic = parsedSettings.skipConditions.find((skipLogic) => {
      // this is OK because skip condition can't be added to Name question:
      // Business rule: Skip logic can only be used for single choice, confirmation and scale.
      return skipLogic.operator === ConditionOperator.Is && skipLogic.stringValue === result[0];
    });

    return logic?.skipToQuestionId;
  }

  @computed
  public get predecessorIds(): Array<string> {
    if (!this.parentComponentId) {
      return [];
    }
    return [...(this.parent?.predecessorIds ?? []), this.parentComponentId];
  }

  @computed
  public get updateAssessmentComponentDTO(): IAssessmentComponentDto | null {
    if (this.componentType === FormComponentType.Section) {
      return null;
    }

    if (this.isQuestionSet) {
      return {
        id: this.assessmentComponent.id,
        componentId: this.formComponentId,
        assessmentId: this.assessmentId,
        isBookmarked: this.isBookmarked,
      };
    }

    return {
      id: this.assessmentComponent.id,
      componentId: this.formComponentId,
      assessmentId: this.assessmentId,
      isBookmarked: this.isBookmarked,
      result: this.assessmentComponent.result,
      attachments: this.assessmentComponent.attachments,
    };
  }

  @computed
  public get result(): Array<string> | null {
    return this.assessmentComponent.result;
  }

  @computed
  public get isValid(): boolean {
    return isResultValid(this);
  }

  public getFieldValues() {
    return getFieldValues(this);
  }

  public getResultFromFieldValue(formFieldValue: Array<Record<string, unknown>>) {
    return getResultFromFieldValue(this, formFieldValue);
  }

  public fieldName(index = 0): string {
    if (
      this.componentType === FormComponentType.ScaleNumber &&
      (this.parsedSettings as ScaleSettings)?.scaleType === ScaleType.Range
    ) {
      return `${this.id}.${index}` as const;
    }

    if (this.componentType === FormComponentType.Name) {
      return `${this.id}.${index}` as const;
    }

    return `${this.id}.${index}.${this.componentType}` as const;
  }

  public isResultChanged(result: Array<string | null> | null): boolean {
    return result?.toString() !== this.result?.toString();
  }

  // returns true if result is changed, otherwise returns false
  public updateResult(result: Array<string | null> | null): boolean {
    if (!this.isResultChanged(result)) {
      return false;
    }
    this.assessmentComponent.update({ result });

    return true;
  }

  // returns true if result is changed, otherwise returns false
  public updateAnswer(formValues: AssessmentFormValues): boolean {
    const values = formValues[this.id];
    const result = this.getResultFromFieldValue(values);

    return this.updateResult(result);
  }
}
