import { Flex, Grid, HStack, Image } from '@chakra-ui/react';
import { Fragment, useRef, useState } from 'react';
import { useClickAway, useToggle } from 'react-use';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';

import { InputField } from 'fields/InputField/InputField';
import { useTenant } from 'hooks/useTenant';
import { Avatar } from 'components/core/Avatar/Avatar';

import { UserDropdown } from './UserDropdown';
import { SiteSelectModal } from '../../../SiteSelectModal/SiteSelectModal';

export const Header = () => {
  const ref = useRef(null);

  const { userData } = useAuth();

  const { activeTenant } = useTenant();

  const { t } = useTranslation();

  const [isDropdownVisible, setIsDropdownVisible] = useToggle(false);
  const [isSiteModalVisible, setIsSiteModalVisible] = useState(false);

  useClickAway(ref, () => {
    if (isDropdownVisible) {
      setIsDropdownVisible();
    }
  });

  return (
    <Fragment>
      <Flex
        sx={{
          pr: '20px',
          bg: 'grey.lvl3',
          color: 'white',
          height: '100%',
          zIndex: '100',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Image
          src="/assets/PwC-logo.svg"
          alt={t('header.imageAlt')}
          width="360px"
          height="30px"
          ml={20}
        />
        <Flex flex="2" justify="flex-end" sx={{ '.a-select-wrapper': { width: '200px' } }}>
          <InputField
            name="site"
            value={activeTenant?.siteName || ''}
            onClick={() => setIsSiteModalVisible(true)}
            placeholder={
              !activeTenant
                ? t('header.activeTenantInput.placeholder.noSitesAvailable')
                : t('header.activeTenantInput.placeholder.noSiteSelected')
            }
            disabled={!activeTenant}
            readOnly
          />
          <HStack spacing="20px" ml="20px">
            <Grid position="relative" placeItems="center" ref={ref} onClick={setIsDropdownVisible}>
              <Avatar width="24px" height="24px" cursor="pointer" name={userData?.profile.name} />
              <UserDropdown isVisible={isDropdownVisible} />
            </Grid>
          </HStack>
        </Flex>
      </Flex>
      {isSiteModalVisible && (
        <SiteSelectModal isVisible onCancel={() => setIsSiteModalVisible(false)} />
      )}
    </Fragment>
  );
};
