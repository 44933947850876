import { Attribute } from '@datx/core';
import { computed } from 'mobx';

import { FolderUserRole } from 'typings/enums/FolderUserRole';
import { UpsertRoomFolderUserDTO } from 'typings/types/dto/RoomFolderDTO';

import { Resource } from './Resource';
import { RoomUser } from './RoomUser';

export class RoomFolderUser extends Resource {
  public static type = 'room_folders_users';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public userId!: string;

  @Attribute({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parse: (_, data: Record<string, any>) => data.role,
  })
  public folderUserRole!: FolderUserRole;

  @Attribute()
  public expirationDate!: string;

  @computed
  public get user() {
    return this.meta.collection?.findAll(RoomUser).find((user) => user.userId === this.userId);
  }

  @computed
  public get username() {
    if (this.user) {
      return this.user.username;
    }
    return undefined;
  }

  @computed
  public get email() {
    if (this.user) {
      return this.user.email;
    }
    return undefined;
  }

  @computed
  public get displayName() {
    if (this.user) {
      return this.user.username === this.user.email
        ? this.user.email
        : `${this.user.username} (${this.user.email})`;
    }

    return undefined;
  }

  @computed
  public get upsertDTO(): UpsertRoomFolderUserDTO {
    return {
      id: this.userId,
      role: this.folderUserRole,
    };
  }
}
