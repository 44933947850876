import { Box, HStack } from '@chakra-ui/layout';
import { Fragment, useRef } from 'react';
import { FC } from 'types'
import { useTranslation } from 'react-i18next';
import { useClickAway, useToggle } from 'react-use';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { RoomsCardSort } from 'typings/enums/RoomsCardSort';
import { RoomsViewMode } from 'typings/enums/RoomsViewMode';

import { RoomsCardViewSortMenu } from '../RoomsCardViewSortMenu/RoomsCardViewSortMenu';

interface IRoomsToggleButtonsProps {
  viewMode: RoomsViewMode;
  onModeChange: (viewMode: RoomsViewMode) => void;
  onSortChange: (viewMode: RoomsCardSort) => void;
}
export const RoomsToggleButtons: FC<IRoomsToggleButtonsProps> = ({
  viewMode = RoomsViewMode.Cards,
  onModeChange,
  onSortChange,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isSortMenuVisible, setIsSortMenuVisible] = useToggle(false);
  useClickAway(ref, () => {
    if (isSortMenuVisible) {
      setIsSortMenuVisible();
    }
  });

  return (
    <HStack spacing="0" position="relative">
      {viewMode === RoomsViewMode.Cards && (
        <Fragment>
          <Box ref={ref}>
            <StyledButton
              kind="secondary"
              size="lg"
              onClick={() => {
                setIsSortMenuVisible();
              }}
            >
              {t('rooms.toggleButtons.sort')}
            </StyledButton>
            <Box
              sx={{
                position: 'absolute',
                top: '34px',
                zIndex: 900,
              }}
            >
              <RoomsCardViewSortMenu
                isVisible={isSortMenuVisible}
                onSortClick={(_, value) => onSortChange && onSortChange(value)}
              />
            </Box>
          </Box>
        </Fragment>
      )}
      {(viewMode === RoomsViewMode.Cards || viewMode === RoomsViewMode.Trash) && (
        <StyledButton
          kind={viewMode === RoomsViewMode.Cards ? 'primary' : 'secondary'}
          size="lg"
          onClick={() => onModeChange(RoomsViewMode.List)}
        >
          {t('rooms.toggleButtons.list')}
        </StyledButton>
      )}
      {viewMode === RoomsViewMode.List && (
        <StyledButton
          kind={viewMode === RoomsViewMode.List ? 'primary' : 'secondary'}
          size="lg"
          onClick={() => onModeChange(RoomsViewMode.Cards)}
        >
          {t('rooms.toggleButtons.cards')}
        </StyledButton>
      )}
      <StyledButton
        kind={viewMode === RoomsViewMode.Trash ? 'primary' : 'secondary'}
        size="lg"
        onClick={() => onModeChange(RoomsViewMode.Trash)}
      >
        {t('rooms.toggleButtons.trash')}
      </StyledButton>
    </HStack>
  );
};
