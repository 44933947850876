import { Attribute } from '@datx/core';
import { computed } from 'mobx';

import { Resource } from './Resource';
import { TrashItem } from './TrashItem';

export class Trash extends Resource {
  public static type = 'trash';

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute({ toMany: TrashItem })
  public forms!: Array<TrashItem>;

  @Attribute({ toMany: TrashItem })
  public roomFolders!: Array<TrashItem>;

  @Attribute({ toMany: TrashItem })
  public assessments!: Array<TrashItem>;

  @Attribute({ toMany: TrashItem })
  public campaigns!: Array<TrashItem>;

  @computed
  public get items() {
    return [...this.forms, ...this.roomFolders, ...this.assessments, ...this.campaigns];
  }
}
