import { Assessment } from 'models/Assessment';
import { AppStore } from 'store/AppStore';
import { User } from 'models/User';
import { IPermissionGroupDto } from 'typings/interfaces/dto/IPermissionGroupDto';

export const getPermittedComponents = (
  datx: AppStore,
  assessmentId: string | undefined,
  user: User | null,
) => {
  if (!user || !assessmentId) {
    return [];
  }

  const assessment = datx.findOne<Assessment>(Assessment, assessmentId);
  const componentIds = getComponentsForUser(assessment?.permissionGroups ?? [], user);
  return componentIds;
};

const getComponentsForUser = (permissionGroups: Array<IPermissionGroupDto>, user: User) => {
  return permissionGroups
    .filter((pg) => pg.recipientIds?.includes(user.id))
    .flatMap((pg) => pg.componentIds);
};
