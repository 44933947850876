import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { FormComponent } from 'models/FormComponent';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { apiClient } from 'utils/apiClient';
import { PopAlertFail } from 'services/PopAlertFail';
import { useDatx } from 'hooks/useDatx';
import { IComponentMutationOptions } from 'typings/interfaces/IComponentMutationOptions';
import { parseFormComponentsDto } from 'services/formComponents/parseFormComponentsDto';
import {
  getOrderAfterInsert,
  getOrderAfterRemove,
  IComponentToShift,
} from 'services/formComponents/getComponentOrderChanges';

export const useFormsComponents = (roomId: string, formId: string) => {
  const dependencyHeaders = useDependencyHeaders();
  const datx = useDatx();

  const siteId = dependencyHeaders['x-site-id'];
  const key = siteId ? `forms-${formId}-components@${siteId}` : null;

  const swr = useSWR(
    formId ? key : null,
    async () => {
      const response = await apiClient(
        `rooms/${roomId}/forms/${formId}/components`,
        HttpMethod.Get,
        {
          headers: {
            ...dependencyHeaders,
          },
        },
      );

      const components: Array<FormComponent> = datx.add(
        parseFormComponentsDto(response),
        FormComponent,
      );

      return components;
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  return {
    isInitialLoad: !swr.data && !swr.error,
    ...swr,
  };
};

export const useFormsComponentsWithMutate = (
  roomId: string,
  formId: string,
  setLoading: (isLoading: boolean) => void,
) => {
  const { t } = useTranslation();
  const dependencyHeaders = useDependencyHeaders();
  const datx = useDatx();

  const siteId = dependencyHeaders['x-site-id'];
  const key = siteId ? `forms-${formId}-components@${siteId}` : null;

  const swr = useSWR(
    formId ? key : null,
    async () => {
      const response = await apiClient(
        `rooms/${roomId}/forms/${formId}/components`,
        HttpMethod.Get,
        {
          headers: {
            ...dependencyHeaders,
          },
        },
      );

      const components: Array<FormComponent> = datx.add(
        parseFormComponentsDto(response),
        FormComponent,
      );

      const storedIds = datx
        .findAll<FormComponent>(FormComponent)
        .map((formComponent) => formComponent.id);
      storedIds.forEach((id) => {
        if (components.some((component) => component.id === id)) {
          return;
        }

        datx.removeOne(FormComponent, id);
      });

      return components;
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  const addComponent = async (formComponent: FormComponent) => {
    const url = `rooms/${roomId}/forms/${formId}/components`;
    const method = HttpMethod.Post;

    const componentsToShift = getOrderAfterInsert(datx, formComponent);

    setLoading(true);
    try {
      if (componentsToShift.length) {
        await apiClient(`${url}/reorder`, HttpMethod.Post, {
          headers: {
            'Content-Type': 'application/json',
            ...dependencyHeaders,
          },
          body: JSON.stringify({ componentsOrder: componentsToShift }),
        });
      }
      swr.mutate((current) => current?.concat(formComponent), false);
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify(formComponent.upsertDto),
      });
    } catch (response: any) { // eslint-disable-line
      const message = response.message || t('form.errors.components.add');
      PopAlertFail(t('alerts.fail.title'), message);
    } finally {
      await swr.mutate();
      setLoading(false);
    }
  };

  const reorderComponents = async (componentsToShift: Array<IComponentToShift>) => {
    const url = `rooms/${roomId}/forms/${formId}/components/reorder`;
    const method = HttpMethod.Post;

    setLoading(true);
    try {
      if (componentsToShift.length) {
        await apiClient(url, method, {
          headers: {
            'Content-Type': 'application/json',
            ...dependencyHeaders,
          },
          body: JSON.stringify({ componentsOrder: componentsToShift }),
        });
      }
    } catch (response: any) { // eslint-disable-line
      const message = response.message || t('form.errors.components.update');
      PopAlertFail(t('alerts.fail.title'), message);
    } finally {
      await swr.mutate();
      setLoading(false);
    }
  };

  const updateComponent = async (
    formComponent: FormComponent,
    options?: IComponentMutationOptions,
  ) => {
    try {
      setLoading(true);
      await updateFormComponent(formComponent, roomId, formId, dependencyHeaders);
      await swr.mutate();
    } catch (response: any) { // eslint-disable-line
      if (options?.displayErrorNotification) {
        const message = response.message || t('form.errors.components.update');
        PopAlertFail(t('alerts.fail.title'), message);
      }

      throw response;
    } finally {
      setLoading(false);
    }
  };

  const removeComponent = async (formComponent: FormComponent) => {
    const url = `rooms/${roomId}/forms/${formId}/components/${formComponent.id}`;
    const method = HttpMethod.Delete;

    const componentsToShiftBack = getOrderAfterRemove(datx, formComponent);

    try {
      setLoading(true);
      swr.mutate((current) => current?.filter((x) => formComponent.id !== x.id), false);
      await apiClient(url, method, {
        headers: {
          'Content-Type': 'application/json',
          ...dependencyHeaders,
        },
      });
      try {
        if (componentsToShiftBack.length) {
          await apiClient(`rooms/${roomId}/forms/${formId}/components/reorder`, HttpMethod.Post, {
            headers: {
              'Content-Type': 'application/json',
              ...dependencyHeaders,
            },
            body: JSON.stringify({ componentsOrder: componentsToShiftBack }),
          });
        }
        // eslint-disable-next-line no-empty
      } catch {}
    } catch (response: any) { // eslint-disable-line
      const message = response.message || t('form.errors.components.remove');
      PopAlertFail(t('alerts.fail.title'), message);
    } finally {
      await swr.mutate();
      setLoading(false);
    }
  };

  return {
    addComponent,
    reorderComponents,
    updateComponent,
    removeComponent,
    isInitialLoad: !swr.data && !swr.error,
    ...swr,
  };
};

async function updateFormComponent(
  formComponent: FormComponent,
  roomId: string,
  formId: string,
  dependencyHeaders: Record<string, string | undefined>,
) {
  const url = `rooms/${roomId}/forms/${formId}/components/${formComponent.id}`;
  const method = HttpMethod.Put;
  return await apiClient(url, method, {
    headers: {
      'Content-Type': 'application/json',
      ...dependencyHeaders,
    },
    body: JSON.stringify(formComponent.upsertDto),
  });
}
