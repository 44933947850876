import { FC } from 'types';
import { ValidateResult } from 'react-hook-form';
import { Box } from '@chakra-ui/react';

interface IFieldErrorTextProps {
  children: ValidateResult;
}
export const FieldErrorText: FC<IFieldErrorTextProps> = ({ children }) => (
  <Box as="span" display="block" className="a-form-error">
    {children}
  </Box>
);
