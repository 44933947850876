import { FormComponent } from 'models/FormComponent';
import { AppStore } from 'store/AppStore';

export interface IComponentToShift {
  id: string;
  parentComponentId: string | null;
  index: number;
  text?: string;
}

const areComponentsOnTheSameLevel = (a: FormComponent, b: FormComponent): boolean => {
  return (
    a.formId === b.formId &&
    ((a.isSection && b.isSection) || a.parentComponentId === b.parentComponentId)
  );
};

export const getOrderAfterInsert = (
  datx: AppStore,
  formComponent: FormComponent,
): Array<IComponentToShift> =>
  datx
    .findAll(FormComponent)
    .reduce((newOrder: Array<IComponentToShift>, component: FormComponent) => {
      if (
        !component.isPlaceholder &&
        component.index >= formComponent.index &&
        component.id !== formComponent.id &&
        areComponentsOnTheSameLevel(formComponent, component)
      ) {
        newOrder.push({
          id: component.id,
          parentComponentId: component.parentComponentId,
          index: component.index + 1,
          text: component.text,
        });
      }

      return newOrder;
    }, []);

export const getOrderAfterRemove = (
  datx: AppStore,
  formComponent: FormComponent,
): Array<IComponentToShift> =>
  datx
    .findAll(FormComponent)
    .reduce((newOrder: Array<IComponentToShift>, component: FormComponent) => {
      if (
        component.index > formComponent.index &&
        areComponentsOnTheSameLevel(formComponent, component)
      ) {
        newOrder.push({
          id: component.id,
          parentComponentId: component.parentComponentId,
          index: component.index - 1,
          text: component.text,
        });
      }
      return newOrder;
    }, []);

export const getOrderAfterMove = (
  datx: AppStore,
  formComponent: FormComponent,
  oldIndex: number,
  oldParentId: string | null = null,
): Array<IComponentToShift> =>
  formComponent.isSection || formComponent.parentComponentId === oldParentId
    ? getOrderAfterMoveSameParent(datx, formComponent, oldIndex)
    : getOrderAfterMoveQuestionDifferentParent(datx, formComponent, oldIndex, oldParentId);

const getOrderAfterMoveSameParent = (
  datx: AppStore,
  formComponent: FormComponent,
  oldIndex: number,
): Array<IComponentToShift> => {
  const shift = formComponent.index > oldIndex ? -1 : 1;
  const from = Math.min(formComponent.index, oldIndex);
  const to = Math.max(formComponent.index, oldIndex);

  return datx
    .findAll(FormComponent)
    .reduce((newOrder: Array<IComponentToShift>, component: FormComponent) => {
      if (
        areComponentsOnTheSameLevel(formComponent, component) &&
        ((shift === 1 && component.index >= from && component.index < to) ||
          (shift === -1 && component.index > from && component.index <= to))
      ) {
        newOrder.push({
          id: component.id,
          parentComponentId: component.parentComponentId,
          index: component.id === formComponent.id ? component.index : component.index + shift,
          text: component.text,
        });
      }
      return newOrder;
    }, []);
};

const getOrderAfterMoveQuestionDifferentParent = (
  datx: AppStore,
  formComponent: FormComponent,
  oldIndex: number,
  oldParentId: string | null,
): Array<IComponentToShift> => {
  const shiftForward = getOrderAfterInsert(datx, formComponent);

  const shiftBack = datx
    .findAll(FormComponent)
    .reduce((newOrder: Array<IComponentToShift>, component: FormComponent) => {
      if (
        formComponent.formId === component.formId &&
        oldParentId === component.parentComponentId &&
        component.index > oldIndex
      ) {
        newOrder.push({
          id: component.id,
          parentComponentId: component.parentComponentId,
          index: component.index - 1,
          text: component.text,
        });
      }
      return newOrder;
    }, []);

  return [
    ...shiftForward,
    ...shiftBack,
    {
      id: formComponent.id,
      parentComponentId: formComponent.parentComponentId,
      index: formComponent.index,
      text: formComponent.text,
    },
  ];
};
