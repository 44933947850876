import { FC } from 'types';
import { Control, useWatch } from 'react-hook-form';
import { Box, Tr, Td, HStack, Text, Center } from '@chakra-ui/react';

import { Avatar } from 'components/core/Avatar/Avatar';
import { IFormUser } from 'typings/interfaces/IFormUser';
import { IFormUserManagementFields } from 'typings/interfaces/IFormUserManagementFields';
import { FormUserRole } from 'typings/enums/FormUserRole';

import { FormUserDefaultInitiatorButtonGroupField } from '../FormUserDefaultInitiatorButtonGroupField/FormUserDefaultInitiatorButtonGroupField';
import { FormUserRoleButtonGroupField } from '../FormUserRoleButtonGroupField/FormUserRoleButtonGroupField';

interface IFormUserManagementRowProps {
  user: IFormUser;
  index: number;
  control: Control<IFormUserManagementFields>;
  onRemove(): void;
}
export const FormUserManagementRow: FC<IFormUserManagementRowProps> = ({
  user,
  index,
  control,
  onRemove,
}) => {
  const watchUsers = useWatch({ control, name: 'users' }); // use conditional field since useFieldArray fields is not updated on each nested field change
  const numberOfEditors = watchUsers.reduce(
    (acc, user) => (user.role === FormUserRole.Edit ? acc + 1 : acc),
    0,
  );
  const isLastEditor = numberOfEditors === 1 && watchUsers[index].role === FormUserRole.Edit;

  return (
    <Tr key={user.userId}>
      <HStack as={Td}>
        <Avatar name={user.username} />
        <Text overflowWrap="anywhere">{user.displayName ?? user.username}</Text>
      </HStack>
      <Td>
        <FormUserRoleButtonGroupField
          isLastEditor={isLastEditor}
          control={control}
          name={`users.${index}.role` as const}
        />
      </Td>
      <Td>
        <FormUserDefaultInitiatorButtonGroupField
          control={control}
          name={`users.${index}.defaultInitiatorStatus` as const}
        />
      </Td>
      <Center as={Td}>
        <Box
          as="span"
          className="appkiticon icon-circle-delete-outline a-font-24 a-text-orange"
          cursor="pointer"
          onClick={!isLastEditor ? onRemove : undefined}
        />
      </Center>
    </Tr>
  );
};
