import isError from 'lodash/isError';
import { TFunction } from 'i18next';

import { ParsedApiClientError } from 'typings/types/ParsedApiClientError';
import { IApiClientError } from 'typings/interfaces/IApiClientError';
import { isApiResponseError } from './isApiResponseError';

export function parseApiClientError(
  apiClientError: IApiClientError | undefined,
  t: TFunction,
): ParsedApiClientError | undefined {
  if (!apiClientError) {
    return undefined;
  }

  if (isError(apiClientError)) {
    return {
      message: `${t('alerts.fails.unexpected')}: ${apiClientError.name}`,
      errors: [apiClientError.message],
    };
  }

  if (isApiResponseError(apiClientError)) {
    let errors;
    if (apiClientError.errors?.keys?.length) {
      errors = Object.entries(apiClientError.errors).map(
        ([key, value]) => `${key}: ${value.join(', ')}`,
      );
    }

    if (Array.isArray(apiClientError.errors) && apiClientError.errors?.length) {
      errors = apiClientError.errors;
    }

    return { message: apiClientError.message, errors };
  }

  return {
    message: t('alerts.fails.unknown'),
  };
}
