import { useTranslation } from 'react-i18next';

import { StyledBadge } from 'components/core/StyledAppKit/StyledAppKit';

export const MandatoryAddonBadge = () => {
  const { t } = useTranslation();
  return (
    <StyledBadge type="primary" size="small">
      {t('assessment.form.addon.mandatory').toUpperCase()}
    </StyledBadge>
  );
};
