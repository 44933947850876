import { FormControl, FormControlProps, Text } from '@chakra-ui/react';
import { ReactTagInputProps } from '@pathofdev/react-tag-input';
import React from 'react';
import { FC } from 'types'

import { TagInput } from 'components/core/TagInput/TagInput';
import { IFieldProps } from 'typings/interfaces/IFieldProps';
import { FormFieldLabel } from 'components/core/FormFieldLabel/FormFieldLabel';

interface ITagInputFieldProps
  extends IFieldProps,
    Omit<FormControlProps, 'onChange' | 'defaultValue'>,
    ReactTagInputProps {
  defaultValue?: string;
  tags: Array<string>;
  onChange(value: Array<string>): void;
}

export const TagInputField: FC<ITagInputFieldProps> = ({
  errors,
  tags,
  validator,
  onChange,
  placeholder,
  label,
  htmlFor,
  sx,
  ...rest
}) => {
  return (
    <FormControl
      aria-invalid={Boolean(errors)}
      sx={{
        '&[aria-invalid="true"]': {
          '.react-tag-input': {
            boxShadow: (theme) => `0 0 0 0.125rem ${theme.colors.brand.red}`,
            border: 'none',
          },
        },
        ...sx,
      }}
      {...rest}
    >
      <FormFieldLabel htmlFor={htmlFor}>{label}</FormFieldLabel>
      <TagInput tags={tags} placeholder={placeholder} validator={validator} onChange={onChange} />
      {errors && <Text className="a-form-error">{errors.message}</Text>}
    </FormControl>
  );
};
