import { FC } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';

import { ConfirmationModal } from 'components/modal/ConfirmationModal/ConfirmationModal';
import { ApprovalEntity } from 'typings/enums/ApprovalEntity';

interface IReviewRejectWarningModalProps {
  entityType: ApprovalEntity;
  onConfirm(): Promise<void>;
  onClose(): void;
}
export const ReviewRejectWarningModal: FC<IReviewRejectWarningModalProps> = ({
  entityType,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleClickConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
    onClose();
  };

  return (
    <ConfirmationModal
      maxWidth="800px"
      title={t('review.reject.modal.title', { entity: t(`approval.entity.${entityType}`) })}
      confirmText={t('review.reject.modal.confirm')}
      cancelText={t('review.reject.modal.cancel')}
      showLoader={isLoading}
      onCancel={onClose}
      onConfirm={handleClickConfirm}
    >
      <Text size="lg" my={40}>
        {t('review.reject.modal.warning')}
      </Text>
    </ConfirmationModal>
  );
};
