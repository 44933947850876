export const Table = {
  parts: ['tbody', 'thead', 'tr', 'th', 'td'],

  sizes: {
    sm: {
      thead: {
        'tr, td, th': {
          py: 0,
          px: 12,
          height: '34px',
          fontSize: 14,
          fontWeight: 'normal',
        },
      },
      tbody: {
        'tr, td, th': {
          py: 0,
          px: 12,
          height: '34px',
          fontSize: 14,
          fontWeight: 'normal',
        },
      },
    },
    md: {
      thead: {
        'tr, td, th': {
          py: 0,
          px: 0,
          height: '30px',
          fontSize: 14,
          fontWeight: 'normal',
        },
      },
      tbody: {
        'tr, td, th': {
          py: 0,
          px: 0,
          minHeight: '60px',
          fontSize: 14,
          fontWeight: 'normal',
        },
      },
    },
  },
  variants: {
    list: {
      tr: {
        borderBottom: `2px solid`,
        borderColor: 'grey.lvl3',
        'td, th': {
          pr: 20,
          textTransform: 'none',
          color: 'white',
          borderBottom: 'none',
          verticalAlign: 'middle',
        },
      },
    },
    dragTable: {
      thead: {
        bgColor: 'grey.lvl3',
      },
      tbody: {
        tr: {
          '&:nth-of-type(odd)': {
            bgColor: 'grey.lvl6',
          },
          '&:nth-of-type(even)': {
            bgColor: 'grey.lvl5',
          },
        },
      },
      tr: {
        'td, th': {
          textTransform: 'none',
          color: 'white',
          borderBottom: 'none',
          verticalAlign: 'middle',
        },
      },
    },
  },
};
