import { uniq } from 'lodash';
import { FormComponent } from 'models/FormComponent';
import { AppStore } from 'store/AppStore';
import {
  ConditionRule,
  ConditionRuleGroup,
  SkipCondition,
} from 'typings/types/FormComponentSettings';

export const getConditionDependencies = (conditionRuleGroup: ConditionRuleGroup): Array<string> => {
  const dependencies: Set<string> = new Set<string>();

  const parseRuleDependencies = (conditionRules: Array<ConditionRule>) => {
    conditionRules.forEach((cr) => {
      dependencies.add(cr.componentId);
    });
  };

  const parseGroupDependencies = (conditionRuleGroup: ConditionRuleGroup) => {
    parseRuleDependencies(conditionRuleGroup.conditionRules);
    conditionRuleGroup.conditionRuleGroups.forEach((crg) => {
      parseGroupDependencies(crg);
    });
  };

  parseGroupDependencies(conditionRuleGroup);

  return Array.from(dependencies);
};

export const getSkipDependents = (skipConditions: Array<SkipCondition>): Array<string> =>
  uniq(skipConditions.map((skipCondition) => skipCondition.skipToQuestionId));

// returns object where keys are Form Component ids and values are Sets of form components Ids that are its dependencies
export const getSkipDependencies = (store: AppStore, formId: string) =>
  store.findAll(FormComponent).reduce((acc: Record<string, Set<string>>, fc) => {
    if (fc.formId !== formId) {
      return acc;
    }
    const skipConditions = fc.skipConditions;
    if (!skipConditions.length) {
      return acc;
    }

    skipConditions.forEach((skipCondition) => {
      if (acc[skipCondition.skipToQuestionId]) {
        acc[skipCondition.skipToQuestionId].add(fc.id);
      } else {
        acc[skipCondition.skipToQuestionId] = new Set<string>([fc.id]);
      }
    });
    return acc;
  }, {});
