import {
  JsonGroup,
  JsonRule,
  JsonRuleGroup,
  JsonRuleGroupExt,
  Utils as QbUtils,
} from 'react-awesome-query-builder';
import { AppStore } from 'store/AppStore';

import { FormComponent } from 'models/FormComponent';
import { ConditionRuleOperator } from 'typings/enums/ConditionRuleOperator';
import { ConditionType } from 'typings/enums/ConditionType';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { IConditionRule } from 'typings/interfaces/IConditionRule';
import { IConditionRuleGroup } from 'typings/interfaces/IConditionRuleGroup';
import { IFormConditionGroup } from 'typings/interfaces/IFormConditionGroup';
import { ILogicBuilderState } from 'typings/interfaces/ILogicBuilderState';

enum WidgetType {
  Statement = 'text',
  EmailAddress = 'text',
  FreeText = 'text',
  Name = 'text',
  Number = 'number',
  ScaleNumber = 'number',
  Confirmation = 'boolean',
  Date = 'date',
  Country = 'select_country',
  SingleChoice = 'select',
  MultipleChoice = 'select',
  ScaleText = 'select',
  QuestionSet = 'QuestionSet',
  Section = 'Section',
}

interface IRuleChildren {
  [id: string]: JsonRuleGroupExt | JsonRule | JsonRuleGroup | JsonGroup;
}

const selectFieldSettings = (component: FormComponent) => {
  const componentValues = JSON.parse(component.valueJson as string).map(
    (settings: Record<string, unknown>) => ({
      value: settings.value,
      title: settings.value,
    }),
  );

  // Allow empty answers in conditions
  const listValues = [{ value: '', title: '' }, ...componentValues];

  return {
    listValues,
  };
};

const countrySelectFieldSettings = (component: FormComponent) => {
  const formatType = JSON.parse(component.settingsJson as string).formatType;

  return {
    customProps: {
      formatType,
    },
  };
};

const dateFieldSettings = (component: FormComponent) => {
  return {
    dateFormat: (component.parsedSettings as Record<string, unknown>)?.dateFormat,
    valueFormat: (component.parsedSettings as Record<string, unknown>)?.dateFormat,
  };
};

const numberFieldSettings = (component: FormComponent) => {
  return {
    min: (component.parsedSettings as Record<string, unknown>)?.minimumScale ?? -Infinity,
    max: (component.parsedSettings as Record<string, unknown>)?.maximumScale ?? Infinity,
  };
};

const fieldSettings = (component: FormComponent) => {
  if (
    WidgetType[component.componentType] === WidgetType.SingleChoice ||
    WidgetType[component.componentType] === WidgetType.MultipleChoice
  ) {
    return selectFieldSettings(component);
  }

  if (WidgetType[component.componentType] === WidgetType.Date) {
    return dateFieldSettings(component);
  }

  if (WidgetType[component.componentType] === WidgetType.Number) {
    return numberFieldSettings(component);
  }

  if (WidgetType[component.componentType] === WidgetType.Country) {
    return countrySelectFieldSettings(component);
  }

  return {};
};

export const componentsToFields = (components: Array<FormComponent>) =>
  components.reduce(
    (previousValue, component) => ({
      ...previousValue,
      [component.id]: {
        label: `${component.orderTag}) ${component.text}`,
        type: WidgetType[component.componentType],
        valueSources: ['value'],
        fieldSettings: {
          ...fieldSettings(component),
        },
      },
    }),
    {},
  );

const stringifyValue = (value: unknown) => {
  if (typeof value === 'string') {
    return value;
  }

  return JSON.stringify(value);
};

export const stateToDto = ({ previewTree }: ILogicBuilderState) => {
  if (!previewTree) {
    return null;
  }

  if (previewTree && previewTree.children1) {
    const mapChildren = (children?: IRuleChildren): Array<IConditionRule & IConditionRuleGroup> => {
      if (!children) {
        return [];
      }

      return Object.keys(children).map((childId) => {
        const rule = children?.[childId];
        if (rule?.type === 'rule') {
          return {
            componentId: rule.properties.field as string,
            operator: rule.properties.operator as string,
            stringValue: stringifyValue(rule.properties.value[0]) ?? '',
            conditionType: ConditionType.LogicCondition,
          };
        } else if (rule?.type === 'group') {
          const transformedState = mapChildren(rule.children1);
          const conditionRuleGroups = transformedState.filter(
            (condition) => !condition.componentId,
          );
          const conditionRules = transformedState.filter((condition) =>
            Boolean(condition.componentId),
          );

          return {
            operator: rule.properties?.conjunction,
            conditionRules,
            conditionRuleGroups,
          };
        }

        return {};
      });
    };

    const transformedState = mapChildren(previewTree?.children1);
    const conditionRuleGroups = transformedState.filter((condition) => !condition.componentId);
    const conditionRules = transformedState.filter((condition) => Boolean(condition.componentId));

    return {
      operator: previewTree.properties?.conjunction,
      conditionRules,
      conditionRuleGroups,
    };
  }

  return null;
};

const getValueType = (componentId: string | undefined, store: AppStore) => {
  if (!componentId) {
    return '';
  }
  const component = store.findOne(FormComponent, componentId);
  if (!component) {
    return '';
  }

  switch (component.componentType) {
    case FormComponentType.FreeText:
    case FormComponentType.EmailAddress:
    case FormComponentType.Name:
    case FormComponentType.Statement:
      return 'text';

    case FormComponentType.MultipleChoice:
    case FormComponentType.SingleChoice:
    case FormComponentType.ScaleText:
    case FormComponentType.Country:
      return 'select';

    case FormComponentType.Number:
    case FormComponentType.ScaleNumber:
      return 'number';

    case FormComponentType.Confirmation:
      return 'boolean';

    case FormComponentType.Date:
      return 'date';

    default:
      return 'text';
  }
};

const parseValue = (
  store: AppStore,
  componentId: string | undefined,
  value: string | undefined = '',
) => {
  if (!componentId) {
    return null;
  }
  const component = store.findOne<FormComponent>(FormComponent, componentId);

  if (!component) {
    return null;
  }

  if (component.componentType === FormComponentType.Confirmation) {
    return JSON.parse(value);
  }

  if (
    component.componentType === FormComponentType.Number ||
    component.componentType === FormComponentType.ScaleNumber
  ) {
    return Number(value);
  }

  return value;
};

export const getInitialState = (store: AppStore, ruleGroup?: IFormConditionGroup) => {
  if (!ruleGroup) {
    return {
      id: QbUtils.uuid(),
      type: 'group',
      properties: {
        conjunction: ConditionRuleOperator.And,
      },
    };
  }

  const reduceRules = (rules: Array<IConditionRule>) => {
    return rules.reduce(
      (prevValue, currentValue) => ({
        ...prevValue,
        [QbUtils.uuid()]: {
          type: 'rule',
          properties: {
            field: currentValue.componentId,
            operator: currentValue.operator,
            value: [parseValue(store, currentValue.componentId, currentValue.stringValue)],
            valueSrc: ['value'],
            valueType: [getValueType(currentValue.componentId, store)],
          },
        },
      }),
      {},
    );
  };

  const reduceGroups = (ruleGroups: Array<IFormConditionGroup>): Record<string, unknown> => {
    return ruleGroups.reduce(
      (prevValue, currentValue) => ({
        ...prevValue,
        [QbUtils.uuid()]: {
          type: 'group',
          properties: { conjunction: currentValue.operator },
          children1: {
            ...reduceRules(currentValue.conditionRules),
            ...reduceGroups(currentValue.conditionRuleGroups),
          },
        },
      }),
      {},
    );
  };

  return {
    id: QbUtils.uuid(),
    type: 'group',
    properties: {
      conjunction: ruleGroup.operator,
    },
    children1: {
      ...reduceRules(ruleGroup.conditionRules),
      ...reduceGroups(ruleGroup.conditionRuleGroups),
    },
  };
};
