import { FC } from 'types';
import { Box, Center, HStack, Td, Text, Tr } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Control, useWatch } from 'react-hook-form';

import { Avatar } from 'components/core/Avatar/Avatar';
import { CampaignUserRole } from 'typings/enums/CampaignUserRole';
import { CampaignUserInitiatorStatus } from 'typings/enums/CampaignUserInitiatorStatus';
import { ICampaignInitiatorsFormData, ICampaignUser } from 'typings/interfaces/ICampaignFormData';

import { CampaignUserRoleGroupField } from '../CampaignUserRoleGroupField/CampaignUserRoleGroupField';

interface ICampaignInitiatorManagementRowProps {
  user: ICampaignUser;
  index: number;
  control: Control<ICampaignInitiatorsFormData>;
  onRemove(): void;
}
export const CampaignInitiatorManagementRow: FC<ICampaignInitiatorManagementRowProps> = ({
  user,
  index,
  control,
  onRemove,
}) => {
  const { t } = useTranslation();
  const watchUsers = useWatch({ control, name: 'initiators' }); // use conditional field since useFieldArray fields is not updated on each nested field change
  const numberOfInitiators = watchUsers.reduce(
    (acc: number, user: ICampaignUser) =>
      user.role === CampaignUserRole.Initiator ? acc + 1 : acc,
    0,
  );
  const isLastInitiator =
    numberOfInitiators === 1 && watchUsers[index].role === CampaignUserRole.Initiator;

  return (
    <Tr>
      <HStack as={Td}>
        <Avatar name={user.username} />
        <Text overflowWrap="anywhere">{user.displayName ?? user.username}</Text>
      </HStack>
      <Td>
        {(user.defaultInitiatorStatus === CampaignUserInitiatorStatus.Mandatory ||
          user.defaultInitiatorStatus === CampaignUserInitiatorStatus.Optional) && (
          <Center mr={20} py={4} bg="brand.orange">
            <Text size="sm">{t('campaign.initiators.default').toUpperCase()}</Text>
          </Center>
        )}
      </Td>
      <Td>
        <CampaignUserRoleGroupField
          isLastInitiator={isLastInitiator}
          control={control}
          name={`initiators.${index}.role`}
        />
      </Td>
      <Td>
        <Center>
          {user.defaultInitiatorStatus !== CampaignUserInitiatorStatus.Mandatory && (
            <Box
              as="span"
              className={`appkiticon icon-circle-delete-outline a-font-24 ${
                isLastInitiator ? '' : 'a-text-orange'
              }`}
              cursor="pointer"
              onClick={!isLastInitiator ? onRemove : undefined}
            />
          )}
        </Center>
      </Td>
    </Tr>
  );
};
