import { VStack } from '@chakra-ui/react';
import { FC } from 'types';

export const FormEditorViewMenuContainer: FC = ({ children }) => (
  <VStack
    sx={{
      boxShadow: 'mainWhite',
      position: 'absolute',
      transform: 'translateX(-50%)',
      left: '50%',
      zIndex: '900',
      w: '150px',
      borderRadius: '2px',
      bg: 'grey.lvl4',
      transition: 'all 0.5s ease',
      py: 12,
      px: 16,
    }}
  >
    {children}
  </VStack>
);
