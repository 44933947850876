import { Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { ConfirmationModal } from 'components/modal/ConfirmationModal/ConfirmationModal';
import { useSubmitAssessment } from 'hooks/useAssessment';
import { useAssessmentForm } from 'context/AssessmentContext/AssessmentContextProvider';
import { setSpinner } from 'context/AssessmentContext/AssessmentContext.utils';

interface IValidateAndSubmitModalProps {
  onClose(): void;
}
export const ValidateAndSubmitModal: FC<IValidateAndSubmitModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { assessment, dispatch, skippedComponents } = useAssessmentForm();
  const { submitAssessment } = useSubmitAssessment(assessment?.id);

  const isValid = assessment?.isValid(skippedComponents);

  const handleSubmit = async () => {
    if (isValid) {
      setSpinner(dispatch, true);
      await submitAssessment();
      setSpinner(dispatch, false);
    }
    onClose();
  };

  if (!assessment) {
    return null;
  }

  return (
    <ConfirmationModal
      maxWidth="480px"
      title={t('assessment.validate.modal.title')}
      confirmText={t('action.submit.label')}
      onCancel={onClose}
      onConfirm={handleSubmit}
      disabled={!assessment.isValid}
    >
      <Text size="lg" mt={20} mb={16}>
        {isValid
          ? t('assessment.validate.modal.validText')
          : t('assessment.validate.modal.invalidText')}
      </Text>
    </ConfirmationModal>
  );
};
