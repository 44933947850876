import { BasicConfig, Settings } from 'react-awesome-query-builder';

import { AfterWidgetBadge } from '../components/AfterWidgetBadge';
import { AQBButton } from '../components/Button';
import { AQBConjs } from '../components/Conjs';
import { AQBFieldSelect } from '../components/FieldSelect';

type CustomSettings = (disabled: boolean) => Settings;

export const settings: CustomSettings = (disabled: boolean) => ({
  ...BasicConfig.settings,
  showNot: false,
  showErrorMessage: true,
  groupActionsPosition: 'topLeft',
  renderField: AQBFieldSelect,
  renderButton: AQBButton,
  renderConjs: AQBConjs,
  renderOperator: AQBFieldSelect,
  renderFunc: AQBFieldSelect,
  renderAfterWidget: AfterWidgetBadge,
  canReorder: !disabled,
  canRegroup: !disabled,
  immutableGroupsMode: disabled,
  immutableFieldsMode: disabled,
  immutableOpsMode: disabled,
  immutableValuesMode: disabled,
});
