import { Text } from '@chakra-ui/layout';
import { chakra } from '@chakra-ui/system';

export const StyledClearValue = chakra(Text, {
  baseStyle: {
    cursor: 'pointer',
    color: 'brand.red',
    position: 'absolute',
    top: '9px',
    right: '35px',
  },
});
