import { Box, HStack } from '@chakra-ui/react';
import { Table } from 'appkit-react';
import { useTranslation, TFunction } from 'react-i18next';

import { StatusColumn } from 'components/site-admin/rooms/StatusColumn/StatusColumn';
import { TableSearch } from 'components/table/TableSearch/TableSearch';
import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { useFilteredResources } from 'hooks/useFilteredResources';
import { RoomAdmin } from 'models/RoomAdmin';
import { dateSortMethod } from 'utils/dateSortMethod';
import { ExportSiteData } from 'components/export-data/ExportSiteData/ExportSiteData';

const roomsColumns = (t: TFunction) => [
  {
    Header: t('siteAdmin.rooms.tableColumns.name'),
    accessor: 'name',
  },
  {
    Header: t('siteAdmin.rooms.tableColumns.description'),
    accessor: 'description',
  },
  {
    Header: t('siteAdmin.rooms.tableColumns.type'),
    accessor: 'roomType',
  },
  {
    Header: t('siteAdmin.rooms.tableColumns.requestedBy'),
    accessor: 'requestedBy',
  },
  {
    Header: t('siteAdmin.rooms.tableColumns.requestedAt'),
    accessor: 'formattedRequestedAt',
    sortMethod: dateSortMethod,
  },
  {
    Header: t('siteAdmin.rooms.tableColumns.status'),
    accessor: 'roomStatus',
    Cell: (row: Record<string, unknown>) => <StatusColumn rowData={row} />,
  },
  {
    Header: t('siteAdmin.rooms.tableColumns.lastStatusChangeBy'),
    accessor: 'lastStatusChangeBy',
  },
  {
    Header: t('siteAdmin.rooms.tableColumns.lastStatusChangeAt'),
    accessor: 'formattedLastStatusChangeAt',
    sortMethod: dateSortMethod,
  },
];

export const SiteAdminRoomsManagementPage = () => {
  const { handleSearchInputChange, data, isInitialLoad, isValidating } = useFilteredResources(
    RoomAdmin,
    'name',
  );
  const { t } = useTranslation();

  return (
    <Box mt="20px">
      <TableWrapper isLoading={isInitialLoad || isValidating}>
        <TableSearch onChange={handleSearchInputChange} />
        <Table
          columns={roomsColumns(t)}
          data={data}
          showPageSizeOptions={false}
          defaultPageSize={10}
          defaultSorted={[{ id: 'name', asc: true }]}
        />
        <HStack spacing={20} position="absolute" right="0" bottom="0">
          <ExportSiteData />
        </HStack>
      </TableWrapper>
    </Box>
  );
};
