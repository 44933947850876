import { Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useState } from 'react';
import { useSWRConfig } from 'swr';
import { useTranslation } from 'react-i18next';

import { HttpMethod } from 'typings/enums/HttpMethod';
import { PopAlertFail } from 'services/PopAlertFail';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { ConfirmationModal } from 'components/modal/ConfirmationModal/ConfirmationModal';
import { RoomFolder } from 'models/RoomFolder';
import { apiClient } from 'utils/apiClient';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';

interface IFolderDeleteModal {
  roomId: string;
  roomFolder: RoomFolder;
  onClose(): void;
}

export const FolderDeleteModal: FC<IFolderDeleteModal> = ({ roomFolder, roomId, onClose }) => {
  const dependencyHeaders = useDependencyHeaders();
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();

  const handleCloseModal = () => {
    if (!isDeleting) {
      onClose();
    }

    return;
  };
  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      await apiClient(`rooms/${roomId}/roomfolders/${roomFolder.id}`, HttpMethod.Delete, {
        headers: {
          'content-type': 'application/json',
          ...dependencyHeaders,
        },
      });
      mutate(`rooms/${roomId}/roomfolders@${dependencyHeaders['x-site-id']}`);
      PopAlertSuccess(
        t('room.sidebar.folderDetails.modals.delete.successMessages.changed', {
          name: roomFolder?.name,
        }),
      );
    } catch (error: any) { // eslint-disable-line
      PopAlertFail(t('room.errors.deleteFolder.main'), error.Message);
      onClose();
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <ConfirmationModal
      maxWidth="480px"
      title={t('room.sidebar.folderDetails.modals.delete.title')}
      showLoader={isDeleting}
      disabled={isDeleting}
      confirmText={t('room.sidebar.folderDetails.modals.delete.action.confirm')}
      onCancel={handleCloseModal}
      onConfirm={handleDelete}
    >
      <Text size="lg" my="40px">
        {t('room.sidebar.folderDetails.modals.delete.text')}
      </Text>
    </ConfirmationModal>
  );
};
