import { useDisclosure } from '@chakra-ui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { RoomHistoryModal } from 'components/room/RoomHistoryModal/RoomHistoryModal';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

export const RoomHistory = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <Fragment>
      <StyledButton size="md" kind="secondary" onClick={onOpen}>
        {t('room.sidebar.roomDetails.action.history.label')}
      </StyledButton>
      {isOpen && <RoomHistoryModal onClose={onClose} />}
    </Fragment>
  );
};
