import { FC } from 'types';
import { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextAreaField } from 'fields/TextareaField/TextareaField';
import { AssessmentFormComponent } from 'models/AssessmentFormComponent';

interface IAssessmentFreeTextFieldProps {
  assessmentQuestion: AssessmentFormComponent;
  disabled?: boolean;
  index?: number;
}

export const AssessmentFreeTextField: FC<IAssessmentFreeTextFieldProps> = ({
  assessmentQuestion,
  disabled,
  index = 0,
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Controller
        control={control}
        name={assessmentQuestion.fieldName(index)}
        // eslint-disable-next-line
        render={({ field: { ref: _ref, ...fieldProps } }) => (
          <TextAreaField autosize disabled={disabled} {...fieldProps} />
        )}
        rules={{
          required: assessmentQuestion.isRequired ? t<string>('validation.required') : false,
        }}
      />
    </Fragment>
  );
};
