import { useCallback, useState } from 'react';
import { HStack, Spacer, useConst } from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';

import { BoxHeader } from 'components/core/BoxHeader/BoxHeader';
import { BackButton } from 'components/core/BoxHeader/components/BackButton';
import { MainLayout } from 'components/layouts/MainLayout/MainLayout';
import { AssessmentStatusIndicator } from 'components/assessment/AssessmentStatusIndicator/AssessmentStatusIndicator';
import { BoxTitle } from 'components/core/BoxHeader/components/BoxTitle';
import { AssessmentProvider } from 'context/AssessmentContext/AssessmentContextProvider';
import { AssessmentMain } from 'components/assessment/AssessmentMain/AssessmentMain';
import { useTestAssessment } from 'hooks/useAssessment';
import { useRoomForm } from 'hooks/useRoomForms';
import { IAssessmentBase } from 'typings/interfaces/IAssessmentBase';
import { AssessmentResetActionButton } from 'components/assessment/AssessmentResetActionButton/AssessmentResetActionButton';
import { FormEditorViewIconButton } from 'components/form-editor/FormEditorViewIconButton/FormEditorViewIconButton';
import { useResources } from 'hooks/useResources';
import { User } from 'models/User';

export const FormTestAssessmentPage = () => {
  useResources(User);
  const {
    params: { roomId, formId },
  } = useRouteMatch<{ roomId: string; formId: string }>();
  const { form } = useRoomForm(roomId, formId);

  const timestamp = useConst(Date.now().toString());

  const { assessment: assessmentTest, error } = useTestAssessment(roomId, formId, timestamp);

  const assessment: IAssessmentBase | undefined = assessmentTest;

  // force rerender RHF form after reset
  const [keyNumber, setValue] = useState(0);
  const incrementKeyNumber = useCallback(() => {
    setValue((previous) => previous + 1);
  }, []);
  const key = `${assessment?.id}@${keyNumber}`;

  return (
    <MainLayout>
      <AssessmentProvider
        assessmentId={assessment?.id}
        assessment={assessment}
        error={error}
        isLoaded={Boolean(assessment)}
        timestamp={timestamp}
      >
        <BoxHeader align="center" justify="space-between">
          <BackButton />
          <AssessmentStatusIndicator status={assessment?.displayStatus} />
          <Spacer />
          <BoxTitle>{form?.name}</BoxTitle>
          <Spacer />
          <HStack spacing={12} color="brand.orange">
            <FormEditorViewIconButton />
          </HStack>
          <AssessmentResetActionButton assessment={assessment} onReset={incrementKeyNumber} />
        </BoxHeader>
        <AssessmentMain isLoaded={Boolean(assessment)} key={key} />
      </AssessmentProvider>
    </MainLayout>
  );
};
