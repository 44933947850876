import { Box, BoxProps, Flex, Text, VStack } from '@chakra-ui/react';
import { Slider as AppKitSlider } from 'appkit-react-slider';
import { useState } from 'react';
import { FC } from 'types';

interface ISliderWithIntervalsProps extends Omit<BoxProps, 'defaultValue' | 'value' | 'onChange'> {
  name: string;
  label?: string;
  marks: Array<string>;
  symbol?: string;
  defaultValue?: number;
  value?: number;
  disabled?: boolean;
  onChange?(value: number): void;
}
export const SliderWithIntervals: FC<ISliderWithIntervalsProps> = ({
  label,
  marks,
  symbol,
  value,
  defaultValue,
  disabled,
  onChange,
  ...rest
}) => {
  const [sliderValue, setSliderValue] = useState(value);
  const markLabels = marks.map((mark) => (symbol ? `${mark} ${symbol}` : mark));

  const handleChange = (newValue: number) => {
    setSliderValue(newValue);
    if (!disabled) {
      onChange?.(newValue);
    }
  };

  return (
    <VStack className="a-slider-wrapper" pb={20} {...rest}>
      <Text pb={20} alignSelf="flex-start">
        {label}
      </Text>
      <AppKitSlider
        kind="slider"
        min={0}
        max={marks.length - 1}
        value={sliderValue}
        defaultValue={defaultValue}
        onChange={handleChange}
        disabled={disabled}
      />
      <Flex width="100%">
        {markLabels.map((x, xi) => {
          return (
            <Box flex="1" ml={xi === 0 ? 0 : '20px'}>
              <Text wordBreak="normal" textAlign={'justify'}>
                {x}
              </Text>
            </Box>
          );
        })}
      </Flex>
    </VStack>
  );
};
