import { useDisclosure } from '@chakra-ui/react';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';
import { FormAction } from 'typings/enums/FormAction';
import { FormStatus } from 'typings/enums/FormStatus';

import { FormEditorReviewModal } from '../FormEditorReviewModal/FormEditorReviewModal';

interface IFormEditorReviewButtonProps {
  roomId: string;
  formId: string;
  formStatus: FormStatus | undefined;
  isLoading?: boolean;
  disabled?: boolean;
}
export const FormEditorReviewButton: FC<IFormEditorReviewButtonProps> = ({
  disabled,
  isLoading,
  ...rest
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Fragment>
      <StyledButton
        size="md"
        minWidth="50px"
        disabled={disabled}
        isLoading={isLoading}
        onClick={onOpen}
      >
        {t(`form.action.${FormAction.Review}`)}
      </StyledButton>
      {isOpen && <FormEditorReviewModal onClose={onClose} {...rest} />}
    </Fragment>
  );
};
