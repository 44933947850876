import { Factory, useEffect } from 'react';
import { InputNumber } from 'appkit-react';
import { NumberWidgetProps } from 'react-awesome-query-builder';

import { RuleWrapper } from '../LogicBuilder.elements';

export const AQBNumber: Factory<NumberWidgetProps> = (props) => {
  // since the InputNumber defaults to 0, we have to update the state,
  // otherwise it stays undefined for new rules, but the users see 0 as the value
  useEffect(() => {
    if (props?.value === undefined) {
      props?.setValue(0);
    }
  });

  const onChange = (value?: number) => {
    let val = value;
    if (val === null) val = undefined;
    else val = Number(val);
    props?.setValue(val);
  };

  return (
    <RuleWrapper>
      <InputNumber
        min={props?.min}
        max={props?.max}
        step={props?.step}
        placeholder={props?.placeholder}
        value={props?.value}
        disabled={props?.readonly}
        onChange={onChange}
      />
    </RuleWrapper>
  );
};
