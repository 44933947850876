import { useEffect, useRef, useState } from 'react';

// use react component key to force rerender RHF question after all data has settled
// AssessmentQuestion.tsx:
// const { updatingComponentIds } = useAssessmentForm();
// const isUpdating = updatingComponentIds.includes(question.id);
// const questionKey = useQuestionKey(question.id, question.result, isUpdating);
export const useRenderNumberKey = (
  result: Array<string> | null,
  isUpdating: boolean,
  isRevalidating: boolean,
) => {
  const newResultString = result?.toString();
  const resultStringRef = useRef<string>();
  const [renderNumber, setRenderNumber] = useState(0);
  const isChanged = resultStringRef.current !== newResultString;
  useEffect(() => {
    if (!isUpdating && !isRevalidating && isChanged) {
      resultStringRef.current = newResultString;
      setRenderNumber((prev) => prev + 1);
    }
  }, [isChanged, isRevalidating, isUpdating, newResultString]);

  return renderNumber;
};
