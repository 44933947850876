import { FC } from 'types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { CheckboxField } from 'fields/CheckboxField/CheckboxField';

import { getValidSettings } from './AssessmentConfirmationField.utils';

interface IAssessmentConfirmationFieldProps {
  assessmentQuestion: AssessmentFormComponent;
  disabled?: boolean;
  index?: number;
}
export const AssessmentConfirmationField: FC<IAssessmentConfirmationFieldProps> = ({
  assessmentQuestion,
  disabled,
  index = 0,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const settings = getValidSettings(assessmentQuestion);

  return (
    <CheckboxField
      sx={{ textAlign: 'center' }}
      name={assessmentQuestion.fieldName(index)}
      control={control}
      disabled={disabled}
      rules={{
        required: assessmentQuestion.isRequired ? t<string>('validation.required') : false,
      }}
    >
      {settings.confirmationText}
    </CheckboxField>
  );
};
