import { SiteView } from 'models/SiteView';

import { useDebouncedSearch } from './useDebouncedSearch';
import { useResourcesWithoutSiteId } from './useResourcesWithoutSiteId';

export const useFilteredSites = (searchField: 'siteName' | 'pwcContact') => {
  const { data, ...rest } = useResourcesWithoutSiteId(SiteView);
  const [searchQuery, handleSearchInputChange] = useDebouncedSearch();

  return {
    data: (data as Array<SiteView>)?.filter((item) =>
      item[searchField].toLowerCase().includes(searchQuery),
    ),
    handleSearchInputChange,
    ...rest,
  };
};
