import { Box, Wrap, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import { ThemeContainer } from 'appkit-react';

import { InfoTooltip } from 'components/core/InfoTooltip/InfoTooltip';
import { ToggleExpandButton } from 'components/core/ToggleExpandButton/ToggleExpandButton';
import { FormComponent } from 'models/FormComponent';
import { StyledBadge, StyledInput } from 'components/core/StyledAppKit/StyledAppKit';

import { QuestionContainer } from '../Question/Question.elements';
import { RemoveButton } from '../Question/components/RemoveButton/RemoveButton';
import { QuestionOrderTag } from '../Question/components/QuestionOrderTag/QuestionOrderTag';
import { QuestionSettingsEditorButton } from '../Question/components/QuestionSettingsEditorButton/QuestionSettingsEditorButton';
import { QuestionAddonButton } from '../Question/components/QuestionAddonButton/QuestionAddonButton';

interface IQuestionCloneProps {
  model: FormComponent;
}
export const QuestionClone: FC<IQuestionCloneProps> = ({ model, ...rest }) => {
  const { t } = useTranslation();

  return (
    <ThemeContainer theme="theme-dark" level="level2">
      <QuestionContainer paddingRight={model.level > 1 ? '0' : 44} {...rest}>
        <Box position="relative">
          <Box position="absolute" right="0px" zIndex={100}>
            <StyledBadge type="primary" size="small">
              {t(`form.components.${model.componentType}`)}
            </StyledBadge>
            {model.isQuestionSet && <ToggleExpandButton isExpanded={false} onClick={noop} />}
            <RemoveButton onClick={noop} />
          </Box>
          <Box position="relative">
            <QuestionOrderTag level={model.level} orderTag={model.orderTag} />
            <StyledInput
              label={t('form.editor.question')}
              paddingBottom={12}
              defaultValue={model.text}
            />
            <Box>
              <Text size="sm" opacity="0.87" textTransform="uppercase">
                {t('form.editor.addons')}
              </Text>
              <Wrap
                sx={{
                  minH: '36px',
                  mt: 12,
                  pl: 4,
                  pr: 20,
                  pt: '2px',
                  position: 'relative',
                  border: ({ colors }) => `1px solid ${colors.grey.lvl6}`,
                }}
              >
                <QuestionSettingsEditorButton question={model} onPlaceholderSettingsClick={noop} />

                {model.addons?.map((addon) => (
                  <QuestionAddonButton key={addon.addonType} addon={addon} question={model} />
                ))}

                <Box position="absolute" right="2px" top="1px">
                  <InfoTooltip />
                </Box>
              </Wrap>
            </Box>
          </Box>
        </Box>
      </QuestionContainer>
    </ThemeContainer>
  );
};
