import { FC } from 'types';
import { ButtonGroupItem, ButtonGroup, Tooltip } from 'appkit-react';
import { Box, Tr, Td, HStack, Text, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { RoomUser } from 'models/RoomUser';
import { Avatar } from 'components/core/Avatar/Avatar';
import { RoomUserRole } from 'typings/enums/RoomUserRole';
import { CalendarPickerField } from 'fields/CalendarPickerField/CalendarPickerField';

interface IRoomUsersListViewItemProps {
  roomUser: RoomUser;
  currentUserIsAdmin?: boolean;
  disabled?: boolean;
  isLastAdmin?: boolean;
  onChangeRole(userId: string, role: RoomUserRole): void;
  onChangeExpiration(userId: string, expirationDate: Date): void;
  onRemoveUser(userId: string): void;
}
export const RoomUsersListViewItem: FC<IRoomUsersListViewItemProps> = ({
  roomUser,
  disabled,
  currentUserIsAdmin,
  isLastAdmin,
  onChangeRole,
  onRemoveUser,
  onChangeExpiration,
}) => {
  const { t } = useTranslation();

  return (
    <Tr key={roomUser.userId}>
      <HStack as={Td}>
        <Avatar name={roomUser.username} />
        <Text overflowWrap="anywhere">{roomUser.displayName ?? roomUser.username}</Text>
      </HStack>

      <Td>
        <Tooltip
          content={t(
            'room.sidebar.roomDetails.modals.membersManagement.usersList.item.tooltipLabel',
          )}
          disabled={!isLastAdmin}
        >
          <ButtonGroup
            defaultValue={roomUser?.roomUserRole || RoomUserRole.Member}
            size="lg"
            onChange={(_: unknown, value: RoomUserRole) => {
              onChangeRole(roomUser.userId, value);
            }}
          >
            <ButtonGroupItem
              data-value={RoomUserRole.Admin}
              disabled={disabled || !currentUserIsAdmin}
            >
              {t('roles.admin').toUpperCase()}
            </ButtonGroupItem>
            <ButtonGroupItem data-value={RoomUserRole.Member} disabled={disabled}>
              {t('roles.member').toUpperCase()}
            </ButtonGroupItem>
          </ButtonGroup>
        </Tooltip>
      </Td>
      <Td>
        <CalendarPickerField
          format="dd/mm/yyyy"
          name="expirationDate"
          placeholder={t(
            'room.sidebar.roomDetails.modals.membersManagement.usersList.item.datePicker.placeholder',
          )}
          defaultValue={roomUser?.expirationDate ? new Date(roomUser.expirationDate) : null}
          minDate={new Date()}
          onChange={(date: Date) => onChangeExpiration(roomUser.userId, date)}
          sx={{
            width: '150px',
            mr: '10px',
            '.react-date-picker': {
              width: '100%',
              '.a-react-date-picker-calendar': {
                right: '0 !important',
                left: 'unset !important',
              },
            },
          }}
        />
      </Td>
      <Center as={Td}>
        <Tooltip
          content={t(
            'room.sidebar.roomDetails.modals.membersManagement.usersList.item.tooltipLabel',
          )}
          disabled={!isLastAdmin}
        >
          <Box
            className={`appkiticon icon-circle-delete-outline a-font-24 ${
              disabled ? '' : 'a-text-orange'
            }`}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() =>
              !disabled &&
              (currentUserIsAdmin || roomUser.roomUserRole === RoomUserRole.Member) &&
              onRemoveUser(roomUser.userId)
            }
          />
        </Tooltip>
      </Center>
    </Tr>
  );
};
