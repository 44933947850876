import { IWithId, IWithUserId, transformResourceUserDto } from './transformResourceUserDto';

interface ICampaign extends IWithId {
  initiators: Array<IWithId> | null;
}

interface ITransformedCampaign extends IWithId {
  initiators: Array<IWithUserId> | null;
}

export const transformCampaignUsersData = (campaignUsersDto: Array<IWithId>, campaignId: string) =>
  campaignUsersDto.map((campaignUserDto) => transformResourceUserDto(campaignUserDto, campaignId));

export const transformCampaignDto = (campaignDto: ICampaign): ITransformedCampaign => ({
  ...campaignDto,
  initiators: campaignDto.initiators
    ? transformCampaignUsersData(campaignDto.initiators, campaignDto.id)
    : null,
});
