import { Collection } from '@datx/core';

import { Site } from 'models/Site';
import { Domain } from 'models/Domain';
import { User } from 'models/User';
import { Tenant } from 'models/Tenant';
import { Room } from 'models/Room';
import { Form } from 'models/Form';
import { Profile } from 'models/Profile';
import { RoomUser } from 'models/RoomUser';
import { Audit } from 'models/Audit';
import { RoomFolder } from 'models/RoomFolder';
import { Comment } from 'models/Comment';
import { RoomFolderUser } from 'models/RoomFolderUser';
import { RoomAdmin } from 'models/RoomAdmin';
import { FormComponent } from 'models/FormComponent';
import { FormUser } from 'models/FormUser';
import { FormApproval } from 'models/FormApproval';
import { Trash } from 'models/Trash';
import { TrashItem } from 'models/TrashItem';
import { Currency } from 'models/Currency';
import { FormConclusion } from 'models/FormConclusion';
import { FormReview } from 'models/FormReview';
import { Campaign } from 'models/Campaign';
import { CampaignUser } from 'models/CampaignUser';
import { CampaignPermissionGroupComponent } from 'models/CampaignPermissionGroupComponent';
import { CampaignPermissionGroup } from 'models/CampaignPermissionGroup';
import { Assessment } from 'models/Assessment';
import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { AssessmentComponent } from 'models/AssessmentComponent';
import { AssessmentPermissionGroup } from 'models/AssessmentPermissionGroup';
import { AssessmentView } from 'models/AssessmentView';
import { Language } from 'models/Language';
import { Country } from 'models/Country';
import { CampaignResponse } from 'models/CampaignResponse';
import { FormConclusionGroup } from 'models/FormConclusionGroup';
import { AssessmentApproval } from 'models/AssessmentApproval';
import { AssessmentApprover } from 'models/AssessmentApprover';
import { AssessmentReview } from 'models/AssessmentReview';
import { DomainAppAdmin } from 'models/DomainAppAdmin';
import { SiteView } from 'models/SiteView';
import { AssessmentTest } from 'models/AssessmentTest';

export class AppStore extends Collection {
  public static types = [
    User,
    Site,
    SiteView,
    Domain,
    DomainAppAdmin,
    Tenant,
    Profile,
    Room,
    RoomAdmin,
    RoomUser,
    Form,
    FormUser,
    RoomFolder,
    RoomFolderUser,
    Audit,
    Comment,
    FormComponent,
    Trash,
    TrashItem,
    Currency,
    FormApproval,
    FormConclusionGroup,
    FormConclusion,
    FormReview,
    Campaign,
    CampaignUser,
    CampaignPermissionGroup,
    CampaignPermissionGroupComponent,
    CampaignResponse,
    AssessmentComponent,
    AssessmentFormComponent,
    AssessmentPermissionGroup,
    AssessmentView,
    Country,
    Language,
    AssessmentApprover,
    AssessmentApproval,
    AssessmentReview,
    Assessment,
    AssessmentTest,
  ];

  public getCopy() {
    return new AppStore(this.snapshot);
  }
}
