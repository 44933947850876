import { chakra } from '@chakra-ui/system';

export const StyledCreateDropdown = chakra('div', {
  baseStyle: {
    boxShadow: 'mainWhite',
    width: '430px',
    borderRadius: '2px',
    bg: 'grey.lvl4',
    position: 'absolute',
    bottom: '0',
    right: '0',
    transition: 'all 0.2s ease',
    zIndex: '900',
  },
});

export const StyledCreateDropdownBody = chakra('div', {
  baseStyle: {
    p: '0 36px 10px 20px',
  },
});
