import { FC } from 'types';
import { useTranslation } from 'react-i18next';

import { FormType } from 'typings/enums/FormType';
import { FormModal } from 'components/modal/FormModal/FormModal';

import { useAvailableFormUsers, useCreateForm } from './CreateFormModal.hooks';
import { CreateFormForm } from './components/CreateFormForm/CreateFormForm';

interface ICreateFormModalProps {
  roomId: string;
  folderId?: string;
  onCancel(): void;
}
export const CreateFormModal: FC<ICreateFormModalProps> = ({ roomId, folderId, onCancel }) => {
  const { t } = useTranslation();
  const { roomUsers } = useAvailableFormUsers(roomId, folderId);
  const [createForm, { pending }] = useCreateForm(roomId);

  return (
    <FormModal isOpen maxWidth="940px" title={t('createForm.title')} onClose={onCancel}>
      <CreateFormForm
        formType={FormType.BlankForm}
        folderId={folderId}
        availableUsers={roomUsers}
        isLoading={pending}
        onSave={createForm}
        onCancel={onCancel}
      />
    </FormModal>
  );
};
