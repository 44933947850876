import { Domain } from 'models/Domain';
import { DomainAppAdmin } from 'models/DomainAppAdmin';
import { Form } from 'models/Form';
import { Resource } from 'models/Resource';
import { Room } from 'models/Room';
import { RoomFolder } from 'models/RoomFolder';
import { User } from 'models/User';

import { transformDomains } from './transformDomains';
import { transformFormsData } from './transformFormsData';
import { transformRoomFolderData } from './transformRoomFolderData';
import { transformRoomsData } from './transformRoomsData';
import { transformUsersData } from './transformUsersData';

// this is primarily for useResources
export const getResourceTransform = (model: typeof Resource) => {
  switch (model.type) {
    case Room.type:
      return transformRoomsData;
    case Form.type:
      return transformFormsData;
    case RoomFolder.type:
      return transformRoomFolderData;
    case DomainAppAdmin.type:
    case Domain.type:
      return transformDomains;
    case User.type:
      return transformUsersData;
    default:
      return (data: Array<Record<string, unknown>>) => data;
  }
};
