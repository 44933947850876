import { FC } from 'types';
import { useMemo} from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { SelectOption } from 'appkit-react';

import { ControlledSelectField } from 'fields/SelectField/SelectField';
import { useDatx } from 'hooks/useDatx';
import { FormComponent } from 'models/FormComponent';
import { compareNumberByNumberArrayFn } from 'utils/compareNumberByNumberArrayFn';
import { compareFormComponentOrder } from 'services/formComponents/compareFormComponentOrder';

interface ISkipConditionSelectProps {
  control: Control<FieldValues>;
  name: string;
  disabled?: boolean;
  currentComponentOrder: Array<number>;
  label?: string;
  variant?: string;
}
export const SkipConditionSelect: FC<ISkipConditionSelectProps> = ({
  control,
  name,
  currentComponentOrder,
  ...rest
}) => {
  const datx = useDatx();

  const components = useMemo(
    () =>
      datx
        .findAll<FormComponent>(FormComponent)
        .filter(
          (component) =>
            !component.isSection &&
            compareNumberByNumberArrayFn(component.order, currentComponentOrder) === 1,
        )
        .sort(compareFormComponentOrder),
    [currentComponentOrder, datx],
  );

  return (
    <ControlledSelectField control={control} name={name} {...rest}>
      <SelectOption value={null} />

      {components.map((c) => (
        <SelectOption key={c.id} value={c.id}>
          {c.orderTag}
        </SelectOption>
      ))}
    </ControlledSelectField>
  );
};
