import { HttpMethod } from 'typings/enums/HttpMethod';
import { getIdToken } from 'utils/getIdToken';
import { config } from 'config';

const API_URL = `${config.apiUrl}/api/`;

export const assessmentExportFetcher =
  (siteId: string, format: string, assessmentIds: string[], campaignId?: string) => () =>
    fetch(`${API_URL}assessments/export`, {
      headers: {
        'x-site-id': siteId,
        'x-id-token': getIdToken() ?? '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        assessmentIds,
        format: format,
        campaignId,
      }),
      method: HttpMethod.Post,
    });

export const campaignExportFetcher =
  (siteId: string, formId: string, format: string, campaignIds: string[]) => () =>
    fetch(`${API_URL}campaigns/export`, {
      headers: {
        'x-site-id': siteId,
        'x-id-token': getIdToken() ?? '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        formId,
        campaignIds,
        format: format,
      }),
      method: HttpMethod.Post,
    });
