import { ChoiceQuestionValue } from 'typings/types/FormComponentSettings';

export function parseChoiceValues(stringifiedJSON: string | null): Array<ChoiceQuestionValue> {
  if (!stringifiedJSON) {
    return [];
  }
  try {
    const result = JSON.parse(stringifiedJSON) as Array<ChoiceQuestionValue>;
    if (!Array.isArray(result)) {
      throw 'Error parsing choice values';
    }
    return result;
  } catch (error: any) { // eslint-disable-line
    return [];
  }
}

export function parseMultipleChoiceSettings(stringifiedJSON: string | null): string | null {
  if (!stringifiedJSON) {
    return null;
  }
  try {
    const result = JSON.parse(stringifiedJSON) as { exclusiveOptionText: string };
    if (result.exclusiveOptionText !== null && typeof result.exclusiveOptionText !== 'string') {
      throw 'Error parsing settings';
    }
    return result.exclusiveOptionText;
  } catch (error: any) { // eslint-disable-line
    return null;
  }
}
