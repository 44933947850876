import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { Control, FieldArrayWithId, FieldValues } from 'react-hook-form';
import { Box, Table, Thead, Tr, Th, Tbody } from '@chakra-ui/react';

import { FormFieldError } from 'components/core/FormFieldError/FormFieldError';
import { Total } from 'components/core/Total/Total';

import { UserManagementRow } from '../UserManagementRow/UserManagementRow';
import { ISiteUsersFields } from '../../UserDeleteModal';

interface IUserManagementTableProps {
  fields: Array<FieldArrayWithId<ISiteUsersFields, 'users', 'id'>>;
  control: Control<ISiteUsersFields>;
  remove(index: number): void;
}
export const UserManagementTable: FC<IUserManagementTableProps> = ({ fields, control, remove }) => {
  const { t } = useTranslation();

  return (
    <Box overflowX="auto" minHeight="150px">
      <Table size="md" variant="list" mt={20}>
        <Thead>
          <Tr>
            <Th>
              <Total label={t('form.formMembers.totalUsers')} total={fields.length} />
            </Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {fields.map((user, index) => (
            <UserManagementRow key={user.id} user={user} onRemove={() => remove(index)} />
          ))}
        </Tbody>
      </Table>
      <FormFieldError name="users" control={control as unknown as Control<FieldValues>} />
    </Box>
  );
};
