import { Campaign } from 'models/Campaign';
import { TableCellRow } from 'typings/types/TableCellRow';
import { PermissionGroupsFormTableCell } from '../PermissionGroupsFormTableCell/PermissionGroupsFormTableCell';

export const PermissionGroupsFormTableColumns = (
  campaign: Campaign,
  handleSelect: (componentId: string, permissionGroupId: string) => void,
  handleSelectAll: (permissionGroupId: string) => void,
) => {
  return campaign?.permissionGroups?.map((group) => {
    return {
      Header: group.name,
      Cell: (row: TableCellRow) => {
        return (
          <PermissionGroupsFormTableCell
            rowData={row}
            campaign={campaign}
            permissionGroup={group}
            handleSelect={handleSelect}
            handleSelectAll={handleSelectAll}
          />
        );
      },

      minWidth: 100,
    };
  });
};
