import { useBoolean, Box } from '@chakra-ui/react';
import { FC } from 'types';
import { useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { useFormEditor } from 'context/FormEditorContext/FormEditorContextProvider';
import { DraggingAction } from 'typings/enums/DraggingAction';
import { FormConclusionGroup } from 'models/FormConclusionGroup';

import { FormEditorConclusions } from '../FormEditorConclusions/FormEditorConclusions';

import { FormEditorConclusionGroupHeader } from './components/FormEditorConclusionGroupHeader/FormEditorConclusionGroupHeader';

interface IFormEditorConclusionGroupProps {
  index: number;
  conclusionGroup: FormConclusionGroup;
}

export const FormEditorConclusionGroup: FC<IFormEditorConclusionGroupProps> = ({
  index,
  conclusionGroup,
}) => {
  const [isExpanded, setExpanded] = useBoolean(false);
  const { removeConclusionGroup, dragAction, disableEdit } = useFormEditor();

  const handleRemoveClick = useCallback(() => {
    if (disableEdit) {
      return;
    }
    removeConclusionGroup(conclusionGroup);
  }, [conclusionGroup, disableEdit, removeConclusionGroup]);

  return (
    <Draggable
      draggableId={`conclusionGroup-${conclusionGroup.id}`}
      index={index}
      isDragDisabled={isExpanded || dragAction !== DraggingAction.AddConditionGroup}
    >
      {(provided) => (
        <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <FormEditorConclusionGroupHeader
            isExpanded={isExpanded}
            onDelete={handleRemoveClick}
            onToggleExpand={setExpanded.toggle}
          />
          {isExpanded && <FormEditorConclusions conclusionGroup={conclusionGroup} />}
        </Box>
      )}
    </Draggable>
  );
};
