import { extendTheme } from '@chakra-ui/react';

import { colors } from './foundations/colors';
import { space } from './foundations/space';
import { fontStyles } from './foundations/fontStyles';
import { borders } from './foundations/borders';
import { lineHeights } from './foundations/lineHeights';
import { Heading } from './components/heading';
import { Text } from './components/text';
import { Table } from './components/table';
import { NumberInput } from './components/numberInput';
import { appkit } from './appkit';
import { awesomeQueryBuilder } from './awesome-query-builder';
import { StyledSelect } from './components/select';

export const theme = extendTheme({
  colors,
  borders,
  lineHeights,
  ...fontStyles,
  space,
  shadows: {
    mainWhite: '0 7px 18px 1px rgba(255,255,255,0.16)',
    mainWhiteBig: '0 18px 29px 1px rgba(255,255,255,0.16)',
    mainWhiteSmall: '0 3px 7px 1px rgba(255,255,255,0.16)',
  },
  styles: {
    global: {
      '*': {
        boxSizing: 'border-box',
      },
      body: {
        fontFamily: 'body',
      },
      ...appkit,
      ...awesomeQueryBuilder,
    },
  },
  components: {
    Heading,
    Text,
    Table,
    StyledSelect,
    NumberInput,
  },
});
