import { TFunction } from 'i18next';

import { Audit } from 'models/Audit';
import { AuditEntity } from 'typings/enums/AuditEntity';
import { AuditActionType } from 'typings/enums/AuditActionType';

export const getAssessmentAuditRecord = (
  { type, newValuesObject, oldValuesObject }: Audit,
  t: TFunction,
) => {
  if (type === AuditActionType.Create) {
    if (newValuesObject) {
      return t('assessment.history.content.create.main', { name: newValuesObject.Name });
    } else {
      return t('assessment.history.content.create.catch');
    }
  }

  if (type === AuditActionType.Update) {
    if (newValuesObject) {
      if (oldValuesObject.Name !== newValuesObject.Name) {
        return t('assessment.history.content.update.name', {
          oldName: oldValuesObject.Name,
          newName: newValuesObject.Name,
        });
      }

      if (oldValuesObject.HasSubmissionAlert !== newValuesObject.HasSubmissionAlert) {
        return t('assessment.history.content.update.submissionAlert', {
          name: newValuesObject.Name,
        });
      }

      if (oldValuesObject.AssessmentStatus !== newValuesObject.AssessmentStatus) {
        return t('assessment.history.content.update.status', {
          name: newValuesObject.Name,
          status: t(`assessment.status.${newValuesObject.AssessmentStatus}`),
        });
      }

      if (oldValuesObject.ActivationStatus !== newValuesObject.ActivationStatus) {
        return t('assessment.history.content.update.activationStatus', {
          name: newValuesObject.Name,
          status: t(`assessment.activationStatus.${newValuesObject.ActivationStatus}`),
        });
      }

      if (oldValuesObject.IsDeleted !== newValuesObject.IsDeleted) {
        if (oldValuesObject.IsDeleted === null || oldValuesObject.IsDeleted === false) {
          return t('assessment.history.content.update.deleted', { name: newValuesObject.Name });
        } else {
          return t('assessment.history.content.update.restored', { name: newValuesObject.Name });
        }
      }

      return t('assessment.history.content.update.main', { name: newValuesObject.Name });
    } else {
      return t('assessment.history.content.update.catch');
    }
  }

  if (type === AuditActionType.Delete) {
    if (oldValuesObject) {
      return t('assessment.history.content.delete.main', { name: oldValuesObject.Name });
    } else {
      return t('assessment.history.content.delete.catch');
    }
  }

  return t('assessment.history.content.unknown');
};

export const getAssessmentPermissionGroupUserAuditRecord = (
  { type, newValuesObject, oldValuesObject, replacedUser }: Audit,
  t: TFunction,
) => {
  if (type === AuditActionType.Create) {
    if (newValuesObject) {
      if (replacedUser) {
        return t('assessment.history.permissionGroupUser.create.userReplacement', {
          name: newValuesObject.Username,
          groupName: newValuesObject.PermissionGroup,
          replacedUserName: replacedUser.username,
        });
      }

      return t('assessment.history.permissionGroupUser.create.main', {
        name: newValuesObject.Username,
        groupName: newValuesObject.PermissionGroup,
      });
    } else {
      return t('assessment.history.permissionGroupUser.create.catch');
    }
  }

  if (type === AuditActionType.Update) {
    if (newValuesObject) {
      if (oldValuesObject.PermissionGroup !== newValuesObject.PermissionGroup) {
        return t('assessment.history.permissionGroupUser.update.main', {
          name: newValuesObject.Username,
          oldGroupName: oldValuesObject.PermissionGroup,
          newGroupName: newValuesObject.PermissionGroup,
        });
      }
    } else {
      return t('assessment.history.permissionGroupUser.update.catch');
    }
  }

  if (type === AuditActionType.Delete) {
    if (oldValuesObject) {
      return t('assessment.history.permissionGroupUser.delete.main', {
        name: oldValuesObject.Username,
        groupName: oldValuesObject.PermissionGroup,
      });
    } else {
      return t('assessment.history.permissionGroupUser.delete.catch');
    }
  }

  return t('assessment.history.permissionGroupUser.unknown');
};

export const getAssessmentApproverAuditRecord = (
  { type, newValuesObject, oldValuesObject }: Audit,
  t: TFunction,
) => {
  if (type === AuditActionType.Create) {
    if (newValuesObject) {
      return t('assessment.history.approver.create.main', {
        name: newValuesObject.Username,
      });
    } else {
      return t('assessment.history.approver.create.catch');
    }
  }

  if (type === AuditActionType.Update) {
    if (newValuesObject) {
      if (oldValuesObject.Approved !== newValuesObject.Approved) {
        if (newValuesObject.Approved === null) {
          return t('assessment.history.approver.update.approved.reset', {
            name: newValuesObject.Username,
          });
        }
        return t(`assessment.history.approver.update.approved.${newValuesObject.Approved}`, {
          name: newValuesObject.Username,
        });
      }
    } else {
      return t('assessment.history.approver.update.catch');
    }
  }

  if (type === AuditActionType.Delete) {
    if (oldValuesObject) {
      return t('assessment.history.approver.delete.main', {
        name: oldValuesObject.Username,
      });
    } else {
      return t('assessment.history.approver.delete.catch');
    }
  }

  return t('assessment.history.approver.unknown');
};

export const getAssessmentReviewCommentAuditRecord = ({ type }: Audit, t: TFunction) => {
  if (type === AuditActionType.Create) {
    return t('assessment.history.comment.create.main');
  }
  return t('assessment.history.comment.unknown');
};

export const getAssessmentApprovalAuditRecord = (
  { type, newValuesObject, oldValuesObject }: Audit,
  t: TFunction,
) => {
  if (type === AuditActionType.Create) {
    if (newValuesObject) {
      return t('assessment.history.approval.create.main', {
        requiredNumber: newValuesObject.RequiredApprovals,
      });
    } else {
      return t('assessment.history.approval.create.catch');
    }
  }

  if (type === AuditActionType.Update) {
    if (newValuesObject) {
      if (oldValuesObject.RequiredApprovals !== newValuesObject.RequiredApprovals) {
        return t('assessment.history.approval.update.required', {
          requiredNumber: newValuesObject.RequiredApprovals,
        });
      }

      if (oldValuesObject.CurrentApprovals !== newValuesObject.CurrentApprovals) {
        return t('assessment.history.approval.update.current', {
          numberOfApprovals: newValuesObject.CurrentApprovals,
          requiredNumber: newValuesObject.RequiredApprovals,
        });
      }
    } else {
      return t('assessment.history.approval.update.catch');
    }
  }
  return t('assessment.history.approval.update.unknown');
};

export const getAuditRecord = (audit: Audit, t: TFunction) => {
  switch (audit.entity) {
    case AuditEntity.Assessment:
      return getAssessmentAuditRecord(audit, t);
    case AuditEntity.AssessmentPermissionGroupUser:
      return getAssessmentPermissionGroupUserAuditRecord(audit, t);
    case AuditEntity.AssessmentApprover:
      return getAssessmentApproverAuditRecord(audit, t);
    case AuditEntity.AssessmentReviewComment:
      return getAssessmentReviewCommentAuditRecord(audit, t);
    case AuditEntity.AssessmentApproval:
      return getAssessmentApprovalAuditRecord(audit, t);
    default:
      return t('room.historyContent.unknownAuditAction');
  }
};
