import { Attribute } from '@datx/core';

import { FormUser } from 'models/FormUser';
import { Resource } from 'models/Resource';
import { Comment } from 'models/Comment';

import { AssessmentApprover } from './AssessmentApprover';

export class AssessmentReview extends Resource {
  public static type = 'assessment_review';

  @Attribute({ isIdentifier: true })
  public assessmentId!: string;

  @Attribute({ toMany: FormUser })
  public approvers!: Array<AssessmentApprover>;

  @Attribute({ toMany: Comment })
  public comments!: Array<Comment>;
}
