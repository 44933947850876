import { VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { NavigationLink } from './NavigationLink';

export const Sidebar = () => {
  const { t } = useTranslation();

  return (
    <VStack bg="grey.lvl3" spacing="0">
      <NavigationLink
        icon={'icon-grid-view-outline'}
        title={t('sidebar.navigation.roomsLabel')}
        to="/rooms"
      />
      <NavigationLink
        icon={'icon-news-outline'}
        title={t('sidebar.navigation.formsLabel')}
        to="/forms"
      />
      <NavigationLink
        icon={'icon-survey-outline'}
        title={t('sidebar.navigation.assessmentsLabel')}
        to="/assessments"
      />
      <NavigationLink
        icon={'icon-spider-chart-outline'}
        title={t('sidebar.navigation.statisticsLabel')}
        to="/statistics"
      />
    </VStack>
  );
};
