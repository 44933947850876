import { FC } from 'types';
import { Control, FieldArrayWithId } from 'react-hook-form';
import { Box, Table, Thead, Tr, Th, Tbody } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { IFolderUserManagementFields } from 'typings/interfaces/IFolderUserManagementFields';
import { Total } from 'components/core/Total/Total';

import { FolderUserManagementRow } from './components/FolderUserManagementRow/FolderUserManagementRow';

interface IFolderUserManagementTableProps {
  fields: Array<FieldArrayWithId<IFolderUserManagementFields, 'users', 'id'>>;
  control: Control<IFolderUserManagementFields>;
  remove(index: number): void;
}
export const FolderUserManagementTable: FC<IFolderUserManagementTableProps> = ({
  fields,
  control,
  remove,
}) => {
  const { t } = useTranslation();

  return (
    <Box overflowX="auto">
      <Table size="md" variant="list" mt={20}>
        <Thead>
          <Tr>
            <Th>
              <Total label={t('folder.folderMembers.table.totalUsers')} total={fields.length} />
            </Th>
            <Th>{t('folder.folderMembers.table.role')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {fields.map((user, index) => (
            <FolderUserManagementRow
              key={user.userId}
              user={user}
              index={index}
              control={control}
              onRemove={() => remove(index)}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
