import { FormControl, FormControlProps, Text } from '@chakra-ui/react';
import { FC } from 'types';
import { Select } from 'appkit-react';
import { ErrorMessage } from '@hookform/error-message';
import { useController, UseControllerProps } from 'react-hook-form';

import { IFieldProps } from 'typings/interfaces/IFieldProps';
import { StyledSelect } from 'components/core/StyledAppKit/StyledAppKit';
import { FormFieldLabel } from 'components/core/FormFieldLabel/FormFieldLabel';

interface ISelectFieldProps
  extends IFieldProps,
    Omit<FormControlProps, 'onChange' | 'defaultValue'> {
  defaultValue?: string;
  onChange(value: string): void;
  showSearchOnToggle?: boolean;
}

export const SelectField: FC<ISelectFieldProps> = ({
  name,
  label,
  defaultValue,
  htmlFor,
  children,
  errors,
  sx,
  onChange,
  ...props
}) => {
  return (
    <FormControl sx={sx}>
      {label && <FormFieldLabel htmlFor={htmlFor}>{label}</FormFieldLabel>}
      <Select name={name} onSelect={onChange} defaultValue={defaultValue} {...props}>
        {children}
      </Select>
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <Text className="a-form-error">{message}</Text>}
        />
      )}
    </FormControl>
  );
};

interface IControlledSelectFieldProps
  extends UseControllerProps,
    Omit<FormControlProps, 'onChange' | 'defaultValue'> {
  htmlFor?: string;
  showSearchOnToggle?: boolean;
  label?: string;
  dropdownAlwaysDown?: boolean;
  disabled?: boolean;
}

export const ControlledSelectField: FC<IControlledSelectFieldProps> = ({
  control,
  name,
  rules,
  label,
  dropdownAlwaysDown = true,
  htmlFor,
  sx,
  children,
  ...rest
}) => {
  const {
    field: { onChange, ...restField },
    fieldState: { error },
  } = useController({ control, name, rules });

  return (
    <FormControl sx={sx}>
      {label && <FormFieldLabel htmlFor={htmlFor}>{label}</FormFieldLabel>}
      <StyledSelect
        onSelect={onChange}
        {...restField}
        dropdownAlwaysDown={dropdownAlwaysDown}
        {...rest}
        variant={undefined}
      >
        {children}
      </StyledSelect>
      {error && <span className="a-form-error">{error.message}</span>}
    </FormControl>
  );
};
