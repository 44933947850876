import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { Box, Table, Thead, Tr, Th, Tbody } from '@chakra-ui/react';

import { Room } from 'models/Room';
import { RoomUser } from 'models/RoomUser';
import { RoomUserRole } from 'typings/enums/RoomUserRole';
import { Scrollbar } from 'components/core/ScrollBar/Scrollbar';
import { Total } from 'components/core/Total/Total';

import { RoomUsersListViewItem } from './components/RoomUserListViewItem';

interface IRoomUsersListViewProps {
  addedUsers: Array<RoomUser>;
  room: Room;
  disabled?: boolean;
  onChangeRole(userId: string, role: RoomUserRole): void;
  onChangeExpiration(userId: string, expirationDate: Date): void;
  onRemoveUser(userId: string): void;
}
export const RoomUsersListView: FC<IRoomUsersListViewProps> = ({
  addedUsers,
  room,
  disabled = false,
  onChangeRole,
  onRemoveUser,
  onChangeExpiration,
}) => {
  const { t } = useTranslation();

  const numberOfAdmins = addedUsers.reduce((count, user) => {
    return user.roomUserRole === RoomUserRole.Admin ? count + 1 : count;
  }, 0);

  return (
    <Box overflowX="auto">
      <Scrollbar height="340px">
        <Table size="md" variant="list" mt={20}>
          <Thead>
            <Tr>
              <Th>
                <Total
                  label={t(
                    'room.sidebar.roomDetails.modals.membersManagement.usersList.totalUsers',
                  )}
                  total={addedUsers.length}
                />
              </Th>
              <Th />
              <Th />
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {addedUsers.map((roomUser) => (
              <RoomUsersListViewItem
                key={roomUser.userId}
                roomUser={roomUser}
                currentUserIsAdmin={room.isRoomAdmin}
                disabled={
                  disabled || (numberOfAdmins === 1 && roomUser.roomUserRole === RoomUserRole.Admin)
                }
                isLastAdmin={numberOfAdmins === 1 && roomUser.roomUserRole === RoomUserRole.Admin}
                onChangeRole={onChangeRole}
                onRemoveUser={onRemoveUser}
                onChangeExpiration={onChangeExpiration}
              />
            ))}
          </Tbody>
        </Table>
      </Scrollbar>
    </Box>
  );
};
