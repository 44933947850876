import { Flex, Grid, Center } from '@chakra-ui/react';
import React, { FC, MouseEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { useTranslation } from 'react-i18next';

import { apiClient } from 'utils/apiClient';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { Room } from 'models/Room';
import { PopAlertFail } from 'services/PopAlertFail';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

import { RoomCardTitle } from './components/RoomCardTitle';
import { RoomCardLastEdit } from './components/RoomCardLastEdit';
import { RoomCardStats } from './components/RoomCardStats';
import { RoomCardFavorite } from './components/RoomCardFavorite';

interface IRoomCardProps {
  room: Room;
}

export const RoomCard: FC<IRoomCardProps> = ({ room }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { mutate } = useSWRConfig();
  const dependencyHeaders = useDependencyHeaders();
  const [isChangingFavorite, setIsChangingFavorite] = useState(false);

  const bgTopColor = room.isMyRoom ? 'utils.orange.200' : 'grey.lvl6';

  const handleEnterRoom = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    history.push(`/rooms/${room.id}`);
  };

  const handleSetFavorite = async () => {
    if (isChangingFavorite) {
      return;
    }
    setIsChangingFavorite(true);
    const url = `rooms/${room.id}/setFavorite`;
    const newValue = !room.isFavorite;

    try {
      await apiClient(url, HttpMethod.Post, {
        headers: {
          'content-type': 'application/json',
          ...dependencyHeaders,
        },
        body: JSON.stringify({
          isFavorite: newValue,
        }),
      });
      await mutate(`rooms@${dependencyHeaders['x-site-id']}`);
    } catch (response: any) { // eslint-disable-line
      if (response.statusCode === 400) {
        PopAlertFail(t('rooms.errors.updatingRoomStatus.main'), response.errors?.name);
      } else {
        PopAlertFail(t('rooms.errors.updatingRoomStatus.unexpected'), response.message);
      }
    } finally {
      setIsChangingFavorite(false);
    }
  };

  return (
    <Grid
      sx={{
        h: '254px',
        w: '338px',
        boxShadow: '0 3px 7px 1px rgba(255,255,255,0.16)',
        bgGradient: `linear(to-b, ${bgTopColor} 50%, grey.lvl4 50%)`,
        alignContent: 'space-between',
      }}
    >
      <Center mt="4px" p="0 24px">
        <RoomCardTitle>{room.name}</RoomCardTitle>
      </Center>

      <Flex justifyContent="space-evenly" alignItems="flex-end" marginBottom="20px">
        <RoomCardStats
          label={t('rooms.cardView.roomCard.stats.members')}
          value={room.members}
          visible={!room.isMyRoom}
        />
        <RoomCardFavorite
          isFavorite={room.isFavorite}
          onClick={handleSetFavorite}
          isLoading={isChangingFavorite}
        />
        <RoomCardStats
          label={t('rooms.cardView.roomCard.stats.forms')}
          value={room.formCount ?? 0}
        />
      </Flex>

      <Center>
        <RoomCardLastEdit>
          {t('rooms.cardView.roomCard.stats.lastEdit', {
            formattedDistanceSinceLastEdit: room.formattedDistanceSinceLastEdit,
          })}
        </RoomCardLastEdit>
      </Center>
      <Center mb="8px">
        <StyledButton size="lg" onClick={handleEnterRoom}>
          {t('rooms.cardView.roomCard.action.enter')}
        </StyledButton>
      </Center>
    </Grid>
  );
};
