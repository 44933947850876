import { Attribute } from '@datx/core';
import { computed } from 'mobx';

import { Resource } from 'models/Resource';
import { AssessmentFormComponent } from 'models/AssessmentFormComponent';
import { Campaign } from 'models/Campaign';
import { AssessmentStatus } from 'typings/enums/AssessmentStatus';
import { PermissionGroupUserDTO } from 'typings/types/dto/AssessmentDTO';
import { AssessmentViewActivationStatus } from 'typings/enums/AssessmentViewActivationStatus';
import { AssessmentStatusDisplay } from 'typings/types/AssessmentStatusDisplay';
import { IPermissionGroupDto } from 'typings/interfaces/dto/IPermissionGroupDto';
import { IAssessmentBase } from 'typings/interfaces/IAssessmentBase';

export class Assessment extends Resource implements IAssessmentBase {
  public static type = 'assessment';

  @Attribute({ isIdentifier: true })
  public readonly id!: string;

  @Attribute()
  public readonly name!: string;

  @Attribute()
  public readonly roomId!: string;

  @Attribute()
  public readonly formId!: string;

  @Attribute()
  public status!: AssessmentStatus;

  @Attribute({ toOne: Campaign })
  public readonly campaign!: Campaign;

  @Attribute()
  public permissionGroups!: Array<IPermissionGroupDto>;

  @Attribute({ toMany: AssessmentFormComponent })
  public assessmentFormComponents!: Array<AssessmentFormComponent>;

  @Attribute()
  public activationStatus!: AssessmentViewActivationStatus;

  @computed
  public get displayStatus(): AssessmentStatusDisplay {
    if (this.activationStatus === AssessmentViewActivationStatus.Deactivated) {
      return AssessmentViewActivationStatus.Deactivated;
    }
    return this.status;
  }

  @computed
  public get parsedCollaborators(): Array<PermissionGroupUserDTO> {
    return this.permissionGroups.flatMap((pg) =>
      pg.recipientIds.map((r) => ({ userId: r, permissionGroupId: pg.id })),
    );
  }

  @computed
  public get isEditableAfterSubmit() {
    return Boolean(this.campaign?.editAfterSubmission);
  }

  @computed
  public get isEditable() {
    return (
      this.activationStatus !== AssessmentViewActivationStatus.Deactivated &&
      this.status !== AssessmentStatus.Overdue &&
      (this.status === AssessmentStatus.InProgress ||
        this.status === AssessmentStatus.Initiated ||
        (this.status === AssessmentStatus.Submitted && this.isEditableAfterSubmit))
    );
  }

  public hasRecipient(email: string) {
    return this.permissionGroups
      .flatMap((permissionGroup) => permissionGroup.recipientEmails)
      .some((recipientEmail) => recipientEmail.toLowerCase() === email);
  }

  public hasInitiator(email: string) {
    return Boolean(this.campaign?.initiators?.some((campaignUser) => campaignUser.email === email));
  }

  public isValid(skippedComponents: Array<string>): boolean {
    return !this.assessmentFormComponents.some(
      (component) => !component.isValid && !skippedComponents.includes(component.id),
    );
  }
}
