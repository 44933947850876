import { FC } from 'types';
import { Box, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Control, FieldArrayWithId } from 'react-hook-form';

import { Total } from 'components/core/Total/Total';
import { ICampaignInitiatorsFormData } from 'typings/interfaces/ICampaignFormData';

import { CampaignInitiatorManagementRow } from '../CampaignInitiatorManagementRow/CampaignInitiatorManagementRow';

interface ICampaignInitiatorManagementTableProps {
  fields: Array<FieldArrayWithId<ICampaignInitiatorsFormData, 'initiators', 'id'>>;
  control: Control<ICampaignInitiatorsFormData>;
  remove(index: number): void;
}
export const CampaignInitiatorManagementTable: FC<ICampaignInitiatorManagementTableProps> = ({
  fields,
  control,
  remove,
}) => {
  const { t } = useTranslation();

  return (
    <Box overflowX="auto">
      <Table size="md" variant="list" mt={20}>
        <Thead>
          <Tr>
            <Th>
              <Total label={t('folder.folderMembers.table.totalUsers')} total={fields.length} />
            </Th>
            <Th />
            <Th />
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {fields.map((user, index) => (
            <CampaignInitiatorManagementRow
              key={user.userId}
              user={user}
              index={index}
              control={control}
              onRemove={() => remove(index)}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
