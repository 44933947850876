import { FC } from 'types';
import { Control } from 'react-hook-form';
import { Box, Tr, Td, HStack, Text, Center } from '@chakra-ui/react';

import { FolderUserRole } from 'typings/enums/FolderUserRole';
import { Avatar } from 'components/core/Avatar/Avatar';
import { IFolderUser } from 'typings/interfaces/IFolderUser';
import { IFolderUserManagementFields } from 'typings/interfaces/IFolderUserManagementFields';

import { FolderUserRoleButtonGroupField } from '../FolderUserRoleButtonGroupField/FolderUserRoleButtonGroupField';

interface IFolderUserManagementRowProps {
  user: IFolderUser;
  index: number;
  control: Control<IFolderUserManagementFields>;
  onRemove(): void;
}
export const FolderUserManagementRow: FC<IFolderUserManagementRowProps> = ({
  user,
  index,
  control,
  onRemove,
}) => {
  return (
    <Tr key={user.userId}>
      <HStack as={Td}>
        <Avatar name={user.username} />
        <Text overflowWrap="anywhere">{user.displayName ?? user.username}</Text>
      </HStack>
      <Td>
        <FolderUserRoleButtonGroupField
          control={control}
          name={`users.${index}.folderUserRole` as const}
        />
      </Td>

      {user.folderUserRole !== FolderUserRole.Owner ? (
        <Center as={Td}>
          <Box
            as="span"
            className="appkiticon icon-circle-delete-outline a-font-24 a-text-orange"
            cursor="pointer"
            onClick={onRemove}
          />
        </Center>
      ) : (
        <Td />
      )}
    </Tr>
  );
};
