import { Box, BoxProps, FormControl, FormControlProps } from '@chakra-ui/react';
import { FC } from 'types';
import { TextArea } from 'appkit-react';
import { useController, UseControllerProps } from 'react-hook-form';

import { IFieldProps } from 'typings/interfaces/IFieldProps';

interface ITextAreaFieldProps extends IFieldProps, Omit<BoxProps, 'onChange' | 'defaultValue'> {
  defaultValue?: string | undefined;
  autosize?: boolean;
  onChange?(value: string): void;
}

export const TextAreaField: FC<ITextAreaFieldProps> = ({ name, label, sx, onChange, ...props }) => {
  return (
    <Box sx={sx}>
      <TextArea
        name={name}
        label={
          label && (
            <Box as="span" textTransform="uppercase">
              {label}
            </Box>
          )
        }
        onChange={onChange}
        {...props}
      />
    </Box>
  );
};

interface IControlledTextAreaFieldProps
  extends UseControllerProps,
    Omit<FormControlProps, 'onChange' | 'defaultValue'> {
  defaultValue?: string | undefined;
  autosize?: boolean;
  label?: string;
}

export const ControlledTextAreaField: FC<IControlledTextAreaFieldProps> = ({
  control,
  name,
  rules,
  sx,
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, rules });
  return (
    <FormControl sx={sx}>
      <TextArea {...field} {...rest} />
      {error && <p className="a-form-error">{error.message}</p>}
    </FormControl>
  );
};
