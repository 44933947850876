import { TFunction } from 'react-i18next';

import { PopAlertFail } from 'services/PopAlertFail';
import { exportDownload } from 'utils/exportDownload';
import { appDataFetcher } from 'fetchers/appExport';

type AppExportOption = 'sites' | 'domains' | 'forms' | 'statistics';

export type AppExportFormValues = Record<AppExportOption, boolean>;
interface IExportOption {
  label: string;
  value: AppExportOption;
  disabled?: boolean;
}
export const exportOptions: Array<IExportOption> = [
  { label: 'export.modal.checkbox.optionSitesLabel', value: 'sites' },
  { label: 'export.modal.checkbox.optionDomainsLabel', value: 'domains' },
  {
    label: 'export.modal.checkbox.optionFormsLabel',
    value: 'forms',
    disabled: true,
  },
  {
    label: 'export.modal.checkbox.optionStatisticsLabel',
    value: 'statistics',
    disabled: true,
  },
];

const getAppExportDto = ({ sites, domains, forms, statistics }: AppExportFormValues) => ({
  doExportDomains: domains,
  doExportForms: forms,
  doExportStatistics: statistics,
  doExportSites: sites,
});

export const handleAppDataCsvExport = async (t: TFunction, values: AppExportFormValues) => {
  try {
    await exportDownload(appDataFetcher(getAppExportDto(values)));
  } catch {
    PopAlertFail(t('export.errors.dataDownload.main'));
  }
};
