import { FC } from 'types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';

import { FormActionButton } from 'components/core/FormActions/FormActionButton';
import { ApprovalEntity } from 'typings/enums/ApprovalEntity';

import { ReviewEditWarningModal } from '../ReviewEditWarningModal/ReviewEditWarningModal';

interface IReviewEditButtonProps {
  entityType: ApprovalEntity;
  disabled: boolean;
  onConfirm(): Promise<void>;
}

export const ReviewEditButton: FC<IReviewEditButtonProps> = ({
  entityType,
  disabled,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Fragment>
      <FormActionButton kind="secondary" disabled={disabled} onClick={onOpen}>
        {t('review.action.edit', { entity: t(`approval.entity.${entityType}`) })}
      </FormActionButton>
      {isOpen && (
        <ReviewEditWarningModal entityType={entityType} onClose={onClose} onConfirm={onConfirm} />
      )}
    </Fragment>
  );
};
