import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSWRConfig } from 'swr';

import { HttpMethod } from 'typings/enums/HttpMethod';
import { IApiClientError } from 'typings/interfaces/IApiClientError';
import { apiClient } from 'utils/apiClient';
import { useTenant } from 'hooks/useTenant';

export interface IApiClientFnResult<TResult> {
  data: TResult | undefined;
  error?: IApiClientError;
  pending: boolean;
}

type UseApiClientResult<TResult> = [
  (data?: Record<string, unknown> | undefined) => Promise<IApiClientFnResult<TResult>>,
  {
    data: TResult | undefined;
    error?: IApiClientError;
    pending: boolean;
  },
];
export const useApiClient = <TResult = undefined>({
  url,
  method,
  immediate = false,
  options,
  mutateKeys,
}: {
  url: string;
  method: HttpMethod;
  immediate?: boolean;
  options?: Record<string, unknown>;
  mutateKeys?: Array<string>;
}): UseApiClientResult<TResult> => {
  const [settings] = useState(() => ({
    mutateKeys: mutateKeys ?? [],
    options: options ?? {},
  }));

  const [pending, setPending] = useState(false);
  const [error, setError] = useState(undefined);
  const [responseData, setResponseData] = useState<TResult | undefined>(undefined);

  const siteId = useTenant().activeTenant?.siteId;
  const { mutate } = useSWRConfig();

  const execute = useCallback(
    async (data?: Record<string, unknown>) => {
      const result: {
        pending: boolean;
        data: TResult | undefined;
        error: IApiClientError | undefined;
      } = {
        pending: true,
        data: undefined,
        error: undefined,
      };
      if (!siteId) {
        return result;
      }
      // TODO: pass additional options and use lodash merge to override defaults if needed
      setPending(true);
      setResponseData(undefined);
      setError(undefined);

      try {
        const response = await apiClient(url, method, {
          ...settings.options,
          headers: {
            'Content-Type': 'application/json',
            'x-site-id': siteId,
          },
          body: JSON.stringify(data),
        });
        setResponseData(response);
        result.data = response;
      } catch (error: any) { // eslint-disable-line
        setError(error);
        result.error = error;
      } finally {
        setPending(false);
        result.pending = false;
        Promise.all(settings.mutateKeys.map((mutateKey) => mutate(`${mutateKey}@${siteId}`)));
      }
      return result;
    },
    [method, mutate, settings.mutateKeys, settings.options, siteId, url],
  );

  useEffect(() => {
    if (immediate && siteId) {
      execute();
    }
  }, [execute, immediate, siteId]);

  const state = useMemo(
    () => ({ data: responseData, error, pending }),
    [error, pending, responseData],
  );

  return [execute, state];
};
