import groupBy from 'lodash/groupBy';

import { FormComponentType } from 'typings/enums/FormComponentType';
import { IFormComponentDto } from 'typings/interfaces/dto/IFormComponentDto';
import { IFormComponentAddonDto } from 'typings/interfaces/dto/IFormComponentAddonDto';
import { sumArrayValues } from 'utils/sumArrayValues';

interface IFormComponentRaw {
  id: string;
  formId: string;
  text: string;
  componentType: FormComponentType;
  valueJson: string | null;
  addons: Array<IFormComponentAddonDto> | null;
  settingsJson: string | null;
  index: number;
  parentComponentId: string | null;
  childStartIndex: number | null;
}
// to each section add the global index of the first child component for that section
// this can't be done in preprocess (in datx model) because we need to know the order and children count for each other section
// TODO: find the better way for this, because this only works when we have all of the data, and has to be recalculated on each component add/remove/move for all other components
export const parseFormComponentsDto = (
  apiResponse: Array<IFormComponentDto>,
): Array<IFormComponentRaw> => {
  if (apiResponse.length === 0) {
    return [];
  }
  const groups = groupBy(apiResponse, 'parentComponentId');
  const sections = groups.null.sort((a, b) => a.index - b.index);
  const childrenCounts: Array<number> = sections.map((section) => {
    const children = groups[section.id];
    return children?.length ?? 0;
  });

  return apiResponse.map((item: IFormComponentDto) => ({
    ...item,
    childStartIndex: item.parentComponentId ? null : sumArrayValues(childrenCounts, item.index - 1),
  }));
};
