import { RoomStatus } from 'typings/enums/RoomStatus';

// TODO: translations
export function getOptionsForStatus(status: RoomStatus) {
  return [
    {
      value: RoomStatus.Approved,
      text: 'Approve',
    },
    {
      value: status,
      text: 'Pending',
    },
    { value: RoomStatus.Rejected, text: 'Reject' },
  ];
}
