import { useCallback, useState } from 'react';
import { FC } from 'types'
import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Room } from 'models/Room';
import { Modal } from 'components/modal/Modal/Modal';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { ModalFooter } from 'components/modal/Modal/components/ModalFooter';
import { RoomMembersManagement } from 'components/room/RoomMembersManagement/RoomMembersManagement';
import { IRoomUser } from 'typings/interfaces/IRoomUser';
import { transformRoomUsersToDto } from 'services/transformDto/transformFormsData';
import { StyledButton } from 'components/core/StyledAppKit/StyledAppKit';

import { useUpdateRoomUsers } from '../room.hooks';

interface IRoomMembersManagementModalProps {
  room: Room;
  readonly?: boolean;
  onClose(): void;
}

export const RoomMembersManagementModal: FC<IRoomMembersManagementModalProps> = ({
  room,
  readonly,
  onClose,
}) => {
  const { save, isSaving } = useUpdateRoomUsers(room.id);
  const [roomUsersJSON, setRoomUsersJSON] = useState<Array<IRoomUser>>();
  const { t } = useTranslation();

  const handleConfirm = async () => {
    if (readonly || isSaving) {
      return;
    }

    await save({
      ...room.upsertDTO,
      roomUsers: transformRoomUsersToDto(roomUsersJSON ?? []),
    });
    onClose();
  };

  const handleChange = useCallback((values: Array<IRoomUser>) => {
    setRoomUsersJSON(values);
  }, []);

  return (
    <Modal isVisible maxWidth="755px">
      <ModalHeader onCancel={onClose}>
        <ModalTitle>{t('room.sidebar.roomDetails.modals.membersManagement.title')}</ModalTitle>
      </ModalHeader>
      <ModalBody py={40} px={20}>
        <RoomMembersManagement room={room} onChange={handleChange} />
      </ModalBody>
      <ModalFooter pb={20}>
        <HStack spacing={20}>
          <StyledButton size="lg" kind="secondary" onClick={onClose}>
            {t('action.cancel.label')}
          </StyledButton>
          <StyledButton
            size="lg"
            onClick={handleConfirm}
            disabled={isSaving || readonly}
            isLoading={isSaving}
          >
            {t('action.save.label')}
          </StyledButton>
        </HStack>
      </ModalFooter>
    </Modal>
  );
};
