import { Box, Text, Flex } from '@chakra-ui/react';
import { FC } from 'types';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Select, SelectOption } from 'appkit-react';
import { useTranslation } from 'react-i18next';
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldValues,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { updateModel } from '@datx/core';
import { Utils as QbUtils } from 'react-awesome-query-builder';

import { RemoveButton } from 'components/form-editor/Question/components/RemoveButton/RemoveButton';
import { Campaign } from 'models/Campaign';
import { TagInput } from 'components/core/TagInput/TagInput';
import { InputNumberField } from 'fields/InputNumberField/InputNumberField';
import { ControlledSelectField } from 'fields/SelectField/SelectField';
import { IAssessmentSetupFormValues } from 'typings/interfaces/formValues/IAssessmentSetupFormValues';
import { useDatx } from 'hooks/useDatx';
import { FormFieldError } from 'components/core/FormFieldError/FormFieldError';

import { assessmentName, getAvailablePermissionGroupId } from './OneRecipientGroupForm.utils';
import { ControlledInputField } from 'fields/InputField/InputField';

interface IOneRecipientGroupFormProps {
  campaign: Campaign;
  control: Control<IAssessmentSetupFormValues>;
}
export const OneRecipientGroupForm: FC<IOneRecipientGroupFormProps> = observer(
  ({ campaign, control }) => {
    const { t } = useTranslation();
    const { fields, append, remove } = useFieldArray({
      control,
      name: 'assessmentPermissionGroups',
    });
    const datx = useDatx();

    const watchNumberOfAssessments = useWatch({ control, name: 'numberOfAssessments' });
    const watchAssessmentPermissionGroups = useWatch({
      control,
      name: 'assessmentPermissionGroups',
    });

    const isAssessmentSelectDisabled = useMemo(
      () =>
        watchAssessmentPermissionGroups.length
          ? !watchAssessmentPermissionGroups[watchAssessmentPermissionGroups.length - 1]
              .permissionGroupId
          : false,
      [watchAssessmentPermissionGroups],
    );

    const onNewAssessmentSelect = (index: number) => {
      const name = assessmentName(index);
      const assessment = fields.find((field) => field.name === name);
      const id = assessment?.id ?? `${index} - ${Date.now()}`;
      const nextPermissionGroupId = getAvailablePermissionGroupId(
        watchAssessmentPermissionGroups,
        campaign.permissionGroups,
        assessment?.id,
      );

      append({
        id,
        keyId: `${name}@${QbUtils.uuid()}`,
        name,
        permissionGroupId: nextPermissionGroupId!,
        recipientEmails: null,
      });
    };

    const onAssessmentRemove = (
      id: number,
      field: FieldArrayWithId<IAssessmentSetupFormValues, 'assessmentPermissionGroups', 'id'>,
    ) => {
      const assessmentPermissionGroup = campaign.assessmentPermissionGroups.find(
        (group) => group.id === field.id,
      );

      const permissionGroups = assessmentPermissionGroup?.permissionGroups.filter(
        (group) => group.id !== field.permissionGroupId,
      );

      if (assessmentPermissionGroup) {
        updateModel(assessmentPermissionGroup, {
          permissionGroups,
        });

        if (assessmentPermissionGroup?.permissionGroups.length < 1) {
          datx.removeOne(assessmentPermissionGroup);
        }
      }

      remove(id);
    };

    const getPermissionGroupSelectPlaceholder = (
      field: FieldArrayWithId<IAssessmentSetupFormValues, 'assessmentPermissionGroups', 'id'>,
    ) => {
      const permissionGroup = campaign.permissionGroups.find(
        (group) => group.id === field?.permissionGroupId,
      );

      return permissionGroup?.name ?? t('campaign.setup.selectPermissionGroup');
    };

    return (
      <Box>
        <Flex alignItems="center" mb={30}>
          <Text mr={5}> {t('campaign.setup.numberOfAssessments')}</Text>
          <InputNumberField
            min={1}
            max={100}
            step={1}
            sx={{ w: 'auto', mb: '0px' }}
            kind="separate"
            control={control as unknown as Control<FieldValues>}
            name="numberOfAssessments"
          />
        </Flex>
        <Flex
          alignItems="center"
          sx={{
            backgroundColor: 'grey.lvl3',
            height: '34px',
            px: 12,
          }}
        >
          <Text size="md" width="300px">
            {t('campaign.setup.assessments')}
          </Text>
          <Text size="md" width="300px">
            {t('campaign.setup.permissionGroup')}
          </Text>
          <Text size="md">{t('campaign.setup.recipients')}</Text>
        </Flex>
        <Box
          sx={{
            '> div': {
              backgroundColor: 'grey.lvl6',
              ':nth-of-type(even)': {
                backgroundColor: 'grey.lvl5',
              },
            },
          }}
        >
          {fields.map((field, index) => {
            return (
              <Flex
                key={field.keyId}
                alignItems="center"
                sx={{
                  minHeight: '34px',
                  px: 12,
                  '.a-select-wrapper': {
                    width: '300px',
                  },
                }}
              >
                <ControlledInputField
                  sx={{ w: '300px' }}
                  control={control as unknown as Control<FieldValues>}
                  htmlFor="name"
                  name={`assessmentPermissionGroups.${index}.name` as const}
                  defaultValue={field.name}
                  rules={{ required: { value: true, message: t('validation.required') } }}
                />

                <ControlledSelectField
                  control={control as unknown as Control<FieldValues>}
                  htmlFor={`assessmentPermissionGroups.${index}.permissionGroupId`}
                  name={`assessmentPermissionGroups.${index}.permissionGroupId` as const}
                  placeholder={getPermissionGroupSelectPlaceholder(field)}
                  sx={{ w: '30%' }}
                >
                  {campaign.permissionGroups.map((group) => {
                    return (
                      <SelectOption
                        key={group.id}
                        value={group.id}
                        disabled={watchAssessmentPermissionGroups.some((item) => {
                          return item.id === field.id && item.permissionGroupId === group.id;
                        })}
                      >
                        {group.name}
                      </SelectOption>
                    );
                  })}
                </ControlledSelectField>

                <Flex alignItems="center" flex="2">
                  <Controller
                    control={control}
                    name={`assessmentPermissionGroups.${index}.recipientEmails` as const}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <TagInput
                          sx={{ flex: '2', overflow: 'auto' }}
                          tags={value || []}
                          placeholder={t('campaign.setup.emails')}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                  <RemoveButton
                    onClick={() => {
                      onAssessmentRemove(index, field);
                    }}
                  />
                </Flex>
              </Flex>
            );
          })}

          <Select
            value=""
            onSelect={onNewAssessmentSelect}
            disabled={isAssessmentSelectDisabled}
            placeholder={t('campaign.setup.selectAssessment')}
          >
            {[...new Array(watchNumberOfAssessments)].map((_, index) => (
              <SelectOption
                key={index}
                value={index}
                disabled={
                  fields.filter((item) => item.name === assessmentName(index)).length ===
                  campaign.permissionGroups.length
                }
              >
                {assessmentName(index)}
              </SelectOption>
            ))}
          </Select>
        </Box>
        <FormFieldError
          name="assessmentPermissionGroups"
          control={control as unknown as Control<FieldValues>}
        />
      </Box>
    );
  },
);
