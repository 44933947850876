import { FormUserInitiatorStatus } from 'typings/enums/FormUserInitiatorStatus';
import { FormUserRole } from 'typings/enums/FormUserRole';
import { IFormUser } from 'typings/interfaces/IFormUser';
import { IResourceUser } from 'typings/interfaces/IResourceUser';

export const transformUserToFormUser = ({
  id,
  userId,
  username,
  email,
  displayName,
}: IResourceUser): IFormUser => ({
  id,
  userId,
  username,
  email,
  displayName,
  role: FormUserRole.Edit,
  defaultInitiatorStatus: FormUserInitiatorStatus.Na,
});
