import { FC } from 'types';
import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/modal/Modal/Modal';
import { ModalHeader } from 'components/modal/Modal/components/ModalHeader';
import { ModalTitle } from 'components/modal/Modal/components/ModalTitle';
import { ModalBody } from 'components/modal/Modal/components/ModalBody';
import { ModalFooter } from 'components/modal/Modal/components/ModalFooter';
import { FormActionButton } from 'components/core/FormActions/FormActionButton';
import { FormButtonCancel } from 'components/core/FormActions/FormButtonCancel';

interface IConfirmationModalProps {
  title: string;
  maxWidth: string;
  confirmText?: string;
  cancelText?: string;
  showLoader?: boolean;
  disabled?: boolean;
  onCancel(): void;
  onConfirm(): void;
}

export const ConfirmationModal: FC<IConfirmationModalProps> = ({
  title,
  maxWidth,
  confirmText,
  cancelText,
  showLoader = false,
  disabled = false,
  onCancel,
  onConfirm,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isVisible maxWidth={maxWidth}>
      <ModalHeader onCancel={onCancel}>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody py={40} px={20}>
        {children}
      </ModalBody>
      <ModalFooter>
        <HStack spacing={20}>
          <FormButtonCancel onClick={onCancel}>{cancelText}</FormButtonCancel>
          <FormActionButton onClick={onConfirm} disabled={disabled} isLoading={showLoader}>
            {confirmText || t('action.confirm.label')}
          </FormActionButton>
        </HStack>
      </ModalFooter>
    </Modal>
  );
};
