import useSWR from 'swr';

import { Comment } from 'models/Comment';
import { HttpMethod } from 'typings/enums/HttpMethod';
import { apiClient } from 'utils/apiClient';
import { useDatx } from './useDatx';
import { useCurrentRoom } from './useCurrentRoom';
import { useDependencyHeaders } from './useDependencyHeaders';

export const useComments = () => {
  const depHeaders = useDependencyHeaders();

  const { room } = useCurrentRoom();
  const datx = useDatx();

  const key = `comments-${room?.id}@${depHeaders['x-site-id']}`;

  const swr = useSWR(
    room?.id ? key : null,
    async () => {
      const response = await apiClient(`rooms/${room?.id}/comments`, HttpMethod.Get, {
        headers: {
          ...depHeaders,
        },
      });

      const comments: Array<Comment> = datx.add(response, 'comments');

      return comments.sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  return {
    isInitialLoad: !swr.data && !swr.error,
    ...swr,
  };
};
