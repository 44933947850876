import { FormConclusion } from 'models/FormConclusion';
import { DraggingAction } from 'typings/enums/DraggingAction';
import { DropAction } from 'typings/enums/DropAction';
import { FormAddonType } from 'typings/enums/FormAddonType';
import { FormComponentType } from 'typings/enums/FormComponentType';
import { FormEditorDraggable } from 'typings/enums/FormEditorDraggable';
import { FormEditorDroppable } from 'typings/enums/FormEditorDroppable';

function zoneIsSidebarComponents(zoneId: string) {
  return zoneId.startsWith(`${FormEditorDroppable.SidebarComponents}-`);
}

function zoneIsSidebarAddons(zoneId: string) {
  return zoneId.startsWith(`${FormEditorDroppable.SidebarAddons}-`);
}

function zoneIsQuestionAddons(zoneId: string) {
  return zoneId.startsWith(`${FormEditorDroppable.QuestionAddons}-`);
}

function zoneIsSectionContent(zoneId: string) {
  return zoneId.startsWith(`${FormEditorDroppable.SectionContent}-`);
}

function zoneIsQuestionSetContent(zoneId: string) {
  return zoneId.startsWith(`${FormEditorDroppable.QuestionSetQuestions}-`);
}

function zoneIsConclusionType(zoneId: string) {
  return zoneId.startsWith('conclusions');
}

function getDraggableComponentType(draggableId: string) {
  return draggableId.substring(`${FormEditorDraggable.SidebarQuestion}-`.length);
}

function getDraggableAddonType(draggableId: string) {
  return draggableId.substring(`${FormEditorDraggable.SidebarAddon}-`.length);
}

export function getModelIdFromDraggable(draggableId: string) {
  if (draggableId.startsWith(`${FormEditorDraggable.Question}-`)) {
    return draggableId.substring(`${FormEditorDraggable.Question}-`.length);
  }

  if (draggableId.startsWith(`${FormEditorDraggable.Section}-`)) {
    return draggableId.substring(`${FormEditorDraggable.Section}-`.length);
  }

  if (draggableId === FormEditorDraggable.Conclusion) {
    return FormConclusion.type;
  }

  if (draggableId.startsWith('conclusion-')) {
    return draggableId.substring('section-'.length);
  }

  if (draggableId === FormEditorDraggable.ConditionGroup) {
    return 'condition_group';
  }

  throw Error('Unknown draggable');
}

export function inferDraggingAction(draggableId: string, sourceId: string) {
  if (zoneIsSidebarComponents(sourceId) && draggableId === FormEditorDraggable.SidebarSection) {
    return DraggingAction.AddSectionComponent;
  }
  if (zoneIsSidebarComponents(sourceId) && draggableId !== FormEditorDraggable.SidebarSection) {
    return DraggingAction.AddQuestionComponent;
  }
  if (
    sourceId === FormEditorDroppable.Sections &&
    draggableId.startsWith(`${FormEditorDraggable.Section}-`)
  ) {
    return DraggingAction.MoveSection;
  }
  if (
    zoneIsSidebarAddons(sourceId) &&
    draggableId.startsWith(`${FormEditorDraggable.SidebarAddon}-`)
  ) {
    const addonType = getDraggableAddonType(draggableId);
    switch (addonType as FormAddonType) {
      case FormAddonType.Condition:
        return DraggingAction.AddConditionAddon;
      case FormAddonType.SkipCondition:
        return DraggingAction.AddSkipConditionAddon;
      case FormAddonType.MoreAnswers:
        return DraggingAction.AddMoreAnswersAddon;
      default:
        return DraggingAction.AddAddon;
    }
  }
  if (
    zoneIsSectionContent(sourceId) &&
    draggableId.startsWith(`${FormEditorDraggable.Question}-`)
  ) {
    return DraggingAction.MoveQuestion;
  }
  if (
    zoneIsQuestionSetContent(sourceId) &&
    draggableId.startsWith(`${FormEditorDraggable.Question}-`)
  ) {
    return DraggingAction.MoveQuestion;
  }
  if (zoneIsConclusionType(sourceId) && draggableId === FormEditorDraggable.Conclusion) {
    return DraggingAction.AddConclusion;
  }
  if (zoneIsConclusionType(sourceId) && draggableId.startsWith('conclusion-')) {
    return DraggingAction.MoveConclusion;
  }
  if (zoneIsConclusionType(sourceId) && draggableId === FormEditorDraggable.ConditionGroup) {
    return DraggingAction.AddConditionGroup;
  }

  return DraggingAction.None;
}

export function inferDropAction(
  draggableId: string,
  sourceDroppableId: string,
  destinationDroppableId?: string,
) {
  if (!destinationDroppableId) {
    return { dropAction: DropAction.None };
  }

  // Add new Section from sidebar
  if (
    destinationDroppableId === FormEditorDroppable.Sections &&
    zoneIsSidebarComponents(sourceDroppableId) &&
    draggableId === FormEditorDraggable.SidebarSection
  ) {
    return {
      dropAction: DropAction.AddSectionComponent,
      draggableComponentType: FormComponentType.Section,
    };
  }

  // Add new Question from sidebar to Section
  if (
    zoneIsSectionContent(destinationDroppableId) &&
    zoneIsSidebarComponents(sourceDroppableId) &&
    draggableId.startsWith(`${FormEditorDraggable.SidebarQuestion}-`)
  ) {
    const destinationId = destinationDroppableId.substring(
      FormEditorDroppable.SectionContent.length + 1,
    );
    return {
      dropAction: DropAction.AddQuestionComponent,
      draggableComponentType: getDraggableComponentType(draggableId),
      destinationId,
    };
  }

  // Add new Question from sidebar to Question set
  if (
    zoneIsQuestionSetContent(destinationDroppableId) &&
    zoneIsSidebarComponents(sourceDroppableId) &&
    draggableId.startsWith(`${FormEditorDraggable.SidebarQuestion}-`)
  ) {
    const destinationId = destinationDroppableId.substring(
      FormEditorDroppable.QuestionSetQuestions.length + 1,
    );
    return {
      dropAction: DropAction.AddQuestionComponent,
      draggableComponentType: getDraggableComponentType(draggableId),
      destinationId,
    };
  }

  // Move Section from one index to another
  if (
    destinationDroppableId === FormEditorDroppable.Sections &&
    sourceDroppableId === FormEditorDroppable.Sections &&
    draggableId.startsWith(`${FormEditorDraggable.Section}-`)
  ) {
    return {
      dropAction: DropAction.MoveSection,
      modelId: getModelIdFromDraggable(draggableId),
    };
  }

  // Move Question from Section or Question set to Section
  if (
    (zoneIsSectionContent(sourceDroppableId) &&
      zoneIsSectionContent(destinationDroppableId) &&
      draggableId.startsWith(`${FormEditorDraggable.Question}-`)) ||
    (zoneIsQuestionSetContent(sourceDroppableId) &&
      zoneIsSectionContent(destinationDroppableId) &&
      draggableId.startsWith(`${FormEditorDraggable.Question}-`))
  ) {
    const destinationId = destinationDroppableId.substring(
      FormEditorDroppable.SectionContent.length + 1,
    );
    return {
      dropAction: DropAction.MoveQuestion,
      destinationId,
      modelId: getModelIdFromDraggable(draggableId),
    };
  }

  // Move Question from Section or Question set to Question set
  if (
    (zoneIsSectionContent(sourceDroppableId) &&
      zoneIsQuestionSetContent(destinationDroppableId) &&
      draggableId.startsWith(`${FormEditorDraggable.Question}-`)) ||
    (zoneIsQuestionSetContent(sourceDroppableId) &&
      zoneIsQuestionSetContent(destinationDroppableId) &&
      draggableId.startsWith(`${FormEditorDraggable.Question}-`))
  ) {
    const destinationId = destinationDroppableId.substring(
      FormEditorDroppable.QuestionSetQuestions.length + 1,
    );
    return {
      dropAction: DropAction.MoveQuestion,
      destinationId,
      modelId: getModelIdFromDraggable(draggableId),
    };
  }

  // Add new conclusion type section
  if (zoneIsConclusionType(sourceDroppableId) && draggableId === 'Conclusion') {
    const destinationId = 'conclusions';

    return {
      destinationId,
      modelId: getModelIdFromDraggable(draggableId),
      dropAction: DropAction.AddConclusion,
    };
  }

  // Moving conclusions
  if (zoneIsConclusionType(sourceDroppableId) && draggableId.startsWith('conclusion-')) {
    return {
      destinationId: 'conclusions',
      modelId: getModelIdFromDraggable(draggableId),
      dropAction: DropAction.MoveConclusion,
    };
  }

  // Add Condition group from sidebar
  if (
    zoneIsConclusionType(sourceDroppableId) &&
    draggableId.includes(FormEditorDraggable.ConditionGroup)
  ) {
    return {
      destinationId: destinationDroppableId,
      modelId: getModelIdFromDraggable(draggableId),
      dropAction: DropAction.AddConditionGroup,
    };
  }

  // Add Addon from sidebar to Question
  if (
    zoneIsQuestionAddons(destinationDroppableId) &&
    zoneIsSidebarAddons(sourceDroppableId) &&
    draggableId.startsWith(`${FormEditorDraggable.SidebarAddon}-`)
  ) {
    const destinationId = destinationDroppableId.substring(
      FormEditorDroppable.QuestionAddons.length + 1,
    );
    return {
      dropAction: DropAction.AddAddon,
      draggableComponentType: getDraggableAddonType(draggableId),
      destinationId,
    };
  }

  return {
    dropAction: DropAction.None,
  };
}

export function addonHasSettings(addonType: FormAddonType): boolean {
  switch (addonType) {
    case FormAddonType.HelpText:
    case FormAddonType.SkipCondition:
    case FormAddonType.Condition:
      return true;
    default:
      return false;
  }
}
