import { Box } from '@chakra-ui/layout';
import { chakra } from '@chakra-ui/react';

export const CheckboxMock = chakra(Box, {
  baseStyle: {
    position: 'relative',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    border: '1px solid white',
    display: 'inline-block',
    _checked: {
      bgColor: 'brand.orange',
      border: 'none',
      _before: {
        content: '""',
        position: 'absolute',
        bgColor: 'white',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        transform: 'translate(-50%,-50%)',
        left: '50%',
        top: '50%',
      },
    },
  },
});
