import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { FC } from 'types';

interface IQuestionIconButtonProps extends Omit<IconButtonProps, 'aria-label' | 'icon'> {
  icon: string;
  label: string;
  onClick?(): void;
}
export const QuestionIconButton: FC<IQuestionIconButtonProps> = ({
  icon,
  label,
  isDisabled,
  onClick,
  ...rest
}) => {
  return (
    <IconButton
      isRound
      size="xs"
      icon={<span className={`appkiticon ${icon} a-font-16`} />}
      onClick={onClick}
      aria-label={`${label}`}
      isDisabled={isDisabled}
      sx={{
        color: 'brand.orange',
        bg: 'transparent',
        cursor: 'pointer',
        'svg path': {
          fill: 'brand.orange',
        },
        _focus: {
          bg: 'transparent',
        },
        _active: {
          bg: 'transparent',
        },
        _hover: {
          bg: 'transparent',
        },
      }}
      {...rest}
    />
  );
};
