import { ConfirmationModal } from 'components/modal/ConfirmationModal/ConfirmationModal';
import { FC } from 'types';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface IRoomTypeConfirmationModalProps {
  isSaving?: boolean;
  onCancel(): void;
  onConfirm(): void;
}

export const RoomTypeConfirmationModal: FC<IRoomTypeConfirmationModalProps> = ({
  isSaving = false,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      title={t('room.sidebar.roomDetails.modals.typeConfirmation.title')}
      maxWidth="480px"
      showLoader={isSaving}
      disabled={isSaving}
      confirmText={t('action.yes.label')}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <Text size="lg">{t('room.sidebar.roomDetails.modals.typeConfirmation.text')}</Text>
    </ConfirmationModal>
  );
};
