import { IDomainDto, ITransformedDomainDto, transformDomain } from './transformDomains';

interface IUser {
  domain: IDomainDto;
}

interface ITransformedUser {
  domain: ITransformedDomainDto;
}
const transformRoomDto = (dto: IUser): ITransformedUser => ({
  ...dto,
  domain: transformDomain(dto.domain),
});

export const transformUsersData = (data: Array<IUser>): Array<ITransformedUser> =>
  data.map(transformRoomDto);
