import { Text } from '@chakra-ui/react';
import { Fragment } from 'react';
import { PopAlert } from 'appkit-react';

// TODO: Move 'warning' and 'duration' args to 'options' object in the future?
// Open for discussion

export const PopAlertFail = (
  title = '',
  message: Array<string> | string = '',
  warning = false,
  duration = 5000,
) => {
  return PopAlert({
    duration,
    status: warning ? 'warning' : 'error',
    message: (
      <Fragment>
        <Text size="lg" mb="10px" fontWeight="bold">
          {title}
        </Text>
        <Text>{message}</Text>
      </Fragment>
    ),
    className: 'alert-fixed alert-fixed-bottom-right appkit-animation animated fadeInRight',
  });
};
