import { User } from 'models/User';
import { CampaignUserInitiatorStatus } from 'typings/enums/CampaignUserInitiatorStatus';
import { CampaignUserRole } from 'typings/enums/CampaignUserRole';
import { ICampaignUser } from 'typings/interfaces/ICampaignFormData';

export const transformUserToCampaignUser = (
  { id, username, email, displayName }: User,
  campaignId: string,
): ICampaignUser => ({
  id: `${id}@${campaignId}`,
  userId: id,
  username,
  email,
  displayName,
  role: CampaignUserRole.Viewer,
  defaultInitiatorStatus: CampaignUserInitiatorStatus.NA,
});
