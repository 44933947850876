import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'types';

import { StyledBadge } from 'components/core/StyledAppKit/StyledAppKit';

interface IQuestionOrderTagProps extends BoxProps {
  orderTag: string;
  level: number;
}
export const QuestionOrderTag: FC<IQuestionOrderTagProps> = ({ level, orderTag, ...rest }) => {
  return (
    <Box position="absolute" right="calc(100% + 12px)">
      <StyledBadge type={`${level > 0 ? 'primary' : 'light'}`} size="small" {...rest}>
        {orderTag}
      </StyledBadge>
    </Box>
  );
};
