import { FC } from 'types';
import { BoxProps } from '@chakra-ui/react';
import { useTranslation, TFunction } from 'react-i18next';

import { TableCellRow } from 'typings/types/TableCellRow';
import { TableWrapper } from 'components/table/TableWrapper/TableWrapper';
import { RoomFolder } from 'models/RoomFolder';
import { Form } from 'models/Form';
import { dateSortMethod } from 'utils/dateSortMethod';
import { useCurrentUserEmail } from 'hooks/useCurrentUserEmail';
import { StyledTable } from 'components/core/StyledAppKit/StyledAppKit';

import { RoomCellName } from './components/RoomCellName/RoomCellName';
import { RoomCellType } from './components/RoomCellType/RoomCellType';
import { useRoomItemsList } from './RoomsItemsList.hooks';

interface IRoomItemsListProps extends BoxProps {
  searchQuery: string;
}

const roomListColumns = (t: TFunction, userEmail: string) => [
  {
    width: 40,
    Cell: RoomCellType,
    id: 'type',
    accessor: (model: RoomFolder | Form) => {
      if (model.meta.type === Form.type) {
        return (model as Form).isFavorited(userEmail);
      }

      return false;
    },
    sortMethod(a: boolean, b: boolean) {
      return a > b ? 1 : -1;
    },
  },
  {
    Header: t('room.tableColumns.name'),
    accessor: 'name',
    Cell: RoomCellName,
  },
  {
    Header: t('room.tableColumns.owner'),
    accessor: 'ownerUsername',
  },
  {
    Header: t('room.tableColumns.lastUpdate'),
    accessor: 'formattedLastEdit',
    sortMethod: dateSortMethod,
  },
  {
    Header: t('room.tableColumns.status'),
    accessor: 'formStatus',
    Cell: (props: TableCellRow) =>
      props.original.formStatus ? t(`form.status.${props.original.formStatus}`) : null,
  },
];

export const RoomItemsList: FC<IRoomItemsListProps> = ({ searchQuery, sx }) => {
  const { t } = useTranslation();
  const { data, isInitialLoad, isRevalidating } = useRoomItemsList(searchQuery);
  const userEmail = useCurrentUserEmail();

  return (
    <TableWrapper isLoading={isInitialLoad || isRevalidating}>
      <StyledTable
        columns={roomListColumns(t, userEmail)}
        data={data}
        defaultPageSize={10}
        showPageSizeOptions={false}
        sx={sx}
      />
    </TableWrapper>
  );
};
