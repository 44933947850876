import { RoomUserRole } from 'typings/enums/RoomUserRole';
import { IResourceUser } from 'typings/interfaces/IResourceUser';
import { IRoomUser } from 'typings/interfaces/IRoomUser';

export const transformUserToRoomUser = ({
  id,
  username,
  email,
  displayName,
}: Omit<IResourceUser, 'userId'>): IRoomUser => ({
  id,
  userId: id,
  username,
  email,
  displayName,
  roomUserRole: RoomUserRole.Admin,
  expirationDate: null,
});
