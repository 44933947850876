import { FC } from 'types';
import { useTranslation } from 'react-i18next';
import { useBoolean } from '@chakra-ui/react';
import { mutate } from 'swr';
import { useHistory } from 'react-router-dom';

import { ActionsMenuItem } from 'components/core/ActionsMenu/ActionsMenuItem/ActionsMenuItem';
import { ActionsMenu } from 'components/core/ActionsMenu/ActionsMenu.elements';
import { PopAlertSuccess } from 'services/PopAlertSuccess';
import { PopAlertFail } from 'services/PopAlertFail';
import { useDependencyHeaders } from 'hooks/useDependencyHeaders';
import { FormStatus } from 'typings/enums/FormStatus';
import { FormUserRole } from 'typings/enums/FormUserRole';
import { FormActivationStatus } from 'typings/enums/FormActivationStatus';
import { Form } from 'models/Form';

import { useCurrentFormUser, useFormsMenuActions } from '../../FormsTable.hooks';

interface IFormsTableMenuProps {
  form: Form;
  top?: number;
  left?: number;
  onMembersModalOpen(): void;
  onExportModalOpen(): void;
  onClose(): void;
}

export const FormsTableMenu: FC<IFormsTableMenuProps> = ({
  form,
  onMembersModalOpen,
  onExportModalOpen,
  onClose,
  ...props
}) => {
  const [isDeleting, setIsDeleting] = useBoolean();
  const [isActivating, setIsActivating] = useBoolean();
  const [isRestoring, setIsRestoring] = useBoolean();
  const [isFinalizing, setIsFinalizing] = useBoolean();
  const [isApproving, setIsApproving] = useBoolean();
  const [isSubmittingForReview, setIsSubmittingForReview] = useBoolean();

  const { t } = useTranslation();
  const dependencyHeaders = useDependencyHeaders();
  const history = useHistory();

  const currentFormUser = useCurrentFormUser(form);

  const { submitForReviewForm, approveForm, finalizeForm, activateForm, restoreForm, deleteForm } =
    useFormsMenuActions();

  const handleOpen = () => {
    history.push(`/rooms/${form.room.id}/forms/${form.id}`);
  };

  const handleSubmitForReview = async () => {
    setIsSubmittingForReview.on();
    try {
      await submitForReviewForm(form.room.id, form.id);
      PopAlertSuccess(t('forms.tableView.successMessages.submitForReview'));
      await mutate(`forms@${dependencyHeaders['x-site-id']}`);
      onClose();
    } catch (response: any) { // eslint-disable-line
      if (response.StatusCode === 400) {
        PopAlertFail(t('forms.tableView.errors.submitForReview.main'));
      } else {
        PopAlertFail(t('forms.tableView.errors.submitForReview.unexpected'));
      }
    } finally {
      setIsSubmittingForReview.off();
      onClose();
    }
  };

  const handleApprove = async () => {
    setIsApproving.on();
    try {
      await approveForm(form.room.id, form.id);
      PopAlertSuccess(t('forms.tableView.successMessages.approve'));
      await mutate(`forms@${dependencyHeaders['x-site-id']}`);
      onClose();
    } catch (response: any) { // eslint-disable-line
      if (response.StatusCode === 400) {
        PopAlertFail(t('forms.tableView.errors.approve.main'));
      } else {
        PopAlertFail(t('forms.tableView.errors.approve.unexpected'));
      }
    } finally {
      setIsApproving.off();
      onClose();
    }
  };

  const handleFinalize = async () => {
    setIsFinalizing.on();
    try {
      await finalizeForm(form.room.id, form.id);
      PopAlertSuccess(t('forms.tableView.successMessages.finalize'));
      await mutate(`forms@${dependencyHeaders['x-site-id']}`);
      onClose();
    } catch (response: any) { // eslint-disable-line
      if (response.StatusCode === 400) {
        PopAlertFail(t('forms.tableView.errors.finalize.main'));
      } else {
        PopAlertFail(t('forms.tableView.errors.finalize.unexpected'));
      }
    } finally {
      setIsFinalizing.off();
      onClose();
    }
  };

  const handleShareWith = () => {
    onMembersModalOpen();
    onClose();
  };

  // For V2
  // const handleCopy = () => {
  //   onCopyModalOpen();
  //   onClose();
  // };

  const handleActivation = async () => {
    setIsActivating.on();
    try {
      await activateForm(form.room.id, form.id);
      PopAlertSuccess(t('forms.tableView.successMessages.activationStatus'));
      await mutate(`forms@${dependencyHeaders['x-site-id']}`);
      onClose();
    } catch (response: any) { // eslint-disable-line
      if (response.StatusCode === 400) {
        PopAlertFail(t('forms.tableView.errors.activationStatus.main'));
      } else {
        PopAlertFail(t('forms.tableView.errors.activationStatus.unexpected'));
      }
    } finally {
      setIsActivating.off();
      onClose();
    }
  };

  const handleRestore = async () => {
    setIsRestoring.on();
    try {
      await restoreForm(form.id);
      PopAlertSuccess(t('forms.tableView.successMessages.restore'));
      await mutate(`forms@${dependencyHeaders['x-site-id']}`);
      onClose();
    } catch (response: any) { // eslint-disable-line
      if (response.StatusCode === 400) {
        PopAlertFail(t('forms.tableView.errors.restore.main'));
      } else {
        PopAlertFail(t('forms.tableView.errors.restore.unexpected'));
      }
    } finally {
      setIsRestoring.off();
      onClose();
    }
  };

  const handleDelete = async () => {
    setIsDeleting.on();
    try {
      await deleteForm(form.room.id, form.id);
      PopAlertSuccess(t('forms.tableView.successMessages.delete'));
      await mutate(`forms@${dependencyHeaders['x-site-id']}`);
      onClose();
    } catch (response: any) { // eslint-disable-line
      if (response.StatusCode === 400) {
        PopAlertFail(t('forms.tableView.errors.delete.main'));
      } else {
        PopAlertFail(t('forms.tableView.errors.delete.unexpected'));
      }
    } finally {
      setIsDeleting.off();
      onClose();
    }
  };

  const currentUserCanEdit = currentFormUser?.role === FormUserRole.Edit;

  return (
    <ActionsMenu {...props}>
      {!form.isDeleted && (
        <ActionsMenuItem
          label={t('forms.tableView.menu.items.open')}
          iconName="icon-purpose-outline"
          onClick={handleOpen}
        />
      )}

      {form.isEditable && form.hasApproverFlag && currentUserCanEdit && (
        <ActionsMenuItem
          label={t('forms.tableView.menu.items.submitForReview')}
          iconName="icon-survey-outline"
          onClick={handleSubmitForReview}
          isLoading={isSubmittingForReview}
        />
      )}
      {form.isActive && form.hasApproverFlag && currentFormUser?.isApprover && (
        <ActionsMenuItem
          label={t('forms.tableView.menu.items.approve')}
          iconName="icon-survey-checkmark-outline"
          onClick={handleApprove}
          isLoading={isApproving}
        />
      )}
      {form.isActive &&
        currentUserCanEdit &&
        !form.hasApproverFlag &&
        form.formStatus !== FormStatus.Finalized &&
        form.formStatus !== FormStatus.WaitingForApproval && (
          <ActionsMenuItem
            label={t('forms.tableView.menu.items.finalize')}
            iconName="icon-confirmed-outline"
            onClick={handleFinalize}
            isLoading={isFinalizing}
          />
        )}

      <ActionsMenuItem
        label={t('forms.tableView.menu.items.shareWith')}
        iconName="icon-add-user-outline"
        onClick={handleShareWith}
      />

      <ActionsMenuItem
        label={t('forms.tableView.menu.items.export')}
        iconName="icon-download-outline"
        onClick={() => {
          onExportModalOpen();
          onClose();
        }}
      />

      {/* For V2 */}
      {/* {form.isActive && (
        <ActionsMenuItem
          label={t('forms.tableView.menu.items.sendCopy')}
          iconName="icon-duplicate-outline"
          onClick={handleCopy}
        />
      )} */}

      {/* Not ready on the backend - V2 */}
      {/* <ActionsMenuItem label={'Move to'} iconName="icon-move-outline" onClick={() => 'TODO'} /> */}

      {!form.isDeleted && currentUserCanEdit && (
        <ActionsMenuItem
          label={
            form.activationStatus === FormActivationStatus.Active
              ? t('forms.tableView.menu.items.deactivate')
              : t('forms.tableView.menu.items.activate')
          }
          iconName={
            form.activationStatus === FormActivationStatus.Active
              ? 'icon-end-process-outline'
              : 'icon-check-mark-outline'
          }
          onClick={handleActivation}
          isLoading={isActivating}
        />
      )}
      {form.isDeleted && currentUserCanEdit && (
        <ActionsMenuItem
          label={t('forms.tableView.menu.items.restore')}
          iconName="icon-refresh-outline"
          onClick={handleRestore}
          isLoading={isRestoring}
        />
      )}
      {!form.isDeleted && currentUserCanEdit && (
        <ActionsMenuItem
          label={t('forms.tableView.menu.items.delete')}
          iconName="icon-delete-outline"
          onClick={handleDelete}
          isLoading={isDeleting}
        />
      )}
    </ActionsMenu>
  );
};
